import React, { useState } from 'react';
import { Typography, Tabs, Tab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { useNavigate, useLocation } from 'react-router-dom';

const AntTabs = withStyles((theme) => ({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(1),
  },
}));

const RulesNav = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(location.pathname.split('/')[3]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/settings/rules/${newValue}`);
  };

  return (
    <div className={classes.root}>
      <AntTabs value={value} onChange={handleChange} aria-label="rules nav">
        <AntTab
          label="Single"
          onClick={() => navigate(`/settings/rules/single`)}
          value={'single'}
        />
        <AntTab
          label="Group"
          onClick={() => navigate(`/settings/rules/group`)}
          value={'group'}
        />
        <AntTab
          label="CSVs"
          onClick={() => navigate(`/settings/rules/csv`)}
          value={'csv'}
        />
      </AntTabs>
      <Typography className={classes.padding} />
    </div>
  );
};

export default RulesNav;
