import { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import axios from 'axios';
import EnhancedTable from '../../components/NewEnhancedTable';
import { useResolvedPath } from 'react-router-dom';
import Loading from '../../components/Loading';
import _ from 'lodash';

const BoatShowReports = () => {
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [initLayoutInfo, setInitLayoutInfo] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const url = useResolvedPath('').pathname;

  useEffect(() => {
    let source = axios.CancelToken.source();
    let tableSource = axios.CancelToken.source();
    const loadReports = async () => {
      const response = await axios.get('/api/boat-show/reports');
      setReports(_.orderBy(response.data, 'id', 'desc'));
      loadTableSettings();
    };

    const loadTableSettings = async () => {
      let title = `Boat Show Reports`;
      let formattedTableName = title.replace(/\s/g, '_').toLowerCase();
      try {
        const { data } = await axios.get('/api/user/table/settings', {
          cancelToken: tableSource.token,
          params: { tableName: formattedTableName },
        });
        if (data.message === 'Found table settings') {
          let tableSettings = data.tableSettings.fields;
          console.log('TABLE SETTINGS: ', tableSettings);
          setInitLayoutInfo(tableSettings);
        } else {
          setInitLayoutInfo({
            filter: {
              filterModel: {
                items: [],
                linkOperator: 'and',
              },
            },
            sorting: {
              sortModel: [],
            },
            columns: {
              columnVisibilityModel: { epicorRequestDate: false },
            },
          });
        }
        setLoading(false);
      } catch (err) {
        return console.log(err);
      }
    };

    loadReports();
    return function () {
      source.cancel('Cancelling in cleanup');
      tableSource.cancel('Cancelling table load in cleanup');
    };
  }, []);

  const viewReport = (id) => {
    window.open(`${url}/${id}`, '_blank');
  };

  const handleDeleteReport = () => {
    try {
      axios.delete(`/api/boat-show/report?id=${selectedReport}`);
      setReports(reports.filter((report) => report.id !== selectedReport));
      setOpenDeleteDialog(false);
      setSelectedReport(null);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleOpenDeleteDialog = (id) => {
    setSelectedReport(id);
    setOpenDeleteDialog(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Boat Show Reports
          </Typography>
          {loading && <Loading />}
          {!loading && (
            <EnhancedTable
              title="Boat Show Reports"
              data={reports}
              initLayoutInfo={initLayoutInfo}
              viewReport={viewReport}
              deleteReport={handleOpenDeleteDialog}
              disableActionIcon
              initRowsPerPage={50}
            />
          )}
        </Paper>
      </Grid>
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Delete Report</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete{' '}
            {reports.find((report) => report.id === selectedReport)?.showName}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button color="error" onClick={handleDeleteReport}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default BoatShowReports;
