import { useState, useEffect, useContext } from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';
import Loading from '../../components/Loading';
import LocationOrders from '../../components/LocationOrders';
import OrdersNav from '../../components/OrdersNav';
import { UserContext } from '../../userContext';
import { useParams } from 'react-router-dom';

const OrderHistory = () => {
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [archives, setArchives] = useState([]);
  const { dealershipId } = useParams();
  const userContext = useContext(UserContext);
  const [outsideRepEmails, setOutsideRepEmails] = useState([]);

  useEffect(() => {
    const loadLocations = async () => {
      if (dealershipId !== undefined) {
        const { data } = await axios.get('/api/dealer/locations', {
          params: { dealershipId: dealershipId },
        });
        userContext.userDispatch({
          type: 'setLocations',
          payload: {
            dealership: data.dealership,
            locations: data.dealership.locations,
          },
        });

        if (userContext.userState.me.role === 1) {
          setLocations(data.dealership.locations);
        } else {
          setLocations(data.dealership.locations.filter((l) => l.active));
        }

        setArchives([{ id: 'current', name: 'Current Year' }, ...data.archives]);
        setLoading(false);
      } else {
        const { data } = await axios.get('/api/dealer/locations');

        setLocations(data.dealership.locations.filter((l) => l.active));
        setArchives([{ id: 'current', name: 'Current Year' }, ...data.archives]);
        let repEmails = [];
        for (const rep of data.dealership.reps) {
          if (rep.user.role === 3) repEmails.push(rep.user.email);
        }
        // console.log('Outside rep emails: ', repEmails);
        setOutsideRepEmails(repEmails);
        setLoading(false);
      }
    };
    loadLocations();
  }, [dealershipId]);

  return (
    <Grid container justifyContent="center" spacing={2}>
      {loading ? (
        <Loading />
      ) : (
        <Grid item xs={12}>
          <OrdersNav role={userContext.userState.me.role} />
          <LocationOrders
            archives={archives}
            locations={locations}
            repEmails={outsideRepEmails}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default OrderHistory;
