import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Grid, Paper, Typography } from '@mui/material';
import EnhancedTable from '../components/NewEnhancedTable';
import Loading from '../components/Loading';
import { UserContext } from '../userContext';
import { orderStatus } from '../lib';

const OrderNotes = () => {
  const [notes, setNotes] = useState([]);
  const [initLayoutInfo, setInitLayoutInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [externalReps, setExternalReps] = useState([]);
  const [selectedRep, setSelectedRep] = useState('0');
  const userContext = useContext(UserContext);

  useEffect(() => {
    let source = axios.CancelToken.source();
    const loadExternalReps = async () => {
      try {
        const { data } = await axios.get('/api/user/reps/external');
        setExternalReps(data);
      } catch (err) {
        console.log(err.message);
      }
    };

    const loadNotes = async () => {
      try {
        const { data } = await axios.get('/api/notes', {
          params: {
            repId: selectedRep,
          },
          cancelToken: source.token,
        });
        setNotes(data.notes);
        loadTableSettings();
      } catch (err) {
        console.log(err.message);
      }
    };

    const loadTableSettings = async () => {
      let title = `Notes`;
      let formattedTableName = title.replace(/\s/g, '_').toLowerCase();
      try {
        const { data } = await axios.get('/api/user/table/settings', {
          params: { tableName: formattedTableName },
        });
        if (data.message === 'Found table settings') {
          let tableSettings = data.tableSettings.fields;
          setInitLayoutInfo(tableSettings);
        } else {
          setInitLayoutInfo({
            filter: {
              filterModel: {
                items: [],
                linkOperator: 'and',
              },
            },
            sorting: {
              sortModel: [],
            },
            columns: {
              columnVisibilityModel: {
                epicorRequestDate: false,
              },
            },
          });
        }
      } catch (err) {
        return console.log(err);
      }

      setLoading(false);
    };

    loadNotes();
    if (userContext.userState.me.role <= 2) {
      loadExternalReps();
    }

    return function () {
      source.cancel('Cancelling in cleanup');
    };
  }, [selectedRep]);

  const viewOrder = (id) => {
    const order = notes.find((note) => note.id === id);
    window.open(
      `/orders/${orderStatus(order.orderStatus).toLowerCase()}/order/${
        order.orderId
      }`,
      '_blank'
    );
  };

  return (
    <Grid container item>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h5">Order Notes</Typography>
          {loading && <Loading />}
          {!loading && (
            <EnhancedTable
              title="Notes"
              data={notes}
              disableActionIcon
              initRowsPerPage={50}
              initLayoutInfo={initLayoutInfo}
              hiddenColumns={['id', 'customerId']}
              externalReps={externalReps}
              selectedRep={selectedRep}
              setSelectedRep={setSelectedRep}
              viewOrder={viewOrder}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default OrderNotes;
