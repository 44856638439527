import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import collection from 'lodash/collection';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    top: 0,
    left: 0,
    height: '30vh',
    marginBottom: theme.spacing(2),
    maxHeight: '265px',
    '@media screen and (max-height: 400px) and (orientation:landscape)': {
      height: '185px',
    },
  },
  pdfRoot: {
    position: 'relative',
    top: 0,
    left: 0,
    height: '100%',
    marginBottom: theme.spacing(2),
    maxHeight: '265px',
  },
  preview: {
    position: 'relative',
    top: 0,
    left: 0,
    height: '16vh',
    marginBottom: theme.spacing(2),
    maxHeight: '150px',
    width: '225px',
    background: '#F8F8F8',
    borderRadius: '16px',
    cursor: 'pointer',
    '@media screen and (max-height: 400px) and (orientation:landscape)': {
      maxHeight: '100px',
      height: '100px',
      width: '200px',
    },
  },
  viewed: {
    position: 'relative',
    top: 0,
    left: 0,
    height: '16vh',
    marginBottom: theme.spacing(2),
    maxHeight: '150px',
    width: '225px',
    background: '#F8F8F8',
    border: 'solid 2px #006991',
    borderRadius: '16px',
    cursor: 'pointer',
    '@media screen and (max-height: 400px) and (orientation:landscape)': {
      height: '100px',
      width: '200px',
    },
  },
  mobilePreview: {
    height: '12vh',
    width: '105px',
  },
  image: {
    maxWidth: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: 'auto',
    maxHeight: '265px',
    '@media screen and (max-height: 400px) and (orientation:landscape)': {
      height: '185px',
    },
  },
  previewImage: {
    maxWidth: 'calc(100% - 15px)',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: 'auto',
    maxHeight: '265px',
    '@media screen and (max-height: 400px) and (orientation:landscape)': {
      maxHeight: '100px',
    },
  },
  pdfImage: {
    maxWidth: '100%',
    maxHeight: '155px',
    position: 'absolute',
    margin: 'auto',
    top: '0',
    bottom: '0',
    left: '50%',
    transform: 'translateX(-50%)',
    [theme.breakpoints.down('xl')]: {
      maxHeight: '140px',
    },
  },
}));

const OrderFormHeroImage = (props) => {
  const {
    isMobile,
    inputs,
    mainSrc,
    maxHeightOverride,
    pdf = false,
    isViewed = false,
    isPreview = false,
    onClick,
  } = props;
  const classes = useStyles();
  let timestamp = Date.now();

  return (
    <div
      className={clsx({
        [classes.pdfRoot]: pdf,
        [classes.root]: !pdf && !isPreview,
        [classes.viewed]: !pdf && isPreview && isViewed,
        [classes.preview]: !pdf && isPreview && !isViewed,
        [classes.mobilePreview]: !pdf && isPreview && isMobile,
      })}
      onClick={onClick}
    >
      {mainSrc !== undefined ? (
        <img
          className={
            pdf ? classes.pdfImage : isPreview ? classes.previewImage : classes.image
          }
          src={`https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/images/${mainSrc}?${timestamp}`}
          height={maxHeightOverride !== undefined ? maxHeightOverride : null}
          crossOrigin="anonymous"
        />
      ) : null}
      {collection.sortBy(inputs, ['imageOrder']).map((i) => {
        if (i.value !== '') {
          let src = i.inputOptions.find((io) => io.id === i.value);
          if (src === undefined) {
            // console.log('MISSING OPTION: ', i, src);
            return null;
          }
          if (src.imgPath === null) return null;
          return (
            <img
              key={src.id}
              className={
                pdf
                  ? classes.pdfImage
                  : isPreview
                  ? classes.previewImage
                  : classes.image
              }
              src={`https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/images/${src.imgPath}?${timestamp}`}
              alt={src.imgPath}
              height={maxHeightOverride !== undefined ? maxHeightOverride : null}
              crossOrigin="anonymous"
            />
          );
        }
      })}
    </div>
  );
};

export default OrderFormHeroImage;
