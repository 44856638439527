import { TextField, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import UploadRebateFile from '../forms/UploadRebateFile';
import { UserContext } from '../../userContext';
import { useContext } from 'react';
import { formatPrice } from '../../lib';

const ViewRebate = ({ rebate, setRebates, rebates }) => {
  const userContext = useContext(UserContext);

  return (
    <Grid item container gap={2}>
      <Grid item container gap={1}>
        <Grid item sm={3}>
          <TextField
            label="ATOM Order ID"
            variant="standard"
            value={rebate.orderId}
            disabled
          />
        </Grid>
        {rebate.order?.epicorOrderNumber && (
          <Grid item sm={3}>
            <TextField
              label="Epicor Order ID"
              variant="standard"
              value={rebate.order?.epicorOrderNumber}
              disabled
            />
          </Grid>
        )}
        <Grid item sm={3}>
          <TextField
            label="Request Date"
            variant="standard"
            value={format(new Date(rebate.requestDate), 'MM/dd/yyyy')}
            disabled
          />
        </Grid>
        <Grid item sm={3}>
          <TextField
            label="Requested By"
            variant="standard"
            value={rebate.requestUser.name}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item container gap={2}>
        <Grid item>
          <TextField
            label="Sale Amount"
            variant="standard"
            value={rebate.saleAmount}
            disabled
          />
        </Grid>
        <Grid item>
          <TextField
            label="Rebate Amount"
            variant="standard"
            value={rebate.rebateAmount}
            disabled
          />
        </Grid>
        <Grid item>
          <TextField
            label="Rebate Status"
            variant="standard"
            value={rebate.rebateStatus}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item container gap={2}>
        <Grid item>
          <TextField
            label="Response Date"
            variant="standard"
            value={
              rebate?.approvalDate
                ? format(new Date(rebate.approvalDate), 'MM/dd/yyyy')
                : ''
            }
            disabled
          />
        </Grid>
        <Grid item>
          <TextField
            label="Updated By"
            variant="standard"
            value={rebate?.approvalUser?.name ?? ''}
            disabled
          />
        </Grid>
        {rebate?.filename && (
          <Grid item>
            <Typography variant="h6">Bill of Sale</Typography>
            <a
              href={`https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/files/${rebate.filename
                .split(' ')
                .join('+')}`}
              target="_blank"
            >
              {rebate?.filename}
            </a>
          </Grid>
        )}
      </Grid>
      {rebate.OrderDtl_UnitPrice && (
        <Grid item xs={12}>
          <Typography variant="h6">
            Dealer margin if rebate approved:{' '}
            {Math.round(
              100 *
                ((+rebate.saleAmount -
                  +rebate.OrderDtl_UnitPrice +
                  +rebate.rebateAmount) /
                  +rebate.saleAmount)
            )}{' '}
            %
          </Typography>
        </Grid>
      )}

      {rebate.order?.epicorOrderNumber && userContext?.userState?.me?.role <= 2 && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6">Epicor Rebate Info</Typography>
          </Grid>
          <Grid item container gap={4}>
            <Grid item>
              <TextField
                label="OrderDtl_LineDesc"
                variant="standard"
                value={rebate.OrderDtl_LineDesc}
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                label="OrderDtl_UnitPrice"
                variant="standard"
                value={formatPrice(+rebate.OrderDtl_UnitPrice)}
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                label="Calculated_bomcost"
                variant="standard"
                value={formatPrice(+rebate.Calculated_bomcost)}
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                label="Calculated_ContPerc"
                variant="standard"
                value={`${Math.round(100 * +rebate.Calculated_ContPerc)} %`}
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                label="JobHead_JobComplete"
                variant="standard"
                value={rebate.JobHead_JobComplete}
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                label="JobHead_JobClosed"
                variant="standard"
                value={rebate.JobHead_JobClosed}
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                label="Calculated_closedfordays"
                variant="standard"
                value={rebate.Calculated_closedfordays}
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                label="Calculated_Contribution"
                variant="standard"
                value={formatPrice(+rebate.Calculated_Contribution)}
                disabled
              />
            </Grid>
            <Grid item container xs={12} gap={2}>
              <Grid item>
                <Grid item container gap={2}>
                  <Grid item xs={12}>
                    <Typography>Before Rebate</Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Contribution Margin"
                      variant="standard"
                      value={formatPrice(
                        +rebate.OrderDtl_UnitPrice - +rebate.Calculated_bomcost
                      )}
                      disabled
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Gross Margin"
                      variant="standard"
                      value={`${Math.round(
                        100 *
                          ((+rebate.OrderDtl_UnitPrice -
                            +rebate.Calculated_bomcost) /
                            +rebate.OrderDtl_UnitPrice)
                      )} %`}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid item container gap={2}>
                  <Grid item xs={12}>
                    <Typography>After Rebate</Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Contribution Margin"
                      variant="standard"
                      value={formatPrice(
                        +rebate.OrderDtl_UnitPrice -
                          +rebate.rebateAmount -
                          +rebate.Calculated_bomcost
                      )}
                      disabled
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Gross Margin"
                      variant="standard"
                      value={`${Math.round(
                        100 *
                          ((+rebate.OrderDtl_UnitPrice -
                            +rebate.rebateAmount -
                            +rebate.Calculated_bomcost) /
                            (+rebate.OrderDtl_UnitPrice - +rebate.rebateAmount))
                      )} %`}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {rebate.rebateStatus === 'APPROVED' && (
        <UploadRebateFile
          rebate={rebate}
          rebates={rebates}
          setRebates={setRebates}
        />
      )}
    </Grid>
  );
};

export default ViewRebate;
