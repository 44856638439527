import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Button,
  Typography,
  CardActions,
  Divider,
  Box,
  Slider,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import makeStyles from '@mui/styles/makeStyles';
import {
  useParams,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Loading from '../../Loading';
import Filters from './Filters';
import { formatPrice } from '../../../lib';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    backgroundColor: 'rgba(0,0,0,0)',
    boxShadow: 'none',
    padding: 20,
    '&:hover': {
      backgroundColor: 'lightGrey',
      borderRadius: 20,
      cursor: 'pointer',
    },
    '@media screen and (max-height: 400px) and (orientation:landscape)': {
      padding: '0px 20px',
    },
  },
  body2: {
    fontSize: '11px',
  },
  marginBot2: {
    marginBottom: theme.spacing(2),
  },
  floorplanTitle: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  media: {
    width: 'auto',
    maxHeight: '90px',
  },
  cardActions: {
    paddingTop: '0px',
  },
  inlineBlock: {
    display: 'inline-block',
  },
  title: {
    color: '#747679',
  },
  pathName: {
    textDecoration: 'underline',
    textDecorationColor: '#006991',
    textDecorationThickness: '3px',
  },
}));

const Floorplans = (props) => {
  const [
    models,
    tags,
    queryTags,
    customerBuild,
    priceFilter,
    lengthFilter,
    handleChangeLengthFilter,
    handleChangePriceFilter,
    hasAvailableFloorplans,
    floorplans,
    price,
    setPrice,
    customerOrderForm,
    showHidden,
    markup,
    setMarkup,
    modelMarkups,
    useModelMarkups,
    calculatePriceWithMarkup,
    setModelMarkups,
    setUseModelMarkups,
    priceMarkupFilter,
  ] = useOutletContext();
  const { modelId, formType, brand, orderId } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [selectedModel, setSelectedModel] = useState(null);
  const [groupedFloorplans, setGroupedFloorplans] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // console.log(modelId, url)
    let sm = models.inputOptions.find((io) => io.id === parseInt(modelId, 10));
    // console.log(tags.filter(t => t.type === 'SECONDARY'), queryTags)
    // console.log("SELECTED MODEL: ", sm)
    // console.log("MODEL FLOORPLAN RULES: ", models.rulesX.filter(r => r.yValue === sm.id))
    // console.log("MODEL FLOORPLAN TAG RULES: ", models.rulesX.filter(r => r.yValue === sm.id && r.changeType === 'TAG'))
    let mfRules = models.rulesX.filter((r) => r.yValue === sm.id);
    for (let fpRule of mfRules) {
      let foundOption = sm.floorplans.find(
        (fp) => fp.floorplan.id === fpRule.zDropdownId
      );
      if (foundOption !== undefined) {
        switch (fpRule.changeType) {
          case 'IMGPATH':
            foundOption.floorplan.imgPath = fpRule.changeValue;
            foundOption.floorplan.rules = { ...foundOption.rules, imgPath: fpRule };
            break;
          case 'PRICE':
            foundOption.floorplan.price = parseInt(fpRule.changeValue, 10);
            foundOption.floorplan.rules = { ...foundOption.rules, price: fpRule };
            break;
          case 'TAG':
            let foundTag = tags.find(
              (tag) => tag.id === parseInt(fpRule.changeValue, 10)
            );
            if (foundTag !== undefined) {
              console.log(foundTag);
              foundOption.floorplan.tags !== undefined
                ? foundOption.floorplan.tags.push(foundTag)
                : (foundOption.floorplan.tags = [foundTag]);
            }
            break;
        }
      }
    }
    // Set fp size and type
    for (let fp of sm.floorplans) {
      let parsedNames = fp.floorplan.avalonName.split("'");
      fp.size = parseInt(parsedNames[0], 10);
      fp.type = parsedNames[1].trim();
    }

    // Group by fp type
    let tmpFloorplans = sm.floorplans.reduce(function (r, a) {
      r[a.type] = r[a.type] || [];
      r[a.type].push(a);
      return r;
    }, Object.create(null));

    // sort floorplans by size
    Object.entries(tmpFloorplans).map(([key, value]) => {
      return (tmpFloorplans[key] = value.sort((a, b) => a.size - b.size));
    });

    // filter by tags
    if (queryTags !== []) {
      Object.entries(tmpFloorplans).map(([key, value]) => {
        return (tmpFloorplans[key] = value.filter((v) => {
          let flag = true;
          for (let qt of queryTags) {
            if (!v.floorplan.tags.find((tag) => tag.name === qt)) flag = false;
          }
          if (flag) return v;
        }));
      });
    }

    setGroupedFloorplans(tmpFloorplans);
    setSelectedModel(sm);
    setLoading(false);
  }, [formType, modelId]);

  const handleNavBack = () => {
    navigate('../');
    // history.push(`/order/${orderId}/${formType}/${brand}` + history.location.search);
  };

  const handleSelectFloorplan = (id, model, floorplan, price) => {
    window.dataLayer.push({
      event: 'selected-floorplan',
      eventCategory: `${floorplan} ${model}`,
      eventLabel: `${model}|${floorplan}|${price} MSRP`,
    });
    if (searchParams) {
      navigate(`floorplan/${id}/page/1?` + searchParams);
    } else {
      navigate(`floorplan/${id}/page/1`);
    }
  };

  const floorplanIsActive = (fp) => {
    let active = true;

    if (formType === 'd' && !customerBuild) {
      if (
        priceMarkupFilter[0] >
          calculatePriceWithMarkup(fp.floorplan.price, +modelId, true) ||
        calculatePriceWithMarkup(fp.floorplan.price, +modelId, true) >
          priceMarkupFilter[1]
      ) {
        active = false;
      }
      if (lengthFilter[0] > fp.size || fp.size > lengthFilter[1]) {
        active = false;
      }
    } else {
      if (
        priceFilter[0] > fp.floorplan.price ||
        fp.floorplan.price > priceFilter[1]
      ) {
        active = false;
      }
      if (lengthFilter[0] > fp.size || fp.size > lengthFilter[1]) {
        active = false;
      }
    }

    return active;
  };

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Box
          className={classes.inlineBlock}
          style={{ float: 'left', lineHeight: '65px' }}
          fontSize={28}
        >
          <Box
            component="span"
            color="gray"
            onClick={() => handleNavBack()}
            sx={{ cursor: 'pointer' }}
          >{`Models >`}</Box>
          <Box component="span" mx={1} className={classes.pathName}>
            Floorplans
          </Box>
        </Box>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <Grid container item columnSpacing={5} mt={2}>
          <Grid item sm={12} md={4}>
            <Filters
              models={models}
              priceFilter={priceFilter}
              lengthFilter={lengthFilter}
              handleChangeLengthFilter={handleChangeLengthFilter}
              handleChangePriceFilter={handleChangePriceFilter}
              hasAvailableFloorplans={hasAvailableFloorplans}
              formatPrice={formatPrice}
              customerBuild={customerBuild}
              formType={formType}
              priceMarkupFilter={priceMarkupFilter}
            />
          </Grid>
          <Grid item sm={12} md={8}>
            {!loading && selectedModel !== null ? (
              <Typography variant="h5" align="left" className={classes.title}>
                {brand === 't' ? selectedModel.tahoeName : selectedModel.avalonName}
                {' - '}
                {
                  selectedModel.floorplans.filter((floorplan) =>
                    floorplanIsActive(floorplan)
                  ).length
                }{' '}
                available floorplans
              </Typography>
            ) : null}
            {!loading && selectedModel !== null
              ? Object.entries(groupedFloorplans).map(([key, value], i) => {
                  if (
                    value.filter((floorplan) => floorplanIsActive(floorplan))
                      .length === 0
                  )
                    return null;
                  if (value.length === 0) return null;
                  return (
                    <div key={`${key}_${i}`} className={classes.marginBot2}>
                      <Typography variant="h6" className={classes.floorplanTitle}>
                        {key}
                      </Typography>
                      <Grid container spacing={3}>
                        {value.map((fp) => {
                          if (!floorplanIsActive(fp)) return null;
                          return (
                            <Grid item key={`${fp.floorplan.id}`}>
                              <Card
                                className={classes.root}
                                onClick={() =>
                                  handleSelectFloorplan(
                                    fp.floorplan.id,
                                    brand === 'a'
                                      ? selectedModel.avalonName
                                      : selectedModel.tahoeName,
                                    fp.floorplan.avalonName,
                                    formType === 'd'
                                      ? fp.floorplan.price
                                      : Math.round(fp.floorplan.price / 0.64)
                                  )
                                }
                              >
                                {`https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/images/${fp.floorplan.imgPath}` &&
                                fp.floorplan.imgPath !== null ? (
                                  <CardMedia
                                    className={classes.media}
                                    component="img"
                                    image={`https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/images/${fp.floorplan.imgPath}`}
                                    title={`${fp.floorplan.avalonName}`}
                                  />
                                ) : null}
                                <CardContent>
                                  <Typography variant="h6" textAlign="center">
                                    {fp.size} FT
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    className={classes.body2}
                                    textAlign="center"
                                  >
                                    Starting MSRP at{' '}
                                    {calculatePriceWithMarkup(
                                      fp.floorplan.price,
                                      +modelId
                                    )}
                                    *
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                  );
                })
              : null}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Floorplans;
