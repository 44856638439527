import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  MenuItem,
  Autocomplete,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Loading from '../../../../../components/Loading';

const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

const InputsWithRules = (props) => {
  const { ruleGroup, inputs, pages, inputsWithRules, setInputsWithRules } = props;
  const [selectedInputWithRules, setSelectedInputWithRules] = useState([]);
  const [selectedPage, setSelectedPage] = useState('');
  const [selectedInput, setSelectedInput] = useState(null);
  const [pageInputs, setPageInputs] = useState([]);
  const [initLoading, setInitLoading] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    console.log(ruleGroup);
    if (ruleGroup !== null) {
      let grouped = groupBy(ruleGroup.rules, (r) => r.zId);
      let formattedGrouped = [];
      for (let [key, value] of grouped.entries()) {
        formattedGrouped.push({ id: key, name: value[0].zInput.name, rules: value });
      }
      setInputsWithRules(formattedGrouped);
      setInitLoading(false);
    }
  }, []);

  const editRules = () => {
    if (selectedInputWithRules.length !== 0) {
      navigate(`input/${selectedInputWithRules}`);
    } else {
      console.log('Issue with editing input with rules');
    }
  };

  const deleteAllRules = async () => {
    if (selectedInputWithRules.length !== 0) {
      try {
        await axios.get('/api/rules/group/input/delete', {
          params: { ruleGroupId: ruleGroup.id, zId: selectedInputWithRules },
        });
        setInputsWithRules(
          inputsWithRules.filter((input) => input.id !== selectedInputWithRules)
        );
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const handleSelectPage = (e) => {
    console.log(inputsWithRules);
    setSelectedPage(e.target.value);
    setPageInputs(
      inputs.filter(
        (i) =>
          i.pageId === e.target.value &&
          inputsWithRules.filter((ir) => ir.id === i.id).length === 0 &&
          !i.isDeleted
      )
    );
  };

  const handleSelectInput = (e, option) => {
    if (option === null) {
      setSelectedInput(null);
    } else {
      setSelectedInput(option);
    }
  };

  const handleAddInput = () => {
    navigate(`input/${selectedInput.id}`);
  };

  if (initLoading) {
    return <Loading />;
  }

  return (
    <Box component="div" sx={{ mt: 2 }}>
      <Typography variant="h4" gutterBottom>
        Inputs with rules
      </Typography>

      <Box
        component="div"
        sx={{
          mb: 2,
        }}
      >
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <TextField
                  id="page-select"
                  select
                  label="Page"
                  value={selectedPage}
                  onChange={handleSelectPage}
                  size="small"
                  sx={{
                    width: '30ch',
                  }}
                >
                  <MenuItem value="">Select Page</MenuItem>
                  {pages !== undefined
                    ? pages.map((page) => (
                        <MenuItem key={page.id} value={page.id}>
                          {page.name}
                        </MenuItem>
                      ))
                    : null}
                </TextField>
              </Grid>
              <Grid item>
                <Autocomplete
                  id="input-select"
                  options={pageInputs ? pageInputs : []}
                  getOptionLabel={(option) =>
                    option !== undefined ? option.name : ''
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Input" size="small" />
                  )}
                  onChange={handleSelectInput}
                  value={selectedInput}
                  disabled={selectedPage === ''}
                  sx={{
                    width: '30ch',
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={handleAddInput}
                  disabled={selectedInput === null}
                >
                  Add Input
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={editRules}
              disabled={
                selectedInputWithRules === undefined ||
                selectedInputWithRules.length === 0
              }
            >
              Edit Rules
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                ml: 2,
              }}
              disabled={
                selectedInputWithRules === undefined ||
                selectedInputWithRules.length === 0
              }
              onClick={deleteAllRules}
            >
              Delete Rules
            </Button>
          </Grid>
        </Grid>
      </Box>
      <DataGridPro
        autoHeight
        columns={[
          { field: 'id', headerName: 'ID', width: 90 },
          { field: 'name', headerName: 'Name', width: 240 },
          {
            field: 'rules',
            headerName: '# of rules',
            width: 150,
            valueGetter: (params) => `${params.row.rules.length}`,
          },
        ]}
        rows={inputsWithRules}
        rowHeight={38}
        checkboxSelection
        selectionModel={selectedInputWithRules}
        onSelectionModelChange={(value) => {
          if (value.length === 0) {
            setSelectedInputWithRules([]);
          } else {
            setSelectedInputWithRules(value[value.length - 1]);
          }
        }}
        sortModel={[{ field: 'id', sort: 'asc' }]}
      />
    </Box>
  );
};

export default InputsWithRules;
