import React, { useEffect, useState } from 'react';
import { Grid, Typography, useMediaQuery, Button, Box, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EnhancedTable from '../../../components/NewEnhancedTable';
import Loading from '../../../components/Loading';
import axios from 'axios';
import { DropzoneDialog } from 'material-ui-dropzone';
import theme from '../../../theme';
import DiffTable from './DiffTable';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.5rem',
  },
}));

const MotorSettings = () => {
  const classes = useStyles();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const [loading, setLoading] = useState(true);
  const [motors, setMotors] = useState([]);
  const [openDropzone, setOpenDropzone] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [files, setFiles] = useState([]);
  const [initLayoutInfo, setInitLayoutInfo] = useState({});
  const [settings, setSettings] = useState([]);
  const [isActivating, setIsActivating] = useState(false);
  const [viewDiff, setViewDiff] = useState(false);

  useEffect(() => {
    const fetchMotorSettings = async () => {
      try {
        const { data } = await axios.get('/api/inputOptions/motors');

        setMotors(data.motors);
        setSettings(data.settings);
        loadTableSettings();
      } catch (err) {
        console.log(err.message);
      }
    };

    const loadTableSettings = async () => {
      let title = `Motor Settings`;
      let formattedTableName = title.replace(/\s/g, '_').toLowerCase();
      try {
        const { data } = await axios.get('/api/user/table/settings', {
          params: { tableName: formattedTableName },
        });
        if (data.message === 'Found table settings') {
          let tableSettings = data.tableSettings.fields;
          console.log('TABLE SETTINGS: ', tableSettings);
          setInitLayoutInfo(tableSettings);
        } else {
          setInitLayoutInfo({
            filter: {
              filterModel: {
                items: [],
                linkOperator: 'and',
              },
            },
            sorting: {
              sortModel: [],
            },
            columns: {
              columnVisibilityModel: [],
            },
          });
        }
      } catch (err) {
        return console.log(err);
      }
      setLoading(false);
    };

    if (loading) {
      fetchMotorSettings();
    }
  }, [loading]);

  const handleEdit = async (updatedRow) => {
    const index = motors.findIndex((d) => d.id === updatedRow.id);
    let temp = [...motors];
    temp.splice(index, 1, updatedRow);
    setMotors(temp);
    try {
      let {
        id,
        oemPart,
        epicorPart,
        epicorPrice,
        transom,
        sstPropPart,
        alumPropPart,
        performance,
      } = updatedRow;
      if (
        transom === 1 ||
        transom === '1' ||
        transom === 'true' ||
        transom === 'TRUE'
      ) {
        transom = true;
      } else {
        transom = false;
      }
      await axios.put('/api/inputOptions/motor', {
        id,
        oemPart,
        epicorPart,
        epicorPrice,
        transom,
        sstPropPart,
        alumPropPart,
        performance,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownloadCSV = async (fileName) => {
    let params = {};
    if (fileName.split('.').length > 1) {
      params = { fileName };
    }
    try {
      axios({
        url: '/api/inputOptions/motors/csv',
        method: 'GET',
        responseType: 'blob',
        params,
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        if (fileName.split('.').length > 1) {
          link.setAttribute('download', `${fileName}`);
        } else {
          link.setAttribute('download', `motor_settings_from_table.xlsx`);
        }

        document.body.appendChild(link);
        link.click();
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleFileChange = (files) => {
    setFiles(files);
  };

  const handleFileUpload = async () => {
    let formData = new FormData();
    formData.append('file', files[0], files[0].name);

    try {
      setSubmitting(true);
      const { data } = await axios.post('/api/inputOptions/motors', formData);
      console.log(data);
      setOpenDropzone(false);
      setFiles([]);
      setSubmitting(false);
      setLoading(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleActivateCSV = async () => {
    try {
      setIsActivating(true);
      const { data } = await axios.get('/api/inputOptions/motors/activate');
      if (data.message === 'success') {
        setSettings(data.settings);
      }
      setIsActivating(false);
      setLoading(true);
      setViewDiff(false);
    } catch (err) {
      console.log(err.message);
      setIsActivating(false);
    }
  };

  return (
    <Paper sx={{ p: 4 }}>
      <Grid container>
        {loading ? (
          <Loading />
        ) : (
          <Grid item xs={12}>
            <Typography className={classes.title}>Motor Settings</Typography>
            <Grid
              item
              container
              xs={12}
              justifyContent="space-between"
              sx={{ mt: 2, mb: 3 }}
            >
              <Grid item>
                {settings.find((s) => s.name === 'active_motor_csv').value !==
                  '' && (
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    Active CSV:{' '}
                    {settings.find((s) => s.name === 'active_motor_csv').value}
                  </Typography>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() =>
                    handleDownloadCSV(
                      settings.find((s) => s.name === 'active_motor_csv').value
                    )
                  }
                >
                  Download CSV
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  size="small"
                  disabled={submitting}
                  onClick={() => setOpenDropzone(true)}
                  sx={{ ml: 2 }}
                >
                  Upload CSV
                </Button>
              </Grid>
              {settings.find((s) => s.name === 'pending_motor_csv').value !== '' && (
                <Grid item>
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    Pending CSV:{' '}
                    {settings.find((s) => s.name === 'pending_motor_csv').value}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => handleActivateCSV()}
                    disabled={isActivating}
                  >
                    {isActivating ? 'Activating new motor settings...' : 'Activate'}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() =>
                      handleDownloadCSV(
                        settings.find((s) => s.name === 'pending_motor_csv').value
                      )
                    }
                    sx={{ ml: 2 }}
                  >
                    Download CSV
                  </Button>
                  <Button
                    variant="contained"
                    color={viewDiff ? 'primary' : 'secondary'}
                    size="small"
                    onClick={() => setViewDiff(!viewDiff)}
                    sx={{ ml: 2 }}
                  >
                    {viewDiff ? 'View Current Settings' : 'Compare CSVs'}
                  </Button>
                </Grid>
              )}
            </Grid>

            {viewDiff ? (
              <DiffTable />
            ) : (
              <EnhancedTable
                title="Motor Settings"
                isMobile={isMobile}
                data={motors}
                hiddenColumns={['id']}
                editableColumns={[
                  'oemPart',
                  'epicorPart',
                  'epicorPrice',
                  'transom',
                  'sstPropPart',
                  'alumPropPart',
                  'performance',
                ]}
                elevation={0}
                dense
                disableSelectAll
                handleEdit={handleEdit}
                initLayoutInfo={initLayoutInfo}
                hideExportTableData={true}
              />
            )}

            <DropzoneDialog
              onChange={(files) => handleFileChange(files)}
              filesLimit={1}
              showAlerts={false}
              open={openDropzone}
              onClose={() => setOpenDropzone(false)}
              showFileNames
              acceptedFiles={[
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              ]}
              onSave={() => handleFileUpload()}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default MotorSettings;
