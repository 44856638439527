import { useState } from 'react';
import axios from 'axios';
import { getFormattedDate } from '../../../lib';

import { LoadingButton } from '@mui/lab';

const DownloadMotorReport = () => {
  const [loadingMotorReport, setLoadingMotorReport] = useState(false);

  const downloadReport = async () => {
    try {
      setLoadingMotorReport(true);
      axios({
        url: '/api/motor/report',
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        let formattedDate = getFormattedDate(new Date());
        link.href = url;
        link.setAttribute('download', `motor_report_${formattedDate}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
      setLoadingMotorReport(false);
    } catch (err) {
      setLoadingMotorReport(false);
      console.log(err);
    }
  };

  return (
    <LoadingButton
      loading={loadingMotorReport}
      variant="contained"
      color="primary"
      size="large"
      onClick={() => downloadReport()}
    >
      Download Motor Report
    </LoadingButton>
  );
};

export default DownloadMotorReport;
