import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import AddCommunicationEntry from '../../forms/AddCommunicationEntry';

const AddCommunicationEntryModal = ({
  show,
  handleClose,
  dealershipId,
  communicationList,
  setCommunicationList,
}) => {
  return (
    <Dialog
      open={show}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="add-communication-entry"
    >
      <DialogTitle>Add Communication Entry</DialogTitle>
      <DialogContent>
        <AddCommunicationEntry
          dealershipId={dealershipId}
          communicationList={communicationList}
          setCommunicationList={setCommunicationList}
          closeAdd={handleClose}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCommunicationEntryModal;
