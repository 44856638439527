import React from 'react';
import { FormControl, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2),
  },
}));

const Text = (props) => {
  const { input, handleInputChange, showHidden, disabled } = props;
  const classes = useStyles();

  const handleTextFieldChange = (value, input) => {
    let regex = new RegExp(/^[a-zA-Z0-9-_-\s.'\,]+$/);
    if (regex.test(value) || value === '') {
      handleInputChange(value, input);
    } else {
      console.log('Invalid characters');
    }
  };

  return (
    <div style={{ display: input.isHidden && !showHidden ? 'none' : 'block' }}>
      <FormControl className={classes.formField} fullWidth>
        <TextField
          label={input.name}
          value={input.value}
          onChange={(e) => handleTextFieldChange(e.target.value, input)}
          disabled={input.disabled || disabled}
          fullWidth
        />
      </FormControl>
    </div>
  );
};

export default Text;
