import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, useLocation, Outlet } from 'react-router-dom';
import Loading from '../Loading';
import axios from 'axios';
import { UserContext } from '../../userContext';
import { Grid } from '@mui/material';
import { formatPrice } from '../../lib';

const OrderForm = (props) => {
  const [loading, setLoading] = useState(true);
  const [models, setModels] = useState(null);
  const [price, setPrice] = useState(0);
  const [tags, setTags] = useState([]);
  const [floorplans, setFloorplans] = useState(null);
  const { dealershipId, locationId, formType, brand, orderId } = useParams();
  const userContext = useContext(UserContext);
  const customerBuild = userContext.userState.me.customerBuild;
  const navigate = useNavigate();
  const [queryTags, setQueryTags] = useState([]);
  const [showHidden, setShowHidden] = useState(false);
  let location = useLocation();
  const [priceFilter, setPriceFilter] = useState([0, 0]);
  const [priceMarkupFilter, setPriceMarkupFilter] = useState([0, 0]);
  const [lengthFilter, setLengthFilter] = useState([0, 0]);
  const customerOrderFormRe = new RegExp('/order/new/c/(a|t)');
  const match = customerOrderFormRe.test(location.pathname);
  const [customerOrderForm, setCustomerOrderForm] = useState(match);
  const [markup, setMarkup] = useState(0);
  const [modelMarkups, setModelMarkups] = useState([]);
  const [useModelMarkups, setUseModelMarkups] = useState(false);
  const defaultMSRP = 0.64;

  useEffect(() => {
    const loadPageOne = async () => {
      setLoading(true);
      const { data } = await axios.get(`/api/form/init?brand=${brand}`);

      let initMarkup = 0;
      let initModelMarkups = [];
      let initUseModelMarkups = false;
      if (location.search !== '') {
        // we have query tags
        let queryString = location.search.split('=');
        if (
          (queryString[0] === '?debug' && queryString[1] === 'true') ||
          queryString[1] === 'tru1'
        ) {
          setShowHidden(true);
        } else {
          // assume they're model/floorplan tags
          setQueryTags(queryString[1].split('&'));
        }
      }

      if (formType !== 'c') {
        // double check users dealership brand for dealership only
        if (
          userContext.userState.me.dealership !== null &&
          userContext.userState.me?.dealership?.brand !== undefined
        ) {
          if (
            brand === 'a' &&
            userContext.userState.me.dealership.brand === 2 &&
            userContext.userState.me.role >= 10
          ) {
            navigate(`/order/${orderId}/${formType}/t`);
          }
          if (
            brand === 't' &&
            userContext.userState.me.dealership.brand === 1 &&
            userContext.userState.me.role >= 10
          ) {
            navigate(`/order/${orderId}/${formType}/a`);
          }
        }

        // get the markup
        if (
          userContext.userState.me?.locations &&
          userContext.userState.me.role >= 10
        ) {
          let location = userContext.userState.me.locations[0].location;
          if (location.useModelMarkup) {
            setModelMarkups(location.modelMarkups);
            setUseModelMarkups(true);
            initModelMarkups = location.modelMarkups;
            initUseModelMarkups = true;
          } else {
            setMarkup(location.markup);
            initMarkup = location.markup;
          }
        } else {
          // check for location
          if (userContext.userState.me.role <= 3) {
            if (userContext.userState.me.dealership !== null) {
              let location = null;
              if (locationId) {
                location = userContext.userState.me.dealership.locations.find(
                  (location) => location.id === +locationId
                );
              } else {
                location = userContext.userState.me.dealership.locations[0];
              }

              if (location) {
                if (location.useModelMarkup) {
                  setModelMarkups(location.modelMarkups);
                  setUseModelMarkups(true);
                  initModelMarkups = location.modelMarkups;
                  initUseModelMarkups = true;
                } else {
                  setMarkup(location.markup);
                  initMarkup = location.markup;
                }
              } else {
                console.log(
                  'LOCATION NOT FOUND: ',
                  locationId,
                  userContext.userState.me.dealership.locations
                );
              }
            } else {
              const { data } = await axios.get('/api/dealer/locations', {
                params: { dealershipId: dealershipId, orderId: orderId },
              });
              userContext.userDispatch({
                type: 'setLocations',
                payload: {
                  dealership: data.dealership,
                  locations: data.dealership.locations,
                },
              });
              let location = null;
              if (locationId) {
                location = data.dealership.locations.find(
                  (location) => location.id === +locationId
                );
              } else {
                location = data.dealership.locations[0];
              }

              if (location) {
                if (location.useModelMarkup) {
                  setModelMarkups(location.modelMarkups);
                  setUseModelMarkups(true);
                } else {
                  setMarkup(location.markup);
                }
              } else {
                console.log(
                  'LOCATION NOT FOUND: ',
                  locationId,
                  data.dealership.locations
                );
              }
            }
          }
        }
      }

      // console.log("Order Form INIT: ", data)
      setTags(data.tags);

      let models = data.inputs[0];
      let floorplans = data.inputs[1];

      let mfPairings = data.mfPairings;

      // model filter boundaries
      models.minPrice = null;
      models.maxPrice = null;
      models.minLength = null;
      models.maxLength = null;
      models.minMarkupPrice = null;
      models.maxMarkupPrice = null;
      for (let model of models.inputOptions) {
        model.floorplans = [];
        model.filterOptions = [];

        // find if csv for model exists
        let pairings = mfPairings.find((p) => p.modelId === model.id);
        if (pairings !== undefined) {
          // add floorplans to model
          for (let fp of pairings.ruleGroups) {
            let tmpFilterOption = {
              id: null,
              price: null,
              length: null,
            };
            let fpCondition = fp.conditions.find((c) => c.xId === 2);
            let foundFP = floorplans.inputOptions.find(
              (floorplan) => floorplan.id === fpCondition.yValue
            );
            if (foundFP !== undefined) {
              model.floorplans.push({ floorplan: foundFP, ruleGroupId: fp.id });
              tmpFilterOption.id = foundFP.id;
              let parsedFPName = foundFP.avalonName.split("'");
              tmpFilterOption.length = parseInt(parsedFPName[0], 10);
              tmpFilterOption.name = parsedFPName[1];
              if (models.minLength === null && models.maxLength === null) {
                models.minLength = parseInt(parsedFPName[0], 10);
                models.maxLength = parseInt(parsedFPName[0], 10);
              } else {
                if (models.minLength > parseInt(parsedFPName[0], 10)) {
                  models.minLength = parseInt(parsedFPName[0], 10);
                }
                if (models.maxLength < parseInt(parsedFPName[0], 10)) {
                  models.maxLength = parseInt(parsedFPName[0], 10);
                }
              }
              model.filterOptions.push(tmpFilterOption);
            }
          }
          // find rules for models and floorplans
          let mfPriceRules = models.rulesX.filter(
            (r) => r.yValue === model.id && r.changeType === 'PRICE'
          );
          model.startingPrice = null;
          model.startingMarkupPrice = null;
          model.prices = [];
          model.markupPrices = [];

          if (formType === 'd') {
            if (initUseModelMarkups) {
              const selectedModelMarkup = initModelMarkups.find(
                (mm) => mm.modelId === +model.id
              );
              if (selectedModelMarkup !== undefined) {
                model.markup = selectedModelMarkup.markup;
              } else {
                model.markup = 0;
              }
            } else {
              model.markup = initMarkup;
            }
          } else {
            model.markup = 0;
          }

          for (let rule of mfPriceRules) {
            let price = parseInt(rule.changeValue, 10);
            // if (customerBuild) price = price / 0.64;
            model.prices.push(price);
            // look up model price

            if (models.minPrice === null) {
              models.minPrice = price;
            } else {
              if (models.minPrice > price)
                models.minPrice = -Math.ceil(-price / 1000) * 1000;
            }
            if (models.maxPrice === null) {
              models.maxPrice = price;
            } else {
              if (models.maxPrice < price)
                models.maxPrice = Math.ceil(price / 1000) * 1000;
            }
            if (model.startingPrice === null) {
              model.startingPrice = price;
            } else {
              if (model.startingPrice > price) model.startingPrice = price;
            }

            let markupPrice = parseInt(rule.changeValue, 10);
            model.markupPrices.push(markupPrice);
            if (formType === 'd') {
              if (useModelMarkups) {
                const selectedModelMarkup = modelMarkups.find(
                  (mm) => mm.modelId === +model.id
                );
                if (selectedModelMarkup !== undefined) {
                  markupPrice =
                    markupPrice / ((100 - selectedModelMarkup.markup) / 100);
                }
              } else {
                markupPrice = markupPrice / ((100 - parseInt(markup, 10)) / 100);
              }
            }

            let foundFP = model.filterOptions.find((option) => {
              if (option.id === parseInt(rule.zDropdownId, 10)) {
                option.price = price;
                option.markupPrice = markupPrice;
              }
            });

            if (models.minMarkupPrice === null) {
              models.minMarkupPrice = markupPrice;
            } else {
              if (models.minMarkupPrice > markupPrice)
                models.minMarkupPrice = -Math.ceil(-markupPrice / 1000) * 1000;
            }
            if (models.maxMarkupPrice === null) {
              models.maxMarkupPrice = markupPrice;
            } else {
              if (models.maxMarkupPrice < markupPrice)
                models.maxMarkupPrice = Math.ceil(markupPrice / 1000) * 1000;
            }
            if (model.startingMarkupPrice === null) {
              model.startingMarkupPrice = markupPrice;
            } else {
              if (model.startingMarkupPrice > markupPrice)
                model.startingMarkupPrice = markupPrice;
            }
          }
          model.prices.sort();
          model.markupPrices.sort();
        }
      }
      // console.log(models, floorplans)
      setModels(models);
      setFloorplans(floorplans);
      setPriceFilter([models.minPrice, models.maxPrice]);
      setPriceMarkupFilter([models.minMarkupPrice, models.maxMarkupPrice]);
      setLengthFilter([models.minLength, models.maxLength]);
      setLoading(false);
    };

    // if (userContext.userState.me.dealership === null && userContext.userState.me.role <= 3) navigate('/dealerships')
    loadPageOne();
  }, []);

  const handleChangePriceFilter = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (formType === 'd' && !customerBuild) {
      if (activeThumb === 0) {
        setPriceMarkupFilter([
          Math.min(newValue[0], priceMarkupFilter[1] - 1000),
          priceMarkupFilter[1],
        ]);
      } else {
        setPriceMarkupFilter([
          priceMarkupFilter[0],
          Math.max(newValue[1], priceMarkupFilter[0] + 1000),
        ]);
      }
    } else {
      if (activeThumb === 0) {
        setPriceFilter([
          Math.min(newValue[0], priceFilter[1] - 1000),
          priceFilter[1],
        ]);
      } else {
        setPriceFilter([
          priceFilter[0],
          Math.max(newValue[1], priceFilter[0] + 1000),
        ]);
      }
    }
  };

  const handleChangeLengthFilter = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setLengthFilter([newValue[0], lengthFilter[1]]);
    } else {
      setLengthFilter([lengthFilter[0], newValue[1]]);
    }
  };

  const hasAvailableFloorplans = (model) => {
    let numFloorplans = model.filterOptions.filter((fo) => {
      let price = fo.price === null ? 15000 : fo.price;
      let markupPrice = fo.markupPrice === null ? 15000 : fo.markupPrice;
      if (formType === 'd' && !customerBuild) {
        if (
          priceMarkupFilter[0] <= markupPrice &&
          markupPrice <= priceMarkupFilter[1] &&
          lengthFilter[0] <= fo.length &&
          fo.length <= lengthFilter[1]
        ) {
          return fo;
        }
      } else {
        if (
          priceFilter[0] <= price &&
          price <= priceFilter[1] &&
          lengthFilter[0] <= fo.length &&
          fo.length <= lengthFilter[1]
        ) {
          return fo;
        }
      }
    }).length;

    return numFloorplans;
  };

  const calculatePriceWithMarkup = (price, modelId, asNumber = false) => {
    let calculatedPrice = 0;
    if (formType === 'd') {
      if (customerBuild) {
        if (useModelMarkups) {
          const selectedModelMarkup = modelMarkups.find(
            (mm) => mm.modelId === +modelId
          );
          if (
            selectedModelMarkup !== undefined &&
            selectedModelMarkup.markup !== 0
          ) {
            calculatedPrice = price / ((100 - selectedModelMarkup.markup) / 100);
          } else {
            calculatedPrice = price / defaultMSRP;
          }
        } else {
          calculatedPrice =
            markup === 0
              ? price / defaultMSRP
              : price / ((100 - parseInt(markup, 10)) / 100);
        }
      } else {
        calculatedPrice = price;
      }
    } else {
      calculatedPrice = price / defaultMSRP;
    }
    return asNumber
      ? Math.ceil(calculatedPrice)
      : formatPrice(Math.ceil(calculatedPrice));
  };

  const calculateTotalPrice = (inputs, fpPrice, modelId, asNumber = false) => {
    let calculatedPrice = 0;

    inputs
      .filter((i) => i.active)
      .map((i) => {
        if (i.type === 'DROPDOWN' && i.value !== '' && i.value !== null) {
          let io = i.inputOptions.find((inputOption) => inputOption.id === i.value);
          if (io === undefined) {
            return console.log('FAILED TO FIND OPTION: ', i, io);
          }
          if (i.id === 6 && io.twin) {
            return (calculatedPrice +=
              calculatePriceWithMarkup(io.price, modelId, true) +
              calculatePriceWithMarkup(io.twin.price, modelId, true));
          }
          return (calculatedPrice += calculatePriceWithMarkup(
            io.price,
            modelId,
            true
          ));
        }
        if (i.type === 'CHECKBOX' && i.value) {
          return (calculatedPrice += calculatePriceWithMarkup(
            i.price,
            modelId,
            true
          ));
        }
        return;
      });

    calculatedPrice += calculatePriceWithMarkup(fpPrice, modelId, true);
    if (asNumber) return Math.ceil(calculatedPrice);
    return formatPrice(calculatedPrice);
  };

  return (
    <Grid container spacing={2} sx={{ px: 4 }}>
      {loading ? (
        <Loading />
      ) : (
        <Outlet
          context={[
            models,
            tags,
            queryTags,
            customerBuild,
            priceFilter,
            lengthFilter,
            handleChangeLengthFilter,
            handleChangePriceFilter,
            hasAvailableFloorplans,
            floorplans,
            price,
            setPrice,
            customerOrderForm,
            showHidden,
            markup,
            setMarkup,
            modelMarkups,
            useModelMarkups,
            calculatePriceWithMarkup,
            setModelMarkups,
            setUseModelMarkups,
            priceMarkupFilter,
            calculateTotalPrice,
          ]}
        />
      )}
    </Grid>
  );
};

export default OrderForm;
