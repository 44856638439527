import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import EditUser from '../../../../components/forms/EditUser';
import Loading from '../../../../components/Loading';
import NewEnhancedTable from '../../../../components/NewEnhancedTable';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  highlighted: {
    color: theme.palette.secondary.main,
  },
}));

const ManageUser = (props) => {
  const {
    users,
    setUsers,
    user,
    setSelectedUser,
    loadingUser,
    dealerships,
    updateBulk,
    saveUserPermissions,
    updatedPermissions,
    setUpdatedPermissions,
    userType,
    role,
    userSelectedDealerships,
    setUserSelectedDealerships,
  } = props;
  const classes = useStyles();

  const checkPermission = (permission) => {
    let foundPermission = updatedPermissions.find(
      (p) => p.permission === permission
    );

    if (!foundPermission) return false;
    if (foundPermission.isAllowed === null) {
      return foundPermission.roleDefault;
    } else {
      return foundPermission.isAllowed;
    }
  };

  const handleTogglePermission = (permission) => {
    let foundPermission = updatedPermissions.find((up) => up.permission);
    if (foundPermission) {
      setUpdatedPermissions(
        updatedPermissions.map((up) => {
          if (up.permission === permission) {
            let updatedIsAllowed = null;
            if (up.isAllowed === null) {
              updatedIsAllowed = !up.roleDefault;
            } else {
              if (up.isAllowed === up.roleDefault) {
                updatedIsAllowed = null;
              } else {
                updatedIsAllowed = !up.isAllowed;
              }
            }
            return { ...up, isAllowed: updatedIsAllowed, touched: true };
          } else {
            return up;
          }
        })
      );
    }
  };

  const checkColor = (permission) => {
    if (!updatedPermissions) return false;
    let foundPermission = updatedPermissions.find(
      (p) => p.permission === permission
    );

    return foundPermission?.isAllowed !== null;
  };

  const handleSelectDealership = (dealerships) => {
    setUserSelectedDealerships(dealerships);
  };

  return (
    <Grid item xs={12} lg={6}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Editing {user.name}
      </Typography>
      <Accordion aria-controls="profile-content" id="profile-header">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Profile</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {loadingUser ? (
            <Loading />
          ) : (
            <EditUser
              users={users}
              setUsers={setUsers}
              user={user}
              closeEdit={setSelectedUser}
            />
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion aria-controls="permissions-content" id="permissions-header">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Permissions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button
            variant="contained"
            onClick={() => saveUserPermissions(updatedPermissions)}
          >
            Save Permissions
          </Button>

          <TableContainer>
            <Table sx={{ minWidth: 750 }}>
              <TableHead>
                <TableRow>
                  <TableCell>View</TableCell>
                  <TableCell>Create</TableCell>
                  <TableCell>Edit</TableCell>
                  <TableCell>Submit</TableCell>
                  <TableCell>Verify</TableCell>
                  <TableCell>Return to Quote</TableCell>
                  <TableCell>Print Order</TableCell>
                  <TableCell>Convert Lead</TableCell>
                  <TableCell>Change Dealership</TableCell>
                  <TableCell>DERP Codes</TableCell>
                  <TableCell>Edit Discounts</TableCell>
                  <TableCell>Edit Locked Inputs</TableCell>
                  <TableCell>Request Rebate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    <Checkbox
                      color={checkColor('view_order') ? 'secondary' : 'primary'}
                      checked={checkPermission('view_order')}
                      onChange={() => handleTogglePermission('view_order')}
                      className={clsx({
                        [classes.highlighted]: checkColor('view_order'),
                      })}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color={checkColor('create_order') ? 'secondary' : 'primary'}
                      checked={checkPermission('create_order')}
                      onChange={() => handleTogglePermission('create_order')}
                      className={clsx({
                        [classes.highlighted]: checkColor('create_order'),
                      })}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color={checkColor('edit_order') ? 'secondary' : 'primary'}
                      checked={checkPermission('edit_order')}
                      onChange={() => handleTogglePermission('edit_order')}
                      className={clsx({
                        [classes.highlighted]: checkColor('edit_order'),
                      })}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color={checkColor('submit_order') ? 'secondary' : 'primary'}
                      checked={checkPermission('submit_order')}
                      onChange={() => handleTogglePermission('submit_order')}
                      className={clsx({
                        [classes.highlighted]: checkColor('submit_order'),
                      })}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color={checkColor('verify_order') ? 'secondary' : 'primary'}
                      checked={checkPermission('verify_order')}
                      onChange={() => handleTogglePermission('verify_order')}
                      className={clsx({
                        [classes.highlighted]: checkColor('verify_order'),
                      })}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color={checkColor('return_to_quote') ? 'secondary' : 'primary'}
                      checked={checkPermission('return_to_quote')}
                      onChange={() => handleTogglePermission('return_to_quote')}
                      className={clsx({
                        [classes.highlighted]: checkColor('return_to_quote'),
                      })}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color={checkColor('print_order') ? 'secondary' : 'primary'}
                      checked={checkPermission('print_order')}
                      onChange={() => handleTogglePermission('print_order')}
                      className={clsx({
                        [classes.highlighted]: checkColor('print_order'),
                      })}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color={checkColor('convert_lead') ? 'secondary' : 'primary'}
                      checked={checkPermission('convert_lead')}
                      onChange={() => handleTogglePermission('convert_lead')}
                      className={clsx({
                        [classes.highlighted]: checkColor('convert_lead'),
                      })}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color={
                        checkColor('change_dealership') ? 'secondary' : 'primary'
                      }
                      checked={checkPermission('change_dealership')}
                      onChange={() => handleTogglePermission('change_dealership')}
                      className={clsx({
                        [classes.highlighted]: checkColor('change_dealership'),
                      })}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color={checkColor('derp_codes') ? 'secondary' : 'primary'}
                      checked={checkPermission('derp_codes')}
                      onChange={() => handleTogglePermission('derp_codes')}
                      className={clsx({
                        [classes.highlighted]: checkColor('derp_codes'),
                      })}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color={checkColor('edit_discounts') ? 'secondary' : 'primary'}
                      checked={checkPermission('edit_discounts')}
                      onChange={() => handleTogglePermission('edit_discounts')}
                      className={clsx({
                        [classes.highlighted]: checkColor('edit_discounts'),
                      })}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color={
                        checkColor('edit_locked_inputs') ? 'secondary' : 'primary'
                      }
                      checked={checkPermission('edit_locked_inputs')}
                      onChange={() => handleTogglePermission('edit_locked_inputs')}
                      className={clsx({
                        [classes.highlighted]: checkColor('edit_locked_inputs'),
                      })}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color={checkColor('request_rebate') ? 'secondary' : 'primary'}
                      checked={checkPermission('request_rebate')}
                      onChange={() => handleTogglePermission('request_rebate')}
                      className={clsx({
                        [classes.highlighted]: checkColor('request_rebate'),
                      })}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Manage Dealer</TableCell>
                  <TableCell>Dealer Users</TableCell>
                  <TableCell>Locations</TableCell>
                  <TableCell>Find Dealers</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    <Checkbox
                      color={checkColor('manage_dealers') ? 'secondary' : 'primary'}
                      checked={checkPermission('manage_dealers')}
                      onChange={() => handleTogglePermission('manage_dealers')}
                      className={clsx({
                        [classes.highlighted]: checkColor('manage_dealers'),
                      })}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color={
                        checkColor('manage_dealer_users') ? 'secondary' : 'primary'
                      }
                      checked={checkPermission('manage_dealer_users')}
                      onChange={() => handleTogglePermission('manage_dealer_users')}
                      className={clsx({
                        [classes.highlighted]: checkColor('manage_dealer_users'),
                      })}
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      color={
                        checkColor('manage_dealer_locations')
                          ? 'secondary'
                          : 'primary'
                      }
                      checked={checkPermission('manage_dealer_locations')}
                      onChange={() =>
                        handleTogglePermission('manage_dealer_locations')
                      }
                      className={clsx({
                        [classes.highlighted]: checkColor('manage_dealer_locations'),
                      })}
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      color={
                        checkColor('find_dealerships') ? 'secondary' : 'primary'
                      }
                      checked={checkPermission('find_dealerships')}
                      onChange={() => handleTogglePermission('find_dealerships')}
                      className={clsx({
                        [classes.highlighted]: checkColor('find_dealerships'),
                      })}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Dealer Portal</TableCell>
                  <TableCell>View Invoices</TableCell>
                  <TableCell>Rep Portal</TableCell>
                  <TableCell>Terms</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    <Checkbox
                      color={checkColor('dealer_portal') ? 'secondary' : 'primary'}
                      checked={checkPermission('dealer_portal')}
                      onChange={() => handleTogglePermission('dealer_portal')}
                      className={clsx({
                        [classes.highlighted]: checkColor('dealer_portal'),
                      })}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color={checkColor('view_invoices') ? 'secondary' : 'primary'}
                      checked={checkPermission('view_invoices')}
                      onChange={() => handleTogglePermission('view_invoices')}
                      className={clsx({
                        [classes.highlighted]: checkColor('view_invoices'),
                      })}
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      color={checkColor('rep_portal') ? 'secondary' : 'primary'}
                      checked={checkPermission('rep_portal')}
                      onChange={() => handleTogglePermission('rep_portal')}
                      className={clsx({
                        [classes.highlighted]: checkColor('rep_portal'),
                      })}
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      color={checkColor('terms') ? 'secondary' : 'primary'}
                      checked={checkPermission('terms')}
                      onChange={() => handleTogglePermission('terms')}
                      className={clsx({
                        [classes.highlighted]: checkColor('terms'),
                      })}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {role === 1 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Form Builder</TableCell>
                    <TableCell>Rules Engine</TableCell>
                    <TableCell>Motor Settings</TableCell>
                    <TableCell>AT Dealer Users</TableCell>
                    <TableCell>Roles</TableCell>
                    <TableCell>Dealer Allotment</TableCell>
                    <TableCell>Notifications</TableCell>
                    <TableCell>Reporting</TableCell>
                    <TableCell>Boat Show Reports</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      <Checkbox
                        color={
                          checkColor('admin_form_builder') ? 'secondary' : 'primary'
                        }
                        checked={checkPermission('admin_form_builder')}
                        onChange={() => handleTogglePermission('admin_form_builder')}
                        className={clsx({
                          [classes.highlighted]: checkColor('admin_form_builder'),
                        })}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        color={checkColor('admin_rules') ? 'secondary' : 'primary'}
                        checked={checkPermission('admin_rules')}
                        onChange={() => handleTogglePermission('admin_rules')}
                        className={clsx({
                          [classes.highlighted]: checkColor('admin_rules'),
                        })}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        color={checkColor('admin_motors') ? 'secondary' : 'primary'}
                        checked={checkPermission('admin_motors')}
                        onChange={() => handleTogglePermission('admin_motors')}
                        className={clsx({
                          [classes.highlighted]: checkColor('admin_motors'),
                        })}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        color={
                          checkColor('manage_io_sales') ? 'secondary' : 'primary'
                        }
                        checked={checkPermission('manage_io_sales')}
                        onChange={() => handleTogglePermission('manage_io_sales')}
                        className={clsx({
                          [classes.highlighted]: checkColor('manage_io_sales'),
                        })}
                      />
                    </TableCell>

                    <TableCell>
                      <Checkbox
                        color={checkColor('manage_roles') ? 'secondary' : 'primary'}
                        checked={checkPermission('manage_roles')}
                        onChange={() => handleTogglePermission('manage_roles')}
                        className={clsx({
                          [classes.highlighted]: checkColor('manage_roles'),
                        })}
                      />
                    </TableCell>

                    <TableCell>
                      <Checkbox
                        color={
                          checkColor('admin_allotment') ? 'secondary' : 'primary'
                        }
                        checked={checkPermission('admin_allotment')}
                        onChange={() => handleTogglePermission('admin_allotment')}
                        className={clsx({
                          [classes.highlighted]: checkColor('admin_allotment'),
                        })}
                      />
                    </TableCell>

                    <TableCell>
                      <Checkbox
                        color={
                          checkColor('manage_notifications')
                            ? 'secondary'
                            : 'primary'
                        }
                        checked={checkPermission('manage_notifications')}
                        onChange={() =>
                          handleTogglePermission('manage_notifications')
                        }
                        className={clsx({
                          [classes.highlighted]: checkColor('manage_notifications'),
                        })}
                      />
                    </TableCell>

                    <TableCell>
                      <Checkbox
                        color={
                          checkColor('admin_upcoming_orders')
                            ? 'secondary'
                            : 'primary'
                        }
                        checked={checkPermission('admin_upcoming_orders')}
                        onChange={() =>
                          handleTogglePermission('admin_upcoming_orders')
                        }
                        className={clsx({
                          [classes.highlighted]: checkColor('admin_upcoming_orders'),
                        })}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        color={
                          checkColor('view_boat_show_reports')
                            ? 'secondary'
                            : 'primary'
                        }
                        checked={checkPermission('view_boat_show_reports')}
                        onChange={() =>
                          handleTogglePermission('view_boat_show_reports')
                        }
                        className={clsx({
                          [classes.highlighted]: checkColor(
                            'view_boat_show_reports'
                          ),
                        })}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </AccordionDetails>
      </Accordion>

      {userType === 'reps' && (
        <Accordion aria-controls="dealers-content" id="dealers-header">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Dealers</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <NewEnhancedTable
              title="Dealerships"
              totalSelectedShow
              data={dealerships}
              hiddenColumns={[
                'id',
                'reps',
                'active',
                'q1',
                'q2',
                'q3',
                'q4',
                'q1Next',
                'q2Next',
                'q3Next',
                'q4Next',
              ]}
              disableActionIcon={true}
              initSelected={userSelectedDealerships}
              selectedData={userSelectedDealerships}
              setSelectedData={handleSelectDealership}
              updateBulk={updateBulk}
              enableFilters
              filterTypes={['name']}
              nameSearchField={'name'}
              elevation={0}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </Grid>
  );
};

export default ManageUser;
