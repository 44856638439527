import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';
Chart.register(ArcElement);
import { Grid, Typography } from '@mui/material';

import { makeStyles } from '@mui/styles';

const orderChartOptions = {
  legend: {
    display: false,
  },
  elements: {
    arc: {
      borderWidth: 2,
    },
  },
  cutout: '60%',
};

const useStyles = makeStyles((theme) => ({
  orderPrice: {
    fontSize: '2rem',
    fontWeight: '700',
  },
}));

const OrderCounts = (props) => {
  const { ordersData, totalOrdersCount, colors } = props;
  const classes = useStyles();

  const OrderMark = (index) => {
    const color = colors[index];
    let orderKindName;
    switch (index) {
      case 0:
        orderKindName = 'Leads';
        break;
      case 1:
        orderKindName = 'Quote';
        break;
      case 2:
        orderKindName = 'Submitted';
        break;
      case 3:
        orderKindName = 'Confirmed';
        break;
    }
    return (
      <Typography>
        <span
          style={{ backgroundColor: color, borderRadius: '4px', marginRight: '8px' }}
        >
          &nbsp;&nbsp;
        </span>
        <span style={{ color: '#747679' }}>{orderKindName}</span>
      </Typography>
    );
  };

  return (
    <Grid item container xs={12} spacing={3} alignItems="center">
      <Grid item container xs={12} sm={3}>
        {ordersData && (
          <Grid item xs={10}>
            <Doughnut data={ordersData} options={orderChartOptions} />
          </Grid>
        )}
      </Grid>
      <Grid item container xs={12} sm={9}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            sx={{ mb: 3 }}
          >{`${totalOrdersCount} Orders`}</Typography>
        </Grid>
        {ordersData?.datasets?.[0]?.data?.map((item, index) => (
          <Grid item xs={6} sm={3} key={index}>
            {OrderMark(index)}
            <Typography className={classes.orderPrice}>{item}</Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default OrderCounts;
