import React, { useState } from 'react';
import {
  TextField,
  Select,
  FormControl,
  Button,
  Grid,
  FormControlLabel,
  Switch as Toggle,
  InputLabel,
  Box,
  MenuItem,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Loading from '../Loading';

const validationSchema = yup.object({
  name: yup.string('Enter a name').required('Name is required'),
  tahoeName: yup.string(),
  price: yup.number(),
  required: yup.boolean(),
  formType: yup.mixed().oneOf(['ALL', 'CUSTOMER', 'DEALER']),
  inputLayout: yup.mixed().oneOf(['NORMAL', 'GRID', 'HERO', 'INTERACTIVETOOLTIP']),
  overlayImage: yup.mixed().oneOf(['NA', 'BOAT', 'BENCH']),
  imageOrder: yup.number(),
  active: yup.boolean(),
  id: yup.string(),
  inputOrder: yup.number(),
  description: yup.string(),
  printOrder: yup.number(),
  hidePrint: yup.boolean(),
  isHidden: yup.boolean(),
  epicorPriceBoxName: yup.string(),
  epicorConfigPage: yup.string(),
  epicorInputName: yup.string(),
  epicorInputType: yup
    .mixed()
    .oneOf(['CHARACTER', 'CHECKBOX', 'COMBOBOX', 'DECIMAL']),
  isDeleted: yup.boolean(),
  isLockedSubmitted: yup.boolean(),
  branded: yup.boolean(),
  locked: yup.boolean(),
});

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

const EditInput = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const { input, pageGroups } = props;

  const formik = useFormik({
    initialValues: {
      name: input.name,
      tahoeName: input.tahoeName !== null ? input.tahoeName : '',
      type: input.type,
      price: input.price !== null ? input.price : 0,
      required: input.required,
      formType: input.formType,
      inputLayout: input.inputLayout,
      overlayImage: input.overlayImage !== null ? input.overlayImage : 'NA',
      imageOrder: input.imageOrder !== null ? input.imageOrder : '',
      active: input.active,
      id: input.id,
      inputOrder: input.inputOrder,
      pageGroupId: input.pageGroupId !== null ? input.pageGroupId : 'NA',
      description: input.description !== null ? input.description : '',
      printOrder: input.printOrder,
      hidePrint: input.hidePrint,
      isHidden: input.isHidden,
      epicorPriceBoxName:
        input.epicorPriceBoxName !== null ? input.epicorPriceBoxName : '',
      epicorConfigPage:
        input.epicorConfigPage !== null ? input.epicorConfigPage : '',
      epicorInputName: input.epicorInputName !== null ? input.epicorInputName : '',
      epicorInputType: input.epicorInputType !== null ? input.epicorInputType : '',
      isDeleted: input.isDeleted !== null ? input.isDeleted : false,
      isLockedSubmitted:
        input.isLockedSubmitted !== null ? input.isLockedSubmitted : false,
      branded: input.branded !== null ? input.branded : false,
      locked: input.locked !== null ? input.locked : false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSubmitting(true);
      try {
        await axios.put('/api/input', values);

        setSubmitError(null);
        setSubmitting(false);
      } catch (err) {
        setSubmitError(err.response.data.message);
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      {submitting ? (
        <Loading text="Updating..." />
      ) : (
        <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          {submitError !== null ? (
            <Box color="error.main">
              <Typography variant="subtitle2">{submitError}</Typography>
            </Box>
          ) : null}
          <Grid container alignContent="space-between" spacing={3}>
            <Grid item sm={12} md={4}>
              <Typography variant="h6" gutterBottom>
                Settings
              </Typography>
              <FormControl fullWidth>
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  className={classes.formField}
                  variant="standard"
                  size="small"
                  fullWidth
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  id="tahoeName"
                  name="tahoeName"
                  label="Tahoe Name"
                  value={formik.values.tahoeName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.tahoeName && Boolean(formik.errors.tahoeName)
                  }
                  helperText={formik.touched.tahoeName && formik.errors.tahoeName}
                  className={classes.formField}
                  variant="standard"
                  size="small"
                  fullWidth
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  id="price"
                  name="price"
                  label="Price"
                  value={formik.values.price}
                  onChange={formik.handleChange}
                  error={formik.touched.price && Boolean(formik.errors.price)}
                  helperText={formik.touched.price && formik.errors.price}
                  className={classes.formField}
                  variant="standard"
                  size="small"
                  fullWidth
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel>Form Type</InputLabel>
                <Select
                  id="formType"
                  name="formType"
                  label="Form Type"
                  value={formik.values.formType}
                  onChange={formik.handleChange}
                  error={formik.touched.formType && Boolean(formik.errors.formType)}
                  className={classes.formField}
                  size="small"
                  fullWidth
                >
                  <MenuItem value="ALL">All</MenuItem>
                  <MenuItem value="DEALER">Dealer</MenuItem>
                  <MenuItem value="CUSTOMER">Customer</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  id="description"
                  name="description"
                  label="Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  className={classes.formField}
                  size="small"
                  fullWidth
                />
              </FormControl>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.switches}
              >
                <Grid item>
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Toggle
                          checked={formik.values.required}
                          onChange={formik.handleChange}
                          id="required"
                          name="required"
                        />
                      }
                      label="Required"
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Toggle
                          checked={formik.values.active}
                          onChange={formik.handleChange}
                          id="active"
                          name="active"
                        />
                      }
                      label="Active"
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Toggle
                          checked={formik.values.hidePrint}
                          onChange={formik.handleChange}
                          id="hidePrint"
                          name="hidePrint"
                        />
                      }
                      label="Force Print"
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Toggle
                          checked={formik.values.isHidden}
                          onChange={formik.handleChange}
                          id="isHidden"
                          name="isHidden"
                        />
                      }
                      label="Hidden"
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Toggle
                          checked={formik.values.isLockedSubmitted}
                          onChange={formik.handleChange}
                          id="isLockedSubmitted"
                          name="isLockedSubmitted"
                        />
                      }
                      label="Locked Submitted"
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Toggle
                          checked={formik.values.locked}
                          onChange={formik.handleChange}
                          id="locked"
                          name="locked"
                        />
                      }
                      label="Locked Selected"
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Toggle
                          checked={formik.values.isDeleted}
                          onChange={formik.handleChange}
                          id="isDeleted"
                          name="isDeleted"
                        />
                      }
                      label="Deleted"
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Toggle
                          checked={formik.values.branded}
                          onChange={formik.handleChange}
                          id="branded"
                          name="branded"
                        />
                      }
                      label="Branded"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12} md={4}>
              <Typography variant="h6" gutterBottom>
                Layout
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Input Type</InputLabel>
                    <Select
                      id="type"
                      name="type"
                      value={formik.values.type}
                      onChange={formik.handleChange}
                      className={classes.formField}
                      error={formik.touched.type && Boolean(formik.errors.type)}
                      size="small"
                      fullWidth
                    >
                      <MenuItem value="DROPDOWN">Dropdown</MenuItem>
                      <MenuItem value="CHECKBOX">Checkbox</MenuItem>
                      <MenuItem value="TEXT">Text</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Input Layout</InputLabel>
                    <Select
                      id="inputLayout"
                      name="inputLayout"
                      label="Input Layout"
                      value={formik.values.inputLayout}
                      onChange={formik.handleChange}
                      className={classes.formField}
                      error={
                        formik.touched.inputLayout &&
                        Boolean(formik.errors.inputLayout)
                      }
                      size="small"
                      fullWidth
                    >
                      <MenuItem value="NORMAL">Normal</MenuItem>
                      <MenuItem value="GRID">Grid</MenuItem>
                      <MenuItem value="HERO">Hero</MenuItem>
                      <MenuItem value="INTERACTIVETOOLTIP">Modal</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl fullWidth>
                <InputLabel>Overlay Image</InputLabel>
                <Select
                  id="overlayImage"
                  name="overlayImage"
                  label="Overlay Image"
                  value={formik.values.overlayImage}
                  onChange={formik.handleChange}
                  className={classes.formField}
                  size="small"
                  fullWidth
                >
                  <MenuItem value="NA">N/A</MenuItem>
                  <MenuItem value="BOAT">Boat</MenuItem>
                  <MenuItem value="BENCH">Bench</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  id="imageOrder"
                  name="imageOrder"
                  label="Image Order"
                  value={formik.values.imageOrder}
                  onChange={formik.handleChange}
                  className={classes.formField}
                  error={
                    formik.touched.imageOrder && Boolean(formik.errors.imageOrder)
                  }
                  helperText={formik.touched.imageOrder && formik.errors.imageOrder}
                  variant="standard"
                  size="small"
                  fullWidth
                />
              </FormControl>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="page-group-select-label">Page Group</InputLabel>
                    <Select
                      id="pageGroupId"
                      name="pageGroupId"
                      labelId="page-group-select-label"
                      label="Page Group"
                      value={formik.values.pageGroupId}
                      onChange={formik.handleChange}
                      className={classes.formField}
                      size="small"
                    >
                      <MenuItem value="NA">N/A</MenuItem>
                      {pageGroups.map((pg) => (
                        <MenuItem key={pg.id} value={pg.id}>
                          {pg.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <TextField
                      id="inputOrder"
                      name="inputOrder"
                      label="Input Order"
                      value={formik.values.inputOrder}
                      onChange={formik.handleChange}
                      className={classes.formField}
                      error={
                        formik.touched.inputOrder &&
                        Boolean(formik.errors.inputOrder)
                      }
                      helperText={
                        formik.touched.inputOrder && formik.errors.inputOrder
                      }
                      variant="standard"
                      size="small"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <TextField
                      id="printOrder"
                      name="printOrder"
                      label="Print Order"
                      value={formik.values.printOrder}
                      onChange={formik.handleChange}
                      className={classes.formField}
                      error={
                        formik.touched.printOrder &&
                        Boolean(formik.errors.printOrder)
                      }
                      helperText={
                        formik.touched.printOrder && formik.errors.printOrder
                      }
                      size="small"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12} md={4}>
              <Typography variant="h6" gutterBottom>
                Epicor Fields
              </Typography>
              <FormControl fullWidth>
                <TextField
                  id="epicorInputName"
                  name="epicorInputName"
                  label="Input Name"
                  value={formik.values.epicorInputName}
                  onChange={formik.handleChange}
                  className={classes.formField}
                  error={
                    formik.touched.epicorInputName &&
                    Boolean(formik.errors.epicorInputName)
                  }
                  helperText={
                    formik.touched.epicorInputName && formik.errors.epicorInputName
                  }
                  size="small"
                  fullWidth
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel>Input Type</InputLabel>
                <Select
                  id="epicorInputType"
                  name="epicorInputType"
                  label="Input Type"
                  value={formik.values.epicorInputType}
                  onChange={formik.handleChange}
                  className={classes.formField}
                  size="small"
                  fullWidth
                >
                  <MenuItem value="">N/A</MenuItem>
                  <MenuItem value="CHARACTER">CHARACTER</MenuItem>
                  <MenuItem value="CHECKBOX">CHECKBOX</MenuItem>
                  <MenuItem value="COMBOBOX">COMBOBOX</MenuItem>
                  <MenuItem value="DECIMAL">DECIMAL</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  id="epicorPriceBoxName"
                  name="epicorPriceBoxName"
                  label="Price Box Name"
                  value={formik.values.epicorPriceBoxName}
                  onChange={formik.handleChange}
                  className={classes.formField}
                  error={
                    formik.touched.epicorPriceBoxName &&
                    Boolean(formik.errors.epicorPriceBoxName)
                  }
                  helperText={
                    formik.touched.epicorPriceBoxName &&
                    formik.errors.epicorPriceBoxName
                  }
                  size="small"
                  variant="standard"
                  fullWidth
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  id="epicorConfigPage"
                  name="epicorConfigPage"
                  label="Config Page"
                  value={formik.values.epicorConfigPage}
                  onChange={formik.handleChange}
                  className={classes.formField}
                  error={
                    formik.touched.epicorConfigPage &&
                    Boolean(formik.errors.epicorConfigPage)
                  }
                  helperText={
                    formik.touched.epicorConfigPage && formik.errors.epicorConfigPage
                  }
                  size="small"
                  variant="standard"
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button color="primary" variant="contained" fullWidth type="submit">
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

export default EditInput;
