import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Loading';
import EnhancedTable from '../../../../components/EnhancedTable';
import AddPage from '../../../../components/forms/AddPage';

const Pages = () => {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openAddPage, setOpenAddPage] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const { data } = await axios.get('/api/pages/options');
        let pages = data.pages.map((page) => {
          return {
            id: page.id,
            name: page.name,
            inputs: page.inputs.length,
            pageGroups: page.pageGroups.length,
            active: !page.isDeleted,
          };
        });
        setPages(pages);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchPages();
  }, []);

  const handleOpenAddPage = () => {
    setOpenAddPage(true);
  };

  const handleCloseAddPage = () => {
    setOpenAddPage(false);
  };

  const handleEditPage = (id) => {
    navigate(`/settings/form/pages/${id}`);
  };

  return (
    <Grid container justifyContent="center">
      {loading ? (
        <Loading />
      ) : (
        <Grid item lg={8} md={12}>
          <EnhancedTable
            title="Pages"
            data={pages}
            addData={handleOpenAddPage}
            openEditData={handleEditPage}
            hiddenColumns={['id']}
            initRowsPerPage={10}
            orderBy={'active'}
            order={'desc'}
            disableSelectAll={true}
          />

          <Dialog
            open={openAddPage}
            onClose={handleOpenAddPage}
            fullWidth
            maxWidth="sm"
            aria-labelledby="add-page-group"
          >
            <DialogTitle id="form-add-page">New Page</DialogTitle>
            <DialogContent>
              <AddPage closeAdd={handleCloseAddPage} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAddPage} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      )}
    </Grid>
  );
};

export default Pages;
