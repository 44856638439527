import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';
import { DataGridPro } from '@mui/x-data-grid-pro';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import LoadingButton from '@mui/lab/LoadingButton';
import collection from 'lodash/collection';

const FloorplanList = ({ floorplans, csvs, selectedCSV, models, modelId }) => {
  const [selectedFloorplans, setSelectedFloorplans] = useState([]);
  const [loadingCreateCSV, setLoadingCreateCSV] = useState(false);

  useEffect(() => {
    const foundCSV = csvs.find((csv) => csv.id === selectedCSV);
    let newSelectedFloorplans = [];
    if (foundCSV?.rows) {
      for (const row of foundCSV.rows) {
        const foundFP = floorplans.inputOptions.find(
          (io) => io.avalonName === row[1]
        );
        if (foundFP !== undefined) newSelectedFloorplans.push(foundFP.id);
      }
    }
    setSelectedFloorplans(newSelectedFloorplans);
  }, [selectedCSV]);

  const handleCreateCSV = async () => {
    let model = models.inputOptions.find((io) => io.id === +modelId);
    let floorplansByName = [];
    for (const fp of selectedFloorplans) {
      let foundFloorplan = floorplans.inputOptions.find((io) => io.id === fp);
      if (foundFloorplan !== undefined)
        floorplansByName.push(foundFloorplan.avalonName);
    }

    let data = {
      model: model.avalonName,
      floorplans: collection.sortBy(floorplansByName),
    };
    try {
      setLoadingCreateCSV(true);
      axios({
        url: '/api/rules/csv/create',
        method: 'POST',
        responseType: 'blob',
        data,
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${model.avalonName}_csv_rules.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
      setLoadingCreateCSV(false);
    } catch (err) {
      setLoadingCreateCSV(false);
      console.log(err.message);
    }
  };

  return (
    <Grid item xs={12}>
      <LoadingButton
        variant="contained"
        onClick={handleCreateCSV}
        loading={loadingCreateCSV}
        loadingPosition="start"
        startIcon={<SaveAltIcon />}
        sx={{ my: 2 }}
      >
        Create CSV
      </LoadingButton>
      {floorplans?.inputOptions?.length > 0 && (
        <DataGridPro
          autoHeight
          columns={[
            { field: 'id', headerName: 'Floorplan ID', width: 100 },
            { field: 'avalonName', headerName: 'Floorplan', width: 200 },
          ]}
          rows={floorplans.inputOptions}
          rowHeight={38}
          pagination
          pageSize={25}
          rowsPerPageOptions={[10, 25, 50]}
          checkboxSelection
          selectionModel={selectedFloorplans}
          onSelectionModelChange={(value) => {
            setSelectedFloorplans(value);
          }}
        />
      )}
    </Grid>
  );
};

export default FloorplanList;
