import React, { useState } from 'react';
import { TextField, FormControl, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
  name: yup.string('Please enter a name').required('Tag name is required'),
  value: yup.string().required(),
});

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const EditSetting = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const { closeAdd, settings, setSettings, setting } = props;

  const formik = useFormik({
    initialValues: {
      id: setting.id,
      name: setting.name,
      value: setting.value,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        await axios.put('/api/settings', values);
        setSettings(
          settings.map((s) => {
            if (s.id === setting.id) {
              return values;
            } else {
              return s;
            }
          })
        );
        setSubmitError(null);
        resetForm();
        closeAdd();
      } catch (err) {
        setSubmitError(err.response.data.message);
      }
    },
  });

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      {submitError !== null ? (
        <h3 style={{ color: '#f44336', marginTop: '0px' }}>{submitError}</h3>
      ) : null}
      <FormControl fullWidth>
        <TextField
          id="name"
          name="name"
          label="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          className={classes.textField}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          fullWidth
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="value"
          name="value"
          label="Value"
          value={formik.values.value}
          onChange={formik.handleChange}
          className={classes.textField}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          fullWidth
        />
      </FormControl>
      <Button color="primary" variant="contained" fullWidth type="submit">
        Submit
      </Button>
    </form>
  );
};

export default EditSetting;
