import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../userContext';
import axios from 'axios';
import { Box, Container, Typography, CircularProgress } from '@mui/material';
import PublicLayout from './Components/PublicLayout';
import PrivateLayout from './Components/PrivateLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { parseBrand } from '../../lib';

const Layout = () => {
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();
  const customerOrderFormRe = new RegExp('/order/new/c/(a|t)');
  const match = customerOrderFormRe.test(pathname);
  const rebateRe = new RegExp('/rebate/([0-9]+)/([0-9a-zA-Z]+)');
  const rebateMatch = rebateRe.test(pathname);
  const boatShowRe = new RegExp('/boat-show');
  const boatShowMatch = boatShowRe.test(pathname);
  const [customerOrderForm, setCustomerOrderForm] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const { data } = await axios.get('/api/refresh_token');
        const user = data.user;
        user.isAuthenticated = true;
        const brand =
          user.dealership !== null ? parseBrand(user.dealership.brand) : 'a';
        user.orderFormPath =
          user.dealership !== null ? `/order/new/d/${brand}` : null;
        user.customerOrderFormPath =
          user.dealership !== null ? `/order/new/cd/${brand}` : null;
        user.customerIds = [];

        if (user.role >= 10) {
          let tmpIds = [];
          for (let location of user.locations) {
            for (let cIds of location.location.customerIds) {
              if (tmpIds.indexOf(cIds.customerId) === -1)
                tmpIds.push(cIds.customerId);
            }
          }
          user.customerIds = tmpIds;
        }
        userContext.userDispatch({ type: 'login', user });
        // console.log(user.permissions);
        setLoading(false);
      } catch (err) {
        if (
          !match &&
          !rebateMatch &&
          !boatShowMatch &&
          pathname !== '/reset-password'
        )
          navigate('/login');
        setLoading(false);
      }
    };

    if (match || rebateMatch) {
      setCustomerOrderForm(true);
    } else {
      setCustomerOrderForm(false);
    }
    verifyUser();
  }, []);

  if (loading)
    return (
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress
              size={120}
              sx={{ margin: 'auto', marginTop: 4 }}
              disableShrink
            />
          </Box>
        </Container>
      </Box>
    );

  return userContext.userState.me.isAuthenticated ? (
    <PrivateLayout customerOrderForm={customerOrderForm} />
  ) : (
    <PublicLayout customerOrderForm={customerOrderForm} />
  );
};

export default Layout;
