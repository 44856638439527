import { Grid, Paper, Typography } from '@mui/material';

const NotFound = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={6}>
        <Typography variant="h2">404 Not Found</Typography>
      </Grid>
    </Grid>
  );
};

export default NotFound;
