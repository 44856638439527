import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import EditCommunicationEntry from '../../forms/EditCommunicationEntry';

const EditCommunicationEntryModal = ({
  show,
  handleClose,
  entry,
  communicationList,
  setCommunicationList,
}) => {
  return (
    <Dialog
      open={show}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="edit-location"
    >
      <DialogTitle>Edit Entry</DialogTitle>
      <DialogContent>
        <EditCommunicationEntry
          entry={entry}
          communicationList={communicationList}
          setCommunicationList={setCommunicationList}
          closeEdit={handleClose}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCommunicationEntryModal;
