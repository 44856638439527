import React, { useState, useEffect } from 'react';
import { Grid, Paper, Tabs, Tab, Typography } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import ModelSelect from './Components/ModelSelect';
import Loading from '../../../../components/Loading';
import axios from 'axios';
import CSVSelect from './Components/CSVSelect';
import FloorplanList from './Components/FloorplanList';
import CSVDisplay from './Components/CSVDisplay';
import CSVErrors from './CSVErrors';

const CSV = () => {
  const { modelId, floorplanId } = useParams();
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [floorplans, setFloorplans] = useState([]);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [inputs, setInputs] = useState([]);
  const [inputsWithRules, setInputsWithRules] = useState([]);
  const [inputWithRules, setInputWithRules] = useState('');
  const [selectedInputWithRules, setSelectedInputWithRules] = useState([]);
  const [viewActiveRules, setViewActiveRules] = useState(false);
  const [csvs, setCSVs] = useState([]);
  const [selectedCSV, setSelectedCSV] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const loadModels = async () => {
      const { data: model } = await axios.get('/api/input', { params: { id: 1 } });
      setModels(model.input);
      if (modelId !== undefined) setSelectedModel(modelId);
      const { data: floorplan } = await axios.get('/api/input', {
        params: { id: 2 },
      });
      if (floorplan?.input) setFloorplans(floorplan.input);
    };

    const loadInputs = async () => {
      const { data } = await axios.get('/api/inputs');
      setInputs(data.inputs);
    };

    loadModels();
    loadInputs();
  }, []);

  useEffect(() => {
    const loadModelCSVs = async () => {
      const { data } = await axios.get('/api/rules/model/csvs', {
        params: { modelId: modelId },
      });

      if (data.csvs) {
        let tmpCSVs = data.csvs.map((csv) => {
          return {
            ...csv,
            ruleErrors: [],
          };
        });
        setCSVs(tmpCSVs);
        const activeCSV = tmpCSVs.find((csv) => csv.active === true);
        if (activeCSV) setSelectedCSV(activeCSV.id);
      }
      setLoadingCSV(false);
    };

    if (modelId !== undefined) {
      setLoadingCSV(true);
      loadModelCSVs();
    } else {
      setCSVs([]);
      setSelectedModel('');
      setSelectedCSV('');
    }
  }, [modelId]);

  useEffect(() => {
    const loadCSV = async () => {
      try {
        const { data } = await axios.get('/api/rules/csv', {
          params: { csvId: selectedCSV },
        });
        if (data.csv !== null) {
          setCSVs(
            csvs.map((csv) => {
              if (csv.id === selectedCSV) {
                return data.csv;
              } else {
                return csv;
              }
            })
          );
        }
        setLoadingCSV(false);
      } catch (err) {
        console.log(err.message);
        setLoadingCSV(false);
      }
    };

    if (selectedCSV !== '') {
      const foundCSV = csvs.find((csv) => csv.id === selectedCSV);
      if (!foundCSV?.head || !foundCSV?.rows) {
        setLoadingCSV(true);
        loadCSV();
      }
    } else {
      setLoadingCSV(false);
    }
  }, [selectedCSV]);

  const handleTabSelect = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSelectCSV = (csvId) => {
    setSelectedCSV(csvId);
    if (csvId === '' || csvId === csvs.find((csv) => csv.active)?.id)
      setSelectedTab(0);
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item container xs={12}>
          <Grid item xs={12} sm={6}>
            <ModelSelect
              models={models}
              selectedModel={selectedModel}
              setSelectedModel={setSelectedModel}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CSVSelect
              csvs={csvs}
              setCSVs={setCSVs}
              selectedCSV={selectedCSV}
              handleSelectCSV={handleSelectCSV}
              modelId={selectedModel}
              setLoadingCSV={setLoadingCSV}
              handleTabSelect={handleTabSelect}
            />
          </Grid>
        </Grid>
        {loadingCSV && <Loading />}

        {csvs.find((csv) => csv.id === selectedCSV) !== undefined && (
          <CSVErrors
            csv={csvs.find((csv) => csv.id === selectedCSV)}
            isLoading={loadingCSV}
          />
        )}

        {!loadingCSV && selectedModel !== '' && (
          <Grid item xs={12}>
            <Tabs value={selectedTab} onChange={handleTabSelect}>
              <Tab label="Floorplan" />
              {selectedCSV !== '' &&
                !loadingCSV &&
                selectedCSV !== csvs.find((csv) => csv.active)?.id && (
                  <Tab label="CSV" />
                )}
            </Tabs>
            {selectedTab === 0 && (
              <FloorplanList
                floorplans={floorplans}
                csvs={csvs}
                selectedCSV={selectedCSV}
                models={models}
                modelId={modelId}
              />
            )}
            {selectedTab === 1 && csvs.length > 1 && (
              <CSVDisplay
                csvs={csvs}
                selectedCSV={selectedCSV}
                loadingCSV={loadingCSV}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default CSV;
