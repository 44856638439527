import React from 'react';
import clsx from 'clsx';
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  Grid,
  IconButton,
  Typography,
  Button,
  useMediaQuery,
  Checkbox as CheckboxInput,
  FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import SmsFailedOutlinedIcon from '@mui/icons-material/SmsFailedOutlined';
import { useParams } from 'react-router-dom';
import theme from '../../../theme';
import { parseBrandName } from '../../../lib';

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2),
  },
  infoButton: {
    color: '#747679',
    '& svg': {
      fontSize: '20px',
    },
  },
  inputLabel: {
    display: 'flex',
    left: '8px',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: '#171A20',
  },
  mobileLabel: {
    fontSize: theme.spacing(2),
  },
  selectedInputLabel: {
    display: 'flex',
    left: '58px',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: '#171A20',
  },
  required: {
    fontSize: '14px',
    marginLeft: '5px',
  },
  thumbnail: {
    marginRight: '10px',
    width: '40px',
    height: '40px',
    borderRadius: '100%',
  },
  dropdownBtn: {
    background: '#006991',
    color: '#fff',
    '&:hover': {
      background: '#0c4054',
    },
    position: 'absolute',
    right: '12px',
    top: 'calc(45% - 0.5em)',
    padding: 0,
    fontSize: '12px',
    borderRadius: '12px',
    pointerEvents: 'none',
  },
  mobileBtn: {
    top: 'unset',
    right: 'unset',
    left: theme.spacing(2.5),
    bottom: '1rem',
  },
}));

const Select = styled(MuiSelect)(({ theme }) => ({
  color: '#747679',
  fontSize: '14px',
  borderRadius: '16px',
  '& .MuiSelect-select': {
    display: 'flex',
    paddingTop: '30px',
    paddingLeft: '20px',
  },
  '&.mobile': {
    whiteSpace: 'unset',
  },
  '&.mobile .MuiSelect-select': {
    paddingBottom: theme.spacing(5),
    whiteSpace: 'unset',
  },
  '& .MuiSelect-select p:last-child': {
    marginLeft: '10px',
  },
  '& .MuiSelect-select p.mobile': {
    marginLeft: '0',
  },
  '&.has-image .MuiSelect-select p': {
    marginLeft: '50px',
  },
  '& .MuiSelect-select img': {
    position: 'absolute',
    top: '10px',
  },
  '&:before': {
    display: 'none',
  },
  '&:after': {
    display: 'none',
  },
}));

const InterativeDropdown = (props) => {
  const {
    input,
    handleInputChange,
    handleOpenTooltip,
    formatPrice,
    showHidden,
    brand,
    orderStatus,
    role,
    customerBuild,
    hasTwin,
    canEditLockedInputs,
    calculatePriceWithMarkup,
    modelId,
  } = props;
  const classes = useStyles();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const { formType } = useParams();

  const getThumbnailImage = (io) => {
    if (io && io.thumbnailImgPath !== null && io.thumbnailImgPath !== '') {
      return (
        <img
          className={classes.thumbnail}
          src={`https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/images/${io.thumbnailImgPath}`}
          height="80"
          alt={io.avalonName}
        />
      );
    } else {
      return null;
    }
  };

  const selectedOptionImage = () => {
    if (input.value) {
      const selectedOption = input.inputOptions.find((io) => io.id === input.value);
      return getThumbnailImage(selectedOption);
    }
    return null;
  };

  const dropdownExpandIcon = () => (
    <Button className={clsx(classes.dropdownBtn, { [classes.mobileBtn]: isMobile })}>
      Select
    </Button>
  );

  const brandedInputOptionName = (io) => {
    if (input.branded) {
      if (input.id === 249) return parseBrandName(brand, io);
      return brand === 't' ? io.tahoeName : io.avalonName;
    } else {
      return io.avalonName;
    }
  };

  const getTwinMotorName = (input) => {
    let name = '';
    if (input.value) {
      let foundOption = input.inputOptions.find((io) => io.id === input.value);
      if (foundOption.twin.name) name = foundOption.twin.name;
    }
    return name;
  };

  if (input.id === 6 && hasTwin) {
    return (
      <div style={{ display: input.isHidden && !showHidden ? 'none' : 'block' }}>
        <Grid container sx={{ mb: 2 }}>
          <Grid item container xs={12}>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
              Twin Motors
            </Typography>
            {input.inputOptions
              .filter((io) => io.active)
              .map((io) => (
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
                  key={`${io.id}_twin_motor`}
                >
                  <FormControlLabel
                    control={
                      <CheckboxInput
                        checked={input.value === io.id}
                        sx={{
                          '&.Mui-checked': {
                            color: '#006991',
                          },
                        }}
                        onChange={(e) =>
                          handleInputChange(
                            input.value === io.id ? '' : io.id,
                            input
                          )
                        }
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          fontSize: io.avalonName.length > 40 ? '14px' : '16px',
                        }}
                      >
                        {io.avalonName}
                        <Typography
                          variant="caption"
                          sx={{ color: 'rgb(0, 105, 145)' }}
                        >
                          {' '}
                          {`${calculatePriceWithMarkup(io.price, +modelId)}`}
                        </Typography>
                        <br />
                        {io?.twin?.name}
                        {io?.twin?.price && (
                          <Typography
                            variant="caption"
                            sx={{ color: 'rgb(0, 105, 145)' }}
                          >
                            {' '}
                            {`${calculatePriceWithMarkup(io.price, +modelId)}`}
                          </Typography>
                        )}
                      </Typography>
                    }
                    sx={{ marginRight: '5px' }}
                  />
                  <Typography
                    sx={{
                      marginLeft: 'auto',
                      marginRight: '12px',
                    }}
                  >
                    {io?.twin?.mainPrice &&
                      `${calculatePriceWithMarkup(io.price, +modelId)}`}
                  </Typography>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div style={{ display: input.isHidden && !showHidden ? 'none' : 'block' }}>
      <Grid container>
        <Grid item xs={12}>
          <FormControl
            variant="filled"
            fullWidth
            className={classes.formField}
            error={input.required && input.value === ''}
          >
            <InputLabel
              className={clsx({
                [classes.selectedInputLabel]:
                  input.value &&
                  input.inputLayout === 'INTERACTIVETOOLTIP' &&
                  selectedOptionImage(),
                [classes.inputLabel]: !(
                  input.value &&
                  input.inputLayout === 'INTERACTIVETOOLTIP' &&
                  selectedOptionImage()
                ),
                [classes.mobileLabel]: isMobile,
              })}
            >
              {input.name}
              <small className={classes.required}>
                {input.required && input.value === '' ? ' *Required' : null}
              </small>
              {input.tooltip !== null ? (
                <IconButton
                  className={classes.infoButton}
                  size="small"
                  aria-label="Tooltip"
                  onClick={() => handleOpenTooltip(input)}
                >
                  <SmsFailedOutlinedIcon />
                </IconButton>
              ) : null}
            </InputLabel>
            <Select
              id={`${input.name}-select`}
              label={input.name}
              value={input.value}
              onChange={(e) => handleInputChange(e.target.value, input)}
              disabled={
                input.inputOptions.filter((io) => io.active).length === 0 ||
                (input.disabled && role === undefined) ||
                (input.disabled && role !== undefined && !canEditLockedInputs) ||
                (input.isLockedSubmitted &&
                  orderStatus >= 1 &&
                  role !== undefined &&
                  role >= 10) ||
                (input.locked &&
                  input.value !== '' &&
                  input.value !== null &&
                  !canEditLockedInputs) ||
                (orderStatus > 0 && input.id === 6 && !canEditLockedInputs) ||
                (orderStatus > 0 && input.id === 4 && !canEditLockedInputs)
              }
              error={input.required && input.value === ''}
              fullWidth
              IconComponent={dropdownExpandIcon}
              className={clsx({
                'has-image':
                  input.inputLayout === 'INTERACTIVETOOLTIP' &&
                  selectedOptionImage(),
                mobile: isMobile,
              })}
            >
              <MenuItem value="">Select Option</MenuItem>
              {input.inputOptions
                .filter((io) => io.active)
                .map((io) => {
                  return (
                    <MenuItem
                      key={io.id}
                      value={io.id}
                      sx={{
                        position: 'relative',
                      }}
                    >
                      {input.inputLayout === 'INTERACTIVETOOLTIP' &&
                        getThumbnailImage(io)}
                      {isMobile ? (
                        <Typography className="mobile">{`${brandedInputOptionName(
                          io
                        )} ${
                          input.id === undefined ||
                          input.id === 142 ||
                          input.id === 164
                            ? ''
                            : `${calculatePriceWithMarkup(io.price, +modelId)}`
                        }`}</Typography>
                      ) : (
                        <Typography>{brandedInputOptionName(io)}</Typography>
                      )}
                      {!isMobile && (
                        <Typography
                          sx={{
                            marginLeft: 'auto',
                          }}
                        >
                          {input.id === undefined ||
                          input.id === 142 ||
                          input.id === 164
                            ? ''
                            : `${calculatePriceWithMarkup(io.price, +modelId)}`}
                        </Typography>
                      )}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default InterativeDropdown;
