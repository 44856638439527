import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Grid, Paper } from '@mui/material';
import EnhancedTable from '../NewEnhancedTable';
import Loading from '../Loading';
import { UserContext } from '../../userContext';
import EditLocationModal from './Components/EditLocationModal';
import AddLocationModal from './Components/AddLocationModal';
import EditUserModal from './Components/EditUserModal';
import AddUserModal from './Components/AddUserModal';
import EditCommunicationEntryModal from './Components/EditCommunicationEntryModal';
import AddCommunicationEntryModal from './Components/AddCommunicationEntryModal';
import EditLocationMarkupAndDiscountModal from './Components/EditLocationMarkupAndDiscountModal';
import Header from './Components/Header';
import LocationList from './Components/LocationList';
import UserList from './Components/UserList';
import DisableDealershipModal from './Components/DisableDealershipModal';

const DealershipProfile = () => {
  const userContext = useContext(UserContext);
  const { dealershipId } = useParams();
  const [dealership, setDealership] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [editUser, setEditUser] = useState({});
  const [openAddLocation, setOpenAddLocation] = useState(false);
  const [openEditLocation, setOpenEditLocation] = useState(false);
  const [openEditLocationMarkupAndDiscount, setEditLocationMarkupAndDiscount] =
    useState(false);
  const [editLocation, setEditLocation] = useState({});
  const [editBrand, setEditBrand] = useState({});
  const [locationHiddenColumns, setLocationHiddenColumns] = useState([
    'dealershipId',
    'notificationList',
    'id',
    'customerIds',
    'atdealerDealershipId',
    'users',
    'website',
  ]);

  const [loadingList, setLoadingList] = useState(false);
  const [communicationList, setCommunicationList] = useState([]);
  const [openAddCommunicationEntry, setOpenAddCommunicationEntry] = useState(false);
  const [openEditCommunicationEntry, setOpenEditCommunicationEntry] =
    useState(false);
  const [editEntry, setEditEntry] = useState({});
  const [selectedTab, setSelectedTab] = useState('manage-dealership');
  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const [deleteUser, setDeleteUser] = useState({});
  const [openDisableDealership, setOpenDisableDealership] = useState(false);

  const handleOpenDisableDealership = () => {
    setOpenDisableDealership(true);
  };

  const handleCloseDisableDealership = () => {
    setOpenDisableDealership(false);
  };

  const handleOpenAddUser = () => {
    setOpenAddUser(true);
  };

  const handleCloseAddUser = () => {
    setOpenAddUser(false);
  };

  const handleOpenEditUser = (user) => {
    if (user) {
      setEditUser({ ...user });
    }
    setOpenEditUser(true);
  };

  const handleCloseEditUser = () => {
    setOpenEditUser(false);
  };

  const handleOpenAddLocation = () => {
    setOpenAddLocation(true);
  };

  const handleCloseAddLocation = () => {
    setOpenAddLocation(false);
  };

  const handleOpenEditLocation = (location) => {
    if (location) {
      setEditLocation({ ...location });
    }
    setOpenEditLocation(true);
  };

  const handleCloseEditLocation = () => {
    setOpenEditLocation(false);
  };

  const handleOpenEditLocationMarkupAndDiscount = (location) => {
    if (location) {
      setEditLocation({ ...location });
    }
    setEditLocationMarkupAndDiscount(true);
  };

  const handleCloseEditLocationMarkupAndDiscount = () => {
    setEditLocationMarkupAndDiscount(false);
  };

  const handleOpenAddCommunicationEntry = () => {
    setOpenAddCommunicationEntry(true);
  };

  const handleCloseAddCommunicationEntry = () => {
    setOpenAddCommunicationEntry(false);
  };

  const handleOpenEditCommunicationEntry = () => {
    setOpenEditCommunicationEntry(true);
  };

  const handleCloseEditCommunicationEntry = () => {
    setOpenEditCommunicationEntry(false);
  };

  const onLocationNotificationList = (notificationList, email) => {
    const allEmails = notificationList.split(',');

    return allEmails.indexOf(email) !== -1;
  };

  useEffect(() => {
    const fetchDealership = async () => {
      try {
        const { data } = await axios.get('/api/dealer', {
          params: { id: dealershipId },
        });
        const { data: activeModels } = await axios.get('/api/models/active', {
          params: { brand: data.brand },
        });
        setDealership({ ...data, activeModels });
        setEditBrand(data.brand);
        if (userContext.userState.me.role > 2)
          setLocationHiddenColumns([...locationHiddenColumns, 'disableConfirm']);

        setIsLoading(false);
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchDealership();
  }, []);

  useEffect(() => {
    const fetchCommunicationList = async () => {
      setLoadingList(true);
      try {
        const { data } = await axios.get('/api/dealers/communication/list', {
          params: { id: dealership.id },
        });
        setCommunicationList(data);
      } catch (err) {
        console.log(err.message);
      }
      setLoadingList(false);
    };
    if (selectedTab === 'communications') fetchCommunicationList();
  }, [selectedTab]);

  const handleBrandChange = async (value) => {
    try {
      let values = {
        dealershipId: dealership.id,
        brand: parseInt(value, 10),
      };

      const { data } = await axios.put('/api/dealer', values);
      setDealership({ ...dealership, brand: parseInt(value, 10) });
      setEditBrand(value);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleDeleteCommunicationEntries = async (values) => {
    try {
      await axios.post('/api/dealers/communication/users/remove', {
        entries: values,
      });
      let updatedCommunicationList = communicationList.filter(
        (entry) => values.indexOf(entry.id) === -1
      );
      setCommunicationList(updatedCommunicationList);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleDeactivate = async (id, type, all, isDeleted) => {
    if (all) {
      const enableFlag =
        dealership.locations.filter((location) => location.active).length === 0;

      for (let user of dealership.users) {
        try {
          await axios.put('/api/user/active', {
            user: {
              id: user.id,
              active: enableFlag,
            },
          });
        } catch (err) {
          console.log(err.message);
        }
      }

      for (let location of dealership.locations) {
        try {
          await axios.put('/api/location/active', {
            location: {
              id: location.id,
              active: enableFlag,
            },
          });
        } catch (err) {
          console.log(err.message);
        }
      }

      setDealership({
        ...dealership,
        users: dealership.users.map((user) => ({
          ...user,
          active: enableFlag,
        })),
        locations: dealership.locations.map((location) => ({
          ...location,
          active: enableFlag,
        })),
      });
      handleCloseDisableDealership();
    } else {
      if (type === 'user') {
        const foundUser = dealership.users.find((user) => user.id === id);
        try {
          if (isDeleted) {
            await axios.delete(`/api/user?id=${id}`);
          } else {
            await axios.put('/api/user/active', {
              user: {
                id: id,
                active: !foundUser.active,
              },
            });
          }

          const updatedUsers = dealership.users.map((user) => ({
            ...user,
            active: user.id === id ? !user.active : user.active,
          }));

          const filteredUsers = dealership.users.filter((user) => user.id !== id);

          // check for locations with notification lists
          const locationsToUpdate = [];
          setDealership({
            ...dealership,
            users: isDeleted ? filteredUsers : updatedUsers,
            locations: dealership.locations.map((location) => {
              const onList = onLocationNotificationList(
                location.notificationList,
                foundUser.email
              );
              let users = location.users;
              if (isDeleted) users = location.users.filter((u) => u.userId !== id);
              if (onList) {
                const updatedNotificationList = location.notificationList
                  .split(',')
                  .filter((e) => e !== foundUser.email && e !== 'undefined')
                  .join(',');
                locationsToUpdate.push({
                  id: location.id,
                  notificationList: updatedNotificationList,
                });
                return {
                  ...location,
                  notificationList: updatedNotificationList,
                  users,
                };
              } else {
                return { ...location, users };
              }
            }),
          });

          // only update locations if user is being deactivated
          if (locationsToUpdate.length > 0 && !foundUser.active) {
            try {
              await axios.put('/api/location/notification-list', {
                locations: locationsToUpdate,
              });
            } catch (err) {
              console.log(err.message);
            }
          }

          if (isDeleted) {
            setDeleteUser({});
            setOpenDeleteUser(false);
          }
        } catch (err) {
          console.log(err.message);
        }
      } else {
        const foundLocation = dealership.locations.find(
          (location) => location.id === id
        );
        try {
          await axios.put('/api/location/active', {
            location: {
              id: id,
              active: !foundLocation.active,
            },
          });
        } catch (err) {
          console.log(err.message);
        }

        setDealership({
          ...dealership,
          locations: dealership.locations.map((location) => ({
            ...location,
            active: location.id === id ? !location.active : location.active,
          })),
        });
      }
    }
  };

  return (
    <Grid container>
      {isLoading || selectedTab === '' ? (
        <Loading />
      ) : (
        <Grid item xs={12} lg={12}>
          <Paper sx={{ p: 2 }}>
            <Header
              userContext={userContext}
              dealership={dealership}
              editBrand={editBrand}
              handleBrandChange={handleBrandChange}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              permissions={userContext.userState.me.permissions}
              handleOpenDisableDealership={handleOpenDisableDealership}
            />
          </Paper>

          {selectedTab === 'manage-dealership' && (
            <Grid container>
              {userContext.userState.me.permissions.indexOf(
                'manage_dealer_users'
              ) !== -1 && (
                <UserList
                  handleOpenAddUser={handleOpenAddUser}
                  handleOpenEditUser={handleOpenEditUser}
                  handleDeactivate={handleDeactivate}
                  dealership={dealership}
                  permissions={userContext.userState.me.permissions}
                  myId={userContext.userState.me.id}
                  openDeleteUser={openDeleteUser}
                  setOpenDeleteUser={setOpenDeleteUser}
                  deleteUser={deleteUser}
                  setDeleteUser={setDeleteUser}
                />
              )}

              <LocationList
                dealership={dealership}
                setDealership={setDealership}
                handleOpenEditLocation={handleOpenEditLocation}
                handleOpenAddLocation={handleOpenAddLocation}
                handleOpenEditLocationMarkupAndDiscount={
                  handleOpenEditLocationMarkupAndDiscount
                }
                handleDeactivate={handleDeactivate}
                permissions={userContext.userState.me.permissions}
                onLocationNotificationList={onLocationNotificationList}
              />
            </Grid>
          )}

          <DisableDealershipModal
            show={openDisableDealership}
            dealership={dealership}
            handleClose={handleCloseDisableDealership}
            handleConfirmDisable={() => handleDeactivate(dealership.id, '', true)}
          />

          <AddUserModal
            show={openAddUser}
            dealership={dealership}
            setDealership={setDealership}
            handleClose={handleCloseAddUser}
          />

          <EditUserModal
            show={openEditUser}
            handleClose={handleCloseEditUser}
            user={editUser}
            dealership={dealership}
            setDealership={setDealership}
          />

          <EditLocationModal
            show={openEditLocation}
            handleClose={handleCloseEditLocation}
            users={dealership.users}
            dealership={dealership}
            setDealership={setDealership}
            location={editLocation}
          />

          <EditLocationMarkupAndDiscountModal
            show={openEditLocationMarkupAndDiscount}
            handleClose={handleCloseEditLocationMarkupAndDiscount}
            dealership={dealership}
            setDealership={setDealership}
            location={editLocation}
          />

          <AddLocationModal
            show={openAddLocation}
            users={dealership.users}
            dealership={dealership}
            setDealership={setDealership}
            handleClose={handleCloseAddLocation}
          />

          {selectedTab === 'communications' && loadingList && <Loading />}
          {selectedTab === 'communications' && !loadingList && (
            <Paper sx={{ p: 2, mt: 2 }}>
              <EnhancedTable
                title="Communication List"
                data={communicationList}
                addData={handleOpenAddCommunicationEntry}
                openEditData={handleOpenEditCommunicationEntry}
                setEditData={setEditEntry}
                handleDeleteMultiple={handleDeleteCommunicationEntries}
                dense
                hiddenColumns={['id', 'dealershipId']}
                elevation={0}
              />
            </Paper>
          )}

          <EditCommunicationEntryModal
            show={openEditCommunicationEntry}
            handleClose={handleCloseEditCommunicationEntry}
            entry={editEntry}
            communicationList={communicationList}
            setCommunicationList={setCommunicationList}
          />

          <AddCommunicationEntryModal
            show={openAddCommunicationEntry}
            handleClose={handleCloseAddCommunicationEntry}
            dealershipId={dealership.id}
            communicationList={communicationList}
            setCommunicationList={setCommunicationList}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DealershipProfile;
