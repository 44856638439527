import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  IconButton,
  TextField,
  Tabs,
  Tab,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import EnhancedTable from '../../../../components/EnhancedTable';
import AddInput from '../../../../components/forms/AddInput';
import Loading from '../../../../components/Loading';
import AddPageGroup from '../../../../components/forms/AddPageGroup';
import EditPageGroup from '../../../../components/forms/EditPageGroup';
import Nav from './Nav';
import {
  Edit as EditIcon,
  Check as SaveIcon,
  NotInterested as DisabledIcon,
} from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1, 2, 0, 2),
    margin: theme.spacing(2, 0),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0px',
  },
  button: {
    marginRight: theme.spacing(2),
  },
  buttonRight: {
    marginLeft: theme.spacing(2),
  },
}));

const Page = () => {
  let navigate = useNavigate();
  const initHiddenColumns = [
    'pageGroupId',
    'id',
    'pageId',
    'branded',
    'locked',
    'inputOptions',
    'isDeleted',
    'isHidden',
    'description',
    'required',
    'hidePrint',
  ];
  const classes = useStyles();
  const { pageId } = useParams();
  const [page, setPage] = useState({});
  const [openAddInput, setOpenAddInput] = useState(false);
  const [openAddPageGroup, setOpenAddPageGroup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [openEditPageGroup, setOpenEditPageGroup] = useState(false);
  const [editPageGroup, setEditPageGroup] = useState(null);
  const [editPageName, setEditPageName] = useState(false);
  const [pageName, setPageName] = useState('');
  const [pageActive, setPageActive] = useState(true);
  const [routes, setRoutes] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [viewIsDeleted, setViewIsDeleted] = useState(false);

  const handleOpenAddInput = () => {
    setOpenAddInput(true);
  };

  const handleCloseAddInput = () => {
    setOpenAddInput(false);
  };

  const handleOpenAddPageGroup = () => {
    setOpenAddPageGroup(true);
  };

  const handleCloseAddPageGroup = () => {
    setOpenAddPageGroup(false);
  };

  const handleOpenEditPageGroup = () => {
    setOpenEditPageGroup(true);
  };

  const handleCloseEditPageGroup = () => {
    setOpenEditPageGroup(false);
  };

  const addToObject = (obj, key, value, index) => {
    // Create a temp object and index variable
    let temp = {};
    let i = 0;
    // Loop through the original object
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        // If the indexes match, add the new item
        if (i === index && key && value) {
          temp[key] = value;
        }
        // Add the current item in the loop to the temp obj
        temp[prop] = obj[prop];
        // Increase the count
        i++;
      }
    }
    // If no index, add to the end
    if (!index && key && value) {
      temp[key] = value;
    }
    return temp;
  };

  useEffect(() => {
    const loadPage = async () => {
      try {
        const { data } = await axios.get('/api/page', {
          params: { id: pageId, isDeleted: viewIsDeleted },
        });
        data.page.inputs.filter((input) => input.overlayImage !== null).length === 0
          ? setHiddenColumns([...initHiddenColumns, 'overlayImage'])
          : setHiddenColumns([...initHiddenColumns]);
        data.page.inputs = data.page.inputs.map((input) => {
          if (input.pageGroupId !== null) {
            return addToObject(
              input,
              'pageGroup',
              data.page.pageGroups.find((p) => p.id === input.pageGroupId).name,
              11
            );
          } else {
            return addToObject(input, 'pageGroup', 'N/A', 11);
          }
        });
        setPage(data.page);
        setPageName(data.page.name);
        setPageActive(!data.page.isDeleted);
        setRoutes([
          { name: 'Pages', path: '/settings/form/pages' },
          { name: data.page.name, path: `/settings/form/pages/${data.page.id}` },
        ]);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
      }
    };
    setLoading(true);
    loadPage();
  }, [pageId, viewIsDeleted]);

  const navigateToInput = (id) => {
    navigate(`input/${id}`);
  };

  const handleEditPageName = async () => {
    try {
      await axios.put('/api/page', { id: pageId, name: pageName });
      setPage({ ...page, name: pageName });
      setEditPageName(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handlePageActive = async () => {
    try {
      await axios.put('/api/page', { id: pageId, isDeleted: pageActive });
      setPage({ ...page, isDeleted: pageActive });
      setPageActive(!pageActive);
      setEditPageName(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Nav routes={routes} />
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h1 className={classes.title}>
                  <span>
                    {!editPageName ? (
                      <>
                        {page.name}
                        <IconButton
                          color="default"
                          aria-label="Edit"
                          onClick={() => setEditPageName(!editPageName)}
                        >
                          <EditIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <TextField
                          id="edit-page-title"
                          value={pageName}
                          variant="outlined"
                          size="small"
                          onChange={(e) => setPageName(e.target.value)}
                        />
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          startIcon={<SaveIcon />}
                          onClick={() => handleEditPageName()}
                          className={classes.buttonRight}
                        >
                          Save
                        </Button>
                      </>
                    )}
                  </span>

                  {pageActive ? (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      startIcon={<SaveIcon />}
                      onClick={() => handlePageActive(!pageActive)}
                    >
                      Active
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      color="warning"
                      startIcon={<DisabledIcon />}
                      onClick={() => handlePageActive(!pageActive)}
                    >
                      Disabled
                    </Button>
                  )}
                </h1>
                <br />
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setViewIsDeleted(!viewIsDeleted)}
                >
                  {viewIsDeleted ? 'View Inputs' : 'View Deleted'}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Tabs
                  value={tabValue}
                  indicatorColor="primary"
                  onChange={(e, newValue) => setTabValue(newValue)}
                >
                  <Tab label="Inputs" />
                  <Tab label="Page Groups" />
                </Tabs>
                {tabValue === 0 ? (
                  <EnhancedTable
                    title="Inputs"
                    data={page.inputs}
                    addData={handleOpenAddInput}
                    hiddenColumns={hiddenColumns}
                    disableSelectAll={true}
                    openEditData={navigateToInput}
                    enableFilters={true}
                    filterTypes={['disabled', 'name', 'pageGroupId']}
                    nameSearchField={'name'}
                    orderBy="inputOrder"
                    elevation={0}
                  />
                ) : (
                  <EnhancedTable
                    title="Page Groups"
                    data={page.pageGroups}
                    addData={handleOpenAddPageGroup}
                    openEditData={handleOpenEditPageGroup}
                    setEditData={setEditPageGroup}
                    hiddenColumns={['id', 'pageId']}
                    disableSelectAll={true}
                    orderBy="pageOrder"
                    elevation={0}
                    initRowsPerPage={5}
                  />
                )}
              </Grid>
            </Grid>

            <Dialog
              open={openAddInput}
              onClose={handleCloseAddInput}
              fullWidth
              maxWidth="sm"
              aria-labelledby="add-input"
            >
              <DialogTitle id="form-add-input">New Input</DialogTitle>
              <DialogContent>
                <AddInput
                  page={page}
                  setPage={setPage}
                  closeAdd={handleCloseAddInput}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAddInput} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openAddPageGroup}
              onClose={handleCloseAddPageGroup}
              fullWidth
              maxWidth="sm"
              aria-labelledby="add-page-group"
            >
              <DialogTitle id="form-add-page-group">New Page Group</DialogTitle>
              <DialogContent>
                <AddPageGroup
                  pageId={page.id}
                  page={page}
                  setPage={setPage}
                  closeAdd={handleCloseAddPageGroup}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAddPageGroup} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openEditPageGroup}
              onClose={handleCloseEditPageGroup}
              fullWidth
              maxWidth="sm"
              aria-labelledby="add-page-group"
            >
              <DialogTitle id="form-edit-page-group">Edit Page Group</DialogTitle>
              <DialogContent>
                <EditPageGroup
                  page={page}
                  setPage={setPage}
                  closeAdd={handleCloseEditPageGroup}
                  pageGroup={editPageGroup}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseEditPageGroup} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </div>
      )}
    </>
  );
};

export default Page;
