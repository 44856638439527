import React, { useState, useEffect } from 'react';
import { Typography, Tabs, Tab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { useNavigate, useParams } from 'react-router-dom';

const AntTabs = withStyles((theme) => ({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(1),
  },
}));

const OrdersByStatusNav = () => {
  const classes = useStyles();
  const { status } = useParams();
  const [value, setValue] = useState(status);
  const navigate = useNavigate();
  const [allowRebate, setAllowRebate] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/orders/${newValue}`);
  };

  useEffect(() => {
    const verifyStatus = async () => {
      const response = await fetch('/api/settings/rebates');
      const data = await response.json();
      setAllowRebate(data.allowed);
      setLoading(false);
    };

    verifyStatus();
  }, []);

  return (
    <div className={classes.root}>
      {!loading && (
        <AntTabs
          value={value}
          onChange={handleChange}
          aria-label="orders by status nav"
        >
          <AntTab label="Leads" value={'leads'} />
          <AntTab label="Quote" value={'quote'} />
          {/* <AntTab label="Submitted" value={'submitted'} /> */}
          <AntTab label="Submitted - Not Verified" value={'submittednv'} />
          <AntTab label="Submitted - Verified" value={'submittedv'} />
          <AntTab label="Confirmed" value={'confirmed'} />
          <AntTab label="Complete" value={'complete'} />
          {allowRebate && <AntTab label="Rebates" value={'rebates'} />}
        </AntTabs>
      )}

      <Typography className={classes.padding} />
    </div>
  );
};

export default OrdersByStatusNav;
