import React, { useState } from 'react';
import {
  TextField,
  FormControl,
  Button,
  FormControlLabel,
  Switch as Toggle,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DropzoneArea } from 'material-ui-dropzone';

const validationSchema = yup.object({
  title: yup.string('Please enter a title').required('Title is required'),
  text: yup.string(),
  active: yup.boolean(),
  isFaq: yup.boolean(),
});

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const AddNotification = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const { closeAdd, notifications, setNotifications, type } = props;
  const [files, setFiles] = useState([]);

  const handleFileChange = (files) => {
    console.log('Files: ', files);
    setFiles(files);
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      text: '',
      active: false,
      isFaq: type === 'faqs' ? true : false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      try {
        let formData = new FormData();
        if (files.length > 0) formData.append('file', files[0], files[0].name);
        formData.append('title', values.title);
        formData.append('text', values.text);
        formData.append('active', values.active);
        formData.append('isFaq', values.isFaq);
        const { data } = await axios.post('/api/notifications', formData);

        setNotifications([...notifications, data.notification]);
        setSubmitError(null);
        setFiles([]);
        resetForm();
        closeAdd();
      } catch (err) {
        setSubmitError(err.response.data.message);
      }
    },
  });

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      {submitError !== null ? (
        <h3 style={{ color: '#f44336', marginTop: '0px' }}>{submitError}</h3>
      ) : null}
      <FormControl fullWidth>
        <TextField
          id="title"
          name="title"
          label="Title"
          value={formik.values.title}
          onChange={formik.handleChange}
          className={classes.textField}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
          fullWidth
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="text"
          name="text"
          label="Text"
          value={formik.values.text}
          onChange={formik.handleChange}
          className={classes.textField}
          error={formik.touched.text && Boolean(formik.errors.text)}
          helperText={formik.touched.text && formik.errors.text}
          multiline
        />
      </FormControl>
      <FormControl fullWidth>
        <DropzoneArea
          onChange={(files) => handleFileChange(files)}
          filesLimit={1}
          showAlerts={false}
          showFileNames
        />
      </FormControl>
      <FormControl>
        <FormControlLabel
          control={
            <Toggle
              checked={formik.values.active}
              onChange={formik.handleChange}
              id="active"
              name="active"
            />
          }
          label="Active"
        />
      </FormControl>
      <Button color="primary" variant="contained" fullWidth type="submit">
        Submit
      </Button>
    </form>
  );
};

export default AddNotification;
