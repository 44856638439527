import { useContext } from 'react';
import { UserContext } from '../../userContext';
import PrivateHome from './PrivateHome';
import PublicHome from './PublicHome';

const Home = () => {
  const userContext = useContext(UserContext);

  return userContext.userState.me.isAuthenticated ? (
    <PrivateHome role={userContext.userState.me.role} />
  ) : (
    <PublicHome />
  );
};

export default Home;
