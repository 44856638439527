import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  brandToggle: {
    width: '150px',
    marginLeft: theme.spacing(3),
  },
  tabs: {
    '& .MuiTabs-indicator': {
      background: '#006991',
      height: '4px',
      borderRadius: '4px',
      bottom: '8px',
    },
  },
  tab: {
    padding: 0,
    minWidth: 'unset',
    marginRight: '20px',
  },
}));

const Header = ({
  userContext,
  dealership,
  editBrand,
  handleBrandChange,
  selectedTab,
  setSelectedTab,
  permissions,
  handleOpenDisableDealership,
}) => {
  const classes = useStyles();
  let navigate = useNavigate();

  const handleNavigation = () => {
    navigate(`/dealerships/${dealership.id}/locations/all/current/quote`);
  };

  return (
    <Grid container justifyContent="space-between" spacing={3}>
      <Grid item>
        {userContext.userState.me.role <= 3 &&
        permissions.indexOf('manage_dealers') !== -1 ? (
          <div>
            <Typography variant="h4" component="span">
              {dealership.name}
            </Typography>
            <FormControl className={classes.brandToggle}>
              <InputLabel>Brand</InputLabel>
              <Select
                id="dealerBrand"
                name="dealerBrand"
                label="Brand"
                value={editBrand}
                onChange={(e) => handleBrandChange(e.target.value)}
              >
                <MenuItem value="1">Avalon</MenuItem>
                <MenuItem value="2">Tahoe</MenuItem>
                <MenuItem value="3">Grand Island</MenuItem>
                <MenuItem value="4">Legend</MenuItem>
              </Select>
            </FormControl>
          </div>
        ) : (
          <Typography variant="h4">
            {dealership.name} -{' '}
            <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
              {dealership.brand === 1 ? 'Avalon' : 'Tahoe'}
            </span>
          </Typography>
        )}

        {/* Tabs to show users or locations table */}
        {permissions.indexOf('manage_dealer_communication_list') !== -1 &&
          permissions.indexOf('manage_dealers') !== -1 && (
            <Tabs
              className={classes.tabs}
              value={selectedTab}
              onChange={(event, value) => setSelectedTab(value)}
            >
              <Tab
                className={classes.tab}
                value="manage-dealership"
                label="Manage Dealership"
              />
              {userContext.userState.me.permissions.indexOf(
                'manage_dealer_communication_list'
              ) !== -1 && (
                <Tab
                  className={classes.tab}
                  value="communications"
                  label="Communication List"
                />
              )}
            </Tabs>
          )}
      </Grid>
      {userContext.userState.me.role <= 2 ? (
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            size="small"
            onClick={() => handleNavigation()}
          >
            View Orders
          </Button>
          {permissions.indexOf('manage_dealers') !== -1 &&
            permissions.indexOf('manage_dealer_admin_info') !== -1 && (
              <Button
                color={
                  dealership.locations.filter((location) => location.active)
                    .length === 0
                    ? 'primary'
                    : 'error'
                }
                variant="contained"
                size="small"
                onClick={handleOpenDisableDealership}
                sx={{ ml: 4 }}
              >
                {dealership.locations.filter((location) => location.active)
                  .length === 0
                  ? 'Enable Dealership'
                  : 'Disable Dealership'}
              </Button>
            )}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default Header;
