import { useState, useContext } from 'react';
import AddDealership from '../../components/forms/AddDealership';
import DealershipLookup from '../../components/forms/DealershipLookup';
import { UserContext } from '../../userContext';
import {
  Grid,
  Paper,
  Typography,
  FormControlLabel,
  Switch as Toggle,
} from '@mui/material';

const Search = () => {
  const [addDealership, setAddDealership] = useState(false);
  const userContext = useContext(UserContext);

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item sm={12} md={6} lg={4}>
        <Paper sx={{ p: 2 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h4" paragraph>
                Dealerships
              </Typography>
            </Grid>
            {userContext.userState.me.role <= 2 ? (
              <Grid item>
                <FormControlLabel
                  control={
                    <Toggle
                      checked={addDealership}
                      onChange={() => setAddDealership(!addDealership)}
                      name="Add Dealership Toggle"
                    />
                  }
                  label={addDealership ? 'List Dealers' : 'Add Dealer'}
                />
              </Grid>
            ) : null}
          </Grid>
          {addDealership ? <AddDealership /> : <DealershipLookup />}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Search;
