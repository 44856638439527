import React from 'react';
import { Outlet } from 'react-router-dom';
import RulesNav from './Components/RulesNav';
import { Grid } from '@mui/material';
import { UserContext } from '../../../userContext';

const Rules = () => {
  const userContext = React.useContext(UserContext);
  const permissions = userContext.userState.me.permissions;

  return (
    <Grid container spacing={2}>
      {permissions?.includes('admin_rules') && (
        <Grid item xs={12}>
          <RulesNav />
        </Grid>
      )}

      <Grid item xs={12}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default Rules;
