import React, { useState, useEffect } from 'react';
import { Paper, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import Loading from '../components/Loading';
import EditUser from '../components/forms/EditUser';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const Profile = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const loadProfile = async () => {
      try {
        const { data } = await axios.get('/api/user');

        console.log(data);
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
      }
    };

    setLoading(true);
    loadProfile();
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={6}>
        <Paper variant="outlined" className={classes.paper}>
          <h1>User Profile</h1>
          {loading ? <Loading /> : <EditUser user={profile} type="self" />}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Profile;
