import React from 'react';
import clsx from 'clsx';
import {
  FormControl,
  Checkbox as CheckboxInput,
  FormControlLabel,
  IconButton,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SmsFailedOutlinedIcon from '@mui/icons-material/SmsFailedOutlined';
import { useParams } from 'react-router-dom';
import theme from '../../../theme';

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: 0,
    padding: '0 5px',
    backgroundColor: '#FFF',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  noGroup: {
    maxWidth: 'calc(100% - 27px)',
    padding: '6px 12px 3px',
    marginBottom: '12px',
    border: 'solid 1px #d1d1d1',
    borderRadius: '12px',
  },
  infoButton: {
    color: '#747679',
    '& svg': {
      fontSize: '20px',
    },
  },
  mobilePrice: {
    marginLeft: '2rem',
  },
}));

const Checkbox = (props) => {
  const {
    input,
    isNoGroup = false,
    handleInputChange,
    handleOpenTooltip,
    formatPrice,
    showHidden,
    orderStatus,
    role,
    brand,
    customerBuild,
    canEditLockedInputs,
    modelId,
    calculatePriceWithMarkup,
  } = props;
  const classes = useStyles();
  const { formType } = useParams();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  const getInputName = () => {
    if (input.branded) {
      return brand === 't' && input.tahoeName !== null && input.tahoeName !== ''
        ? input.tahoeName
        : input.name;
    } else {
      return input.name;
    }
  };

  return (
    <div
      style={{
        display: input.isHidden && !showHidden ? 'none' : 'block',
        marginBottom: '8px',
      }}
    >
      <FormControl
        fullWidth
        className={clsx(classes.formField, {
          [classes.noGroup]: isNoGroup,
        })}
      >
        <Grid container spacing={0}>
          <Grid
            item
            className={clsx({
              [classes.container]: !isMobile,
            })}
            xs={12}
          >
            <FormControlLabel
              control={
                <CheckboxInput
                  checked={input.value}
                  sx={{
                    '&.Mui-checked': {
                      color: '#006991',
                    },
                  }}
                  onChange={(e) => handleInputChange(e.target.checked, input)}
                  disabled={
                    (input.disabled && role === undefined) ||
                    (input.disabled && role !== undefined && !canEditLockedInputs) ||
                    (input.isLockedSubmitted &&
                      orderStatus >= 1 &&
                      role !== undefined &&
                      role >= 10) ||
                    (input.locked && input.value === true && !canEditLockedInputs)
                  }
                />
              }
              label={`${getInputName()}`}
              sx={{
                marginRight: '5px',
              }}
            />
            {input.tooltip !== null ? (
              <IconButton
                className={classes.infoButton}
                size="small"
                aria-label="Tooltip"
                onClick={() => handleOpenTooltip(input)}
              >
                <SmsFailedOutlinedIcon />
              </IconButton>
            ) : null}
            <Typography
              sx={
                isNoGroup
                  ? {
                      marginLeft: 'auto',
                    }
                  : {
                      marginLeft: 'auto',
                      marginRight: '12px',
                    }
              }
              className={clsx({ [classes.mobilePrice]: isMobile })}
            >{`${calculatePriceWithMarkup(input.price, +modelId)}`}</Typography>
          </Grid>
        </Grid>
      </FormControl>
    </div>
  );
};

export default Checkbox;
