import { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  ImageList,
  ImageListItem,
  Button,
} from '@mui/material';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

const BoatShowReport = () => {
  const [report, setReport] = useState(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const loadReport = async () => {
      try {
        const { data } = await axios.get(`/api/boat-show/report?id=${id}`);
        if (data) {
          setReport(data);
          setLoading(false);
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    loadReport();
  }, []);

  return (
    <Grid container spacing={2} maxWidth="md" sx={{ mx: 'auto' }}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          {loading && <Typography variant="h4">Loading...</Typography>}
          {!loading && report && (
            <Grid item container spacing={2}>
              <Grid item container xs={12} spacing={2}>
                <Grid item container xs={12} sm={6} spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4">{report.showName}</Typography>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => navigate(`/boat-show/reports/${id}/edit`)}
                      sx={{ mt: 1 }}
                    >
                      Edit Report
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h4">{report.dealership.name}</Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6} spacing={2}>
                  <Grid item container xs={12} spacing={4}>
                    <Grid item>
                      <Typography variant="h6">Dates of Show</Typography>
                      <Typography variant="body1">
                        {format(new Date(report.showDatesBegin), 'MM/dd/yyyy')} -{' '}
                        {format(new Date(report.showDatesEnd), 'MM/dd/yyyy')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6">Dates Attended</Typography>
                      <Typography variant="body1">
                        {format(new Date(report.datesAttendedBegin), 'MM/dd/yyyy')} -{' '}
                        {format(new Date(report.datesAttendedEnd), 'MM/dd/yyyy')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Reps</Typography>
                    <Typography variant="body1">
                      {report.reps.length === 0
                        ? 'No Reps'
                        : report.reps.length > 1
                        ? report.reps.map((rep) => rep.name).join(', ')
                        : report.reps[0].name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ mt: 4 }}>
                <TableContainer>
                  <Table sx={{ minWidth: '500px' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Avalon/Tahoe Boats/Engines Displayed</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {report.boatShowBoats
                        .filter((boat) => boat.category === 'DISPLAYED')
                        .map((boat) => (
                          <TableRow key={`displayed_${boat.id}`}>
                            <TableCell>{boat.boatName}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12} sx={{ mb: 4 }}>
                <TableContainer>
                  <Table sx={{ minWidth: '500px' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Boats Sold</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {report.boatShowBoats
                        .filter((boat) => boat.category === 'SOLD')
                        .map((boat) => (
                          <TableRow key={`sold${boat.id}`}>
                            <TableCell>{boat.boatName}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">Additional Brands</Typography>
                <Typography variant="body1">
                  {report.additionalBrandsDisplayed}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Booth/Display Notes</Typography>
                <Typography variant="body1">{report.boothDisplayNotes}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Competition Notes</Typography>
                <Typography variant="body1">{report.competitionNotes}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Additional Notes</Typography>
                <Typography variant="body1">{report.additionalNotes}</Typography>
              </Grid>
              <Grid item xs={12}>
                {report.images.filter((image) => image.imgPath.endsWith('.pdf'))
                  .length > 0 && (
                  <div>
                    <Typography variant="h6">PDFs</Typography>
                    <ul>
                      {report.images
                        .filter((image) => image.imgPath.endsWith('.pdf'))
                        .map((image) => (
                          <li key={`pdf_${image.id}`}>
                            <a
                              href={`https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/${image.imgPath}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {image.imgPath}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
                <Typography variant="h6">Photos</Typography>
                <ImageList sx={{ height: 450 }} cols={3} gap={16} rowHeight={200}>
                  {report.images
                    .filter((image) => !image.imgPath.endsWith('.pdf'))
                    .map((image) => (
                      <ImageListItem
                        key={`photo_${image.id}`}
                        sx={{
                          border: '1px solid rgba(0, 0, 0, 0.27)',
                          borderRadius: '5px',
                        }}
                      >
                        <img
                          src={`https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/${image.imgPath}`}
                          alt={image.imgPath}
                          loading="lazy"
                        />
                      </ImageListItem>
                    ))}
                </ImageList>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default BoatShowReport;
