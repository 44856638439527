import AddRebate from '../forms/AddRebate';
import { Typography } from '@mui/material';

const RebateForm = ({ order, setOrder, handleCloseModal, totalPrice }) => {
  if (order.rebates.find((r) => r.rebateStatus === 'PENDING')) {
    return (
      <Typography variant="h4" gutterBottom>
        There is already a pending rebate request.
      </Typography>
    );
  }

  if (order.rebates.find((r) => r.rebateStatus === 'APPROVED')) {
    return (
      <Typography variant="h4" gutterBottom>
        This order already has an approved rebate.
      </Typography>
    );
  }

  return (
    <AddRebate
      selectedOrder={order}
      setSelectedOrder={setOrder}
      handleCloseModal={handleCloseModal}
      totalPrice={totalPrice}
    />
  );
};

export default RebateForm;
