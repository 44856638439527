import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Checkbox,
  Box,
  Paper,
} from '@mui/material';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoatOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import RepPortalIcon from '@mui/icons-material/Language';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import SubjectIcon from '@mui/icons-material/Subject';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PanelIcon from '@mui/icons-material/ContentPaste';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PeopleAltIcon from '@mui/icons-material/PeopleAltOutlined';
import PageviewIcon from '@mui/icons-material/Pageview';
import SpeedIcon from '@mui/icons-material/Speed';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

const PermissionsTable = (props) => {
  const { data, setData } = props;

  function checkPermission(data, permission) {
    const pos = data.permissions.map((p) => p.permission).indexOf(permission);
    const foundPermission = data.permissions[pos];
    return pos !== -1 && !foundPermission?.isDeleted;
  }

  function handleTogglePermission(item, permission) {
    setData(
      data.map((d) => {
        if (d.id === item.id) {
          const pos = item.permissions.map((p) => p.permission).indexOf(permission);
          if (pos === -1) {
            return {
              ...d,
              permissions: [
                ...d.permissions,
                { id: null, role: d.role, permission: permission },
              ],
            };
          } else {
            return {
              ...d,
              permissions: d.permissions.map((p) => {
                if (p.permission === permission) {
                  if (p?.isDeleted) {
                    p.isDeleted = !p.isDeleted;
                  } else {
                    p.isDeleted = true;
                  }
                  return p;
                } else {
                  return p;
                }
              }),
            };
          }
        } else {
          return d;
        }
      })
    );
  }

  return (
    <Box component="div">
      <Paper sx={{ p: 2 }}>
        <Typography variant="h5">Orders</Typography>
        <TableContainer>
          <Table sx={{ minWidth: 900 }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '26px',
                      lineHeight: '26px',
                    }}
                  >
                    <Typography>
                      <AssignmentIcon fontSize="small" />
                      <ReceiptIcon fontSize="small" />
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ height: 'inherit', pl: '5px' }}
                    >
                      View
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '26px',
                      lineHeight: '26px',
                    }}
                  >
                    <Typography>
                      <DirectionsBoatIcon fontSize="small" />
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ height: 'inherit', pl: '5px' }}
                    >
                      Create
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>Edit</TableCell>
                <TableCell>Submit</TableCell>
                <TableCell>Verify</TableCell>
                <TableCell>Return to Quote</TableCell>
                <TableCell>Print Order</TableCell>
                <TableCell>Convert Lead</TableCell>
                <TableCell>Change Dealership</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '26px',
                      lineHeight: '26px',
                    }}
                  >
                    <Typography>
                      <WarningIcon fontSize="small" />
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ height: 'inherit', pl: '5px' }}
                    >
                      DERP Codes
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>Edit Discounts</TableCell>
                <TableCell>Edit Locked Inputs</TableCell>
                <TableCell>Order Notes Page</TableCell>
                <TableCell>Motor Orders</TableCell>
                <TableCell>Request Rebate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((d) => (
                <TableRow
                  key={`admin_${d.id}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{d.name}</TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'view_order')}
                      onChange={() => handleTogglePermission(d, 'view_order')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'create_order')}
                      onChange={() => handleTogglePermission(d, 'create_order')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'edit_order')}
                      onChange={() => handleTogglePermission(d, 'edit_order')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'submit_order')}
                      onChange={() => handleTogglePermission(d, 'submit_order')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'verify_order')}
                      onChange={() => handleTogglePermission(d, 'verify_order')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'return_to_quote')}
                      onChange={() => handleTogglePermission(d, 'return_to_quote')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'print_order')}
                      onChange={() => handleTogglePermission(d, 'print_order')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'convert_lead')}
                      onChange={() => handleTogglePermission(d, 'convert_lead')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'change_dealership')}
                      onChange={() => handleTogglePermission(d, 'change_dealership')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'derp_codes')}
                      onChange={() => handleTogglePermission(d, 'derp_codes')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'edit_discounts')}
                      onChange={() => handleTogglePermission(d, 'edit_discounts')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'edit_locked_inputs')}
                      onChange={() =>
                        handleTogglePermission(d, 'edit_locked_inputs')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'notes_page')}
                      onChange={() => handleTogglePermission(d, 'notes_page')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'motor_orders')}
                      onChange={() => handleTogglePermission(d, 'motor_orders')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'request_rebate')}
                      onChange={() => handleTogglePermission(d, 'request_rebate')}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Paper sx={{ p: 2, mt: 4 }}>
        <Typography variant="h5">Dealers</Typography>
        <TableContainer>
          <Table sx={{ minWidth: 900 }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '26px',
                      lineHeight: '26px',
                      width: '130px',
                    }}
                  >
                    <Typography>
                      <PeopleAltIcon fontSize="small" />
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ height: 'inherit', pl: '5px' }}
                    >
                      Manage Dealer
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>Dealer Users</TableCell>
                <TableCell>Locations</TableCell>
                <TableCell>Markups and Discounts</TableCell>
                <TableCell>Admin Info</TableCell>
                <TableCell>Communication List</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '26px',
                      lineHeight: '26px',
                      width: '120px',
                    }}
                  >
                    <Typography>
                      <PageviewIcon fontSize="small" />
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ height: 'inherit', pl: '5px' }}
                    >
                      Find Dealers
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((d) => (
                <TableRow
                  key={`manage_${d.id}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{d.name}</TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'manage_dealers')}
                      onChange={() => handleTogglePermission(d, 'manage_dealers')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'manage_dealer_users')}
                      onChange={() =>
                        handleTogglePermission(d, 'manage_dealer_users')
                      }
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'manage_dealer_locations')}
                      onChange={() =>
                        handleTogglePermission(d, 'manage_dealer_locations')
                      }
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'manage_dealer_markups')}
                      onChange={() =>
                        handleTogglePermission(d, 'manage_dealer_markups')
                      }
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'manage_dealer_admin_info')}
                      onChange={() =>
                        handleTogglePermission(d, 'manage_dealer_admin_info')
                      }
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(
                        d,
                        'manage_dealer_communication_list'
                      )}
                      onChange={() =>
                        handleTogglePermission(d, 'manage_dealer_communication_list')
                      }
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'find_dealerships')}
                      onChange={() => handleTogglePermission(d, 'find_dealerships')}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Paper sx={{ p: 2, mt: 4 }}>
        <Typography variant="h5">External Links</Typography>
        <TableContainer>
          <Table sx={{ minWidth: 900 }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '26px',
                      lineHeight: '26px',
                    }}
                  >
                    <Typography>
                      <PersonIcon fontSize="small" />
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ height: 'inherit', pl: '5px' }}
                    >
                      Dealer Portal
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '26px',
                      lineHeight: '26px',
                    }}
                  >
                    <Typography>
                      <DescriptionIcon fontSize="small" />
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ height: 'inherit', pl: '5px' }}
                    >
                      View Invoices
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '26px',
                      lineHeight: '26px',
                    }}
                  >
                    <Typography>
                      <RepPortalIcon fontSize="small" />
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ height: 'inherit', pl: '5px' }}
                    >
                      Rep Portal
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '26px',
                      lineHeight: '26px',
                    }}
                  >
                    <Typography>
                      <SubjectIcon fontSize="small" />
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ height: 'inherit', pl: '5px' }}
                    >
                      Terms
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((d) => (
                <TableRow
                  key={`external_links_${d.id}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{d.name}</TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'dealer_portal')}
                      onChange={() => handleTogglePermission(d, 'dealer_portal')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'view_invoices')}
                      onChange={() => handleTogglePermission(d, 'view_invoices')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'rep_portal')}
                      onChange={() => handleTogglePermission(d, 'rep_portal')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'terms')}
                      onChange={() => handleTogglePermission(d, 'terms')}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Paper sx={{ p: 2, mt: 4 }}>
        <Typography variant="h5">Admin</Typography>
        <TableContainer>
          <Table sx={{ minWidth: 1400 }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '26px',
                      lineHeight: '26px',
                      width: '120px',
                    }}
                  >
                    <Typography>
                      <ListAltIcon fontSize="small" />
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ height: 'inherit', pl: '5px' }}
                    >
                      Form Builder
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '26px',
                      lineHeight: '26px',
                      width: '120px',
                    }}
                  >
                    <Typography>
                      <FormatListNumberedIcon fontSize="small" />
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ height: 'inherit', pl: '5px' }}
                    >
                      Rules Engine
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '26px',
                      lineHeight: '26px',
                      width: '120px',
                    }}
                  >
                    <Typography>
                      <FormatListNumberedIcon fontSize="small" />
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ height: 'inherit', pl: '5px' }}
                    >
                      CSV Rules
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '26px',
                      lineHeight: '26px',
                      width: '120px',
                    }}
                  >
                    <Typography>
                      <SpeedIcon fontSize="small" />
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ height: 'inherit', pl: '5px' }}
                    >
                      Motor Settings
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '26px',
                      lineHeight: '26px',
                      width: '140px',
                    }}
                  >
                    <Typography>
                      <PeopleIcon fontSize="small" />
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ height: 'inherit', pl: '5px' }}
                    >
                      AT Dealer Users
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '26px',
                      lineHeight: '26px',
                      width: '100px',
                    }}
                  >
                    <Typography>
                      <AdminPanelSettingsIcon fontSize="small" />
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ height: 'inherit', pl: '5px' }}
                    >
                      Roles
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '26px',
                      lineHeight: '26px',
                      width: '145px',
                    }}
                  >
                    <Typography>
                      <PanelIcon fontSize="small" />
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ height: 'inherit', pl: '5px' }}
                    >
                      Dealer Allotment
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '26px',
                      lineHeight: '26px',
                      width: '100px',
                    }}
                  >
                    <Typography>
                      <AnnouncementIcon fontSize="small" />
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ height: 'inherit', pl: '5px' }}
                    >
                      Notifications
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '26px',
                      lineHeight: '26px',
                      width: '100px',
                    }}
                  >
                    <Typography>
                      <ScheduleSendIcon fontSize="small" />
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ height: 'inherit', pl: '5px' }}
                    >
                      Reporting
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '26px',
                      lineHeight: '26px',
                      width: '140px',
                    }}
                  >
                    <Typography>
                      <AssignmentOutlinedIcon fontSize="small" />
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ height: 'inherit', pl: '5px' }}
                    >
                      Boat Show Reports
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((d) => (
                <TableRow
                  key={`admin_${d.id}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{d.name}</TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'admin_form_builder')}
                      onChange={() =>
                        handleTogglePermission(d, 'admin_form_builder')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'admin_rules')}
                      onChange={() => handleTogglePermission(d, 'admin_rules')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'admin_rules_csv')}
                      onChange={() => handleTogglePermission(d, 'admin_rules_csv')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'admin_motors')}
                      onChange={() => handleTogglePermission(d, 'admin_motors')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'manage_io_sales')}
                      onChange={() => handleTogglePermission(d, 'manage_io_sales')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'manage_roles')}
                      onChange={() => handleTogglePermission(d, 'manage_roles')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'admin_allotment')}
                      onChange={() => handleTogglePermission(d, 'admin_allotment')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'manage_notifications')}
                      onChange={() =>
                        handleTogglePermission(d, 'manage_notifications')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'admin_upcoming_orders')}
                      onChange={() =>
                        handleTogglePermission(d, 'admin_upcoming_orders')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkPermission(d, 'view_boat_show_reports')}
                      onChange={() =>
                        handleTogglePermission(d, 'view_boat_show_reports')
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default PermissionsTable;
