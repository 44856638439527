import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddDealershipUser from '../../forms/AddDealershipUser';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      borderRadius: '16px',
    },
  },
  dialogTitle: {
    fontSize: '32px',
    fontWeight: '700',
    paddingBottom: 0,
  },
  dialogSubTitle: {
    paddingLeft: '24px',
    color: '#747679',
  },
  dialogContent: {
    paddingTop: 0,
  },
  dialogClose: {
    position: 'absolute',
    top: '11px',
    right: 0,
  },
}));

const AddUserModal = ({ show, handleClose, dealership, setDealership }) => {
  const classes = useStyles();

  return (
    <Dialog open={show} aria-labelledby="add-user" className={classes.dialog}>
      <DialogTitle id="form-add-user" className={classes.dialogTitle}>
        Add New User
      </DialogTitle>
      <Typography className={classes.dialogSubTitle}>
        Fill the information below to create new user.
      </Typography>
      <DialogContent className={classes.dialogContent}>
        <AddDealershipUser
          dealershipId={dealership.id}
          dealership={dealership}
          setDealership={setDealership}
          closeAdd={handleClose}
        />
      </DialogContent>
      <Button className={classes.dialogClose} onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </Button>
    </Dialog>
  );
};

export default AddUserModal;
