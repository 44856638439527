import React, { useState, useEffect, useContext } from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  useMediaQuery,
  Paper,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useParams, useResolvedPath, useNavigate } from 'react-router-dom';
import axios from 'axios';
import EnhancedTable from '../NewEnhancedTable';
import Loading from '../Loading';
import { format } from 'date-fns';
import AddNote from '../forms/AddNote';
import { UserContext } from '../../userContext';
import AddPaperOrder from '../forms/AddPaperOrder';
import ApproveOrder from '../forms/ApproveOrder';
import EditERP from '../forms/EditERP';
import PDF from '../Order/Components/PDF';
import html2pdf from 'html2pdf.js';
import theme from '../../theme';
import { orderStatus, parseBrand } from '../../lib';
import RebatesTable from '../RebatesTable';

const useStyles = makeStyles((theme) => ({
  note: {
    marginBottom: theme.spacing(2),
  },
  pdf: {
    display: 'none',
  },
  optionsTable: {
    width: '100%',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    '& thead tr th': {
      padding: theme.spacing(1, 2),
    },
    '& tbody tr td': {
      padding: theme.spacing(1, 2),
    },
    '& tbody tr:nth-child(odd)': {
      background: '#F8F8F8',
      borderRadius: '7px',
    },
    '&.parallel tbody tr td': {
      padding: theme.spacing(0.25, 2),
      height: '30px',
      fontSize: '12px',
    },
  },
}));

const LocationOrders = (props) => {
  const classes = useStyles();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const { archives, locations, repEmails } = props;
  const { locationId, archive, dealershipId, orderSource } = useParams();
  const userContext = useContext(UserContext);
  const url = useResolvedPath('').pathname;
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openNotes, setOpenNotes] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openPaperOrder, setOpenPaperOrder] = useState(false);
  const [paperOrder, setPaperOrder] = useState(null);
  const [openDeleteOrder, setOpenDeleteOrder] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);
  const [openApproveOrder, setOpenApproveOrder] = useState(false);
  const [approveOrder, setApproveOrder] = useState(null);
  const [openEditERP, setOpenEditERP] = useState(false);
  const [editERPOrder, setEditERPOrder] = useState(null);
  const [returnToQuoteOrders, setReturnToQuoteOrders] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [submittingQuotes, setSubmittingQuotes] = useState(false);
  const [downloadOrders, setDownloadOrders] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([
    'options',
    'userId',
    'dealershipId',
    'locationId',
    'orderSource',
    'archiveId',
    'active',
    'customerPhone',
    'customerEmail',
    'atdealerDealerId',
    'customerId',
    'optionsByPage',
    'requestedBuildWeek',
    'originalPrice',
    'markup',
  ]);
  const [initLayoutInfo, setInitLayoutInfo] = useState({});
  const [rebates, setRebates] = useState([]);
  const [selectedRebate, setSelectedRebate] = useState(null);
  const [selectedArchive, setSelectedArchive] = useState(
    archive !== undefined && archive !== 'current'
      ? archives.find((a) => a.name === archive).id
      : 'current'
  );

  const getPageName = (pageId) => {
    switch (pageId) {
      case 2:
        return 'Colors';
      case 3:
        return 'Packages';
      case 4:
        return 'Performance';
      case 5:
        return 'Additional Features';
    }
  };

  useEffect(() => {
    let source = axios.CancelToken.source();
    const loadOrders = async () => {
      let archiveId = archives.find((a) => a.name === archive);

      archiveId = archiveId === undefined ? 'current' : archiveId.id;
      if (archiveId === 'current' || archiveId > 3) {
        let params = {
          id: locationId,
          archiveId: archiveId,
          orderStatus: orderSource,
        };
        if (userContext.userState.me.role <= 3) params.dealershipId = dealershipId;
        const { data } = await axios.get('/api/dealer/location/orders', {
          cancelToken: source.token,
          params: params,
        });
        let sortedOrders = [];
        for (let order of data.orders) {
          let price = 0;
          order.options
            .filter((o) => o.lastVersion === order.orderVersion)
            .map((o) => {
              price += o.price;
              if (o.inputId === 1) order.model = o.optionDropdownName;
              if (o.inputId === 2) order.floorplan = o.optionDropdownName;
              if (o.inputId === 142) order.requestedBuildWeek = o.optionDropdownName;
              if (o.inputId === 6) order.motor = o.optionDropdownName;
              if (o.inputId === 4) order.preRig = o.optionDropdownName;
              if (o.inputId === 349) order.prop = o.optionDropdownName;
            });

          let tmpOptionsByPage = {};
          for (let o of order.options) {
            // console.log(o.input.page.id)
            let pageId = o.inputId === 164 ? 1 : o.input.page.id;
            if (tmpOptionsByPage[pageId] === undefined) {
              tmpOptionsByPage[pageId] = {
                name: o.inputId === 164 ? 'Initial Boat Build' : o.input.page.name,
                options: [o],
                note: null,
              };
            } else {
              tmpOptionsByPage[pageId].options.push(o);
            }
          }

          for (let note of order.notes) {
            if (note.pageId !== null) {
              if (tmpOptionsByPage[note.pageId] !== undefined) {
                tmpOptionsByPage[note.pageId].note = note.text;
              } else {
                tmpOptionsByPage[note.pageId] = {
                  name: getPageName(note.pageId),
                  options: [],
                  note: note.text,
                };
              }
            }
          }

          order.price = price;

          let sortedOrder = {};

          if (locationId === 'all') {
            let markup = 0;
            if (order.location.useModelMarkup) {
              const modelId = order.options.find(
                (o) => o.inputId === 1
              ).inputOptionId;
              const modelMarkup = order.location.modelMarkups.find(
                (m) => m.modelId === modelId
              );
              markup = modelMarkup.markup;
            } else {
              markup = order.location.markup;
            }

            let initialPrice = order.price;
            if (userContext.userState.me.customerBuild) {
              if (markup !== 0) {
                initialPrice = order.price / ((100 - parseInt(markup, 10)) / 100);
              } else {
                initialPrice = initialPrice / 0.64;
              }
            } else {
              initialPrice = order.price;
            }

            sortedOrder = {
              id: order.id,
              location: order.location.name,
              orderStatus: order.orderStatus,
              epicorOrder: order.epicorOrderNumber,
              epicorApprovalStatus: order.epicorApprovalStatus,
              confirmed: order.finalApproval,
              customerName: order.customerName,
              originalPrice: order.price,
              price: initialPrice,
              model: order.model,
              markup,
              floorplan: order.floorplan,
              motor: order.motor !== undefined ? order.motor : '',
              preRig: order.preRig !== undefined ? order.preRig : '',
              prop: order.prop !== undefined ? order.prop : '',
              po: order.po !== null ? order.po : '',
              requestedBuildWeek: order.requestedBuildWeek,
              productionDate:
                order.shipByDate !== null
                  ? format(new Date(order.shipByDate), 'MM/dd/yyyy')
                  : null,
              confirmedDate:
                order.confirmedDate !== null
                  ? format(new Date(order.confirmedDate), 'MM/dd/yyyy')
                  : null,
              orderDate: format(new Date(order.orderDate), 'MM/dd/yyyy'),
              lastModified:
                order.lastModified !== null
                  ? format(new Date(order.lastModified), 'MM/dd/yyyy')
                  : null,
              notes: order.notes,
              customerId: order.location.customerIds[0].customerId,
              optionsByPage: tmpOptionsByPage,
              orderSource: order.orderSource,
              leadRef: order.leadId,
              verified: order.verified,
            };
          } else {
            let markup = 0;
            if (order.location.useModelMarkup) {
              const modelId = order.options.find(
                (o) => o.inputId === 1
              ).inputOptionId;
              const modelMarkup = order.location.modelMarkups.find(
                (m) => m.modelId === modelId
              );
              markup = modelMarkup.markup;
            } else {
              markup = order.location.markup;
            }

            let initialPrice = order.price;
            if (userContext.userState.me.customerBuild) {
              if (markup !== 0) {
                initialPrice = order.price / ((100 - parseInt(markup, 10)) / 100);
              } else {
                initialPrice = order.price / 0.64;
              }
            } else {
              initialPrice = order.price;
            }

            sortedOrder = {
              id: order.id,
              location: order.location.name,
              orderStatus: order.orderStatus,
              epicorOrder: order.epicorOrderNumber,
              epicorApprovalStatus: order.epicorApprovalStatus,
              confirmed: order.finalApproval,
              customerName: order.customerName,
              originalPrice: order.price,
              price: initialPrice,
              model: order.model,
              markup,
              floorplan: order.floorplan,
              motor: order.motor !== undefined ? order.motor : '',
              preRig: order.preRig !== undefined ? order.preRig : '',
              prop: order.prop !== undefined ? order.prop : '',
              po: order.po !== null ? order.po : '',
              requestedBuildWeek: order.requestedBuildWeek,
              productionDate:
                order.shipByDate !== null
                  ? format(new Date(order.shipByDate), 'MM/dd/yyyy')
                  : null,
              confirmedDate:
                order.confirmedDate !== null
                  ? format(new Date(order.confirmedDate), 'MM/dd/yyyy')
                  : null,
              orderDate: format(new Date(order.orderDate), 'MM/dd/yyyy'),
              lastModified:
                order.lastModified !== null
                  ? format(new Date(order.lastModified), 'MM/dd/yyyy')
                  : null,
              notes: order.notes,
              customerId: order.location.customerIds[0].customerId,
              optionsByPage: tmpOptionsByPage,
              orderSource: order.orderSource,
              leadRef: order.leadId,
              verified: order.verified,
            };
          }

          sortedOrders.push(sortedOrder);
        }
        setOrders(sortedOrders);
      } else {
        let atdealerIds = [];
        for (let l of locations) {
          if (l.atdealerDealershipId !== null) {
            atdealerIds.push(l.atdealerDealershipId);
          }
        }

        if (atdealerIds.length === 0) {
          console.log('NO AT DEALER ID', locations);
          setOrders([]);
          setLoading(false);
          return;
        }

        const { data } = await axios.get('/api/dealer/location/orders/archived', {
          cancelToken: source.token,
          params: { id: locationId, archive: archiveId, atdealerId: atdealerIds },
        });
        let brand = userContext.userState.me.dealership.brand;
        let sortedOrders = [];
        for (let order of data.orders) {
          let price = 0;
          order.model = 'N/A';
          order.floorplan = 'N/A';
          order.options.map((o) => {
            price += o.price;
            if (o.optionId === 1) {
              order.model = data.modelsFloorplans
                .find((i) => i.id === 1)
                .inputOptions.find((io) => io.id === o.optionDropdownId);
              if (order.model !== undefined) {
                order.model =
                  brand === 2 ? order.model.tahoeName : order.model.avalonName;
              } else {
                order.model = 'N/A';
              }
            }

            if (o.optionId === 2) {
              order.floorplan = data.modelsFloorplans
                .find((i) => i.id === 2)
                .inputOptions.find((io) => io.id === o.optionDropdownId);
              if (order.floorplan !== undefined) {
                order.floorplan = order.floorplan.avalonName;
              } else {
                order.floorplan = 'N/A';
              }
            }
          });

          let sortedOrder = {};
          // console.log(order)
          sortedOrder = {
            id: order.id,
            orderStatus: order.orderStatus,
            erpNumber: order.erpNumber,
            customerName: order.customerName,
            price: price,
            model: order.model,
            floorplan: order.floorplan,
            // confirmedDate: order.confirmedDate !== null ?  format(new Date(order.confirmedDate), 'MM/dd/yyyy') : null,
            orderDate: format(new Date(order.orderDate), 'MM/dd/yyyy'),
            lastModified:
              order.lastModified !== null
                ? format(new Date(order.lastModified), 'MM/dd/yyyy')
                : null,
            customerId: locations.find(
              (l) => l.atdealerDealershipId === order.dealershipId
            ).customerIds[0].customerId,
            notes: order.notes,
          };

          sortedOrders.push(sortedOrder);
        }
        setOrders(sortedOrders);
      }
      let tmpHiddenColumns = [
        'options',
        'userId',
        'dealershipId',
        'locationId',
        'orderSource',
        'archiveId',
        'active',
        'customerPhone',
        'customerEmail',
        'atdealerDealerId',
        'customerId',
        'optionsByPage',
        'verified',
        'leadRef',
        'originalPrice',
        'markup',
      ];
      if (orderSource === 'leads') {
        tmpHiddenColumns = tmpHiddenColumns.concat([
          'erpNumber',
          'confirmed',
          'confirmedDate',
          'leadRef',
          'originalPrice',
          'markup',
        ]);
      }
      if (orderSource === 'quote') {
        tmpHiddenColumns = tmpHiddenColumns.concat([
          'epicorOrder',
          'epicorApprovalStatus',
          'confirmed',
          'productionDate',
          'confirmedDate',
          'originalPrice',
          'markup',
        ]);
      }
      if (locationId !== 'all') {
        tmpHiddenColumns.push('location');
        setHiddenColumns(tmpHiddenColumns);
      }

      if (archive !== 'MY21' && archive !== 'MY20' && archive !== 'MY19')
        tmpHiddenColumns.push('requestedBuildWeek');
      setHiddenColumns(tmpHiddenColumns);

      loadTableSettings();
    };

    const loadRebates = async () => {
      let archiveId = archives.find((a) => a.name === archive);
      archiveId = archiveId === undefined ? 'current' : archiveId.id;
      try {
        let params = {
          id: locationId,
          archiveId: archiveId,
          orderStatus: orderSource,
        };
        if (userContext.userState.me.role <= 3) params.dealershipId = dealershipId;
        const { data: rebates } = await axios.get('/api/dealer/rebates', {
          cancelToken: source.token,
          params: params,
        });

        setSelectedArchive(archiveId);
        setRebates(rebates);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
      }
    };

    if (locations.length === 1 && locationId === 'all') {
      if (userContext.userState.me.role < 10) {
        navigate(
          `/dealerships/${dealershipId}/locations/${locations[0].id}/${archive}/${orderSource}`
        );
      } else {
        navigate(`/locations/${locations[0].id}/${archive}/${orderSource}`);
      }
    }

    const loadTableSettings = async () => {
      let title = `Orders ${orderSource}`;
      let formattedTableName = title.replace(/\s/g, '_').toLowerCase();
      try {
        const { data } = await axios.get('/api/user/table/settings', {
          params: { tableName: formattedTableName },
        });
        if (data.message === 'Found table settings') {
          let tableSettings = data.tableSettings.fields;
          console.log('TABLE SETTINGS: ', tableSettings);
          setInitLayoutInfo(tableSettings);
        } else {
          setInitLayoutInfo({
            filter: {
              filterModel: {
                items: [],
                linkOperator: 'and',
              },
            },
            sorting: {
              sortModel: [],
            },
            columns: {
              columnVisibilityModel: [],
            },
          });
        }
      } catch (err) {
        return console.log(err);
      }
      setLoading(false);
    };

    if (locations.length === 1 && locationId === 'all') {
      if (userContext.userState.me.role < 10) {
        navigate(
          `/dealerships/${dealershipId}/locations/${locations[0].id}/${archive}/${orderSource}`
        );
      } else {
        navigate(`/locations/${locations[0].id}/${archive}/${orderSource}`);
      }
    } else {
      setLoading(true);
      if (orderSource === 'rebates') {
        loadRebates();
      } else {
        loadOrders();
      }
    }

    return function () {
      source.cancel('Cancelling in cleanup');
    };
  }, [locationId, archive, orderSource]);

  useEffect(() => {
    setOrders(
      orders.map((order) => {
        if (userContext.userState.me.customerBuild) {
          let price = order.originalPrice;
          price =
            order.markup !== 0 ? price / ((100 - order.markup) / 100) : price / 0.64;
          order.price = price;
        } else {
          order.price = order.originalPrice;
        }
        return order;
      })
    );
  }, [userContext.userState.me.customerBuild]);

  const viewOrder = (id) => {
    if (orders.find((o) => o.id === id)?.orderStatus === 5) {
      alert('Unable to view paper orders');
    } else {
      navigate(`${url}/${id}`);
    }
  };

  const handleOpenNotes = (order) => {
    console.log(order);
    setSelectedOrder(order);
    setOpenNotes(true);
  };

  const handleCloseNotes = () => {
    setOpenNotes(false);
  };

  const handleClosePaperOrder = () => {
    setPaperOrder(null);
    setOpenPaperOrder(false);
  };

  const handlePaperOrder = () => {
    let tmpPaperOrder = {
      dealershipId: parseInt(dealershipId, 10),
      locationId: parseInt(locationId, 10),
      orderSource: 3,
      orderStatus: 5,
      erpNumber: '',
      customerName: '',
    };
    setPaperOrder(tmpPaperOrder);
    setOpenPaperOrder(true);
  };

  const handleOpenDeleteOrder = (id) => {
    setOpenDeleteOrder(true);
    setOrderToDelete(id);
  };

  const handleCloseDeleteOrder = () => {
    setOpenDeleteOrder(false);
    setOrderToDelete(null);
  };

  const handleOpenApproveOrder = (id) => {
    setApproveOrder(id);
    setOpenApproveOrder(true);
  };

  const handleCloseApproveOrder = () => {
    setOpenApproveOrder(false);
  };

  const handleOpenEditERP = (order) => {
    setEditERPOrder(order);
    setOpenEditERP(true);
  };

  const handleCloseEditERP = () => {
    setOpenEditERP(false);
  };

  const bulkReturnToQuote = (orders) => {
    console.log(orders);
    setReturnToQuoteOrders(orders);
    setOpenConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const handleReturnToQuote = async () => {
    try {
      setSubmittingQuotes(true);
      const { data } = await axios.post('/api/orders/quote', returnToQuoteOrders);
      if (data.message !== undefined) {
        setOrders(orders.filter((o) => returnToQuoteOrders.indexOf(o.id) === -1));
        setReturnToQuoteOrders([]);
        setSubmittingQuotes(false);
        setOpenConfirmationModal(false);
      }
    } catch (err) {
      setSubmittingQuotes(false);
      console.log(err.response.data.message);
    }
  };

  const confirmDeleteOrder = async () => {
    try {
      const { data } = await axios.get('/api/order/delete', {
        params: { id: orderToDelete, userId: userContext.userState.me.id },
      });
      if (data.message !== undefined) {
        console.log(data.message);
        setOrders(orders.filter((o) => o.id !== orderToDelete));
        setOrderToDelete(null);
        setOpenDeleteOrder(false);
      }
    } catch (err) {
      console.log(err.response.data.message);
    }
  };

  const createLocationOrder = () => {
    const brand =
      userContext.userState.me.dealership !== null
        ? parseBrand(userContext.userState.me.dealership.brand)
        : 'a';
    navigate(
      `/dealerships/${dealershipId}/locations/${locationId}/order/new/d/${brand}?debug=true`
    );
  };

  const startDownloadOrders = async (orders) => {
    setLoading(true);
    let tmpOrders = [];
    for (let o of orders) {
      try {
        let { data } = await axios.get('/api/order', {
          params: { id: o, orderSource },
        });

        let tmpOptionsByPage = {};
        for (let o of data.options) {
          // console.log(o.input.page.id)
          let pageId = o.inputId === 164 ? 1 : o.input.page.id;
          if (tmpOptionsByPage[pageId] === undefined) {
            tmpOptionsByPage[pageId] = {
              name: o.inputId === 164 ? 'Initial Boat Build' : o.input.page.name,
              options: [o],
              note: null,
            };
          } else {
            tmpOptionsByPage[pageId].options.push(o);
          }
        }

        for (let note of data.notes) {
          if (note.pageId !== null) {
            if (tmpOptionsByPage[note.pageId] !== undefined) {
              tmpOptionsByPage[note.pageId].note = note.text;
            } else {
              tmpOptionsByPage[note.pageId] = {
                name: getPageName(note.pageId),
                options: [],
                note: note.text,
              };
            }
          }
        }

        data.optionsByPage = tmpOptionsByPage;
        data.brand = data.dealership.brand;
        tmpOrders.push(data);
      } catch (err) {
        console.log(err);
      }
    }
    setDownloadOrders(tmpOrders);

    try {
      let pages = Array.from(document.querySelectorAll('.pdf-page'));
      const pdfOptions = {
        margin: [5, 2],
        html2canvas: {
          scale: 4,
        },
        pagebreak: {
          before: '.pagebreak',
        },
      };
      await download(pages, pdfOptions);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const download = async (pages, options) => {
    let worker = html2pdf().set(options).from(pages[0]);

    if (pages.length > 1) {
      worker = worker.toPdf();

      // add each element/page individually to the pdf render process
      pages.slice(1).forEach((page, index) => {
        worker = worker
          .get('pdf')
          .then((pdf) => {
            pdf.addPage();
          })
          .from(page)
          .toContainer()
          .toCanvas()
          .toPdf();
      });
    }

    worker = worker.save(`Orders_${format(new Date(), 'MM_dd_yyyy')}`);
    setLoading(false);
  };

  const calculatePrice = (option) => {
    return option.price === 0
      ? ''
      : option.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const calculateTotalPrice = (order) => {
    let calculatedPrice = 0;
    for (let option of order.options) {
      calculatedPrice += option.price;
      // console.log(option.price)
    }

    return calculatedPrice.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  const handleDownloadXML = async (id) => {
    let order = orders.filter((o) => o.id === id[0])[0];
    const date = new Date();
    const formattedDate = date.toLocaleDateString('en', {
      // you can use undefined as first argument
      year: '2-digit',
      day: '2-digit',
      month: '2-digit',
    });
    try {
      axios
        .get('/api/xml', {
          params: { orderId: order.id, customerId: order.customerId },
          responseType: 'blod',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `WebOrder_ATOM_${formattedDate.split('/').join('')}_${order.id}_edit.xml`
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectRebate = (id) => {
    if (selectedRebate === id) {
      setSelectedRebate(null);
    } else {
      setSelectedRebate(id);
    }
  };

  const handleUpdateRebate = async (newStatus) => {
    const rebateUpdate = {
      rebateId: selectedRebate,
      rebateStatus: newStatus,
    };

    try {
      const { data: newRebate } = await axios.put('/api/order/rebate', rebateUpdate);
      setRebates(rebates.map((r) => (r.id === selectedRebate ? newRebate : r)));
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectArchive = (archiveId) => {
    let archiveName = archives.find((a) => a.id === archiveId).name;
    if (archiveName === 'Current Year') archiveName = 'current';
    let newPath = '';
    newPath =
      dealershipId !== undefined
        ? `/dealerships/${dealershipId}/locations/${locationId}/${archiveName}/${orderSource}`
        : `/locations/${locationId}/${archiveName}/${orderSource}`;
    setSelectedArchive(archiveId);
    navigate(newPath);
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container>
        <div id="pdf" className={classes.pdf}>
          {downloadOrders.length > 0
            ? downloadOrders.map((order, index) => (
                <PDF
                  key={order.id}
                  order={order}
                  classes={classes}
                  calculatePrice={calculatePrice}
                  calculateTotalPrice={calculateTotalPrice}
                  first={index === 0}
                  optionsByPage={order.optionsByPage}
                  hasNotes={order.notes.length > 0}
                  bulkPrint
                  brand={order.brand}
                />
              ))
            : null}
        </div>
        {loading ? (
          <Loading />
        ) : orderSource === 'rebates' ? (
          <RebatesTable
            rebates={rebates}
            selectedRebate={selectedRebate}
            handleSelectRebate={handleSelectRebate}
            role={userContext.userState.me.role}
            handleUpdateRebate={handleUpdateRebate}
            setRebates={setRebates}
            height={500}
            viewOrder={viewOrder}
            includeOrderId={true}
          />
        ) : (
          <EnhancedTable
            title={`Orders ${orderSource}`}
            isMobile={isMobile}
            data={orders}
            locations={locations}
            archives={archives}
            hiddenColumns={hiddenColumns}
            disableActionIcon
            viewOrder={archive === 'MY19' || archive === 'MY20' ? null : viewOrder}
            paperOrder={
              userContext.userState.me.role <= 3 &&
              locationId !== 'all' &&
              archive === 'current' &&
              orderSource === 'orders'
                ? handlePaperOrder
                : null
            }
            deleteOrder={
              userContext.userState.me.role <= 3 ||
              (userContext.userState.me.role === 10 && archive === 'current')
                ? handleOpenDeleteOrder
                : null
            }
            createLocationOrder={
              userContext.userState.me.permissions.indexOf('create_order') !== -1 &&
              locationId !== 'all' &&
              archive === 'current' &&
              locations.find((l) => l.id === +locationId).active
                ? createLocationOrder
                : null
            }
            handleOpenApproveOrder={
              userContext.userState.me.role >= 10 && archive === 'current'
                ? handleOpenApproveOrder
                : null
            }
            handleDownloadXML={handleDownloadXML}
            openNotes={handleOpenNotes}
            downloadOrders={archive !== 'current' ? null : startDownloadOrders}
            enableFilters
            filterTypes={['orderStatus', 'model', 'floorplan', 'customerName']}
            elevation={0}
            bulkReturnToQuote={
              userContext.userState.me.permissions.indexOf('return_to_quote') !==
                -1 &&
              archive === 'current' &&
              orderSource !== 'leads' &&
              orderSource !== 'quote'
                ? bulkReturnToQuote
                : null
            }
            initLayoutInfo={initLayoutInfo}
            dense
            initRowsPerPage={50}
            ordersTable
          />
        )}
        <Dialog
          open={openEditERP}
          onClose={handleCloseEditERP}
          maxWidth="sm"
          fullWidth
          aria-labelledby="edit-erp"
        >
          <DialogTitle>Edit ERP Number</DialogTitle>
          <DialogContent>
            <EditERP
              closeAdd={handleCloseEditERP}
              orders={orders}
              setOrders={setOrders}
              selectedOrder={selectedOrder}
              order={editERPOrder}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditERP} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openApproveOrder}
          onClose={handleCloseApproveOrder}
          maxWidth="sm"
          fullWidth
          aria-labelledby="approve-order"
        >
          <DialogTitle>Approve Order</DialogTitle>
          <DialogContent>
            <Typography variant="body1" paragraph>
              Are you sure you want to approve this order?
            </Typography>
            <ApproveOrder
              closeAdd={handleCloseApproveOrder}
              orders={orders}
              setOrders={setOrders}
              approveOrder={approveOrder}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={openDeleteOrder}
          onClose={handleCloseDeleteOrder}
          maxWidth="sm"
          fullWidth
          aria-labelledby="delete-order"
        >
          <DialogTitle>Confirm Delete Order</DialogTitle>
          <DialogContent>
            <Typography variant="body1" paragraph>
              Are you sure you want to delete order #{orderToDelete}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => confirmDeleteOrder()}
            >
              Confirm
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteOrder} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openPaperOrder}
          onClose={handleClosePaperOrder}
          maxWidth="sm"
          fullWidth
          aria-labelledby="paper-order"
        >
          <DialogTitle>New Paper Order</DialogTitle>
          <DialogContent>
            {paperOrder !== null ? (
              <AddPaperOrder
                closeAdd={handleClosePaperOrder}
                paperOrder={paperOrder}
                setPaperOrder={setPaperOrder}
                orders={orders}
                setOrders={setOrders}
              />
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePaperOrder} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openNotes}
          onClose={handleCloseNotes}
          maxWidth="sm"
          fullWidth
          aria-labelledby="order-notes"
        >
          {selectedOrder !== null ? (
            <>
              <DialogTitle>Order #{selectedOrder.id} Notes</DialogTitle>
              <DialogContent>
                {selectedOrder !== null
                  ? selectedOrder.notes
                      .filter((note) => note.pageId === null)
                      .map((note) => {
                        return (
                          <Box className={classes.note} key={note.id}>
                            <Typography variant="body1">
                              By {note.user.name} at{' '}
                              {format(new Date(note.createdAt), 'MM/dd/yyyy')}
                            </Typography>
                            <Typography variant="h6">{note.text}</Typography>
                          </Box>
                        );
                      })
                  : null}
                {['MY21', 'MY20', 'MY19'].indexOf(archive) !== -1 &&
                  ['quote', 'submitted'].indexOf(orderSource) !== -1 && (
                    <AddNote
                      selectedOrder={selectedOrder}
                      setSelectedOrder={setSelectedOrder}
                      orders={orders}
                      setOrders={setOrders}
                      closeAdd={handleCloseNotes}
                      repEmails={repEmails}
                    />
                  )}
              </DialogContent>
            </>
          ) : null}
          <DialogActions>
            <Button onClick={handleCloseNotes} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openConfirmationModal}
          onClose={handleCloseConfirmationModal}
          maxWidth="sm"
          fullWidth
          aria-labelledby="return-to-quote"
        >
          <DialogTitle>Return orders to quote</DialogTitle>
          <DialogContent>
            {submittingQuotes ? (
              <Loading text="Returning order(s) to quote" />
            ) : (
              <>
                <Typography variant="body1" paragraph>
                  Are you sure you want to return {returnToQuoteOrders.length} orders
                  to quote?
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleReturnToQuote()}
                >
                  Confirm
                </Button>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleCloseConfirmationModal}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Paper>
  );
};

export default LocationOrders;
