import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Grid,
  Typography,
  Autocomplete,
  TextField,
  MenuItem,
  Checkbox,
  Button,
  Collapse,
  Switch,
  FormControlLabel,
  Divider,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Loading from '../../../../../components/Loading';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

const Conditions = (props) => {
  const { ruleGroup, setRuleGroup, pages, conditions, setConditions, inputs } =
    props;
  const [showConditions, setShowConditions] = useState(true);
  const [submittingConditions, setSubmittingConditions] = useState(false);
  const [initLoading, setInitLoading] = useState(true);

  useEffect(() => {
    console.log(ruleGroup);
    if (ruleGroup !== null) {
      let groupedConditions = groupBy(ruleGroup.conditions, (r) => r.xId);
      let finalConditions = [];
      for (let [key, value] of groupedConditions.entries()) {
        let tmpInput = inputs.filter((input) => input.id === key)[0];
        if (tmpInput === undefined) {
          console.log('missing input', tmpInput);
        } else {
          let selectedOptions = tmpInput.inputOptions.filter(
            (io) => value.map((v) => v.yValue).indexOf(io.id) !== -1
          );

          finalConditions.push({
            page: tmpInput.pageId,
            input: tmpInput,
            inputOptions: tmpInput.inputOptions.filter((io) => !io.isDeleted),
            inputs: inputs.filter((input) => input.pageId === tmpInput.pageId),
            selectedOptions: selectedOptions,
            conditionOrder: value[0].conditionOrder,
            rules: value,
          });
        }
      }
      console.log('Init conditions: ', finalConditions);
      setConditions(finalConditions);
      setInitLoading(false);
    }
  }, []);

  const handleSelectPage = (e, index) => {
    setConditions(
      conditions.map((c, i) => {
        if (i === index) {
          console.log({
            ...c,
            page: e.target.value,
            inputs: inputs.filter((i) => i.pageId === e.target.value),
          });
          return {
            ...c,
            page: e.target.value,
            inputs: inputs.filter((i) => i.pageId === e.target.value),
          };
        } else {
          return c;
        }
      })
    );
  };

  const handleSelectInput = (e, option, index) => {
    setConditions(
      conditions.map((c, i) => {
        let selectedInput = option;

        console.log(
          selectedInput.inputOptions.length,
          selectedInput.inputOptions.filter((io) => !io.isDeleted).length
        );

        if (i === index) {
          return {
            ...c,
            input: selectedInput,
            inputOptions: selectedInput.inputOptions.filter((io) => !io.isDeleted),
            selectedOptions: [selectedInput.inputOptions[0]],
          };
        } else {
          return c;
        }
      })
    );
  };

  const handleSelectInputOption = (e, option, index) => {
    setConditions(
      conditions.map((c, i) => {
        if (i === index) {
          if (c.input.type === 'DROPDOWN') {
            return {
              ...c,
              selectedOptions: option,
            };
          } else {
            return {
              ...c,
              selectedOptions: [option],
            };
          }
        } else {
          return c;
        }
      })
    );
  };

  const handleAddCondition = () => {
    setConditions([
      ...conditions,
      {
        page: '',
        input: '',
        inputOptions: [],
        inputs: [],
        selectedOptions: [],
        rules: [],
      },
    ]);
  };

  const handleDeleteCondition = (index) => {
    setConditions(conditions.filter((c, i) => i !== index));
  };

  const handleSaveConditions = async () => {
    let groupConditions = [];
    conditions.forEach((condition, index) => {
      let newConditions = [];
      newConditions = Array.isArray(condition.selectedOptions)
        ? condition.selectedOptions
        : [condition.selectedOptions];
      newConditions.map((nco, i) => {
        let c = {
          conditionOrder: index,
          conditional: i === 0 ? 'START' : 'OR',
          ruleGroupId: ruleGroup.id,
          xId: condition.input.id,
          xType: condition.input.type,
          yValue: nco.id,
        };
        return groupConditions.push(c);
      });
    });

    try {
      setSubmittingConditions(true);
      const { data } = await axios.post('/api/rule/group/conditions', {
        ruleGroupId: ruleGroup.id,
        conditions: groupConditions,
      });
      // update conditions.rules
      setRuleGroup({ ...ruleGroup, conditions: data.conditions });

      // update ruleGroup.conditions
      setConditions(
        conditions.map((condition) => {
          let newRules = data.conditions.filter(
            (gc) => gc.xId === condition.input.id
          );
          console.log('NEW RULES: ', newRules);
          return { ...condition, rules: newRules };
        })
      );
      setSubmittingConditions(false);
    } catch (err) {
      console.log(err);
      setSubmittingConditions(false);
    }
  };

  if (initLoading) {
    return <Loading />;
  }

  return (
    <Box component="div">
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h5" sx={{ my: 2 }}>
            Conditions
            <FormControlLabel
              control={
                <Switch
                  checked={showConditions}
                  onChange={() => setShowConditions(!showConditions)}
                />
              }
              label={showConditions ? 'Hide' : 'Show'}
              sx={{ ml: 2 }}
            />
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={handleSaveConditions}
          >
            Save Conditions
          </Button>
        </Grid>
      </Grid>

      {submittingConditions ? (
        <Loading />
      ) : (
        <Collapse in={showConditions}>
          <Grid container>
            {conditions.map((condition, index) => {
              return (
                <Grid item xs={12} key={`condition_${index}`}>
                  {index !== 0 ? (
                    <Grid item xs={12} sx={{ my: 2 }}>
                      <Grid container alignItems="center" spacing={3}>
                        <Grid item xs={5}>
                          <Divider />
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body1" align="center">
                            AND
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Divider />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <Grid container spacing={2} justifyContent="space-between">
                        <Grid item xs={12} sm={4}>
                          <Button
                            color="error"
                            onClick={() => handleDeleteCondition(index)}
                            disabled={conditions.length === 1}
                          >
                            Delete
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <TextField
                            id={`page-select-${index}`}
                            select
                            label="Page"
                            value={condition.page}
                            onChange={(e) => handleSelectPage(e, index)}
                            disabled={condition.selectedOptions.length > 0}
                            size="small"
                            fullWidth
                          >
                            <MenuItem value="">Select Page</MenuItem>
                            {pages !== undefined
                              ? pages.map((page) => (
                                  <MenuItem key={page.id} value={page.id}>
                                    {page.name}
                                  </MenuItem>
                                ))
                              : null}
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={3}>
                      <Autocomplete
                        id={`input-select-${index}`}
                        options={
                          condition?.inputs
                            ? condition?.inputs.filter((input) => !input.isDeleted)
                            : []
                        }
                        getOptionLabel={(option) => (option ? option.name : '')}
                        renderInput={(params) => (
                          <TextField {...params} label="Input" size="small" />
                        )}
                        onChange={(e, option) => handleSelectInput(e, option, index)}
                        value={condition.input}
                        disabled={
                          condition.inputs.length === 0 ||
                          condition.selectedOptions.length > 0
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={6}>
                      {condition?.input?.type === 'DROPDOWN' ? (
                        <Autocomplete
                          id={`input-option-select-${index}`}
                          multiple
                          options={
                            condition?.input?.inputOptions
                              ? condition?.input?.inputOptions.filter(
                                  (io) => !io.isDeleted
                                )
                              : []
                          }
                          disableCloseOnSelect={
                            condition?.input?.type === 'DROPDOWN'
                          }
                          getOptionLabel={(option) =>
                            option?.avalonName ? option.avalonName : ''
                          }
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.avalonName}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Input Options"
                              size="small"
                            />
                          )}
                          onChange={(e, option) =>
                            handleSelectInputOption(e, option, index)
                          }
                          value={condition.selectedOptions}
                          fullWidth
                        />
                      ) : null}
                      {condition?.input?.type === 'CHECKBOX' ? (
                        <Autocomplete
                          id={`input-option-select-${index}`}
                          options={
                            condition?.inputOptions ? condition.inputOptions : []
                          }
                          disableCloseOnSelect={
                            condition?.input?.type === 'DROPDOWN'
                          }
                          getOptionLabel={(option) =>
                            option?.avalonName ? option.avalonName : ''
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Input Options"
                              size="small"
                            />
                          )}
                          onChange={(e, option) =>
                            handleSelectInputOption(e, option, index)
                          }
                          value={condition.selectedOptions[0]}
                          fullWidth
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddCondition}
                sx={{ mt: 2 }}
              >
                Add Condition
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      )}
    </Box>
  );
};

export default Conditions;
