import React, { useState } from 'react';
import { TextField, FormControl, Button, Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
  name: yup.string('Enter the page name').required('Page name is required'),
});

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(0, 2, 2, 2),
  },
}));

const AddPage = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  let navigate = useNavigate();
  const { closeAdd } = props;

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const { data } = await axios.post('/api/page', values);

        setSubmitError(null);
        resetForm();
        closeAdd();
        navigate(`/settings/form/pages/${data.page.id}`);
      } catch (err) {
        setSubmitError(err.response.data.message);
      }
    },
  });

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      {submitError !== null ? (
        <Box color="error.main">
          <Typography variant="subtitle2">{submitError}</Typography>
        </Box>
      ) : null}
      <FormControl fullWidth>
        <TextField
          fullWidth
          id="name"
          name="name"
          label="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          className={classes.textField}
        />
      </FormControl>
      <Button color="primary" variant="contained" fullWidth type="submit">
        Submit
      </Button>
    </form>
  );
};

export default AddPage;
