import React, { useState, useEffect } from 'react';
import {
  TextField,
  Select,
  FormControl,
  Button,
  Grid,
  Chip,
  MenuItem,
  InputLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
  address: yup.string('Enter your address').required('Please enter an address'),
  address2: yup.string(),
  city: yup.string('Enter your city').required('Please enter a city'),
  state: yup
    .string('Enter your state/province')
    .required('Please enter a state/province'),
  zipcode: yup.string('Enter your zipcode').required('Please enter a zipcode'),
  country: yup.string('Enter your country').required('Please enter a country'),
  website: yup.string(),
  notificationList: yup.array().of(yup.string().email()).ensure(),
  lat: yup.string(),
  lng: yup.string(),
  customerIds: yup
    .array()
    .of(
      yup.object().shape({
        customer_id: yup.string().required(),
      })
    )
    .ensure(),
  locationUsers: yup.array().ensure(),
  name: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2),
  },
  lastInput: {
    marginBottom: theme.spacing(4),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    chip: {
      margin: 2,
    },
  },
  cancelBtn: {
    background: '#fff',
    color: '#171A20',
    '&:hover': {
      background: '#F8F8F8',
    },
  },
  submitBtn: {
    background: '#006991',
    color: '#fff',
    '&:hover': {
      background: '#0c4054',
    },
  },
  textBtn: {
    marginBottom: theme.spacing(2),
    color: '#006991',
    '&:hover': {
      color: '#0c4054',
    },
  },
}));

const AddLocation = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const [emails, setEmails] = useState([]);
  const { users, dealership, setDealership, closeAdd } = props;

  useEffect(() => {
    const userEmails = (users) => {
      let tmp = [];
      users.map((u) => {
        return tmp.push(u.email);
      });

      setEmails(tmp);
    };

    userEmails(users);
  }, []);

  const formik = useFormik({
    initialValues: {
      dealershipId: dealership.id,
      address: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      country: '',
      website: '',
      notificationList: [],
      lat: '',
      lng: '',
      customerIds: [],
      locationUsers: [],
      name: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        console.log(values);
        let { data } = await axios.post('/api/location', values);
        console.log(data.newLocation);
        delete data.newLocation.locationUsers;
        delete data.newLocation.customerIds;
        setDealership({
          ...dealership,
          locations: [...dealership.locations, data.newLocation],
        });
        setSubmitError(null);
        resetForm();
        closeAdd();
      } catch (err) {
        setSubmitError(err.respoance.data.message);
      }
    },
  });

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      {submitError !== null ? (
        <h3 style={{ color: '#f44336', marginTop: '0px' }}>{submitError}</h3>
      ) : null}
      <FormControl fullWidth>
        <TextField
          id="name"
          name="name"
          label="Name"
          placeholder="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          className={classes.formField}
          fullWidth
          variant="standard"
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="address"
          name="address"
          label="Address 1"
          placeholder="Enter the address 1"
          value={formik.values.address}
          onChange={formik.handleChange}
          className={classes.formField}
          error={formik.touched.address && Boolean(formik.errors.address)}
          helperText={formik.touched.address && formik.errors.address}
          fullWidth
          variant="standard"
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="address2"
          name="address2"
          label="Address 2"
          placeholder="Enter the address 2"
          value={formik.values.address2}
          onChange={formik.handleChange}
          className={classes.formField}
          error={formik.touched.address2 && Boolean(formik.errors.address2)}
          helperText={formik.touched.address2 && formik.errors.address2}
          fullWidth
          variant="standard"
        />
      </FormControl>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="city"
              name="city"
              label="City"
              value={formik.values.city}
              onChange={formik.handleChange}
              className={classes.formField}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              fullWidth
              variant="standard"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="state"
              name="state"
              label="State/Province"
              value={formik.values.state}
              onChange={formik.handleChange}
              className={classes.formField}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
              fullWidth
              variant="standard"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="country"
              name="country"
              label="Country"
              value={formik.values.country}
              onChange={formik.handleChange}
              className={classes.formField}
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={formik.touched.country && formik.errors.country}
              fullWidth
              variant="standard"
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="zipcode"
              name="zipcode"
              label="Zipcode"
              placeholder="Enter zipcode"
              value={formik.values.zipcode}
              onChange={formik.handleChange}
              className={classes.formField}
              error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
              helperText={formik.touched.zipcode && formik.errors.zipcode}
              fullWidth
              variant="standard"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="lat"
              name="lat"
              label="Latitude"
              placeholder="Enter latitude"
              value={formik.values.lat}
              onChange={formik.handleChange}
              className={classes.formField}
              error={formik.touched.lat && Boolean(formik.errors.lat)}
              helperText={formik.touched.lat && formik.errors.lat}
              fullWidth
              variant="standard"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="lng"
              name="lng"
              label="Longitude"
              placeholder="Enter longitude"
              value={formik.values.lng}
              onChange={formik.handleChange}
              className={classes.formField}
              error={formik.touched.lng && Boolean(formik.errors.lng)}
              helperText={formik.touched.lng && formik.errors.lng}
              fullWidth
              variant="standard"
            />
          </FormControl>
        </Grid>
      </Grid>
      <FormControl fullWidth>
        <TextField
          id="website"
          name="website"
          label="Website"
          placeholder="Enter the website"
          value={formik.values.website}
          onChange={formik.handleChange}
          className={classes.formField}
          error={formik.touched.website && Boolean(formik.errors.website)}
          helperText={formik.touched.website && formik.errors.website}
          fullWidth
          variant="standard"
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="locationUsersLabel">Users</InputLabel>
        <Select
          id="locationUsers"
          name="locationUsers"
          labelId="locationUsersLabel"
          IconComponent={ExpandMoreIcon}
          multiple
          value={formik.values.locationUsers}
          onChange={formik.handleChange}
          className={classes.formField}
          error={
            formik.touched.locationUsers && Boolean(formik.errors.locationUsers)
          }
          fullWidth
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((user) => (
                <Chip key={user.id} label={user.name} className={classes.chip} />
              ))}
            </div>
          )}
        >
          {users.map((user) => (
            <MenuItem key={user.id} value={user}>
              {user.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="notificationListLabel">Notification List</InputLabel>
        <Select
          id="notificationList"
          name="notificationList"
          labelId="notificationListLabel"
          IconComponent={ExpandMoreIcon}
          multiple
          value={formik.values.notificationList}
          onChange={formik.handleChange}
          className={classes.formField}
          error={
            formik.touched.notificationList &&
            Boolean(formik.errors.notificationList)
          }
          fullWidth
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
        >
          {emails.map((email) => (
            <MenuItem key={email} value={email}>
              {email}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <FormikProvider value={formik}>
          <FieldArray name="customerIds">
            {({ push, remove }) => (
              <Grid container alignItems="center">
                {formik.values.customerIds.map((cid, index) => {
                  const name = `customerIds[${index}].customer_id`;
                  return (
                    <Grid key={`${index}_${cid}`} item xs={12} sm={6}>
                      <TextField
                        className={classes.formField}
                        name={name}
                        value={cid.customer_id}
                        label="Customer Id"
                        onChange={formik.handleChange}
                        variant="standard"
                      />
                      <br />
                      <Button
                        variant="contained"
                        className={classes.cancelBtn}
                        size="small"
                        onClick={() => remove(index)}
                      >
                        remove
                      </Button>
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    className={classes.textBtn}
                    variant="text"
                    size="small"
                    onClick={() => push({ customer_id: '' })}
                  >
                    <AddIcon fontSize="small" />
                    Add Customer Id
                  </Button>
                </Grid>
              </Grid>
            )}
          </FieldArray>
        </FormikProvider>
      </FormControl>
      <Grid container spacing={1} className={classes.actions}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            className={classes.cancelBtn}
            onClick={closeAdd}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            color="primary"
            variant="contained"
            className={classes.submitBtn}
            fullWidth
            type="submit"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddLocation;
