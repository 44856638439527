import React, { useState, useEffect } from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { useNavigate, useParams } from 'react-router';

const AntTabs = withStyles((theme) => ({
  root: {
    borderBottom: '1px solid #e8e8e8',
    marginBottom: theme.spacing(2),
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const OrdersNav = ({ role }) => {
  const classes = useStyles();
  let { locationId, archive, orderSource, dealershipId } = useParams();
  const [value, setValue] = useState(orderSource);
  const [allowRebate, setAllowRebate] = useState(false);

  useEffect(() => {
    setValue(orderSource);
  }, [orderSource]);

  useEffect(() => {
    const verifyStatus = async () => {
      const response = await fetch('/api/settings/rebates');
      const data = await response.json();
      setAllowRebate(data.allowed);
    };

    verifyStatus();
  }, []);

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(
      dealershipId !== undefined
        ? `/dealerships/${dealershipId}/locations/${locationId}/${archive}/${newValue}`
        : `/locations/${locationId}/${archive}/${newValue}`
    );
  };

  return (
    <Box
      sx={{
        maxWidth: { xs: 'calc(100vw - 50px)', sm: 'unset' },
      }}
      className={classes.root}
    >
      <AntTabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="orders nav"
      >
        <AntTab label="Quote" value={'quote'} />
        <AntTab label="Submitted" value={'submitted'} />
        <AntTab label="Confirmed" value={'confirmed'} />
        <AntTab label="Complete" value={'complete'} />
        <AntTab label="Leads" value={'leads'} />
        {allowRebate && role <= 3 && <AntTab label="Rebates" value={'rebates'} />}
      </AntTabs>
    </Box>
  );
};

export default OrdersNav;
