import React from 'react';
import { Typography, CircularProgress, Grid } from '@mui/material';

const Loading = (props) => {
  const { text } = props;
  return (
    <Grid item>
      <Typography variant="h3">
        <CircularProgress color="primary" />{' '}
        {text !== undefined ? text : 'Loading...'}
      </Typography>
    </Grid>
  );
};

export default Loading;
