import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddLocation from '../../forms/AddLocation';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      borderRadius: '16px',
    },
  },
  dialogTitle: {
    fontSize: '32px',
    fontWeight: '700',
    paddingBottom: 0,
  },
  dialogSubTitle: {
    paddingLeft: '24px',
    color: '#747679',
  },
  dialogContent: {
    paddingTop: 0,
  },
  dialogClose: {
    position: 'absolute',
    top: '11px',
    right: 0,
  },
}));

const AddLocationModal = ({
  show,
  users,
  dealership,
  setDealership,
  handleClose,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={show}
      aria-labelledby="add-location"
      className={classes.dialog}
    >
      <DialogTitle id="form-add-location" className={classes.dialogTitle}>
        Add New Location
      </DialogTitle>
      <Typography className={classes.dialogSubTitle}>
        Fill the information below to create new user.
      </Typography>
      <DialogContent className={classes.dialogContent}>
        <AddLocation
          users={users}
          dealership={dealership}
          setDealership={setDealership}
          closeAdd={handleClose}
        />
        <Button className={classes.dialogClose} onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default AddLocationModal;
