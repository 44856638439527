import React, { useState, useEffect, useContext } from 'react';
import {
  TextField,
  Select,
  FormControl,
  Button,
  Grid,
  MenuItem,
  InputLabel,
  Chip,
  Typography,
  FormControlLabel,
  Switch as Toggle,
  Stack,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import * as yup from 'yup';
import { UserContext } from '../../userContext';

const validationSchema = yup.object({
  address: yup.string('Enter your address').required('Please enter an address'),
  address2: yup.string(),
  city: yup.string('Enter your city').required('Please enter a city'),
  state: yup
    .string('Enter your state/province')
    .required('Please enter a state/province'),
  zipcode: yup.string('Enter your zipcode').required('Please enter a zipcode'),
  country: yup.string('Enter your country').required('Please enter a country'),
  website: yup.string(),
  lat: yup.string(),
  lng: yup.string(),
  name: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2),
  },
  lastInput: {
    marginBottom: theme.spacing(4),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    chip: {
      margin: 2,
    },
  },
}));

const EditLocation = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const { dealership, setDealership, closeAdd, location } = props;
  const userContext = useContext(UserContext);
  const [newCustomerId, setNewCustomerId] = useState('');

  let initialValues = {
    id: location.id,
    address: location.address,
    address2: location.address2,
    city: location.city,
    state: location.state,
    zipcode: location.zipcode,
    country: location.country,
    website: location.website,
    lat: location.lat === null ? '' : location.lat,
    lng: location.lng === null ? '' : location.lng,
    name: location.name,
  };

  if (userContext.userState.me.role <= 2) {
    initialValues.disableConfirm = location.disableConfirm;
    initialValues.atdealerDealershipId =
      location.atdealerDealershipId === null ? '' : location.atdealerDealershipId;
    initialValues.customerIds = location.customerIds;
    initialValues.active = location.active;
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        let fullData = {
          values,
          oldCustomerIds: location.customerIds,
        };

        if (fullData.values.atdealerDealershipId !== undefined)
          fullData.values.atdealerDealershipId =
            fullData.values.atdealerDealershipId === ''
              ? null
              : parseInt(fullData.values.atdealerDealershipId, 10);
        let { data } = await axios.put('/api/location', fullData);
        setDealership({
          ...dealership,
          locations: dealership.locations.map((l) => {
            if (l.id === data.location.id) {
              return data.location;
            } else {
              return l;
            }
          }),
        });
        setSubmitError(null);
        closeAdd();
      } catch (err) {
        setSubmitError(err.respoance.data.message);
      }
    },
  });

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      {submitError !== null ? (
        <h3 style={{ color: '#f44336', marginTop: '0px' }}>{submitError}</h3>
      ) : null}
      <Grid container spacing={2} sx={{ marginBottom: '24px' }}>
        <Grid container item justifyContent="space-between" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ marginBottom: '16px' }}>
              About
            </Typography>
            <FormControl fullWidth>
              <TextField
                id="name"
                name="name"
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                className={classes.formField}
                fullWidth
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="website"
                name="website"
                label="Website"
                value={formik.values.website}
                onChange={formik.handleChange}
                className={classes.formField}
                error={formik.touched.website && Boolean(formik.errors.website)}
                helperText={formik.touched.website && formik.errors.website}
                fullWidth
              />
            </FormControl>
            {userContext.userState.me.role <= 2 ? (
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      id="atdealerDealershipId"
                      name="atdealerDealershipId"
                      label="Previous AT Dealer ID"
                      value={formik.values.atdealerDealershipId}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Toggle
                          checked={formik.values.disableConfirm}
                          onChange={formik.handleChange}
                          id="disableConfirm"
                          name="disableConfirm"
                        />
                      }
                      label="Disable Confirm"
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Toggle
                          checked={formik.values.active}
                          onChange={formik.handleChange}
                          id="active"
                          name="active"
                        />
                      }
                      label="Active"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      {userContext.userState.me.role <= 2 && (
        <Grid
          item
          container
          justifyContent="space-between"
          spacing={2}
          sx={{ marginBottom: '24px' }}
        >
          <FormikProvider value={formik}>
            <FieldArray name="customerIds">
              {({ push, remove }) => {
                return (
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={1}>
                        {formik.values.customerIds?.map((cid, index) => {
                          const name = `customerIds[${index}].customerId`;
                          return (
                            <Chip
                              key={`${index}_${cid}`}
                              name={name}
                              label={cid.customerId}
                              onDelete={() => remove(index)}
                              style={{ display: 'inline-flex' }}
                            />
                          );
                        })}
                      </Stack>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="New Customer ID"
                        value={newCustomerId}
                        size="small"
                        onChange={(e) => setNewCustomerId(e.target.value)}
                        sx={{ marginRight: '8px' }}
                      />
                      <Button
                        color="primary"
                        className={classes.formField}
                        variant="contained"
                        onClick={() => {
                          push({ customerId: newCustomerId });
                          setNewCustomerId('');
                        }}
                      >
                        Add Customer Id
                      </Button>
                    </Grid>
                  </Grid>
                );
              }}
            </FieldArray>
          </FormikProvider>
        </Grid>
      )}
      <Typography variant="h6" sx={{ marginBottom: '16px' }}>
        Address
      </Typography>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} sm={8}>
          <FormControl fullWidth>
            <TextField
              id="address"
              name="address"
              label="Address"
              value={formik.values.address}
              onChange={formik.handleChange}
              className={classes.formField}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="address2"
              name="address2"
              label="Address2"
              value={formik.values.address2}
              onChange={formik.handleChange}
              className={classes.formField}
              error={formik.touched.address2 && Boolean(formik.errors.address2)}
              helperText={formik.touched.address2 && formik.errors.address2}
              fullWidth
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="city"
              name="city"
              label="City"
              value={formik.values.city}
              onChange={formik.handleChange}
              className={classes.formField}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="state"
              name="state"
              label="State/Province"
              value={formik.values.state}
              onChange={formik.handleChange}
              className={classes.formField}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="country"
              name="country"
              label="Country"
              value={formik.values.country}
              onChange={formik.handleChange}
              className={classes.formField}
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={formik.touched.country && formik.errors.country}
              fullWidth
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item>
          <FormControl fullWidth>
            <TextField
              id="zipcode"
              name="zipcode"
              label="Zipcode"
              value={formik.values.zipcode}
              onChange={formik.handleChange}
              className={classes.formField}
              error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
              helperText={formik.touched.zipcode && formik.errors.zipcode}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            id="lat"
            name="lat"
            label="Latitude"
            value={formik.values.lat}
            onChange={formik.handleChange}
            className={classes.formField}
            error={formik.touched.lat && Boolean(formik.errors.lat)}
            helperText={formik.touched.lat && formik.errors.lat}
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            id="lng"
            name="lng"
            label="Longitude"
            value={formik.values.lng}
            onChange={formik.handleChange}
            className={classes.formField}
            error={formik.touched.lng && Boolean(formik.errors.lng)}
            helperText={formik.touched.lng && formik.errors.lng}
            fullWidth
          />
        </Grid>
      </Grid>
      <Button
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        sx={{ marginTop: '24px' }}
      >
        Save
      </Button>
    </form>
  );
};

export default EditLocation;
