import React from 'react';
import { Breadcrumbs, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Nav = (props) => {
  const { routes } = props;
  return (
    <Breadcrumbs>
      {routes.map((route, index) => {
        if (index + 1 === routes.length) {
          return (
            <Typography color="textPrimary" key={`nav_${index}`}>
              <strong>{route.name}</strong>
            </Typography>
          );
        } else {
          return (
            <Link key={`nav_${index}`} component={RouterLink} to={route.path}>
              {route.name}
            </Link>
          );
        }
      })}
    </Breadcrumbs>
  );
};

export default Nav;
