import React from 'react';
import OrdersByStatusNav from './Components/OrdersByStatusNav';
import RepOrders from './Components/RepOrders';
import { Paper, Grid } from '@mui/material';

const OrdersByStatus = () => {
  return (
    <Grid item xs={12}>
      <OrdersByStatusNav />
      <Paper sx={{ p: 2 }}>
        <RepOrders />
      </Paper>
    </Grid>
  );
};

export default OrdersByStatus;
