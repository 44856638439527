import React, { useState } from 'react';
import { Grid, TextField, FormControl, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
  inputId: yup.number().required(),
  avalonName: yup.string('Enter a name').required('Name is required'),
  tahoeName: yup.string(),
  griName: yup.string('need name').nullable(true),
  legendName: yup.string('need name').nullable(true),
  price: yup.number(),
  imgPath: yup.string(),
  optionOrder: yup.number(),
  thumbnailImgPath: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(4),
  },
}));

const AddInputOption = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const { input, setInput, closeAdd } = props;

  const formik = useFormik({
    initialValues: {
      inputId: input.id,
      avalonName: '',
      tahoeName: '',
      griName: '',
      legendName: '',
      price: 0,
      imgPath: '',
      optionOrder: input.inputOptions.length + 1,
      thumbnailImgPath: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const { data } = await axios.post('/api/input/option', values);
        let newOption = data.inputOption;
        newOption.tags = [];
        setInput({
          ...input,
          inputOptions: [...input.inputOptions, newOption],
        });
        setSubmitError(null);
        resetForm();
        closeAdd();
      } catch (err) {
        setSubmitError(err.response.data.message);
      }
    },
  });

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      {submitError !== null ? (
        <h3 style={{ color: '#f4436', marginTop: '0px' }}>{submitError}</h3>
      ) : null}
      <Grid container alignContent="space-between" spacing={3}>
        <Grid item xs={12} sm={input.branded ? 6 : 12}>
          <FormControl fullWidth>
            <TextField
              id="avalonName"
              name="avalonName"
              label={input.branded ? 'Avalon Name' : 'Name'}
              value={formik.values.avalonName}
              onChange={formik.handleChange}
              className={classes.formField}
              error={formik.touched.avalonName && Boolean(formik.errors.avalonName)}
              helperText={formik.touched.avalonName && formik.errors.avalonName}
              fullWidth
            />
          </FormControl>
        </Grid>
        {input.branded ? (
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                id="tahoeName"
                name="tahoeName"
                label="Tahoe Name"
                value={formik.values.tahoeName}
                onChange={formik.handleChange}
                className={classes.formField}
                error={formik.touched.tahoeName && Boolean(formik.errors.tahoeName)}
                helperText={formik.touched.tahoeName && formik.errors.tahoeName}
                fullWidth
              />
            </FormControl>
          </Grid>
        ) : null}
        {input.branded ? (
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                id="griName"
                name="griName"
                label="GRI Name"
                value={formik.values.griName}
                onChange={formik.handleChange}
                className={classes.formField}
                error={formik.touched.griName && Boolean(formik.errors.griName)}
                helperText={formik.touched.griName && formik.errors.griName}
                fullWidth
              />
            </FormControl>
          </Grid>
        ) : null}
        {input.branded ? (
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                id="legendName"
                name="legendName"
                label="Legend Name"
                value={formik.values.legendName}
                onChange={formik.handleChange}
                className={classes.formField}
                error={
                  formik.touched.legendName && Boolean(formik.errors.legendName)
                }
                helperText={formik.touched.legendName && formik.errors.legendName}
                fullWidth
              />
            </FormControl>
          </Grid>
        ) : null}
      </Grid>
      <FormControl fullWidth>
        <TextField
          id="price"
          name="price"
          label="Price"
          value={formik.values.price}
          onChange={formik.handleChange}
          className={classes.formField}
          error={formik.touched.price && Boolean(formik.errors.price)}
          helperText={formik.touched.price && formik.errors.price}
          fullWidth
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="imgPath"
          name="imgPath"
          label="Image Path"
          value={formik.values.imgPath}
          onChange={formik.handleChange}
          className={classes.formField}
          fullWidth
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="thumbnailImgPath"
          name="thumbnailImgPath"
          label="Thumbnail Image Path"
          value={formik.values.thumbnailImgPath}
          onChange={formik.handleChange}
          className={classes.formField}
          fullWidth
        />
      </FormControl>
      <Button
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        className={classes.submitButton}
      >
        Submit
      </Button>
    </form>
  );
};

export default AddInputOption;
