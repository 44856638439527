import React, { useEffect } from 'react';
import { Typography, Box, Grid, Breadcrumbs, Link } from '@mui/material';
import {
  useParams,
  Link as RouterLink,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Loading from '../../../../../components/Loading';
import Checkbox from './Checkbox';
import Dropdown from './Dropdown';
import Text from './Text';
const useStyles = makeStyles((theme) => ({
  highlightedRule: {
    '& fieldset': {
      borderColor: theme.palette.secondary.main,
      borderWidth: '2px',
    },
  },
}));

const CSVInputWithRules = (props) => {
  const { modelId, floorplanId } = useParams();
  const [
    models,
    floorplans,
    csvFloorplans,
    setCSVFloorplans,
    csv,
    inputsWithRules,
    selectedFloorplan,
    selectedInputWithRules,
    setSelectedInputWithRules,
    viewActiveRules,
    setViewActiveRules,
    rows,
    setRows,
    inputWithRules,
    setInputWithRules,
  ] = useOutletContext();
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (inputWithRules === '')
      navigate(`/settings/rules/csv/model/${modelId}/floorplan/${floorplanId}`);
  }, []);

  const renderActiveButtonText = (option) => {
    let optionActiveRule = option.rules.filter((r) => r.changeType === 'ACTIVE');
    if (optionActiveRule !== undefined && optionActiveRule.length > 0) {
      return optionActiveRule[0].changeValue === 1 ? 'Active' : 'Inactive';
    } else {
      return option.active ? 'Active' : 'Inactive';
    }
  };

  const renderInputSettings = () => {
    switch (inputWithRules.type) {
      case 'CHECKBOX':
        return (
          <Checkbox
            input={inputWithRules}
            classes={classes}
            renderActiveButtonText={renderActiveButtonText}
          />
        );
      case 'DROPDOWN':
        return (
          <Dropdown
            input={inputWithRules}
            classes={classes}
            renderActiveButtonText={renderActiveButtonText}
          />
        );
      case 'TEXT':
        return (
          <Text
            input={inputWithRules}
            classes={classes}
            renderActiveButtonText={renderActiveButtonText}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {!selectedInputWithRules && inputWithRules === null ? (
        <Loading />
      ) : (
        <Box component="div" padding={2}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<Typography variant="h6">-</Typography>}
                  >
                    <Link
                      underline="hover"
                      component={RouterLink}
                      to={`/settings/rules/csv/model/${modelId}`}
                    >
                      <Typography variant="h6">Floorplans</Typography>
                    </Link>
                    <Link
                      underline="hover"
                      component={RouterLink}
                      to={`/settings/rules/csv/model/${modelId}/floorplan/${floorplanId}`}
                    >
                      <Typography variant="h6">
                        {
                          csvFloorplans.filter(
                            (fp) => fp.id === parseInt(floorplanId, 10)
                          )[0].avalonName
                        }
                      </Typography>
                    </Link>
                    <Typography color="text.primary" variant="h6">
                      <strong>{inputWithRules.name}</strong>
                    </Typography>
                  </Breadcrumbs>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {renderInputSettings()}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default CSVInputWithRules;
