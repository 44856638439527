import { Grid, Typography, TextField, MenuItem, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ModelSelect = (props) => {
  const { models, selectedModel, setSelectedModel } = props;

  const navigate = useNavigate();

  const handleSelectModel = (e) => {
    setSelectedModel(e.target.value);
    if (e.target.value === '') {
      navigate(`/settings/rules/csv`);
    } else {
      navigate(`/settings/rules/csv/model/${e.target.value}`);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { mr: 2, my: 2, width: '30ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item>
          <Typography variant="h4">Model Select</Typography>
          <TextField
            id="model-select"
            select
            label="Model"
            value={selectedModel}
            onChange={handleSelectModel}
            size="small"
          >
            <MenuItem value="">Select Model</MenuItem>
            {models !== undefined
              ? models.inputOptions?.map((model) => (
                  <MenuItem key={model.id} value={model.id}>
                    {model.avalonName}
                  </MenuItem>
                ))
              : null}
          </TextField>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ModelSelect;
