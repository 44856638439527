import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import EditUser from '../../forms/EditUser';

const EditUserModal = ({ show, handleClose, user, dealership, setDealership }) => {
  return (
    <Dialog open={show} onClose={handleClose} aria-labelledby="edit-user">
      <DialogTitle id="form-edit-user">Edit User</DialogTitle>
      <DialogContent>
        <EditUser
          user={user}
          dealership={dealership}
          setDealership={setDealership}
          closeEdit={handleClose}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserModal;
