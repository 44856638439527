import { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  MenuItem,
  Autocomplete,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Select,
  Checkbox,
  FormControl,
  InputLabel,
  ImageList,
  ImageListItem,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import axios from 'axios';
import theme from '../../theme';
import CircularProgress from '@mui/material/CircularProgress';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import makeStyles from '@mui/styles/makeStyles';
import { useParams, useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';

const menuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
}));

const BoatShowForm = () => {
  const { id } = useParams();
  const classes = useStyles();
  let navigate = useNavigate();
  const [boatShow, setBoatShow] = useState({
    showName: '',
    dealershipId: null,
    reps: [],
    showDatesBegin: dayjs(new Date()),
    showDatesEnd: dayjs(new Date()),
    datesAttendedBegin: dayjs(new Date()),
    datesAttendedEnd: dayjs(new Date()),
    additionalBrandsDisplayed: '',
    boothDisplayNotes: '',
    competitionNotes: '',
    additionalNotes: '',
    boatsDisplayed: [],
    boatsSold: [],
    images: [],
    removedImages: [],
    removedBoats: [],
  });

  const [submitting, setSubmitting] = useState(false);

  const [reps, setReps] = useState([]);
  const [dealerships, setDealerships] = useState([]);
  const [selectedDealership, setSelectedDealership] = useState(null);

  const [newBoatDisplayed, setNewBoatDisplayed] = useState('');
  const [newBoatSold, setNewBoatSold] = useState('');
  const [loading, setLoading] = useState(false);

  const [files, setFiles] = useState([]);

  useEffect(() => {
    const getDealerships = async () => {
      try {
        const { data } = await axios.get('/api/boat-show/dealerships');
        setDealerships(data);
      } catch (err) {
        console.log(err);
      }
    };

    const getReps = async () => {
      try {
        const { data } = await axios.get('/api/boat-show/reps');
        setReps(data);
      } catch (err) {
        console.log(err);
      }
    };

    const getReport = async () => {
      try {
        const { data } = await axios.get(`/api/boat-show/report?id=${id}`);
        if (data) {
          setBoatShow({
            showName: data.showName,
            dealershipId: data.dealership.id,
            reps: data.reps,
            initialReps: data.reps,
            showDatesBegin: dayjs(data.showDatesBegin),
            showDatesEnd: dayjs(data.showDatesEnd),
            datesAttendedBegin: dayjs(data.datesAttendedBegin),
            datesAttendedEnd: dayjs(data.datesAttendedEnd),
            additionalBrandsDisplayed: data.additionalBrandsDisplayed,
            boothDisplayNotes: data.boothDisplayNotes,
            competitionNotes: data.competitionNotes,
            additionalNotes: data.additionalNotes,
            boatsDisplayed: data.boatShowBoats
              .filter((boat) => boat.category === 'DISPLAYED')
              .map((boat) => ({
                ...boat,
                initialCategory: 'DISPLAYED',
              })),
            boatsSold: data.boatShowBoats
              .filter((boat) => boat.category === 'SOLD')
              .map((boat) => ({
                ...boat,
                initialCategory: 'SOLD',
              })),
            images: data.images,
            removedImages: [],
            removedBoats: [],
          });
          setSelectedDealership(data.dealership);
          // setFiles(data.images.map((image) => ({ data: image })));
          setLoading(false);
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    getDealerships();
    getReps();
    if (id) {
      setLoading(true);
      getReport();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    let uploadedImages = [];

    if (files.length > 0) {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('images', file.file);
      });

      const response = await axios.post('/api/aws/upload/images', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.status === 201) {
        uploadedImages = response.data;
      } else {
        console.log(response);
        setSubmitting(false);
      }
    }

    if (id) {
      let formattedData = {
        id: +id,
        showName: boatShow.showName,
        removeReps: boatShow.initialReps.filter(
          (rep) => !boatShow.reps.find((r) => r.id === rep.id)
        ),
        addReps: boatShow.reps.filter(
          (rep) => !boatShow.initialReps.find((r) => r.id === rep.id)
        ),
        dealershipId: boatShow.dealershipId,
        showDatesBegin: boatShow.showDatesBegin.$d,
        showDatesEnd: boatShow.showDatesEnd.$d,
        datesAttendedBegin: boatShow.datesAttendedBegin.$d,
        datesAttendedEnd: boatShow.datesAttendedEnd.$d,
        additionalBrandsDisplayed: boatShow.additionalBrandsDisplayed,
        boothDisplayNotes: boatShow.boothDisplayNotes,
        competitionNotes: boatShow.competitionNotes,
        additionalNotes: boatShow.additionalNotes,
        removedImages: boatShow.removedImages,
        boatsDisplayed: boatShow.boatsDisplayed.filter(
          (boat) => boat.initialCategory === undefined
        ),
        boatsSold: boatShow.boatsSold.filter(
          (boat) => boat.initialCategory === undefined
        ),
        updatedBoatsDisplayed: boatShow.boatsDisplayed.filter(
          (boat) => boat?.initialCategory === 'SOLD'
        ),
        updatedBoatsSold: boatShow.boatsSold.filter(
          (boat) => boat?.initialCategory === 'DISPLAYED'
        ),
        images: uploadedImages,
        removedBoats: boatShow.removedBoats,
      };

      try {
        const response = await axios.put('/api/boat-show', formattedData);
        if (response.status === 201) {
          navigate(`/boat-show/reports/${id}`);
        }
      } catch (err) {
        console.log(err.message);
      }
    } else {
      try {
        const boatShowData = {
          ...boatShow,
          showDatesBegin: boatShow.showDatesBegin.$d,
          showDatesEnd: boatShow.showDatesEnd.$d,
          datesAttendedBegin: boatShow.datesAttendedBegin.$d,
          datesAttendedEnd: boatShow.datesAttendedEnd.$d,
          images: uploadedImages,
        };

        try {
          const response = await axios.post('/api/boat-show', boatShowData);
          if (response.status === 201) {
            setBoatShow({
              showName: '',
              dealershipId: null,
              reps: [],
              showDatesBegin: dayjs(new Date()),
              showDatesEnd: dayjs(new Date()),
              datesAttendedBegin: dayjs(new Date()),
              datesAttendedEnd: dayjs(new Date()),
              additionalBrandsDisplayed: '',
              boothDisplayNotes: '',
              competitionNotes: '',
              additionalNotes: '',
              boatsDisplayed: [],
              boatsSold: [],
              images: [],
            });
            setFiles([]);
            setSelectedDealership(null);
            setSubmitting(false);
          }
        } catch (err) {
          console.log(err.message);
        }
      } catch (err) {
        console.log(err.message);
        setSubmitting(false);
      }
    }
  };

  const handleSelectDate = (value, key) => {
    setBoatShow({ ...boatShow, [key]: value });
  };

  const handleSelectDealership = (value) => {
    setSelectedDealership(value);
    setBoatShow({ ...boatShow, dealershipId: value?.id ?? null });
  };

  const handleAddBoat = (category) => {
    if (category === 'boatsDisplayed') {
      setBoatShow({
        ...boatShow,
        [category]: [
          ...boatShow[category],
          { id: null, boatName: newBoatDisplayed },
        ],
      });
      setNewBoatDisplayed('');
    }
    if (category === 'boatsSold') {
      setBoatShow({
        ...boatShow,
        [category]: [...boatShow[category], { id: null, boatName: newBoatSold }],
      });
      setNewBoatSold('');
    }
  };

  const handleRemoveBoat = (index, category) => {
    const boats = [...boatShow[category]];
    const boat = boatShow[category][index];
    if (boat.initialCategory !== undefined) {
      setBoatShow({
        ...boatShow,
        [category]: boats.filter((b) => b.id !== boat.id),
        removedBoats: [...boatShow.removedBoats, boat.id],
      });
      return;
    } else {
      boats.splice(index, 1);
      setBoatShow({ ...boatShow, [category]: boats });
    }
  };

  const canSubmit = () => {
    let canSubmit = true;
    if (boatShow.showName === '') canSubmit = false;
    if (boatShow.dealershipId === null) canSubmit = false;
    if (boatShow.repName === '') canSubmit = false;
    return canSubmit;
  };

  const handleSelectRep = (e) => {
    let lastRep = reps.find(
      (rep) => rep.id === e.target.value[e.target.value.length - 1].id
    );
    if (e.target.value.filter((rep) => rep.id === lastRep.id).length > 1) {
      setBoatShow({
        ...boatShow,
        reps: e.target.value.filter((rep) => rep.id !== lastRep.id),
      });
    } else {
      setBoatShow({ ...boatShow, reps: e.target.value });
    }
  };

  const handleMoveBoatCategory = (index, category) => {
    const boats = [...boatShow[category]];
    const boat = boats.splice(index, 1);
    if (category === 'boatsDisplayed') {
      setBoatShow({
        ...boatShow,
        boatsDisplayed: boats,
        boatsSold: [...boatShow.boatsSold, ...boat],
      });
    } else {
      setBoatShow({
        ...boatShow,
        boatsSold: boats,
        boatsDisplayed: [...boatShow.boatsDisplayed, ...boat],
      });
    }
  };

  const addFiles = (newFiles) => {
    newFiles = newFiles.filter((file) => !files.find((f) => f.data === file.data));
    setFiles([...files, ...newFiles]);
  };

  const removeFile = (deleted) => {
    setFiles(files.filter((f) => f !== deleted));
  };

  const handleRemoveImage = (image) => {
    setBoatShow({
      ...boatShow,
      images: boatShow.images.filter((i) => i.id !== image.id),
      removedImages: [...boatShow.removedImages, image.id],
    });
  };

  return (
    <Grid container maxWidth="md" sx={{ mx: 'auto' }}>
      <Grid item xs={12}>
        {loading ? (
          <Loading />
        ) : (
          <Paper
            sx={{
              width: '100%',
              px: 2,
              py: 4,
            }}
          >
            <Typography variant="h4" sx={{ pb: 2 }}>
              2023 Avalon/Tahoe Boat Show Report
            </Typography>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    id="showName"
                    name="showName"
                    label="Show Name"
                    variant="outlined"
                    value={boatShow.showName}
                    onChange={(e) =>
                      setBoatShow({ ...boatShow, showName: e.target.value })
                    }
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    id="dealership-search"
                    value={selectedDealership}
                    onChange={(e, newValue) => handleSelectDealership(newValue)}
                    isOptionEqualToValue={(e, v) => {
                      return e.id === v.id;
                    }}
                    options={dealerships}
                    getOptionLabel={(option) => option?.name ?? ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Dealerships"
                        variant="outlined"
                      />
                    )}
                    renderOption={(props, option) => {
                      return (
                        <MenuItem {...props} key={option.id}>
                          {option.name}
                        </MenuItem>
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="rep-select-label">Reps</InputLabel>
                    <Select
                      labelId="rep-select-label"
                      id="rep-select"
                      multiple
                      value={boatShow.reps}
                      onChange={(e) => handleSelectRep(e)}
                      renderValue={(selected) =>
                        selected.map((rep) => rep.name).join(', ')
                      }
                      MenuProps={menuProps}
                      fullWidth
                    >
                      {reps.map((rep) => (
                        <MenuItem key={rep.id} value={rep}>
                          <Checkbox
                            checked={
                              boatShow.reps.find((r) => r.id === rep.id) !==
                              undefined
                            }
                          />
                          {rep.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Date of Show - Begin"
                        inputFormat="MM/DD/YYYY"
                        value={boatShow.showDatesBegin}
                        onChange={(e) => handleSelectDate(e, 'showDatesBegin')}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Date of Show - End"
                        inputFormat="MM/DD/YYYY"
                        value={boatShow.showDatesEnd}
                        onChange={(e) => handleSelectDate(e, 'showDatesEnd')}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Dates Attended - Begin"
                        inputFormat="MM/DD/YYYY"
                        value={boatShow.datesAttendedBegin}
                        onChange={(e) => handleSelectDate(e, 'datesAttendedBegin')}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Dates Attended - End"
                        inputFormat="MM/DD/YYYY"
                        value={boatShow.datesAttendedEnd}
                        onChange={(e) => handleSelectDate(e, 'datesAttendedEnd')}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      Avalon/Tahoe Boats/Engines Displayed
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    {boatShow.boatsDisplayed.length > 0 && (
                      <TableContainer>
                        <Table sx={{ minWidth: '500px' }}>
                          <TableHead>
                            <TableRow>
                              <TableCell>#</TableCell>
                              <TableCell>Boat</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {boatShow.boatsDisplayed.map((boat, index) => (
                              <TableRow key={`displayed_${boat.id}`}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                  {boat.boatName !== null
                                    ? boat.boatName
                                    : `${boat.model} - ${boat.floorplan}`}
                                </TableCell>
                                <TableCell>
                                  <Button
                                    type="button"
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={() =>
                                      handleMoveBoatCategory(index, 'boatsDisplayed')
                                    }
                                    sx={{ mr: 2 }}
                                  >
                                    Move to Sold
                                  </Button>
                                  <Button
                                    type="button"
                                    size="small"
                                    color="error"
                                    variant="contained"
                                    onClick={() =>
                                      handleRemoveBoat(index, 'boatsDisplayed')
                                    }
                                  >
                                    Remove
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddBoat('boatsDisplayed')}
                      sx={{ display: 'inline-flex' }}
                    >
                      Add Boat
                    </Button>

                    <TextField
                      id="new-boat-sold"
                      name="new-boat-sold"
                      placeholder="Add A Boat to the List"
                      value={newBoatDisplayed}
                      onChange={(e) => setNewBoatDisplayed(e.target.value)}
                      sx={{ display: 'inline-flex', flexGrow: 1, ml: 2 }}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Avalon/Tahoe Boats Sold</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    {boatShow.boatsSold.length > 0 && (
                      <TableContainer>
                        <Table sx={{ minWidth: '500px' }}>
                          <TableHead>
                            <TableRow>
                              <TableCell>#</TableCell>
                              <TableCell>Boat</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {boatShow.boatsSold.map((boat, index) => (
                              <TableRow key={`sold_${boat.id}`}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                  {boat.boatName !== null
                                    ? boat.boatName
                                    : `${boat.model} - ${boat.floorplan}`}
                                </TableCell>
                                <TableCell>
                                  <Button
                                    type="button"
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={() =>
                                      handleMoveBoatCategory(index, 'boatsSold')
                                    }
                                    sx={{ mr: 2 }}
                                  >
                                    Move to Displayed
                                  </Button>
                                  <Button
                                    type="button"
                                    size="small"
                                    color="error"
                                    variant="contained"
                                    onClick={() =>
                                      handleRemoveBoat(index, 'boatsSold')
                                    }
                                  >
                                    Remove
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddBoat('boatsSold')}
                      sx={{ display: 'inline-flex' }}
                    >
                      Add Boat
                    </Button>

                    <TextField
                      id="new-boat-sold"
                      name="new-boat-sold"
                      placeholder="Add A Boat to the List"
                      value={newBoatSold}
                      onChange={(e) => setNewBoatSold(e.target.value)}
                      sx={{ display: 'inline-flex', flexGrow: 1, ml: 2 }}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="additionalBrandsDisplayed"
                    name="additionalBrandsDisplayed"
                    label="Additional Brands Displayed"
                    variant="outlined"
                    value={boatShow.additionalBrandsDisplayed}
                    onChange={(e) =>
                      setBoatShow({
                        ...boatShow,
                        additionalBrandsDisplayed: e.target.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="boothDisplayNotes"
                    name="boothDisplayNotes"
                    label="Booth/Display Notes"
                    variant="outlined"
                    value={boatShow.boothDisplayNotes}
                    onChange={(e) =>
                      setBoatShow({
                        ...boatShow,
                        boothDisplayNotes: e.target.value,
                      })
                    }
                    fullWidth
                    multiline
                    rows={3}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="competitionNotes"
                    name="competitionNotes"
                    label="Competition Notes"
                    variant="outlined"
                    value={boatShow.competitionNotes}
                    onChange={(e) =>
                      setBoatShow({
                        ...boatShow,
                        competitionNotes: e.target.value,
                      })
                    }
                    fullWidth
                    multiline
                    rows={3}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="additionalNotes"
                    name="additionalNotes"
                    label="Additional Notes"
                    variant="outlined"
                    value={boatShow.additionalNotes}
                    onChange={(e) =>
                      setBoatShow({
                        ...boatShow,
                        additionalNotes: e.target.value,
                      })
                    }
                    fullWidth
                    multiline
                    rows={3}
                  />
                </Grid>

                {boatShow.images.filter((image) => image.imgPath.endsWith('.pdf'))
                  .length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant="h6">PDFs</Typography>
                    <ul>
                      {boatShow.images
                        .filter((image) => image.imgPath.endsWith('.pdf'))
                        .map((image) => (
                          <li key={`pdf_${image.id}`}>
                            <a
                              href={`https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/${image.imgPath}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {image.imgPath}
                            </a>

                            <Button
                              size="small"
                              color="primary"
                              sx={{ ml: 2 }}
                              onClick={() => handleRemoveImage(image)}
                            >
                              Remove
                            </Button>
                          </li>
                        ))}
                    </ul>
                  </Grid>
                )}

                {boatShow.images.filter((image) => !image.imgPath.endsWith('.pdf'))
                  .length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant="h6">Photos</Typography>

                    <ImageList
                      sx={{ height: 410 }}
                      cols={3}
                      gap={16}
                      rowHeight={200}
                    >
                      {boatShow.images
                        .filter((image) => !image.imgPath.endsWith('.pdf'))
                        .map((image) => (
                          <ImageListItem
                            key={`photo_${image.id}`}
                            sx={{
                              border: '1px solid rgba(0, 0, 0, 0.27)',
                              borderRadius: '5px',
                            }}
                          >
                            <img
                              src={`https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/${image.imgPath}`}
                              alt={image.imgPath}
                              loading="lazy"
                            />
                            <Button
                              size="small"
                              color="primary"
                              sx={{ mt: 1 }}
                              onClick={() => handleRemoveImage(image)}
                            >
                              Remove
                            </Button>
                          </ImageListItem>
                        ))}
                    </ImageList>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Upload Photos
                  </Typography>
                  <DropzoneAreaBase
                    showAlerts={false}
                    showPreviews={true}
                    showPreviewsInDropzone={false}
                    acceptedFiles={['image/*', 'application/pdf']}
                    useChipsForPreview
                    previewGridProps={{
                      container: { spacing: 1, direction: 'row' },
                    }}
                    previewChipProps={{ classes: { root: classes.previewChip } }}
                    filesLimit={20}
                    fileObjects={files}
                    onAdd={(newFiles) => addFiles(newFiles)}
                    onDelete={(deleted) => removeFile(deleted)}
                  />
                </Grid>
              </Grid>

              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{ mt: 4, px: 4 }}
                disabled={!canSubmit() || submitting}
              >
                {submitting ? 'Submitting...' : 'Submit'}
              </Button>
            </form>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

export default BoatShowForm;
