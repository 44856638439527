import React, { useState } from 'react';
import {
  TextField,
  FormControl,
  Button,
  FormControlLabel,
  Switch as Toggle,
  Typography,
  Grid,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DropzoneArea } from 'material-ui-dropzone';

const validationSchema = yup.object({
  id: yup.string(),
  title: yup.string('Please enter a title').required('Title is required'),
  text: yup.string(),
  filename: yup.string(),
  active: yup.boolean(),
});

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const AddNotification = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const { closeAdd, notification, notifications, setNotifications, type } = props;
  const [files, setFiles] = useState([]);

  const handleFileChange = (files) => {
    console.log('Files: ', files);
    setFiles(files);
  };

  const formik = useFormik({
    initialValues: {
      id: notification.id,
      title: notification.title,
      text: notification.text === null ? '' : notification.text,
      filename: notification.filename === null ? '' : notification.filename,
      active: notification.active,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        let formData = new FormData();
        if (files.length > 0) formData.append('file', files[0], files[0].name);
        formData.append('title', values.title);
        formData.append('text', values.text);
        formData.append('active', values.active);
        formData.append('filename', values.filename);
        formData.append('id', values.id);
        const { data } = await axios.put('/api/notifications', formData);

        setNotifications(
          notifications.map((n) => {
            return n.id === notification.id ? data.notification : n;
          })
        );
        setSubmitError(null);
        setFiles([]);
        resetForm();
        closeAdd();
      } catch (err) {
        setSubmitError(err.response.data.message);
      }
    },
  });

  const handleSendNotification = async () => {
    console.log(notification.id);
    try {
      const { data } = await axios.get('/api/notification/send', {
        params: { id: notification.id },
      });

      if (data.message === 'success') {
        setNotifications(
          notifications.map((n) => {
            return n.id === notification.id ? { ...notification, sent: true } : n;
          })
        );
        closeAdd();
      }

      console.log('HERE: ', data);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      {submitError !== null ? (
        <h3 style={{ color: '#f44336', marginTop: '0px' }}>{submitError}</h3>
      ) : null}
      <FormControl fullWidth>
        <TextField
          id="title"
          name="title"
          label="Title"
          value={formik.values.title}
          onChange={formik.handleChange}
          className={classes.textField}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
          fullWidth
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="text"
          name="text"
          label="Text"
          value={formik.values.text}
          onChange={formik.handleChange}
          className={classes.textField}
          error={formik.touched.text && Boolean(formik.errors.text)}
          helperText={formik.touched.text && formik.errors.text}
          multiline
        />
      </FormControl>
      <FormControl fullWidth>
        <Typography variant="subtitle2">
          To remove file, clear out filename field
        </Typography>
        <TextField
          id="filename"
          name="filename"
          label="Filename"
          value={formik.values.filename}
          onChange={formik.handleChange}
          className={classes.textField}
          error={formik.touched.filename && Boolean(formik.errors.filename)}
          helperText={formik.touched.filename && formik.errors.filename}
          fullWidth
        />
      </FormControl>
      <FormControl fullWidth>
        <DropzoneArea
          onChange={(files) => handleFileChange(files)}
          filesLimit={1}
          showAlerts={false}
          showFileNames
        />
      </FormControl>
      <FormControl>
        <FormControlLabel
          control={
            <Toggle
              checked={formik.values.active}
              onChange={formik.handleChange}
              id="active"
              name="active"
            />
          }
          label="Active"
        />
      </FormControl>
      {notification.sent === true || notification.isFaq ? (
        <Button color="primary" variant="contained" fullWidth type="submit">
          Save
        </Button>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Save
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              type="button"
              onClick={() => handleSendNotification()}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default AddNotification;
