import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  TextField,
  InputAdornment,
  Button,
  Grid,
  Breadcrumbs,
  Link,
} from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import SearchIcon from '@mui/icons-material/Search';
import {
  useNavigate,
  Link as RouterLink,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const CSVInputsWithRules = () => {
  const [
    models,
    floorplans,
    csvFloorplans,
    setCSVFloorplans,
    csv,
    inputsWithRules,
    selectedFloorplan,
    selectedInputWithRules,
    setSelectedInputWithRules,
    viewActiveRules,
    setViewActiveRules,
    rows,
    setRows,
    inputWithRules,
    setInputWithRules,
  ] = useOutletContext();
  const { modelId } = useParams();
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      let tmpData = [...inputsWithRules];
      let filteredData = [];
      let columns = tmpData.length !== 0 ? Object.keys(tmpData[0]) : [];
      columns = columns.filter((c) => {
        return true;
      });

      for (let d of tmpData) {
        let addFlag = false;
        for (let c of columns) {
          let columnString = d[c];
          let string = String(columnString).toLowerCase();
          if (string.includes(searchText.toLowerCase())) addFlag = true;
        }

        if (addFlag) filteredData.push(d);
      }

      setRows(filteredData);
    }, 100);

    return () => clearTimeout(timer);
  }, [searchText, inputsWithRules]);

  const handleSelectInputWithRules = () => {
    setInputWithRules(
      inputsWithRules.filter((input) => input.id === selectedInputWithRules)[0]
    );

    navigate(`input/${selectedInputWithRules}`);
  };

  return (
    <Box component="div">
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<Typography variant="h6">-</Typography>}
        sx={{ mb: 2 }}
      >
        <Link
          underline="hover"
          component={RouterLink}
          to={`/settings/rules/csv/model/${modelId}`}
        >
          <Typography variant="h6">Floorplans</Typography>
        </Link>
        <Typography color="text.primary" variant="h6">
          <strong>
            {selectedFloorplan !== null ? selectedFloorplan.avalonName : null}
          </strong>
        </Typography>
      </Breadcrumbs>
      <Box component="div">
        <Grid container justifyContent="space-between">
          <Grid item>
            <TextField
              id="input-search"
              label="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              size="small"
              sx={{ width: '45ch', mb: 2 }}
            />
            <Button
              variant="contained"
              color={viewActiveRules ? 'secondary' : 'primary'}
              onClick={() => setViewActiveRules(!viewActiveRules)}
              sx={{ ml: 2 }}
            >
              {viewActiveRules ? 'View CSV Rules' : 'View Active Rules'}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
              disabled={selectedInputWithRules.length === 0}
              onClick={handleSelectInputWithRules}
            >
              View input
            </Button>
          </Grid>
        </Grid>
      </Box>
      <DataGridPro
        autoHeight
        columns={[
          { field: 'id', headerName: 'ID', width: 90 },
          { field: 'name', headerName: 'Name', width: 240 },
          {
            field: 'numRules',
            headerName: '# of rules',
            width: 150,
          },
        ]}
        pageSize={25}
        rows={rows}
        rowHeight={38}
        checkboxSelection
        selectionModel={selectedInputWithRules}
        onSelectionModelChange={(value) => {
          if (value.length === 0) {
            setSelectedInputWithRules([]);
          } else {
            setSelectedInputWithRules(value[value.length - 1]);
          }
        }}
        pagination
      />
    </Box>
  );
};

export default CSVInputsWithRules;
