import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  TextField,
  MenuItem,
  Box,
  Autocomplete,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const RuleSettings = () => {
  const { pageId, inputId, yValue, zId } = useParams();
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState('');
  const [inputs, setInputs] = useState([]);
  const [selectedInput, setSelectedInput] = useState(null);
  const [yValues, setYValues] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  let navigate = useNavigate();

  useEffect(() => {
    const initPages = async () => {
      const { data } = await axios.get('/api/pages');
      setPages(data);
      if (pageId !== undefined) setSelectedPage(pageId);
    };

    const loadPageInputs = async () => {
      const { data } = await axios.get('/api/page', { params: { id: pageId } });
      if (data.page === null) navigate('/settings/rules/single');
      setInputs(data.page.inputs);
      setYValues([]);
    };

    initPages();
    if (pageId !== undefined) loadPageInputs();
  }, [pageId]);

  useEffect(() => {
    const loadInputOptions = async () => {
      const { data } = await axios.get('/api/input', { params: { id: inputId } });
      if (data.input === null || data.input.pageId !== parseInt(pageId, 10)) {
        navigate(`/settings/rules/single/page/${pageId}`);
      }
      setSelectedInput(data.input);
      let tmpYValues = [];
      if (data.input.type === 'CHECKBOX') {
        tmpYValues = [
          { id: 0, avalonName: 'Not Selected' },
          { id: 1, avalonName: 'Selected' },
        ];
      } else {
        tmpYValues = [
          { id: 0, avalonName: 'Not Selected' },
          ...data.input.inputOptions.filter((io) => !io.isDeleted),
        ];
      }
      setYValues(tmpYValues);

      if (yValue !== undefined) {
        let tmpValue = tmpYValues.find((value) => value.id === parseInt(yValue, 10));
        if (tmpValue === undefined) {
          setSelectedValue(null);
          navigate(`/settings/rules/single/page/${pageId}/input/${inputId}`);
        } else {
          setSelectedValue(tmpValue);
        }
      } else {
        setSelectedValue(null);
      }
    };

    if (inputId !== undefined) loadInputOptions();
  }, [inputId]);

  const handleSelectPage = (e) => {
    setSelectedPage(e.target.value);
    setSelectedInput(null);
    setSelectedValue(null);
    if (e.target.value === '') {
      navigate(`/settings/rules/single`);
    } else {
      navigate(`/settings/rules/single/page/${e.target.value}`);
    }
  };

  const handleSelectInput = (e, option) => {
    if (option === null) {
      setSelectedInput(null);
      setSelectedValue(null);
      navigate(`/settings/rules/single/page/${pageId}`);
    } else {
      setSelectedInput(option);
      setSelectedValue(null);
      navigate(`/settings/rules/single/page/${pageId}/input/${option.id}`);
    }
  };

  const handleSelectValue = (e, option) => {
    if (option === null) {
      setSelectedValue(null);
      navigate(`/settings/rules/single/page/${pageId}/input/${inputId}`);
    } else {
      setSelectedValue(option);
      navigate(
        `/settings/rules/single/page/${pageId}/input/${inputId}/value/${option.id}`
      );
    }
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { mr: 2, my: 2, width: '30ch' },
      }}
      padding={2}
      noValidate
      autoComplete="off"
    >
      <Typography variant="h4">Rule Settings</Typography>
      <Grid container>
        <Grid item>
          <div>
            <TextField
              id="page-select"
              select
              label="Page"
              value={selectedPage}
              onChange={handleSelectPage}
              size="small"
              disabled={zId !== undefined}
            >
              <MenuItem value="">Select Page</MenuItem>
              {pages !== undefined
                ? pages.map((page) => (
                    <MenuItem key={page.id} value={page.id}>
                      {page.name}
                    </MenuItem>
                  ))
                : null}
            </TextField>
          </div>
        </Grid>
        <Grid item>
          <div>
            <Autocomplete
              id="input-select"
              options={
                inputs !== undefined
                  ? inputs.filter(
                      (input) => input.type !== 'TEXT' && input.id !== 142
                    )
                  : []
              }
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Input" size="small" />
              )}
              onChange={handleSelectInput}
              value={selectedInput}
              disabled={zId !== undefined}
            />
          </div>
        </Grid>
        <Grid item>
          <div>
            <Autocomplete
              id="value-select"
              options={yValues}
              getOptionLabel={(option) => option.avalonName}
              renderInput={(params) => (
                <TextField {...params} label="Value" size="small" />
              )}
              onChange={handleSelectValue}
              value={selectedValue}
              disabled={zId !== undefined}
            />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RuleSettings;
