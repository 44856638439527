import React, { useState } from 'react';
import {
  TextField,
  Select,
  FormControl,
  Button,
  Grid,
  InputLabel,
  MenuItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import * as yup from 'yup';
import Loading from '../Loading';

const validationSchema = yup.object({
  inputId: yup.number().required(),
  title: yup.string('enter a title').required(),
  text: yup.string(),
  media: yup.array(),
});

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(4),
  },
}));

const EditTooltip = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { input, tooltip, setTooltip, setInput, closeModal } = props;

  const formik = useFormik({
    initialValues: {
      inputId: input.id,
      title: input.tooltip === null ? '' : input.tooltip.title,
      text: input.tooltip === null ? '' : input.tooltip.text,
      media: input.tooltip === null ? [] : input.tooltip.media,
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        if (tooltip.id === null) {
          // add tooltip
          let { data } = await axios.post('/api/input/tooltip', values);
          if (data.media.length > 0) data.selectedMedia = 0;
          setInput({ ...input, tooltip: data });
          setTooltip(data);
          resetForm();
          setLoading(false);
          closeModal();
        } else {
          let tmpValues = {
            ...values,
            oldMedia: input.tooltip.media,
            id: input.tooltip.id,
          };
          console.log(values, tmpValues);
          let { data } = await axios.put('/api/input/tooltip', tmpValues);
          if (data.media.length > 0) data.selectedMedia = 0;
          setInput({ ...input, tooltip: data });
          setTooltip(data);
          resetForm();
          setLoading(false);
          closeModal();
        }
      } catch (err) {
        setSubmitError(err.response.data.message);
      }
    },
  });

  return (
    <>
      <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
        {submitError !== null ? (
          <h3 style={{ color: '#f4436', marginTop: '0px' }}>{submitError}</h3>
        ) : null}
        {loading ? (
          <Loading text="Saving tooltip..." />
        ) : (
          <Grid container alignContent="space-between" spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  id="title"
                  name="title"
                  label="Title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  className={classes.formField}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  id="text"
                  name="text"
                  label="Text"
                  value={formik.values.text}
                  onChange={formik.handleChange}
                  className={classes.formField}
                  error={formik.touched.text && Boolean(formik.errors.text)}
                  helperText={formik.touched.text && formik.errors.text}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormikProvider value={formik}>
                  <FieldArray name="media">
                    {({ push, remove }) => (
                      <Grid container>
                        {formik.values.media.map((m, index) => {
                          const name = `media[${index}]`;
                          return (
                            <Grid
                              key={`media_${index}`}
                              container
                              spacing={2}
                              alignItems="center"
                            >
                              <Grid item xs={12} sm={3}>
                                <FormControl fullWidth>
                                  <InputLabel>Media Type</InputLabel>
                                  <Select
                                    name={`${name}.type`}
                                    value={m.type}
                                    onChange={formik.handleChange}
                                    fullWidth
                                  >
                                    <MenuItem value="image">Image</MenuItem>
                                    <MenuItem value="video">Video</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                  <TextField
                                    name={`${name}.src`}
                                    value={m.src}
                                    label="Source"
                                    onChange={formik.handleChange}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <Button
                                  color="primary"
                                  size="small"
                                  variant="outlined"
                                  onClick={() => remove(index)}
                                >
                                  remove
                                </Button>
                              </Grid>
                            </Grid>
                          );
                        })}
                        <Grid item xs={12} style={{ margin: '16px 0px' }}>
                          <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => push({ type: 'image', src: '' })}
                          >
                            Add Media
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </FieldArray>
                </FormikProvider>
              </FormControl>
            </Grid>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Submit
            </Button>
          </Grid>
        )}
      </form>
    </>
  );
};

export default EditTooltip;
