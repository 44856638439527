import { useState } from 'react';
import { TextField, FormControl, Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { DropzoneArea } from 'material-ui-dropzone';
import { formatPrice } from '../../lib';

const validationSchema = yup.object({
  orderId: yup.number().required(),
  dealershipId: yup.number().required(),
  rebateAmount: yup
    .number()
    .moreThan(0, 'Please enter a value greater than 0.')
    .required(),
  saleAmount: yup
    .number()
    .moreThan(0, 'Please enter a value greater than 0.')
    .required(),
  rebateStatus: yup.string().required(),
  epicorOrderNumber: yup.number().nullable(),
});

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2),
  },
  formMarginTop2: {
    marginTop: theme.spacing(2),
  },
}));

const AddRebate = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const { selectedOrder, setSelectedOrder, handleCloseModal, totalPrice } = props;
  const [files, setFiles] = useState([]);
  const handleFileChange = (files) => {
    setFiles(files);
  };

  const formik = useFormik({
    initialValues: {
      orderId: selectedOrder.id,
      dealershipId: selectedOrder.dealershipId,
      rebateAmount: 0,
      saleAmount: 0,
      rebateStatus: 'PENDING',
      epicorOrderNumber: selectedOrder?.epicorOrderNumber ?? null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setSubmitting(true);
        let formData = new FormData();
        if (files.length > 0) formData.append('file', files[0], files[0].name);
        for (let key in values) {
          formData.append(key, values[key]);
        }
        const { data: newRebate } = await axios.post('/api/order/rebate', formData);

        let tmpOrder = {
          ...selectedOrder,
          rebates: [...selectedOrder.rebates, newRebate],
        };

        setSelectedOrder(tmpOrder);
        resetForm();
        setSubmitting(false);
        handleCloseModal();
      } catch (error) {
        setSubmitError(error.response.data.message);
      }
    },
  });

  return (
    <form
      noValidate
      autoComplete="off"
      className={classes.formMarginTop2}
      onSubmit={formik.handleSubmit}
    >
      <Grid container rowSpacing={4}>
        <Grid item container>
          <Grid item sm={3}>
            <TextField
              id="Model"
              label="Model"
              variant="standard"
              value={`${selectedOrder.model} ${selectedOrder.floorplan}`}
              disabled
            />
          </Grid>
          {selectedOrder?.epicorData?.OrderDtl_UnitPrice && (
            <Grid item sm={3}>
              <TextField
                id="price"
                label="Price"
                variant="standard"
                value={formatPrice(selectedOrder?.epicorData?.OrderDtl_UnitPrice)}
                disabled
              />
            </Grid>
          )}
          <Grid item sm={3}>
            <TextField
              id="id"
              label="ATOM ID"
              variant="standard"
              value={selectedOrder.id}
              disabled
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              id="id"
              label="ORDER ID"
              variant="standard"
              value={selectedOrder?.epicorOrderNumber ?? 'N/A'}
              disabled
            />
          </Grid>
        </Grid>
        <Grid item container columnSpacing={4}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth className={classes.formField}>
              <TextField
                id="saleAmount"
                name="saleAmount"
                label="Sale Amount"
                variant="standard"
                value={formik.values.saleAmount}
                onChange={formik.handleChange}
                error={
                  formik.touched.saleAmount && Boolean(formik.errors.saleAmount)
                }
                helperText={formik.touched.saleAmount && formik.errors.saleAmount}
                type="number"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth className={classes.formField}>
              <TextField
                id="rebateAmount"
                name="rebateAmount"
                label="Rebate Amount"
                variant="standard"
                value={formik.values.rebateAmount}
                onChange={formik.handleChange}
                error={
                  formik.touched.rebateAmount && Boolean(formik.errors.rebateAmount)
                }
                helperText={
                  formik.touched.rebateAmount && formik.errors.rebateAmount
                }
                type="number"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <Typography variant="h6" sx={{ my: 4 }}>
          Upload Bill of Sale
        </Typography>
        <DropzoneArea
          onChange={(files) => handleFileChange(files)}
          filesLimit={1}
          showAlerts={false}
          showFileNames
        />
        {submitError && (
          <Typography variant="h5" sx={{ my: 4, color: '#f44336' }}>
            {submitError}
          </Typography>
        )}
      </div>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={submitting}
        sx={{ mt: 2 }}
      >
        {submitting ? 'Requesting rebate...' : 'Request Rebate'}
      </Button>
      {submitError && <p>{submitError}</p>}
    </form>
  );
};

export default AddRebate;
