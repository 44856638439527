import React, { useMemo } from 'react';
import { Grid, Box, Typography, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: '0.75rem',
    color: '#747679',
  },
  formField: {
    marginBottom: theme.spacing(1),
  },
  box: {
    height: '63px',
    width: '63px',
    display: 'flex',
    '&:hover': {
      border: `3px solid ${theme.palette.secondary.main}`,
      cursor: 'pointer',
    },
    boxSizing: 'border-box',
    margin: 'auto',
  },
  exteriorBox: {
    position: 'relative',
    height: '32px',
    width: '32px',
  },
  imgContainer: {
    padding: '4px!important',
    display: 'flex',
  },
  imgAlt: {
    width: '100%',
  },
  price: {
    fontSize: '0.75em',
    textAlign: 'center',
    color: theme.palette.secondary.main,
  },
  selected: {
    border: `3px solid ${theme.palette.secondary.main}`,
  },
  optionName: {
    fontSize: '0.70em',
    fontWeight: '500',
    maxWidth: '65px',
    wordWrap: 'break-work',
  },
  thumbnailContainer: {
    textAlign: 'center',
  },
  effect: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    width: '5px',
    height: '5px',
    backgroundColor: '#fff',
    filter: 'blur(3px)',
  },
}));

const GridSelect = (props) => {
  const {
    input,
    handleInputChange,
    formatPrice,
    customerBuild,
    modelId,
    calculatePriceWithMarkup,
  } = props;
  const classes = useStyles();
  const { formType } = useParams();

  const isExteriorInput = useMemo(() => {
    return input.overlayImage === 'BOAT';
  }, [input]);

  const checkImage = (io) => {
    if (io.thumbnailImgPath === null) {
      return (
        <Typography variant="subtitle1" align="center" className={classes.imgAlt}>
          {io.avalonName}
        </Typography>
      );
    }
  };

  const filters = (io) => {
    return io.active && io.thumbnailImgPath;
  };

  let findValue = (input) => {
    let inputOption = input.inputOptions.find((io) => io.id === input.value);
    if (!inputOption) {
      console.log('BAD VALUE: ', input.name, input.value);
    } else {
      return `${inputOption.avalonName}  ${calculatePriceWithMarkup(
        inputOption.price,
        +modelId
      )}`;
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="subtitle">{input.name}</Typography>
      <Typography className={classes.title}>
        {input.value !== '' ? findValue(input) : null}
      </Typography>
      <Grid container className={classes.formField} spacing={2}>
        {input.inputOptions
          .filter((io) => filters(io))
          .map((io) => {
            return (
              <Grid item key={io.id} className={classes.thumbnailContainer}>
                <Tooltip title={io.avalonName} placement="top">
                  <Box
                    className={clsx(classes.box, {
                      [classes.exteriorBox]: isExteriorInput,
                      [classes.selected]:
                        input.value !== '' && input.value === io.id,
                    })}
                    borderRadius={isExteriorInput ? '100%' : '5px'}
                    style={{
                      backgroundImage:
                        'url(https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/images/' +
                        io.thumbnailImgPath +
                        ')',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                    }}
                    onClick={() => handleInputChange(io.id, input)}
                  >
                    {checkImage(io)}
                    {isExteriorInput && <div className={classes.effect} />}
                  </Box>
                </Tooltip>
                {!isExteriorInput && io.price !== 0 ? (
                  <div className={classes.price}>
                    +{calculatePriceWithMarkup(io.price, +modelId)}
                  </div>
                ) : null}
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default GridSelect;
