import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

const RemoveUserModal = ({ show, handleClose, data, handleConfirmRemove }) => {
  return (
    <Dialog open={show} onClose={handleClose} aria-labelledby="edit-user">
      <DialogTitle id="form-delete-user">Remove User</DialogTitle>
      <DialogContent>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Are you sure you want to remove <strong>{data.user.username}</strong> from{' '}
          <strong>{data.location.name}</strong>?
        </Typography>
        <Button
          color="error"
          variant="contained"
          sx={{ display: 'block' }}
          onClick={handleConfirmRemove}
        >
          Remove
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveUserModal;
