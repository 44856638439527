import { Grid, Typography } from '@mui/material';

const ErrorElement = () => {
  return (
    <Grid container>
      <Grid item>
        <Typography variant="h3">
          Oops! Looks like something went wrong, please refresh the page and try
          again or reach out to support.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ErrorElement;
