import React, { useState } from 'react';
import { TextField, FormControl, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
  name: yup.string('Enter the group name').required(),
  id: yup.number(),
  pageOrder: yup.number(),
});

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(0, 2, 2, 2),
  },
}));

const EditPageGroup = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const { page, setPage, closeAdd, pageGroup } = props;

  const formik = useFormik({
    initialValues: {
      id: pageGroup.id,
      name: pageGroup.name,
      pageOrder: pageGroup.pageOrder,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const { data } = await axios.put('/api/page/group', values);

        setPage({
          ...page,
          pageGroups: page.pageGroups.map((pg) =>
            pg.id === pageGroup.id ? data.pageGroup : pg
          ),
        });
        setSubmitError(null);
        resetForm();
        closeAdd();
      } catch (err) {
        setSubmitError(err.response.data.message);
      }
    },
  });

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      {submitError !== null ? (
        <h3 style={{ color: '#f4436', marginTop: '0px' }}>{submitError}</h3>
      ) : null}
      <FormControl fullWidth>
        <TextField
          fullWidth
          id="name"
          name="name"
          label="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          className={classes.textField}
          variant="standard"
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          fullWidth
          id="pageOrder"
          name="pageOrder"
          label="Page Order"
          value={formik.values.pageOrder}
          onChange={formik.handleChange}
          className={classes.textField}
          variant="standard"
        />
      </FormControl>
      <Button color="primary" variant="contained" fullWidth type="submit">
        Submit
      </Button>
    </form>
  );
};

export default EditPageGroup;
