import React, { useState } from 'react';
import {
  TextField,
  Select,
  FormControl,
  Button,
  Grid,
  MenuItem,
  Typography,
  FormControlLabel,
  Switch as Toggle,
} from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
  markup: yup.number('Enter a whole number'),
  floorplanDiscount: yup.number('Enter a whole number'),
  floorplanDiscount2: yup.number('Enter a whole number'),
  motorDiscount: yup.number('Enter a whole number'),
  motorDiscount2: yup.number('Enter a whole number'),
  locationDiscountType: yup.string().oneOf(['RETAIL', 'DEALER', 'BOTH']),
  useModelMarkup: yup.boolean(),
  modelMarkups: yup.array(),
});

const EditLocationMarkupAndDiscount = (props) => {
  const [submitError, setSubmitError] = useState(null);
  const { dealership, setDealership, closeAdd, location } = props;

  let initialValues = {
    id: location.id,
    name: location.name,
    markup: location.markup,
    floorplanDiscount: location.floorplanDiscount,
    floorplanDiscount2: location.floorplanDiscount2,
    motorDiscount: location.motorDiscount,
    motorDiscount2: location.motorDiscount2,
    locationDiscountType: location.locationDiscountType,
    useModelMarkup: location.useModelMarkup ?? false,
    modelMarkups: dealership.activeModels.map((model) => {
      let existingMarkup = location.modelMarkups.find(
        (mm) => mm.modelId === model.id
      );
      return {
        id: existingMarkup ? existingMarkup.id : null,
        modelId: model.id,
        markup: existingMarkup ? existingMarkup.markup : model.markup,
        locationId: location.id,
        name: model.name,
      };
    }),
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        let { data } = await axios.put('/api/location/markup-discount', values);
        setDealership({
          ...dealership,
          locations: dealership.locations.map((l) => {
            if (l.id === data.location.id) {
              return data.location;
            } else {
              return l;
            }
          }),
        });
        setSubmitError(null);
        closeAdd();
      } catch (err) {
        setSubmitError(err.respoance.data.message);
      }
    },
  });

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      {submitError !== null ? (
        <h3 style={{ color: '#f44336', marginTop: '0px' }}>{submitError}</h3>
      ) : null}
      <Grid
        item
        container
        justifyContent="space-between"
        spacing={2}
        sx={{ marginBottom: '24px' }}
      >
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ marginBottom: '16px' }}>
              Discounts
            </Typography>
            <Select
              id="locationDiscountType"
              name="locationDiscountType"
              label="Location Discount Type"
              value={formik.values.locationDiscountType}
              onChange={formik.handleChange}
              fullWidth
            >
              <MenuItem value="RETAIL">Retail Build Only</MenuItem>
              <MenuItem value="DEALER">Dealer Build Only</MenuItem>
              <MenuItem value="BOTH">Both</MenuItem>
            </Select>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="floorplanDiscount"
                name="floorplanDiscount"
                label="Floorplan Discount"
                value={formik.values.floorplanDiscount}
                onChange={formik.handleChange}
                error={
                  formik.touched.floorplanDiscount &&
                  Boolean(formik.errors.floorplanDiscount)
                }
                helperText={
                  formik.touched.floorplanDiscount && formik.errors.floorplanDiscount
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="floorplanDiscount2"
                name="floorplanDiscount2"
                label="Floorplan Discount 2"
                value={formik.values.floorplanDiscount2}
                onChange={formik.handleChange}
                error={
                  formik.touched.floorplanDiscount2 &&
                  Boolean(formik.errors.floorplanDiscount2)
                }
                helperText={
                  formik.touched.floorplanDiscount2 &&
                  formik.errors.floorplanDiscount2
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="motorDiscount"
                name="motorDiscount"
                label="Motor Discount"
                value={formik.values.motorDiscount}
                onChange={formik.handleChange}
                error={
                  formik.touched.motorDiscount &&
                  Boolean(formik.errors.motorDiscount)
                }
                helperText={
                  formik.touched.motorDiscount && formik.errors.motorDiscount
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="motorDiscount2"
                name="motorDiscount2"
                label="Motor Discount 2"
                value={formik.values.motorDiscount2}
                onChange={formik.handleChange}
                error={
                  formik.touched.motorDiscount2 &&
                  Boolean(formik.errors.motorDiscount2)
                }
                helperText={
                  formik.touched.motorDiscount2 && formik.errors.motorDiscount2
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item container spacing={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" sx={{ marginBottom: '16px' }}>
                  Markup
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} style={{ textAlign: 'right' }}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Toggle
                        checked={formik.values.useModelMarkup}
                        onChange={formik.handleChange}
                        id="useModelMarkup"
                        name="useModelMarkup"
                      />
                    }
                    label="Model Based Markup"
                  />
                </FormControl>
              </Grid>
            </Grid>
            {formik.values.useModelMarkup ? (
              <Grid item xs={12}>
                {formik.values.modelMarkups.map((model, index) => {
                  const name = `modelMarkups[${index}].markup`;
                  return (
                    <FormControl
                      fullWidth
                      style={{ paddingBottom: '16px' }}
                      key={`${index}_${model.modelId}`}
                    >
                      <TextField
                        id={`${index}_${model.modelId}`}
                        name={name}
                        label={`${model.name} Markup`}
                        value={model.markup}
                        onChange={formik.handleChange}
                        type="number"
                      />
                    </FormControl>
                  );
                })}
              </Grid>
            ) : (
              <Grid item xs={12}>
                <TextField
                  id="markup"
                  name="markup"
                  label="Default Markup"
                  value={formik.values.markup}
                  onChange={formik.handleChange}
                  error={formik.touched.markup && Boolean(formik.errors.markup)}
                  helperText={formik.touched.markup && formik.errors.markup}
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Button
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        sx={{ marginTop: '24px' }}
      >
        Save
      </Button>
    </form>
  );
};

export default EditLocationMarkupAndDiscount;
