import { useState } from 'react';
import { Grid, Typography, Select, MenuItem, Button } from '@mui/material';
import axios from 'axios';
import DownloadIcon from '@mui/icons-material/Download';
import { DropzoneDialog } from 'material-ui-dropzone';
import LoadingButton from '@mui/lab/LoadingButton';

const CSVSelect = ({
  csvs,
  setCSVs,
  selectedCSV,
  handleSelectCSV,
  modelId,
  setLoadingCSV,
  handleTabSelect,
}) => {
  const [loadingDownloadCSV, setLoadingDownloadCSV] = useState(false);
  const [files, setFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [openDropzone, setOpenDropzone] = useState(false);
  const [updatingCSV, setUpdatingCSV] = useState(false);

  const handleDownloadCSV = async () => {
    const csv = csvs.filter((csv) => csv.id === selectedCSV)[0];
    setLoadingDownloadCSV(true);
    try {
      axios({
        url: '/api/rules/csv/download',
        method: 'GET',
        responseType: 'blob',
        params: {
          fileName: csv.fileName,
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `New_${csv.fileName}`);
        document.body.appendChild(link);
        link.click();
        setLoadingDownloadCSV(false);
      });
    } catch (err) {
      console.log(err.message);
      setLoadingDownloadCSV(false);
    }
  };

  const handleFileChange = (files) => {
    setFiles(files);
  };

  const handleFileUpload = async () => {
    let formData = new FormData();
    formData.append('file', files[0], files[0].name);
    formData.append('modelId', modelId);

    try {
      setSubmitting(true);
      const { data } = await axios.post('/api/rules/csv/upload', formData);
      setOpenDropzone(false);
      setFiles([]);
      setSubmitting(false);
      // check if csv already exists
      const foundCSV = csvs.find((csv) => csv.id === data.csv.id);
      if (!foundCSV) {
        setCSVs([data.csv, ...csvs]);
      } else {
        try {
          setLoadingCSV(true);
          const { data } = await axios.get('/api/rules/csv', {
            params: { csvId: selectedCSV },
          });
          if (data.csv !== null) {
            setCSVs(
              csvs.map((csv) => {
                if (csv.id === selectedCSV) {
                  return data.csv;
                } else {
                  return csv;
                }
              })
            );
          }
          setLoadingCSV(false);
        } catch (err) {
          console.log(err.message);
          setLoadingCSV(false);
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleDisableCSV = async () => {
    setUpdatingCSV(true);
    try {
      const { data } = await axios.delete('/api/rules/csv', {
        params: { csvId: selectedCSV },
      });
      if (data.message === 'success') {
        setCSVs(
          csvs.map((csv) => {
            if (csv.id === selectedCSV) {
              return { ...csv, active: false };
            } else {
              return csv;
            }
          })
        );
        setUpdatingCSV(false);
      }
    } catch (err) {
      console.log(err.message);
      setUpdatingCSV(false);
    }
  };

  const handleActivateCSV = async () => {
    setUpdatingCSV(true);
    try {
      // check for active csv first
      const activeCSV = csvs.find((csv) => csv.active);
      if (activeCSV) {
        const { data } = await axios.delete('/api/rules/csv', {
          params: { csvId: activeCSV.id },
        });
        if (data.message === 'success') {
          setCSVs(
            csvs.map((csv) => {
              if (csv.id === activeCSV.id) {
                return { ...csv, active: false };
              } else {
                return csv;
              }
            })
          );
        }
      }

      const { data } = await axios.get('/api/rules/csv/enable', {
        params: {
          csvId: selectedCSV,
        },
      });
      if (data.message === 'success') {
        setCSVs(
          csvs
            .map((csv) => {
              if (csv.id === selectedCSV) {
                return { ...csv, active: true };
              } else {
                return { ...csv, active: false };
              }
            })
            .filter((csv) => csv.id === selectedCSV)
        );
        handleTabSelect(null, 0);
        handleSelectCSV(`${selectedCSV}`);
        setUpdatingCSV(false);
      }
    } catch (err) {
      console.log(err.message);
      setUpdatingCSV(false);
    }
  };

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">CSV</Typography>
      </Grid>
      <Grid item xs={12}>
        <Select
          fullWidth
          value={selectedCSV}
          onChange={(e) => handleSelectCSV(e.target.value)}
          variant="outlined"
          size="small"
        >
          <MenuItem value="">
            <em>Select CSV</em>
          </MenuItem>
          {csvs.map((csv) => (
            <MenuItem key={csv.id} value={csv.id}>
              {csv.active ? `${csv.fileName} - ACTIVE` : csv.fileName}
            </MenuItem>
          ))}
        </Select>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Active CSV: {csvs.find((csv) => csv.active)?.fileName ?? 'N/A'}
        </Typography>
      </Grid>

      {selectedCSV !== '' ? (
        <Grid item container spacing={2} justifyContent="space-between">
          <Grid item>
            {csvs.find((csv) => csv.id === selectedCSV)?.active ? (
              <Button
                color="error"
                variant="contained"
                onClick={handleDisableCSV}
                disabled={updatingCSV}
              >
                DISABLE CSV
              </Button>
            ) : (
              <Button
                color="secondary"
                variant="contained"
                onClick={handleActivateCSV}
                disabled={
                  updatingCSV ||
                  csvs.find((csv) => csv.id === selectedCSV)?.ruleErrors?.length > 0
                }
              >
                ACTIVATE CSV
              </Button>
            )}
          </Grid>
          <Grid item>
            <LoadingButton
              variant="contained"
              onClick={handleDownloadCSV}
              loading={loadingDownloadCSV}
              loadingPosition="start"
              startIcon={<DownloadIcon />}
              disabled={selectedCSV === ''}
              sx={{ mr: 2 }}
            >
              Download CSV
            </LoadingButton>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              disabled={submitting}
              onClick={() => setOpenDropzone(true)}
            >
              Upload File
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            disabled={submitting}
            onClick={() => setOpenDropzone(true)}
          >
            Upload File
          </Button>
        </Grid>
      )}
      <DropzoneDialog
        onChange={(files) => handleFileChange(files)}
        filesLimit={1}
        showAlerts={false}
        open={openDropzone}
        onClose={() => setOpenDropzone(false)}
        showFileNames
        acceptedFiles={[
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ]}
        onSave={() => handleFileUpload()}
      />
    </Grid>
  );
};

export default CSVSelect;
