import { useState } from 'react';
import {
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  Typography,
  Paper,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { readableRole } from '../../../lib';
import axios from 'axios';
import DeleteLocationModal from './DeleteLocationModal';
import RemoveUserModal from './RemoveUserModal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const LocationList = ({
  dealership,
  setDealership,
  handleOpenEditLocation,
  handleOpenAddLocation,
  handleOpenEditLocationMarkupAndDiscount,
  handleDeactivate,
  permissions,
  onLocationNotificationList,
}) => {
  const [openDeleteLocation, setOpenDeleteLocation] = useState(false);
  const [deleteLocation, setDeleteLocation] = useState({});
  const [selectedUsers, setSelectedUsers] = useState(
    dealership.locations.map((location) => {
      return {
        locationId: location.id,
        userId: '',
      };
    })
  );

  const [openRemoveUser, setOpenRemoveUser] = useState(false);
  const [removeUser, setRemoveUser] = useState({
    user: {},
    location: {},
    row: {},
  });

  const handleToggleNotificationList = async (locationId, email, onList) => {
    const foundLocation = dealership.locations.find((l) => l.id === locationId);
    let updatedNotificationList =
      foundLocation.notificationList === undefined
        ? ''
        : foundLocation.notificationList;
    if (onList) {
      updatedNotificationList = updatedNotificationList
        .split(',')
        .filter((e) => e !== email && e !== 'undefined')
        .join(',');
    } else {
      updatedNotificationList =
        updatedNotificationList === ''
          ? email
          : `${updatedNotificationList},${email}`;
    }

    try {
      await axios.put('/api/location/notification-list', {
        locations: [{ id: locationId, notificationList: updatedNotificationList }],
      });

      setDealership({
        ...dealership,
        locations: dealership.locations.map((location) => {
          if (location.id === locationId) {
            return {
              ...location,
              notificationList: updatedNotificationList,
            };
          } else {
            return location;
          }
        }),
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const getUserList = (locationId) => {
    const userList = dealership.users.filter((user) => {
      return (
        dealership.locations
          .find((location) => {
            return location.id === locationId;
          })
          .users.find((u) => u.userId === user.id) === undefined
      );
    });

    return userList;
  };

  const handleSelectLocationAddUser = (userId, locationId) => {
    setSelectedUsers(
      selectedUsers.map((user) => {
        if (user.locationId === locationId) {
          return {
            ...user,
            userId,
          };
        } else {
          return user;
        }
      })
    );
  };

  const handleAddUserToLocation = async (locationId) => {
    const user = selectedUsers.find((user) => user.locationId === locationId);
    try {
      const { data } = await axios.post('/api/location/user', {
        userId: +user.userId,
        locationId: +locationId,
      });

      setDealership({
        ...dealership,
        locations: dealership.locations.map((location) => {
          if (location.id === locationId) {
            return {
              ...location,
              users: [...location.users, data],
            };
          } else {
            return location;
          }
        }),
      });
      setSelectedUsers(
        selectedUsers.map((user) => {
          if (user.locationId === locationId) {
            return { ...user, userId: '' };
          } else {
            return user;
          }
        })
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleRemoveUserFromLocation = async (row) => {
    const { id, userId, locationId } = row;
    try {
      await axios.delete(`/api/location/user?rowId=${id}`);
      setDealership({
        ...dealership,
        locations: dealership.locations.map((location) => {
          if (location.id === locationId) {
            return {
              ...location,
              users: location.users.filter((user) => user.userId !== userId),
            };
          } else {
            return location;
          }
        }),
      });
      handleCloseRemoveUser();
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleOpenDeleteLocation = (location) => {
    if (location) {
      setDeleteLocation({ ...location });
    }
    setOpenDeleteLocation(true);
  };

  const handleCloseDeleteLocation = () => {
    setOpenDeleteLocation(false);
  };

  const handleOpenRemoveUser = (user, location, row) => {
    setRemoveUser({ user, location, row });
    setOpenRemoveUser(true);
  };

  const handleCloseRemoveUser = () => {
    setOpenRemoveUser(false);
  };

  const handleDeleteLocation = async () => {
    try {
      await axios.delete(`/api/location?locationId=${deleteLocation.id}`);
      setDealership({
        ...dealership,
        locations: dealership.locations.filter(
          (location) => location.id !== deleteLocation.id
        ),
      });
      handleCloseDeleteLocation();
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <Grid container>
      <Grid item container sm={12}>
        <Grid
          item
          container
          xs={12}
          sx={{ mb: 2, mt: 4 }}
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="h4">Locations</Typography>
          </Grid>
          <Grid item>
            {permissions.indexOf('manage_dealer_admin_info') !== -1 && (
              <Button
                color="primary"
                variant="contained"
                onClick={handleOpenAddLocation}
              >
                Add Location
              </Button>
            )}
          </Grid>
        </Grid>

        {dealership.locations.map((location) => {
          return (
            <Grid item xs={12} sx={{ my: 2 }} key={location.id}>
              <Accordion sx={{ width: '100%' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`location-content-${location.id}`}
                  id={`location-header-${location.id}`}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 'bold', display: 'inline-flex' }}
                  >
                    {location.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{ mb: 2 }}
                    justifyContent="space-between"
                  >
                    <Grid item alignItems="start">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={location.active}
                            onClick={() =>
                              handleDeactivate(location.id, 'location', false)
                            }
                            disabled={
                              permissions.indexOf('manage_dealer_locations') === -1
                            }
                          />
                        }
                        label={location.active ? 'Active' : 'Disabled'}
                        sx={{ ml: 4 }}
                      />
                    </Grid>
                    <Grid item sx={{ textAlign: 'right' }}>
                      {permissions.indexOf('manage_dealer_markups') !== -1 && (
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() =>
                            handleOpenEditLocationMarkupAndDiscount(location)
                          }
                          sx={{ mr: 2 }}
                        >
                          Edit Markup & Discount
                        </Button>
                      )}
                      {permissions.indexOf('manage_dealer_admin_info') !== -1 && (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => handleOpenEditLocation(location)}
                          sx={{ mr: 2 }}
                        >
                          Edit Info
                        </Button>
                      )}

                      {permissions.indexOf('manage_dealer_admin_info') !== -1 && (
                        <Button
                          color="error"
                          variant="contained"
                          onClick={() => handleOpenDeleteLocation(location)}
                        >
                          Delete
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {location.users.length > 0 ? (
                      <TableContainer>
                        <Table size="small" sx={{ mb: 2 }}>
                          <TableHead>
                            <TableRow>
                              <TableCell>User</TableCell>
                              <TableCell>Email</TableCell>
                              <TableCell>Role</TableCell>
                              <TableCell>Notification List</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {location.users.map((user) => {
                              const foundUser = dealership.users.find(
                                (u) => u.id === user.userId
                              );

                              return (
                                <TableRow
                                  key={`${location.id}_${user.id}`}
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>{foundUser.name}</TableCell>
                                  <TableCell>{foundUser.email}</TableCell>
                                  <TableCell>
                                    {readableRole(foundUser.role)}
                                  </TableCell>
                                  <TableCell>
                                    <Switch
                                      checked={onLocationNotificationList(
                                        location.notificationList,
                                        foundUser.email
                                      )}
                                      onClick={() =>
                                        handleToggleNotificationList(
                                          location.id,
                                          foundUser.email,
                                          onLocationNotificationList(
                                            location.notificationList,
                                            foundUser.email
                                          )
                                        )
                                      }
                                      disabled={
                                        permissions.indexOf(
                                          'manage_dealer_locations'
                                        ) === -1
                                      }
                                    />
                                  </TableCell>
                                  {permissions.indexOf('manage_dealer_locations') !==
                                    -1 && (
                                    <TableCell sx={{ textAlign: 'right' }}>
                                      <Button
                                        color="error"
                                        variant="contained"
                                        onClick={() =>
                                          handleOpenRemoveUser(
                                            foundUser,
                                            location,
                                            user
                                          )
                                        }
                                        size="small"
                                      >
                                        Remove
                                      </Button>
                                    </TableCell>
                                  )}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        No users assigned to this location.
                      </Typography>
                    )}

                    {getUserList(location.id).length > 0 &&
                      permissions.indexOf('manage_dealer_locations') !== -1 && (
                        <Grid item container sx={{ alignItems: 'flex-end' }} gap={2}>
                          <FormControl sx={{ minWidth: '240px' }}>
                            <InputLabel>Users</InputLabel>
                            <Select
                              id={`add-user-${location.id}`}
                              name={`add-user-${location.id}`}
                              label="Add User"
                              value={
                                selectedUsers.find(
                                  (user) => user.locationId === location.id
                                ).userId
                              }
                              onChange={(e) =>
                                handleSelectLocationAddUser(
                                  e.target.value,
                                  location.id
                                )
                              }
                            >
                              <MenuItem value="">Select User</MenuItem>
                              {getUserList(location.id).map((user) => (
                                <MenuItem key={user.id} value={user.id}>
                                  {user.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <Button
                            color="secondary"
                            variant="contained"
                            size="small"
                            onClick={() => handleAddUserToLocation(location.id)}
                            disabled={
                              selectedUsers.find(
                                (user) => user.locationId === location.id
                              ).userId === ''
                            }
                          >
                            Add User to Location
                          </Button>
                        </Grid>
                      )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })}
      </Grid>
      <DeleteLocationModal
        show={openDeleteLocation}
        handleClose={handleCloseDeleteLocation}
        location={deleteLocation}
        handleConfirmDelete={handleDeleteLocation}
      />
      <RemoveUserModal
        show={openRemoveUser}
        handleClose={handleCloseRemoveUser}
        data={removeUser}
        handleConfirmRemove={() => handleRemoveUserFromLocation(removeUser.row)}
      />
    </Grid>
  );
};

export default LocationList;
