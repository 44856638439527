import React, { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  Typography,
  Box,
  TextField,
  Button,
  Autocomplete,
} from '@mui/material';
import axios from 'axios';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../../components/Loading';

const RuleGroupLookup = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [ruleGroups, setRuleGroups] = useState([]);
  const [selectedRuleGroups, setSelectedRuleGroups] = useState([]);
  const [newRuleGroup, setNewRuleGroup] = useState('');
  const [inputs, setInputs] = useState([]);
  const [selectedInput, setSelectedInput] = useState(null);
  const [selectedInputValue, setSelectedInputValue] = useState(null);
  const [filteredRuleGroups, setFilteredRuleGroups] = useState([]);
  const [filterMessage, setFilterMessage] = useState('');

  useEffect(() => {
    const loadRuleGroups = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get('/api/rule/groups');
        // console.log(data);
        let rgs = data.map((rg) => {
          return {
            ...rg,
            numConditions: rg.conditions.length,
            numRules: rg.rules.length,
          };
        });
        setRuleGroups(rgs);
        setFilteredRuleGroups(rgs);
      } catch (err) {
        console.log(err);
      }
    };

    const loadInputs = async () => {
      const { data } = await axios.get('/api/inputs?isDeleted=1');
      let adjustedInputs = data.inputs.map((input) => {
        if (input.type === 'DROPDOWN') {
          return {
            ...input,
            inputOptions: [
              { id: 0, inputId: input.id, avalonName: 'Not Selected' },
              ...input.inputOptions,
            ],
          };
        } else if (input.type === 'CHECKBOX') {
          return {
            ...input,
            inputOptions: [
              { id: 0, inputId: input.id, avalonName: 'NOT SELECTED' },
              { id: 1, inputId: input.id, avalonName: 'SELECTED' },
            ],
          };
        } else {
          return input;
        }
      });
      setInputs(adjustedInputs);
      setLoading(false);
    };
    loadRuleGroups();
    loadInputs();
  }, []);

  const handleAddRuleGroup = async () => {
    console.log(newRuleGroup);
    try {
      const { data } = await axios.post('/api/rule/group', { name: newRuleGroup });
      navigate(`/settings/rules/group/${data.ruleGroup.id}`);
    } catch (err) {
      console.log(err.ressponse.data.message);
    }
  };

  const handleDeleteRuleGroups = async () => {
    try {
      const { data } = await axios.get('/api/rule/group/disable', {
        params: { ruleGroupId: selectedRuleGroups[0] },
      });
      setRuleGroups(ruleGroups.filter((rg) => rg.id !== selectedRuleGroups[0]));
      setSelectedRuleGroups([]);
    } catch (err) {
      console.log(err.ressponse.data.message);
    }
  };

  const handleViewRuleGroup = () => {
    window.open(
      `/settings/rules/group/${selectedRuleGroups[0]}`,
      '_blank',
      'noreferrer'
    );
  };

  const handleSelectInput = (e, option) => {
    setSelectedInput(option);
    setSelectedInputValue(null);
  };

  const handleSelectInputValue = (e, option) => {
    setSelectedInputValue(option);
  };

  const handleFilterRuleGroups = () => {
    if (selectedInput === null) {
      setFilterMessage('');
      return setFilteredRuleGroups(ruleGroups);
    }
    let tmpRuleGroups = ruleGroups.filter((rg) => {
      if (selectedInputValue !== null) {
        return (
          rg.conditions.filter(
            (c) => c.xId === selectedInput.id && c.yValue === selectedInputValue.id
          ).length > 0
        );
      } else {
        return rg.conditions.filter((c) => c.xId === selectedInput.id).length > 0;
      }
    });
    if (tmpRuleGroups.length === 0) {
      setFilterMessage('No groups found for that filter');
    } else {
      setFilterMessage('');
      setFilteredRuleGroups(tmpRuleGroups);
    }
  };

  return (
    <Paper component="div" sx={{ p: 2 }}>
      <Typography variant="h4">Rule Groups</Typography>
      {loading ? (
        <Loading />
      ) : (
        <Box component="div" sx={{ my: 2 }}>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignContent="center"
          >
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleViewRuleGroup}
                disabled={selectedRuleGroups.length !== 1}
              >
                View Rule Group
              </Button>
            </Grid>
            <Grid item>
              <TextField
                id="add-rule-group"
                label="Rule group name"
                value={newRuleGroup}
                onChange={(e) => setNewRuleGroup(e.target.value)}
                size="small"
                sx={{ width: '45ch' }}
              />
              <Button
                variant="contained"
                onClick={handleAddRuleGroup}
                disabled={newRuleGroup === ''}
                sx={{ ml: 2 }}
              >
                Add Rule Group
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteRuleGroups}
                disabled={selectedRuleGroups.length !== 1}
              >
                Delete rule groups
              </Button>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Filter Rule Groups
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFilterRuleGroups}
                >
                  {!selectedInput ? 'Clear Filter' : 'Filter Groups'}
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  id="rule-group-input-filter"
                  options={inputs}
                  getOptionLabel={(option) =>
                    option ? `${option.name} | ${option.id}` : ''
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Input" size="small" />
                  )}
                  onChange={(e, option) => handleSelectInput(e, option)}
                  value={selectedInput}
                  sx={{ mx: 2 }}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  id="rule-group-input-value-filter"
                  options={
                    selectedInput?.inputOptions ? selectedInput.inputOptions : []
                  }
                  getOptionLabel={(option) => `${option.avalonName} | ${option.id}`}
                  renderInput={(params) => (
                    <TextField {...params} label="Input Value" size="small" />
                  )}
                  onChange={(e, option) => handleSelectInputValue(e, option)}
                  value={selectedInputValue}
                  disabled={!selectedInput}
                  sx={{ mr: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                {filterMessage.length > 0 && (
                  <Typography variant="body1" sx={{ color: 'red', mt: 1 }}>
                    {filterMessage}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DataGridPro
                autoHeight
                columns={[
                  { field: 'id', headerName: 'ID', width: 90 },
                  { field: 'name', headerName: 'Name', width: 600 },
                  {
                    field: 'numConditions',
                    headerName: 'Num of conditions',
                    width: 200,
                  },
                  { field: 'numRules', headerName: 'Num of rules', width: 160 },
                ]}
                rows={filteredRuleGroups}
                rowHeight={38}
                checkboxSelection
                pageSize={25}
                pagination
                selectionModel={selectedRuleGroups}
                onSelectionModelChange={(value) => setSelectedRuleGroups(value)}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Paper>
  );
};

export default RuleGroupLookup;
