import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useNavigate, useMatch, useParams, useLocation } from 'react-router-dom';
import { DataGridPro, GridLinkOperator, useGridApiRef } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Tooltip,
  Button,
  Grid,
  Select,
  TextField,
  FormControl,
  InputAdornment,
  Box,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import ImageIcon from '@mui/icons-material/Image';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import debounce from 'lodash.debounce';
import { format } from 'date-fns';
import { UserContext } from '../../userContext';
import Pagination from './Pagination';
import CommentIcon from '@mui/icons-material/Comment';
import axios from 'axios';
import { formatPrice } from '../../lib';

// Warn if overriding existing method
if (Array.prototype.equals)
  console.warn(
    "Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code."
  );
// attach the .equals method to Array's prototype to call it on any array
Array.prototype.equals = function (array) {
  // if the other array is a falsy value, return
  if (!array) return false;

  // compare lengths - can save a lot of time
  if (this.length !== array.length) return false;

  for (var i = 0, l = this.length; i < l; i++) {
    // Check if we have nested arrays
    if (this[i] instanceof Array && array[i] instanceof Array) {
      // recurse into the nested arrays
      if (!this[i].equals(array[i])) return false;
    } else if (this[i] !== array[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }
  return true;
};
// Hide method from for-in loops
Object.defineProperty(Array.prototype, 'equals', { enumerable: false });

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  dealership: {
    paddingLeft: 0,
  },
  title: {
    marginRight: '10px',
    marginLeft: 'auto',
    marginTop: '10px',
    fontWeight: '500',
  },
  filters: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  formField: {
    margin: theme.spacing(0, 1, 2, 1),
  },
  select: {
    margin: theme.spacing(2, 0, 2, 0),
    width: '200px',
  },
  button: {
    margin: theme.spacing(1, 2, 0, 0),
  },
  mobileButton: {
    marginRight: 0,
  },
  orderSearch: {
    marginTop: '3px',
    paddingRight: 0,
    '& .MuiFilledInput-root': {
      paddingRight: 0,
    },
  },
  search: {
    marginTop: '3px',
    paddingLeft: 0,
  },
  formctl: {
    width: '215px',
    marginRight: '20px',
    '&.Users': {
      width: '450px',
    },
    '&.Locations': {
      width: '450px',
    },
  },
  showFiltersTrigger: {
    position: 'absolute',
    top: '-42px',
    right: theme.spacing(0),
    backgroundColor: '#F4F4F4',
  },
  filtersActive: {
    backgroundColor: '#006991 !important',
    color: '#fff',
  },
  tableSettings: {
    padding: '0px',
    marginTop: '8px',
    marginLeft: '6px',
    '&.right': {
      marginLeft: 'auto',
      float: 'right',
    },
  },
}));

const EnhancedTableToolbar = (props) => {
  const { locationId, archive, dealershipId, orderSource } = useParams();
  // const { path, url } = useMatch();
  let location = useLocation();
  let path = location.pathname;
  // let url = '';

  const isAdminOrdersPage = useMatch(
    '/dealerships/:dealershipId/locations/:locationId/:archive/:orderSource'
  );
  let navigate = useNavigate();
  const classes = useToolbarStyles();
  const {
    numSelected,
    title,
    isMobile,
    rows,
    locations,
    archives,
    selected,
    addData,
    openEditData,
    setEditData,
    disableActionIcon,
    updateBulk,
    initSelected,
    viewOrder,
    overrideInit,
    paperOrder,
    bulkReturnToQuote,
    deleteOrder,
    createLocationOrder,
    handleOpenEditERP,
    downloadOrders,
    searchHandler,
    searchRef,
    handleDownloadXML,
    showFilters,
    setShowFilters,
    handleSaveTableLayout,
    ordersTable,
    externalReps,
    selectedRep,
    setSelectedRep,
    handleDeleteMultiple,
    handleExportTableData,
    hideExportTableData,
    viewReport,
    deleteReport,
  } = props;
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedArchive, setSelectedArchive] = useState(
    archive !== undefined && archive !== 'current'
      ? archives.find((a) => a.name === archive).id
      : 'current'
  );
  const [isActiveApplyBtn, setIsActiveApplyBtn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const userContext = useContext(UserContext);

  const findSelected = () => {
    const selectedRow = rows.find((row) => selected[0] === row.id);

    if (setEditData !== undefined) {
      setEditData(selectedRow);
      openEditData();
    }

    if (setEditData === undefined && openEditData !== undefined) {
      console.log(selectedRow);
      openEditData(selectedRow.id);
    }
  };

  const isManageDealershipButtonEnable = useMemo(() => {
    return (
      userContext.userState.me.role <= 3 &&
      isAdminOrdersPage &&
      userContext.userState.me.permissions.indexOf('manage_dealers') !== -1
    );
  }, [userContext]);

  const renderActionIcon = (isMobile) => {
    switch (numSelected) {
      case 0:
        return (
          <>
            {title !== 'Users' && title !== 'Locations' && addData && (
              <Tooltip title={`Add ${title}`}>
                <IconButton onClick={addData} aria-label="add" size="large">
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
            {title === 'Users' && addData && (
              <Button
                color="secondary"
                variant="contained"
                size="small"
                onClick={addData}
                className={clsx(classes.button, {
                  [classes.mobileButton]: isMobile,
                })}
              >
                {isMobile ? 'Add' : 'Add New User'}
              </Button>
            )}
            {title === 'Locations' && (
              <Button
                color="secondary"
                variant="contained"
                size="small"
                onClick={addData}
                className={clsx(classes.button, {
                  [classes.mobileButton]: isMobile,
                })}
              >
                {isMobile ? 'Add' : 'Add New Location'}
              </Button>
            )}
          </>
        );
      case 1:
        return (
          <>
            <Tooltip title="Edit">
              <IconButton onClick={findSelected} aria-label="edit" size="large">
                <EditIcon />
              </IconButton>
            </Tooltip>
            {title === 'Communication List' && (
              <Tooltip title="Delete">
                <IconButton
                  onClick={() => handleDeleteMultiple(selected)}
                  aria-label="delete"
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      default:
        return (
          <Tooltip title="Delete">
            <IconButton
              onClick={() => handleDeleteMultiple(selected)}
              aria-label="delete"
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        );
    }
  };

  const disableDeleteCheck = () => {
    let flag = true;
    if (
      rows.find((r) => r.id === selected[0])?.orderStatus === 0 &&
      userContext.userState.me.role <= 10
    )
      flag = false;
    if (
      rows.find((r) => r.id === selected[0])?.orderStatus === 5 &&
      userContext.userState.me.role <= 2
    )
      flag = false;

    return flag;
  };

  const disableBulkReturnToQuoteCheck = () => {
    let flag = false;
    for (let s of selected) {
      if (rows.find((r) => r.id === s)?.orderStatus === 5) flag = true;
    }

    return flag;
  };

  const handleSelectArchive = (archiveId) => {
    let achiveName = archives.find((a) => a.id === archiveId).name;
    if (achiveName === 'Current Year') achiveName = 'current';
    if (archive !== achiveName) setIsActiveApplyBtn(true);
    else setIsActiveApplyBtn(false);
    setSelectedArchive(archiveId);
  };

  const handleSelectLocation = (id) => {
    if (id !== locationId) setIsActiveApplyBtn(true);
    else setIsActiveApplyBtn(false);
    setSelectedLocation(id);
  };

  const applyFilter = () => {
    // if a
    let archiveName = archives.find((a) => a.id === selectedArchive).name;
    if (archiveName === 'Current Year') archiveName = 'current';
    let newPath = '';
    newPath =
      dealershipId !== undefined
        ? `/dealerships/${dealershipId}/locations/${selectedLocation}/${archiveName}/${orderSource}`
        : `/locations/${selectedLocation}/${archiveName}/${orderSource}`;
    navigate(newPath);
    searchHandler();
  };

  const handleTableSettingsClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  {
    /* TODO FIX URL USE */
  }
  useEffect(() => {
    let initOrderSource = orderSource === undefined ? 'orders' : orderSource;
    if (path === '/locations') {
      navigate(
        `${url}/${
          locations.length > 1 ? 'all' : locations[0].id
        }/current/${initOrderSource}`
      );
    }

    if (dealershipId !== undefined && locationId === undefined) {
      if (!locations || locations.length === 0) {
        navigate(`/dealerships/${dealershipId}/settings`);
      } else {
        navigate(`${url}/${locations[0].id}/current/${initOrderSource}`);
      }
    }

    if (locationId !== undefined)
      locationId === 'all'
        ? setSelectedLocation('all')
        : setSelectedLocation(
            locations.find((l) => l.id === parseInt(locationId, 10)).id
          );
  }, [locationId]);

  const showAllLocations = userContext.userState.me.role === 1;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.dealership]: title === 'Users' || title === 'Locations',
      })}
    >
      {isMobile && ordersTable && (
        <IconButton
          className={clsx(classes.showFiltersTrigger, {
            [classes.filtersActive]: showFilters,
          })}
          onClick={() => setShowFilters(!showFilters)}
        >
          <TuneOutlinedIcon />
        </IconButton>
      )}
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={isMobile && ordersTable ? 0 : 1}
      >
        {ordersTable && showFilters ? (
          <Grid
            item
            xs={isMobile && ordersTable ? 12 : 8}
            sm={isManageDealershipButtonEnable ? 12 : 8}
            sx={
              isMobile && ordersTable
                ? {
                    display: 'flex',
                    flexDirection: 'column',
                  }
                : {
                    display: 'flex',
                    alignItems: 'center',
                  }
            }
          >
            <FormControl className={classes.formctl}>
              <TextField
                id="search"
                name="search"
                label="Search"
                inputRef={searchRef}
                variant="standard"
                placeholder="Search by keyword"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start" style={{ marginRight: 0 }}>
                      <div />
                    </InputAdornment>
                  ),
                }}
                className={classes.orderSearch}
              />
            </FormControl>
            {showAllLocations
              ? locations.length > 1 && (
                  <FormControl className={classes.formctl}>
                    <InputLabel id="select-location-label">Location</InputLabel>
                    <Select
                      labelId="select-location-label"
                      id="select-location"
                      value={selectedLocation}
                      IconComponent={ExpandMoreIcon}
                      onChange={(e) => handleSelectLocation(e.target.value)}
                      sx={{
                        color:
                          locations.filter((l) => l.active).length === 0 ||
                          (!locations.find((l) => l.id === +selectedLocation)
                            ?.active &&
                            selectedLocation !== 'all')
                            ? 'red'
                            : 'inherit',
                      }}
                    >
                      <MenuItem
                        value="all"
                        sx={{
                          color:
                            locations.filter((l) => l.active).length > 0
                              ? 'inherit'
                              : 'red',
                        }}
                      >
                        All Locations
                      </MenuItem>
                      {locations.map((l) => (
                        <MenuItem
                          key={l.id}
                          value={l.id}
                          sx={{ color: l.active ? 'inherit' : 'red' }}
                        >
                          {l.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )
              : locations.filter((l) => l.active).length > 1 && (
                  <FormControl className={classes.formctl}>
                    <InputLabel id="select-location-label">Location</InputLabel>
                    <Select
                      labelId="select-location-label"
                      id="select-location"
                      value={selectedLocation}
                      IconComponent={ExpandMoreIcon}
                      onChange={(e) => handleSelectLocation(e.target.value)}
                    >
                      <MenuItem value="all">All Locations</MenuItem>
                      {locations
                        .filter((l) => l.active)
                        .map((l) => (
                          <MenuItem key={l.id} value={l.id}>
                            {l.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}

            <FormControl className={classes.formctl}>
              <InputLabel id="select-archive-label">Model Year</InputLabel>
              <Select
                labelId="select-archive-label"
                id="select-archive"
                value={selectedArchive}
                IconComponent={ExpandMoreIcon}
                onChange={(e) => handleSelectArchive(e.target.value)}
              >
                {archives.map((a) => (
                  <MenuItem key={a.id} value={a.id}>
                    {a.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={applyFilter}
              className={classes.button}
              sx={
                isMobile
                  ? {
                      marginTop: '16px !important',
                      width: '100%',
                    }
                  : null
              }
            >
              Search
            </Button>
            {isManageDealershipButtonEnable && (
              <Button
                color="secondary"
                variant="contained"
                size="small"
                onClick={() => navigate(`/dealerships/${dealershipId}/settings`)}
                className={classes.button}
                sx={
                  isMobile
                    ? {
                        marginTop: '16px !important',
                        width: '100%',
                      }
                    : {
                        marginLeft: 'auto !important',
                      }
                }
              >
                Manage Dealership
              </Button>
            )}
          </Grid>
        ) : title !== 'Orders' ? (
          <Grid
            item
            xs={isMobile && ordersTable ? 12 : 8}
            sm={8}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FormControl className={`${classes.formctl} ${title}`}>
              <TextField
                id="search"
                name="search"
                label="Search"
                inputRef={searchRef}
                variant="standard"
                placeholder="Search by keyword"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start" style={{ marginRight: 0 }}>
                      <div />
                    </InputAdornment>
                  ),
                }}
                className={classes.search}
              />
            </FormControl>
            <Button size="small" variant="contained" onClick={() => searchHandler()}>
              Search
            </Button>
          </Grid>
        ) : null}
        {externalReps?.length > 0 ? (
          <Grid item xs={4}>
            <FormControl className={classes.formctl}>
              <InputLabel id="select-rep-label">External Rep</InputLabel>
              <Select
                labelId="select-rep-label"
                id="select-rep"
                value={selectedRep}
                IconComponent={ExpandMoreIcon}
                onChange={(e) => setSelectedRep(e.target.value)}
              >
                <MenuItem value="0">All Orders</MenuItem>
                {externalReps.map((rep) => (
                  <MenuItem key={rep.id} value={rep.id}>
                    {rep.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : null}
        <Grid
          item
          xs={12}
          sx={
            !isMobile
              ? {
                  display: 'flex',
                  alignItems: 'center',
                }
              : ordersTable
              ? null
              : {
                  textAlign: 'right',
                }
          }
        >
          {title !== 'ATDealerships' && title !== 'Motor Settings' ? (
            <Typography
              className={classes.title}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected > 0 ? `${numSelected} item(s) selected` : ''}
            </Typography>
          ) : null}
          {handleDownloadXML !== null &&
          handleDownloadXML !== undefined &&
          userContext.userState.me.role === 1 ? (
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => handleDownloadXML(selected)}
              disabled={selected.length !== 1}
              className={classes.button}
            >
              XML
            </Button>
          ) : null}
          {!hideExportTableData && (
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={handleExportTableData}
              disabled={selected.length === 0}
              className={classes.button}
            >
              Download CSV
            </Button>
          )}
          {downloadOrders !== null && downloadOrders !== undefined ? (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => downloadOrders(selected)}
              disabled={selected.length === 0 || disableBulkReturnToQuoteCheck()}
              className={classes.button}
            >
              Bulk Print
            </Button>
          ) : null}
          {createLocationOrder !== null && createLocationOrder !== undefined ? (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => createLocationOrder()}
              className={classes.button}
            >
              Create Order
            </Button>
          ) : null}
          {bulkReturnToQuote !== undefined &&
          bulkReturnToQuote !== null &&
          userContext.userState.me.permissions.indexOf('return_to_quote') !== -1 ? (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => bulkReturnToQuote(selected)}
              disabled={selected.length === 0 || disableBulkReturnToQuoteCheck()}
              className={classes.button}
            >
              Return to Quote
            </Button>
          ) : null}
          {updateBulk !== undefined ? (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => updateBulk(selected)}
              disabled={selected.equals(initSelected) && !overrideInit}
              className={classes.button}
            >
              Save Changes
            </Button>
          ) : null}
          {viewOrder !== undefined && viewOrder !== null ? (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => viewOrder(selected[0])}
              disabled={
                numSelected !== 1 ||
                rows.find((r) => r.id === selected[0])?.orderStatus === 5
              }
              className={classes.button}
            >
              View Order
            </Button>
          ) : null}
          {viewReport && (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => viewReport(selected[0])}
              disabled={
                numSelected !== 1 ||
                rows.find((r) => r.id === selected[0])?.orderStatus === 5
              }
              className={classes.button}
            >
              View Report
            </Button>
          )}
          {title === 'Boat Show Reports' && (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => navigate('/boat-show')}
              className={classes.button}
            >
              Create Report
            </Button>
          )}
          {deleteOrder !== undefined && deleteOrder !== null ? (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => deleteOrder(selected[0])}
              disabled={numSelected !== 1 || disableDeleteCheck()}
              className={classes.button}
            >
              Delete Order
            </Button>
          ) : null}
          {deleteReport && (
            <Button
              color="error"
              variant="contained"
              size="small"
              onClick={() => deleteReport(selected[0])}
              disabled={numSelected !== 1}
              className={classes.button}
            >
              Delete Report
            </Button>
          )}
          {handleOpenEditERP !== undefined && handleOpenEditERP !== null ? (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() =>
                handleOpenEditERP(rows.find((r) => r.id === selected[0]))
              }
              disabled={
                numSelected !== 1 ||
                rows.find((r) => r.id === selected[0])?.erpNumber === null
              }
              className={classes.button}
            >
              Edit ERP
            </Button>
          ) : null}
          {disableActionIcon ||
          title === 'ATDealerships' ||
          title === 'Motor Settings'
            ? null
            : renderActionIcon(isMobile)}
          <IconButton
            className={
              isMobile || title === 'ATDealerships' || title === 'Motor Settings'
                ? `${classes.tableSettings} right`
                : classes.tableSettings
            }
            onClick={handleTableSettingsClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="table-settings-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={handleSaveTableLayout}>Save Layout</MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    background: 'transparent',
  },
  table: {
    minWidth: 400,
    height: 'calc(100vh - 215px)',
    border: 'none',
    '&.Users': {
      height: 'calc(100vh - 260px)',
    },
    '&.Locations': {
      height: 'calc(100vh - 260px)',
    },
    '&.extended': {
      height: 'calc(100vh - 272px)',
    },
    '&.extended.admin': {
      height: 'calc(100vh - 285px)',
    },
    '&.mobile': {
      minWidth: 'unset',
    },
    '&.mobile.selected': {
      height: 'calc(100vh - 235px)',
    },
    '&.mobile.show-filters': {
      height: 'calc(100vh - 422px)',
    },
    '&.mobile.selected.show-filters': {
      height: 'calc(100vh - 450px)',
    },
    '& .MuiDataGrid-cell': {
      border: 'none',
    },
    '& .MuiDataGrid-row': {
      '&:nth-child(odd)': {
        background: '#F8F8F8',
        borderRadius: '7px',
      },
    },
    '& .MuiDataGrid-virtualScroller': {
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#006991',
        borderRadius: 4,
      },
    },
    '& .MuiDataGrid-footerContainer': {
      marginLeft: '-24px',
      marginRight: '-24px',
      paddingLeft: '24px',
      paddingRight: '24px',
      boxShadow: '0px -4px 8px rgba(23, 26, 32, 0.1)',
      '& .MuiDataGrid-selectedRowCount': {
        display: 'none',
      },
    },
    '&.mobile .MuiDataGrid-footerContainer': {
      marginLeft: '-16px',
      marginRight: '-16px',
      paddingTop: '16px',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  columnText: {
    fontSize: '12px',
  },
  activeStatus: {
    marginRight: theme.spacing(1),
    width: '8px',
    height: '8px',
    backgroundColor: '#009688',
    borderRadius: '100%',
    '&.inactive': {
      backgroundColor: '#747679',
    },
  },
  numberCell: {
    justifyContent: 'unset !important',
  },
}));

const CustomCheckBox = React.forwardRef((props, ref) => (
  <Checkbox
    inputRef={ref}
    sx={{
      '&.Mui-checked': {
        color: '#006991',
      },
    }}
    {...props}
  />
));

const EnhancedTable = (props) => {
  const classes = useStyles();
  let {
    isMobile = false,
    data,
    locations,
    archives,
    dense,
    setSelectedData,
    title,
    updateBulk,
    viewOrder,
    openNotes,
    filterTypes,
    overrideInit,
    paperOrder,
    bulkReturnToQuote,
    deleteOrder,
    createLocationOrder,
    handleOpenApproveOrder,
    handleOpenEditERP,
    downloadOrders,
    initRowsPerPage,
    handleDownloadXML,
    handleEdit,
    initLayoutInfo,
    ordersTable,
    externalReps,
    selectedRep,
    setSelectedRep,
    handleDeleteMultiple,
    allowRetail,
    handleCompleteWarning,
    hideExportTableData,
    viewReport,
    deleteReport,
  } = props;
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(
    initRowsPerPage !== undefined ? initRowsPerPage : 10
  );
  const hiddenColumns = props.hiddenColumns ? props.hiddenColumns : [];
  const editableColumns = props.editableColumns ? props.editableColumns : [];
  const disableSelectAll =
    props.disableSelectAll !== undefined ? props.disableSelectAll : false;
  const initSelected = props.initSelected !== undefined ? props.initSelected : [];
  const [showFilters, setShowFilters] = useState(false);
  const [layoutInfo, setLayoutInfo] = useState({});
  const userContext = useContext(UserContext);
  const searchRef = useRef();
  const apiRef = useGridApiRef();
  const [init, setInit] = useState(true);
  const [preSearchSelected, setPreSearchSelected] = useState([]);

  useEffect(() => {
    if (!isMobile) {
      setShowFilters(true);
    } else {
      setShowFilters(false);
    }
  }, [isMobile]);

  useEffect(() => {
    const parseData = (data) => {
      if (
        (title === 'ATDealerships' || title === 'Motor Settings') &&
        rows.length > 0
      ) {
        return;
      }

      if (searchRef.current.value === '') setRows(data);
      if (init) {
        setSelected(initSelected);
        setInit(false);
      }

      if (data.length === 0) return;

      let columns = Object.keys(data[0]);

      columns = columns
        .filter((c) => {
          if (hiddenColumns.indexOf(c) !== -1) return false;
          return true;
        })
        .map((c) => {
          return {
            id: c,
            numeric: false,
            disablePadding: dense ? true : false,
            label: c.charAt(0).toUpperCase() + c.slice(1),
          };
        });
    };

    parseData(data);
  }, [JSON.stringify(data), JSON.stringify(initSelected), title, rows]);

  const searchHandler = () => {
    let searchText = searchRef.current.value;
    setPreSearchSelected([...selected]);
    let tmpData = [...data];
    let filteredData = [];
    let columns = tmpData.length !== 0 ? Object.keys(tmpData[0]) : [];
    columns = columns.filter((c) => {
      if (hiddenColumns.indexOf(c) !== -1) return false;
      return true;
    });

    for (let d of tmpData) {
      let addFlag = false;
      for (let c of columns) {
        let columnString = d[c];
        if (c === 'orderStatus') columnString = orderStatus(d[c], d['orderSource']);
        let string = String(columnString).toLowerCase();
        if (string.includes(searchText.toLowerCase())) addFlag = true;
      }

      if (addFlag) filteredData.push(d);
    }

    setRows(filteredData);
  };

  const handleChangePage = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleChangeRowsPerPage = (counts) => {
    setRowsPerPage(counts);
    setPage(0);
  };

  const roles = (role) => {
    switch (parseInt(role, 10)) {
      case 1:
        return 'Admin';
      case 2:
        return 'Sales Manager';
      case 3:
        return 'Sales Rep';
      case 10:
        return 'Manager';
      case 11:
        return 'Sales';
      case 12:
        return 'Location Manager';
      case 13:
        return 'Accounting';
      default:
        return 'User';
    }
  };

  const orderStatus = (status, orderSource, verified) => {
    switch (parseInt(status, 10)) {
      case 0:
        if (orderSource === 2) {
          return 'Lead';
        } else {
          return 'Quote';
        }
      case 1:
        if (orderSource === 2) {
          return 'Order Created';
        } else {
          return verified ? 'Submitted' : <Box color="error.main">Submitted</Box>;
        }
      case 2:
        return 'Confirmed';
      case 3:
        return 'Complete';
      case 5:
        return 'Paper Order';
    }
  };

  const ruledColumns = [
    {
      field: 'completed',
      renderCell: (params) => (
        <Checkbox
          checked={params.value}
          onClick={() => handleCompleteWarning(params.row, params.value)}
          sx={{
            '&.Mui-checked': {
              color: '#006991',
            },
          }}
        />
      ),
    },
    { field: 'PO', type: 'string' },
    {
      field: 'price',
      type: 'number',
      valueFormatter: ({ value }) => {
        let price = value;
        if (userContext.userState.me.customerBuild && price && allowRetail)
          price = Math.ceil(price / 0.64);
        return price ? `${formatPrice(Math.ceil(price))}` : 'N/A';
      },
    },
    {
      field: 'dealerships',
      valueFormatter: ({ value }) => value.length,
    },
    {
      field: 'rules',
      valueFormatter: ({ value }) => value.length,
    },
    {
      field: 'tags',
      valueFormatter: ({ value }) => value.length,
    },
    {
      field: 'brand',
      valueFormatter: ({ value }) => (value === 1 ? 'Avalon' : 'Tahoe'),
    },
    {
      field: 'required',
      valueFormatter: ({ value }) => (value === 1 ? 'Yes' : ''),
    },
    {
      field: 'orderStatus',
      renderCell: ({ value, row }) =>
        orderStatus(value, row['orderSource'], row['verified']),
    },
    {
      field: 'active',
      renderCell: ({ value, row }) => (
        <Typography
          component="div"
          sx={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}
        >
          <div
            className={
              value ? classes.activeStatus : `${classes.activeStatus} inactive`
            }
          ></div>
          {isMobile ? '' : value ? 'Active' : 'Inactive'}
        </Typography>
      ),
    },
    {
      field: 'epicorOrder',
      renderCell: ({ value, row }) =>
        value !== null ? (
          <a
            target="_blank"
            href={`https://www.teamavalonpontoons.com/dealerportal/account/orderpage.php?dealerid=${row['customerId']}&order=${value}`}
          >
            {value}
          </a>
        ) : (
          ''
        ),
    },
    {
      field: 'erpNumber',
      renderCell: ({ value, row }) =>
        value !== null ? (
          <a
            target="_blank"
            href={`https://www.teamavalonpontoons.com/dealerportal/account/orderpage.php?dealerid=${row['customerId']}&order=${value}`}
          >
            {value}
          </a>
        ) : (
          ''
        ),
    },
    {
      field: 'markup',
      valueFormatter: ({ value }) => `${value}%`,
    },
    {
      field: 'floorplanDiscount',
      valueFormatter: ({ value }) => `${value}%`,
    },
    {
      field: 'floorplanDiscount2',
      valueFormatter: ({ value }) => `${value}%`,
    },
    {
      field: 'motorDiscount',
      valueFormatter: ({ value }) => `${value}%`,
    },
    {
      field: 'motorDiscount2',
      valueFormatter: ({ value }) => `${value}%`,
    },
    {
      field: 'sent',
      valueFormatter: ({ value }) => (value === true ? 'Yes' : 'No'),
    },
    {
      field: 'createdAt',
      valueFormatter: ({ value }) => format(new Date(value), 'MM/dd/yyyy'),
    },
    {
      field: 'imgPath',
      renderCell: ({ value }) =>
        value !== null ? <ImageIcon fontSize="small" /> : 'N/A',
    },
    {
      field: 'disableConfirm',
      valueFormatter: ({ value }) => (value === false ? 'No' : 'Yes'),
    },
    {
      field: 'q1',
      type: 'number',
      headerAlign: 'left',
      cellAlign: 'left',
      cellClassName: classes.numberCell,
    },
    {
      field: 'q2',
      type: 'number',
      headerAlign: 'left',
      cellAlign: 'left',
      cellClassName: classes.numberCell,
    },
    {
      field: 'q3',
      type: 'number',
      headerAlign: 'left',
      cellAlign: 'left',
      cellClassName: classes.numberCell,
    },
    {
      field: 'q4',
      type: 'number',
      headerAlign: 'left',
      cellAlign: 'left',
      cellClassName: classes.numberCell,
    },
    {
      field: 'role',
      valueFormatter: ({ value }) => roles(value),
    },
    {
      field: 'dealer',
      renderCell: ({ row, value }) => (
        <span
          style={{
            color: row.dealerColor !== undefined ? row.dealerColor : 'black',
          }}
        >
          {value}
        </span>
      ),
    },
    {
      field: 'notes',
      renderCell: ({ row, value }) => (
        <CommentIcon
          size="small"
          onClick={() => openNotes(row)}
          color={
            value.filter((v) => v.pageId === null).length === 0
              ? 'disabled'
              : 'secondary'
          }
        />
      ),
    },
  ];

  const gridColumns = useCallback(() => {
    let source = layoutInfo?.columns ? layoutInfo : initLayoutInfo;
    let tmpHiddenColumns = [...hiddenColumns];
    // if (userContext.userState.me.customerBuild) tmpHiddenColumns.push('price');
    if (data && data.length > 0) {
      const columns = Object.keys(data[0]);
      const validColumns = columns
        .filter((column) => tmpHiddenColumns.indexOf(column) < 0)
        .map((column) => {
          const index = ruledColumns.findIndex((c) => c.field === column);
          if (isMobile && column === 'active') {
            return {
              ...ruledColumns[index],
              headerName: '',
              flex: 0.1,
            };
          } else if (isMobile && column === 'role') {
            return {
              field: column,
              headerName: (column.charAt(0).toUpperCase() + column.slice(1))
                .match(/[A-Z][a-z]+|[0-9]+/g)
                .join(' '),
              flex: 0.1,
              valueFormatter: ({ value }) => value || '',
            };
          } else if (index > -1) {
            let width = 100;
            if (source && source.columns !== undefined) {
              width = source?.columns[column] ? source.columns[column].width : 100;
            }
            return {
              ...ruledColumns[index],
              headerName: (column.charAt(0).toUpperCase() + column.slice(1))
                .match(/[A-Z][a-z]+|[0-9]+/g)
                .join(' '),
              editable: editableColumns.indexOf(column) > -1 ? true : false,
              width: width,
            };
          } else {
            let width = 100;
            if (source && source.columns !== undefined) {
              width = source?.columns[column] ? source.columns[column].width : 100;
            }
            return {
              field: column,
              headerName: (column.charAt(0).toUpperCase() + column.slice(1))
                .match(/[A-Z][a-z]+|[0-9]+/g)
                .join(' '),
              valueFormatter: ({ value }) => value || '',
              editable: editableColumns.indexOf(column) > -1 ? true : false,
              width: width,
            };
          }
        });
      return validColumns;
    }
    return [];
  }, [data, isMobile, userContext.userState.me.customerBuild]);

  const initLayout = useMemo(() => {
    let filterInfo = [];
    let sortInfo = [];
    let hiddenColumns = {};
    const columns = gridColumns();
    let source = layoutInfo;
    if (
      initLayoutInfo !== undefined &&
      Object.keys(initLayoutInfo).length > 0 &&
      Object.keys(layoutInfo).length === 0
    ) {
      source = initLayoutInfo;
      setLayoutInfo(source);
    }

    if (!columns) {
      return;
    }

    source?.filter?.filterModel?.items.map((f) => {
      if (columns.findIndex((c) => c.field === f.columnField) > -1) {
        filterInfo.push(f);
      }
    });

    if (
      source?.sorting?.sortModel &&
      source?.sorting?.sortModel.length > 0 &&
      columns.findIndex((c) => c.field === source?.sorting?.sortModel[0].field) > -1
    ) {
      sortInfo = source?.sorting?.sortModel[0];
    }

    if (source?.columns?.columnVisibilityModel) {
      Object.keys(source.columns.columnVisibilityModel).map((hc) => {
        if (columns.findIndex((c) => c.field === hc) > -1) {
          hiddenColumns[hc] = source.columns.columnVisibilityModel[hc];
        }
      });
    }

    return {
      filter: {
        filterModel: {
          items: filterInfo,
          linkOperator: GridLinkOperator.And,
        },
      },
      sorting: {
        sortModel: [sortInfo],
      },
      columns: {
        ...source.columns,
        columnVisibilityModel: hiddenColumns,
      },
    };
  }, [layoutInfo, gridColumns]);

  const handleChangeFilterColumns = (value) => {
    const newFilterInfo = value.items.map(
      ({ columnField, operatorValue, value, ...other }) => {
        return { columnField, operatorValue, value };
      }
    );
    setLayoutInfo({
      ...layoutInfo,
      filter: {
        filterModel: {
          items: newFilterInfo,
          linkOperator: GridLinkOperator.And,
        },
      },
    });
  };

  const handleChangeSortColumn = (value) => {
    setLayoutInfo({
      ...layoutInfo,
      sorting: {
        sortModel: value,
      },
    });
  };

  const handleChangeShowColumns = (value) => {
    setLayoutInfo({
      ...layoutInfo,
      columns: {
        columnVisibilityModel: value,
      },
    });
  };

  const handleSaveTableLayout = async () => {
    let formattedTableName = title.replace(/\s/g, '_').toLowerCase();
    let tableData = {
      name: formattedTableName,
      fields: layoutInfo,
    };
    console.log('SAVING TABLE LAYOUT: ', tableData, layoutInfo);

    try {
      const { data } = await axios.post('/api/user/table/settings', tableData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditRow = async (value) => {
    const result = {};
    result[value.field] = !value.value && value.value !== 0 ? 50 : value.value;
    const index = rows.findIndex((d) => d.id === value.id);
    const oldRow = rows.find((d) => d.id === value.id);
    let newRow = oldRow;
    newRow = { ...newRow, ...result };
    let temp = [...rows];
    temp.splice(index, 1, newRow);
    setRows(temp);
    if (oldRow[value.field] === result[value.field]) {
      return null;
    }
    try {
      await handleEdit(newRow);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnColumnWidthChange = (value) => {
    let newLayout = { ...layoutInfo };
    newLayout.columns[value.colDef.field] = { width: value.width };
    setLayoutInfo(newLayout);
  };

  const handleExportTableData = () => {
    const selectedRows = rows.filter((row) => selected.includes(row.id));
    const fullColumns = Object.keys(selectedRows[0]);
    let combinedHiddenColumns = [...hiddenColumns];
    let columnsWithStatus = Object.keys(layoutInfo.columns.columnVisibilityModel);
    for (let c of columnsWithStatus) {
      if (layoutInfo.columns.columnVisibilityModel[c] === false) {
        combinedHiddenColumns.push(c);
      }
    }
    const selectedColumns = fullColumns.filter(
      (fc) => combinedHiddenColumns.indexOf(fc) === -1
    );

    const csvData = selectedRows.map((row) => {
      const newRow = {};
      selectedColumns.forEach((sc) => {
        const formattedSc = (sc.charAt(0).toUpperCase() + sc.slice(1))
          .match(/[A-Z][a-z]+|[0-9]+/g)
          .join(' ');
        if (sc === 'notes' && row[sc].length > 0) {
          newRow[formattedSc] = row[sc].map((n) => n.text).join(',');
          return;
        }
        if (sc === 'orderStatus') {
          newRow[formattedSc] = orderStatus(
            row[sc],
            row['orderSource'],
            row['verified']
          );
          return;
        }
        if (sc === 'price') {
          let formattedPrice = userContext.userState.me.customerBuild
            ? (row[sc] / 0.64).toFixed(2)
            : row[sc];
          newRow[formattedSc] = formattedPrice;
        }
        newRow[formattedSc] = row[sc];
      });
      return newRow;
    });

    try {
      const json = JSON.stringify(csvData);
      const blob = new Blob([json], {
        type: 'application/json',
      });
      const data = new FormData();
      data.append('document', blob);
      data.append('title', title);
      axios({
        url: '/api/order/download/csv',
        method: 'POST',
        responseType: 'blob',
        data: data,
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${title} Export.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <EnhancedTableToolbar
          title={title}
          isMobile={isMobile}
          numSelected={selected.length}
          rows={rows}
          locations={locations}
          archives={archives}
          selected={selected}
          addData={props.addData}
          openEditData={props.openEditData}
          setEditData={props.setEditData}
          disableActionIcon={
            props.disableActionIcon !== undefined ? props.disableActionIcon : false
          }
          initSelected={initSelected}
          updateBulk={updateBulk}
          viewOrder={viewOrder}
          filterTypes={filterTypes}
          overrideInit={overrideInit}
          paperOrder={paperOrder}
          bulkReturnToQuote={bulkReturnToQuote}
          deleteOrder={deleteOrder}
          createLocationOrder={createLocationOrder}
          handleOpenEditERP={handleOpenEditERP}
          downloadOrders={downloadOrders}
          searchHandler={searchHandler}
          searchRef={searchRef}
          handleDownloadXML={handleDownloadXML}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          handleSaveTableLayout={handleSaveTableLayout}
          ordersTable={ordersTable}
          externalReps={externalReps}
          selectedRep={selectedRep}
          setSelectedRep={setSelectedRep}
          handleDeleteMultiple={handleDeleteMultiple}
          handleExportTableData={handleExportTableData}
          hideExportTableData={hideExportTableData ?? false}
          viewReport={viewReport}
          deleteReport={deleteReport}
        />
        {rows.length === 0 ? (
          <h2 style={{ textAlign: 'center', paddingBottom: '1em' }}>
            No {props.title.toLowerCase()} found
          </h2>
        ) : (
          <div>
            <DataGridPro
              components={{
                BaseCheckbox: CustomCheckBox,
                Pagination: Pagination,
              }}
              apiRef={apiRef}
              onColumnWidthChange={handleOnColumnWidthChange}
              initialState={initLayout}
              pagination
              checkboxSelection={!disableSelectAll}
              disableSelectionOnClick={!disableSelectAll}
              className={clsx({
                [`${classes.table} ${title}`]: !isMobile,
                [`${classes.table} ${title} mobile`]: isMobile,
                [`${classes.table} ${title} selected`]: selected.length > 0,
                [`${classes.table} ${title} show-filters`]: showFilters,
                [`${classes.table} extended`]: ordersTable,
                [`${classes.table} extended admin`]:
                  ordersTable && isMobile && userContext.userState.me.role < 3,
              })}
              density="comfortable"
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              columns={gridColumns()}
              disableMultipleSelection={disableSelectAll}
              selectionModel={selected}
              onSelectionModelChange={(selectedRows) => {
                setSelected([...new Set([...selectedRows, ...preSearchSelected])]);
                if (disableSelectAll && setSelectedData && selectedRows.length > 0) {
                  setSelectedData(
                    data.find((r) => r.id === selectedRows[selectedRows.length - 1])
                  );
                }
              }}
              rowsPerPageOptions={[5, 10, 25, 50]}
              page={page}
              pageSize={rowsPerPage}
              rowCount={rows.length}
              onPageChange={handleChangePage}
              onPageSizeChange={handleChangeRowsPerPage}
              rows={rows}
              onFilterModelChange={handleChangeFilterColumns}
              onSortModelChange={handleChangeSortColumn}
              onColumnVisibilityModelChange={handleChangeShowColumns}
              onCellEditCommit={handleEditRow}
              rowHeight={38}
            />
          </div>
        )}
      </Paper>
    </div>
  );
};

export default EnhancedTable;
