import * as React from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Outlet, useNavigate, Link, NavLink } from 'react-router-dom';
import { UserContext } from '../../../userContext';
import axios from 'axios';
import {
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  Box,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Tooltip,
  Divider,
  IconButton,
  Button,
  Container,
  FormControl,
  TextField,
  Menu,
  MenuItem,
  useMediaQuery,
  Collapse,
  Switch,
  Stack,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import PeopleIcon from '@mui/icons-material/People';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PageviewIcon from '@mui/icons-material/Pageview';
import SubjectIcon from '@mui/icons-material/Subject';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PanelIcon from '@mui/icons-material/ContentPaste';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SettingsIcon from '@mui/icons-material/Settings';
import RepPortalIcon from '@mui/icons-material/Language';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAltOutlined';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoatOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import SpeedIcon from '@mui/icons-material/Speed';
import theme from '../../../theme';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  activeLink: {
    color: theme.palette.darkBlue.main,
    '& .MuiSvgIcon-root': {
      color: theme.palette.darkBlue.main,
    },
  },
  searchInput: {
    marginLeft: theme.spacing(8),
    maxWidth: '430px',
    '& .MuiOutlinedInput-root': {
      padding: '2px 4px',
      backgroundColor: '#505556',
      color: '#fff',
      borderRadius: '24px',
    },
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: '32px',
    height: '32px',
    borderRadius: '100%',
  },
  headerLogo: {
    display: 'flex',
  },
  mobileHeaderLogo: {
    padding: '15px 0',
    width: '100%',
  },
  headerContent: {
    display: 'flex',
    width: '100%',
  },
  mobileHeaderContent: {
    padding: '15px 0',
  },
  hide: {
    display: 'none!important',
  },
  nested: {
    paddingLeft: theme.spacing(1),
  },
  hide: {
    display: 'none!important',
  },
  hidePrint: {
    ['@media print']: {
      display: 'none',
    },
  },
}));

const drawerWidth = 300;

const MyNavLink = React.forwardRef((props, ref) => {
  return (
    <NavLink
      ref={ref}
      to={props.to}
      className={({ isActive }) =>
        `${props.className} ${isActive ? props.activeClassName : ''}`
      }
    >
      {props.children}
    </NavLink>
  );
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  })
);

const PrivateLayout = (props) => {
  const [open, setOpen] = React.useState(false);
  const userContext = React.useContext(UserContext);
  const permissions = userContext.userState.me.permissions;
  const role = userContext.userState.me.role;
  const name = userContext.userState.me.name;
  const navigate = useNavigate();
  const classes = useStyles();
  const [orderResults, setOrderResults] = React.useState([]);
  const [openOrderResults, setOpenOrderResults] = React.useState(false);
  const [queryText, setQueryText] = React.useState('');
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDealerPortals, setOpenDealerPortals] = React.useState(false);
  const [openViewInvoices, setOpenViewInvoices] = React.useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const logout = async () => {
    setOpen(false);
    await axios.get('/api/logout');
    userContext.userDispatch({ type: 'logout' });
    navigate('/login');
  };

  React.useEffect(() => {
    const timer = setTimeout(async () => {
      if (queryText === '') {
        setOrderResults([]);
        setOpenOrderResults(false);
      } else {
        try {
          const response = await axios.post('/api/orders/search', { id: queryText });

          let orders = response.data.map((order) => {
            order.status = null;
            if (order.order_status === 3) order.status = 'complete';
            if (order.order_status === 2) order.status = 'confirmed';
            if (order.order_status === 1 && order.verified)
              order.status = 'submittedv';
            if (order.order_status === 1 && !order.verified)
              order.status = 'submittednv';
            if (order.order_status === 0) order.status = 'quote';
            return order;
          });
          setOrderResults(orders);
          setOpenOrderResults(true);
        } catch (err) {
          console.log(err.message);
        }
      }
    }, 200);

    return () => clearTimeout(timer);
  }, [queryText]);

  const navigateSearchHandler = (path) => {
    setOpenOrderResults(false);
    setQueryText('');
    navigate(path);
  };

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const ShowMoreIcon = () => (
    <ChevronRightIcon fontSize="small" className={clsx({ [classes.hide]: !open })} />
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="absolute"
        open={open}
        className={clsx(classes.hidePrint, {
          [classes.hide]: props.customerOrderForm,
        })}
      >
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            {role <= 3 && (
              <FormControl className={classes.searchBar}>
                <TextField
                  id="search"
                  name="search"
                  placeholder="Search current orders..."
                  size="small"
                  // inputRef={queryRef}
                  value={queryText}
                  onChange={(e) => setQueryText(e.target.value)}
                  className={classes.searchInput}
                />
                {openOrderResults && (
                  <Box
                    sx={{
                      bgcolor: 'background.paper',
                      color: '#333',
                      position: 'absolute',
                      top: '45px',
                      left: '70px',
                      maxWidth: '410px',
                      width: '100%',
                      borderRadius: '7px',
                      boxShadow: theme.shadows[2],
                    }}
                  >
                    <List>
                      {orderResults.length !== 0 && queryText.length > 0 ? (
                        orderResults.map((order) => (
                          <ListItem key={order.id} disablePadding>
                            <ListItemButton
                              onClick={() =>
                                navigateSearchHandler(
                                  `/orders/${order.status}/order/${order.id}`
                                )
                              }
                            >
                              <ListItemText>
                                ID {order.id} - EPICOR{' '}
                                {order.epicor_order_number
                                  ? order.epicor_order_number
                                  : 'N/A'}
                              </ListItemText>
                            </ListItemButton>
                          </ListItem>
                        ))
                      ) : (
                        <ListItem>
                          <ListItemText>No orders found.</ListItemText>
                        </ListItem>
                      )}
                    </List>
                  </Box>
                )}
              </FormControl>
            )}
          </Box>
          <Stack direction="row" spacing={1} alignItems="center">
            <Button
              variant="text"
              color={
                !userContext.userState.me.customerBuild ? 'secondary' : 'inherit'
              }
              onClick={() =>
                userContext.userDispatch({
                  type: 'setCustomerBuild',
                  payload: { customerBuild: false },
                })
              }
            >
              Dealer Build
            </Button>
            <Switch
              checked={userContext.userState.me.customerBuild}
              onChange={() =>
                userContext.userDispatch({ type: 'toggleCustomerBuild' })
              }
              name="customer-build"
              color="secondary"
            />
            <Button
              variant="text"
              color={
                userContext.userState.me.customerBuild ? 'secondary' : 'inherit'
              }
              onClick={() =>
                userContext.userDispatch({
                  type: 'setCustomerBuild',
                  payload: {
                    customerBuild: true,
                  },
                })
              }
            >
              Retail Build
            </Button>
          </Stack>
          <Button
            onClick={handleSettingsClick}
            color="inherit"
            sx={{ marginLeft: 'auto' }}
            className={clsx({ [classes.mobileHeaderButton]: isMobile })}
          >
            <img className={classes.avatar} src={'/default_avatar.png'} alt={name} />{' '}
            {isMobile ? '' : name}
          </Button>
          <Menu
            id="settings-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleSettingsClose}
          >
            <MenuItem
              onClick={() => {
                navigate('/profile');
                handleSettingsClose();
              }}
            >
              Profile
            </MenuItem>
            <MenuItem
              onClick={() => {
                logout();
                handleSettingsClose();
              }}
            >
              Log Out
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        className={clsx(classes.hidePrint, {
          [classes.hide]: props.customerOrderForm,
        })}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          {/* Home */}
          <ListItemButton
            id="home"
            component={MyNavLink}
            to="/"
            activeClassName={classes.activeLink}
          >
            <ListItemIcon>
              <Tooltip title="Home">
                <HomeIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>

          {role >= 10 && (
            <ListItemButton
              id="build"
              component={MyNavLink}
              to={userContext.userState.me.orderFormPath}
              activeClassName={classes.activeLink}
            >
              <ListItemIcon>
                <Tooltip title="Build A Boat">
                  <DirectionsBoatIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Build A Boat" />
            </ListItemButton>
          )}

          {permissions.indexOf('view_order') !== -1 && role <= 3 && (
            <ListItemButton
              id="view_orders"
              component={MyNavLink}
              to="/orders/submittednv"
              activeClassName={classes.activeLink}
            >
              <ListItemIcon>
                <Tooltip title="View Boat Orders">
                  <AssignmentIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="View Boat Orders" />
            </ListItemButton>
          )}

          {permissions.indexOf('view_order') !== -1 && role >= 10 && (
            <ListItemButton
              id="order_history"
              component={MyNavLink}
              to="/locations/all/current/quote"
              activeClassName={classes.activeLink}
            >
              <ListItemIcon>
                <Tooltip title="Boat Order History">
                  <ReceiptIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Boat Order History" />
            </ListItemButton>
          )}

          {permissions.indexOf('motor_orders') !== -1 && (
            <ListItemButton
              id="motor_orders"
              component={MyNavLink}
              to="/motor-orders"
              activeClassName={classes.activeLink}
            >
              <ListItemIcon>
                <Tooltip title="Motor Orders">
                  <SpeedIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Motor Orders" />
            </ListItemButton>
          )}

          {permissions.indexOf('dealer_portal') !== -1 &&
            role >= 10 &&
            userContext.userState.me.customerIds.length > 1 && (
              <>
                <ListItemButton
                  onClick={() => setOpenDealerPortals(!openDealerPortals)}
                >
                  <ListItemIcon>
                    <Tooltip title="Dealer Portal / Invoices">
                      <DescriptionIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary="Dealer Portal / Invoices" />
                  <ShowMoreIcon />
                </ListItemButton>
                <Collapse in={openDealerPortals} timeout="auto" unmountOnExit>
                  <List className={classes.nested} disablePadding>
                    {userContext.userState.me.customerIds.map((cid) => (
                      <ListItemButton
                        key={cid}
                        onClick={() =>
                          window.open(
                            `https://dealerportal.teamavalonpontoons.com/login/${cid}`,
                            '_blank'
                          )
                        }
                      >
                        <ListItemIcon>
                          <Tooltip title="Dealer Portal / Invoices">
                            <DescriptionIcon />
                          </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={cid} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </>
            )}

          {permissions.indexOf('dealer_portal') !== -1 &&
            role >= 10 &&
            userContext.userState.me.customerIds.length === 1 && (
              <ListItemButton
                onClick={() =>
                  window.open(
                    `https://dealerportal.teamavalonpontoons.com/login/${userContext.userState.me.customerIds[0]}`,
                    '_blank'
                  )
                }
              >
                <ListItemIcon>
                  <Tooltip title="Dealer Portal / Invoices">
                    <DescriptionIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Dealer Portal / Invoices" />
              </ListItemButton>
            )}

          {permissions.indexOf('manage_dealers') !== -1 &&
            role >= 10 &&
            (permissions.indexOf('manage_dealer_users') !== -1 ||
              permissions.indexOf('manage_dealer_locations') !== -1) && (
              <ListItemButton
                id="manage_dealership"
                component={MyNavLink}
                to="/settings/dealership"
                activeClassName={classes.activeLink}
              >
                <ListItemIcon>
                  <Tooltip title="Manage Dealership">
                    <PeopleAltIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Manage Dealership" />
              </ListItemButton>
            )}

          {permissions.indexOf('derp_codes') !== -1 && (
            <ListItemButton
              id="derp"
              component={MyNavLink}
              to="/derp"
              activeClassName={classes.activeLink}
            >
              <ListItemIcon>
                <Tooltip title="DERP Codes">
                  <WarningIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="DERP Codes" />
            </ListItemButton>
          )}

          {permissions.indexOf('notes_page') !== -1 && (
            <ListItemButton
              id="order-notes"
              component={MyNavLink}
              to="/order-notes"
              activeClassName={classes.activeLink}
            >
              <ListItemIcon>
                <Tooltip title="Order Notes">
                  <AnnouncementIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Order Notes" />
            </ListItemButton>
          )}

          {permissions.indexOf('find_dealerships') !== -1 && (
            <ListItemButton
              component={MyNavLink}
              to="/dealerships"
              activeClassName={classes.activeLink}
            >
              <ListItemIcon>
                <Tooltip title="Find Dealerships">
                  <PageviewIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Find Dealerships" />
            </ListItemButton>
          )}

          {permissions.indexOf('terms') !== -1 && (
            <ListItemButton
              onClick={() => {
                return window.open(
                  `http://67.209.249.60/termsbyrep.php?id=${userContext.userState.me.id}`,
                  '_blank'
                );
              }}
            >
              <ListItemIcon>
                <Tooltip title="Terms">
                  <SubjectIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Terms" />
            </ListItemButton>
          )}

          {permissions.indexOf('view_boat_show_reports') !== -1 && (
            <ListItemButton
              component={MyNavLink}
              to="/boat-show/reports"
              activeClassName={classes.activeLink}
            >
              <ListItemIcon>
                <Tooltip title="Boat Show Reports">
                  <AssignmentOutlinedIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Boat Show Reports" />
            </ListItemButton>
          )}

          {role <= 3 && (
            <ListItemButton
              onClick={() => {
                return window.open(
                  `https://www.teamavalonpontoons.com/repportal/dealerportal.php?id=${userContext.userState.me.id}`,
                  '_blank'
                );
              }}
            >
              <ListItemIcon>
                <Tooltip title="Rep Portal">
                  <RepPortalIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Rep Portal" />
            </ListItemButton>
          )}

          <Divider sx={{ my: 1 }} />
          {permissions.indexOf('admin_form_builder') !== -1 && (
            <ListItemButton
              id="inputs"
              component={MyNavLink}
              to="/settings/form/pages"
              activeClassName={classes.activeLink}
            >
              <ListItemIcon>
                <Tooltip title="Inputs">
                  <ListAltIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Inputs" />
            </ListItemButton>
          )}
          {permissions.indexOf('admin_rules') !== -1 && (
            <ListItemButton
              id="rules"
              component={MyNavLink}
              to="/settings/rules/single"
              activeClassName={classes.activeLink}
            >
              <ListItemIcon>
                <Tooltip title="Rules">
                  <FormatListNumberedIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Rules" />
            </ListItemButton>
          )}

          {permissions.indexOf('admin_rules_csv') !== -1 && (
            <ListItemButton
              id="rules_csv"
              component={MyNavLink}
              to="/settings/rules/csv"
              activeClassName={classes.activeLink}
            >
              <ListItemIcon>
                <Tooltip title="Rules">
                  <FormatListNumberedIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Rules" />
            </ListItemButton>
          )}

          {permissions.indexOf('admin_motors') !== -1 && (
            <ListItemButton
              id="rules"
              component={MyNavLink}
              to="/settings/motors"
              activeClassName={classes.activeLink}
            >
              <ListItemIcon>
                <Tooltip title="Motors">
                  <SpeedIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Motors" />
            </ListItemButton>
          )}

          {permissions.indexOf('manage_io_sales') !== -1 && (
            <ListItemButton
              id="users"
              component={MyNavLink}
              to="/settings/users"
              activeClassName={classes.activeLink}
            >
              <ListItemIcon>
                <Tooltip title="AT Dealer Users">
                  <PeopleIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="AT Dealer Users" />
            </ListItemButton>
          )}

          {permissions.indexOf('manage_roles') !== -1 && (
            <ListItemButton
              id="users"
              component={MyNavLink}
              to="/settings/roles"
              activeClassName={classes.activeLink}
            >
              <ListItemIcon>
                <Tooltip title="Roles">
                  <AdminPanelSettingsIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Roles" />
            </ListItemButton>
          )}

          {permissions.indexOf('admin_allotment') !== -1 && (
            <ListItemButton
              id="allotment"
              component={MyNavLink}
              to="/settings/allotment"
              activeClassName={classes.activeLink}
            >
              <ListItemIcon>
                <Tooltip title="Dealer Allotment">
                  <PanelIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Dealer Allotment" />
            </ListItemButton>
          )}

          {permissions.indexOf('manage_notifications') !== -1 && (
            <ListItemButton
              id="notifications"
              component={MyNavLink}
              to="/settings/cms/notifications"
              activeClassName={classes.activeLink}
            >
              <ListItemIcon>
                <Tooltip title="Notifications">
                  <AnnouncementIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Notifications" />
            </ListItemButton>
          )}

          {permissions.indexOf('admin_upcoming_orders') !== -1 && (
            <ListItemButton
              id="notifications"
              component={MyNavLink}
              to="/settings/reporting"
              activeClassName={classes.activeLink}
            >
              <ListItemIcon>
                <Tooltip title="Reporting">
                  <ScheduleSendIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Reporting" />
            </ListItemButton>
          )}
          {role === 1 && (
            <ListItemButton
              id="notifications"
              component={MyNavLink}
              to="/settings/atom"
              activeClassName={classes.activeLink}
            >
              <ListItemIcon>
                <Tooltip title="ATOM Settings">
                  <SettingsIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="ATOM Settings" />
            </ListItemButton>
          )}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          minHeight: '100vh',
          overflow: 'auto',
        }}
      >
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Toolbar className={clsx({ [classes.hide]: props.customerOrderForm })} />
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
};

export default PrivateLayout;
