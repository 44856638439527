import {
  Button,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { format } from 'date-fns';
import _ from 'lodash';
import { DataGridPro } from '@mui/x-data-grid-pro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ViewRebate from '../ViewRebate';
import { useState } from 'react';

const RebatesTable = ({
  rebates,
  selectedRebate,
  handleSelectRebate,
  handleUpdateRebate,
  role,
  height,
  viewOrder,
  includeOrderId,
  externalReps,
  selectedRep,
  setSelectedRep,
  setRebates,
}) => {
  const rebateStatusColor = (status) => {
    switch (status) {
      case 'PENDING':
        return 'primary';
      case 'APPROVED':
        return 'secondary';
      case 'DENIED':
        return 'error';
    }
  };

  const [viewRebate, setViewRebate] = useState(false);

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'dealership',
      headerName: 'Dealership',
      valueGetter: (params) => params.row.dealership?.name ?? '',
      width: 120,
    },
    { field: 'modelYear', headerName: 'Model Year', width: 100 },
    { field: 'orderId', headerName: 'Order ID', width: 100 },
    {
      field: 'epicorOrderNumber',
      headerName: 'Epicor ID',
      valueGetter: (params) => params.row.order?.epicorOrderNumber ?? '',
      width: 100,
    },
    { field: 'saleAmount', headerName: 'SALE AMOUNT', width: 120 },
    { field: 'rebateAmount', headerName: 'REBATE AMOUNT', width: 120 },
    {
      field: 'requestedBy',
      headerName: 'REQUESTED BY',
      valueGetter: (params) => params.row.requestUser.name,
      width: 100,
    },
    {
      field: 'requestDate',
      headerName: 'REQUEST DATE',
      valueFormatter: (params) => format(new Date(params.value), 'MM/dd/yyyy'),
      width: 120,
    },
    {
      field: 'rebateStatus',
      headerName: 'STATUS',
      renderCell: (params) => (
        <Typography color={rebateStatusColor(params.value)}>
          {params.value}
        </Typography>
      ),
      width: 120,
    },
    {
      field: 'approvalDate',
      headerName: 'RESPONSE DATE',
      valueFormatter: (params) =>
        params.value !== null ? format(new Date(params.value), 'MM/dd/yyyy') : '',
      width: 120,
    },
    {
      field: 'approvalUser',
      headerName: 'UPDATED BY',
      valueGetter: (params) => params.row.approvalUser?.name ?? '',
      width: 120,
    },
    {
      field: 'filename',
      headerName: 'Bill of Sale',
      width: 120,
      renderCell: (params) =>
        params?.value ? (
          <a
            href={`https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/files/${params.value
              .split(' ')
              .join('+')}`}
            target="_blank"
          >
            {params?.value}
          </a>
        ) : (
          ''
        ),
    },
  ];

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ pb: 2 }}
        justifyContent={externalReps ? 'space-between' : 'flex-end'}
        alignItems="center"
      >
        {externalReps?.length > 0 ? (
          <Grid item>
            <FormControl sx={{ width: 200 }}>
              <InputLabel id="select-rep-label">External Rep</InputLabel>
              <Select
                labelId="select-rep-label"
                id="select-rep"
                value={selectedRep}
                IconComponent={ExpandMoreIcon}
                onChange={(e) => setSelectedRep(e.target.value)}
                disabled={viewRebate}
              >
                <MenuItem value="0">All Orders</MenuItem>
                {externalReps.map((rep) => (
                  <MenuItem key={rep.id} value={rep.id}>
                    {rep.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : null}
        <Grid item>
          {viewOrder && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                viewOrder(rebates.find((r) => r.id === selectedRebate).orderId)
              }
              disabled={!selectedRebate}
              sx={{ mr: 2 }}
            >
              View Order
            </Button>
          )}
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setViewRebate(!viewRebate)}
            disabled={!selectedRebate}
            sx={{ mr: 2 }}
          >
            {!viewRebate ? 'View Rebate' : 'View Table'}
          </Button>
          {role <= 2 && handleUpdateRebate && (
            <>
              <Button
                variant="contained"
                color="secondary"
                sx={{ mr: 2 }}
                disabled={
                  !selectedRebate ||
                  rebates.find((r) => r.id === selectedRebate).rebateStatus ===
                    'APPROVED' ||
                  rebates.find((r) => r.id === selectedRebate).rebateStatus ===
                    'PROCESSED'
                }
                onClick={() => handleUpdateRebate('APPROVED')}
              >
                APPROVE
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !selectedRebate ||
                  rebates.find((r) => r.id === selectedRebate).rebateStatus ===
                    'DENIED' ||
                  rebates.find((r) => r.id === selectedRebate).rebateStatus ===
                    'PROCESSED'
                }
                onClick={() => handleUpdateRebate('DENIED')}
              >
                DENY
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      {viewRebate && selectedRebate ? (
        <ViewRebate
          rebate={rebates.find((r) => r.id === selectedRebate)}
          rebates={rebates}
          setRebates={setRebates}
        />
      ) : (
        <div style={{ height: height ?? 400, width: '100%' }}>
          <DataGridPro
            rows={rebates ?? []}
            columns={
              includeOrderId
                ? columns
                : columns.filter(
                    (c) => c.field !== 'orderId' && c.field !== 'dealership'
                  )
            }
            selectionModel={selectedRebate ? [selectedRebate] : []}
            onSelectionModelChange={(selectedRows) =>
              handleSelectRebate(
                rebates.find((r) => r.id === selectedRows[selectedRows.length - 1])
                  ?.id ?? null
              )
            }
            checkboxSelection
            disableMultipleSelection={true}
          />
        </div>
      )}
    </>
  );
};
export default RebatesTable;
