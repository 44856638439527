import {
  Grid,
  Typography,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import DeleteUserModal from './DeleteUserModal';
import { readableRole } from '../../../lib';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const UserList = ({
  handleOpenAddUser,
  handleOpenEditUser,
  handleDeactivate,
  dealership,
  myId,
  openDeleteUser,
  setOpenDeleteUser,
  deleteUser,
  setDeleteUser,
}) => {
  const handleOpenDeleteUser = (user) => {
    if (user) {
      setDeleteUser({ ...user });
    }
    setOpenDeleteUser(true);
  };

  const handleCloseDeleteUser = () => {
    setOpenDeleteUser(false);
  };

  return (
    <Grid item container sm={12} sx={{ pt: 4 }}>
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="userlist-content"
          id="userlist-header"
        >
          <Typography variant="h4">Users</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button
            color="primary"
            variant="contained"
            onClick={handleOpenAddUser}
            sx={{ mb: 2 }}
          >
            Add User
          </Button>
          <TableContainer sx={{ pb: 2 }}>
            <Table size="small" sx={{ minWidth: 750 }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Username</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Password</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Role</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Active</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dealership.users.map((user) => (
                  <TableRow
                    key={user.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.password}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{readableRole(user.role)}</TableCell>
                    <TableCell>
                      <Switch
                        checked={user.active}
                        onClick={() =>
                          handleDeactivate(user.id, 'user', false, false)
                        }
                        disabled={user.id === myId}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'right',
                        width: '200px',
                        minWidth: '200px',
                      }}
                    >
                      {user.id !== myId && (
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          onClick={() => handleOpenEditUser(user)}
                          sx={{ mr: 2 }}
                        >
                          Edit
                        </Button>
                      )}

                      {user.id !== myId && (
                        <Button
                          color="error"
                          variant="contained"
                          size="small"
                          onClick={() => handleOpenDeleteUser(user)}
                        >
                          Delete
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <DeleteUserModal
        show={openDeleteUser}
        handleClose={handleCloseDeleteUser}
        user={deleteUser}
        handleConfirmDelete={() =>
          handleDeactivate(deleteUser.id, 'user', false, true)
        }
      />
    </Grid>
  );
};

export default UserList;
