import { Grid, Typography, Box, useMediaQuery } from '@mui/material';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import makeStyles from '@mui/styles/makeStyles';
import theme from '../../theme';

const useStyles = makeStyles((theme) => ({
  paperTitle: {
    fontSize: '2rem',
    fontWeight: '700',
    marginBottom: theme.spacing(1),
  },
  contactIcon: {
    color: '#006991',
    marginBottom: theme.spacing(-1),
    marginRight: theme.spacing(1.5),
  },
  logo: {
    width: '200px',
    maxWidth: '100%',
  },
}));

const PublicHome = () => {
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} sm={6}>
        <Typography className={classes.paperTitle}>Contact Us</Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <LocationOnOutlinedIcon className={classes.contactIcon} />
          903 Michigan Ave, Alma MI 48801
        </Typography>

        <Typography variant="body1" sx={{ mb: 1 }}>
          <PhoneInTalkOutlinedIcon className={classes.contactIcon} />
          (989) 463-2112
        </Typography>
        <Typography variant="body1" paragraph>
          <EmailOutlinedIcon className={classes.contactIcon} />
          <a href="mailto:info@avalonpontoons.com">info@avalonpontoons.com</a>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box textAlign={matches ? 'right' : 'left'}>
          <img
            src="/unisonAT_min.png"
            alt="Avalon & Tahoe Logo"
            className={classes.logo}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default PublicHome;
