import React from 'react';
import OrderFormHeroImage from './OrderFormHeroImage';
import { formatPrice } from '../../../lib';

const CustomerPDF = (props) => {
  const {
    model,
    floorplan,
    price,
    pages,
    inputs,
    boatImages,
    benchImages,
    classes,
    formType,
    modelName,
    location,
    brand,
  } = props;

  const calculatePrice = (input) => {
    if (input.type === 'DROPDOWN') {
      if (
        input.value === '' ||
        input.inputOptions.find((io) => io.id === input.value).price === 0
      )
        return null;
      return formatPrice(
        formType === 'd'
          ? input.inputOptions.find((io) => io.id === input.value).price
          : input.inputOptions.find((io) => io.id === input.value).price / 0.64
      );
    } else {
      if (input.value === false || input.price === 0) return null;
      return formatPrice(formType === 'd' ? input.price : input.price / 0.64);
    }
  };

  return (
    <div className={'printable pdf-page'}>
      {(brand === 'a' || brand === undefined) && (
        <img src="/logo.png" alt="logo" style={{ margin: '0px 12px 16px 12px' }} />
      )}
      {brand === 't' && (
        <img
          src="/tahoe_logo_small.png"
          alt="logo"
          style={{ margin: '0px 12px 16px 12px', height: '40px' }}
        />
      )}
      <table style={{ width: '100%', padding: '0px 24px', display: 'block' }}>
        <tbody>
          <tr>
            <td>
              <div>
                <h2 style={{ margin: '0px', fontSize: '2.25rem' }}>
                  {modelName} - {floorplan.floorplan.avalonName}
                </h2>
                {location !== null ? (
                  <div>
                    <h3 style={{ margin: '0px' }}>{location.name}</h3>
                    {location.phone !== '' && location.phone !== null ? (
                      <p style={{ margin: '0px' }}>{location.phone}</p>
                    ) : null}
                    <p style={{ margin: '0px' }}>{location.address}</p>
                    <p style={{ margin: '0px' }}>
                      {location.city}, {location.state} {location.zipcode}
                    </p>
                  </div>
                ) : null}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <table
                style={{
                  marginLeft: '-16px',
                  width: 'calc(100% + 32px)',
                  borderSpacing: '16px',
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: '33%',
                        textAlign: 'center',
                        backgroundColor: '#F8F8F8',
                        borderRadius: '16px',
                      }}
                    >
                      <div>
                        <img
                          src={`https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/images/${
                            floorplan.floorplan.imgPath
                          }?${Date.now()}`}
                          alt={floorplan.floorplan.avalonName}
                          className={classes.floorplanImageAlt}
                          crossOrigin="anonymous"
                        />
                      </div>
                    </td>
                    <td
                      style={{
                        width: '33%',
                        backgroundColor: '#F8F8F8',
                        borderRadius: '16px',
                      }}
                    >
                      <div className={classes.heroImageParentAlt}>
                        <OrderFormHeroImage
                          inputs={boatImages}
                          mainSrc={model.imgPath}
                          pdf
                        />
                      </div>
                    </td>
                    <td
                      style={{
                        width: '33%',
                        backgroundColor: '#F8F8F8',
                        borderRadius: '16px',
                      }}
                    >
                      <div className={classes.heroImageParentAlt}>
                        <OrderFormHeroImage inputs={benchImages} pdf />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div style={{ display: 'flex' }}>
                <img
                  src="/speaker.png"
                  alt="icon"
                  style={{ width: '20px', height: '18px' }}
                />
                <p
                  style={{
                    fontSize: '12px',
                    marginTop: '0px',
                    marginLeft: '10px',
                    color: '#747679',
                  }}
                >
                  The image of the boat shown is displayed to provide you with an
                  idea of the look of your boat. Precise depictions of colors,
                  dimensions, textures, or features may not be visible, or fully
                  accurate in this image. Please see your dealer to view the boat in
                  person, or to see samples of materials, to be sure you are
                  satisfied with its appearance before you purchase your boat.
                </p>
              </div>
              <div
                style={{
                  marginTop: '12px',
                  marginBottom: '5px',
                  height: '1px',
                  backgroundColor: '#F4F4F4',
                }}
              />
            </td>
          </tr>
          <tr>
            <td style={{ verticalAlign: 'top' }}>
              <table className={classes.optionsTable}>
                <thead>
                  <tr>
                    <th align="left">Options</th>
                    <th align="right">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ fontSize: '16px' }}>
                    <td>Floorplan - {floorplan.floorplan.avalonName}</td>
                    <td style={{ paddingLeft: '6px', textAlign: 'right' }}>
                      {formatPrice(floorplan.floorplan.price / 0.64) || '$0'}
                    </td>
                  </tr>
                  {pages.map((p) => {
                    if (
                      inputs.filter(
                        (input) =>
                          input.pageId === p.id &&
                          input.active &&
                          ((input.value !== '' &&
                            input.type === 'DROPDOWN' &&
                            (input.inputOptions.find((io) => io.id === input.value)
                              ?.price > 0 ||
                              input.hidePrint)) ||
                            (input.value !== false &&
                              input.type === 'CHECKBOX' &&
                              (input.price > 0 || input.hidePrint)))
                      ).length === 0
                    ) {
                      return null;
                    } else {
                      return inputs
                        .filter(
                          (input) =>
                            input.pageId === p.id &&
                            input.active &&
                            ((input.value !== '' &&
                              input.type === 'DROPDOWN' &&
                              (input.inputOptions.find((io) => io.id === input.value)
                                ?.price > 0 ||
                                input.hidePrint)) ||
                              (input.value !== false &&
                                input.type === 'CHECKBOX' &&
                                (input.price > 0 || input.hidePrint)))
                        )
                        .sort((a, b) => a.printOrder - b.printOrder)
                        .map((input) => {
                          if (input.id === 142 || input.id === 164) return null;
                          return (
                            <tr style={{ fontSize: '16px' }} key={`po_${input.id}`}>
                              <td>
                                {input.name}
                                {input.type === 'DROPDOWN' && input.value !== ''
                                  ? ` - ${
                                      input.inputOptions.find(
                                        (io) => io.id === input.value
                                      ).avalonName
                                    }`
                                  : null}
                              </td>
                              <td style={{ paddingLeft: '6px', textAlign: 'right' }}>
                                {calculatePrice(input) || '$0'}
                              </td>
                            </tr>
                          );
                        });
                    }
                  })}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        style={{
          padding: '10px 24px',
          margin: '0 24px',
          backgroundColor: '#171A20',
          color: '#fff',
          borderRadius: '8px',
        }}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <h3
            style={{
              marginTop: '0px',
              marginBottom: '0px',
            }}
          >
            TOTAL MSRP
          </h3>
          <h3
            style={{
              marginLeft: 'auto',
              marginTop: '0px',
              marginBottom: '0px',
              textAlign: 'right',
            }}
          >
            {formatPrice(price / 0.64)}
          </h3>
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: '4px',
          }}
        >
          <h3
            style={{
              marginTop: '0px',
              marginBottom: '0px',
            }}
          >
            AVERAGE RETAIL PRICE
          </h3>
          <h3
            style={{
              marginLeft: 'auto',
              marginTop: '0px',
              marginBottom: '0px',
              textAlign: 'right',
            }}
          >
            {formatPrice((price / 0.64) * 0.89)}
          </h3>
        </div>
        <div>
          <p style={{ fontSize: '10px', marginTop: '0px', marginBottom: '0px' }}>
            Pricing does not include shipping, motor installation, dealer rigging
            materials, or dealer supplied options
          </p>
        </div>
      </div>
      <div className="pagebreak">
        <h3>STANDARD FEATURES</h3>
        <div dangerouslySetInnerHTML={{ __html: model.description }}></div>
      </div>
    </div>
  );
};

export default CustomerPDF;
