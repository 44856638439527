import React, { useContext } from 'react';
import clsx from 'clsx';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Accordion from './Accordion';
import GridSelect from './GridSelect';
import Dropdown from './Dropdown';
import Checkbox from './Checkbox';
import Text from './Text';
import { UserContext } from '../../../userContext';
import theme from '../../../theme';

const useStyles = makeStyles((theme) => ({
  inputs: {
    padding: theme.spacing(2),
    minHeight: 'calc(90vh - 135px)',
    maxHeight: 'calc(90vh - 135px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      border: '2px solid white' /* should match background, can't be transparent */,
      backgroundColor: 'rgba(0, 0, 0, .5)',
    },
    '@media screen and (max-height: 400px) and (orientation:landscape)': {
      minHeight: '250px',
      maxHeight: '250px',
      marginTop: '10px',
    },
  },
  mobileInputs: {
    minHeight: 'unset',
    maxHeight: 'unset',
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  thumbnail: {
    marginTop: theme.spacing(1),
    maxWidth: '100%',
  },
  infoButton: {
    marginLeft: theme.spacing(1),
    color: '#ffac33',
  },
  modalSelect: {
    minWidth: '200px',
  },
}));

const Page = (props) => {
  const {
    pageId,
    inputs,
    handleInputChange,
    selectedPage,
    setPages,
    pages,
    setSelectedPage,
    handleOpenTooltip,
    formType,
    formatPrice,
    showHidden,
    brand,
    oldOrder,
    customerBuild,
    canEditDiscounts,
    canEditLockedInputs,
    calculatePriceWithMarkup,
    modelId,
  } = props;
  const classes = useStyles();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const userContext = useContext(UserContext);

  const hasTwin = inputs.find((i) => i.id === 364).value;

  const getThumbnailImage = (input) => {
    let io = input.inputOptions.find((io) => io.id === input.value);
    if (io.thumbnailImgPath !== null && io.thumbnailImgPath !== '') {
      return (
        <img
          className={classes.thumbnail}
          src={`https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/images/${io.thumbnailImgPath}`}
          height="80"
          alt={io.avalonName}
        />
      );
    } else {
      return null;
    }
  };

  const getPageName = () => {
    switch (selectedPage?.name) {
      case 'Packages':
        return 'Packages';
      case 'Performance':
        return 'Performance Options';
    }
    return null;
  };

  // remained this function for now
  const hasNonValuedRequired = (po) => {
    return (
      inputs.filter(
        (i) =>
          i.active &&
          i.pageId === pages[parseInt(pageId, 10) - 1].id &&
          i.pageGroupId === po.id &&
          i.required &&
          !i.value
      ).length > 0
    );
  };

  if (!selectedPage) return <Typography variant="h1">404 Page not found</Typography>;
  return (
    <Grid
      item
      xs={12}
      className={clsx(classes.inputs, { [classes.mobileInputs]: isMobile })}
    >
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 'bold',
          marginBottom: '10px',
        }}
      >
        {getPageName()}
      </Typography>
      {selectedPage.pageGroups
        .sort((a, b) => a.pageOrder - b.pageOrder)
        .map((po, index) => {
          if (
            inputs.filter(
              (i) =>
                i.active &&
                i.pageId === pages[parseInt(pageId, 10) - 1].id &&
                i.pageGroupId === po.id
            ).length === 0
          ) {
            return null;
          } else {
            if (po.name === 'Dealer Misc Discounts' && !canEditDiscounts)
              return null;

            return (
              <Accordion
                key={`po_${po.id}`}
                title={po.name}
                isExpanded={index === 0}
              >
                {inputs
                  .filter(
                    (i) =>
                      i.active &&
                      i.pageId === pages[parseInt(pageId, 10) - 1].id &&
                      i.pageGroupId === po.id
                  )
                  .sort((a, b) => a.inputOrder - b.inputOrder)
                  .map((input) => {
                    // console.log(po.name, inputs.filter(i => i.active && i.pageId === pageId && i.pageGroupId === po.id))
                    if (input === undefined) return null;
                    if (
                      input.type === 'DROPDOWN' &&
                      input.inputOptions.filter((io) => io.active).length > 0
                    ) {
                      switch (input.inputLayout) {
                        case 'GRID':
                          return (
                            <GridSelect
                              key={input.id}
                              input={input}
                              handleInputChange={handleInputChange}
                              handleOpenTooltip={handleOpenTooltip}
                              formatPrice={formatPrice}
                              customerBuild={customerBuild}
                              calculatePriceWithMarkup={calculatePriceWithMarkup}
                              modelId={modelId}
                            />
                          );
                        default:
                          return (
                            <Dropdown
                              key={input.id}
                              input={input}
                              handleInputChange={handleInputChange}
                              handleOpenTooltip={handleOpenTooltip}
                              formatPrice={formatPrice}
                              customerBuild={customerBuild}
                              showHidden={showHidden}
                              brand={brand}
                              orderStatus={oldOrder.orderStatus}
                              role={userContext.userState.me.role}
                              hasTwin={hasTwin}
                              canEditLockedInputs={canEditLockedInputs}
                              calculatePriceWithMarkup={calculatePriceWithMarkup}
                              modelId={modelId}
                            />
                          );
                      }
                    }
                    if (input.type === 'CHECKBOX' || input.type === 'PACKAGE') {
                      return (
                        <Checkbox
                          key={input.id}
                          input={input}
                          handleInputChange={handleInputChange}
                          handleOpenTooltip={handleOpenTooltip}
                          formatPrice={formatPrice}
                          customerBuild={customerBuild}
                          showHidden={showHidden}
                          orderStatus={oldOrder.orderStatus}
                          role={userContext.userState.me.role}
                          brand={brand}
                          canEditLockedInputs={canEditLockedInputs}
                          calculatePriceWithMarkup={calculatePriceWithMarkup}
                          modelId={modelId}
                        />
                      );
                    }
                    if (input.type === 'TEXT') {
                      return (
                        <Text
                          key={input.id}
                          input={input}
                          handleInputChange={handleInputChange}
                          disabled={userContext.userState.me.role > 3}
                        />
                      );
                    }
                  })}
              </Accordion>
            );
          }
        })}
      {inputs
        .filter(
          (i) =>
            i.active &&
            i.pageId === pages[parseInt(pageId, 10) - 1]?.id &&
            i.pageGroupId === null &&
            i.overlayImage !== 'BOAT'
        )
        .sort((a, b) => a.inputOrder - b.inputOrder)
        .map((input) => {
          if (
            input.type === 'DROPDOWN' &&
            input.inputOptions.filter((io) => io.active).length > 0
          ) {
            switch (input.inputLayout) {
              case 'GRID':
                return (
                  <GridSelect
                    key={input.id}
                    input={input}
                    handleInputChange={handleInputChange}
                    handleOpenTooltip={handleOpenTooltip}
                    formatPrice={formatPrice}
                    customerBuild={customerBuild}
                    calculatePriceWithMarkup={calculatePriceWithMarkup}
                    modelId={modelId}
                  />
                );
              default:
                return (
                  <Dropdown
                    key={input.id}
                    input={input}
                    handleInputChange={handleInputChange}
                    handleOpenTooltip={handleOpenTooltip}
                    formatPrice={formatPrice}
                    customerBuild={customerBuild}
                    showHidden={showHidden}
                    brand={brand}
                    orderStatus={oldOrder.orderStatus}
                    role={userContext.userState.me.role}
                    hasTwin={hasTwin}
                    canEditLockedInputs={canEditLockedInputs}
                    calculatePriceWithMarkup={calculatePriceWithMarkup}
                    modelId={modelId}
                  />
                );
            }
          }

          if (input.type === 'CHECKBOX' || input.type === 'PACKAGE') {
            return (
              <Checkbox
                key={input.id}
                input={input}
                isNoGroup
                handleInputChange={handleInputChange}
                handleOpenTooltip={handleOpenTooltip}
                formatPrice={formatPrice}
                customerBuild={customerBuild}
                showHidden={showHidden}
                orderStatus={oldOrder.orderStatus}
                role={userContext.userState.me.role}
                brand={brand}
                canEditLockedInputs={canEditLockedInputs}
                calculatePriceWithMarkup={calculatePriceWithMarkup}
                modelId={modelId}
              />
            );
          }

          if (input.type === 'TEXT') {
            return (
              <Text
                key={input.id}
                input={input}
                handleInputChange={handleInputChange}
                disabled={userContext.userState.me.role > 3}
              />
            );
          }
        })}
    </Grid>
  );
};

export default Page;
