import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TextField,
  FormControl,
  Button,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router-dom';
import Accordion from './Accordion';
import Dropdown from './Dropdown';
import { UserContext } from '../../../userContext';
import NotesIcon from '@mui/icons-material/Notes';
import axios from 'axios';
import theme from '../../../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    minHeight: 'calc(90vh - 185px)',
    maxHeight: 'calc(90vh - 185px)',
    overflowY: 'auto',
    '&.no-c': {
      minHeight: 'calc(90vh - 135px)',
      maxHeight: 'calc(90vh - 135px)',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },
  },
  mobileRoot: {
    minHeight: 'unset !important',
    maxHeight: 'unset !important',
    overflow: 'hidden',
  },
  page: {
    marginBottom: theme.spacing(3),
  },
  table: {
    '& tr:last-child td': {
      borderBottom: 'none',
    },
  },
  tableOption: {
    width: '220px',
  },
  tableWarning: {
    fontWeight: '500',
    color: theme.palette.error.main,
  },
  note: {
    wordWrap: 'break-word',
    maxWidth: '43vw',
  },
  customerInfoTitle: {
    marginTop: theme.spacing(2),
  },
  customerInfoDescription: {
    color: '#747679',
  },
  formInput: {
    marginBottom: theme.spacing(2),
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
  selectLocation: {},
  selectedLocation: {
    display: 'flex',
    padding: theme.spacing(0, 2, 1.5),
    fontSize: '0.875rem',
    color: 'rgba(0, 0, 0, 0.87)',
  },
}));

const Summary = (props) => {
  const classes = useStyles();
  const {
    inputs,
    pages,
    selectedFloorplan,
    customerInfo,
    setCustomerInfo,
    customerOrderForm,
    loadingLocation,
    price,
    selectedModel,
    formatPrice,
    isSubmit,
    setUserLocation,
    orderNote,
    handleOpenNotes,
    oldOrder,
    userLocations,
    setUserLocations,
    customerBuild,
    calculatePriceWithMarkup,
    modelId,
  } = props;
  const { formType, brand } = useParams();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  const formatInputPrice = (input) => {
    if (input.type === 'DROPDOWN') {
      if (input.value === '') return null;
      return calculatePriceWithMarkup(
        input.inputOptions.find((io) => io.id === input.value)?.price || 0,
        +modelId
      );
    } else {
      if (input.value === false) return null;
      return calculatePriceWithMarkup(input.price, +modelId);
    }
  };

  const handleChangeCustomerInfo = (value, type) => {
    switch (type) {
      case 'name':
        return setCustomerInfo({ ...customerInfo, customerName: value });
      case 'email':
        return setCustomerInfo({ ...customerInfo, customerEmail: value });
      case 'phone':
        return setCustomerInfo({ ...customerInfo, customerPhone: value });
      case 'po':
        return setCustomerInfo({ ...customerInfo, po: value });
    }
  };

  const handleSelectLocation = (l) => {
    if (l.id === customerInfo.selectedLocation?.id) {
      setCustomerInfo({ ...customerInfo, selectedLocation: null });
    } else {
      // data layer event
      window.dataLayer.push({
        event: 'selectDealer',
        eventAction: {
          dealerName: l.name,
          mileage: l.distance.toFixed(2),
        },
        eventLabel: `${
          brand === 't' ? selectedModel.tahoeName : selectedModel.avalonName
        }|${selectedFloorplan.floorplan.avalonName}|${Math.round(
          price / 0.64
        )} MSRP`,
      });
      setCustomerInfo({ ...customerInfo, selectedLocation: l });
    }
  };

  const handleLocationChange = (value) => {
    setUserLocations({
      ...userLocations,
      value,
    });
    setUserLocation(value);
  };

  const getInputName = (input) => {
    if (input.type === 'CHECKBOX') {
      if (input.branded && brand === 't') {
        return input.tahoeName;
      } else {
        return input.name;
      }
    } else {
      return input.name;
    }
  };

  return (
    <Grid
      item
      xs={12}
      className={clsx({
        [`${classes.root} no-c`]: formType !== 'c',
        [classes.root]: formType === 'c',
        [classes.mobileRoot]: isMobile,
      })}
    >
      {isSubmit ? (
        <div>
          <form autoComplete="off" className={classes.page} noValidate>
            <Typography variant="h6" className={classes.customerInfoTitle}>
              {customerOrderForm ? 'Contact Information' : 'Customer Info'}
            </Typography>
            <Typography variant="p" className={classes.customerInfoDescription}>
              After entering your information below, a dealer will be in contact with
              you shortly.
            </Typography>
            <FormControl
              fullWidth
              sx={{
                marginTop: '25px',
              }}
              className={classes.formInput}
            >
              <TextField
                id="customerName"
                name="customerName"
                label="Name"
                variant="standard"
                value={customerInfo.customerName}
                onChange={(e) => handleChangeCustomerInfo(e.target.value, 'name')}
                error={customerOrderForm && customerInfo.customerName === ''}
                required={customerOrderForm}
              />
              {customerInfo.customerName.length > 25 && (
                <Typography
                  variant="body2"
                  className={classes.tableWarning}
                  sx={{ mt: 1 }}
                >
                  Max customer name length is 25 characters.
                </Typography>
              )}
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="customerPhone"
                name="customerPhone"
                label="Phone"
                variant="standard"
                value={customerInfo.customerPhone}
                onChange={(e) => handleChangeCustomerInfo(e.target.value, 'phone')}
                className={classes.formInput}
                error={customerOrderForm && customerInfo.customerPhone === ''}
                required={customerOrderForm}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="customerEmail"
                name="customerEmail"
                label="Email"
                variant="standard"
                value={customerInfo.customerEmail}
                onChange={(e) => handleChangeCustomerInfo(e.target.value, 'email')}
                className={classes.formInput}
              />
            </FormControl>
            {customerOrderForm ? (
              <FormControl fullWidth>
                <TextField
                  id="customerZipcode"
                  name="customerZipcode"
                  label="Zipcode"
                  variant="standard"
                  value={customerInfo.customerZipcode}
                  onChange={(e) =>
                    setCustomerInfo({
                      ...customerInfo,
                      customerZipcode: e.target.value,
                    })
                  }
                  className={classes.formInput}
                  error={customerInfo.customerZipcode === ''}
                  required
                />
              </FormControl>
            ) : null}
            {loadingLocation &&
            customerInfo.customerZipcode !== '' &&
            customerOrderForm ? (
              <Typography variant="h6">Finding nearest dealership...</Typography>
            ) : null}
            {customerInfo.location !== null &&
            !loadingLocation &&
            customerOrderForm ? (
              <div>
                <Typography variant="h6">Select a location</Typography>
                {customerInfo.location.map((l) => {
                  return (
                    <Grid container alignItems="center" key={l.id}>
                      <Grid item sm={2}>
                        <Button
                          size="small"
                          variant="contained"
                          color={
                            customerInfo.selectedLocation?.id !== null &&
                            customerInfo.selectedLocation?.id === l.id
                              ? 'secondary'
                              : 'primary'
                          }
                          onClick={() => handleSelectLocation(l)}
                        >
                          {customerInfo.selectedLocation?.id !== null &&
                          customerInfo.selectedLocation?.id === l.id
                            ? 'Selected'
                            : 'Select'}
                        </Button>
                      </Grid>
                      <Grid item sm={10}>
                        <Typography variant="h6">
                          {l.name} - {l.distance.toFixed(2)}mi
                        </Typography>
                        <Typography variant="body1">{l.address}</Typography>
                        <Typography variant="body1">
                          {l.city}, {l.state} {l.country}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </div>
            ) : null}
          </form>
        </div>
      ) : (
        <div>
          <Accordion title="Initial Boat Build" isExpanded>
            {!customerOrderForm && oldOrder.notes !== undefined ? (
              <Button
                variant="contained"
                size="small"
                color="secondary"
                startIcon={<NotesIcon />}
                onClick={() => handleOpenNotes()}
                className={classes.button}
                sx={{ mb: 2 }}
              >
                {oldOrder.notes.filter((n) => n.pageId === null).length} Order Notes
              </Button>
            ) : null}
            {!customerOrderForm && oldOrder.notes === undefined ? (
              <Button
                variant="contained"
                size="small"
                color="secondary"
                startIcon={<NotesIcon />}
                onClick={() => handleOpenNotes()}
                className={classes.button}
                sx={{ mb: 2 }}
              >
                {orderNote.length > 0 ? '1' : '0'} Order Notes
              </Button>
            ) : null}

            {!customerOrderForm && userLocations ? (
              <div className={classes.selectLocation}>
                <Dropdown
                  input={userLocations}
                  handleInputChange={handleLocationChange}
                />
              </div>
            ) : null}
            {!customerOrderForm ? (
              <div>
                <FormControl className={classes.formInput} fullWidth>
                  <TextField
                    id="customerName"
                    name="customerName"
                    label="Customer Name"
                    value={customerInfo.customerName}
                    onChange={(e) =>
                      handleChangeCustomerInfo(e.target.value, 'name')
                    }
                    size="small"
                  />
                  {customerInfo.customerName.length > 25 && (
                    <Typography
                      variant="body2"
                      className={classes.tableWarning}
                      sx={{ mt: 1 }}
                    >
                      Max customer name length is 25 characters. Please enter any
                      additional information in an order note.
                    </Typography>
                  )}
                </FormControl>
                <FormControl className={classes.formInput} fullWidth>
                  <TextField
                    id="po"
                    name="po"
                    label="PO"
                    value={customerInfo.po}
                    onChange={(e) => handleChangeCustomerInfo(e.target.value, 'po')}
                    size="small"
                  />
                  {customerInfo.po.length > 25 && (
                    <Typography
                      variant="body2"
                      className={classes.tableWarning}
                      sx={{ mt: 1 }}
                    >
                      Max PO length is 25 characters. Please enter any additional
                      information in an order note.
                    </Typography>
                  )}
                </FormControl>
              </div>
            ) : null}
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Floorplan</TableCell>
                  <TableCell className={classes.tableOption}>
                    {selectedFloorplan.floorplan.avalonName}
                  </TableCell>
                  <TableCell align="right">
                    {calculatePriceWithMarkup(
                      selectedFloorplan.floorplan.price,
                      +modelId
                    )}
                  </TableCell>
                </TableRow>
                {inputs
                  .filter((input) => input.id === 164)
                  .map((input) => {
                    return (
                      <TableRow key={`input_${input.id}`}>
                        <TableCell>{getInputName(input)}</TableCell>
                        <TableCell className={classes.tableOption}>
                          {input.type === 'DROPDOWN' && input.value !== ''
                            ? input.inputOptions.find((io) => io.id === input.value)
                                .avalonName
                            : null}
                          {input.type === 'DROPDOWN' &&
                          input.value === '' &&
                          input.required ? (
                            <span className={classes.tableWarning}>
                              MISSING REQUIRED OPTION
                            </span>
                          ) : null}
                        </TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Accordion>
          {pages.map((p) => {
            if (
              inputs.filter(
                (input) =>
                  input.pageId === p.id &&
                  input.active &&
                  ((input.value !== '' &&
                    input.type === 'DROPDOWN' &&
                    (input.inputOptions.find((io) => io.id === input.value)?.price >
                      0 ||
                      input.hidePrint)) ||
                    (input.value !== false &&
                      input.type === 'CHECKBOX' &&
                      (input.price > 0 || input.hidePrint)) ||
                    (input.type === 'DROPDOWN' &&
                      input.value === '' &&
                      input.required))
              ).length === 0
            ) {
              return null;
            } else {
              return (
                <Accordion key={p.id} title={p.name} isExpanded>
                  <Table className={classes.table} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell></TableCell>
                        <TableCell align="right">Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {inputs
                        .filter(
                          (input) =>
                            input.pageId === p.id &&
                            input.active &&
                            ((input.value !== '' &&
                              input.type === 'DROPDOWN' &&
                              (input.inputOptions.find((io) => io.id === input.value)
                                ?.price > 0 ||
                                input.hidePrint)) ||
                              (input.value !== false &&
                                input.type === 'CHECKBOX' &&
                                (input.price > 0 || input.hidePrint)) ||
                              (input.type === 'DROPDOWN' &&
                                input.value === '' &&
                                input.required))
                        )
                        .sort((a, b) => a.printOrder - b.printOrder)
                        .map((input) => {
                          if (input.id === 142 || input.id === 164) return null;
                          if (input.id === 6) {
                            let twinMotorUpgrade = inputs.find(
                              (ui) => ui.id === 364
                            );
                            let prerigInput = inputs.find((i) => i.id === 4);
                            let selectedPrerig = prerigInput.inputOptions.find(
                              (io) => io.id === prerigInput.value
                            );
                            let primaryMotorSetting =
                              selectedPrerig.prerigMotors.find(
                                (pm) => pm.motor === input.value
                              );

                            if (twinMotorUpgrade.value) {
                              let twinMotorSetting =
                                selectedPrerig.prerigMotors.find(
                                  (pm) => pm.twin === input.value
                                );

                              let twinOption = null;
                              if (twinMotorSetting) {
                                twinOption = input.inputOptions.find(
                                  (io) => io.id === twinMotorSetting?.motor
                                );
                              }

                              return (
                                <React.Fragment
                                  key={`input_${input.id}_${primaryMotorSetting.motor}_motors`}
                                >
                                  <TableRow
                                    key={`input_${input.id}_${primaryMotorSetting.motor}_m1`}
                                  >
                                    <TableCell>{getInputName(input)}</TableCell>
                                    <TableCell className={classes.tableOption}>
                                      {
                                        input.inputOptions.find(
                                          (io) => io.id === input.value
                                        ).avalonName
                                      }
                                    </TableCell>
                                    <TableCell align="right">
                                      {calculatePriceWithMarkup(
                                        primaryMotorSetting.epicorPrice,
                                        modelId
                                      )}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={`input_${input.id}_${twinMotorSetting.motor}_m2`}
                                  >
                                    <TableCell>{getInputName(input)}</TableCell>
                                    <TableCell className={classes.tableOption}>
                                      {twinOption.avalonName}
                                    </TableCell>
                                    <TableCell align="right">
                                      {calculatePriceWithMarkup(
                                        twinMotorSetting.epicorPrice,
                                        modelId
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              );
                            }
                          }
                          return (
                            <TableRow key={`input_${input.id}`}>
                              <TableCell>{getInputName(input)}</TableCell>
                              <TableCell className={classes.tableOption}>
                                {input.type === 'DROPDOWN' &&
                                input.value !== '' &&
                                input.inputOptions.find(
                                  (io) => io.id === input.value
                                )
                                  ? input.inputOptions.find(
                                      (io) => io.id === input.value
                                    ).avalonName
                                  : null}
                                {input.type === 'DROPDOWN' &&
                                input.value === '' &&
                                input.required ? (
                                  <span className={classes.tableWarning}>
                                    MISSING REQUIRED OPTION
                                  </span>
                                ) : null}
                              </TableCell>
                              <TableCell align="right">
                                {formatInputPrice(input) !== null
                                  ? formatInputPrice(input)
                                  : 'N/A'}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </Accordion>
              );
            }
          })}
        </div>
      )}
    </Grid>
  );
};

export default Summary;
