import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Grid,
  TextField,
  MenuItem,
  InputAdornment,
} from '@mui/material';

const Text = (props) => {
  const { input, classes, renderActiveButtonText } = props;
  const [defaultRule, setDefaultRule] = useState({ changeValue: '' });
  const [priceRule, setPriceRule] = useState({ changeValue: '' });
  const [activeRule, setActiveRule] = useState({ changeValue: '' });

  useEffect(() => {
    let dRule = input?.rules?.filter((rule) => rule.changeType === 'DEFAULT');
    if (dRule !== undefined && dRule.length > 0) {
      setDefaultRule(dRule[0]);
    }

    let pRule = input?.rules?.filter((rule) => rule.changeType === 'PRICE');
    if (pRule !== undefined && pRule.length > 0) {
      console.log(pRule);
      setPriceRule(pRule[0]);
    }

    let aRule = input?.rules?.filter((rule) => rule.changeType === 'ACTIVE');
    if (aRule !== undefined && aRule.length > 0) {
      console.log(aRule[0]);
      setActiveRule(aRule[0]);
    }
  }, [input]);

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item>
        <TextField
          id="default-value"
          label="Default value"
          value={defaultRule.changeValue}
          size="small"
          className={defaultRule.changeValue}
          sx={{ width: '25ch' }}
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color={activeRule.changeValue !== '' ? 'secondary' : 'primary'}
        >
          {renderActiveButtonText(input)}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Text;
