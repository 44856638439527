import { useState } from 'react';
import {
  Grid,
  Button,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import GetAppIcon from '@mui/icons-material/GetApp';
import NotesIcon from '@mui/icons-material/Notes';
import SendIcon from '@mui/icons-material/Send';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CheckIcon from '@mui/icons-material/Check';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';

const useStyles = makeStyles((theme) => ({
  actionBar: {
    ['@media print']: {
      display: 'none',
    },
  },
}));

const ActionsBar = (props) => {
  const {
    handleOpenDiscounts,
    handleTogglePricing,
    showMSRP,
    numNotes,
    handleOpenNotes,
    handleOpenHistory,
    handleEditOrder,
    canEdit,
    canDownload,
    download,
    handleOpenSubmit,
    canSubmit,
    canVerify,
    handleOpenActionModal,
    canDuplicate,
    canCreateOrder,
    canReset,
    canEditDealership,
    handleOpenEditDealership,
    downloadAsCSV,
    rebateAllowed,
    handleOpenRebates,
  } = props;
  const classes = useStyles();

  return (
    <Grid container item xs={12} spacing={2} className={classes.actionBar}>
      <Grid item container spacing={2} justifyContent="center">
        {canDownload && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<GetAppIcon />}
              onClick={() => download()}
            >
              Download
            </Button>
          </Grid>
        )}
        {canDownload && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<GetAppIcon />}
              onClick={downloadAsCSV}
            >
              Download CSV
            </Button>
          </Grid>
        )}
        {canEdit && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon fontSize="small" />}
              onClick={() => handleEditOrder()}
            >
              Edit
            </Button>
          </Grid>
        )}

        {canSubmit && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SendIcon fontSize="small" />}
              onClick={() => handleOpenSubmit()}
            >
              Submit
            </Button>
          </Grid>
        )}

        {canVerify && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CheckIcon fontSize="small" />}
              onClick={() =>
                handleOpenActionModal({
                  name: 'Verify Order',
                  type: 'verify',
                  showButton: true,
                  message: 'Are you sure you want to verify this order?',
                })
              }
            >
              Verify
            </Button>
          </Grid>
        )}

        {canDuplicate && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileCopyIcon fontSize="small" />}
              onClick={() =>
                handleOpenActionModal({
                  name: 'Duplicate Order',
                  type: 'duplicate',
                  showButton: true,
                  message: 'Are you sure you want to duplicate this order?',
                })
              }
            >
              Duplicate
            </Button>
          </Grid>
        )}

        {canCreateOrder && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileCopyIcon fontSize="small" />}
              onClick={() =>
                handleOpenActionModal({
                  name: 'Create Order',
                  type: 'duplicate',
                  showButton: true,
                  message:
                    'Are you sure you want to create an order from this lead?',
                })
              }
            >
              Create Order
            </Button>
          </Grid>
        )}

        {canReset && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ClearIcon fontSize="small" />}
              onClick={() =>
                handleOpenActionModal({
                  name: 'Reset Order',
                  type: 'reset',
                  showButton: true,
                  message: 'Are you sure you want to reset this order?',
                })
              }
            >
              Reset Order
            </Button>
          </Grid>
        )}
        {canEditDealership && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon fontSize="small" />}
              onClick={() => handleOpenEditDealership()}
            >
              Change Dealer
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<NotesIcon />}
            onClick={() => handleOpenNotes()}
          >
            {numNotes} Notes
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<HistoryIcon fontSize="small" />}
            onClick={() => handleOpenHistory()}
          >
            Action Log
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AttachMoneyIcon fontSize="small" />}
            onClick={() => handleOpenDiscounts()}
          >
            Discounts
          </Button>
        </Grid>
        {rebateAllowed && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AttachMoneyIcon fontSize="small" />}
              onClick={() => handleOpenRebates()}
            >
              Rebates
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ActionsBar;
