import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  ButtonGroup,
} from '@mui/material';
import { useParams, Link as RouterLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import Loading from '../../../../../components/Loading';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Checkbox from './Checkbox';
import Dropdown from './Dropdown';
import Text from './Text';
const useStyles = makeStyles((theme) => ({
  highlightedRule: {
    '& fieldset': {
      borderColor: theme.palette.secondary.main,
      borderWidth: '2px',
    },
  },
}));

const InputWithRules = () => {
  const { pageId, inputId, yValue, zId } = useParams();
  const [listView, setListView] = useState(false);
  const [input, setInput] = useState(null);
  const [loading, setLoading] = useState(true);
  const [defaultRule, setDefaultRule] = useState({
    id: null,
    changeType: 'DEFAULT',
    changeValue: '',
  });
  const [priceRule, setPriceRule] = useState({
    id: null,
    changeType: 'PRICE',
    changeValue: '',
  });
  const [activeRule, setActiveRule] = useState({
    id: null,
    changeType: 'ACTIVE',
    changeValue: '',
  });
  const [disabledRule, setDisabledRule] = useState({
    id: null,
    changeType: 'DISABLED',
    changeValue: '',
  });
  const [requiredRule, setRequiredRule] = useState({
    id: null,
    changeType: 'REQUIRED',
    changeValue: '',
  });
  const [mathRule, setMathRule] = useState({
    id: null,
    changeType: 'MATH',
    changeValue: '',
  });
  const classes = useStyles();
  const [rulesList, setRulesList] = useState([]);
  const [selectedListRules, setSelectedListRules] = useState([]);

  useEffect(() => {
    const loadInputWithRules = async () => {
      const { data } = await axios.get('/api/input/rules/single', {
        params: { xId: inputId, yValue: yValue, zId: zId },
      });

      data.input.rules.forEach((rule) => {
        switch (rule.changeType) {
          case 'DEFAULT':
            setDefaultRule(rule);
            break;
          case 'PRICE':
            setPriceRule(rule);
            break;
          case 'ACTIVE':
            setActiveRule(rule);
            break;
          case 'DISABLED':
            setDisabledRule(rule);
            break;
          case 'REQUIRED':
            setRequiredRule(rule);
            break;
          case 'MATH':
            setMathRule(rule);
            break;
        }
      });
      data.input.inputOptions = [
        {
          id: 0,
          avalonName: 'Not Selected',
          rules: data.input.rules.filter((r) => r.zDropdownId === 0),
          price: 0,
        },
        ...data.input.inputOptions,
      ];
      data.input.inputOptions = data.input.inputOptions.map((io) => {
        if (io.rules.filter((rule) => rule.changeType === 'PRICE').length === 0) {
          io = {
            ...io,
            rules: [
              ...io.rules,
              {
                id: null,
                changeType: 'PRICE',
                changeValue: '',
              },
            ],
          };
        }

        if (io.rules.filter((rule) => rule.changeType === 'IMGPATH').length === 0) {
          io = {
            ...io,
            rules: [
              ...io.rules,
              {
                id: null,
                changeType: 'IMGPATH',
                changeValue: '',
              },
            ],
          };
        }

        return io;
      });
      setInput(data.input);
      console.log(data.input);
      setLoading(false);
    };

    setLoading(true);
    loadInputWithRules();
  }, [zId]);

  const handleMathRule = (e) => {
    if (mathRule.id === null && mathRule.changeValue === '') {
      setMathRule({
        ...mathRule,
        xId: parseInt(inputId, 10),
        xType: input.xType,
        yValue: yValue,
        zId: parseInt(zId, 10),
        zDropdownId: null,
        changeType: 'MATH',
        changeValue: e.target.value,
        active: true,
      });
    } else {
      setMathRule({ ...mathRule, changeValue: e.target.value });
    }
  };

  const handleRequiredRule = (e) => {
    if (requiredRule.id === null && requiredRule.changeValue === '') {
      setRequiredRule({
        ...requiredRule,
        xId: parseInt(inputId, 10),
        xType: input.xType,
        yValue: yValue,
        zId: parseInt(zId, 10),
        zDropdownId: null,
        changeType: 'REQUIRED',
        changeValue: '1',
        active: true,
      });
    } else {
      setRequiredRule({ ...requiredRule, changeValue: '' });
    }
  };

  const handleDefaultRule = (e) => {
    // Create new rule
    if (defaultRule.id === null) {
      setDefaultRule({
        ...defaultRule,
        xId: parseInt(inputId, 10),
        xType: input.xType,
        yValue: yValue,
        zId: parseInt(zId, 10),
        zDropdownId: null,
        changeType: 'DEFAULT',
        changeValue: e.target.value,
        active: true,
      });
    } else {
      console.log({
        ...defaultRule,
        xId: parseInt(inputId, 10),
        xType: input.xType,
        yValue: yValue,
        zId: parseInt(zId, 10),
        zDropdownId: null,
        changeType: 'DEFAULT',
        changeValue: e.target.value,
        active: true,
      });
      setDefaultRule({ ...defaultRule, changeValue: e.target.value });
    }
  };

  const handlePriceRule = (e) => {
    if (priceRule.id === null) {
      setPriceRule({
        ...priceRule,
        xId: parseInt(inputId, 10),
        xType: input.xType,
        yValue: yValue,
        zId: parseInt(zId, 10),
        zDropdownId: null,
        changeType: 'PRICE',
        changeValue: e.target.value,
        active: true,
      });
    } else {
      setPriceRule({ ...priceRule, changeValue: e.target.value });
    }
  };

  const handleActiveRule = () => {
    if (activeRule.changeValue === '') {
      // new rule, opposite of the default active state
      setActiveRule({
        ...activeRule,
        xId: parseInt(inputId, 10),
        xType: input.xType,
        yValue: yValue,
        zId: parseInt(zId, 10),
        zDropdownId: null,
        changeType: 'ACTIVE',
        changeValue: input.active ? '0' : '1',
        active: true,
      });
    } else {
      // edit existing rule
      setActiveRule({ ...activeRule, changeValue: '' });
    }
  };

  const handleDisabledRule = () => {
    if (disabledRule.id === null && disabledRule.changeValue === '') {
      setDisabledRule({
        ...disabledRule,
        xId: parseInt(inputId, 10),
        xType: input.xType,
        yValue: yValue,
        zId: parseInt(zId, 10),
        zDropdownId: null,
        changeType: 'DISABLED',
        changeValue: '1',
        active: true,
      });
    } else {
      setDisabledRule({ ...disabledRule, changeValue: '' });
    }
  };

  const handleSaveInputRules = async () => {
    let groupedRules = { create: [], remove: [], update: [] };
    // Default
    if (defaultRule.id === null && defaultRule.changeValue !== '') {
      groupedRules.create.push(defaultRule);
    } else if (defaultRule.id !== null && defaultRule.changeValue !== '') {
      // only add rule to update if changevalue is different from start
      let foundRule = input.rules.find((r) => r.changeType === 'DEFAULT');
      if (foundRule?.changeValue !== defaultRule.changeValue)
        groupedRules.update.push(defaultRule);
    } else if (defaultRule.id !== null && defaultRule.changeValue === '') {
      groupedRules.remove.push(defaultRule);
    }

    // Price
    if (priceRule.id === null && priceRule.changeValue !== '') {
      groupedRules.create.push(priceRule);
    } else if (priceRule.id !== null && priceRule.changeValue !== '') {
      // only add rule to update if changevalue is different from start
      let foundRule = input.rules.find((r) => r.changeType === 'PRICE');
      if (foundRule?.changeValue !== priceRule.changeValue)
        groupedRules.update.push(priceRule);
    } else if (priceRule.id !== null && priceRule.changeValue === '') {
      groupedRules.remove.push(priceRule);
    }
    // Active
    if (activeRule.id === null && activeRule.changeValue !== '') {
      groupedRules.create.push(activeRule);
    } else if (activeRule.id !== null && activeRule.changeValue === '') {
      groupedRules.remove.push(activeRule);
    }
    // Disabled
    if (disabledRule.id === null && disabledRule.changeValue !== '') {
      groupedRules.create.push(disabledRule);
    } else if (disabledRule.id !== null && disabledRule.changeValue !== '') {
      // only add rule to update if changevalue is different from start
      let foundRule = input.rules.find((r) => r.changeType === 'DISABLED');
      if (foundRule?.changeValue !== disabledRule.changeValue)
        groupedRules.update.push(disabledRule);
    } else if (disabledRule.id !== null && disabledRule.changeValue === '') {
      groupedRules.remove.push(disabledRule);
    }
    // Required
    if (requiredRule.id === null && requiredRule.changeValue !== '') {
      groupedRules.create.push(requiredRule);
    } else if (requiredRule.id !== null && requiredRule.changeValue !== '') {
      // only add rule to update if changevalue is different from start
      let foundRule = input.rules.find((r) => r.changeType === 'DISABLED');
      if (foundRule?.changeValue !== requiredRule.changeValue)
        groupedRules.update.push(requiredRule);
    } else if (requiredRule.id !== null && requiredRule.changeValue === '') {
      groupedRules.remove.push(requiredRule);
    }
    // Math
    if (mathRule.id === null && mathRule.changeValue !== '') {
      groupedRules.create.push(mathRule);
    } else if (mathRule.id !== null && mathRule.changeValue !== '') {
      // only add rule to update if changevalue is different from start
      let foundRule = input.rules.find((r) => r.changeType === 'MATH');
      if (foundRule?.changeValue !== requiredRule.changeValue)
        groupedRules.update.push(mathRule);
    } else if (mathRule.id !== null && mathRule.changeValue === '') {
      groupedRules.remove.push(mathRule);
    }

    // api call to bulk rules
    // update rules on input and state
    let updatedRules = [...input.rules];
    console.log(groupedRules);
    groupedRules.update.forEach((rule) => {
      let tmp = updatedRules.find((ur) => ur.id === rule.id);
      if (tmp !== undefined) tmp.changeValue = rule.changeValue;
    });
    updatedRules = updatedRules.filter(
      (rule) => groupedRules.remove.filter((r) => r.id === rule.id).length === 0
    );

    // Reset rules that were deleted
    groupedRules.remove.forEach((rule) => {
      switch (rule.changeType) {
        case 'DEFAULT':
          setDefaultRule({
            id: null,
            changeType: 'DEFAULT',
            changeValue: '',
          });
          break;
        case 'PRICE':
          setPriceRule({
            id: null,
            changeType: 'PRICE',
            changeValue: '',
          });
          break;
        case 'ACTIVE':
          setActiveRule({
            id: null,
            changeType: 'ACTIVE',
            changeValue: '',
          });
          break;
        case 'DISABLED':
          setDisabledRule({
            id: null,
            changeType: 'DISABLED',
            changeValue: '',
          });
          break;
        case 'REQUIRED':
          setRequiredRule({
            id: null,
            changeType: 'REQUIRED',
            changeValue: '',
          });
          break;
      }
    });

    if (input.type === 'DROPDOWN') {
      input.inputOptions.forEach((io) => {
        if (io.rules.length > 0) {
          io.rules.forEach((ior) => {
            if (ior.id === null && ior.changeValue !== '') {
              groupedRules.create.push(ior);
            }

            if (ior.id !== null && ior.changeValue !== '') {
              groupedRules.update.push(ior);
            }

            if (ior.id !== null && ior.changeValue === '') {
              groupedRules.remove.push(ior);
            }
          });
        }
      });
    }

    try {
      const { data } = await axios.post('/api/rules/batch', groupedRules);

      setInput({
        ...input,
        rules: data.rules.filter((r) => r.zDropdownId === null),
        inputOptions: input.inputOptions.map((io) => {
          let optionRules = data.rules.filter((r) => r.zDropdownId === io.id);
          if (optionRules.length > 0) {
            if (optionRules.filter((or) => or.changeType === 'IMGPATH').length === 0)
              optionRules.push({ id: null, changeType: 'IMGPATH', changeValue: '' });
            if (optionRules.filter((or) => or.changeType === 'PRICE').length === 0)
              optionRules.push({ id: null, changeType: 'PRICE', changeValue: '' });
            return { ...io, rules: optionRules };
          } else {
            return {
              ...io,
              rules: [
                { id: null, changeType: 'IMGPATH', changeValue: '' },
                { id: null, changeType: 'PRICE', changeValue: '' },
              ],
            };
          }
        }),
      });
      data.rules
        .filter((r) => r.zDropdownId === null)
        .forEach((rule) => {
          switch (rule.changeType) {
            case 'DEFAULT':
              setDefaultRule(rule);
              break;
            case 'PRICE':
              setPriceRule(rule);
              break;
            case 'ACTIVE':
              setActiveRule(rule);
              break;
            case 'DISABLED':
              setDisabledRule(rule);
              break;
          }
        });
    } catch (err) {
      console.log(err.message);
    }
  };

  const renderActiveButtonText = () => {
    if (activeRule.changeValue === '') {
      return input.active ? 'Active' : 'Inactive';
    } else {
      return activeRule.changeValue === '1' ? 'Active' : 'Inactive';
    }
  };

  const handleViewToggle = (value) => {
    let tmpRules = [];
    let inputRules = input.rules.filter(
      (r) => r.id !== null || r.changeValue !== ''
    );
    inputRules = inputRules.filter((r) => r.zDropdownId !== 0);
    if (input.type === 'DROPDOWN') {
      input.inputOptions.forEach((io) => {
        let ioRules = io.rules.filter(
          (ior) => ior.id !== null || ior.changeValue !== ''
        );
        if (ioRules.length > 0) {
          tmpRules = tmpRules.concat(ioRules);
        }
      });
    }
    tmpRules = tmpRules.concat(inputRules);
    setRulesList(tmpRules);
    setListView(value);
  };

  const renderInputSettings = () => {
    switch (input.type) {
      case 'CHECKBOX':
        return (
          <Checkbox
            input={input}
            defaultRule={defaultRule}
            handleDefaultRule={handleDefaultRule}
            priceRule={priceRule}
            handlePriceRule={handlePriceRule}
            activeRule={activeRule}
            handleActiveRule={handleActiveRule}
            disabledRule={disabledRule}
            handleDisabledRule={handleDisabledRule}
            requiredRule={requiredRule}
            handleRequiredRule={handleRequiredRule}
            classes={classes}
            renderActiveButtonText={renderActiveButtonText}
          />
        );
      case 'DROPDOWN':
        return (
          <Dropdown
            input={input}
            setInput={setInput}
            defaultRule={defaultRule}
            handleDefaultRule={handleDefaultRule}
            activeRule={activeRule}
            handleActiveRule={handleActiveRule}
            disabledRule={disabledRule}
            handleDisabledRule={handleDisabledRule}
            requiredRule={requiredRule}
            handleRequiredRule={handleRequiredRule}
            mathRule={mathRule}
            handleMathRule={handleMathRule}
            classes={classes}
            renderActiveButtonText={renderActiveButtonText}
            inputId={inputId}
            yValue={yValue}
            zId={zId}
          />
        );
      case 'TEXT':
        return (
          <Text
            input={input}
            setInput={setInput}
            defaultRule={defaultRule}
            handleDefaultRule={handleDefaultRule}
            activeRule={activeRule}
            handleActiveRule={handleActiveRule}
            disabledRule={disabledRule}
            handleDisabledRule={handleDisabledRule}
            mathRule={mathRule}
            handleMathRule={handleMathRule}
            classes={classes}
            renderActiveButtonText={renderActiveButtonText}
            inputId={inputId}
            yValue={yValue}
            zId={zId}
          />
        );
      default:
        return null;
    }
  };

  const handleDeleteRules = async () => {
    console.log(selectedListRules);
    try {
      await axios.post('/api/rules/delete', selectedListRules);
      setInput({
        ...input,
        rules: input.rules.filter((r) => selectedListRules.indexOf(r.id) === -1),
        inputOptions: input.inputOptions.map((io) => {
          return {
            ...io,
            rules: io.rules.filter((r) => selectedListRules.indexOf(r.id) === -1),
          };
        }),
      });
      setRulesList(rulesList.filter((r) => selectedListRules.indexOf(r.id) === -1));
      setSelectedListRules([]);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Box component="div" padding={2}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<Typography variant="h4">-</Typography>}
                  >
                    <Link
                      underline="hover"
                      component={RouterLink}
                      to={`/settings/rules/single/page/${pageId}/input/${inputId}/value/${yValue}`}
                    >
                      <Typography variant="h4">Inputs with rules</Typography>
                    </Link>
                    <Typography color="text.primary" variant="h4">
                      <strong>{input.name}</strong>
                    </Typography>
                  </Breadcrumbs>
                </Grid>
                <Grid item>
                  <Box component="div" sx={{ mt: 1 }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ mr: 2 }}
                      onClick={handleSaveInputRules}
                    >
                      Save Rules
                    </Button>

                    <ButtonGroup variant="contained">
                      <Button
                        color={!listView ? 'secondary' : 'primary'}
                        onClick={() => handleViewToggle(false)}
                      >
                        Form
                      </Button>
                      <Button
                        color={listView ? 'secondary' : 'primary'}
                        onClick={() => handleViewToggle(true)}
                      >
                        List
                      </Button>
                    </ButtonGroup>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {!listView ? (
              <Grid item xs={12}>
                {renderInputSettings()}
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  sx={{ mb: 2 }}
                  onClick={handleDeleteRules}
                  disabled={selectedListRules.length === 0}
                >
                  Delete rules
                </Button>
                <DataGridPro
                  autoHeight
                  columns={[
                    { field: 'id', headerName: 'ID', width: 90 },
                    { field: 'xId', headerName: 'xID', width: 90 },
                    { field: 'xType', headerName: 'xType', width: 120 },
                    { field: 'yValue', headerName: 'yValue', width: 90 },
                    { field: 'zId', headerName: 'zID', width: 90 },
                    { field: 'zDropdownId', headerName: 'zDropdownId', width: 90 },
                    { field: 'changeType', headerName: 'Change Type', width: 120 },
                    { field: 'changeValue', headerName: 'Change Value', width: 120 },
                  ]}
                  rows={rulesList}
                  rowHeight={38}
                  checkboxSelection
                  selectionModel={selectedListRules}
                  onSelectionModelChange={(value) => {
                    setSelectedListRules(value);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default InputWithRules;
