import React, { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EnhancedTable from '../../../../components/EnhancedTable';
import AddInputOption from '../../../../components/forms/AddInputOption';
import Loading from '../../../../components/Loading';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import EditInput from '../../../../components/forms/EditInput';
import EditInputOption from '../../../../components/forms/EditInputOption';
// import InputPreview from '../InputPreview'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import EditTooltip from '../../../../components/forms/EditTooltip';
import Nav from './Nav';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1, 2, 2, 2),
    margin: theme.spacing(2, 0),
  },
  tablePaper: {
    padding: theme.spacing(1, 0, 0, 0),
  },
  tooltipImage: {
    display: 'block',
    maxWidth: '60%',
    margin: 'auto',
    [theme.breakpoints.down('xl')]: {
      maxWidth: '80%',
    },
    [theme.breakpoints.down('xl')]: {
      maxWidth: '100%',
    },
  },
  tooltipNav: {
    textAlign: 'center',
  },
  tooltipNavButton: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  tooltipVideo: {
    display: 'block',
    width: '80%',
    maxWidth: '560px',
    height: '315px',
    margin: 'auto',
    [theme.breakpoints.down('xl')]: {
      width: '100%',
    },
  },
  tooltipBar: {
    marginTop: theme.spacing(2),
  },
}));

const Input = (props) => {
  const classes = useStyles();
  const { inputId } = useParams();
  const [openAddInputOption, setOpenAddInputOption] = useState(false);
  const [openEditInputOption, setOpenEditInputOption] = useState(false);
  const [selectedInputOption, setSelectedInputOption] = useState(null);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [tooltip, setTooltip] = useState({
    id: null,
    title: null,
    text: null,
    media: [],
    selectedMedia: 0,
  });
  const [openEditTooltip, setOpenEditTooltip] = useState(false);
  const [tags, setTags] = useState([]);
  const [input, setInput] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pageGroups, setPageGroups] = useState([]);
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const fetchInput = async () => {
      try {
        const { data } = await axios.get('/api/input', { params: { id: inputId } });
        data.input.value = '';
        setInput(data.input);
        setPageGroups(data.pageGroups);
        setRoutes([
          { name: 'Pages', path: '/settings/form/pages' },
          {
            name: data.input.page.name,
            path: `/settings/form/pages/${data.input.page.id}`,
          },
          {
            name: data.input.name,
            path: `/settings/form/pages/${data.input.page.id}/inputs/${data.input.id}`,
          },
        ]);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
      }
    };

    const fetchTags = async () => {
      try {
        const { data } = await axios.get('/api/input/tags');
        setTags(data);
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchInput();
    fetchTags();
  }, [inputId]);

  const handleOpenAddInputOption = () => {
    setOpenAddInputOption(true);
  };

  const handleCloseAddInputOption = () => {
    setOpenAddInputOption(false);
  };

  const handleOpenEditInputOption = () => {
    setOpenEditInputOption(true);
  };

  const handleCloseEditInputOption = () => {
    setOpenEditInputOption(false);
  };

  const handleOpenTooltip = () => {
    setTooltip({ ...input.tooltip, selectedMedia: 0 });
    setOpenTooltip(true);
  };

  const handleCloseTooltip = () => {
    setTooltip(null);
    setOpenTooltip(false);
  };

  const handleOpenEditTooltip = () => {
    if (input.tooltip !== null) setTooltip({ ...input.tooltip });
    setOpenEditTooltip(true);
  };

  const handleCloseEditTooltip = () => {
    setOpenEditTooltip(false);
  };

  const renderTooltipMedia = (media) => {
    if (media === undefined) return null;
    switch (media.type) {
      case 'image':
        return (
          <img
            className={classes.tooltipImage}
            src={`https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/images/${
              tooltip.media[tooltip.selectedMedia].src
            }`}
            alt={tooltip.media[tooltip.selectedMedia].alt}
          />
        );
      case 'video':
        return (
          <iframe
            className={classes.tooltipVideo}
            src={tooltip.media[tooltip.selectedMedia].src}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        );
      default:
        break;
    }
  };

  const handleDeleteTooltip = async () => {
    try {
      await axios.get('/api/input/tooltip/delete', {
        params: { id: input.tooltip.id },
      });
      setTooltip(null);
      setInput({ ...input, tooltip: null });
    } catch (err) {
      console.log(err.response.data.message);
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Grid container justify="space-between" alignContent="center" spacing={3}>
            <Grid item xs={12}>
              <Nav routes={routes} />
              <Paper className={classes.paper}>
                <Typography variant="h6">Edit Input</Typography>
                <EditInput input={input} pageGroups={pageGroups} />
                <div className={classes.tooltipBar}>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => handleOpenEditTooltip()}
                      >
                        {input.tooltip === null ? 'Add' : 'Edit'} Tooltip
                      </Button>
                    </Grid>
                    <Grid item>
                      {input.tooltip !== null ? (
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          onClick={() => handleOpenTooltip()}
                        >
                          Preview Tooltip
                        </Button>
                      ) : null}
                    </Grid>
                    <Grid item>
                      {input.tooltip !== null ? (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => handleDeleteTooltip()}
                        >
                          Delete Tooltip
                        </Button>
                      ) : null}
                    </Grid>
                  </Grid>
                </div>
                {/* <InputPreview input={input} /> */}
                <Dialog
                  open={openEditTooltip}
                  onClose={handleCloseEditTooltip}
                  fullWidth
                  maxWidth="sm"
                  aria-labelledby="edit-input-tooltip"
                >
                  <DialogTitle id="form-edit-input-tooltip">
                    Edit Tooltip
                  </DialogTitle>
                  <DialogContent>
                    <EditTooltip
                      input={input}
                      tooltip={tooltip}
                      setTooltip={setTooltip}
                      setInput={setInput}
                      closeModal={handleCloseEditTooltip}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseEditTooltip} color="primary">
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                {tooltip !== null ? (
                  <Dialog
                    open={openTooltip}
                    onClose={handleCloseTooltip}
                    maxWidth={
                      tooltip.text === null || tooltip.media.length === 0
                        ? 'sm'
                        : 'md'
                    }
                    fullWidth
                  >
                    <DialogTitle>{tooltip.title}</DialogTitle>
                    <DialogContent>
                      {tooltip.text === null ? (
                        <div>
                          {renderTooltipMedia(tooltip.media[tooltip.selectedMedia])}
                        </div>
                      ) : (
                        <Grid container spacing={2}>
                          {tooltip.media.length === 0 ? (
                            <Grid item xs={12}>
                              <Typography variant="body1" paragraph>
                                {tooltip.text}
                              </Typography>
                            </Grid>
                          ) : (
                            <>
                              <Grid item xs={12} sm={6} md={5}>
                                <Typography variant="body1" paragraph>
                                  {tooltip.text}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={7}>
                                {renderTooltipMedia(
                                  tooltip.media[tooltip.selectedMedia]
                                )}
                                {tooltip.media.length > 1 ? (
                                  <div className={classes.tooltipNav}>
                                    {tooltip.media.map((m, index) => {
                                      return (
                                        <FiberManualRecordIcon
                                          key={`m_${index}`}
                                          color={
                                            index === tooltip.selectedMedia
                                              ? 'primary'
                                              : 'disabled'
                                          }
                                          className={classes.tooltipNavButton}
                                          onClick={() =>
                                            setTooltip({
                                              ...tooltip,
                                              selectedMedia: index,
                                            })
                                          }
                                        />
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </Grid>
                            </>
                          )}
                        </Grid>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseTooltip} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                ) : null}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              {input.type === 'DROPDOWN' ? (
                <Paper className={classes.tablePaper}>
                  <EnhancedTable
                    title="Input Options"
                    data={input.inputOptions.filter((io) => !io.isDeleted)}
                    addData={handleOpenAddInputOption}
                    hiddenColumns={
                      input.branded
                        ? ['id', 'inputId', 'isDeleted', 'description']
                        : ['id', 'inputId', 'tahoeName', 'isDeleted', 'description']
                    }
                    orderBy="optionOrder"
                    disableSelectAll
                    elevation={0}
                    setSelectedData={setSelectedInputOption}
                    openEditData={handleOpenEditInputOption}
                    enableFilters
                    filterTypes={['name']}
                    nameSearchField={'avalonName'}
                  />

                  <Dialog
                    open={openAddInputOption}
                    onClose={handleCloseAddInputOption}
                    fullWidth
                    maxWidth="sm"
                    aria-labelledby="add-input-option"
                  >
                    <DialogTitle id="form-add-input-option">
                      New Input Option
                    </DialogTitle>
                    <DialogContent>
                      <AddInputOption
                        input={input}
                        setInput={setInput}
                        closeAdd={handleCloseAddInputOption}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseAddInputOption} color="primary">
                        Cancel
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    open={openEditInputOption}
                    onClose={handleCloseEditInputOption}
                    fullWidth
                    maxWidth="sm"
                    aria-labelledby="edit-input-option"
                  >
                    <DialogTitle id="form-edit-input-option">
                      New Input Option
                    </DialogTitle>
                    <DialogContent>
                      <EditInputOption
                        input={input}
                        setInput={setInput}
                        closeEdit={handleCloseEditInputOption}
                        inputOption={selectedInputOption}
                        tags={tags}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseEditInputOption} color="primary">
                        Cancel
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Paper>
              ) : null}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default Input;
