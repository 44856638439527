import { AppBar, Toolbar, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const PublicAppBar = (props) => {
  return (
    <AppBar position="relative" className={props.className}>
      <Toolbar style={{ justifyContent: 'end' }}>
        <Button component={Link} to="login" color="inherit">
          Login
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default PublicAppBar;
