import { useState, useEffect, useContext } from 'react';
import { Grid, Paper } from '@mui/material';
import axios from 'axios';
import { UserContext } from '../userContext';
import Loading from '../components/Loading';
import NewEnhancedTable from '../components/NewEnhancedTable';

const DERP = () => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const userContext = useContext(UserContext);
  const [externalReps, setExternalReps] = useState([]);
  const [selectedRep, setSelectedRep] = useState('0');
  const [initLayoutInfo, setInitLayoutInfo] = useState({});

  useEffect(() => {
    let source = axios.CancelToken.source();
    let externalRepsSource = axios.CancelToken.source();
    let tableSource = axios.CancelToken.source();
    const loadExternalReps = async () => {
      try {
        const { data } = await axios.get('/api/user/reps/external', {
          cancelToken: externalRepsSource.token,
        });
        setExternalReps(data);
      } catch (err) {
        console.log(err.message);
      }
    };

    const loadOrders = async () => {
      const { data } = await axios.get('/api/orders/status', {
        cancelToken: source.token,
        params: { status: 'all', repId: selectedRep, derp: 1 },
      });
      setOrders(data.orders);
      loadTableSettings();
    };

    const loadTableSettings = async () => {
      let title = `DERP Codes`;
      let formattedTableName = title.replace(/\s/g, '_').toLowerCase();
      try {
        const { data } = await axios.get('/api/user/table/settings', {
          cancelToken: tableSource.token,
          params: { tableName: formattedTableName },
        });
        if (data.message === 'Found table settings') {
          let tableSettings = data.tableSettings.fields;
          console.log('TABLE SETTINGS: ', tableSettings);
          setInitLayoutInfo(tableSettings);
        } else {
          setInitLayoutInfo({
            filter: {
              filterModel: {
                items: [],
                linkOperator: 'and',
              },
            },
            sorting: {
              sortModel: [],
            },
            columns: {
              columnVisibilityModel: { epicorRequestDate: false },
            },
          });
        }
      } catch (err) {
        return console.log(err);
      }
      setLoading(false);
    };

    loadOrders();
    if (userContext.userState.me.role <= 2) {
      loadExternalReps();
    }

    return function () {
      source.cancel('Cancelling in cleanup');
      externalRepsSource.cancel('Cancelling external rep in cleanup');
      tableSource.cancel('Cancelling table load in cleanup');
    };
  }, [selectedRep]);

  const viewOrder = (id) => {
    const order = orders.find((order) => order.id === id);
    console.log(order);
    let orderStatus = '';

    switch (order.orderStatus) {
      case 1:
        orderStatus = 'submitted';
        break;
      case 2:
        orderStatus = 'confirmed';
        break;
      case 3:
        orderStatus = 'complete';
        break;
    }

    window.open(
      `/dealerships/${order.dealershipId}/locations/${order.locationId}/current/${orderStatus}/${id}`,
      '_blank'
    );
  };

  const handleCompleteWarning = (row, value) => {
    setOrders(
      orders.map((order) => {
        if (order.id === row.id) {
          order.completed = !value;
        }
        return order;
      })
    );

    axios.put('/api/order/warning', {
      orderId: row.id,
      completed: !value,
    });
  };

  return (
    <Grid container justifyContent="center" spacing={3}>
      {loading ? (
        <Loading />
      ) : (
        <Grid item sm={12}>
          <Paper sx={{ p: 2 }}>
            <NewEnhancedTable
              title={`DERP Codes`}
              initLayoutInfo={initLayoutInfo}
              data={orders}
              hiddenColumns={[
                'dealershipId',
                'locationId',
                'orderStatus',
                'epicorApprovalStatus',
              ]}
              viewOrder={viewOrder}
              locations={[]}
              disableActionIcon
              enableFilters
              filterTypes={['name', 'model', 'floorplan', 'customerName']}
              externalReps={externalReps}
              selectedRep={selectedRep}
              setSelectedRep={setSelectedRep}
              handleCompleteWarning={handleCompleteWarning}
              dense
              initRowsPerPage={50}
            />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default DERP;
