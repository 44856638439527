import React, { useEffect, useState } from 'react';
import { Grid, Typography, useMediaQuery, Button, Box, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EnhancedTable from '../../../components/NewEnhancedTable';
import Loading from '../../../components/Loading';
import axios from 'axios';
import { DropzoneDialog } from 'material-ui-dropzone';
import theme from '../../../theme';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.5rem',
  },
}));

const DealerAllotment = () => {
  const classes = useStyles();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const [loading, setLoading] = useState(true);
  const [dealerships, setDealerships] = useState([]);
  const [openDropzone, setOpenDropzone] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchDealerships = async () => {
      try {
        const { data } = await axios.get('/api/dealers/all');

        setDealerships(data);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
      }
    };

    if (loading) fetchDealerships();
  }, [loading]);

  const handleEdit = async (updatedRow) => {
    const index = dealerships.findIndex((d) => d.id === updatedRow.id);
    let temp = [...dealerships];
    temp.splice(index, 1, updatedRow);
    setDealerships(temp);
    try {
      const { id, q1, q2, q3, q4 } = updatedRow;
      const payload = {
        dealershipId: id,
        q1,
        q2,
        q3,
        q4,
      };
      await axios.put('/api/dealer', payload);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownloadCSV = async () => {
    try {
      axios({
        url: '/api/dealer/allotments',
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `dealership_allotments.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleFileChange = (files) => {
    setFiles(files);
  };

  const handleFileUpload = async () => {
    let formData = new FormData();
    formData.append('file', files[0], files[0].name);

    try {
      setSubmitting(true);
      const { data } = await axios.post('/api/dealer/allotments', formData);
      console.log(data);
      setOpenDropzone(false);
      setFiles([]);
      setSubmitting(false);
      setLoading(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <Paper sx={{ p: 4 }}>
      <Grid container>
        {loading ? (
          <Loading />
        ) : (
          <Grid item xs={12}>
            <Typography className={classes.title}>Dealer Allotment</Typography>
            <Box sx={{ my: 1 }}>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => handleDownloadCSV()}
              >
                Download CSV
              </Button>
              <Button
                color="secondary"
                variant="contained"
                size="small"
                disabled={submitting}
                onClick={() => setOpenDropzone(true)}
                sx={{ ml: 2 }}
              >
                Upload CSV
              </Button>
            </Box>
            <EnhancedTable
              title="ATDealerships"
              isMobile={isMobile}
              data={dealerships}
              hiddenColumns={[
                'id',
                'reps',
                'active',
                'q1Next',
                'q2Next',
                'q3Next',
                'q4Next',
              ]}
              editableColumns={['q1', 'q2', 'q3', 'q4']}
              elevation={0}
              dense
              disableSelectAll
              handleEdit={handleEdit}
            />
            <DropzoneDialog
              onChange={(files) => handleFileChange(files)}
              filesLimit={1}
              showAlerts={false}
              open={openDropzone}
              onClose={() => setOpenDropzone(false)}
              showFileNames
              acceptedFiles={[
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              ]}
              onSave={() => handleFileUpload()}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default DealerAllotment;
