import React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import BoatImage from '../BoatImage';
import BenchImage from '../BenchImage';

const useStyles = makeStyles((theme) => ({
  container: {
    ['@media print']: {
      width: '100%',
      padding: theme.spacing(0, 2),
    },
  },
  imageContainer: {
    textAlign: 'center',
    backgroundColor: '#F8F8F8',
    borderRadius: '16px',
    height: '140px',
    maxHeight: '140px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      maxHeight: '140px',
    },
  },
  image: {
    maxHeight: '140px',
    margin: 'auto',
    maxWidth: '100%',
  },
}));

const ImageRow = (props) => {
  const { boatImages, benchImages, floorplanImage } = props;
  const classes = useStyles();
  return (
    <Grid container item spacing={2} sx={{ mb: 1 }} className={classes.container}>
      <Grid item sm={4}>
        <div className={classes.imageContainer}>
          <img
            src={`https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/images/${floorplanImage}?${new Date()}`}
            crossOrigin="anonymous"
            className={classes.image}
          />
        </div>
      </Grid>
      <Grid item sm={4}>
        <div className={classes.imageContainer}>
          <BoatImage boatImages={boatImages} />
        </div>
      </Grid>
      <Grid item sm={4}>
        <div className={classes.imageContainer}>
          <BenchImage benchImages={benchImages} />
        </div>
      </Grid>
    </Grid>
  );
};

export default ImageRow;
