import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

const DeleteLocationModal = ({
  show,
  handleClose,
  location,
  handleConfirmDelete,
}) => {
  return (
    <Dialog open={show} onClose={handleClose} aria-labelledby="edit-user">
      <DialogTitle id="form-delete-user">Delete Location</DialogTitle>
      <DialogContent>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Are you sure you want to delete <strong>{location.name}</strong>?
        </Typography>
        <Button
          color="error"
          variant="contained"
          sx={{ display: 'block' }}
          onClick={handleConfirmDelete}
        >
          Delete
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteLocationModal;
