import React, { useState } from 'react';
import { TextField, FormControl, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
  text: yup.string('Enter a note').required('Note is required'),
  orderId: yup.number().required(),
});

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2),
  },
  formMarginTop2: {
    marginTop: theme.spacing(2),
  },
}));

const AddNote = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const { selectedOrder, setSelectedOrder, orders, setOrders, repEmails } = props;

  const formik = useFormik({
    initialValues: {
      text: '',
      orderId: selectedOrder.id,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      let notification = {
        orderId: values.orderId,
        text: values.text,
        locationName: selectedOrder.location?.name
          ? selectedOrder.location.name
          : selectedOrder.location,
        emails: repEmails,
      };

      values.notification = notification;

      try {
        const { data } = await axios.post('/api/order/note', values);

        let testOrder = { ...selectedOrder, notes: data.notes };
        setSelectedOrder(testOrder);

        if (setOrders !== undefined) {
          setOrders(
            orders.map((o) => {
              if (o.id === selectedOrder.id) {
                return testOrder;
              } else {
                return o;
              }
            })
          );
        }

        resetForm();
      } catch (err) {
        setSubmitError(err.response.data.message);
      }
    },
  });

  return (
    <form
      noValidate
      autoComplete="off"
      className={classes.formMarginTop2}
      onSubmit={formik.handleSubmit}
    >
      {submitError !== null ? (
        <h3 style={{ color: '#f4436', marginTop: '0px' }}>{submitError}</h3>
      ) : null}
      <FormControl fullWidth>
        <TextField
          id="text"
          name="text"
          label="Note"
          variant="outlined"
          multiline
          rows={2}
          value={formik.values.text}
          onChange={formik.handleChange}
          className={classes.formField}
          fullWidth
        />
      </FormControl>
      <Button color="primary" variant="contained" type="submit">
        Add Note
      </Button>
    </form>
  );
};

export default AddNote;
