import React, { useState } from 'react';
import {
  Grid,
  TextField,
  Select,
  FormControl,
  Button,
  MenuItem,
  InputLabel,
  Chip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
  username: yup
    .string('Enter a username')
    .min(3, 'Username must be atleast 3 characters')
    .matches(/(^\S*$)/, { message: 'Username can not contain spaces' })
    .required('Username is required'),
  password1: yup
    .string('Please enter a password')
    .min(5, 'Password must be at least 5 characters')
    .required('Password is required'),
  name: yup.string("Enter the user's name").required('Name is required'),
  email: yup
    .string('Please enter a valid email')
    .email()
    .required('Email is required'),
  role: yup.string('').min(1, 'Select a role').required('Role is requied'),
  dealershipId: yup.string(),
  userLocations: yup.array().ensure().min(1, 'Select a location'),
});

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    chip: {
      margin: 2,
    },
  },
  actions: {
    marginTop: '10px',
  },
  cancelBtn: {
    background: '#fff',
    color: '#171A20',
    '&:hover': {
      background: '#F8F8F8',
    },
  },
  submitBtn: {
    background: '#006991',
    color: '#fff',
    '&:hover': {
      background: '#0c4054',
    },
  },
}));

const AddDealershipUser = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const { dealershipId, dealership, setDealership, closeAdd } = props;

  const formik = useFormik({
    initialValues: {
      username: '',
      password1: '',
      name: '',
      email: '',
      role: '',
      dealershipId: dealershipId ? dealershipId : '',
      userLocations: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const { data } = await axios.post('/api/user', values);
        console.log(data);
        // need to update dealership locations
        let updatedDealership = {
          ...dealership,
          users: [...dealership.users, data.user],
          locations: dealership.locations.map((location) => {
            if (
              data.user.locations.filter((l) => l.locationId === location.id)
                .length > 0
            ) {
              return {
                ...location,
                users: [
                  ...location.users,
                  data.user.locations.filter((l) => l.locationId === location.id)[0],
                ],
              };
            } else {
              return location;
            }
          }),
        };
        setDealership(updatedDealership);
        setSubmitError(null);
        resetForm();
        closeAdd();
      } catch (err) {
        setSubmitError(err.response.data.message);
      }
    },
  });

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      {submitError !== null ? (
        <h3 style={{ color: '#f44336', marginTop: '0px' }}>{submitError}</h3>
      ) : null}
      <FormControl fullWidth>
        <TextField
          id="username"
          name="username"
          label="Username"
          placeholder="Enter the username"
          value={formik.values.username}
          onChange={formik.handleChange}
          className={classes.textField}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
          fullWidth
          variant="standard"
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="password1"
          name="password1"
          label="Password"
          placeholder="Enter the password"
          value={formik.values.password1}
          onChange={formik.handleChange}
          className={classes.textField}
          error={formik.touched.password1 && Boolean(formik.errors.password1)}
          helperText={formik.touched.password1 && formik.errors.password1}
          fullWidth
          variant="standard"
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="name"
          name="name"
          label="Name"
          placeholder="Enter the name"
          value={formik.values.name}
          onChange={formik.handleChange}
          className={classes.textField}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          fullWidth
          variant="standard"
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="email"
          name="email"
          label="Email"
          placeholder="Enter the email address"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          className={classes.textField}
          fullWidth
          variant="standard"
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="roleLabel">Role</InputLabel>
        {dealershipId ? (
          <Select
            id="role"
            name="role"
            labelId="roleLabel"
            IconComponent={ExpandMoreIcon}
            value={formik.values.role}
            onChange={formik.handleChange}
            className={classes.textField}
            error={formik.touched.role && Boolean(formik.errors.role)}
            fullWidth
          >
            <MenuItem value="">Select Role</MenuItem>
            <MenuItem value="10">Manager</MenuItem>
            <MenuItem value="11">Sales</MenuItem>
            <MenuItem value="13">Accounting</MenuItem>
          </Select>
        ) : (
          <Select
            id="role"
            name="role"
            labelId="roleLabel"
            IconComponent={ExpandMoreIcon}
            value={formik.values.role}
            onChange={formik.handleChange}
            className={classes.textField}
            error={formik.touched.role && Boolean(formik.errors.role)}
            fullWidth
          >
            <MenuItem value="">Select Role</MenuItem>
            <MenuItem value="2">Sales Manager</MenuItem>
            <MenuItem value="3">Sales Rep</MenuItem>
          </Select>
        )}
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="userLocationsLabel">Location(s)</InputLabel>
        <Select
          id="userLocations"
          name="userLocations"
          labelId="userLocationsLabel"
          multiple
          IconComponent={ExpandMoreIcon}
          value={formik.values.userLocations}
          onChange={formik.handleChange}
          className={classes.textField}
          error={
            formik.touched.userLocations && Boolean(formik.errors.userLocations)
          }
          fullWidth
          renderValue={(selected) => (
            <div className={classes.chip}>
              {selected.map((location) => (
                <Chip
                  key={location.id}
                  label={location.name}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
        >
          {dealership.locations.map((location) => (
            <MenuItem key={location.id} value={location}>
              {location.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        id="dealershipId"
        name="dealershipId"
        label="dealershipId"
        value={formik.values.dealershipId}
        style={{ display: 'none' }}
      />
      <Grid container spacing={1} className={classes.actions}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            className={classes.cancelBtn}
            onClick={closeAdd}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            color="primary"
            variant="contained"
            className={classes.submitBtn}
            fullWidth
            type="submit"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddDealershipUser;
