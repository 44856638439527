import { useState, useEffect } from 'react';
import { Grid, Paper } from '@mui/material';
import axios from 'axios';
import EnhancedTable from '../components/NewEnhancedTable';
import Loading from '../components/Loading';
import { format } from 'date-fns';

export const MotorOrders = () => {
  const [motorOrders, setMotorOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initLayoutInfo, setInitLayoutInfo] = useState({});

  useEffect(() => {
    let source = axios.CancelToken.source();
    let tableSource = axios.CancelToken.source();
    const loadTableSettings = async () => {
      let title = `Motor Orders`;
      let formattedTableName = title.replace(/\s/g, '_').toLowerCase();
      try {
        const { data } = await axios.get('/api/user/table/settings', {
          cancelToken: tableSource.token,
          params: { tableName: formattedTableName },
        });
        if (data.message === 'Found table settings') {
          let tableSettings = data.tableSettings.fields;
          setInitLayoutInfo(tableSettings);
        } else {
          setInitLayoutInfo({
            filter: {
              filterModel: {
                items: [],
                linkOperator: 'and',
              },
            },
            sorting: {
              sortModel: [],
            },
            columns: {
              columnVisibilityModel: { epicorRequestDate: false },
            },
          });
        }
      } catch (err) {
        return console.log(err);
      }
      setLoading(false);
    };

    const loadMotors = async () => {
      setLoading(true);
      const response = await axios.get('/api/epicor/motor-orders', {
        cancelToken: source.token,
      });
      let formattedData = response.data.map((order) => {
        return {
          ...order,
          orderDate: format(new Date(order.orderDate), 'MM/dd/yyyy'),
        };
      });
      formattedData = formattedData.sort((a, b) => {
        return new Date(b.orderDate) - new Date(a.orderDate);
      });
      setMotorOrders(formattedData);
      loadTableSettings();
    };

    loadMotors();

    return function () {
      source.cancel('Cancelling in cleanup');
      tableSource.cancel('Cancelling table load in cleanup');
    };
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, mb: 2 }}>
          {loading ? (
            <Loading />
          ) : (
            <EnhancedTable
              title="Motor Orders"
              data={motorOrders}
              hiddenColumns={['customerId', 'id']}
              initLayoutInfo={initLayoutInfo}
              disableActionIcon
              initRowsPerPage={25}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
