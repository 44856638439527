import React, { useState, useEffect } from 'react';
import {
  gridPageCountSelector,
  gridPageSelector,
  gridVisibleRowCountSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import {
  Typography,
  MenuItem,
  Grid,
  Select,
  FormControl,
  Pagination as BasePagination,
  PaginationItem,
  useMediaQuery,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import theme from '../../theme';

const Pagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(50);

  const onChangePageSize = (size) => {
    setPageSize(size);
    apiRef.current.setPageSize(size);
  };

  useEffect(() => {
    apiRef.current.subscribeEvent('stateChange', () => {
      const len = gridVisibleRowCountSelector(apiRef.current.state);
      const size = gridPageSizeSelector(apiRef.current.state);
      setPageSize(size);
      setCount(len);
    });
  }, [apiRef]);

  return (
    <Grid
      container
      justifyContent={isMobile ? 'center' : 'space-between'}
      direction={isMobile ? 'column' : 'row'}
    >
      <Grid
        item
        sx={
          isMobile
            ? {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }
            : {
                display: 'flex',
                alignItems: 'center',
              }
        }
      >
        <Typography
          sx={{ fontWeight: '500', marginRight: '20px' }}
        >{`${count} items in total`}</Typography>
        <FormControl
          sx={{
            '& .MuiInput-root:before': {
              display: 'none',
            },
            '& .MuiInput-root:after': {
              display: 'none',
            },
          }}
        >
          <Select
            id="select-page-size"
            value={pageSize}
            IconComponent={ExpandMoreIcon}
            sx={{
              fontSize: '14px',
              color: '#747679',
            }}
            onChange={(e) => onChangePageSize(e.target.value)}
          >
            <MenuItem value={5}>Showing 5 items per page</MenuItem>
            <MenuItem value={10}>Showing 10 items per page</MenuItem>
            <MenuItem value={25}>Showing 25 items per page</MenuItem>
            <MenuItem value={50}>Showing 50 items per page</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <BasePagination
          page={page + 1}
          count={pageCount}
          showFirstButton
          showLastButton
          renderItem={(item) => (
            <PaginationItem
              components={{
                first: KeyboardDoubleArrowLeftIcon,
                last: KeyboardDoubleArrowRightIcon,
              }}
              {...item}
              sx={{
                minWidth: '24px',
              }}
            />
          )}
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
      </Grid>
    </Grid>
  );
};

export default Pagination;
