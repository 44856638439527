import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Grid,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { formatPrice } from '../lib';

const Rebate = () => {
  const { id, token } = useParams();
  const [rebate, setRebate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let source = axios.CancelToken.source();
    const getRebate = async () => {
      try {
        const { data } = await axios.get('/api/order/rebate', {
          params: {
            id,
            token,
          },
        });
        if (data.message) {
          setError(data.message);
          setLoading(false);
        } else {
          setRebate(data);
          setLoading(false);
        }
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    if (id && token) {
      getRebate();
    } else {
      setLoading(false);
      setError('Invalid URL');
    }

    return function () {
      source.cancel('Cancelling in cleanup');
    };
  }, [id, token]);

  const handleUpdateRebate = async (newStatus) => {
    const rebateUpdate = {
      rebateId: rebate.id,
      rebateStatus: newStatus,
    };

    try {
      const { data: newRebate } = await axios.put(
        '/api/order/rebate/no-user',
        rebateUpdate
      );
      setRebate(newRebate);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid container spacing={2} sx={{ justifyContent: 'center', px: 1, pb: 8 }}>
      {loading && <Typography variant="h3">Loading...</Typography>}
      {!loading && error && <Typography variant="h3">{error}</Typography>}
      {!loading && rebate && (
        <Grid item>
          <Typography variant="h6">{rebate?.dealership?.name}</Typography>
          <Typography variant="h6" sx={{ mt: 1 }}>
            ATOM ID - {rebate?.orderId}
          </Typography>
          <Typography variant="h6" sx={{ mt: 1 }}>
            EPICOR ID - {rebate?.order?.epicorOrderNumber}
          </Typography>
          <Typography variant="h6" sx={{ mt: 1 }}>
            {rebate?.OrderDtl_LineDesc}
          </Typography>
          <Typography variant="h6" sx={{ mt: 1 }}>
            Days in inventory - {rebate?.Calculated_closedfordays}
          </Typography>
          <Table size="small" sx={{ mt: 1 }}>
            <TableBody>
              <TableRow>
                <TableCell>Sales Price</TableCell>
                <TableCell>{formatPrice(rebate?.saleAmount)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Requested Rebate</TableCell>
                <TableCell>{formatPrice(rebate?.rebateAmount)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Rebate Status</TableCell>
                <TableCell>{rebate.rebateStatus}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Before Rebate
          </Typography>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>Price</TableCell>
                <TableCell>{formatPrice(rebate.OrderDtl_UnitPrice)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Cost</TableCell>
                <TableCell>{formatPrice(rebate.Calculated_bomcost)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>CM</TableCell>
                <TableCell>
                  {formatPrice(
                    +rebate.OrderDtl_UnitPrice - +rebate.Calculated_bomcost
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>GM</TableCell>
                <TableCell>{`${Math.round(
                  100 *
                    ((+rebate.OrderDtl_UnitPrice - +rebate.Calculated_bomcost) /
                      +rebate.OrderDtl_UnitPrice)
                )} %`}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="h6" sx={{ mt: 2 }}>
            After Rebate
          </Typography>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>Price</TableCell>
                <TableCell>
                  {formatPrice(+rebate.OrderDtl_UnitPrice - +rebate.rebateAmount)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Cost</TableCell>
                <TableCell>{formatPrice(rebate.Calculated_bomcost)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>CM</TableCell>
                <TableCell>
                  {formatPrice(
                    +rebate.OrderDtl_UnitPrice -
                      +rebate.rebateAmount -
                      +rebate.Calculated_bomcost
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>GM</TableCell>
                <TableCell>{`${Math.round(
                  100 *
                    ((+rebate.OrderDtl_UnitPrice -
                      +rebate.rebateAmount -
                      +rebate.Calculated_bomcost) /
                      (+rebate.OrderDtl_UnitPrice - +rebate.rebateAmount))
                )} %`}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Dealer Margin with Rebate Approval ((Sale Price for Dealer{' '}
            {formatPrice(rebate.saleAmount)} - Price to Avalon{' '}
            {formatPrice(+rebate.OrderDtl_UnitPrice - +rebate.rebateAmount)}) / Sale
            Price for Dealer {formatPrice(rebate.saleAmount)}
          </Typography>
          <Typography variant="body1" sx={{ mt: 1, fontWeight: 'bold' }}>
            {`${Math.round(
              100 *
                ((+rebate.saleAmount -
                  +rebate.OrderDtl_UnitPrice +
                  +rebate.rebateAmount) /
                  +rebate.saleAmount)
            )} %`}
          </Typography>
          <Grid item container gap={2} sx={{ mt: 4, justifyContent: 'center' }}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: '100%' }}
                size="large"
                onClick={() => handleUpdateRebate('APPROVED')}
                disabled={
                  rebate.rebateStatus === 'PROCESSED' ||
                  rebate.rebateStatus === 'APPROVED'
                }
              >
                Approve
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                sx={{ width: '100%' }}
                size="large"
                onClick={() => handleUpdateRebate('DENIED')}
                disabled={
                  rebate.rebateStatus === 'PROCESSED' ||
                  rebate.rebateStatus === 'DENIED'
                }
                color="error"
              >
                Deny
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Rebate;
