import { useState, useContext } from 'react';
import { UserContext } from '../../../userContext';
import { Box, IconButton, Grid, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AdfScannerOutlinedIcon from '@mui/icons-material/AdfScannerOutlined';
import { useNavigate } from 'react-router-dom';
import { orderStatus, formatPrice } from '../../../lib';
import PDF from '../../../components/Order/Components/PDF';
import html2pdf from 'html2pdf.js';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  paperTitle: {
    fontSize: '2rem',
    fontWeight: '700',
    marginBottom: theme.spacing(1),
  },
  link: {
    fontSize: '14px',
    color: '#006991',
  },
  pdf: {
    display: 'none',
    padding: theme.spacing(0, 4),
    ['@media print']: {
      display: 'block',
      position: 'absolute',
      top: '15px',
      left: '15px',
    },
  },
  orderContainer: {
    margin: theme.spacing(2, 0, 0, 0),
    position: 'relative',
    height: '100%',
  },
  orderOverlay: {
    position: 'absolute',
    top: '5px',
    left: '8px',
    width: '100%',
    height: '95%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 20,
    backgroundColor: '#006991',
    opacity: 0.9,
    '& button': {
      backgroundColor: '#1799cb',
      margin: theme.spacing(0, 0.5),
      '&:hover': {
        backgroundColor: '#0177a5',
      },
      '& svg': {
        fontSize: '16px',
        color: '#fff',
      },
    },
  },
  orderCard: {
    borderRadius: '16px',
  },
  orderId: {
    marginBottom: theme.spacing(1),
    fontSize: '14px',
    color: '#006991',
  },
  orderInfo: {
    maxWidth: '100%',
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: '14px',
    fontWeight: '700',
  },
  orderPrice: {
    fontSize: '2rem',
    fontWeight: '700',
  },
  orderDate: {
    color: '#747679',
  },
  link: {
    fontSize: '14px',
    color: '#006991',
  },
}));

const RecentOrders = (props) => {
  const { recentOrders } = props;
  const classes = useStyles();
  const [currentOrder, setCurrentOrder] = useState(null);
  let navigate = useNavigate();
  const userContext = useContext(UserContext);

  const handlePopoverOpen = (order) => {
    setCurrentOrder(order);
  };

  const handlePopoverClose = () => {
    setCurrentOrder(null);
  };

  const downloadOrder = (order) => {
    console.log('starting download');
    try {
      let t = document.getElementById(`pdf-${order.id}`).innerHTML;
      var opt = {
        margin: [5, 2],
        filename: `Order_${order.id}_${
          order.options.find((o) => o.inputId === 1).optionDropdownName
        }_${order.options
          .find((o) => o.inputId === 2)
          .optionDropdownName.replace("'", '')
          .replace(' ', '_')}_${format(new Date(), 'MM_dd_yyyy')}`,
        html2canvas: {
          scale: 4,
          useCORS: true,
        },
        pagebreak: {
          before: '.pagebreak',
        },
      };
      html2pdf().set(opt).from(t).save();
    } catch (err) {
      console.log(err);
    }
  };

  const optionsByPage = (order) => {
    let tmpOptionsByPage = {};
    for (let o of order.options) {
      // console.log(o.input.page.id)
      let pageId = o.inputId === 164 ? 1 : o.input.page.id;
      if (tmpOptionsByPage[pageId] === undefined) {
        tmpOptionsByPage[pageId] = {
          name: o.inputId === 164 ? 'Initial Boat Build' : o.input.page.name,
          options: [o],
          note: null,
        };
      } else {
        tmpOptionsByPage[pageId].options.push(o);
      }
    }

    for (let note of order.notes) {
      if (note.pageId !== null) {
        if (tmpOptionsByPage[note.pageId] !== undefined) {
          tmpOptionsByPage[note.pageId].note = note.text;
        } else {
          tmpOptionsByPage[note.pageId] = {
            name: getPageName(note.pageId),
            options: [],
            note: note.text,
          };
        }
      }
    }

    return tmpOptionsByPage;
  };

  const benchImages = (order) => {
    return order.options.filter((o) => o.input.overlayImage === 'BENCH');
  };

  const boatImages = (order) => {
    let foundBoatImages = [order.options.find((i) => i.inputId === 1)];
    foundBoatImages = foundBoatImages.concat(
      order.options.filter((o) => o.input.overlayImage === 'BOAT')
    );

    return foundBoatImages;
  };

  const calculatePrice = (option) => {
    let calculatedPrice = option.price;

    return calculatedPrice === 0
      ? ''
      : calculatedPrice.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
  };

  const calculateTotalPrice = (order) => {
    let calculatedPrice = 0;
    for (let option of order.options) {
      let tmpPrice = option.price;
      calculatedPrice += tmpPrice;
    }

    return calculatedPrice.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  const discounts = (order) => {
    let tmpDiscounts = {
      markup: order.location.markup,
      floorplan: order.location.floorplanDiscount,
      motor: order.location.motorDiscount,
      markupOptions: [],
      discountOptions: [],
    };
    for (let i = 0; i < 41; i++) {
      tmpDiscounts.markupOptions.push(i);
    }

    for (let i = 0; i < 101; i++) {
      tmpDiscounts.discountOptions.push(i);
    }

    return tmpDiscounts;
  };

  const viewOrder = (order) => {
    const formattedOrderStatus = orderStatus(
      order.orderStatus,
      order.orderSource
    ).toLowerCase();
    navigate(`locations/all/current/${formattedOrderStatus}/${order.id}`);
  };

  const calculatePriceWithMarkup = (order) => {
    if (userContext.userState.me.customerBuild) {
      if (order.location.useModelMarkup) {
        const modelId = order.options.find((o) => o.inputId === 1).inputOptionId;
        const modelMarkup = order.location.modelMarkups.find(
          (mm) => mm.modelId === modelId
        ).markup;

        if (modelMarkup && modelMarkup !== 0) {
          return formatPrice(
            order.totalPrice / ((100 - parseInt(modelMarkup, 10)) / 100)
          );
        } else {
          return formatPrice(order.totalPrice / 0.64);
        }
      } else {
        return formatPrice(
          order.location.markup !== 0
            ? order.totalPrice / ((100 - parseInt(order.location.markup, 10)) / 100)
            : order.totalPrice / 0.64
        );
      }
    } else {
      return formatPrice(order.totalPrice);
    }
  };

  return (
    <Grid
      container
      item
      xs={12}
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
    >
      {recentOrders.map((order) => {
        return (
          <Grid
            item
            xs={12}
            sm={4}
            className={classes.orderContainer}
            onMouseEnter={() => handlePopoverOpen(order.id)}
            onMouseLeave={handlePopoverClose}
            key={order.id}
          >
            <div id={`pdf-${order.id}`} className={classes.pdf}>
              <PDF
                order={order}
                optionsByPage={optionsByPage(order)}
                classes={classes}
                benchImages={benchImages(order)}
                boatImages={boatImages(order)}
                calculatePrice={calculatePrice}
                calculateTotalPrice={calculateTotalPrice}
                discounts={discounts(order)}
                disableStandardFeatures={false}
                hasNotes={order.notes.length > 0}
                brand={order.dealership.brand}
              />
            </div>
            {currentOrder && currentOrder === order.id && (
              <Box className={classes.orderOverlay}>
                <IconButton onClick={() => downloadOrder(order)}>
                  <AdfScannerOutlinedIcon />
                </IconButton>
                <IconButton onClick={() => viewOrder(order)}>
                  <RemoveRedEyeOutlinedIcon />
                </IconButton>
              </Box>
            )}
            <Card variant="outlined" className={classes.orderCard} key={order.id}>
              <CardContent>
                <Typography className={classes.orderId}>
                  Order #{order.id}
                </Typography>
                <Typography className={classes.orderInfo}>
                  {order.title}
                  <br />
                  {order.focusedInput}
                </Typography>
                <Typography className={classes.orderPrice}>
                  {calculatePriceWithMarkup(order)}
                </Typography>
                <Typography className={classes.orderDate}>
                  {order.formattedDate}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default RecentOrders;
