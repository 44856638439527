import React, { useEffect, useState } from 'react';
import BoatImage from '../BoatImage';
import BenchImage from '../BenchImage';
import clsx from 'clsx';

const PDF = (props) => {
  const {
    order,
    classes,
    boatImages,
    benchImages,
    calculatePrice,
    calculateTotalPrice,
    discounts,
    bulkPrint,
    disableStandardFeatures,
    optionsByPage,
    hasNotes,
    brand,
    hasTwin,
    showMSRP,
    calculateFloorplanDiscount,
    calculateInternetPrice,
  } = props;
  const [options, setOptions] = useState([]);
  const [splitOptions, setSplitOptions] = useState([]);

  useEffect(() => {
    const availableOptions = order.options
      .filter(
        (o) =>
          (o.input.hidePrint || o.price > 0) &&
          o.inputId !== 142 &&
          o.inputId !== 164 &&
          o.lastVersion === order.orderVersion
      )
      .sort((a, b) => a.input.printOrder - b.input.printOrder);

    if (availableOptions.length > 13 && availableOptions.length <= 36) {
      let split = availableOptions.length / 2;
      setOptions(availableOptions.filter((option, index) => index < split));
      setSplitOptions(availableOptions.filter((option, index) => index >= split));
    } else if (availableOptions.length > 36) {
      const optionsLength = Math.ceil(availableOptions.length / 2) + 1;
      setOptions(availableOptions.filter((option, index) => index < optionsLength));
      setSplitOptions(
        availableOptions.filter((option, index) => index >= optionsLength)
      );
    } else {
      setOptions(availableOptions);
      setSplitOptions([]);
    }
  }, [order]);

  return (
    <div className={'printable pdf-page'}>
      {(brand === 1 || brand === undefined) && (
        <img src="/logo.png" alt="logo" style={{ margin: '0px 12px 16px 12px' }} />
      )}
      {brand === 2 && (
        <img
          src="/tahoe_logo_small.png"
          alt="logo"
          style={{ margin: '0px 12px 16px 12px', height: '40px' }}
        />
      )}
      <table style={{ padding: '0 24px' }}>
        <tbody>
          <tr>
            <td style={{ display: 'flex' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '115px',
                  paddingBottom: '20px',
                }}
              >
                <p
                  style={{
                    margin: '0px',
                    fontSize: '1.25rem',
                  }}
                >
                  {order.location.name}
                </p>
                <div
                  style={{
                    fontSize: '2rem',
                    fontWeight: 'bold',
                    lineHeight: '1.8rem',
                    padding: '8px 0px',
                  }}
                >
                  {order.options.find((o) => o.inputId === 1).optionDropdownName}{' '}
                  {order.options.find((o) => o.inputId === 2).optionDropdownName}
                </div>
                {order.orderSource === 1 ? (
                  <h2 style={{ margin: '0px' }}>
                    Finance Option -{' '}
                    {
                      order.options.find((o) => o.inputId === 164)
                        ?.optionDropdownName
                    }
                  </h2>
                ) : null}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  marginLeft: 'auto',
                  padding: '21px 25px',
                  textAlign: 'center',
                  backgroundColor: '#F8F8F8',
                  borderRadius: '4px',
                }}
              >
                <p
                  style={{ marginTop: '0px', marginBottom: '4px', fontSize: '1rem' }}
                >
                  {order.orderSource === 1
                    ? `Order #${order.id}`
                    : `Lead #${order.leadId}`}
                </p>
                <h2 style={{ margin: '0px' }}>
                  {calculateTotalPrice(order)}
                  {calculateInternetPrice && showMSRP && ' TOTAL MSRP'}
                </h2>
                {calculateInternetPrice && showMSRP && (
                  <h4 style={{ margin: '0px', marginBottom: '8px' }}>
                    {calculateInternetPrice()} AVERAGE RETAIL PRICE
                  </h4>
                )}
                <h3
                  style={{ margin: '0px', fontSize: '1.2rem', lineHeight: '1.2rem' }}
                >
                  {order.dealership.name}
                </h3>
              </div>
            </td>
          </tr>
          {bulkPrint === undefined ? (
            <tr>
              <td>
                <table
                  style={{
                    marginLeft: '-16px',
                    width: 'calc(100% + 32px)',
                    borderSpacing: '16px',
                  }}
                >
                  <tbody style={{ width: '100%' }}>
                    <tr>
                      <td
                        style={{
                          width: '33%',
                          textAlign: 'center',
                          backgroundColor: '#F8F8F8',
                          borderRadius: '16px',
                        }}
                      >
                        <div>
                          {order.options.find((o) => o.inputId === 2)
                            .optionImgPath !== null && (
                            <img
                              src={`https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/images/${
                                order.options.find((o) => o.inputId === 2)
                                  .optionImgPath
                              }?${new Date()}`}
                              style={{ maxWidth: '250px' }}
                              crossOrigin="anonymous"
                            />
                          )}
                        </div>
                      </td>
                      <td
                        style={{
                          width: '33%',
                          backgroundColor: '#F8F8F8',
                          borderRadius: '16px',
                        }}
                      >
                        <div className={classes.heroImageParentAlt}>
                          <BoatImage boatImages={boatImages} />
                        </div>
                      </td>
                      <td
                        style={{
                          width: '33%',
                          backgroundColor: '#F8F8F8',
                          borderRadius: '16px',
                        }}
                      >
                        <div className={classes.heroImageParentAlt}>
                          <BenchImage benchImages={benchImages} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          ) : null}
          <tr>
            <td>
              <div style={{ display: 'flex' }}>
                <img
                  src="/speaker.png"
                  alt="icon"
                  style={{ width: '20px', height: '18px' }}
                />
                <p
                  style={{
                    fontSize: '10px',
                    marginTop: '0px',
                    marginLeft: '10px',
                    color: '#747679',
                  }}
                >
                  The image of the boat shown is displayed to provide you with an
                  idea of the look of your boat. Precise depictions of colors,
                  dimensions, textures, or features may not be visible, or fully
                  accurate in this image. Please see your dealer to view the boat in
                  person, or to see samples of materials, to be sure you are
                  satisfied with its appearance before you purchase your boat.
                </p>
              </div>
              <div
                style={{
                  marginTop: '7px',
                  marginBottom: '3px',
                  height: '1px',
                  backgroundColor: '#F4F4F4',
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{ width: '100%', padding: '0px 24px', display: 'block' }}>
        <tbody>
          <tr>
            <td style={{ verticalAlign: 'top' }}>
              <table
                className={
                  splitOptions.length > 0
                    ? `${classes.optionsTable} parallel`
                    : classes.optionsTable
                }
              >
                <thead>
                  <tr>
                    <th align="left" style={{ width: '100vw' }}>
                      Options
                    </th>
                    <th align="right">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {options.map((o, index) => {
                    return (
                      <tr style={{ fontSize: '16px' }} key={`po_${o.id}`}>
                        <td>
                          {o.optionName}
                          {o.optionDropdownName !== null
                            ? ` - ${o.optionDropdownName}`
                            : ''}
                          {o.inputId === 2 &&
                            !showMSRP &&
                            !bulkPrint &&
                            discounts?.floorplan !== 0 && (
                              <span>
                                Floorplan Discount - {discounts?.floorplan}%
                                {discounts?.floorplan2 !== 0 &&
                                  ` + ${discounts?.floorplan2}%`}
                              </span>
                            )}
                          {o.inputId === 6 &&
                            !showMSRP &&
                            !bulkPrint &&
                            discounts?.motor !== 0 && (
                              <span variant="body2">
                                Motor Discount - {discounts?.motor}%
                              </span>
                            )}
                        </td>
                        <td
                          style={{
                            paddingLeft: '6px',
                            textAlign: 'right',
                          }}
                        >
                          {calculatePrice(o) || `$0`}
                          {o.inputId === 2 &&
                            !showMSRP &&
                            discounts?.floorplan !== 0 &&
                            calculateFloorplanDiscount && (
                              <p>{calculateFloorplanDiscount(o.price)}</p>
                            )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </td>
            {splitOptions.length > 0 ? (
              <td style={{ verticalAlign: 'top', width: '50%' }}>
                <table className={`${classes.optionsTable} parallel`}>
                  <thead>
                    <tr>
                      <th align="left" style={{ width: '100vw' }}>
                        Options
                      </th>
                      <th align="right">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {splitOptions.map((o, index) => {
                      return (
                        <tr style={{ fontSize: '16px' }} key={`po_${o.id}`}>
                          <td>
                            {o.optionName}
                            {o.optionDropdownName !== null
                              ? ` - ${o.optionDropdownName}`
                              : ''}
                            {o.inputId === 2 &&
                              !showMSRP &&
                              !bulkPrint &&
                              discounts?.floorplan !== 0 && (
                                <span>
                                  Floorplan Discount - {discounts?.floorplan}%
                                  {discounts?.floorplan2 !== 0 &&
                                    ` + ${discounts?.floorplan2}%`}
                                </span>
                              )}
                            {o.inputId === 6 &&
                              !showMSRP &&
                              !bulkPrint &&
                              discounts?.motor !== 0 && (
                                <span variant="body2">
                                  Motor Discount - {discounts?.motor}%
                                </span>
                              )}
                          </td>
                          <td
                            style={{
                              paddingLeft: '6px',
                              textAlign: 'right',
                            }}
                          >
                            {calculatePrice(o) || `$0`}
                            {o.inputId === 2 &&
                              !showMSRP &&
                              discounts?.floorplan !== 0 && (
                                <p>{calculateFloorplanDiscount(o.price)}</p>
                              )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </td>
            ) : null}
          </tr>
        </tbody>
      </table>
      <div
        style={{
          display: 'flex',
          padding: '10px 24px',
          margin: '8px 24px 0px 24px',
          backgroundColor: '#171A20',
          color: '#fff',
          borderRadius: '8px',
        }}
      >
        <h3
          style={{
            marginTop: '0px',
            marginBottom: '0px',
          }}
        >
          TOTAL
        </h3>
        <h3
          style={{
            marginLeft: 'auto',
            marginTop: '0px',
            marginBottom: '0px',
            textAlign: 'right',
          }}
        >
          {calculateTotalPrice(order, false)}
        </h3>
      </div>
      {bulkPrint === undefined ? (
        <div
          className={clsx('pagebreak', {
            [classes.disableStandardFeatures]: disableStandardFeatures,
          })}
        >
          <h3>STANDARD FEATURES</h3>
          <div dangerouslySetInnerHTML={{ __html: order.description }}></div>
        </div>
      ) : null}
    </div>
  );
};

export default PDF;
