import React, { useState } from 'react';
import {
  Grid,
  TextField,
  FormControl,
  Button,
  Box,
  FormControlLabel,
  Switch as Toggle,
  Select,
  InputLabel,
  Chip,
  MenuItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Loading from '../Loading';

const validationSchema = yup.object({
  avalonName: yup.string('Enter a name').required('Name is required'),
  tahoeName: yup.string('need name').nullable(true),
  griName: yup.string('need name').nullable(true),
  legendName: yup.string('need name').nullable(true),
  price: yup.number('enter int'),
  optionOrder: yup.number('enter int'),
  imgPath: yup.string('img path'),
  optionOrder: yup.number('option order'),
  thumbnailImgPath: yup.string('img thumb path'),
  tags: yup.array(),
  active: yup.boolean(),
  intialTags: yup.array(),
  iseDeleted: yup.boolean(),
  description: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(4),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    chip: {
      margin: 2,
    },
  },
}));

const EditInputOption = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const { input, setInput, closeEdit, inputOption } = props;
  const [submitting, setSubmitting] = useState(false);
  const { tags } = props;

  const formik = useFormik({
    initialValues: {
      id: inputOption.id,
      avalonName: inputOption.avalonName,
      tahoeName: inputOption.tahoeName,
      griName: inputOption.griName,
      legendName: inputOption.legendName,
      price: inputOption.price,
      optionOrder: inputOption.optionOrder,
      imgPath: inputOption.imgPath !== null ? inputOption.imgPath : '',
      optionOrder: inputOption.optionOrder,
      thumbnailImgPath:
        inputOption.thumbnailImgPath !== null ? inputOption.thumbnailImgPath : '',
      tags: inputOption.tags.map((t) => {
        return t.id;
      }),
      active: inputOption.active,
      initialTags: inputOption.tags.map((t) => {
        return t.id;
      }),
      isDeleted: inputOption.isDeleted,
      description: inputOption.description !== null ? inputOption.description : '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setSubmitting(true);
      try {
        const { data } = await axios.put('/api/input/option', values);

        setInput({
          ...input,
          inputOptions: input.inputOptions.map((io) => {
            if (io.id === inputOption.id) {
              return { ...data };
            } else {
              return io;
            }
          }),
        });
        closeEdit();
        setSubmitting(false);
      } catch (err) {
        setSubmitError(err.response.data.message);
      }
    },
  });

  return (
    <>
      {submitting ? (
        <Loading text="Updating..." />
      ) : (
        <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          {submitError !== null ? (
            <Box color="error.main">
              <h3 style={{ marginTop: '0px' }}>{submitError}</h3>
            </Box>
          ) : null}
          <Grid container alignContent="space-between" spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={input.branded ? 6 : 12}>
              <FormControl fullWidth>
                <TextField
                  id="avalonName"
                  name="avalonName"
                  label={input.branded ? 'Avalon Name' : 'Name'}
                  value={formik.values.avalonName}
                  onChange={formik.handleChange}
                  className={classes.formField}
                  error={
                    formik.touched.avalonName && Boolean(formik.errors.avalonName)
                  }
                  helperText={formik.touched.avalonName && formik.errors.avalonName}
                  fullWidth
                />
              </FormControl>
            </Grid>
            {input.branded ? (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    id="tahoeName"
                    name="tahoeName"
                    label="Tahoe Name"
                    value={formik.values.tahoeName}
                    onChange={formik.handleChange}
                    className={classes.formField}
                    error={
                      formik.touched.tahoeName && Boolean(formik.errors.tahoeName)
                    }
                    helperText={formik.touched.tahoeName && formik.errors.tahoeName}
                    fullWidth
                  />
                </FormControl>
              </Grid>
            ) : null}
            {input.branded ? (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    id="griName"
                    name="griName"
                    label="GRI Name"
                    value={formik.values.griName}
                    onChange={formik.handleChange}
                    className={classes.formField}
                    error={formik.touched.griName && Boolean(formik.errors.griName)}
                    helperText={formik.touched.griName && formik.errors.griName}
                    fullWidth
                  />
                </FormControl>
              </Grid>
            ) : null}
            {input.branded ? (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    id="legendName"
                    name="legendName"
                    label="Legend Name"
                    value={formik.values.legendName}
                    onChange={formik.handleChange}
                    className={classes.formField}
                    error={
                      formik.touched.legendName && Boolean(formik.errors.legendName)
                    }
                    helperText={
                      formik.touched.legendName && formik.errors.legendName
                    }
                    fullWidth
                  />
                </FormControl>
              </Grid>
            ) : null}
          </Grid>
          <FormControl fullWidth>
            <TextField
              id="price"
              name="price"
              label="Price"
              value={formik.values.price}
              onChange={formik.handleChange}
              className={classes.formField}
              error={formik.touched.price && Boolean(formik.errors.price)}
              helperText={formik.touched.price && formik.errors.price}
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              id="optionOrder"
              name="optionOrder"
              label="Option Order"
              value={formik.values.optionOrder}
              onChange={formik.handleChange}
              className={classes.formField}
              error={
                formik.touched.optionOrder && Boolean(formik.errors.optionOrder)
              }
              helperText={formik.touched.optionOrder && formik.errors.optionOrder}
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              id="imgPath"
              name="imgPath"
              label="Image Path"
              value={formik.values.imgPath}
              onChange={formik.handleChange}
              className={classes.formField}
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              id="thumbnailImgPath"
              name="thumbnailImgPath"
              label="Thumbnail Image Path"
              value={formik.values.thumbnailImgPath}
              onChange={formik.handleChange}
              className={classes.formField}
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="inputTagsLabel">Tags</InputLabel>
            <Select
              id="tags"
              name="tags"
              labelId="inputTagsLabel"
              multiple
              value={formik.values.tags}
              onChange={formik.handleChange}
              className={classes.formField}
              fullWidth
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => {
                    // convert from id to tag
                    let t = tags.find((tag) => tag.id === value);
                    return (
                      <Chip key={t.id} label={t.name} className={classes.chip} />
                    );
                  })}
                </div>
              )}
            >
              {tags.map((tag) => {
                return (
                  <MenuItem key={tag.id} value={tag.id}>
                    {tag.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              id="description"
              name="description"
              label="Description"
              value={formik.values.description}
              onChange={formik.handleChange}
              className={classes.formField}
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Toggle
                  checked={formik.values.active}
                  onChange={formik.handleChange}
                  id="active"
                  name="active"
                />
              }
              label="Active"
            />
          </FormControl>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Toggle
                  checked={formik.values.isDeleted}
                  onChange={formik.handleChange}
                  id="isDeleted"
                  name="isDeleted"
                />
              }
              label="Deleted"
            />
          </FormControl>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            className={classes.submitButton}
          >
            Update
          </Button>
        </form>
      )}
    </>
  );
};

export default EditInputOption;
