import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

const DisableDealershipModal = ({
  show,
  handleClose,
  dealership,
  handleConfirmDisable,
}) => {
  return (
    <Dialog open={show} onClose={handleClose} aria-labelledby="edit-user">
      <DialogTitle id="form-delete-user">
        {dealership.locations.filter((location) => location.active).length === 0
          ? 'Enable'
          : 'Disable'}{' '}
        Dealership
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Are you sure you want to{' '}
          {dealership.locations.filter((location) => location.active).length === 0
            ? 'enable'
            : 'disable'}{' '}
          <strong>{dealership.name}</strong>?
        </Typography>
        <Button
          color={
            dealership.locations.filter((location) => location.active).length === 0
              ? 'primary'
              : 'error'
          }
          variant="contained"
          sx={{ display: 'block' }}
          onClick={handleConfirmDisable}
        >
          {dealership.locations.filter((location) => location.active).length === 0
            ? 'Enable'
            : 'Disable'}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisableDealershipModal;
