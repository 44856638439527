import EditLocationMarkupAndDiscount from '../../forms/EditLocationMarkupAndDiscount';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

const EditLocationMarkupAndDiscountModal = ({
  show,
  handleClose,
  location,
  dealership,
  setDealership,
}) => {
  return (
    <Dialog
      open={show}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      aria-labelledby="edit-location-markup-discount"
    >
      <DialogTitle>Edit Location</DialogTitle>
      <DialogContent>
        <EditLocationMarkupAndDiscount
          dealership={dealership}
          setDealership={setDealership}
          location={location}
          closeAdd={handleClose}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditLocationMarkupAndDiscountModal;
