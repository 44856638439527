import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loading from '../../../components/Loading';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Reporting = () => {
  const [loading, setLoading] = useState(true);
  const [mondays, setMondays] = useState([]);
  const [selectedMonday, setSelectedMonday] = useState('');
  const [filterType, setFilterType] = useState('dealer');

  useEffect(() => {
    const loadOrders = async () => {
      try {
        const { data } = await axios.get('/api/epicor/upcomingOrders');

        console.log(data);
        setMondays(data);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
      }
    };

    loadOrders();
  }, []);

  const handleSendTestEmail = async (emailData, type) => {
    // console.log(emailData);
    try {
      const { data } = await axios.post('/api/epicor/emailTest', {
        emailData,
        type,
      });
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item sm={12} md={8}>
        {loading ? (
          <Loading />
        ) : (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h3" component="h3">
              Upcoming Orders
            </Typography>
            <Box>
              <FormControl sx={{ my: 2, minWidth: '200px' }}>
                <InputLabel id="mondaysLabel">Select Monday</InputLabel>
                <Select
                  id="mondays"
                  name="mondays"
                  labelId="mondaysLabel"
                  value={selectedMonday}
                  onChange={(e) => setSelectedMonday(e.target.value)}
                  fullWidth
                >
                  {mondays.map((monday, index) => (
                    <MenuItem key={index} value={index}>
                      {monday.monday.split('T')[0]} - {monday.numOrders} Orders
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {selectedMonday !== '' ? (
                <FormControl sx={{ my: 2, ml: 2, minWidth: '200px' }}>
                  <InputLabel id="filterTypeLabel">Select Filter</InputLabel>
                  <Select
                    id="filterType"
                    name="filterType"
                    labelId="filterTypeLabel"
                    value={filterType}
                    onChange={(e) => setFilterType(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value="dealer">Dealer</MenuItem>
                    <MenuItem value="rep">Rep</MenuItem>
                  </Select>
                </FormControl>
              ) : null}
            </Box>
            <Box>
              {selectedMonday !== '' && filterType === 'dealer'
                ? mondays[selectedMonday].locations.map((location) => (
                    <Accordion key={location.location.id}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${location.location.id}-content`}
                        id={`panel${location.location.id}-header`}
                      >
                        <Typography>
                          {location.location.name} - {location.orders.length} Orders
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ mb: 2 }}
                          onClick={() => handleSendTestEmail(location, 'location')}
                        >
                          Send Test Email
                        </Button>
                        <Typography variant="h6">Notification List</Typography>
                        <Typography sx={{ mb: 2 }}>
                          {location.location.notificationList.split(',').join(', ')}
                        </Typography>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">ATOM ID</TableCell>
                              <TableCell align="left">EPICOR ID</TableCell>
                              <TableCell align="left">SHIP BY DATE</TableCell>
                              <TableCell align="left">MODEL</TableCell>
                              <TableCell align="left">FLOORPLAN</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {location.orders.map((order) => (
                              <TableRow
                                key={order.id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>
                                  <Link
                                    to={`/dealerships/${location.dealership.id}/locations/${location.location.id}/current/submitted/${order.id}`}
                                    target="_blank"
                                  >
                                    {order.id}
                                  </Link>
                                </TableCell>
                                <TableCell>{order.epicorOrderNumber}</TableCell>
                                <TableCell>{order.shipByDate}</TableCell>
                                <TableCell>{order.model}</TableCell>
                                <TableCell>{order.floorplan}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  ))
                : null}
              {selectedMonday !== '' && filterType === 'rep'
                ? mondays[selectedMonday].repOrders.map((rep) => (
                    <Accordion key={rep.id}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${rep.id}-content`}
                        id={`panel${rep.id}-header`}
                      >
                        <Typography>
                          {rep.name} - {rep.orders.length} Orders
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ mb: 2 }}
                          onClick={() => handleSendTestEmail(rep, 'rep')}
                        >
                          Send Test Email
                        </Button>
                        <Typography variant="h6">Email</Typography>
                        <Typography sx={{ mb: 2 }}>{rep.email}</Typography>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">DEALER</TableCell>

                              <TableCell align="left">ATOM ID</TableCell>
                              <TableCell align="left">EPICOR ID</TableCell>
                              <TableCell align="left">SHIP BY DATE</TableCell>
                              <TableCell align="left">MODEL</TableCell>
                              <TableCell align="left">FLOORPLAN</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rep.orders.map((order, index) => (
                              <TableRow
                                key={`${rep.id}-${order.id}`}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>{order.location.name}</TableCell>
                                <TableCell>
                                  <Link
                                    to={`/dealerships/${order.dealershipId}/locations/${order.locationId}/current/submitted/${order.id}`}
                                    target="_blank"
                                  >
                                    {order.id}
                                  </Link>
                                </TableCell>
                                <TableCell>{order.epicorOrderNumber}</TableCell>
                                <TableCell>{order.shipByDate}</TableCell>
                                <TableCell>{order.model}</TableCell>
                                <TableCell>{order.floorplan}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  ))
                : null}
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default Reporting;
