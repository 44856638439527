import { useState, useEffect, useContext } from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@mui/material';
import axios from 'axios';
import Loading from '../../../components/Loading';
import EnhancedTable from '../../../components/EnhancedTable';
import AddUser from '../../../components/forms/AddUser';
import EditUser from '../../../components/forms/EditUser';
import ManageUser from './Components/ManageUser';
import { initPermissions } from '../../../lib';
import { UserContext } from '../../../userContext';

const ATDealerUsers = () => {
  const userContext = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [dealerships, setDealerships] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [updatedPermissions, setUpdatedPermissions] = useState([]);
  const [userType, setUserType] = useState('reps');
  const [userSelectedDealerships, setUserSelectedDealerships] = useState([]);

  const handleOpenAddUser = () => {
    setOpenAddUser(true);
  };

  const handleCloseAddUser = () => {
    setOpenAddUser(false);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await axios.get('/api/users/reps', {
          params: { userType: userType },
        });

        const formattedUsers = data.users.map((u) => {
          return userType === 'reps'
            ? {
                ...u,
                numDealers: u.dealerships.length,
              }
            : { ...u, dealership: u.dealership?.name };
        });
        setUsers(formattedUsers);
      } catch (err) {
        console.log(err.message);
      }

      if (dealerships.length === 0) {
        try {
          const { data } = await axios.get('/api/dealers/all');

          setDealerships(data);
          setLoading(false);
        } catch (err) {
          console.log(err.message);
        }
      } else {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [userType]);

  const formatInitSelected = (arr) => {
    let formattedSelected = [];
    arr.map((item) => {
      return formattedSelected.push(item.dealershipId);
    });

    return formattedSelected;
  };

  const updateBulk = async (arr) => {
    console.log('BULK UPDATE: ', userSelectedDealerships, arr);

    let create = [];
    arr.map((id) => {
      return userSelectedDealerships.indexOf(id) === -1
        ? create.push({ userId: selectedUser.id, dealershipId: id })
        : null;
    });

    let remove = [];
    userSelectedDealerships.map((id) => {
      return arr.indexOf(id) === -1 ? remove.push(id) : null;
    });

    remove = remove.map((id) => {
      for (let dr of selectedUser.dealerships) {
        if (dr.dealershipId === id) return dr.id;
      }
    });

    let values = { userId: selectedUser.id, create, remove };

    try {
      const { data } = await axios.post('/api/users/reps', values);
      setSelectedUser({ ...selectedUser, dealerships: data.dealerships });
      let newUsers = users.map((u) => {
        return u.id === selectedUser.id
          ? {
              ...selectedUser,
              dealerships: data.dealerships,
              numDealers: data.dealerships.length,
            }
          : u;
      });
      console.log(newUsers.find((u) => u.id === selectedUser.id));
      setUsers(newUsers);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleOpenEditUser = () => {
    setOpenEditUser(true);
  };

  const handleCloseEditUser = () => {
    setOpenEditUser(false);
  };

  const handleSelectUser = async (user, editingUser = false) => {
    if (selectedUser?.id === user.id && !editingUser) {
      setSelectedUser(null);
      setUpdatedPermissions([]);
    } else {
      setLoadingUser(true);
      setUpdatedPermissions([]);
      try {
        const { data } = await axios.get('/api/acl/user/full', {
          params: { userId: user.id, role: user.role },
        });
        user.userPermissions = data.userPermissions;
        user.rolePermissions = data.rolePermissions;
        // console.log(user.userPermissions, user.rolePermissions);
        let tmpPermissions = initPermissions.map((p) => {
          return {
            id: null,
            permission: p.permission,
            isAllowed: null,
            roleDefault: false,
            touched: false,
          };
        });
        for (const rp of user.rolePermissions) {
          let foundPermission = tmpPermissions.find((p) => p.permission === rp);
          if (foundPermission) foundPermission.roleDefault = true;
        }
        for (const up of user.userPermissions) {
          let foundPermission = tmpPermissions.find(
            (p) => p.permission === up.permission
          );
          if (foundPermission) {
            foundPermission.id = up.id;
            foundPermission.isAllowed = up.isAllowed;
          }
        }
        user.initPermissions = tmpPermissions;
        setSelectedUser(user);
        setUserSelectedDealerships(formatInitSelected(user.dealerships));
        setUpdatedPermissions(tmpPermissions);
        setLoadingUser(false);
      } catch (err) {
        console.log(err.message);
        setLoadingUser(false);
      }
    }
  };

  const saveUserPermissions = async (permissions) => {
    const createPermissions = permissions
      .filter((p) => p.id === null && p.isAllowed !== null)
      .map((p) => {
        return {
          userId: selectedUser.id,
          permission: p.permission,
          isAllowed: p.isAllowed,
        };
      });
    const updatePermissions = permissions
      .filter((p) => p.id !== null && p.isAllowed !== null && p.touched)
      .map((p) => {
        return { id: p.id, isAllowed: p.isAllowed };
      });
    const deletePermissions = permissions
      .filter((p) => p.id !== null && p.isAllowed === null)
      .map((p) => p.id);

    console.log(createPermissions, updatePermissions, deletePermissions);
    try {
      const { data } = await axios.post('/api/acl/users', {
        createPermissions,
        updatePermissions,
        deletePermissions,
      });
      console.log(data);
      setLoadingUser(true);
      let user = { ...selectedUser };
      try {
        const { data } = await axios.get('/api/acl/user/full', {
          params: { userId: selectedUser.id, role: user.role },
        });

        user.userPermissions = data.userPermissions;
        user.rolePermissions = data.rolePermissions;
        // console.log(user.userPermissions, user.rolePermissions);
        let tmpPermissions = [...initPermissions];
        for (const rp of user.rolePermissions) {
          let foundPermission = tmpPermissions.find((p) => p.permission === rp);
          console.log(foundPermission);
          if (foundPermission) foundPermission.roleDefault = true;
        }
        for (const up of user.userPermissions) {
          let foundPermission = tmpPermissions.find(
            (p) => p.permission === up.permission
          );
          if (foundPermission) {
            foundPermission.id = up.id;
            foundPermission.isAllowed = up.isAllowed;
          }
        }
        user.initPermissions = tmpPermissions;
        setSelectedUser(user);
        setUpdatedPermissions(tmpPermissions);
        setLoadingUser(false);
      } catch (err) {
        console.log(err.message);
        setLoadingUser(false);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <Grid container item spacing={2}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth sx={{ mb: 4 }}>
              <InputLabel id="user-type-select-label">User Type</InputLabel>
              <Select
                labelId="user-type-select-label"
                id="user-type-select"
                value={userType}
                label="User Type"
                onChange={(e) => setUserType(e.target.value)}
              >
                <MenuItem value={'reps'}>Reps</MenuItem>
                <MenuItem value={'dealers'}>Dealers</MenuItem>
              </Select>
            </FormControl>
            <EnhancedTable
              title="Users"
              data={users}
              addData={userType === 'reps' ? handleOpenAddUser : null}
              hiddenColumns={
                userType === 'reps'
                  ? [
                      'id',
                      'dealershipId',
                      'atdealerId',
                      'tableSettings',
                      'dealerships',
                      'active',
                      'email',
                      'userPermissions',
                      'rolePermissions',
                      'initPermissions',
                      'dealership',
                    ]
                  : [
                      'id',
                      'dealershipId',
                      'atdealerId',
                      'tableSettings',
                      'dealerships',
                      'active',
                      'email',
                      'userPermissions',
                      'rolePermissions',
                      'initPermissions',
                    ]
              }
              setSelectedData={handleSelectUser}
              openEditData={handleOpenEditUser}
              setEditData={handleSelectUser}
              disableSelectAll
              initSelected={selectedUser !== null ? [selectedUser.id] : []}
              dense
            />

            <Dialog
              open={openAddUser}
              onClose={handleCloseAddUser}
              maxWidth="sm"
              fullWidth
              aria-labelledby="add-user-form"
            >
              <DialogTitle id="form-add-user">New User</DialogTitle>
              <DialogContent>
                <AddUser
                  closeAdd={handleCloseAddUser}
                  users={users}
                  setUsers={setUsers}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAddUser} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            {selectedUser !== null ? (
              <Dialog
                open={openEditUser}
                onClose={handleCloseEditUser}
                maxWidth="sm"
                fullWidth
                aria-labelledby="edit-user-form"
              >
                <DialogTitle id="form-edit-user">Edit User</DialogTitle>
                <DialogContent>
                  <EditUser
                    closeEdit={handleCloseEditUser}
                    users={users}
                    setUsers={setUsers}
                    user={selectedUser}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseEditUser} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            ) : null}
          </Grid>
          {selectedUser !== null && !loadingUser ? (
            <ManageUser
              users={users}
              setUsers={setUsers}
              user={selectedUser}
              setSelectedUser={handleSelectUser}
              loadingUser={loadingUser}
              dealerships={dealerships}
              updateBulk={updateBulk}
              saveUserPermissions={saveUserPermissions}
              updatedPermissions={updatedPermissions}
              setUpdatedPermissions={setUpdatedPermissions}
              userType={userType}
              role={userContext.userState.me.role}
              userSelectedDealerships={userSelectedDealerships}
              setUserSelectedDealerships={setUserSelectedDealerships}
            />
          ) : null}
        </>
      )}
    </Grid>
  );
};

export default ATDealerUsers;
