import React, { useState, useEffect } from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import EnhancedTable from '../../../components/EnhancedTable';
import axios from 'axios';
import Loading from '../../../components/Loading';
import AddNotification from '../../../components/forms/AddNotification';
import EditNotification from '../../../components/forms/EditNotification';
import NotificationsNav from './Components/Nav';
import { useParams } from 'react-router-dom';

const Notifications = () => {
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [openAddNotification, setOpenAddNotification] = useState(false);
  const [openEditNotification, setOpenEditNotification] = useState(false);
  const [editNotification, setEditNotification] = useState(null);
  const { type } = useParams();

  useEffect(() => {
    const loadNotifications = async () => {
      try {
        const { data } = await axios.get('/api/notifications/all', {
          params: { type: type },
        });

        console.log(data);
        setNotifications(data);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
      }
    };

    setNotifications([]);
    setLoading(true);
    loadNotifications();
  }, [type]);

  const handleOpenAddNotification = () => {
    setOpenAddNotification(true);
  };

  const handleCloseAddNotification = () => {
    setOpenAddNotification(false);
  };

  const handleOpenEditNotification = () => {
    setOpenEditNotification(true);
  };

  const handleCloseEditNotification = () => {
    setOpenEditNotification(false);
  };

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} sm={10}>
        <NotificationsNav />
      </Grid>
      <Grid item xs={12} sm={10}>
        {loading ? (
          <Loading />
        ) : (
          <EnhancedTable
            title={type === 'faqs' ? 'FAQs' : 'Notifications'}
            data={notifications}
            addData={handleOpenAddNotification}
            openEditData={handleOpenEditNotification}
            setEditData={setEditNotification}
            hiddenColumns={
              type === 'faqs' ? ['text', 'isFaq', 'sent'] : ['text', 'isFaq']
            }
            orderBy={'id'}
            order={'desc'}
            dense
          />
        )}
        <Dialog
          open={openAddNotification}
          onClose={handleCloseAddNotification}
          maxWidth="sm"
          fullWidth
          aria-labelledby="add-notification-form"
        >
          <DialogTitle id="form-add-notification">
            New {type === 'faqs' ? 'FAQ' : 'Notification'}
          </DialogTitle>
          <DialogContent>
            <AddNotification
              closeAdd={handleCloseAddNotification}
              notifications={notifications}
              setNotifications={setNotifications}
              type={type}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddNotification} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {editNotification !== null ? (
          <Dialog
            open={openEditNotification}
            onClose={handleCloseEditNotification}
            maxWidth="sm"
            fullWidth
            aria-labelledby="edit-notification-form"
          >
            <DialogTitle id="form-edit-notification">
              Edit {type === 'faqs' ? 'FAQ' : 'Notification'}
            </DialogTitle>
            <DialogContent>
              <EditNotification
                closeAdd={handleCloseEditNotification}
                notification={editNotification}
                notifications={notifications}
                setNotifications={setNotifications}
                type={type}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEditNotification} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default Notifications;
