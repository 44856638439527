import { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import DownloadMotorReport from './Components/DownloadMotorReport';
import OrderCounts from './Components/OrderCounts';
import RecentOrders from './Components/RecentOrders';
import { getFormattedDate } from '../../lib';

import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Paper,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';

const useStyles = makeStyles((theme) => ({
  notificationsPanelTitle: {
    fontSize: '2rem',
    fontWeight: '700',
    marginBottom: theme.spacing(2),
  },
}));

const colors = ['#FFC107', '#FF9800', '#4CAF50', '#F44336'];

const PrivateHome = ({ role }) => {
  const classes = useStyles();
  const [notifications, setNotifications] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [ordersData, setOrdersData] = useState();
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const [recentOrders, setRecentOrders] = useState([]);
  const [loadingCommunicationList, setLoadingCommunicationList] = useState(false);

  useEffect(() => {
    const loadNotifications = async () => {
      try {
        const { data } = await axios.get('/api/notifications');
        let notifications = data.notifications
          .filter((n) => n.isFaq === false)
          .map((n) => {
            return { ...n, expanded: false };
          });

        let faqs = data.notifications
          .filter((n) => n.isFaq)
          .map((n) => {
            return { ...n, expanded: false };
          });
        setNotifications(notifications);
        setFaqs(faqs);
      } catch (err) {
        console.log(err.response.data.message);
      }
    };

    const getOrdersCount = async () => {
      try {
        const { data } = await axios.get('/api/orders/count');
        const { numConfirmed, numLeads, numQuote, numSubmitted, numComplete } =
          data.orderCounts;

        setOrdersData({
          maintainAspectRatio: false,
          responsive: false,
          labels: ['Leads', 'Quote', 'Submitted', 'Confirmed'],
          datasets: [
            {
              data: [numLeads, numQuote, numSubmitted, numConfirmed],
              backgroundColor: colors,
              hoverBackgroundColor: colors,
            },
          ],
        });
        setTotalOrdersCount(numConfirmed + numLeads + numQuote + numSubmitted);
        setRecentOrders(data.recentOrders);
      } catch (err) {
        console.log(err);
      }
    };

    loadNotifications();
    if (role >= 10) {
      getOrdersCount();
    }
  }, []);

  const downloadCommunicationList = async () => {
    try {
      setLoadingCommunicationList(true);
      axios({
        url: '/api/rep/communication/list',
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        let formattedDate = getFormattedDate(new Date());
        link.href = url;
        link.setAttribute(
          'download',
          `dealership_communication_list_${formattedDate}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      });
      setLoadingCommunicationList(false);
    } catch (err) {
      setLoadingCommunicationList(false);
      console.log(err);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        {role >= 10 && (
          <Paper sx={{ p: 4, mb: 4 }}>
            <Grid item container>
              <OrderCounts
                ordersData={ordersData}
                totalOrdersCount={totalOrdersCount}
                colors={colors}
              />
              <Grid item xs={12}>
                <Typography variant="h4" sx={{ mt: 4 }}>
                  Recent Orders
                </Typography>
              </Grid>
              <RecentOrders recentOrders={recentOrders} />
            </Grid>
          </Paper>
        )}
        <Grid item xs={12}>
          {role <= 2 && (
            <Box sx={{ textAlign: 'center', mt: 4 }}>
              <DownloadMotorReport />
            </Box>
          )}
          {role <= 2 ? (
            <Grid item xs={12} sx={{ textAlign: 'center', my: 2 }}>
              <LoadingButton
                loading={loadingCommunicationList}
                variant="contained"
                color="primary"
                size="large"
                onClick={() => downloadCommunicationList()}
              >
                Download Communication List
              </LoadingButton>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="h4" className={classes.notificationsPanelTitle}>
          Notifications
        </Typography>
        {notifications.slice(0, 5).map((n) => {
          return (
            <Accordion key={n.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${n.id}-content`}
                id={`panel-${n.id}-header`}
              >
                <Typography variant="h6">{n.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {n.filename !== null ? (
                  <Typography variant="h6" paragraph>
                    <a
                      href={`https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/files/${n.filename
                        .split(' ')
                        .join('+')}`}
                      target="_blank"
                      download
                    >
                      {n.filename}
                    </a>
                  </Typography>
                ) : null}
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: n.text }}
                  sx={{ overflowWrap: 'break-word' }}
                ></Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}

        <Typography
          variant="h4"
          className={classes.notificationsPanelTitle}
          sx={{ my: 2 }}
        >
          FAQs
        </Typography>
        {faqs.map((n) => {
          return (
            <Accordion key={n.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${n.id}-content`}
                id={`panel-${n.id}-header`}
              >
                <Typography variant="h6">{n.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: n.text }}
                  sx={{ overflowWrap: 'break-word' }}
                ></Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default PrivateHome;
