import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../userContext';
import {
  Paper,
  Grid,
  Button,
  Typography,
  Hidden,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Loading from '../../components/Loading';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
}));

const DuplicateOrder = () => {
  const { orderId } = useParams();
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loadOrder = async () => {
      try {
        const { data } = await axios.get('/api/order/qr/duplicate/read', {
          params: { id: orderId },
        });
        if (data === null) {
          setLoading(false);
        } else {
          let originalOrder = data;
          let model = originalOrder.options.find((o) => o.inputId === 1);
          let floorplan = originalOrder.options.find((o) => o.inputId === 2);
          originalOrder.brand =
            model.optionDropdownName === model.inputOption.avalonName ? 1 : 2;

          let price = 0;
          let brand = userContext.userState.me.dealership.brand;
          for (const oo of originalOrder.options) {
            price += oo.price;
            if (oo.input.branded) {
              switch (oo.input.type) {
                case 'DROPDOWN':
                  oo.optionDropdownName =
                    brand === 2
                      ? oo.inputOption.tahoeName
                      : oo.inputOption.avalonName;
                  break;
                case 'CHECKBOX':
                  oo.optionName = brand === 2 ? oo.input.tahoeName : oo.input.name;
                  break;
              }
            }
          }
          let newOrder = {
            userId: userContext.userState.me.id,
            dealershipId: userContext.userState.me.dealership.id,
            brand: brand,
            locationId: '',
            orderStatus: 0,
            orderSource: 1,
            model: model,
            floorplan: floorplan,
            price: price,
            options: originalOrder.options,
          };
          console.log('USER: ', userContext.userState.me);
          console.log('NEW ORDER: ', newOrder);
          setOrder(newOrder);
          let inputLocation = {
            name: 'Location',
            inputOptions: userContext.userState.me.locations.map((l) => {
              return {
                id: l.locationId,
                avalonName: l.location.name,
                active: true,
              };
            }),
            value: userContext.userState.me.locations[0].locationId,
            tooltip: null,
            required: true,
          };
          setLocations(inputLocation);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    setLoading(true);
    if (userContext.userState.me.isAuthenticated) loadOrder();
  }, [orderId]);

  const formatPrice = (price) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(price);
  };

  const handleLocationChange = (value) => {
    setLocations({
      ...locations,
      value,
    });
  };

  const handleSaveOrder = async () => {
    let orderData = {
      order: {
        userId: order.userId,
        dealershipId: order.dealershipId,
        locationId: parseInt(locations.value, 10),
        orderStatus: 0,
        orderSource: 1,
        customerName: '',
        customerPhone: '',
        customerEmail: '',
        customerZipcode: '',
        duplicatedFrom: parseInt(orderId, 10),
      },
      orderOptions: order.options.map((option) => {
        return {
          inputId: option.inputId,
          inputOptionId: option.inputOptionId,
          price: option.price,
          optionName: option.optionName,
          optionDropdownName: option.optionDropdownName,
          optionImgPath: option.optionImgPath,
          inputTextValue: option.inputTextValue,
        };
      }),
      orderNotes: [],
    };

    setIsSubmitting(true);
    try {
      const { data } = await axios.post('/api/order', orderData);
      if (data.message) {
        return navigate(`/locations/${orderData.order.locationId}/current/quote`);
      }
    } catch (err) {
      setIsSubmitting(false);
      console.log(err.response.data.message);
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={10} md={8} lg={6}>
        {userContext.userState.me.isAuthenticated ? (
          <Paper className={classes.paper}>
            {loading ? (
              <Loading />
            ) : (
              <Grid
                container
                justifyContent="space-between"
                spacing={{ xs: 2, md: 4 }}
              >
                {order === null ? (
                  <Grid item xs={12}>
                    <Typography variant="h4">
                      Could not find order #{orderId}.
                    </Typography>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h5" component="div" gutterBottom>
                        Copying From Order #{orderId}
                      </Typography>
                    </Grid>
                    <Hidden mdUp>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h5" component="div">
                          {order.model.optionDropdownName} -{' '}
                          {order.floorplan.optionDropdownName}
                        </Typography>
                        <Typography variant="h6" component="div" gutterBottom>
                          {formatPrice(order.price)}
                        </Typography>
                      </Grid>
                    </Hidden>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h5" gutterBottom>
                        {userContext.userState.me.dealership.name}
                      </Typography>
                      {userContext.userState.me.locations?.length > 0 ? (
                        <FormControl fullWidth>
                          <InputLabel id="location-select-label">
                            Location
                          </InputLabel>
                          <Select
                            labelId="location-select-label"
                            id="location-select"
                            value={locations.value}
                            label="Location"
                            onChange={(e) => handleLocationChange(e.target.value)}
                            disabled={isSubmitting}
                          >
                            {locations.inputOptions.map((io) => {
                              return (
                                <MenuItem key={io.id} value={io.id}>
                                  {io.avalonName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      ) : null}
                    </Grid>
                    <Hidden mdDown>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h5" component="div">
                          {order.model.optionDropdownName} -{' '}
                          {order.floorplan.optionDropdownName}
                        </Typography>
                        <Typography variant="h6" component="div" gutterBottom>
                          {formatPrice(order.price)}
                        </Typography>
                      </Grid>
                    </Hidden>
                  </>
                )}
              </Grid>
            )}
            {order !== null ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveOrder}
                sx={{ mt: 4 }}
                disabled={isSubmitting || locations.value === ''}
                fullWidth
              >
                {isSubmitting ? 'Creating Order...' : 'Create Order'}
              </Button>
            ) : null}
          </Paper>
        ) : (
          <Paper className={classes.paper}>
            <Typography variant="h4" component="div">
              Please login to copy this order.
            </Typography>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

export default DuplicateOrder;
