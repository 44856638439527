import React from 'react';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    ['@media print']: {
      display: 'none',
    },
  },
  hidePrint: {
    ['@media print']: {
      display: 'none',
    },
  },
  optionRow: {
    '&:nth-child(odd)>td': {
      backgroundColor: '#F8F8F8',
    },
    '& td': {
      borderBottom: 'none',
      fontSize: '16px',
      padding: theme.spacing(1, 2),
    },
    '& td:nth-child(odd)': {
      borderRadius: '7px 0px 0px 7px',
    },
    '& td:nth-child(even)': {
      textAlign: 'right',
      borderRadius: '0px 7px 7px 0px',
    },
  },
  total: {
    '& td': {
      borderBottom: 'none',
      color: '#FFF',
      backgroundColor: '#171A20',
      fontSize: '18px',
    },
    '& td:nth-child(odd)': {
      borderRadius: '7px 0px 0px 7px',
    },
    '& td:nth-child(even)': {
      textAlign: 'right',
      borderRadius: '0px 7px 7px 0px',
    },
  },
}));

const Options = (props) => {
  const {
    options,
    calculatePrice,
    calculateTotalPrice,
    showMSRP,
    discounts,
    hasTwin,
    calculateFloorplanDiscount,
    modelId,
  } = props;
  const classes = useStyles();

  const formatDiscount = (discount) => {
    let formattedDiscount = '';
    switch (discount) {
      case 'floorplan':
        if ([1023, 1024].includes(modelId)) return null;
        if (discounts.floorplan !== 0)
          formattedDiscount = `Floorplan Discount - ${discounts.floorplan}%`;
        if (discounts.floorplan2 !== 0 && discounts.floorplan !== 0)
          formattedDiscount = `${formattedDiscount} + ${discounts.floorplan2}%`;
        if (discounts.floorplan2 !== 0 && discounts.floorplan === 0)
          formattedDiscount = `Floorplan Discount - ${discounts.floorplan2}%`;
        break;
      case 'motor':
        if (discounts.motor !== 0)
          formattedDiscount = `Motor Discount - ${discounts.motor}%`;
        if (discounts.motor2 !== 0 && discounts.motor !== 0)
          formattedDiscount = `${formattedDiscount} + ${discounts.motor2}%`;
        if (discounts.motor2 !== 0 && discounts.motor === 0)
          formattedDiscount = `Motor Discount - ${discounts.motor2}%`;
        break;
    }

    return formattedDiscount;
  };

  return (
    <Grid container item spacing={2} className={classes.container}>
      <Grid container item justifyContent="space-between" spacing={2}>
        <Grid item>
          <Typography variant="h6">Options</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">Price</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table>
          <TableBody>
            {options.map((option) => {
              return (
                <TableRow
                  className={classes.optionRow}
                  key={`${option.inputId}_${option.id}`}
                >
                  <TableCell>
                    {option.optionName}
                    {option.optionDropdownName !== null
                      ? ` - ${option.optionDropdownName}`
                      : ''}
                    {option.inputId === 2 && discounts.useDiscounts && (
                      <Typography variant="body2">
                        {formatDiscount('floorplan')}
                      </Typography>
                    )}
                    {option.inputId === 6 && discounts.useDiscounts && (
                      <Typography variant="body2">
                        {formatDiscount('motor')}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {calculatePrice(option) || `$0`}
                    {option.inputId === 2 &&
                      discounts.useDiscounts &&
                      (discounts.floorplan !== 0 || discounts.floorplan2 !== 0) && (
                        <Typography variant="body2">
                          {calculateFloorplanDiscount(option.price)}
                        </Typography>
                      )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12}>
        <Table className={classes.total}>
          <TableBody>
            <TableRow>
              <TableCell>TOTAL</TableCell>
              <TableCell>{calculateTotalPrice(null, false)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default Options;
