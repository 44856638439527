import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { UserContext } from '../userContext';
import makeStyles from '@mui/styles/makeStyles';
import { Paper, Grid, TextField, Button } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Loading from '../components/Loading';
import { useNavigate, Link } from 'react-router-dom';
import { parseBrand } from '../lib';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(0, 2, 2, 2),
  },
}));

const validationSchema = yup.object({
  username: yup.string('Enter your username').required('Username is required'),
  password: yup.string('Enter your password').required('Password is required'),
});

const Login = () => {
  const [fetchError, setFetchError] = useState(null);
  const [loading, setLoading] = useState(false);
  const userContext = useContext(UserContext);
  const classes = useStyles();
  const [resetPassword, setResetPassword] = useState(false);
  const [resetStatus, setResetStatus] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (userContext.userState.me.isAuthenticated) navigate('/');
  }, [userContext.userState.me.isAuthenticated]);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const { data } = await axios.post('/api/login', values);
        const user = data.user;
        user.isAuthenticated = true;
        let brand =
          user.dealership !== null ? parseBrand(user.dealership.brand) : 'a';
        user.orderFormPath =
          user.dealership !== null ? `/order/new/d/${brand}` : null;
        user.customerOrderFormPath =
          user.dealership !== null ? `/order/new/cd/${brand}` : null;
        user.customerIds = [];
        if (user.role >= 10) {
          let tmpIds = [];
          for (let location of user.locations) {
            for (let cIds of location.location.customerIds) {
              if (tmpIds.indexOf(cIds.customerId) === -1)
                tmpIds.push(cIds.customerId);
            }
          }
          user.customerIds = tmpIds;
        }
        userContext.userDispatch({ type: 'login', user });
        navigate('/');
      } catch (err) {
        setFetchError(err.response.data.message);
        setLoading(false);
      }
    },
  });

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={6} lg={4}>
        <Paper variant="outlined" className={classes.paper}>
          {loading ? (
            <Loading />
          ) : (
            <div>
              <h1>{!resetPassword ? 'Login' : 'Reset Password'}</h1>
              {fetchError && <p style={{ color: 'red' }}>{fetchError}</p>}
              {resetStatus && <p>{resetStatus}</p>}
              <section>
                <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                  <TextField
                    fullWidth
                    id="username"
                    name="username"
                    label="Username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    className={classes.textField}
                  />
                  <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    className={classes.textField}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </Button>
                  <Button
                    variant="text"
                    color="secondary"
                    sx={{ mt: 2 }}
                    component={Link}
                    to="/forgot-password"
                  >
                    Forgot your password?
                  </Button>
                </form>
              </section>
            </div>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Login;
