import React, { useState, useEffect } from 'react';
import { Typography, Divider, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const inputPages = [
  { id: '1', page: '1', hide_print: 0 },
  { id: '2', page: '1', hide_print: 0 },
  { id: '3', page: '1', hide_print: 0 },
  { id: '4', page: '1', hide_print: 0 },
  { id: '5', page: '1', hide_print: 0 },
  { id: '6', page: '1', hide_print: 0 },
  { id: '117', page: '1', hide_print: 0 },
  { id: '142', page: '1', hide_print: 0 },
  { id: '158', page: '1', hide_print: 0 },
  { id: '159', page: '1', hide_print: 0 },
  { id: '160', page: '1', hide_print: 0 },
  { id: '161', page: '1', hide_print: 0 },
  { id: '162', page: '1', hide_print: 0 },
  { id: '164', page: '1', hide_print: 0 },
  { id: '165', page: '1', hide_print: 0 },
  { id: '166', page: '1', hide_print: 0 },
  { id: '173', page: '1', hide_print: 1 },
  { id: '174', page: '1', hide_print: 1 },
  { id: '175', page: '1', hide_print: 1 },
  { id: '176', page: '1', hide_print: 1 },
  { id: '177', page: '1', hide_print: 1 },
  { id: '178', page: '1', hide_print: 0 },
  { id: '182', page: '1', hide_print: 0 },
  { id: '185', page: '1', hide_print: 0 },
  { id: '192', page: '1', hide_print: 0 },
  { id: '193', page: '1', hide_print: 0 },
  { id: '11', page: '2', hide_print: 0 },
  { id: '12', page: '2', hide_print: 0 },
  { id: '13', page: '2', hide_print: 0 },
  { id: '14', page: '2', hide_print: 0 },
  { id: '15', page: '2', hide_print: 0 },
  { id: '94', page: '2', hide_print: 0 },
  { id: '99', page: '2', hide_print: 0 },
  { id: '101', page: '2', hide_print: 0 },
  { id: '102', page: '2', hide_print: 0 },
  { id: '103', page: '2', hide_print: 0 },
  { id: '104', page: '2', hide_print: 0 },
  { id: '115', page: '2', hide_print: 0 },
  { id: '116', page: '2', hide_print: 0 },
  { id: '118', page: '2', hide_print: 0 },
  { id: '119', page: '2', hide_print: 0 },
  { id: '120', page: '2', hide_print: 0 },
  { id: '121', page: '2', hide_print: 0 },
  { id: '122', page: '2', hide_print: 0 },
  { id: '123', page: '2', hide_print: 0 },
  { id: '125', page: '2', hide_print: 0 },
  { id: '126', page: '2', hide_print: 0 },
  { id: '127', page: '2', hide_print: 0 },
  { id: '128', page: '2', hide_print: 0 },
  { id: '129', page: '2', hide_print: 0 },
  { id: '130', page: '2', hide_print: 0 },
  { id: '131', page: '2', hide_print: 0 },
  { id: '132', page: '2', hide_print: 0 },
  { id: '133', page: '2', hide_print: 0 },
  { id: '134', page: '2', hide_print: 0 },
  { id: '135', page: '2', hide_print: 0 },
  { id: '136', page: '2', hide_print: 0 },
  { id: '137', page: '2', hide_print: 0 },
  { id: '138', page: '2', hide_print: 0 },
  { id: '139', page: '2', hide_print: 0 },
  { id: '140', page: '2', hide_print: 0 },
  { id: '168', page: '2', hide_print: 0 },
  { id: '169', page: '2', hide_print: 0 },
  { id: '170', page: '2', hide_print: 0 },
  { id: '171', page: '2', hide_print: 0 },
  { id: '172', page: '2', hide_print: 0 },
  { id: '188', page: '2', hide_print: 0 },
  { id: '189', page: '2', hide_print: 0 },
  { id: '196', page: '2', hide_print: 0 },
  { id: '197', page: '2', hide_print: 0 },
  { id: '198', page: '2', hide_print: 0 },
  { id: '200', page: '2', hide_print: 0 },
  { id: '201', page: '2', hide_print: 0 },
  { id: '202', page: '2', hide_print: 0 },
  { id: '16', page: '3', hide_print: 0 },
  { id: '17', page: '3', hide_print: 0 },
  { id: '18', page: '3', hide_print: 0 },
  { id: '19', page: '3', hide_print: 0 },
  { id: '20', page: '3', hide_print: 0 },
  { id: '21', page: '3', hide_print: 0 },
  { id: '22', page: '3', hide_print: 0 },
  { id: '23', page: '3', hide_print: 0 },
  { id: '24', page: '3', hide_print: 0 },
  { id: '25', page: '3', hide_print: 0 },
  { id: '26', page: '3', hide_print: 0 },
  { id: '27', page: '3', hide_print: 0 },
  { id: '28', page: '3', hide_print: 0 },
  { id: '98', page: '3', hide_print: 0 },
  { id: '106', page: '3', hide_print: 0 },
  { id: '107', page: '3', hide_print: 0 },
  { id: '141', page: '3', hide_print: 0 },
  { id: '149', page: '3', hide_print: 0 },
  { id: '151', page: '3', hide_print: 0 },
  { id: '152', page: '3', hide_print: 0 },
  { id: '157', page: '3', hide_print: 0 },
  { id: '190', page: '3', hide_print: 0 },
  { id: '29', page: '4', hide_print: 0 },
  { id: '30', page: '4', hide_print: 0 },
  { id: '31', page: '4', hide_print: 0 },
  { id: '32', page: '4', hide_print: 0 },
  { id: '33', page: '4', hide_print: 0 },
  { id: '34', page: '4', hide_print: 0 },
  { id: '35', page: '4', hide_print: 0 },
  { id: '110', page: '4', hide_print: 0 },
  { id: '124', page: '4', hide_print: 0 },
  { id: '146', page: '4', hide_print: 0 },
  { id: '147', page: '4', hide_print: 0 },
  { id: '36', page: '5', hide_print: 0 },
  { id: '37', page: '5', hide_print: 0 },
  { id: '38', page: '5', hide_print: 0 },
  { id: '39', page: '5', hide_print: 0 },
  { id: '40', page: '5', hide_print: 0 },
  { id: '41', page: '5', hide_print: 0 },
  { id: '42', page: '5', hide_print: 0 },
  { id: '43', page: '5', hide_print: 0 },
  { id: '44', page: '5', hide_print: 0 },
  { id: '45', page: '5', hide_print: 0 },
  { id: '46', page: '5', hide_print: 0 },
  { id: '47', page: '5', hide_print: 0 },
  { id: '48', page: '5', hide_print: 0 },
  { id: '49', page: '5', hide_print: 0 },
  { id: '111', page: '5', hide_print: 0 },
  { id: '153', page: '5', hide_print: 0 },
  { id: '154', page: '5', hide_print: 0 },
  { id: '155', page: '5', hide_print: 0 },
  { id: '156', page: '5', hide_print: 0 },
  { id: '181', page: '5', hide_print: 0 },
  { id: '184', page: '5', hide_print: 0 },
  { id: '191', page: '5', hide_print: 0 },
  { id: '194', page: '5', hide_print: 0 },
  { id: '195', page: '5', hide_print: 0 },
  { id: '199', page: '5', hide_print: 0 },
  { id: '50', page: '6', hide_print: 0 },
  { id: '51', page: '6', hide_print: 0 },
  { id: '52', page: '6', hide_print: 0 },
  { id: '53', page: '6', hide_print: 0 },
  { id: '54', page: '6', hide_print: 0 },
  { id: '55', page: '6', hide_print: 0 },
  { id: '56', page: '6', hide_print: 0 },
  { id: '108', page: '6', hide_print: 0 },
  { id: '183', page: '6', hide_print: 0 },
  { id: '57', page: '7', hide_print: 0 },
  { id: '58', page: '7', hide_print: 0 },
  { id: '59', page: '7', hide_print: 0 },
  { id: '60', page: '7', hide_print: 0 },
  { id: '61', page: '7', hide_print: 0 },
  { id: '62', page: '7', hide_print: 0 },
  { id: '63', page: '7', hide_print: 0 },
  { id: '64', page: '7', hide_print: 0 },
  { id: '65', page: '7', hide_print: 0 },
  { id: '66', page: '7', hide_print: 0 },
  { id: '67', page: '7', hide_print: 0 },
  { id: '68', page: '7', hide_print: 0 },
  { id: '69', page: '7', hide_print: 0 },
  { id: '70', page: '7', hide_print: 0 },
  { id: '71', page: '7', hide_print: 0 },
  { id: '72', page: '7', hide_print: 0 },
  { id: '73', page: '7', hide_print: 0 },
  { id: '74', page: '7', hide_print: 0 },
  { id: '75', page: '7', hide_print: 0 },
  { id: '76', page: '7', hide_print: 0 },
  { id: '77', page: '7', hide_print: 0 },
  { id: '78', page: '7', hide_print: 0 },
  { id: '79', page: '7', hide_print: 0 },
  { id: '95', page: '7', hide_print: 0 },
  { id: '96', page: '7', hide_print: 0 },
  { id: '97', page: '7', hide_print: 0 },
  { id: '100', page: '7', hide_print: 0 },
  { id: '105', page: '7', hide_print: 0 },
  { id: '109', page: '7', hide_print: 0 },
  { id: '112', page: '7', hide_print: 0 },
  { id: '113', page: '7', hide_print: 0 },
  { id: '114', page: '7', hide_print: 0 },
  { id: '148', page: '7', hide_print: 0 },
  { id: '150', page: '7', hide_print: 0 },
  { id: '163', page: '7', hide_print: 0 },
  { id: '167', page: '7', hide_print: 0 },
  { id: '179', page: '7', hide_print: 0 },
  { id: '186', page: '7', hide_print: 0 },
  { id: '187', page: '7', hide_print: 0 },
  { id: '80', page: '8', hide_print: 1 },
  { id: '81', page: '8', hide_print: 0 },
  { id: '82', page: '8', hide_print: 0 },
  { id: '83', page: '8', hide_print: 0 },
  { id: '84', page: '8', hide_print: 0 },
  { id: '85', page: '8', hide_print: 0 },
  { id: '86', page: '8', hide_print: 0 },
  { id: '87', page: '8', hide_print: 0 },
  { id: '88', page: '8', hide_print: 0 },
  { id: '89', page: '8', hide_print: 0 },
  { id: '90', page: '8', hide_print: 0 },
  { id: '91', page: '8', hide_print: 0 },
  { id: '92', page: '8', hide_print: 0 },
  { id: '93', page: '8', hide_print: 0 },
  { id: '143', page: '8', hide_print: 0 },
  { id: '144', page: '8', hide_print: 0 },
  { id: '145', page: '8', hide_print: 0 },
  { id: '180', page: '8', hide_print: 0 },
];

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(2, 0),
  },
  summary: {
    paddingTop: '0px!important',
    marginTop: '8px',
    borderRight: `1px solid ${theme.palette.text.disabled}`,
    [theme.breakpoints.down('xl')]: {
      borderRight: 'none',
    },
  },
  summaryTitle: {
    fontWeight: '500',
  },
  summaryText: {
    paddingBottom: '6px!important',
  },
}));

const MY21Order = (props) => {
  const { order, setOrder, calculatePrice, calculateTotalPrice, discounts } = props;
  const classes = useStyles();
  const [pages, setPages] = useState({
    1: { name: 'Initial Boat Build', options: [] },
    2: { name: 'Packages', options: [] },
    3: { name: 'Performance/Pontoon', options: [] },
    4: { name: 'Deck Features', options: [] },
    5: { name: 'Stand Features', options: [] },
    6: { name: 'Wall Features', options: [] },
    7: { name: 'Furniture Features', options: [] },
    8: { name: 'Canvas/Top', options: [] },
  });

  const compare = (a, b) => {
    if (a.optionId < b.optionId) {
      return -1;
    }
    if (a.optionId > b.optionId) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    console.log(order);
    let updatedOrder = { ...order };
    let tmpPages = { ...pages };

    for (let o of updatedOrder.options) {
      let page = inputPages.find((ip) => parseInt(ip.id, 10) === o.optionId);
      if (page !== undefined) {
        o.page = parseInt(page.page, 10);
        o.hide_print = parseInt(page.hide_print, 10);
        tmpPages[o.page].options.push(o);
      }
    }

    setPages(tmpPages);
    setOrder(updatedOrder);
  }, []);

  const orderStatus = (status) => {
    switch (parseInt(status, 10)) {
      case 0:
        return 'Quote';
      case 1:
        return 'Confirmed';
      case 2:
        return 'Entered';
      case 3:
        return 'Approved';
      case 5:
        return 'Paper Order';
    }
  };

  return (
    <>
      <Typography variant="h4">
        {order.dealership?.name ? `${order.dealership.name} - ` : null}Order #
        {order.id} - {orderStatus(order.orderStatus)}
      </Typography>
      <Typography variant="h5">Price: {calculateTotalPrice()}</Typography>
      {order.erpNumber !== null ? (
        <Typography variant="h5">ERP Number: {order.erpNumber}</Typography>
      ) : null}
      {order.customerName !== null ? (
        <Typography variant="h5">{order.customerName}</Typography>
      ) : null}
      <Divider className={classes.divider} />
      {Object.keys(pages).map((page) => {
        if (
          pages[page].options.filter((o) => o.hide_print === 1 || o.price > 0)
            .length === 0
        )
          return null;
        return (
          <div key={`page_${page}`}>
            <Typography variant="h6" className={classes.summaryTitle}>
              {pages[page].name}
            </Typography>
            <Grid container justifyContent="space-between">
              <Grid item xs={5}>
                <Typography variant="h6">Option</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="h6">Dropdown Option</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6">Price</Typography>
              </Grid>
            </Grid>
            {pages[page].options
              .filter((o) => o.hide_print === 1 || o.price > 0 || o.page === 1)
              .sort(compare)
              .map((option, index) => {
                if (option.inputId === 142) return null;
                return (
                  <Grid
                    container
                    key={`option_${option.optionId}`}
                    justifyContent="space-between"
                    className={index === 14 ? classes.pageBreak : null}
                  >
                    <Grid item xs={5} className={classes.summaryText}>
                      <Typography variant="body1">{option.optionName}</Typography>
                      {discounts.floorplan !== '0' && option.inputId === 2 ? (
                        <Typography variant="subtitle2">
                          Floorplan Discount - ${discounts.floorplan}%
                        </Typography>
                      ) : null}
                      {discounts.motor !== '0' && option.inputId === 6 ? (
                        <Typography variant="subtitle2">
                          Motor Discount - ${discounts.motor}%
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={5} className={classes.summaryText}>
                      <Typography variant="body1">
                        {option.optionDropdownName !== null
                          ? option.optionDropdownName
                          : null}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.summaryText}>
                      <Typography variant="body1">
                        {calculatePrice(option)}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
          </div>
        );
      })}
    </>
  );
};

export default MY21Order;
