import { Box, Typography, Card, CardContent, Grid } from '@mui/material';

const EpicorInfo = (props) => {
  const { orderNum, status, productionWeek, revisionId, warnings } = props;
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          Epicor Info
        </Typography>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item sm={6}>
            <Box>
              <Typography variant="body1">Order ID</Typography>
              <Typography variant="h6" sx={{ fontWeight: '600' }}>
                {orderNum}
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box>
              <Typography variant="body1">Status</Typography>
              <Typography variant="h6" sx={{ fontWeight: '600' }}>
                {status}
              </Typography>
            </Box>
          </Grid>
          {productionWeek && (
            <Grid item sm={12}>
              <Box>
                <Typography variant="body1">Production Date</Typography>
                <Typography variant="h6" sx={{ fontWeight: '600' }}>
                  {productionWeek}
                </Typography>
              </Box>
            </Grid>
          )}
          {warnings && (
            <Grid item sm={12}>
              <Box>
                <Typography variant="body1">Warnings</Typography>
                <Typography variant="body2" color="error">
                  {warnings}
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid item sm={12}>
            <Box>
              <Typography variant="body1">Revision ID</Typography>
              <Typography variant="body2">{revisionId}</Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EpicorInfo;
