import React from 'react';
import { Box, Typography, Slider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#747679',
  },
  container: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    background: '#FFF',
    borderRadius: '16px',
  },
}));

const Filters = (props) => {
  const {
    models,
    priceFilter,
    lengthFilter,
    handleChangeLengthFilter,
    handleChangePriceFilter,
    hasAvailableFloorplans,
    formatPrice,
    customerBuild,
    formType,
    priceMarkupFilter,
    calculatePriceWithMarkup,
  } = props;
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h5" align="left" className={classes.title}>
        Filter
      </Typography>
      <Box mt={2} className={classes.container}>
        <Box display="flex" alignItems="center">
          <Box component="span">Boat Length</Box>
          <Box flex={1} mx={2}>
            <Box display="flex" justifyContent="space-between" fontSize={12}>
              <Box>Min - {lengthFilter[0]}ft</Box>
              <Box>Max - {lengthFilter[1]}ft</Box>
            </Box>
            <Slider
              valueLabelFormat={(value) => `${value}ft`}
              value={lengthFilter}
              onChange={handleChangeLengthFilter}
              min={models.minLength}
              max={models.maxLength}
              defaultValue={[models.minLength, models.maxLength]}
              step={1}
              valueLabelDisplay="auto"
              getAriaValueText={(value) => `${value}ft`}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mt={2}>
          <Box component="span">Select Price</Box>
          <Box flex={1} mx={2}>
            <Box display="flex" justifyContent="space-between" fontSize={12}>
              <Box>
                Min -{' '}
                {formatPrice(
                  formType === 'd' && !customerBuild
                    ? priceMarkupFilter[0]
                    : priceFilter[0] / 0.64
                )}
              </Box>
              <Box>
                Max -{' '}
                {formatPrice(
                  formType === 'd' && !customerBuild
                    ? priceMarkupFilter[1]
                    : priceFilter[1] / 0.64
                )}
              </Box>
            </Box>
            {formType === 'd' && !customerBuild ? (
              <Slider
                valueLabelFormat={(value) => formatPrice(value)}
                value={priceMarkupFilter}
                onChange={handleChangePriceFilter}
                min={models.minMarkupPrice}
                max={models.maxMarkupPrice}
                defaultValue={[models.minMarkupPrice, models.maxMarkupPrice]}
                step={1000}
                valueLabelDisplay="auto"
                getAriaValueText={(value) => formatPrice(value)}
              />
            ) : (
              <Slider
                valueLabelFormat={(value) => formatPrice(value / 0.64)}
                value={priceFilter}
                onChange={handleChangePriceFilter}
                min={models.minPrice}
                max={models.maxPrice}
                defaultValue={[models.minPrice, models.maxPrice]}
                step={1000}
                valueLabelDisplay="auto"
                getAriaValueText={(value) => formatPrice(value / 0.64)}
              />
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Filters;
