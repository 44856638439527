import { Typography, Card, CardContent } from '@mui/material';

const Warnings = (props) => {
  const { warning } = props;
  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="body1" color="error" sx={{ fontWeight: '600' }}>
          {warning}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Warnings;
