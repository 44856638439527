import React, { useState, useEffect } from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import EnhancedTable from '../../components/EnhancedTable';
import axios from 'axios';
import Loading from '../../components/Loading';
import AddSetting from '../../components/forms/AddSetting';
import EditSetting from '../../components/forms/EditSetting';

const AtomSettings = () => {
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState([]);
  const [openAddSetting, setOpenAddSetting] = useState(false);
  const [openEditSetting, setOpenEditSetting] = useState(false);
  const [editSetting, setEditSetting] = useState(null);

  useEffect(() => {
    const loadAtomSettings = async () => {
      try {
        const { data } = await axios.get('/api/settings');

        console.log(data);
        setSettings(data);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
      }
    };

    loadAtomSettings();
  }, []);

  const handleOpenAddSetting = () => {
    setOpenAddSetting(true);
  };

  const handleCloseAddSetting = () => {
    setOpenAddSetting(false);
  };

  const handleOpenEditSetting = () => {
    setOpenEditSetting(true);
  };

  const handleCloseEditSetting = () => {
    setOpenEditSetting(false);
  };

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item sm={12} md={8}>
        {loading ? (
          <Loading />
        ) : (
          <EnhancedTable
            title="ATOM Settings"
            data={settings}
            addData={handleOpenAddSetting}
            openEditData={handleOpenEditSetting}
            setEditData={setEditSetting}
            disableSelectAll
          />
        )}
        <Dialog
          open={openAddSetting}
          onClose={handleCloseAddSetting}
          maxWidth="sm"
          fullWidth
          aria-labelledby="add-setting-form"
        >
          <DialogTitle id="form-add-setting">New Setting</DialogTitle>
          <DialogContent>
            <AddSetting
              closeAdd={handleCloseAddSetting}
              settings={settings}
              setSettings={setSettings}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddSetting} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {editSetting !== null ? (
          <Dialog
            open={openEditSetting}
            onClose={handleCloseEditSetting}
            maxWidth="sm"
            fullWidth
            aria-labelledby="edit-setting-form"
          >
            <DialogTitle id="form-edit-setting">Edit Setting</DialogTitle>
            <DialogContent>
              <EditSetting
                closeAdd={handleCloseEditSetting}
                settings={settings}
                setSettings={setSettings}
                setting={editSetting}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEditSetting} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default AtomSettings;
