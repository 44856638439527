import React, { useState, useEffect, useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Grid,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useParams, useResolvedPath } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { format } from 'date-fns';
import EnhancedTable from '../../../components/NewEnhancedTable';
import AddNote from '../../../components/forms/AddNote';
import PDF from '../../../components/Order/Components/PDF';
import html2pdf from 'html2pdf.js';
import { UserContext } from '../../../userContext';
import RebatesTable from '../../../components/RebatesTable';

const useStyles = makeStyles((theme) => ({
  note: {
    marginBottom: theme.spacing(2),
  },
  pdf: {
    display: 'none',
  },
  pdfHeader: {
    fontWeight: 'bold',
    marginBottom: '6px',
  },
  standardFeatures: {
    pageBreakBefore: 'always',
  },
  bulkPrint: {
    pageBreakBefore: 'always',
  },
  optionsTable: {
    width: '100%',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    '& thead tr th': {
      padding: theme.spacing(1, 2),
    },
    '& tbody tr td': {
      padding: theme.spacing(1, 2),
    },
    '& tbody tr:nth-child(odd)': {
      background: '#F8F8F8',
      borderRadius: '7px',
    },
    '&.parallel tbody tr td': {
      padding: theme.spacing(0.25, 2),
      height: '30px',
      fontSize: '12px',
    },
  },
}));

const RepOrders = () => {
  const classes = useStyles();
  const { status } = useParams();
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [openNotes, setOpenNotes] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [returnToQuoteOrders, setReturnToQuoteOrders] = useState([]);
  const [submittingQuotes, setSubmittingQuotes] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState([
    'options',
    'userId',
    'dealershipId',
    'locationId',
    'orderSource',
    'archiveId',
    'active',
    'customerPhone',
    'customerEmail',
    'customerId',
    'requestedBuildWeek',
    'optionsByPage',
  ]);
  const [downloadOrders, setDownloadOrders] = useState([]);
  const url = useResolvedPath('').pathname;
  const [initLayoutInfo, setInitLayoutInfo] = useState({});
  const userContext = useContext(UserContext);
  const [externalReps, setExternalReps] = useState([]);
  const [selectedRep, setSelectedRep] = useState('0');
  const [rebates, setRebates] = useState([]);
  const [selectedRebate, setSelectedRebate] = useState(null);
  const [openDeleteOrder, setOpenDeleteOrder] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);

  const getPageName = (pageId) => {
    switch (pageId) {
      case 2:
        return 'Colors';
      case 3:
        return 'Packages';
      case 4:
        return 'Performance';
      case 5:
        return 'Additional Features';
    }
  };

  useEffect(() => {
    let source = axios.CancelToken.source();
    const loadExternalReps = async () => {
      try {
        const { data } = await axios.get('/api/user/reps/external');
        setExternalReps(data);
      } catch (err) {
        console.log(err.message);
      }
    };

    const loadOrdersByStatus = async () => {
      try {
        const { data } = await axios.get('/api/orders/status', {
          cancelToken: source.token,
          params: { status: status, repId: selectedRep },
        });
        switch (status) {
          case 'quote':
            data.orders = data.orders.map((order) => {
              return {
                ...order,
                orderStatus: 0,
              };
            });
            setHiddenColumns([
              'options',
              'userId',
              'dealershipId',
              'locationId',
              'orderSource',
              'archiveId',
              'active',
              'customerPhone',
              'customerEmail',
              'customerId',
              'requestedBuildWeek',
              'erpNumber',
              'confirmedDate',
              'optionsByPage',
              'buildQuarter',
              'epicorOrder',
              'epicorApprovalStatus',
              'productionDate',
              'dealerColor',
              'brand',
              'orderStatus',
            ]);
            break;
          case 'confirmed':
            setHiddenColumns([
              'options',
              'userId',
              'dealershipId',
              'locationId',
              'orderSource',
              'archiveId',
              'active',
              'customerPhone',
              'customerEmail',
              'customerId',
              'requestedBuildWeek',
              'erpNumber',
              'optionsByPage',
              'dealerColor',
              'brand',
            ]);
            break;
          case 'paper':
            setHiddenColumns([
              'options',
              'userId',
              'dealershipId',
              'locationId',
              'orderSource',
              'archiveId',
              'active',
              'customerPhone',
              'customerEmail',
              'customerId',
              'requestedBuildWeek',
              'optionsByPage',
              'model',
              'floorplan',
              'confirmedDate',
              'buildQuarter',
              'brand',
            ]);
            break;
          default:
            setHiddenColumns([
              'options',
              'userId',
              'dealershipId',
              'locationId',
              'orderSource',
              'archiveId',
              'active',
              'customerPhone',
              'customerEmail',
              'customerId',
              'requestedBuildWeek',
              'optionsByPage',
              'dealerColor',
              'brand',
            ]);
        }

        setOrders(data.orders);
        loadTableSettings();
      } catch (err) {
        console.log(err.message);
      }
    };

    const loadTableSettings = async () => {
      let title = `Rep Orders ${status}`;
      let formattedTableName = title.replace(/\s/g, '_').toLowerCase();
      try {
        const { data } = await axios.get('/api/user/table/settings', {
          params: { tableName: formattedTableName },
        });
        if (data.message === 'Found table settings') {
          let tableSettings = data.tableSettings.fields;
          console.log('TABLE SETTINGS: ', tableSettings);
          setInitLayoutInfo(tableSettings);
        } else {
          setInitLayoutInfo({
            filter: {
              filterModel: {
                items: [],
                linkOperator: 'and',
              },
            },
            sorting: {
              sortModel: [],
            },
            columns: {
              columnVisibilityModel: [],
            },
          });
        }
      } catch (err) {
        return console.log(err);
      }
      setLoading(false);
    };

    const loadRebates = async () => {
      try {
        const { data } = await axios.get('/api/orders/rebates', {
          cancelToken: source.token,
          params: { repId: selectedRep },
        });
        setRebates(data.rebates);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
      }
    };

    setLoading(true);
    if (status === 'rebates') {
      loadRebates();
    } else {
      loadOrdersByStatus();
    }

    if (userContext.userState.me.role <= 2) {
      loadExternalReps();
    }
    return function () {
      source.cancel('Cancelling in cleanup');
    };
  }, [status, selectedRep]);

  const handleOpenNotes = (order) => {
    setSelectedOrder(order);
    setOpenNotes(true);
  };

  const handleCloseNotes = () => {
    setOpenNotes(false);
  };

  const viewOrder = (id) => {
    window.open(`${url}/order/${id}`, '_blank');
  };

  const bulkReturnToQuote = (orders) => {
    setReturnToQuoteOrders(orders);
    setOpenConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const handleOpenDeleteOrder = (id) => {
    setOpenDeleteOrder(true);
    setOrderToDelete(id);
  };

  const handleCloseDeleteOrder = () => {
    setOpenDeleteOrder(false);
    setOrderToDelete(null);
  };

  const handleReturnToQuote = async () => {
    try {
      setSubmittingQuotes(true);
      const { data } = await axios.post('/api/orders/quote', returnToQuoteOrders);
      if (data.message !== undefined) {
        setOrders(orders.filter((o) => returnToQuoteOrders.indexOf(o.id) === -1));
        setReturnToQuoteOrders([]);
        setSubmittingQuotes(false);
        setOpenConfirmationModal(false);
      }
    } catch (err) {
      setSubmittingQuotes(false);
      console.log(err.response.data.message);
    }
  };

  const confirmDeleteOrder = async () => {
    try {
      const { data } = await axios.get('/api/order/delete', {
        params: { id: orderToDelete, userId: userContext.userState.me.id },
      });
      if (data.message !== undefined) {
        console.log(data.message);
        setOrders(orders.filter((o) => o.id !== orderToDelete));
        setOrderToDelete(null);
        setOpenDeleteOrder(false);
      }
    } catch (err) {
      console.log(err.response.data.message);
    }
  };

  const startDownloadOrders = async (orders) => {
    setLoading(true);
    let tmpOrders = [];
    for (let o of orders) {
      try {
        let { data } = await axios.get('/api/order', {
          params: { id: o, orderSource: 1 },
        });

        let tmpOptionsByPage = {};
        for (let o of data.options) {
          // console.log(o.input.page.id)
          let pageId = o.inputId === 164 ? 1 : o.input.page.id;
          if (tmpOptionsByPage[pageId] === undefined) {
            tmpOptionsByPage[pageId] = {
              name: o.inputId === 164 ? 'Initial Boat Build' : o.input.page.name,
              options: [o],
              note: null,
            };
          } else {
            tmpOptionsByPage[pageId].options.push(o);
          }
        }

        for (let note of data.notes) {
          if (note.pageId !== null) {
            if (tmpOptionsByPage[note.pageId] !== undefined) {
              tmpOptionsByPage[note.pageId].note = note.text;
            } else {
              tmpOptionsByPage[note.pageId] = {
                name: getPageName(note.pageId),
                options: [],
                note: note.text,
              };
            }
          }
        }

        data.optionsByPage = tmpOptionsByPage;
        data.brand = data.dealership.brand;
        tmpOrders.push(data);
      } catch (err) {
        console.log(err);
      }
    }
    setDownloadOrders(tmpOrders);

    try {
      let pages = Array.from(document.querySelectorAll('.pdf-page'));
      const pdfOptions = {
        margin: [5, 2],
        html2canvas: {
          scale: 4,
        },
        pagebreak: {
          before: '.pagebreak',
        },
      };
      await download(pages, pdfOptions);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const download = async (pages, options) => {
    let worker = html2pdf().set(options).from(pages[0]);

    if (pages.length > 1) {
      worker = worker.toPdf();

      // add each element/page individually to the pdf render process
      pages.slice(1).forEach((page, index) => {
        worker = worker
          .get('pdf')
          .then((pdf) => {
            pdf.addPage();
          })
          .from(page)
          .toContainer()
          .toCanvas()
          .toPdf();
      });
    }

    worker = worker.save(`Orders_${format(new Date(), 'MM_dd_yyyy')}`);
    setLoading(false);
  };

  const calculatePrice = (option) => {
    return option.price === 0
      ? ''
      : option.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const calculateTotalPrice = (order) => {
    let calculatedPrice = 0;
    for (let option of order.options) {
      calculatedPrice += option.price;
      // console.log(option.price)
    }

    return calculatedPrice.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  const handleDownloadXML = async (id) => {
    let order = orders.filter((o) => o.id === id[0])[0];
    const date = new Date();
    const formattedDate = date.toLocaleDateString('en', {
      // you can use undefined as first argument
      year: '2-digit',
      day: '2-digit',
      month: '2-digit',
    });
    try {
      axios
        .get('/api/xml', {
          params: { orderId: order.id, customerId: order.customerId },
          responseType: 'blod',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `WebOrder_ATOM_${formattedDate.split('/').join('')}_${order.id}_new.xml`
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectRebate = (id) => {
    if (selectedRebate === id) {
      setSelectedRebate(null);
    } else {
      setSelectedRebate(id);
    }
  };

  const handleUpdateRebate = async (newStatus) => {
    const rebateUpdate = {
      rebateId: selectedRebate,
      rebateStatus: newStatus,
    };

    try {
      const { data: newRebate } = await axios.put('/api/order/rebate', rebateUpdate);
      setRebates(rebates.map((r) => (r.id === selectedRebate ? newRebate : r)));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid container justifyContent="space-between" spacing={3}>
      <div id="pdf" className={classes.pdf}>
        {downloadOrders.length > 0
          ? downloadOrders.map((order, index) => (
              <PDF
                key={order.id}
                order={order}
                classes={classes}
                calculatePrice={calculatePrice}
                calculateTotalPrice={calculateTotalPrice}
                optionsByPage={order.optionsByPage}
                first={index === 0}
                hasNotes={order.notes.length > 0}
                bulkPrint
                brand={order.brand}
              />
            ))
          : null}
      </div>
      {loading ? (
        <Loading />
      ) : status === 'rebates' ? (
        <Grid item xs={12}>
          <RebatesTable
            rebates={rebates}
            selectedRebate={selectedRebate}
            handleSelectRebate={handleSelectRebate}
            role={userContext.userState.me.role}
            handleUpdateRebate={handleUpdateRebate}
            height={500}
            viewOrder={viewOrder}
            includeOrderId={true}
            externalReps={externalReps}
            selectedRep={selectedRep}
            setSelectedRep={setSelectedRep}
            setRebates={setRebates}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <EnhancedTable
            title={`Rep Orders ${status}`}
            data={orders}
            hiddenColumns={hiddenColumns}
            locations={[]}
            initLayoutInfo={initLayoutInfo}
            disableActionIcon
            viewOrder={status !== 'paper' ? viewOrder : null}
            openNotes={handleOpenNotes}
            enableFilters
            filterTypes={['name', 'model', 'floorplan', 'customerName']}
            bulkReturnToQuote={
              status !== 'quote' &&
              status !== 'paper' &&
              userContext.userState.me.permissions.indexOf('return_to_quote') !== -1
                ? bulkReturnToQuote
                : null
            }
            downloadOrders={status !== 'paper' ? startDownloadOrders : null}
            deleteOrder={
              userContext.userState.me.role <= 3 && status === 'quote'
                ? handleOpenDeleteOrder
                : null
            }
            handleDownloadXML={handleDownloadXML}
            externalReps={externalReps}
            selectedRep={selectedRep}
            setSelectedRep={setSelectedRep}
            dense
            initRowsPerPage={50}
            allowRetail={true}
          />
          <Dialog
            open={openConfirmationModal}
            onClose={handleCloseConfirmationModal}
            maxWidth="sm"
            fullWidth
            aria-labelledby="return-to-quote"
          >
            <DialogTitle>Return orders to quote</DialogTitle>
            <DialogContent>
              {submittingQuotes ? (
                <Loading text="Returning order(s) to quote" />
              ) : (
                <>
                  <Typography variant="body1" paragraph>
                    Are you sure you want to return {returnToQuoteOrders.length}{' '}
                    orders to quote?
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleReturnToQuote()}
                  >
                    Confirm
                  </Button>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={handleCloseConfirmationModal}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openNotes}
            onClose={handleCloseNotes}
            maxWidth="sm"
            fullWidth
            aria-labelledby="order-notes"
          >
            {selectedOrder !== null ? (
              <>
                <DialogTitle>Order #{selectedOrder.id} Notes</DialogTitle>
                <DialogContent>
                  {selectedOrder !== null
                    ? selectedOrder.notes.map((note) => {
                        return (
                          <Box className={classes.note} key={`note_${note.id}`}>
                            <Typography variant="body1">
                              By {note.user.name} at{' '}
                              {format(new Date(note.createdAt), 'MM/dd/yyyy')}
                            </Typography>
                            <Typography variant="h6">{note.text}</Typography>
                          </Box>
                        );
                      })
                    : null}
                  {['quote', 'submittednv', 'submittedv'].indexOf(status) !== -1 && (
                    <AddNote
                      selectedOrder={selectedOrder}
                      setSelectedOrder={setSelectedOrder}
                      orders={orders}
                      setOrders={setOrders}
                      closeAdd={handleCloseNotes}
                    />
                  )}
                </DialogContent>
              </>
            ) : null}
            <DialogActions>
              <Button onClick={handleCloseNotes} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openDeleteOrder}
            onClose={handleCloseDeleteOrder}
            maxWidth="sm"
            fullWidth
            aria-labelledby="delete-order"
          >
            <DialogTitle>Confirm Delete Order</DialogTitle>
            <DialogContent>
              <Typography variant="body1" paragraph>
                Are you sure you want to delete order #{orderToDelete}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => confirmDeleteOrder()}
              >
                Confirm
              </Button>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteOrder} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      )}
    </Grid>
  );
};

export default RepOrders;
