import React, { useState } from 'react';
import { TextField, Select, FormControl, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
  username: yup
    .string('Enter a username')
    .min(3, 'Username must be atleast 3 characters')
    .matches(/(^\S*$)/, { message: 'Username can not contain spaces' })
    .required('Username is required'),
  password1: yup
    .string('Enter your password')
    .required('Password is required')
    .min(8, 'Minimum length is 8')
    .max(64, 'Max length is 64 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
    ),
  password2: yup
    .string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password1 === value;
    }),
  name: yup.string("Enter the user's name").required('Name is required'),
  email: yup
    .string('Please enter a valid email')
    .email()
    .required('Email is required'),
  role: yup.string('Select a role').required('Role is requied'),
  dealershipId: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const AddUser = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const { dealershipId, dealership, setDealership, users, setUsers, closeAdd } =
    props;

  const formik = useFormik({
    initialValues: {
      username: '',
      password1: '',
      password2: '',
      name: '',
      email: '',
      role: '',
      dealershipId: dealershipId ? dealershipId : '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        let userData = { ...values };
        delete userData.password2;
        const { data } = await axios.post('/api/user', userData);

        if (dealershipId === undefined) {
          setUsers([...users, data.user]);
        } else {
          setDealership({ ...dealership, users: [...dealership.users, data.user] });
        }
        setSubmitError(null);
        resetForm();
        closeAdd();
      } catch (err) {
        setSubmitError(err.response.data.message);
      }
    },
  });

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      {submitError !== null ? (
        <h3 style={{ color: '#f44336', marginTop: '0px' }}>{submitError}</h3>
      ) : null}
      <FormControl fullWidth>
        <TextField
          id="username"
          name="username"
          label="Username"
          value={formik.values.username}
          onChange={formik.handleChange}
          className={classes.textField}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
          fullWidth
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="password1"
          name="password1"
          label="Password"
          value={formik.values.password1}
          onChange={formik.handleChange}
          className={classes.textField}
          error={formik.touched.password1 && Boolean(formik.errors.password1)}
          helperText={formik.touched.password1 && formik.errors.password1}
          fullWidth
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="password2"
          name="password2"
          label="Confirm Password"
          value={formik.values.password2}
          onChange={formik.handleChange}
          className={classes.textField}
          error={formik.touched.password2 && Boolean(formik.errors.password2)}
          helperText={formik.touched.password2 && formik.errors.password2}
          fullWidth
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="name"
          name="name"
          label="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          className={classes.textField}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          fullWidth
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          className={classes.textField}
          fullWidth
        />
      </FormControl>
      <FormControl fullWidth>
        {dealershipId ? (
          <Select
            id="role"
            name="role"
            label="Role"
            value={formik.values.role}
            onChange={formik.handleChange}
            className={classes.textField}
            fullWidth
            native
          >
            <option value="">Select Role</option>
            <option value="10">Manager</option>
            <option value="11">Sales</option>
            <option value="13">Accounting</option>
          </Select>
        ) : (
          <Select
            id="role"
            name="role"
            label="Role"
            value={formik.values.role}
            onChange={formik.handleChange}
            className={classes.textField}
            fullWidth
            native
          >
            <option value="">Select Role</option>
            <option value="1">Admin</option>
            <option value="2">Sales Manager</option>
            <option value="3">Sales Rep</option>
          </Select>
        )}
      </FormControl>
      <TextField
        id="dealershipId"
        name="dealershipId"
        label="dealershipId"
        value={formik.values.dealershipId}
        style={{ display: 'none' }}
      />
      <Button color="primary" variant="contained" fullWidth type="submit">
        Submit
      </Button>
    </form>
  );
};

export default AddUser;
