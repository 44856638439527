import { useState } from 'react';
import axios from 'axios';
import {
  TextField,
  Autocomplete,
  Box,
  MenuItem,
  Typography,
  FormControl,
  Button,
} from '@mui/material';

const EditOrderDealership = (props) => {
  const { dealerships, orderId, currentDealership } = props;
  const [selectedDealership, setSelectedDealership] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  if (!dealerships || dealerships.length === 0) {
    return (
      <Box>
        <Typography variant="h4">No available dealerships</Typography>
      </Box>
    );
  }

  const handleChangeDealership = async () => {
    try {
      let data = {
        dealershipId: selectedDealership.id,
        locationId: selectedLocation.id,
        orderId: orderId,
        message: `Changed from ${currentDealership} to ${selectedDealership.name}`,
      };
      await axios.put('/api/order/dealership', data);
      window.location.replace(
        `/dealerships/${selectedDealership.id}/locations/all/current/complete/${orderId}`
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      <FormControl fullWidth>
        <Autocomplete
          id="dealership-search"
          value={selectedDealership}
          onChange={(event, newValue) => {
            setSelectedDealership(newValue);
            setSelectedLocation(null);
          }}
          isOptionEqualToValue={(e, v) => {
            return e.id === v.id;
          }}
          options={dealerships}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} label="Dealerships" variant="outlined" />
          )}
          renderOption={(props, option) => {
            return (
              <MenuItem {...props} key={option.id}>
                {option.name}
              </MenuItem>
            );
          }}
          sx={{ my: 2 }}
        />
      </FormControl>

      <FormControl fullWidth>
        <Autocomplete
          id="location-search"
          value={selectedLocation}
          onChange={(event, newValue) => {
            setSelectedLocation(newValue);
          }}
          isOptionEqualToValue={(e, v) => {
            return e.id === v.id;
          }}
          options={selectedDealership ? selectedDealership.locations : []}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} label="Locations" variant="outlined" />
          )}
          renderOption={(props, option) => {
            return (
              <MenuItem {...props} key={option.id}>
                {option.name}
              </MenuItem>
            );
          }}
          sx={{ my: 2 }}
          disabled={!selectedDealership}
        />
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={handleChangeDealership}
        disabled={!selectedLocation}
      >
        Change Dealership
      </Button>
    </Box>
  );
};

export default EditOrderDealership;
