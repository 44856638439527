import React, { useState } from 'react';
import { TextField, FormControl, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
  erpNumber: yup.string().required(),
  orderId: yup.number().required(),
});

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const EditERP = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const { closeAdd, orders, setOrders, order } = props;

  const formik = useFormik({
    initialValues: {
      erpNumber: order.erpNumber,
      orderId: order.id,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        console.log(values);
        const { data } = await axios.put('/api/order/erp', values);
        console.log(data.message);
        setOrders(
          orders.map((o) => {
            return o.id === order.id ? { ...o, erpNumber: values.erpNumber } : o;
          })
        );
        setSubmitError(null);
        resetForm();
        closeAdd();
      } catch (err) {
        setSubmitError(err.response.data.message);
      }
    },
  });

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      {submitError !== null ? (
        <h3 style={{ color: '#f44336', marginTop: '0px' }}>{submitError}</h3>
      ) : null}
      <FormControl fullWidth>
        <TextField
          id="erpNumber"
          name="erpNumber"
          label="ERP Number"
          value={formik.values.erpNumber}
          onChange={formik.handleChange}
          className={classes.textField}
          error={formik.touched.erpNumber && Boolean(formik.errors.erpNumber)}
          helperText={formik.touched.erpNumber && formik.errors.erpNumber}
          fullWidth
        />
      </FormControl>
      <Button color="primary" variant="contained" fullWidth type="submit">
        Submit
      </Button>
    </form>
  );
};

export default EditERP;
