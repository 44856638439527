import { Grid, Typography } from '@mui/material';

const CSVErrors = ({ csv, isLoading }) => {
  if (isLoading) {
    return null;
  }
  if (csv?.ruleErrors === undefined) return null;
  if (csv?.ruleErrors?.length > 0) {
    return (
      <Grid item xs={12}>
        <Typography variant="h6">CSV Errors</Typography>

        {csv.ruleErrors.map((error, index) => (
          <Typography
            variant="body1"
            sx={{ color: 'red' }}
            key={index}
          >{`INPUT ${error.input} - BAD VALUE ${error.value}`}</Typography>
        ))}
      </Grid>
    );
  }

  return null;
};

export default CSVErrors;
