import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  IconButton,
  Box,
  InputAdornment,
  TextField,
} from '@mui/material';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SearchIcon from '@mui/icons-material/Search';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const Dropdown = (props) => {
  const {
    input,
    setInput,
    activeRule,
    handleActiveRule,
    disabledRule,
    handleDisabledRule,
    renderActiveButtonText,
    inputId,
    yValue,
    zId,
    classes,
  } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState(input.inputOptions);

  useEffect(() => {
    const timer = setTimeout(() => {
      let tmpData = [...input.inputOptions];
      let filteredData = [];
      let columns = tmpData.length !== 0 ? Object.keys(tmpData[0]) : [];
      columns = columns.filter((c) => {
        return true;
      });

      for (let d of tmpData) {
        let addFlag = false;
        for (let c of columns) {
          let columnString = d[c];
          let string = String(columnString).toLowerCase();
          if (string.includes(searchText.toLowerCase())) addFlag = true;
        }

        if (addFlag) filteredData.push(d);
      }

      setRows(filteredData);
    }, 100);

    return () => clearTimeout(timer);
  }, [searchText, input]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - input.inputOptions.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderActiveButton = (option) => {
    let activeRule = option.rules.filter((rule) => rule.changeType === 'ACTIVE');
    if (activeRule.length === 0) {
      if (option.active) {
        return (
          <IconButton aria-label="active" color="primary">
            <CheckBoxIcon />
          </IconButton>
        );
      } else {
        return (
          <IconButton aria-label="active" color="primary">
            <CheckBoxOutlineBlankIcon />
          </IconButton>
        );
      }
    } else {
      if (activeRule[0]?.changeValue === 1) {
        return (
          <IconButton aria-label="active" color="secondary">
            <CheckBoxIcon />
          </IconButton>
        );
      }

      if (activeRule[0]?.changeValue === 0) {
        return (
          <IconButton aria-label="active" color="secondary">
            <CheckBoxOutlineBlankIcon />
          </IconButton>
        );
      }

      if (activeRule[0]?.changeValue === '') {
        return (
          <IconButton aria-label="active" color="primary">
            {option.active ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          </IconButton>
        );
      }
    }
  };

  const renderDefaultButton = (option) => {
    let defaultRule = option.rules.filter((rule) => rule.changeType === 'DEFAULT');
    if (defaultRule.length === 0) {
      return (
        <Button variant="text" size="small" color="primary">
          {option.avalonName}
        </Button>
      );
    } else {
      return (
        <Button
          variant="text"
          size="small"
          color={defaultRule[0]?.changeValue === 1 ? 'secondary' : 'primary'}
        >
          {option.avalonName}
        </Button>
      );
    }
  };

  const renderPriceInput = (option) => {
    let priceRule = option.rules.filter((rule) => rule.changeType === 'PRICE')[0];
    if (priceRule === undefined) {
      return (
        <TextField
          label="Price"
          id={`input-price-${option.id}`}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          size="small"
          placeholder={`${option.price}`}
          sx={{ width: '25ch' }}
        />
      );
    } else {
      return (
        <TextField
          label="Price"
          id={`input-price-${option.id}`}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          size="small"
          placeholder={`${option.price}`}
          value={priceRule.changeValue}
          className={priceRule.changeValue !== '' ? classes.highlightedRule : ''}
          sx={{ width: '25ch' }}
        />
      );
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Button variant="contained" color={'primary'}>
          {renderActiveButtonText(input)}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Box component="div">
          <TextField
            id="option-search"
            label="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            size="small"
            sx={{ width: '45ch' }}
          />
        </Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 70 }}>Active</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : rows
              ).map((io) => (
                <TableRow
                  key={io.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell padding="checkbox">{renderActiveButton(io)}</TableCell>
                  <TableCell>{renderDefaultButton(io)}</TableCell>
                  <TableCell> {renderPriceInput(io)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={4}
                  count={input.inputOptions.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default Dropdown;
