import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';

const DealershipLookup = () => {
  const [dealerships, setDealerships] = useState([]);
  const [selectedDealership, setSelectedDealership] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getDealerships = async () => {
      try {
        const { data } = await axios.get('/api/dealers/all');
        setDealerships(data);
      } catch (err) {
        console.log(err);
      }
    };
    getDealerships();
  }, []);

  return (
    <Autocomplete
      id="dealership-search"
      value={selectedDealership}
      onChange={(event, newValue) => {
        setSelectedDealership(newValue);
        navigate(`/dealerships/${newValue.id}/locations/all/current/quote`);
      }}
      isOptionEqualToValue={(e, v) => {
        return e.id === v.id;
      }}
      options={dealerships}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField {...params} size="small" label="Dealerships" variant="outlined" />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
    />
  );
};

export default DealershipLookup;
