import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Collapse, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '10px',
    padding: theme.spacing(0, 2),
    border: 'solid 1px #d1d1d1',
    borderRadius: '12px',
    backgroundColor: '#FFF',
  },
  handler: {
    paddingLeft: 0,
    paddingRight: 0,
    cursor: 'pointer',
  },
  expandedHandlerIcon: {
    minWidth: '25px',
    '& svg': {
      color: '#006991',
    },
  },
  title: {
    '& span': {
      width: '100%',
      fontSize: '1.25rem',
      fontWeight: 'bold',
    },
  },
}));

const Accordion = (props) => {
  const { title, isExpanded, children } = props;
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setExpanded(!!isExpanded);
  }, []);

  return (
    <div className={classes.root}>
      <ListItem className={classes.handler} onClick={() => setExpanded(!expanded)}>
        <ListItemText className={classes.title} primary={title} />
        <ListItemIcon className={classes.expandedHandlerIcon}>
          {expanded ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />}
        </ListItemIcon>
      </ListItem>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        sx={{ marginBottom: '7px' }}
      >
        {children}
      </Collapse>
    </div>
  );
};

export default Accordion;
