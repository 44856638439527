import React, { useState } from 'react';
import {
  TextField,
  FormControl,
  Button,
  FormControlLabel,
  Switch as Toggle,
  Select,
  MenuItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Loading from '../Loading';

const validationSchema = yup.object({
  password1: yup
    .string('Enter your password')
    .min(8, 'Minimum length is 8')
    .max(64, 'Max length is 64 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
    ),
  password2: yup
    .string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password1 === value;
    }),
  name: yup.string("Enter the user's name").required('Name is required'),
  email: yup
    .string('Please enter a valid email')
    .email()
    .required('Email is required'),
  id: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const EditUser = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const { user, dealership, setDealership, closeEdit, type, users, setUsers } =
    props;
  const [updating, setUpdating] = useState(false);

  let initialValues = {
    password1: user.password,
    password2: user.password,
    name: user.name,
    email: user.email,
    id: user.id,
  };

  if (users !== undefined || dealership !== undefined) {
    // initialValues.role = user.role
    initialValues.active = user.active;
    initialValues.role = user.role;
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setUpdating(true);
        let userData = { ...values };
        delete userData.password2;
        if (type !== undefined && type === 'self') {
          try {
            await axios.put('/api/user/self', userData);
            setSubmitError(null);
            setUpdating(false);
          } catch (err) {
            setSubmitError(err.message);
            setUpdating(false);
          }
        } else {
          if (values.role !== undefined) values.role = parseInt(values.role, 10);
          await axios.put('/api/user', userData);

          if (dealership !== undefined) {
            let updatedUsers = dealership.users.map((u) => {
              if (u.id === user.id) {
                u.password = values.password1;
                u.name = values.name;
                u.email = values.email;
                u.active = values.active;
                u.role = values.role;
              }
              return u;
            });

            setDealership({ ...dealership, users: updatedUsers });
          }

          if (users !== undefined) {
            setUsers(
              users.map((u) => {
                if (u.id === user.id) {
                  return {
                    ...u,
                    password: values.password1,
                    name: values.name,
                    email: values.email,
                    active: values.active,
                  };
                } else {
                  return u;
                }
              })
            );
          }

          setSubmitError(null);
          resetForm();
          setUpdating(false);
          closeEdit(
            {
              ...user,
              password: values.password1,
              name: values.name,
              email: values.email,
              active: values.active,
            },
            true
          );
        }
      } catch (err) {
        setSubmitError(err.message);
      }
    },
  });

  const readableRole = (role) => {
    switch (parseInt(role, 10)) {
      case 10:
        return 'Manager';
      case 11:
        return 'Sales';
      case 1:
        return 'Admin';
      case 2:
        return 'Sales Manager';
      case 3:
        return 'Sales Rep';
      default:
        return 'User';
    }
  };

  return (
    <>
      {updating ? (
        <Loading text="Saving..." />
      ) : (
        <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          {submitError !== null ? (
            <h3 style={{ color: '#f44336', marginTop: '0px' }}>{submitError}</h3>
          ) : null}
          <h4 style={{ marginTop: '0px' }}>
            {user.username} - {readableRole(user.role)}
          </h4>
          <FormControl fullWidth>
            <TextField
              id="name"
              name="name"
              label="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              className={classes.textField}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              className={classes.textField}
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              id="password1"
              name="password1"
              label="Password"
              value={formik.values.password1}
              onChange={formik.handleChange}
              className={classes.textField}
              error={formik.touched.password1 && Boolean(formik.errors.password1)}
              helperText={formik.touched.password1 && formik.errors.password1}
              fullWidth
              type="password"
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              id="password2"
              name="password2"
              label="Confirm Password"
              value={formik.values.password2}
              onChange={formik.handleChange}
              className={classes.textField}
              error={formik.touched.password2 && Boolean(formik.errors.password2)}
              helperText={formik.touched.password2 && formik.errors.password2}
              fullWidth
              type="password"
            />
          </FormControl>
          {dealership !== undefined ? (
            <FormControl fullWidth>
              <Select
                id="role"
                name="role"
                label="Role"
                value={formik.values.role}
                onChange={formik.handleChange}
                className={classes.textField}
                fullWidth
              >
                <MenuItem value="10">Manager</MenuItem>
                <MenuItem value="11">Sales</MenuItem>
                <MenuItem value="13">Accounting</MenuItem>
              </Select>
            </FormControl>
          ) : null}
          {users !== undefined || dealership !== undefined ? (
            <FormControlLabel
              control={
                <Toggle
                  checked={formik.values.active}
                  onChange={formik.handleChange}
                  id="active"
                  name="active"
                />
              }
              label="Active"
            />
          ) : null}
          <Button color="primary" variant="contained" fullWidth type="submit">
            Update
          </Button>
        </form>
      )}
    </>
  );
};

export default EditUser;
