import { Box, Typography, Card, CardContent, Grid } from '@mui/material';

const CustomerInfo = (props) => {
  const { name, email, phone } = props;

  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          Customer Info
        </Typography>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item sm={12}>
            <Box>
              <Typography variant="body1">Name</Typography>
              <Typography variant="h6">{name ? name : 'N/A'}</Typography>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box>
              <Typography variant="body1">Email</Typography>
              <Typography variant="h6">{email ? email : 'N/A'}</Typography>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box>
              <Typography variant="body1">Phone</Typography>
              <Typography variant="h6">{phone ? phone : 'N/A'}</Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CustomerInfo;
