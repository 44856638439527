import React, { useState, useEffect, useContext } from 'react';
import {
  useParams,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import clsx from 'clsx';
import axios from 'axios';
import { format, formatISO } from 'date-fns';
import html2pdf from 'html2pdf.js';
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Link,
  Breadcrumbs,
  Menu,
  MenuItem,
  useMediaQuery,
  Box,
  TextField,
  FormControl,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/material/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import Odometer from 'react-odometerjs';
import Loading from '../../Loading';
import Page from './Page';
import OrderFormHeroImage from './OrderFormHeroImage';
import Summary from './Summary';
import { UserContext } from '../../../userContext';
import CustomerPDF from './CustomerPDF';
import 'odometer/themes/odometer-theme-default.css';
import AddNote from '../../forms/AddNote';
import theme from '../../../theme';
import { formatPrice, orderStatus, parseBrandName } from '../../../lib';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 150px)',
    marginTop: '20px',
  },
  mobileRoot: {
    minHeight: 'unset',
    marginTop: 0,
  },
  mobileBreadcrumbs: {
    '& .MuiBreadcrumbs-ol': {
      width: 'calc(100vw - 80px)',
      flexWrap: 'nowrap',
      overflowX: 'hidden',
    },
  },
  pagePaper: {
    padding: theme.spacing(2, 0, 0, 0),
  },
  inputs: {
    padding: theme.spacing(2),
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  orderTitle: {
    marginBottom: theme.spacing(1),
  },
  heroImageRoot: {
    height: '75vh',
  },
  pagePadding: {
    paddingTop: '0px!important',
    '@media screen and (max-height: 400px) and (orientation:landscape)': {
      marginTop: '20px',
    },
  },
  nextButton: {
    height: '45px',
    fontWeight: '600',
    borderRadius: '12px',
    '@media screen and (max-height: 400px) and (orientation:landscape)': {
      marginBottom: '20px',
    },
  },
  secondButton: {
    marginTop: '10px',
    height: '45px',
    fontWeight: '600',
    borderRadius: '12px',
  },
  nextButtonText: {
    lineHeight: '40px',
    color: '#FFF',
  },
  titleDivider: {
    marginBottom: theme.spacing(2),
  },
  mobileTitle: {
    fontSize: '1.5rem',
  },
  mobileFloorType: {
    fontSize: '14px',
  },
  heroImageContainer: {
    maxHeight: '265px',
    marginTop: '80px',
    '@media screen and (max-height: 400px) and (orientation:landscape)': {
      marginTop: '10px',
    },
  },
  heroImageViewer: {
    position: 'relative',
  },
  prevImageBtn: {
    position: 'absolute',
    margin: 'auto',
    top: '0',
    left: '25px',
    bottom: '0',
    cursor: 'pointer',
    zIndex: '10',
  },
  nextImageBtn: {
    position: 'absolute',
    margin: 'auto',
    top: '0',
    right: '25px',
    bottom: '0',
    cursor: 'pointer',
    zIndex: '10',
  },
  pageNavGrid: {
    padding: '8px 0px 8px 0px!important',
  },
  tooltipImage: {
    display: 'block',
    maxWidth: '60%',
    margin: 'auto',
    [theme.breakpoints.down('xl')]: {
      maxWidth: '80%',
    },
    [theme.breakpoints.down('xl')]: {
      maxWidth: '100%',
    },
  },
  tooltipNav: {
    textAlign: 'center',
  },
  tooltipNavButton: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  tooltipVideo: {
    display: 'block',
    width: '80%',
    maxWidth: '560px',
    height: '315px',
    margin: 'auto',
    [theme.breakpoints.down('xl')]: {
      width: '100%',
    },
  },
  downloadButton: {
    width: '50%',
    borderRadius: '0',
  },
  orderTitle: {
    fontWeight: '400',
    '& div h3 div': {
      paddingBottom: '3px',
    },
  },
  boatTitle: {
    fontWeight: '500',
  },
  floorplanImage: {
    width: '100%',
    maxWidth: '250px',
    marginTop: theme.spacing(2),
    ['@media print']: {
      display: 'none',
    },
  },
  floorplanImageAlt: {
    width: '100%',
    maxWidth: '250px',
    marginTop: theme.spacing(1),
    ['@media print']: {
      display: 'block',
      margin: 'auto',
    },
  },
  heroImageParent: {
    height: '180px',
    [theme.breakpoints.down('xl')]: {
      maxHeight: '140px',
    },
    ['@media print']: {
      display: 'block',
    },
  },
  heroImageParentAlt: {
    height: '140px',
    display: 'block',
    [theme.breakpoints.down('xl')]: {
      maxHeight: '140px',
    },
  },
  summary: {
    paddingTop: '0px!important',
    marginTop: '8px',
    borderRight: `1px solid ${theme.palette.text.disabled}`,
    [theme.breakpoints.down('xl')]: {
      borderRight: 'none',
    },
  },
  summaryTitle: {
    fontWeight: '500',
  },
  summaryText: {
    paddingBottom: '6px!important',
  },
  page: {
    marginBottom: theme.spacing(1),
  },
  order: {
    ['@media print']: {
      display: 'none',
    },
  },
  pdf: {
    display: 'none',
    ['@media print']: {
      display: 'block',
      position: 'absolute',
      top: '15px',
      left: '15px',
    },
  },
  optionsTable: {
    width: '100%',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    '& thead tr th': {
      padding: theme.spacing(1.25, 2),
    },
    '& tbody tr td': {
      padding: theme.spacing(1.25, 2),
    },
    '& tbody tr:nth-child(odd)': {
      background: '#F8F8F8',
      borderRadius: '7px',
    },
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  pdfHeader: {
    fontWeight: 'bold',
    marginBottom: '6px',
  },
  orderInfo: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  customerInfo: {
    marginBottom: theme.spacing(1),
  },
  hidePrint: {
    ['@media print']: {
      display: 'none',
    },
  },
  link: {
    opacity: '0.5',
    whiteSpace: 'nowrap',
  },
  currentLink: {
    textDecoration: 'underline',
    textDecorationColor: '#006991',
    textDecorationThickness: '3px',
    whiteSpace: 'nowrap',
  },
  mobileActions: {
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(2.5),
  },
  mobileImageGrid: {
    '@media screen and (max-height: 400px) and (orientation:landscape)': {
      paddingLeft: '4px!important',
    },
  },
}));

const SecondButton = styled(Button)(({ theme }) => ({
  color: '#171A20',
  backgroundColor: '#F8F8F8',
  '&:hover': {
    backgroundColor: '#F8F8F8',
  },
}));

const Pages = (props) => {
  const {
    orderId,
    modelId,
    floorplanId,
    pageId,
    formType,
    brand,
    dealershipId,
    locationId,
  } = useParams();
  const [
    models,
    tags,
    queryTags,
    customerBuild,
    priceFilter,
    lengthFilter,
    handleChangeLengthFilter,
    handleChangePriceFilter,
    hasAvailableFloorplans,
    floorplans,
    price,
    setPrice,
    customerOrderForm,
    showHidden,
    markup,
    setMarkup,
    modelMarkups,
    useModelMarkups,
    calculatePriceWithMarkup,
    setModelMarkups,
    setUseModelMarkups,
    priceMarkupFilter,
    calculateTotalPrice,
  ] = useOutletContext();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const [pages, setPages] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedFloorplan, setSelectedFloorplan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [inputs, setInputs] = useState([]);
  const [ruleGroups, setRuleGroups] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const classes = useStyles();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [submittingOrder, setSubmittingOrder] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [tooltip, setTooltip] = useState(null);
  const [oldOrder, setOldOrder] = useState({ orderStatus: 0, options: [] });
  const [customerInfo, setCustomerInfo] = useState({
    customerName: '',
    customerEmail: '',
    customerPhone: '',
    customerZipcode: '',
    po: '',
    location: null,
    selectedLocation: null,
  });
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [openCustomerSubmit, setOpenCustomerSubmit] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [mobileBreadcrumbs, setMobileBreadcrumbs] = useState([]);
  const [prevBreadcrumbs, setPrevBreadcrumbs] = useState([]);
  const [selectedImage, setSelectedImage] = useState(1);
  const [isSubmit, setIsSubmit] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userLocation, setUserLocation] = useState('');
  const [orderNote, setOrderNote] = useState('');
  const [openNotes, setOpenNotes] = useState(false);
  const [userLocations, setUserLocations] = useState(null);
  const role = userContext.userState.me.isAuthenticated
    ? userContext.userState.me.role
    : null;
  const [searchParams, setSearchParams] = useSearchParams();
  const [modelConfirmation, setModelConfirmation] = useState({
    my_confirmation: false,
    ModelNum: '',
    action: '',
    confirmed: false,
  });
  const [openMYConfirmation, setOpenMYConfirmation] = useState(false);
  const canEditLockedInputs = userContext.userState.me.isAuthenticated
    ? userContext.userState.me.permissions.indexOf('edit_locked_inputs') > -1
    : false;
  const canEditDiscounts = userContext.userState.me.isAuthenticated
    ? userContext.userState.me.permissions.indexOf('edit_discounts') > -1
    : false;

  if (pageId === '6' && pages.length === 0) {
    let redirectUrl = `/order/${orderId}/${formType}/${brand}/model/${modelId}/floorplan/${floorplanId}/page/2`;
    if (showHidden) redirectUrl += '?debug=true';
    navigate(redirectUrl);
  }

  const groupByKey = (list, key) =>
    list.reduce(
      (hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }),
      {}
    );

  useEffect(() => {
    const loadInputs = async () => {
      let order = null;
      let initEditOrder = false;
      if (orderId !== 'new') {
        try {
          const { data } = await axios.get('/api/order', {
            params: { id: orderId },
          });
          order = data;
          order.options = order.options.filter(
            (o) => o.lastVersion === order.orderVersion
          );
          setOldOrder(order);
          setUserLocation(order.locationId);
          setCustomerInfo({
            customerName: order.customerName !== null ? order.customerName : '',
            customerEmail: order.customerEmail !== null ? order.customerEmail : '',
            customerPhone: order.customerPhone !== null ? order.customerPhone : '',
            po: order.po !== null ? order.po : '',
          });
          setMarkup(order.location.markup);
          setModelMarkups(order.location.modelMarkups);
          setUseModelMarkups(order.location.useModelMarkup);
          // console.log(order.notes.filter((n) => n.pageId === null));
          initEditOrder = true;
        } catch (err) {
          console.log(err.response.data.message);
        }
      } else {
        // console.log('SETTING LOCATION ID: ', locationId);
        if (locationId !== undefined) setUserLocation(parseInt(locationId, 10));
      }

      let sm = models.inputOptions.find((io) => io.id === parseInt(modelId, 10));
      setSelectedModel(sm);

      let sfp = sm.floorplans.find(
        (io) => io.floorplan.id === parseInt(floorplanId, 10)
      );
      sfp.floorplan.defaultPrice = sfp.floorplan.price;

      const { data } = await axios.get('/api/form/pages', {
        params: {
          ruleGroupId: sfp.ruleGroupId,
          modelId: modelId,
          formType: formType,
        },
      });

      // Add confirmation override for customer form
      if (formType === 'c') {
        setModelConfirmation({ ...data.modelConfirmation, confirmed: true });
      } else {
        setModelConfirmation(data.modelConfirmation);
      }

      // console.log(sm)
      // console.log("SM RULE GROUPS: ", models.conditionsX.filter(cx => cx.yValue === sm.id))
      // console.log(floorplans)
      // console.log(data)

      // In event of reload or starting at options, reset image and price rules
      let mfRules = models.rulesX.filter((r) => r.yValue === sm.id);
      for (let fpRule of mfRules) {
        let foundOption = sm.floorplans.find(
          (fp) => fp.floorplan.id === fpRule.zDropdownId
        );
        if (
          foundOption !== undefined &&
          foundOption.floorplan.id === parseInt(floorplanId, 10)
        ) {
          switch (fpRule.changeType) {
            case 'IMGPATH':
              foundOption.floorplan.imgPath = fpRule.changeValue;
              foundOption.floorplan.rules = {
                ...foundOption.rules,
                imgPath: fpRule,
              };
              break;
            case 'PRICE':
              foundOption.floorplan.price = parseInt(fpRule.changeValue, 10);
              foundOption.floorplan.rules = { ...foundOption.rules, price: fpRule };
              break;
          }
        }
      }

      let initPrice = sfp.floorplan.price;

      // order rule groups by conditions
      for (let i = 0; i < data.ruleGroups.length; i++) {
        let currentRG = data.ruleGroups[i];
        let result = groupByKey(currentRG.conditions, 'conditionOrder');
        currentRG.conditionsByOrder = result;
        currentRG.active = {};
        currentRG.isActive = false;

        Object.keys(currentRG.conditionsByOrder).map((order) => {
          currentRG.active[order] = false;
        });
      }

      // initiate model rgs
      for (let rg of models.conditionsX.filter((cx) => cx.yValue === sm.id)) {
        let foundRG = data.ruleGroups.find(
          (ruleGroup) => ruleGroup.id === rg.ruleGroupId
        );
        if (foundRG !== undefined) {
          // found rule group
          Object.entries(foundRG.conditionsByOrder).map(([key, value]) => {
            if (value[0].xId === 1) {
              // check if current model is a condition
              if (value.find((v) => v.yValue === sm.id)) {
                foundRG.active[key] = true;
                // console.log(foundRG)
              }
            }
          });
        }
      }

      // initiate fp rgs
      for (let rg of floorplans.conditionsX.filter(
        (cx) => cx.yValue === sfp.floorplan.id
      )) {
        let foundRG = data.ruleGroups.find(
          (ruleGroup) => ruleGroup.id === rg.ruleGroupId
        );
        if (foundRG !== undefined) {
          // found rule group
          Object.entries(foundRG.conditionsByOrder).map(([key, value]) => {
            if (value[0].xId === 2) {
              // check if current model is a condition
              if (value.find((v) => v.yValue === sfp.floorplan.id)) {
                foundRG.active[key] = true;
              }
            }
          });
        }
      }

      let csvRules = data.csvRules.rules;
      let pages = showHidden
        ? data.pages
        : data.pages.filter((p) => p.name !== 'HIDDEN');
      let ruleGroups = data.ruleGroups;
      let modelRules = data.modelRules;
      let inputs = data.inputs;

      if (order !== null) {
        for (let input of inputs) {
          let orderOption = order.options.find((i) => i.inputId === input.id);
          if (orderOption !== undefined) {
            input.value =
              input.type === 'DROPDOWN'
                ? orderOption.inputOptionId !== undefined
                  ? orderOption.inputOptionId
                  : ''
                : true;
            if (input.type === 'DROPDOWN' && input.value === null) input.value = '';
            if (input.type === 'CHECKBOX') input.checked = true;
            input.orderDefaultValue = input.value;
            input.orderOptionId = orderOption.id;
            input.orderOptionPrice = orderOption.price;
            // input.price = orderOption.price;
            if (input.type === 'TEXT') {
              input.value = orderOption.inputTextValue;
              input.orderOptionTextValue = orderOption.inputTextValue;
            }
            if (input.rulesX.length > 0) {
              if (input.type === 'CHECKBOX') {
                for (let rule of input.rulesX.filter((r) => r.yValue === 1)) {
                  if (rule.zId === 2 && rule.zDropdownId === sfp.floorplan.id) {
                    sfp = {
                      ...sfp,
                      floorplan: {
                        ...sfp.floorplan,
                        price: +rule.changeValue,
                        rules: {
                          ...sfp.floorplan.rules,
                          price: rule,
                        },
                      },
                    };
                  }
                  let affectedInput = inputs.find((i) => i.id === rule.zId);
                  if (affectedInput !== undefined) {
                    let tmpAffectedInput = applyRule(
                      affectedInput,
                      rule,
                      true,
                      false,
                      initEditOrder
                    );
                    affectedInput = Object.assign(affectedInput, tmpAffectedInput);
                  }
                }
              }

              if (input.type === 'DROPDOWN') {
                let currentTmpInputRules = input.rulesX.filter(
                  (r) => r.yValue === input.value
                );
                if (
                  currentTmpInputRules !== undefined &&
                  currentTmpInputRules.length > 0
                ) {
                  for (let rule of currentTmpInputRules) {
                    let affectedInput = inputs.find((i) => i.id === rule.zId);
                    if (affectedInput !== undefined) {
                      let tmpAffectedInput = applyRule(
                        affectedInput,
                        rule,
                        true,
                        false,
                        initEditOrder
                      );
                      affectedInput = Object.assign(affectedInput, tmpAffectedInput);
                    }
                  }
                }
              }
            }
          } else {
            switch (input.type) {
              case 'DRODPOWN':
                input.value = '';
                break;
              case 'CHECKBOX':
                input.value = false;
                break;
              case 'TEXT':
                input.value = '';
                break;
            }
          }
        }
      }

      // csv rules should only need to be applied at the start
      for (let input of inputs) {
        let tmpInput = { ...input };
        if (!tmpInput.rules) tmpInput.rules = {};
        tmpInput.touched = false;

        if (tmpInput.value === undefined) {
          switch (input.type) {
            case 'DROPDOWN':
              input.value = '';
              break;
            case 'CHECKBOX':
              input.value = false;
              break;
            case 'TEXT':
              input.value = '';
              break;
          }
        }

        // search model rules
        let modelInputRules = modelRules.filter((r) => r.zId === tmpInput.id);
        if (modelInputRules.length !== 0) {
          for (let rule of modelInputRules) {
            tmpInput = applyRule(tmpInput, rule, true, false, initEditOrder);
          }
        }

        // find csv rules
        let csvInputRules = csvRules.filter(
          (r) => r.zId === tmpInput.id && r.ruleGroupId === csvRules[0].ruleGroupId
        );

        if (csvInputRules.length !== 0 && !initEditOrder) {
          for (let rule of csvInputRules) {
            tmpInput = applyRule(tmpInput, rule, true, true, initEditOrder);
          }
        }

        if (csvInputRules.length !== 0 && initEditOrder) {
          for (let rule of csvInputRules) {
            if (
              input.type === 'TEXT' &&
              rule.changeType === 'DEFAULT' &&
              input.value !== rule.changeValue
            ) {
              console.log(
                `EDITING ORDER, SKIPPING DEFAULT FOR TEXT FIELD: ${input.name}, CURRENT VAL: ${input.value}, CSV DEFAULT: ${rule.changeValue}`
              );
            } else {
              // only apply csv rules to non selected options
              if (tmpInput.value !== rule.zDropdownId) {
                tmpInput = applyRule(tmpInput, rule, true, true, initEditOrder);
              }
              if (
                tmpInput.value === rule.zDropdownId &&
                rule.changeType !== 'ACTIVE'
              ) {
                tmpInput = applyRule(tmpInput, rule, true, true, initEditOrder);
              }
              if (rule.changeType === 'PRICE')
                tmpInput.defaultPrice = rule.changeValue;
            }
          }
        }

        // initiate unselected input rules
        if (tmpInput.rulesX.length > 0) {
          if (tmpInput.type === 'DROPDOWN') {
            let currentValue =
              tmpInput.value === '' || tmpInput.value === undefined
                ? 0
                : tmpInput.value;
            if (currentValue === undefined)
              console.log('ERROR: ', tmpInput.value, tmpInput);
            let currentTmpInputRules = tmpInput.rulesX.filter(
              (r) => r.yValue === currentValue
            );
            if (
              currentTmpInputRules !== undefined &&
              currentTmpInputRules.length > 0
            ) {
              for (let rule of currentTmpInputRules) {
                let affectedInput = inputs.find((i) => i.id === rule.zId);
                if (affectedInput !== undefined) {
                  let tmpAffectedInput = applyRule(
                    affectedInput,
                    rule,
                    true,
                    false,
                    initEditOrder
                  );
                  affectedInput = Object.assign(affectedInput, tmpAffectedInput);
                }
                // if (tmpInput.id === 16) console.log(affectedInput, rule)
              }
            }
          }

          if (tmpInput.type === 'CHECKBOX') {
            let currentValue = tmpInput.value === true ? 1 : 0;
            let currentTmpInputRules = tmpInput.rulesX.filter(
              (r) => r.yValue === currentValue
            );
            if (
              currentTmpInputRules !== undefined &&
              currentTmpInputRules.length > 0
            ) {
              for (let rule of currentTmpInputRules) {
                let affectedInput = inputs.find((i) => i.id === rule.zId);
                if (affectedInput !== undefined) {
                  let tmpAffectedInput = applyRule(
                    affectedInput,
                    rule,
                    true,
                    false,
                    initEditOrder
                  );
                  affectedInput = Object.assign(affectedInput, tmpAffectedInput);
                }
              }
            }
          }
        }

        if (tmpInput.conditionsX.length > 0) {
          let updatedRuleGroups = [...ruleGroups];
          let currentValue =
            tmpInput.type === 'DROPDOWN'
              ? tmpInput.value === ''
                ? 0
                : tmpInput.value
              : tmpInput.value === true
              ? 1
              : 0;

          let newRuleGroups = tmpInput.conditionsX.filter(
            (cx) => cx.yValue === currentValue
          );
          if (newRuleGroups.length > 0) {
            for (let rg of newRuleGroups) {
              let foundRG = updatedRuleGroups.find(
                (ruleGroup) => ruleGroup.id === rg.ruleGroupId
              );
              if (foundRG !== undefined) {
                // found rule group
                Object.entries(foundRG.conditionsByOrder).map(([key, v]) => {
                  if (v[0].xId === tmpInput.id) {
                    if (v.find((vt) => vt.yValue === currentValue)) {
                      foundRG.active[key] = true;
                      // check if all are active
                      let allActive = true;
                      Object.entries(foundRG.active).forEach(([key, c]) => {
                        if (!c) allActive = false;
                      });

                      // Make sure the group isn't already active
                      if (allActive && !foundRG.isActive) {
                        foundRG.isActive = true;
                        // console.log('Activating rule group: ', foundRG);
                        for (let rule of foundRG.rules) {
                          let affectedInput = inputs.find(
                            (input) => input.id === rule.zId
                          );
                          if (affectedInput !== undefined) {
                            affectedInput = applyRule(
                              affectedInput,
                              rule,
                              true,
                              false,
                              initEditOrder
                            );
                            if (input.id === affectedInput.id)
                              input = Object.assign(input, affectedInput);
                            // console.log(affectedInput)
                          }
                        }
                      }

                      ruleGroups = ruleGroups.map((rg) => {
                        if (rg.id === foundRG.id) {
                          return foundRG;
                        } else {
                          return rg;
                        }
                      });
                    }
                  }
                });
              }
            }
          }
        }

        input = Object.assign(input, tmpInput);
      }

      if (order !== null) {
        for (let input of inputs) {
          let orderOption = order.options.find((i) => i.inputId === input.id);
          if (orderOption !== undefined) {
            if (input.id === 6) {
              let tmpInput = { ...input };
              let prerig = inputs.find((i) => i.id === 4);
              let selectedPrerig = prerig.inputOptions.find(
                (io) => io.id === prerig.value
              );
              let performance = inputs.find((i) => i.id === 16);
              let hasTwin = order.options.find((i) => i.inputId === 364);
              let pfValue = 0;
              switch (performance.value) {
                case 2368:
                  pfValue = 0;
                  break;
                case 275:
                  pfValue = 1;
                  break;
                case 276:
                  pfValue = 2;
                  break;
                case 277:
                  pfValue = 3;
                  break;
              }
              if (prerig.value !== '' && selectedPrerig !== undefined) {
                const availableMotors = prerig.inputOptions
                  .find((io) => io.id === prerig.value)
                  .prerigMotors.filter(
                    (m) =>
                      m.performance <= pfValue &&
                      (m.twin === null || m.twin === m.motor)
                  );

                const availableTwinMotors = selectedPrerig.prerigMotors.filter(
                  (pm) => pm.performance <= pfValue && pm.twin !== null
                );

                tmpInput.inputOptions = tmpInput.inputOptions.map((io) => {
                  let foundMotor = availableMotors.find((m) => m.motor === io.id);
                  if (foundMotor) {
                    io.active = true;
                    let price = foundMotor?.epicorPrice;
                    io.price = price;
                    let twin = { name: '', price: 0, mainPrice: 0, motor: null };
                    if (hasTwin) {
                      let currentTwin = availableTwinMotors.find(
                        (tm) => tm.twin === foundMotor?.motor
                      );
                      let twinMotor = input.inputOptions.find(
                        (io) => io.id === currentTwin.motor
                      );
                      twin.name = twinMotor.avalonName;
                      twin.motor = twinMotor.id;
                      twin.price = currentTwin.epicorPrice;
                      twin.mainPrice =
                        foundMotor.epicorPrice + currentTwin.epicorPrice;
                    }
                    io.twin = twin;
                  } else {
                    io.active = false;
                  }
                  return io;
                });
              }
              tmpInput.active = true;
              input = Object.assign(input, tmpInput);
            }

            if (input.id === 349) {
              let tmpInput = { ...input };
              let motorOption = inputs.find((i) => i.id === 6);
              let selectedMotorOption = motorOption.inputOptions.find(
                (io) => io.id === motorOption.value
              );

              if (
                selectedMotorOption !== undefined &&
                selectedMotorOption.motorOptionSetting[0] !== undefined
              ) {
                for (const prop of tmpInput.inputOptions) {
                  if (prop.id === 1985) {
                    prop.active =
                      selectedMotorOption?.motorOptionSetting[0].alumPropPart !==
                      'NA';
                  }

                  if (prop.id === 1984) {
                    prop.active =
                      selectedMotorOption?.motorOptionSetting[0].sstPropPart !==
                      'NA';
                  }
                }
              } else {
                console.log(
                  `Motor option ${motorOption.value} not found: `,
                  motorOption,
                  selectedMotorOption
                );
              }

              input = Object.assign(input, tmpInput);
            }
          }
        }
      }

      for (let ag of ruleGroups.filter((rg) => rg.isActive)) {
        // console.log(ag);
        for (let rule of ag.rules) {
          let affectedInput = inputs.find((input) => input.id === rule.zId);
          if (affectedInput !== undefined) {
            let tmpAffectedInput = applyRule(
              affectedInput,
              rule,
              true,
              false,
              initEditOrder
            );
            affectedInput = Object.assign(affectedInput, tmpAffectedInput);
          }
        }
      }

      inputs
        .filter((i) => i.active)
        .map((i) => {
          if (i.type === 'DROPDOWN' && i.value !== '' && i.value !== null) {
            let io = i.inputOptions.find(
              (inputOption) => inputOption.id === i.value
            );
            if (io === undefined) {
              return console.log('FAILED TO FIND OPTION: ', i, io);
            }
            if (i.id === 6 && io.twin) {
              return (initPrice += Math.ceil(io.price) + Math.ceil(io.twin.price));
            }
            return (initPrice += Math.ceil(io.price));
          }
          if (i.type === 'CHECKBOX' && i.checked) {
            return (initPrice += Math.ceil(i.price));
          }
          return;
        });

      setSelectedFloorplan(sfp);
      setPrice(initPrice);
      // console.log("INITIAL PRICE: ", initPrice)
      // add order notes
      if (formType === 'd') {
        for (let page of pages) {
          if (order !== null && order.notes.length > 0) {
            let foundNote = order.notes.find((n) => n.pageId === page.id);
            if (foundNote !== undefined) {
              page.note = foundNote.text;
              page.noteId = foundNote.id;
            } else {
              page.note = '';
            }
          } else {
            page.note = '';
          }
        }
      }

      setPages(pages.sort((a, b) => a.id - b.id));
      if (pageId !== pages.length + 1) {
        if (pageId === '6') {
          let location = window.location.toString();
          let newLocation = location.substring(0, location.length - 1);
          window.location.replace(newLocation + '1');
        }
        let initPage = pages[parseInt(pageId, 10) - 1];
        if (initPage === undefined) initPage = pages[1];
        setSelectedPage(initPage);
      }

      getBreadcrumbs(pages);
      // console.log(
      //   'POST INIT: ',
      //   inputs.find((i) => i.id === 252)
      // );
      setInputs(inputs);
      console.log(
        'ACTIVE RULE GROUPS ON INIT: ',
        ruleGroups.filter((rg) => rg.isActive)
      );
      setRuleGroups(ruleGroups);
      if (role && !userLocations) loadLocations(order);
      setLoading(false);
    };
    const loadLocations = async (order) => {
      const locations = [];
      if (role >= 10) {
        console.log(userContext.userState.me.locations);
        userContext.userState.me.locations.map((location) => {
          return locations.push({
            id: location.locationId,
            active: true,
            avalonName: location.location.name,
          });
        });
      }
      if (role <= 3) {
        // load locations from backend
        let dealershipIdParam = dealershipId ? dealershipId : order.dealershipId;
        const response = await axios.get('/api/dealer/locations', {
          params: { dealershipId: dealershipIdParam },
        });
        // format location data
        response.data.dealership.locations.map((location) => {
          return locations.push({
            id: location.id,
            active: true,
            avalonName: location.name,
          });
        });
      }

      let initialLocationValue = locationId ? parseInt(locationId, 10) : '';
      if (locations.length === 1) initialLocationValue = locations[0].id;
      if (order?.locationId) initialLocationValue = order.locationId;
      setUserLocations({
        name: 'Location',
        inputOptions: locations,
        value: initialLocationValue,
        tooltip: null,
        required: true,
        disabled: locations.length === 1,
      });
    };

    loadInputs();
  }, []);

  useEffect(() => {
    if (pages.length !== 0 && pageId !== pages.length)
      setSelectedPage(pages[pageId - 1]);

    getBreadcrumbs(pages);
    setIsSubmit(false);

    // Scroll top to view
    document.getElementById('root').scrollIntoView();
  }, [pageId, isMobile]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      setLoadingLocation(true);
      if (
        customerInfo.customerZipcode === '' ||
        customerInfo.customerZipcode === undefined
      ) {
        setCustomerInfo({ ...customerInfo, location: null, selectedLocation: null });
      } else {
        // console.log(customerInfo.customerZipcode)
        let googleMapsUrl = `https://maps.google.com/maps/api/geocode/json?address=${customerInfo.customerZipcode}&sensor=false&key=AIzaSyCFa-Bf0znBnJNrqv00UgpMfbA9JFgskYs`;

        axios.get(googleMapsUrl).then(async (res) => {
          let location = res.data.results[0].geometry.location;
          // console.log(location)
          let fullBrand = brand === 't' ? 'Tahoe' : 'Avalon';

          try {
            const { data } = await axios.post('/api/order/dealer/find', {
              lat: location.lat,
              lng: location.lng,
              brand: fullBrand,
            });

            // console.log(data)
            setCustomerInfo({ ...customerInfo, location: data.foundLocations });
            setLoadingLocation(false);
          } catch (err) {
            console.log(err);
          }
        });
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [customerInfo.customerZipcode]);

  const handleOpenMYConfirmation = (action) => {
    setModelConfirmation({ ...modelConfirmation, action: action });
    setOpenMYConfirmation(true);
  };

  const handleCloseMYConfirmation = (confirmed = false) => {
    setModelConfirmation({ ...modelConfirmation, confirmed: confirmed });
    handleNextButtonPageChange(confirmed);
    setOpenMYConfirmation(false);
  };

  const handleOpenCustomerSubmit = () => {
    setOpenCustomerSubmit(true);
  };

  const handleCloseCustomerSubmit = () => {
    setOpenCustomerSubmit(false);
  };

  const handleOpenNotes = () => {
    setOpenNotes(true);
  };

  const handleCloseNotes = () => {
    setOpenNotes(false);
  };

  const getNextPageName = () => {
    if (submittingOrder && !openCustomerSubmit) {
      return 'SAVING ORDER....';
    }

    if (submittingOrder && openCustomerSubmit) {
      return 'ORDER SENT TO DEALER';
    }

    if (isSubmit) {
      return 'Submit';
    }

    if (pages.length !== 0 && parseInt(pageId, 10) !== pages.length + 1) {
      if (parseInt(pageId, 10) === pages.length) {
        return 'Complete Build';
      } else {
        return `Next: ${pages[parseInt(pageId, 10)]?.name}`;
      }
    } else {
      if (orderId === 'new') {
        return formType === 'c' ? 'Send to Dealer' : 'Save Quote';
      } else {
        return 'Update Order';
      }
    }
  };

  const getBreadcrumbs = (pageLists) => {
    let prevs =
      orderId !== 'new'
        ? []
        : [
            { name: 'Models', onClick: () => handleNav('models') },
            { name: 'Floorplans', onClick: () => handleNav('floorplans') },
          ];
    let mainBreadcrumbs = pageLists.map((page, index) => {
      if (page.id < parseInt(pageId, 10) + 1) {
        if (isMobile) {
          prevs.push({ name: page.name, onClick: () => handleNav(index) });
          return null;
        } else {
          return (
            <Link
              component="button"
              variant="h6"
              underline="hover"
              key={index + 2}
              className={classes.link}
              onClick={() => handleNav(index)}
            >
              {page.name}
            </Link>
          );
        }
      } else {
        return (
          <Link
            component="button"
            underline="hover"
            variant="h6"
            key={index + 2}
            className={
              parseInt(pageId, 10) + 1 !== page.id
                ? classes.link
                : classes.currentLink
            }
            onClick={() => handleNav(index)}
          >
            {page.name}
          </Link>
        );
      }
    });
    setPrevBreadcrumbs(prevs);
    setMobileBreadcrumbs([
      ...mainBreadcrumbs,
      <Link
        component="button"
        underline="hover"
        variant="h6"
        key={pageLists.length + 2}
        className={
          parseInt(pageId, 10) <= pageLists.length
            ? classes.link
            : classes.currentLink
        }
        onClick={() => handleNav(pageLists.length)}
      >
        Complete
      </Link>,
    ]);

    if (orderId !== 'new') {
      setBreadcrumbs([
        ...mainBreadcrumbs,
        <Link
          component="button"
          underline="hover"
          variant="h6"
          key={pageLists.length + 2}
          className={
            parseInt(pageId, 10) <= pageLists.length
              ? classes.link
              : classes.currentLink
          }
          onClick={() => handleNav(pageLists.length)}
        >
          Complete
        </Link>,
      ]);
    } else {
      setBreadcrumbs([
        <Link
          component="button"
          underline="hover"
          variant="h6"
          key={0}
          className={classes.link}
          onClick={() => handleNav('models')}
        >
          Models
        </Link>,
        <Link
          component="button"
          underline="hover"
          variant="h6"
          key={1}
          className={classes.link}
          onClick={() => handleNav('floorplans')}
        >
          Floorplans
        </Link>,
        ...mainBreadcrumbs,
        <Link
          component="button"
          underline="hover"
          variant="h6"
          key={pageLists.length + 2}
          className={
            parseInt(pageId, 10) <= pageLists.length
              ? classes.link
              : classes.currentLink
          }
          onClick={() => handleNav(pageLists.length)}
        >
          Complete
        </Link>,
      ]);
    }
  };

  const applyRule = (input, rule, activate, csv = false, initEditOrder = false) => {
    // Input rule debugging
    // if (input.id === 250 || input.id === 40) {
    //   console.log(input, rule);
    // }
    if (rule.changeType === 'MATH' && initEditOrder) return input;
    if (activate) {
      switch (rule.changeType) {
        case 'MATH':
          let ruleValue = parseInt(rule.changeValue);
          let currentValue = '';
          let newValue = '';
          if (input.type === 'DROPDOWN') {
            let foundOption = input.inputOptions.find((io) => io.id === input.value);
            currentValue = foundOption.avalonName;
            newValue = ruleValue + parseInt(currentValue, 10);
            let updatedOption = input.inputOptions.find(
              (io) => io.avalonName === newValue.toString()
            );
            console.log(
              `MATH: current value, ${currentValue} - new value, ${newValue} `,
              updatedOption,
              input
            );
            if (updatedOption) {
              return {
                ...input,
                value: updatedOption.id,
              };
            } else {
              console.log('ERROR FINDING MATH OPTION');
              return { ...input };
            }
          }

          if (input.type === 'TEXT') {
            currentValue = parseInt(currentValue, 10);
            newValue = ruleValue + currentValue;
            return {
              ...input,
              value: newValue.toString(),
            };
          }
          break;
        case 'ACTIVE':
          if (rule.zDropdownId !== null) {
            let activeInput = { ...input };
            activeInput = {
              ...activeInput,
              inputOptions: activeInput.inputOptions.map((io) => {
                if (io.id === rule.zDropdownId) {
                  if (csv) {
                    io.defaultActive = parseInt(rule.changeValue, 10) === 1;
                    if (io.rules?.active) return io;
                  }
                  return {
                    ...io,
                    active: parseInt(rule.changeValue, 10) === 1,
                    rules: { ...io.rules, active: rule },
                  };
                } else {
                  return io;
                }
              }),
            };
            activeInput.active =
              activeInput.inputOptions.filter((io) => io.active).length > 0;
            return activeInput;
          } else {
            return {
              ...input,
              active: parseInt(rule.changeValue, 10) === 1,
              rules: { ...input.rules, active: rule },
            };
          }
        case 'DEFAULT':
          if (
            initEditOrder &&
            rule.ruleGroupId === null &&
            (input.value !== '' ||
              (input.value === true && rule.changeValue === '0'))
          ) {
            // console.log(
            //   'SKIP DEFAULT SINGLE RULE ON EDIT ORDER INIT: ',
            //   input,
            //   rule
            // );
            return input;
          }
          let defaultInput = { ...input };
          if (rule.zDropdownId !== null) {
            let foundDefaultOption = defaultInput.inputOptions.find(
              (io) => io.id === +rule.zDropdownId
            );

            if (!foundDefaultOption)
              console.log(
                'Bad default option: ',
                defaultInput,
                foundDefaultOption,
                rule.zDropdownId
              );
            if (csv && foundDefaultOption)
              defaultInput.csvDefault = parseInt(rule.zDropdownId, 10);
            // if (defaultInput.orderDefaultValue !== undefined) console.log("SETTING DEFAULT ON ORDER DEFAULT: ", defaultInput, rule)

            defaultInput = {
              ...defaultInput,
              active: true,
              value:
                defaultInput.orderDefaultValue !== undefined && initEditOrder
                  ? defaultInput.orderDefaultValue
                  : rule.zDropdownId === 0
                  ? ''
                  : rule.zDropdownId,
              inputOptions: defaultInput.inputOptions.map((io) => {
                if (io.id === rule.zDropdownId) {
                  if (!io.active) {
                    io.active = true;
                    // leave any potential inactive rules in place to restore
                  }
                  return { ...io, rules: { ...io.rules, default: rule } };
                } else {
                  return io;
                }
              }),
            };
            return defaultInput;
          } else {
            if (csv) {
              switch (input.type) {
                case 'CHECKBOX':
                  defaultInput.csvDefault = parseInt(rule.changeValue, 10) === 1;
                  break;
                case 'TEXT':
                  defaultInput.csvDefault = rule.changeValue;
                  break;
              }
            }

            switch (input.type) {
              case 'CHECKBOX':
                if (initEditOrder) {
                  return {
                    ...defaultInput,
                  };
                } else {
                  return {
                    ...defaultInput,
                    checked: parseInt(rule.changeValue, 10) === 1,
                    value: parseInt(rule.changeValue, 10) === 1,
                    rules: { ...defaultInput.rules, default: rule },
                  };
                }

              case 'TEXT':
                return {
                  ...defaultInput,
                  value: rule.changeValue,
                  rules: { ...defaultInput.rules, default: rule },
                };
            }
          }
        case 'PRICE':
          // if (
          //   initEditOrder &&
          //   input.orderOptionPrice !== undefined &&
          //   parseInt(rule.changeValue, 10) !== input.orderOptionPrice
          // ) {
          //   return input;
          // }
          if (rule.zDropdownId !== null) {
            return {
              ...input,
              inputOptions: input.inputOptions.map((io) => {
                if (io.id === rule.zDropdownId) {
                  let tmpIo = { ...io };
                  if (tmpIo.defaultPrice === undefined) {
                    if (csv) {
                      tmpIo.defaultPrice = parseInt(rule.changeValue, 10);
                    }

                    if (!csv && !initEditOrder)
                      tmpIo.defaultPrice = parseInt(io.price, 10);
                  }

                  if (initEditOrder && csv && tmpIo.rules?.price !== undefined)
                    return tmpIo;
                  tmpIo.rules = { ...io.rules, price: rule };
                  tmpIo.price = parseInt(rule.changeValue, 10);
                  return tmpIo;
                } else {
                  return io;
                }
              }),
            };
          } else {
            let tmpInput = { ...input };
            if (tmpInput.defaultPrice === undefined) {
              if (csv) {
                tmpInput.defaultPrice = parseInt(rule.changeValue, 10);
              }

              if (!csv && !initEditOrder)
                tmpInput.defaultPrice = parseInt(input.price, 10);
            }
            if (initEditOrder && csv && tmpInput.rules?.price !== undefined)
              return tmpInput;
            tmpInput.rules = { ...tmpInput.rules, price: rule };
            tmpInput.price = parseInt(rule.changeValue, 10);
            return tmpInput;
          }
        case 'IMGPATH':
          if (rule.zDropdownId !== null) {
            return {
              ...input,
              inputOptions: input.inputOptions.map((io) => {
                if (io.id === rule.zDropdownId) {
                  let imgIo = { ...io };
                  if (imgIo.defaultImgPath === undefined)
                    imgIo.defaultImgPath = rule.changeValue;
                  return {
                    ...imgIo,
                    imgPath: rule.changeValue,
                    rules: { ...imgIo.rules, imgPath: rule },
                  };
                } else {
                  return io;
                }
              }),
            };
          } else {
            // Don't think there's img path rules for inputs
            return;
          }
        case 'DISABLED':
          return {
            ...input,
            disabled: true,
            rules: { ...input.rules, disabled: rule },
          };
        case 'REQUIRED':
          return {
            ...input,
            required: true,
            rules: { ...input.rules, required: rule },
          };
      }
    } else {
      switch (rule.changeType) {
        case 'MATH':
          let ruleValue = parseInt(rule.changeValue);
          let currentValue = '';
          let newValue = '';
          if (input.type === 'DROPDOWN') {
            let foundOption = input.inputOptions.find((io) => io.id === input.value);
            currentValue = foundOption.avalonName;
            newValue = parseInt(currentValue, 10) - ruleValue;
            let updatedOption = input.inputOptions.find(
              (io) => io.avalonName === newValue.toString()
            );
            console.log(
              `MATH: current value, ${currentValue} - new value, ${newValue} `,
              updatedOption
            );
            return {
              ...input,
              value: updatedOption.id,
            };
          }

          if (input.type === 'TEXT') {
            currentValue = parseInt(currentValue, 10);
            newValue = currentValue - ruleValue;
            return {
              ...input,
              value: newValue.toString(),
            };
          }
          break;
        case 'ACTIVE':
          if (rule.zDropdownId !== null) {
            let tmpInput = {
              ...input,
              inputOptions: input.inputOptions.map((io) => {
                if (io.id === rule.zDropdownId) {
                  let tmpIo = { ...io };
                  if (tmpIo.defaultActive !== undefined) {
                    tmpIo.active = tmpIo.defaultActive;
                  } else {
                    tmpIo.active = !tmpIo.active;
                  }
                  if (tmpIo.rules !== undefined && tmpIo.rules.active !== undefined)
                    delete tmpIo.rules.active;
                  return tmpIo;
                } else {
                  return io;
                }
              }),
            };
            // if active rule enabled the option and it was selected deselect it
            if (
              tmpInput.value === parseInt(rule.zDropdownId, 10) &&
              rule.changeValue === '1'
            )
              tmpInput.value = '';
            tmpInput.active =
              tmpInput.inputOptions.filter((io) => io.active).length > 0;
            return tmpInput;
          } else {
            if (input.rules.active !== undefined) delete input.rules.active;
            return { ...input, active: !(parseInt(rule.changeValue, 10) === 1) };
          }
        case 'DEFAULT':
          let defaultInput = { ...input };
          if (rule.zDropdownId !== null) {
            let hasOtherDefaultRule = false;
            for (const io of defaultInput.inputOptions) {
              if (
                io.rules !== undefined &&
                io.rules.default !== undefined &&
                io.rules.default.id !== rule.id
              ) {
                hasOtherDefaultRule = true;
                defaultInput.value = io.id;
              }
            }
            if (defaultInput.csvDefault !== undefined && !hasOtherDefaultRule) {
              defaultInput.value = defaultInput.csvDefault;
            } else {
              if (defaultInput.touched === false && !hasOtherDefaultRule)
                defaultInput.value = '';
            }
            if (rule.zDropdownId == '0') {
              console.log('UNSELECTING INPUT: ', input, rule);
              defaultInput.value = '';
            }
            return {
              ...defaultInput,
              inputOptions: defaultInput.inputOptions.map((io) => {
                if (io.id === rule.zDropdownId) {
                  let tmpIo = { ...io };
                  delete tmpIo.rules?.default;
                  // When removing default check if there was a disabling active rule
                  if (tmpIo.rules?.active !== undefined) {
                    tmpIo.active =
                      parseInt(tmpIo.rules.active.changeValue, 10) === 1;
                  } else {
                    if (tmpIo.defaultActive) {
                      tmpIo.active = tmpIo.defaultActive;
                    } else {
                      // Probably applying a default select rule, force being active
                      tmpIo.active = true;
                      tmpIo.defaultActive = true;
                    }
                  }
                  return tmpIo;
                } else {
                  return io;
                }
              }),
            };
          } else {
            if (defaultInput.type === 'DROPDOWN') {
              if (rule.zDropdownId == '0')
                console.log('UNSELECTING INPUT: ', input, rule);
              return {
                ...defaultInput,
                inputOptions: defaultInput.inputOptions.map((io) => {
                  if (io.id === rule.zDropdownId) {
                    let tmpIo = { ...io };
                    delete tmpIo.rules?.default;
                    // When removing default check if there was a disabling active rule
                    if (tmpIo.rules?.active !== undefined) {
                      tmpIo.active =
                        parseInt(tmpIo.rules.active.changeValue, 10) === 1;
                    } else {
                      tmpIo.active = tmpIo.defaultActive;
                    }
                    return tmpIo;
                  } else {
                    return io;
                  }
                }),
                value: rule.zDropdownId === '0' ? '' : rule.zDropdownId,
              };
            }

            if (defaultInput.type === 'CHECKBOX') {
              if (defaultInput.csvDefault !== undefined) {
                defaultInput.checked = defaultInput.csvDefault;
                defaultInput.value = defaultInput.csvDefault;
              } else {
                defaultInput.checked = false;
                defaultInput.value = false;
              }
              if (defaultInput?.rules?.default) delete defaultInput.rules.default;
              return defaultInput;
            }
          }
        case 'PRICE':
          if (rule.zDropdownId !== null) {
            return {
              ...input,
              inputOptions: input.inputOptions.map((io) => {
                if (io.id === rule.zDropdownId) {
                  let tmpIo = { ...io };
                  delete tmpIo.rules.price;
                  tmpIo.price = tmpIo.defaultPrice;
                  return tmpIo;
                } else {
                  return io;
                }
              }),
            };
          } else {
            let tmpInput = { ...input };
            delete tmpInput.rules.price;
            tmpInput.price = tmpInput.defaultPrice;
            return tmpInput;
          }
        case 'IMGPATH':
          if (rule.zDropdownId !== null) {
            return {
              ...input,
              inputOptions: input.inputOptions.map((io) => {
                if (io.id === rule.zDropdownId) {
                  let tmpIo = { ...io };
                  delete tmpIo.rules.imgPath;
                  if (tmpIo.defaultImgPath !== undefined)
                    tmpIo.imgPath = tmpIo.defaultImgPath;
                  return tmpIo;
                } else {
                  return io;
                }
              }),
            };
          } else {
            let tmpInput = { ...input };
            delete tmpInput.rules.imgPath;
            return tmpInput;
          }
        case 'DISABLED':
          let disabledInput = { ...input };
          delete disabledInput.disabled;
          if (disabledInput.rules !== undefined) delete disabledInput.rules.disabled;
          return disabledInput;
        case 'REQUIRED':
          let requiredInput = { ...input };
          delete requiredInput.required;
          if (requiredInput.rules !== undefined) delete requiredInput.rules.required;
          return requiredInput;
      }
    }
  };

  const evaluateRuleGroups = (input, oldValue, newValue, updatedInputs) => {
    let updatedRuleGroups = [...ruleGroups];

    let oldRuleGroups = input.conditionsX.filter((cx) => cx.yValue === oldValue);

    if (oldRuleGroups.length > 0) {
      let oldRuleGroupLogs = [];
      for (let rg of oldRuleGroups) {
        let foundRG = updatedRuleGroups.find(
          (ruleGroup) => ruleGroup.id === rg.ruleGroupId
        );
        if (foundRG !== undefined) {
          Object.entries(foundRG.conditionsByOrder).map(([key, v]) => {
            if (v[0].xId === input.id) {
              // Check to see if new value is also in the rule group conditions
              oldRuleGroupLogs.push(foundRG);
              if (
                v.find((vt) => vt.yValue === oldValue) !== undefined &&
                v.find((vt) => vt.yValue === newValue) === undefined
              ) {
                foundRG.active[key] = false;

                // Check if it was previously active and disable
                if (foundRG.isActive) {
                  // console.log("DISABLING RULE GROUP: ", foundRG)
                  foundRG.isActive = false;
                  for (let rule of foundRG.rules) {
                    let affectedInput = updatedInputs.find(
                      (input) => input.id === rule.zId
                    );
                    // console.log(affectedInput, rule)
                    if (affectedInput !== undefined) {
                      affectedInput = applyRule(affectedInput, rule, false);
                      // if default check old/new option
                      updatedInputs = updatedInputs.map((i) => {
                        if (i.id === affectedInput.id) {
                          return affectedInput;
                        } else {
                          return i;
                        }
                      });
                    }
                  }
                }
              }
            }
          });
        }
      }
      console.log('DISABLE OLD RULE GROUPS: ', oldRuleGroupLogs);
    }

    let newRuleGroups = input.conditionsX.filter((cx) => cx.yValue === newValue);
    if (newRuleGroups.length > 0) {
      let newRuleGroupLogs = [];
      for (let rg of newRuleGroups) {
        let foundRG = updatedRuleGroups.find(
          (ruleGroup) => ruleGroup.id === rg.ruleGroupId
        );
        if (foundRG !== undefined) {
          // found rule group
          let allActive = true;
          Object.entries(foundRG.conditionsByOrder).map(([key, v]) => {
            if (v[0].xId === input.id) {
              if (v.find((vt) => vt.yValue === newValue)) {
                foundRG.active[key] = true;
              }
            } else {
              // check for updatedInputs value
              if (v[0].xId !== 354 && v[0].xId !== 355 && v[0].xId !== 356) {
                let foundUpdatedInput = updatedInputs.find(
                  (input) => input.id === v[0].xId
                );
                if (foundUpdatedInput) {
                  // console.log(foundUpdatedInput.value);
                  let comparisonValue = null;
                  if (foundUpdatedInput.type === 'DROPDOWN') {
                    comparisonValue =
                      foundUpdatedInput.value === '' ? 0 : foundUpdatedInput.value;
                  }

                  if (foundUpdatedInput.type === 'CHECKBOX') {
                    comparisonValue = foundUpdatedInput.value === false ? 0 : 1;
                  }

                  let foundConditional = v.find(
                    (vt) => vt.yValue === comparisonValue
                  );
                  if (foundConditional) {
                    foundRG.active[key] = true;
                  } else {
                    foundRG.active[key] = false;
                  }
                }
              }
            }
          });
          Object.entries(foundRG.active).forEach(([key, c]) => {
            if (!c) allActive = false;
          });

          // Make sure the group isn't already active
          if (allActive && !foundRG.isActive) {
            foundRG.isActive = true;

            newRuleGroupLogs.push(foundRG);
            for (let rule of foundRG.rules) {
              let affectedInput = updatedInputs.find(
                (input) => input.id === rule.zId
              );
              if (affectedInput !== undefined) {
                affectedInput = applyRule(affectedInput, rule, true);
                updatedInputs = updatedInputs.map((i) => {
                  if (i.id === affectedInput.id) {
                    return affectedInput;
                  } else {
                    return i;
                  }
                });
              }
            }
          }

          setRuleGroups(
            ruleGroups.map((rg) => {
              if (rg.id === foundRG.id) {
                return foundRG;
              } else {
                return rg;
              }
            })
          );
        }
      }
      console.log('ACTIVATING RULE GROUPs: ', newRuleGroupLogs);
    }

    return updatedInputs;
  };

  const handleInputChange = (value, input) => {
    // Show proper image which updated
    if (input?.overlayImage === 'BOAT') {
      setSelectedImage(1);
    } else if (input?.overlayImage === 'BENCH') {
      setSelectedImage(2);
    }

    let updatedInputs = [...inputs];

    if (input.type === 'DROPDOWN') {
      let currentValue = input.value === '' ? 0 : input.value;
      let newValue = value === '' ? 0 : value;
      let propAndTransomCheck = input.id === 6;

      // gtm for dropdowns
      if (newValue !== 0) {
        let modelName = parseBrandName(brand, selectedModel);

        window.dataLayer.push({
          event: selectedPage.name,
          eventCategory: 'dropdown',
          eventAction: {
            model: modelName,
            input: input.name,
            option: input.inputOptions.filter((o) => o.id == newValue)[0].avalonName,
          },
          eventLabel: `${modelName}|${selectedFloorplan.floorplan.avalonName}|${
            formType === 'd' && !customerBuild ? price : Math.ceil(price / 0.64)
          } MSRP`,
        });
      }

      if (input.rulesX.length > 0) {
        // start with current option rules
        let oldOptionRules = input.rulesX.filter((r) => r.yValue === currentValue);

        for (let rule of oldOptionRules) {
          let affectedInput = updatedInputs.find((input) => input.id === rule.zId);
          if (affectedInput !== undefined) {
            // console.log(
            //   'UNDOING SINGLE INPUT RULE: ',
            //   rule.changeType,
            //   rule,
            //   affectedInput
            // );

            if (affectedInput.rulesX.length > 0 && rule.changeType === 'DEFAULT') {
              // console.log("APPLYING DEFAULT RULE AND NEED TO DISABLE AFFECTED INPUT: ", affectedInput, affectedInput.rulesX)

              let currentAffectedInputValue = null;
              if (affectedInput.type === 'DROPDOWN') {
                currentAffectedInputValue =
                  affectedInput.value === '' ? 0 : affectedInput.value;
              } else {
                currentAffectedInputValue = affectedInput.value === true ? 1 : 0;
              }
              if (
                currentAffectedInputValue === parseInt(rule.changeValue, 10) &&
                rule.changeValue === '0'
              ) {
                console.log(
                  `${affectedInput.name} is already default rule value, skip applying rules.`
                );
                continue;
              }

              let currentAffectedInputRules = affectedInput.rulesX.filter(
                (r) => r.yValue === currentAffectedInputValue
              );
              console.log(
                'NESTED INPUT RULES DISABLING: ',
                currentAffectedInputRules,
                currentAffectedInputValue
              );
              for (let ar of currentAffectedInputRules) {
                let nestedAffectedInput = updatedInputs.find(
                  (input) => input.id === ar.zId
                );
                if (nestedAffectedInput !== undefined) {
                  nestedAffectedInput = applyRule(nestedAffectedInput, ar, false);
                  console.log('NESTED AFFECTED INPUT: ', nestedAffectedInput, ar);
                  updatedInputs = updatedInputs.map((i) => {
                    if (i.id === nestedAffectedInput.id) {
                      return nestedAffectedInput;
                    } else {
                      return i;
                    }
                  });
                }
              }
            }

            affectedInput = applyRule(affectedInput, rule, false);

            if (affectedInput.rulesX.length > 0 && rule.changeType === 'DEFAULT') {
              // console.log("APPLYING DEFAULT RULE AND NEED TO DISABLE AFFECTED INPUT: ", affectedInput, affectedInput.rulesX)

              let currentAffectedInputValue = null;
              if (affectedInput.type === 'DROPDOWN') {
                currentAffectedInputValue =
                  affectedInput.value === '' ? 0 : affectedInput.value;
              } else {
                currentAffectedInputValue = affectedInput.value === true ? 1 : 0;
              }

              if (
                currentAffectedInputValue === parseInt(rule.changeValue, 10) &&
                rule.changeValue === '0'
              ) {
                console.log(
                  `${affectedInput.name} is already default rule value, skip applying rules.`
                );
                continue;
              }

              let currentAffectedInputRules = affectedInput.rulesX.filter(
                (r) => r.yValue === currentAffectedInputValue
              );
              console.log(
                'NESTED INPUT RULES ENABLING: ',
                currentAffectedInputRules,
                currentAffectedInputValue
              );
              for (let ar of currentAffectedInputRules) {
                let nestedAffectedInput = updatedInputs.find(
                  (input) => input.id === ar.zId
                );
                if (nestedAffectedInput !== undefined) {
                  nestedAffectedInput = applyRule(nestedAffectedInput, ar, true);
                  console.log('NESTED AFFECTED INPUT: ', nestedAffectedInput, ar);
                  updatedInputs = updatedInputs.map((i) => {
                    if (i.id === nestedAffectedInput.id) {
                      return nestedAffectedInput;
                    } else {
                      return i;
                    }
                  });
                }
              }
            }

            updatedInputs = updatedInputs.map((i) => {
              if (i.id === affectedInput.id) {
                return affectedInput;
              } else {
                return i;
              }
            });
          }
        }

        let newOptionRules = input.rulesX.filter((r) => r.yValue === newValue);
        for (let rule of newOptionRules) {
          let affectedInput = updatedInputs.find((input) => input.id === rule.zId);
          if (affectedInput !== undefined) {
            if (affectedInput.rulesX.length > 0 && rule.changeType === 'DEFAULT') {
              // console.log("APPLYING DEFAULT RULE AND NEED TO DISABLE AFFECTED INPUT: ", affectedInput, affectedInput.rulesX)

              let currentAffectedInputValue = null;
              if (affectedInput.type === 'DROPDOWN') {
                currentAffectedInputValue =
                  affectedInput.value === '' ? 0 : affectedInput.value;
              } else {
                currentAffectedInputValue = affectedInput.value === true ? 1 : 0;
              }

              if (
                currentAffectedInputValue === parseInt(rule.changeValue, 10) &&
                rule.changeValue === '0'
              ) {
                console.log(
                  `${affectedInput.name} is already default rule value, skip applying rules.`
                );
                continue;
              }

              let currentAffectedInputRules = affectedInput.rulesX.filter(
                (r) => r.yValue === currentAffectedInputValue
              );
              for (let ar of currentAffectedInputRules) {
                let nestedAffectedInput = updatedInputs.find(
                  (input) => input.id === ar.zId
                );
                if (nestedAffectedInput !== undefined) {
                  nestedAffectedInput = applyRule(nestedAffectedInput, ar, false);
                  console.log('NESTED AFFECTED INPUT: ', nestedAffectedInput, ar);
                  updatedInputs = updatedInputs.map((i) => {
                    if (i.id === nestedAffectedInput.id) {
                      return nestedAffectedInput;
                    } else {
                      return i;
                    }
                  });
                }
              }
            }

            affectedInput = applyRule(affectedInput, rule, true);

            if (affectedInput.rulesX.length > 0 && rule.changeType === 'DEFAULT') {
              let currentAffectedInputValue = null;
              if (affectedInput.type === 'DROPDOWN') {
                currentAffectedInputValue =
                  affectedInput.value === '' ? 0 : affectedInput.value;
              } else {
                currentAffectedInputValue = affectedInput.value === true ? 1 : 0;
              }

              let currentAffectedInputRules = affectedInput.rulesX.filter(
                (r) => r.yValue === currentAffectedInputValue
              );
              console.log(
                'NESTED INPUT RULES ENABLING: ',
                currentAffectedInputRules,
                currentAffectedInputValue
              );
              for (let ar of currentAffectedInputRules) {
                let nestedAffectedInput = updatedInputs.find(
                  (input) => input.id === ar.zId
                );
                if (nestedAffectedInput !== undefined) {
                  nestedAffectedInput = applyRule(nestedAffectedInput, ar, true);
                  console.log('NESTED AFFECTED INPUT: ', nestedAffectedInput, ar);
                  updatedInputs = updatedInputs.map((i) => {
                    if (i.id === nestedAffectedInput.id) {
                      return nestedAffectedInput;
                    } else {
                      return i;
                    }
                  });
                }
              }
            }

            updatedInputs = updatedInputs.map((i) => {
              if (i.id === affectedInput.id) {
                return affectedInput;
              } else {
                return i;
              }
            });
          }
        }
      }

      // EVALUTATE RULE GROUPS
      if (input.conditionsX.length > 0) {
        updatedInputs = evaluateRuleGroups(
          input,
          currentValue,
          newValue,
          updatedInputs
        );
      }

      console.log('UPDATED INPUT: ', input, value);

      updatedInputs = updatedInputs.map((i) => {
        // prerig case
        if ((input.id === 4 || input.id === 16) && i.id === 6) {
          let selectedPrerig = null;

          if (input.id === 4) {
            selectedPrerig =
              value === '' ? null : input.inputOptions.find((io) => io.id === value);
          } else {
            let prerigInput = inputs.find((i) => i.id === 4);
            selectedPrerig = prerigInput.inputOptions.find(
              (io) => io.id === prerigInput.value
            );
          }

          let selectedPerformancePackage = null;
          if (input.id === 16) {
            selectedPerformancePackage = value;
          } else {
            selectedPerformancePackage = inputs.find((i) => i.id === 16).value;
          }

          let pfValue = 0;
          switch (selectedPerformancePackage) {
            case 2368:
              pfValue = 0;
              break;
            case 275:
              pfValue = 1;
              break;
            case 276:
              pfValue = 2;
              break;
            case 277:
              pfValue = 3;
              break;
          }

          let availableMotors =
            selectedPrerig === null
              ? []
              : selectedPrerig.prerigMotors.filter(
                  (pm) =>
                    pm.performance <= pfValue &&
                    (pm.twin === null || pm.twin === pm.motor)
                );

          let twinMotorUpgrade = updatedInputs.find((ui) => ui.id === 364);
          let availableTwinMotors =
            selectedPrerig === null
              ? []
              : selectedPrerig.prerigMotors.filter(
                  (pm) => pm.performance <= pfValue && pm.twin !== null
                );
          console.log('Available Motors: ', availableMotors);
          // Check if selected motor is no longer selected
          let updatedMotorOption = {
            ...i,
            inputOptions: i.inputOptions.map((io) => {
              let foundMotor = availableMotors.find((am) => am.motor === io.id);
              let twin = { name: '', price: 0, motor: null };
              if (foundMotor) {
                if (twinMotorUpgrade.value) {
                  let currentTwin = availableTwinMotors.find(
                    (tm) => tm.twin === foundMotor?.motor
                  );
                  let twinMotor = i.inputOptions.find(
                    (io) => io.id === currentTwin.motor
                  );
                  twin.name = twinMotor.avalonName;
                  twin.motor = twinMotor.id;
                  twin.price = currentTwin.epicorPrice;
                  twin.mainPrice = foundMotor.epicorPrice + currentTwin.epicorPrice;
                }
              }

              return {
                ...io,
                active: foundMotor !== undefined,
                price: foundMotor?.epicorPrice,
                twin,
              };
            }),
          };
          if (
            updatedMotorOption.inputOptions.find(
              (io) => io.id === updatedMotorOption.value
            )?.active
          ) {
            return updatedMotorOption;
          } else {
            return { ...updatedMotorOption, value: '' };
          }
        } else {
          if (i.id === input.id) {
            return { ...input, value: value, touched: true };
          } else {
            return i;
          }
        }
      });

      if (propAndTransomCheck) {
        // prop = 349
        // transom = 24
        updatedInputs = updatedInputs.map((i) => {
          if (i.id === 24 || i.id === 349) {
            let motorOption = updatedInputs.find((i) => i.id === 6);
            let selectedMotorOption = motorOption.inputOptions.find(
              (io) => io.id === motorOption.value
            );

            if (selectedMotorOption) {
              switch (i.id) {
                case 24:
                  return {
                    ...i,
                    value: selectedMotorOption.motorOptionSetting[0].transom,
                  };
                case 349:
                  let unselectProp = false;
                  let updatedOptions = i.inputOptions.map((io) => {
                    let isActive = false;
                    if (io.id === 1985) {
                      isActive =
                        selectedMotorOption.motorOptionSetting[0].alumPropPart !==
                        'NA';
                      unselectProp = !isActive && i.value === 1985;
                      return {
                        ...io,
                        active: isActive,
                      };
                    }
                    if (io.id === 1984) {
                      isActive =
                        selectedMotorOption.motorOptionSetting[0].sstPropPart !==
                        'NA';
                      unselectProp = !isActive && i.value === 1984;
                      return {
                        ...io,
                        active: isActive,
                      };
                    }
                    return io;
                  });
                  console.log('PROP: ', unselectProp, updatedOptions);
                  return {
                    ...i,
                    inputOptions: updatedOptions,
                    value: unselectProp ? '' : i.value,
                  };
              }
            } else {
              switch (i.id) {
                case 24:
                  return { ...i, value: false };
                case 349:
                  return {
                    ...i,
                    inputOptions: i.inputOptions.map((io) => {
                      return { ...io, active: true };
                    }),
                    value: '',
                  };
              }
            }
            return i;
          } else {
            return i;
          }
        });
      }
    }

    if (input.type === 'CHECKBOX' || input.type === 'PACKAGE') {
      // Convert checkbox values to 1 and 0 for rule checking
      let currentValue = input.value === true ? 1 : 0;
      let newValue = value === true ? 1 : 0;

      // gtm for dropdowns
      if (newValue === 1) {
        let modelName = parseBrandName(brand, selectedModel);

        window.dataLayer.push({
          event: selectedPage.name,
          eventCategory: 'checkbox',
          eventAction: {
            model: modelName,
            input: input.name,
            option: null,
          },
          eventLabel: `${modelName}|${selectedFloorplan.floorplan.avalonName}|${
            formType === 'd' && !customerBuild ? price : Math.round(price / 0.64)
          } MSRP`,
        });
      }

      if (input.rulesX.length > 0) {
        // console.log('CHECKBOX HAS RULES X: ', input);
        let oldOptionRules = input.rulesX.filter((r) => r.yValue === currentValue);

        if (currentValue === 0)
          oldOptionRules = oldOptionRules.filter((r) => r.changeType !== 'DEFAULT');
        // console.log('CHECKBOX OLD OPTION RULES: ', oldOptionRules);

        let affectedInputRulesLog = [];
        for (let rule of oldOptionRules) {
          // special use case for affecting floorplan price
          if (
            rule.zId === 2 &&
            rule.zDropdownId === selectedFloorplan.floorplan.id
          ) {
            let newSelectedFloorplan = {
              ...selectedFloorplan,
              floorplan: {
                ...selectedFloorplan.floorplan,
                price: selectedFloorplan.floorplan.defaultPrice,
              },
            };
            delete newSelectedFloorplan.floorplan.rules.price;
            setSelectedFloorplan(newSelectedFloorplan);
          }
          let affectedInput = updatedInputs.find((input) => input.id === rule.zId);

          if (affectedInput !== undefined) {
            affectedInputRulesLog.push({
              changeType: rule.changeType,
              rule,
              affectedInput,
            });

            affectedInput = applyRule(affectedInput, rule, false);
            updatedInputs = updatedInputs.map((i) => {
              if (i.id === affectedInput.id) {
                return affectedInput;
              } else {
                return i;
              }
            });

            if (
              affectedInput.rulesX.length > 0 &&
              rule.changeType === 'DEFAULT' &&
              affectedInput.type === 'CHECKBOX'
            ) {
              let oldValue = parseInt(rule.changeValue, 10);
              let oldValueRules = affectedInput.rulesX.filter(
                (r) => r.yValue === oldValue
              );
              let newValue = rule.changeValue === '1' ? 0 : 1;
              let newValueRules = affectedInput.rulesX.filter(
                (r) => r.yValue === newValue
              );
              for (let rule of oldValueRules) {
                let nestedAffectedInput = updatedInputs.find(
                  (input) => input.id === rule.zId
                );
                if (nestedAffectedInput !== undefined) {
                  nestedAffectedInput = applyRule(nestedAffectedInput, rule, false);
                  updatedInputs = updatedInputs.map((i) => {
                    if (i.id === nestedAffectedInput.id) {
                      return nestedAffectedInput;
                    } else {
                      return i;
                    }
                  });
                }
              }
              for (let rule of newValueRules) {
                let nestedAffectedInput = updatedInputs.find(
                  (input) => input.id === rule.zId
                );
                if (nestedAffectedInput !== undefined) {
                  nestedAffectedInput = applyRule(nestedAffectedInput, rule, true);
                  updatedInputs = updatedInputs.map((i) => {
                    if (i.id === nestedAffectedInput.id) {
                      return nestedAffectedInput;
                    } else {
                      return i;
                    }
                  });
                }
              }
            }
          }
        }

        let newOptionRules = input.rulesX.filter((r) => r.yValue === newValue);
        let newOptionRulesLog = [];
        for (let rule of newOptionRules) {
          // special use case for affecting floorplan price
          if (
            rule.zId === 2 &&
            rule.zDropdownId === selectedFloorplan.floorplan.id
          ) {
            setSelectedFloorplan({
              ...selectedFloorplan,
              floorplan: {
                ...selectedFloorplan.floorplan,
                price: +rule.changeValue,
                rules: {
                  ...selectedFloorplan.floorplan.rules,
                  price: rule,
                },
              },
            });
          }
          let affectedInput = updatedInputs.find((input) => input.id === rule.zId);
          if (affectedInput !== undefined) {
            newOptionRulesLog.push({
              changeType: rule.changeType,
              rule,
              affectedInput,
            });

            affectedInput = applyRule(affectedInput, rule, true);

            updatedInputs = updatedInputs.map((i) => {
              if (i.id === affectedInput.id) {
                return affectedInput;
              } else {
                return i;
              }
            });

            if (affectedInput.rulesX.length > 0 && rule.changeType === 'DEFAULT') {
              let currentAffectedInputValue = null;
              if (affectedInput.type === 'DROPDOWN') {
                currentAffectedInputValue =
                  affectedInput.value === '' ? 0 : affectedInput.value;
              } else {
                currentAffectedInputValue = affectedInput.value === true ? 1 : 0;
              }
              if (affectedInput.id === 185)
                console.log(
                  `CURRENT AFFECTED INPUT VALUE: ${affectedInput.value}, NEW VALUE: ${rule.changeValue}`
                );
              if (
                currentAffectedInputValue === parseInt(rule.changeValue, 10) &&
                rule.changeValue === '0'
              ) {
                console.log(
                  `${affectedInput.name} is already default rule value, skip applying rules.`
                );
                continue;
              }

              // if (affectedInput.id === 185)
              //   console.log(
              //     'APPLYING DEFAULT RULE AND NEED TO REEVALUATE AFFECTED INPUT: ',
              //     affectedInput,
              //     affectedInput.rulesX
              //   );

              let currentAffectedInputRules = affectedInput.rulesX.filter(
                (r) => r.yValue === currentAffectedInputValue
              );

              for (let ar of currentAffectedInputRules) {
                let nestedAffectedInput = updatedInputs.find(
                  (input) => input.id === ar.zId
                );
                if (nestedAffectedInput !== undefined) {
                  nestedAffectedInput = applyRule(nestedAffectedInput, ar, true);
                  updatedInputs = updatedInputs.map((i) => {
                    if (i.id === nestedAffectedInput.id) {
                      return nestedAffectedInput;
                    } else {
                      return i;
                    }
                  });
                }
              }
            }
          }
        }
        console.log('APPLIED SINGLE INPUT RULES: ', newOptionRulesLog);
      }

      if (input.conditionsX.length > 0) {
        updatedInputs = evaluateRuleGroups(
          input,
          currentValue,
          newValue,
          updatedInputs
        );
      }

      updatedInputs = updatedInputs.map((i) => {
        if (i.id === input.id) {
          return { ...i, value: value, touched: true };
        } else {
          // unselect pre-rig and motor on twin motor select
          if (input.id === 364 && newValue && i.id === 4) {
            return { ...i, value: '' };
          } else if (input.id === 364 && newValue && i.id === 6) {
            return {
              ...i,
              inputOptions: i.inputOptions.map((io) => {
                return { ...io, active: false };
              }),
              value: '',
            };
          } else {
            return i;
          }
        }
      });
    }

    if (input.type === 'TEXT') {
      updatedInputs = updatedInputs.map((i) => {
        if (i.id === input.id) {
          return { ...i, value: value, touched: true };
        } else {
          return i;
        }
      });
    }

    // Calculate price
    let calculatedPrice = Math.ceil(selectedFloorplan.floorplan.price);
    for (let i of updatedInputs) {
      if (i.type === 'DROPDOWN') {
        if (i.value !== '' && i.value !== null) {
          let tmpOption = i.inputOptions.find((io) => io.id === i.value);
          if (tmpOption === undefined) {
            // console.log('BAD VALUE SET: ', i, i.value);
          } else {
            if (tmpOption.price) {
              if (i.id === 6 && tmpOption?.twin?.motor) {
                calculatedPrice += Math.ceil(tmpOption.twin.mainPrice);
              } else {
                calculatedPrice += Math.ceil(tmpOption.price);
              }
            }
          }
        }
      } else {
        if (i.value === true) {
          calculatedPrice += Math.ceil(+i.price);
        }
      }
    }

    setPrice(calculatedPrice);
    setInputs(updatedInputs);
  };

  const handlePageChange = (page) => {
    if (searchParams) {
      navigate(
        `/order/${orderId}/${formType}/${brand}/model/${modelId}/floorplan/${floorplanId}/page/${
          parseInt(pageId, 10) + 1
        }?` + searchParams
      );
    } else {
      navigate(
        `/order/${orderId}/${formType}/${brand}/model/${modelId}/floorplan/${floorplanId}/page/${
          parseInt(pageId, 10) + 1
        }`
      );
    }
  };

  const handleBackToCustom = () => {
    setIsSubmit(false);
  };

  const handleNextButtonPageChange = async (confirmed = false) => {
    if (
      orderId === 'new' &&
      formType === 'c' &&
      parseInt(pageId, 10) === pages.length + 1 &&
      !isSubmit
    ) {
      return setIsSubmit(true);
    }
    if (parseInt(pageId, 10) === pages.length + 1) {
      if (orderId === 'new') {
        if (
          modelConfirmation.my_confirmation === 'ON' &&
          !modelConfirmation.confirmed &&
          !confirmed
        ) {
          return handleOpenMYConfirmation('create');
        }
        console.log('TIME TO SUBMIT');
        let orderData = {
          order: {},
          orderOptions: [],
          orderNotes: [],
        };

        // create model option
        let modelOption = {
          inputId: 1,
          inputOptionId: selectedModel.id,
          price: 0,
          optionName: 'Model',
          optionDropdownName: parseBrandName(brand, selectedModel),
          optionImgPath: selectedModel.imgPath,
        };

        let floorplanOption = {
          inputId: 2,
          inputOptionId: selectedFloorplan.floorplan.id,
          price: selectedFloorplan.floorplan.price,
          optionName: 'Floorplan',
          optionDropdownName: selectedFloorplan.floorplan.avalonName,
          optionImgPath: selectedFloorplan.floorplan.imgPath,
        };

        orderData.orderOptions.push(modelOption);
        orderData.orderOptions.push(floorplanOption);

        // now add inputs
        inputs
          .filter(
            (input) =>
              (input.active && input.type === 'DROPDOWN') ||
              (input.type === 'CHECKBOX' && input.value !== false) ||
              (input.type === 'TEXT' && input.value !== '')
          )
          .map((input) => {
            let option = {
              inputId: input.id,
              optionName: input?.name,
            };

            if (input.name === undefined)
              return console.log('ERROR WITH INPUT: ', input);

            let twinMotorOption = null;
            const hasTwin = inputs.find((i) => i.id === 364).value;

            switch (input.type) {
              case 'DROPDOWN':
                let foundInputOption = input.inputOptions.find(
                  (io) => io.id === input.value
                );
                if (foundInputOption === undefined) {
                  // console.log(
                  //   'ERROR - COULD NOT FIND INPUT OPTION: ',
                  //   input,
                  //   input.value,
                  //   input.inputOptions
                  // );
                  option.inputOptionId = null;
                  option.price = 0;
                  option.optionDropdownName = 'Not Selected';
                  option.optionImgPath = null;
                  break;
                }
                option.inputOptionId = input.value;
                option.price = foundInputOption.price;
                if (input.id === 249) {
                  option.optionDropdownName = parseBrandName(
                    brand,
                    foundInputOption
                  );
                } else {
                  option.optionDropdownName =
                    input.branded && brand === 't'
                      ? foundInputOption.tahoeName
                      : foundInputOption.avalonName;
                }
                option.optionImgPath = foundInputOption.imgPath;

                if (input.id === 6 && hasTwin) {
                  twinMotorOption = { ...option };
                  if (foundInputOption?.twin.name === undefined)
                    console.log('ERROR WITH TWIN MOTOR OPTION: ', foundInputOption);
                  twinMotorOption.optionDropdownName = foundInputOption.twin?.name;
                  twinMotorOption.inputOptionId = foundInputOption.twin.motor;
                  twinMotorOption.price = foundInputOption.twin.price;
                  orderData.orderOptions.push(option);
                }
                break;
              case 'CHECKBOX':
                option.price = input.price;
                if (input.branded && brand === 't') {
                  option.optionName = input.tahoeName;
                }
                break;
              case 'TEXT':
                option.inputTextValue = input.value;
                break;
            }

            return orderData.orderOptions.push(
              twinMotorOption !== null ? twinMotorOption : option
            );
          });

        // add notes
        if (orderNote.length > 0) {
          orderData.orderNotes.push({
            userId: userContext.userState.me.id,
            pageId: null,
            text: orderNote,
          });
        }
        // This is legacy support for page notes
        for (let page of pages) {
          if (page.note !== undefined && page.note.length > 0) {
            orderData.orderNotes.push({
              userId: userContext.userState.me.id,
              pageId: page.id,
              text: page.note,
            });
          }
        }

        let orderUserId = null;
        let orderDealershipId = null;
        let orderLocationId = null;
        let orderSource = formType === 'c' ? 2 : 1;

        if (formType === 'c') {
          orderDealershipId = customerInfo.selectedLocation?.dealershipId;
          orderLocationId = customerInfo.selectedLocation?.id;

          window.dataLayer.push({
            event: 'CompleteBuild',
            eventCategory: 'Send to Dealer',
            eventLabel: `${
              brand === 'a' ? selectedModel.avalonName : selectedModel.tahoeName
            }|${selectedFloorplan.floorplan.avalonName}|${
              formType === 'd' && !customerBuild ? price : Math.round(price / 0.64)
            } MSRP`,
          });
        } else {
          orderUserId = userContext.userState.me.id;
          orderDealershipId =
            dealershipId !== undefined
              ? parseInt(dealershipId, 10)
              : userContext.userState.me?.dealership?.id;
          orderLocationId =
            userLocations !== null ? userLocations.value : +locationId;
        }

        orderData.order = {
          userId: orderUserId,
          dealershipId: orderDealershipId,
          locationId: orderLocationId,
          orderStatus: 0,
          orderSource: orderSource,
          customerName: customerInfo.customerName,
          customerEmail: customerInfo.customerEmail,
          customerPhone: customerInfo.customerPhone,
          customerZipcode:
            customerInfo.customerZipcode !== ''
              ? customerInfo.customerZipcode
              : null,
          po: customerInfo.po,
          location: {
            name: customerInfo.selectedLocation?.name,
            notificationList: customerInfo.selectedLocation?.notificationList,
          },
        };

        console.log('FINAL DATA: ', orderData);
        setSubmittingOrder(true);

        try {
          const { data } = await axios.post('/api/order', orderData);

          if (data.message) {
            console.log(data.message);

            if (formType === 'c') {
              setOpenCustomerSubmit(true);
            } else {
              if (dealershipId !== undefined && locationId !== undefined) {
                return navigate(
                  `/dealerships/${dealershipId}/locations/${locationId}/current/quote`
                );
              }
              if (userContext.userState.me?.locations[0]?.locationId === undefined) {
                return navigate(
                  `/dealerships/${userContext.userState.me.dealership.id}/locations/${userContext.userState.me.locations[0].id}/current/quote`
                );
              } else {
                if (formType === 'd' && customerBuild) {
                  if (window.location.hostname === 'localhost') {
                    window.location.replace(
                      `${window.location.protocol}//${window.location.hostname}:3000/locations/all/current/quote/${data.id}/?msrp=1`
                    );
                  } else {
                    window.location.replace(
                      `${window.location.protocol}//${window.location.hostname}/locations/all/current/quote/${data.id}/?msrp=1`
                    );
                  }
                } else {
                  return navigate(`/locations/all/current/quote`);
                }
              }
            }
          }
        } catch (err) {
          setSubmittingOrder(false);
          console.log(err.response.data.message);
        }
      } else {
        if (
          modelConfirmation.my_confirmation === 'ON' &&
          !modelConfirmation.confirmed &&
          !confirmed
        ) {
          console.log(modelConfirmation);
          return handleOpenMYConfirmation('update');
        }
        console.log('UPDATE ORDER');
        // let orderInputs = inputs.filter(i => i.orderDefaultValue !== undefined)
        // update options
        let updatedOptionValues = [];
        let createdOptionValues = [];
        let newOptions = inputs.filter(
          (i) =>
            i.orderDefaultValue === undefined &&
            i.value !== false &&
            i.value !== '' &&
            i.active &&
            i.value !== null
        );

        let newInactiveOptions = inputs.filter(
          (i) =>
            i.orderDefaultValue === undefined &&
            i.value !== false &&
            i.value !== '' &&
            !i.active
        );

        // even if value is the same we'll allow it to be updated to fix any pricing issues
        let updatedOptions = inputs.filter(
          (i) =>
            i.orderDefaultValue !== undefined &&
            (i.orderDefaultValue !== i.value || i.orderDefaultValue === i.value) &&
            i.value !== false &&
            i.value !== '' &&
            i.active
        );

        let inactiveUpdatedOptions = inputs.filter(
          (i) =>
            i.orderDefaultValue !== undefined &&
            (i.orderDefaultValue !== i.value || i.orderDefaultValue === i.value) &&
            i.value !== false &&
            i.value !== '' &&
            !i.active
        );

        let notSelectedOptions = inputs.filter(
          (input) =>
            input.type === 'DROPDOWN' && input.value === '' && input.orderOptionId
        );

        for (let nso of notSelectedOptions) {
          let option = {};

          if (nso.orderDefaultValue !== nso.value) {
            // Unselecting option
            option = {
              where: {
                id: parseInt(nso.orderOptionId, 10),
              },
              data: {
                orderId: parseInt(orderId, 10),
                inputId: nso.id,
                optionName: nso.name,
              },
              type: 'new',
            };
          } else {
            option = {
              where: {
                id: parseInt(nso.orderOptionId, 10),
              },
              data: {
                inputId: nso.id,
                orderId: parseInt(orderId, 10),
              },
              type: 'update',
            };
          }

          updatedOptionValues.push({ option });
        }

        for (let uo of updatedOptions) {
          let tmpUo = { ...uo };
          let option = {
            where: {
              id: parseInt(uo.orderOptionId, 10),
            },
            data: {
              inputId: uo.id,
              optionName: uo.name,
              orderId: parseInt(orderId, 10),
            },
          };
          let motorOptions = [];
          let twinOption = null;
          const twinPackageOption = inputs.find((i) => i.id === 364);
          let needsNewTwinMotorOption = false;

          switch (uo.type) {
            case 'DROPDOWN':
              let foundInputOption = uo.inputOptions.find(
                (io) => io.id === uo.value
              );
              if (uo.id === 6) {
                motorOptions = oldOrder.options.filter((o) => o.inputId === 6);
                if (
                  foundInputOption.twin &&
                  oldOrder.options.filter((o) => o.inputId === 6)[0]
                    .inputOptionId === null
                ) {
                  needsNewTwinMotorOption = true;
                }
              }
              if (!foundInputOption) {
                // console.log('MISSING DROPDOWN OPTION: ', uo, uo.value);
                // Not Selected Input Options
                option.type = 'update';
              } else {
                option.data.inputId = uo.id;
                option.data.inputOptionId = uo.value;
                option.data.price = foundInputOption?.price;
                option.data.optionImgPath = foundInputOption?.imgPath;

                if (uo.id === 249) {
                  option.data.optionDropdownName = parseBrandName(
                    brand,
                    foundInputOption
                  );
                } else {
                  option.data.optionDropdownName =
                    uo.branded && brand === 't'
                      ? foundInputOption?.tahoeName
                      : foundInputOption?.avalonName;
                }

                if (
                  uo.orderOptionPrice !== foundInputOption.price ||
                  parseInt(uo.orderDefaultValue, 10) !== parseInt(uo.value, 10)
                ) {
                  option.type = 'new';
                  // console.log(option);
                } else {
                  option.type = 'update';
                }

                if (
                  uo.id === 6 &&
                  twinPackageOption?.orderOptionId &&
                  twinPackageOption.value &&
                  !needsNewTwinMotorOption
                ) {
                  console.log(needsNewTwinMotorOption);
                  twinOption = {
                    where: {
                      id: parseInt(motorOptions[1].id, 10),
                    },
                    data: {
                      inputId: uo.id,
                      optionName: motorOptions[1].optionName,
                      optionDropdownName: foundInputOption.twin.name,
                      orderId: parseInt(orderId, 10),
                      inputOptionId: foundInputOption.twin.motor,
                      price: foundInputOption.twin.price,
                    },
                    type: option.type,
                  };

                  console.log('UPDATING TWIN OPTION: ', twinOption);
                }

                if (
                  (uo.id === 6 &&
                    !twinPackageOption?.orderOptionId &&
                    twinPackageOption.value) ||
                  needsNewTwinMotorOption
                ) {
                  let newTwinOption = {
                    inputId: uo.id,
                    optionName: uo.name,
                    orderId: parseInt(orderId, 10),
                  };
                  if (foundInputOption?.twin.name === undefined)
                    console.log('ERROR WITH TWIN MOTOR OPTION: ', foundInputOption);
                  newTwinOption.optionDropdownName = foundInputOption.twin?.name;
                  newTwinOption.inputOptionId = foundInputOption.twin.motor;
                  newTwinOption.price = foundInputOption.twin.price;
                  createdOptionValues.push({ option: newTwinOption });
                }
              }

              break;
            case 'CHECKBOX':
              option.data.price = uo.price;
              option.type = uo.price === uo.orderOptionPrice ? 'update' : 'new';
              break;
            case 'TEXT':
              option.data.inputTextValue = uo.value.toString();
              option.type =
                uo.value.toString() === uo.orderOptionTextValue.toString()
                  ? 'update'
                  : 'new';
              break;
          }
          tmpUo.option = option;
          // Remove input options after setting option data
          delete tmpUo.inputOptions;
          // if (option.type === 'new') console.log(option);
          updatedOptionValues.push({ option });
          if (twinOption !== null) updatedOptionValues.push({ option: twinOption });
        }

        let modelOption = {
          option: {
            where: {
              id: oldOrder.options.find((o) => o.inputId === 1).id,
            },
            data: {
              inputId: 1,
              inputOptionId: selectedModel.id,
              price: 0,
              optionName: 'Model',
              optionDropdownName: parseBrandName(brand, selectedModel),
              optionImgPath: selectedModel.imgPath,
            },
            type: 'update',
          },
        };

        let floorplanOption = {
          option: {
            where: {
              id: oldOrder.options.find((o) => o.inputId === 2).id,
            },
            data: {
              inputId: 2,
              inputOptionId: selectedFloorplan.id,
              price: selectedFloorplan.floorplan.price,
              optionName: 'Floorplan',
              optionDropdownName:
                brand === 'a'
                  ? selectedFloorplan.avalonName
                  : selectedFloorplan.tahoeName,
              optionImgPath: selectedFloorplan.floorplan.imgPath,
            },
            type: 'update',
          },
        };

        updatedOptionValues.push(modelOption);
        updatedOptionValues.push(floorplanOption);

        // new options
        for (let no of newOptions) {
          let tmpNo = { ...no };
          let option = {
            inputId: no.id,
            optionName: no.name,
            orderId: parseInt(orderId, 10),
          };
          let twinOption = null;
          const twinPackageOption = inputs.find((i) => i.id === 364);

          switch (no.type) {
            case 'DROPDOWN':
              let foundInputOption = no.inputOptions.find(
                (io) => io.id === no.value
              );
              if (!foundInputOption) {
                console.log(no, no.value);
              }
              option.inputOptionId = no.value;
              option.price = foundInputOption?.price ? foundInputOption.price : 0;
              option.optionDropdownName =
                no.branded && brand === 't'
                  ? foundInputOption.tahoeName
                  : foundInputOption.avalonName;
              option.optionImgPath = foundInputOption.imgPath;
              if (
                no.id === 6 &&
                !twinPackageOption?.orderOptionId &&
                twinPackageOption.value
              ) {
                twinOption = { ...option };
                if (foundInputOption?.twin.name === undefined)
                  console.log('ERROR WITH TWIN MOTOR OPTION: ', foundInputOption);
                twinOption.optionDropdownName = foundInputOption.twin?.name;
                twinOption.inputOptionId = foundInputOption.twin.motor;
                twinOption.price = foundInputOption.twin.price;
                createdOptionValues.push({ option });
              }
              break;
            case 'CHECKBOX':
              option.price = parseInt(no.price, 10);
              // console.log('NEW CHECKBOX: ', no);
              if (no.branded && brand === 't') option.optionName = no.tahoeName;
              break;
            case 'TEXT':
              option.inputTextValue = no.value.toString();
              break;
          }

          tmpNo.option = option;
          delete tmpNo.inputOptions;
          delete tmpNo.rulesX;
          delete tmpNo.conditionsX;
          delete tmpNo.tooltip;
          delete tmpNo.rules;
          createdOptionValues.push(
            twinOption !== null ? { option: twinOption } : { option }
          );
        }

        let notes = {
          update: [],
          create: [],
          delete: [],
        };

        for (let p of pages) {
          if (p.note !== '' && p.noteId !== undefined)
            notes.update.push({ id: p.noteId, text: p.note });
          if (p.note !== '' && p.noteId === undefined)
            notes.create.push({
              orderId: parseInt(orderId, 10),
              userId: userContext.userState.me.id,
              pageId: p.id,
              text: p.note,
            });
          if (p.note === '' && p.noteId !== undefined)
            notes.delete.push({ id: p.noteId });
        }

        let values = {
          orderId: orderId,
          userId: userContext.userState.me.id,
          locationId: userLocation,
          orderVersion: oldOrder.orderVersion,
          lastModified: formatISO(new Date()),
          customerName: customerInfo.customerName,
          customerEmail: customerInfo.customerEmail,
          customerPhone: customerInfo.customerPhone,
          po: customerInfo.po,
          update: updatedOptionValues,
          delete: [],
          create: createdOptionValues,
          notes,
        };
        // console.log(values.update.filter((o) => o.option.type === 'new'));

        if (newInactiveOptions.length > 0 || inactiveUpdatedOptions.length > 0)
          console.log(
            'Skipping these options: ',
            newInactiveOptions,
            inactiveUpdatedOptions
          );

        setSubmittingOrder(true);
        try {
          const { data } = await axios.post('/api/order/update', values);
          if (data.message) {
            console.log(data.message);
            setSubmittingOrder(false);
            if (userContext.userState.me.locations[0] === undefined)
              navigate(
                `/dealerships/${oldOrder.dealership.id}/locations/${oldOrder.location.id}/current/quote`
              );
            if (userContext.userState.me.locations[0].locationId === undefined) {
              navigate(
                `/dealerships/${userContext.userState.me.dealership.id}/locations/${userContext.userState.me.locations[0].id}/current/quote`
              );
            } else {
              navigate(`/locations/all/current/quote`);
            }
          }
        } catch (err) {
          setSubmittingOrder(false);
          console.log(err);
        }
      }
    } else {
      let baseUrl = '';
      if (dealershipId && locationId) {
        baseUrl = `/dealerships/${dealershipId}/locations/${locationId}`;
      }
      if (searchParams) {
        navigate(
          `${baseUrl}/order/${orderId}/${formType}/${brand}/model/${modelId}/floorplan/${floorplanId}/page/${
            parseInt(pageId, 10) + 1
          }?` + searchParams
        );
      } else {
        navigate(
          `${baseUrl}/order/${orderId}/${formType}/${brand}/model/${modelId}/floorplan/${floorplanId}/page/${
            parseInt(pageId, 10) + 1
          }`
        );
      }
    }
  };

  const handleCustomerNewBuildClick = () => {
    if (window.location.hostname === 'localhost') {
      window.location.replace(
        `${window.location.protocol}//${window.location.hostname}:3000/order/new/c/${brand}`
      );
    } else {
      handleOpenCustomerSubmit();
      window.location.replace(
        `${window.location.protocol}//${window.location.hostname}/order/new/c/${brand}`
      );
    }
  };

  const verifySubmit = () => {
    if (submittingOrder) return false;
    if (customerInfo.po.length > 25 || customerInfo.customerName.length > 25)
      return false;
    if (
      submittingOrder ||
      (userLocations?.value === '' &&
        userContext.userState.me.isAuthenticated &&
        parseInt(pageId, 10) === pages.length + 1)
    )
      return false;

    if (parseInt(pageId, 10) !== pages.length + 1) {
      return true;
    } else if (!isSubmit) {
      return (
        inputs.filter(
          (i) => i.required && i.value === '' && i.active && i.pageId !== 9
        ).length === 0 && parseInt(pageId, 10) === pages.length + 1
      );
    } else {
      return (
        customerInfo.selectedLocation !== null &&
        customerInfo.customerName !== '' &&
        customerInfo.customerPhone !== ''
      );
    }
  };

  const handleOpenTooltip = (input) => {
    setTooltip({ ...input.tooltip, selectedMedia: 0 });
    setOpenTooltip(true);
  };

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
    setTooltip(null);
  };

  const renderTooltipMedia = (media) => {
    if (media === undefined) return null;
    switch (media.type) {
      case 'image':
        return (
          <img
            className={classes.tooltipImage}
            src={`https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/images/${
              tooltip.media[tooltip.selectedMedia].src
            }`}
            alt={tooltip.media[tooltip.selectedMedia].alt}
          />
        );
      case 'video':
        return (
          <iframe
            className={classes.tooltipVideo}
            src={tooltip.media[tooltip.selectedMedia].src}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        );
      default:
        break;
    }
  };

  const handleNav = (pageIdx) => {
    let baseUrl = '';
    if (dealershipId && locationId) {
      baseUrl = `/dealerships/${dealershipId}/locations/${locationId}`;
    }
    if (pageIdx === 'floorplans') {
      if (searchParams) {
        navigate(
          `${baseUrl}/order/${orderId}/${formType}/${brand}/model/${modelId}?` +
            searchParams
        );
      } else {
        navigate(
          `${baseUrl}/order/${orderId}/${formType}/${brand}/model/${modelId}`
        );
      }
    } else if (pageIdx === 'models') {
      if (searchParams) {
        navigate(`${baseUrl}/order/${orderId}/${formType}/${brand}?` + searchParams);
      } else {
        navigate(`${baseUrl}/order/${orderId}/${formType}/${brand}`);
      }
    } else {
      if (searchParams) {
        navigate(
          `${baseUrl}/order/${orderId}/${formType}/${brand}/model/${modelId}/floorplan/${floorplanId}/page/${
            pageIdx + 1
          }?` + searchParams
        );
      } else {
        navigate(
          `${baseUrl}/order/${orderId}/${formType}/${brand}/model/${modelId}/floorplan/${floorplanId}/page/${
            pageIdx + 1
          }`
        );
      }
    }
  };

  const showNextImage = () => {
    if (selectedImage < 2) {
      setSelectedImage(selectedImage + 1);
    } else {
      setSelectedImage(0);
    }
  };

  const showPrevImage = () => {
    if (selectedImage > 0) {
      setSelectedImage(selectedImage - 1);
    } else {
      setSelectedImage(2);
    }
  };

  const download = async () => {
    try {
      setLoadingPDF(true);

      // push to data layer
      window.dataLayer.push({
        event: 'CompleteBuild',
        eventCategory: 'Download pdf',
        eventLabel: `${
          brand === 'a' ? selectedModel.avalonName : selectedModel.tahoeName
        }|${selectedFloorplan.floorplan.avalonName}|${
          formType === 'd' && !customerBuild ? price : Math.round(price / 0.64)
        } MSRP`,
      });

      let t = document.getElementById('pdf').innerHTML;
      var opt = {
        margin: [5, 2],
        filename: `Quote_${
          brand === 'a'
            ? selectedModel.avalonName.replace("'", '').replace(' ', '_')
            : selectedModel.tahoeName.replace("'", '').replace(' ', '_')
        }_${selectedFloorplan.floorplan.avalonName
          .replace("'", '')
          .replace(/\s/g, '_')}_${format(new Date(), 'MM_dd_yyyy')}`,
        html2canvas: {
          scale: 4,
          useCORS: true,
        },
        pagebreak: {
          before: '.pagebreak',
        },
      };
      await html2pdf().set(opt).from(t).save();
      setLoadingPDF(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsClick = (e) => {
    console.log(e);
    setAnchorEl(e.currentTarget);
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <Grid container item>
          <div>
            <Menu
              id="settings-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleSettingsClose}
            >
              {prevBreadcrumbs.map((bread, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    bread.onClick();
                    handleSettingsClose();
                  }}
                >
                  {bread.name}
                </MenuItem>
              ))}
            </Menu>
            <Breadcrumbs
              separator={
                <NavigateNextIcon
                  fontSize="small"
                  sx={{
                    color: '#171A20',
                    opacity: '0.2',
                  }}
                />
              }
              aria-label="breadcrumb"
              className={clsx({ [classes.mobileBreadcrumbs]: isMobile })}
            >
              {isMobile && prevBreadcrumbs.length === 0
                ? [...mobileBreadcrumbs]
                : null}
              {isMobile && prevBreadcrumbs.length > 0
                ? [
                    <Button
                      onClick={handleSettingsClick}
                      sx={{
                        fontSize: '24px',
                        color: '#171A20',
                        opacity: '0.2',
                        lineHeight: 'unset',
                        minWidth: 'unset',
                        padding: '0px',
                      }}
                    >
                      ...
                    </Button>,
                    ...mobileBreadcrumbs,
                  ]
                : null}
              {!isMobile ? breadcrumbs : null}
            </Breadcrumbs>
          </div>
          <Grid
            container
            spacing={isMobile ? 0 : 2}
            alignItems="stretch"
            className={clsx(classes.root, { [classes.mobileRoot]: isMobile })}
          >
            <div id="pdf" className={classes.pdf}>
              {formType === 'c' ? (
                <CustomerPDF
                  model={selectedModel}
                  modelName={parseBrandName(brand, selectedModel)}
                  floorplan={selectedFloorplan}
                  price={price}
                  pages={pages}
                  inputs={inputs}
                  boatImages={inputs.filter((i) => i.overlayImage === 'BOAT')}
                  benchImages={inputs.filter((i) => i.overlayImage === 'BENCH')}
                  classes={classes}
                  formType={formType}
                  location={customerInfo.selectedLocation}
                  brand={brand}
                />
              ) : null}
            </div>
            <Grid item className={classes.pagePadding} xs={12} sm={12} md={7} xl={7}>
              <Grid
                container
                alignContent="space-between"
                alignItems="center"
                className={classes.orderTitle}
              >
                {selectedFloorplan !== null && (
                  <Grid item>
                    {orderId !== 'new' && (
                      <Typography variant="body1" color="GrayText" sx={{ mb: 1 }}>
                        {`ATOM #${oldOrder.id} - ${orderStatus(
                          oldOrder.orderStatus
                        ).toUpperCase()}
                      `}
                        {oldOrder.epicorOrderNumber !== null &&
                          ` - EPICOR #${oldOrder.epicorOrderNumber}`}
                      </Typography>
                    )}
                    <Typography
                      variant="h3"
                      sx={{
                        display: 'flex',
                        color: '#006991',
                        fontWeight: 'bold',
                      }}
                      className={clsx({ [classes.mobileTitle]: isMobile })}
                    >
                      {parseBrandName(brand, selectedModel)}
                      &nbsp;-&nbsp;$
                      <Odometer
                        value={calculateTotalPrice(
                          inputs,
                          selectedFloorplan.floorplan.price,
                          +modelId,
                          true
                        )}
                        format="(,ddd)"
                      />
                    </Typography>
                    <Typography
                      variant="h6"
                      align="left"
                      sx={{
                        color: '#747679',
                      }}
                      className={clsx({ [classes.mobileFloorType]: isMobile })}
                    >
                      {selectedFloorplan.floorplan.avalonName}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <Grid
                container
                justifyContent="center"
                className={clsx({ [classes.heroImageRoot]: !isMobile })}
              >
                <Grid
                  item
                  xs={12}
                  className={clsx({ [classes.heroImageContainer]: !isMobile })}
                >
                  <div className={classes.heroImageViewer}>
                    <img
                      src="/prev.png"
                      alt="left arrow"
                      className={classes.prevImageBtn}
                      onClick={showPrevImage}
                    />
                    {selectedImage === 0 && (
                      <OrderFormHeroImage
                        inputs={[]}
                        mainSrc={selectedFloorplan.floorplan.imgPath}
                      />
                    )}
                    {selectedImage === 1 && (
                      <OrderFormHeroImage
                        inputs={inputs.filter((i) => i.overlayImage === 'BOAT')}
                        mainSrc={selectedModel.imgPath}
                      />
                    )}
                    {selectedImage === 2 && (
                      <OrderFormHeroImage
                        inputs={inputs.filter((i) => i.overlayImage === 'BENCH')}
                      />
                    )}
                    <img
                      src="/next.png"
                      alt="right arrow"
                      className={classes.nextImageBtn}
                      onClick={showNextImage}
                    />
                  </div>
                </Grid>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item className={classes.mobileImageGrid}>
                    <OrderFormHeroImage
                      isMobile={isMobile}
                      inputs={[]}
                      mainSrc={selectedFloorplan.floorplan.imgPath}
                      isViewed={selectedImage === 0}
                      isPreview={true}
                      onClick={() => setSelectedImage(0)}
                    />
                  </Grid>
                  <Grid item className={classes.mobileImageGrid}>
                    <OrderFormHeroImage
                      isMobile={isMobile}
                      inputs={inputs.filter((i) => i.overlayImage === 'BOAT')}
                      mainSrc={selectedModel.imgPath}
                      isViewed={selectedImage === 1}
                      isPreview={true}
                      onClick={() => setSelectedImage(1)}
                    />
                  </Grid>
                  <Grid item className={classes.mobileImageGrid}>
                    <OrderFormHeroImage
                      isMobile={isMobile}
                      inputs={inputs.filter((i) => i.overlayImage === 'BENCH')}
                      isViewed={selectedImage === 2}
                      isPreview={true}
                      onClick={() => setSelectedImage(2)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.pagePadding} xs={12} sm={12} md={5} xl={5}>
              <Grid container>
                {parseInt(pageId, 10) === pages.length + 1 ? (
                  <Summary
                    isSubmit={isSubmit}
                    inputs={inputs}
                    pages={pages}
                    selectedFloorplan={selectedFloorplan}
                    customerInfo={customerInfo}
                    setCustomerInfo={setCustomerInfo}
                    customerOrderForm={customerOrderForm}
                    loadingLocation={loadingLocation}
                    price={price}
                    selectedModel={selectedModel}
                    formatPrice={formatPrice}
                    userLocation={userLocation}
                    setUserLocation={setUserLocation}
                    setPages={setPages}
                    handleOpenNotes={handleOpenNotes}
                    oldOrder={oldOrder}
                    orderNote={orderNote}
                    userLocations={userLocations}
                    setUserLocations={setUserLocations}
                    customerBuild={customerBuild}
                    calculatePriceWithMarkup={calculatePriceWithMarkup}
                    modelId={modelId}
                  />
                ) : (
                  <Page
                    pageId={parseInt(pageId, 10)}
                    inputs={inputs}
                    selectedPage={selectedPage}
                    pages={pages}
                    setPages={setPages}
                    setSelectedPage={setSelectedPage}
                    handleInputChange={handleInputChange}
                    price={price}
                    setPrice={setPrice}
                    handleOpenTooltip={handleOpenTooltip}
                    formType={formType}
                    selectedModel={selectedModel}
                    brand={brand}
                    formatPrice={formatPrice}
                    showHidden={showHidden}
                    oldOrder={oldOrder}
                    customerBuild={customerBuild}
                    canEditDiscounts={canEditDiscounts}
                    canEditLockedInputs={canEditLockedInputs}
                    calculatePriceWithMarkup={calculatePriceWithMarkup}
                    modelId={modelId}
                  />
                )}
              </Grid>
              <div className={clsx({ [classes.mobileActions]: isMobile })}>
                {formType === 'c' &&
                pages.length !== 0 &&
                parseInt(pageId, 10) === pages.length + 1 ? (
                  <div>
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{
                        background: '#006991',
                      }}
                      className={classes.nextButton}
                      onClick={() => handleNextButtonPageChange()}
                      disabled={!verifySubmit()}
                      fullWidth
                    >
                      {getNextPageName()}
                    </Button>
                    {!isSubmit && (
                      <SecondButton
                        variant="contained"
                        className={classes.secondButton}
                        onClick={() => download()}
                        fullWidth
                      >
                        Download PDF&nbsp;&nbsp;
                        <PictureAsPdfOutlinedIcon />
                      </SecondButton>
                    )}
                  </div>
                ) : (
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                      background: '#006991',
                    }}
                    className={classes.nextButton}
                    onClick={() => handleNextButtonPageChange()}
                    disabled={!verifySubmit()}
                    fullWidth
                  >
                    {getNextPageName()}
                  </Button>
                )}
                {isSubmit && (
                  <SecondButton
                    variant="contained"
                    className={classes.secondButton}
                    onClick={() => handleBackToCustom()}
                    fullWidth
                  >
                    Back To Custom
                  </SecondButton>
                )}
              </div>
              {tooltip !== null ? (
                <Dialog
                  open={openTooltip}
                  onClose={handleCloseTooltip}
                  maxWidth={
                    tooltip.text === null || tooltip.media.length === 0 ? 'sm' : 'md'
                  }
                  fullWidth
                >
                  <DialogTitle>{tooltip.title}</DialogTitle>
                  <DialogContent>
                    {tooltip.text === null ? (
                      <div>
                        {renderTooltipMedia(tooltip.media[tooltip.selectedMedia])}
                      </div>
                    ) : (
                      <Grid container spacing={2}>
                        {tooltip.media.length === 0 ? (
                          <Grid item xs={12}>
                            <Typography variant="body1" paragraph>
                              {tooltip.text}
                            </Typography>
                          </Grid>
                        ) : (
                          <>
                            <Grid item xs={12} sm={6} md={5}>
                              <Typography variant="body1" paragraph>
                                {tooltip.text}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={7}>
                              {renderTooltipMedia(
                                tooltip.media[tooltip.selectedMedia]
                              )}
                              {tooltip.media.length > 1 ? (
                                <div className={classes.tooltipNav}>
                                  {tooltip.media.map((m, index) => {
                                    return (
                                      <FiberManualRecordIcon
                                        key={`m_${index}`}
                                        color={
                                          index === tooltip.selectedMedia
                                            ? 'primary'
                                            : 'disabled'
                                        }
                                        className={classes.tooltipNavButton}
                                        onClick={() =>
                                          setTooltip({
                                            ...tooltip,
                                            selectedMedia: index,
                                          })
                                        }
                                      />
                                    );
                                  })}
                                </div>
                              ) : null}
                            </Grid>
                          </>
                        )}
                      </Grid>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseTooltip} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              ) : null}
              {customerOrderForm ? (
                <Dialog
                  open={openCustomerSubmit}
                  onClose={handleCloseCustomerSubmit}
                  maxWidth="sm"
                  fullWidth
                >
                  <DialogTitle>Build sent to Dealer!</DialogTitle>
                  <DialogContent>
                    <Typography variant="body1" paragraph>
                      Your build was submitted successfully! A dealer will be in
                      contact with you shortly.
                    </Typography>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleCustomerNewBuildClick()}
                    >
                      Build another boat
                    </Button>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseCustomerSubmit} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              ) : null}
              {!customerOrderForm ? (
                <Dialog
                  open={openNotes}
                  onClose={handleCloseNotes}
                  maxWidth="sm"
                  fullWidth
                  aria-labelledby="order-notes"
                >
                  <DialogTitle>Order Notes</DialogTitle>
                  {oldOrder.notes !== undefined && !customerOrderForm ? (
                    <DialogContent>
                      {oldOrder.notes
                        .filter((n) => n.pageId === null)
                        .map((note) => {
                          return (
                            <Box className={classes.note} key={`note_${note.id}`}>
                              <Typography variant="body1">
                                By {note.user.name} at{' '}
                                {format(new Date(note.createdAt), 'MM/dd/yyyy')}
                              </Typography>
                              <Typography variant="h6">{note.text}</Typography>
                            </Box>
                          );
                        })}
                      <AddNote
                        selectedOrder={oldOrder}
                        setSelectedOrder={setOldOrder}
                        closeAdd={handleCloseNotes}
                      />
                    </DialogContent>
                  ) : null}
                  {oldOrder.notes === undefined && !customerOrderForm ? (
                    <DialogContent>
                      <FormControl fullWidth>
                        <TextField
                          id="text"
                          name="text"
                          label="Note"
                          variant="outlined"
                          multiline
                          rows={3}
                          value={orderNote}
                          onChange={(e) => setOrderNote(e.target.value)}
                          fullWidth
                          sx={{ mt: 1 }}
                        />
                      </FormControl>
                    </DialogContent>
                  ) : null}
                  <DialogActions>
                    <Button onClick={handleCloseNotes} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              ) : null}
              {!customerOrderForm && (
                <Dialog
                  open={openMYConfirmation}
                  onClose={handleCloseMYConfirmation}
                  maxWidth="sm"
                  fullWidth
                >
                  <DialogTitle>Model Year Confirmation</DialogTitle>
                  <DialogContent>
                    <Typography
                      variant="body1"
                      paragraph
                    >{`Are you sure you want to ${
                      modelConfirmation.action
                    } a MY${modelConfirmation.ModelNum.slice(
                      0,
                      2
                    )} order?`}</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleCloseMYConfirmation(true)}
                    >
                      Confirm
                    </Button>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => handleCloseMYConfirmation(false)}
                      color="primary"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Pages;
