import RuleSettings from './Components/RuleSettings';
import { Paper, Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

const Single = () => {
  return (
    <Paper>
      <Grid item>
        <RuleSettings />
        <Outlet />
      </Grid>
    </Paper>
  );
};

export default Single;
