import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  parent: {
    position: 'relative',
    height: '100%',
    top: '0',
    left: '0',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '140px',
    position: 'absolute',
    margin: 'auto',
    top: '0',
    bottom: '0',
    left: '50%',
    [theme.breakpoints.down('xl')]: {
      maxHeight: '140px',
    },
    transform: 'translateX(-50%)',
  },
  imageAlt: {
    maxWidth: '100%',
    maxHeight: '120px',
    height: '120px',
    position: 'absolute',
    top: '0',
    left: '50%',
    [theme.breakpoints.down('xl')]: {
      maxHeight: '120px',
    },
    transform: 'translateX(-50%)',
  },
}));

const BoatImage = (props) => {
  const classes = useStyles();
  const { boatImages, useAlt } = props;

  return (
    <div className={classes.parent}>
      {boatImages
        .filter((img) => img.inputId !== 2)
        .map((input, index) => {
          if (input.optionImgPath === null || input.inputId === 2) return null;
          let timestamp = Date.now();
          return (
            <img
              key={input.id}
              src={`https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/images/${input.optionImgPath}?${timestamp}`}
              className={useAlt ? classes.imageAlt : classes.image}
              crossOrigin="anonymous"
            />
          );
        })}
    </div>
  );
};

export default BoatImage;
