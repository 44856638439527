import { DropzoneArea } from 'material-ui-dropzone';
import { useState } from 'react';
import { Button, Typography } from '@mui/material';
import axios from 'axios';

const UploadRebateFile = ({ rebate, setRebates, rebates }) => {
  const [submitError, setSubmitError] = useState(null);
  const [files, setFiles] = useState([]);
  const handleFileChange = (files) => {
    setFiles(files);
  };

  const uploadFile = async () => {
    let formData = new FormData();
    if (files.length > 0) formData.append('file', files[0], files[0].name);
    formData.append('rebateId', rebate.id);
    if (!rebate) {
      setSubmitError('No rebate selected, please try again');
    } else {
      const { data: updatedRebate } = await axios.post('/api/rebate/file', formData);
      setRebates(rebates.map((r) => (r.id === rebate.id ? updatedRebate : r)));
      setSubmitError(null);
      setFiles([]);
    }
  };

  return (
    <div>
      <Typography variant="h6" sx={{ my: 4 }}>
        Upload Bill of Sale
      </Typography>
      <DropzoneArea
        onChange={(files) => handleFileChange(files)}
        filesLimit={1}
        showAlerts={false}
        showFileNames
      />
      {submitError && (
        <Typography variant="h5" sx={{ my: 4, color: '#f44336' }}>
          {submitError}
        </Typography>
      )}
      <Button
        variant="contained"
        sx={{ mt: 4 }}
        onClick={uploadFile}
        disabled={files.length === 0}
        color="primary"
      >
        Upload
      </Button>
    </div>
  );
};

export default UploadRebateFile;
