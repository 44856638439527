import { useState, useEffect } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import Loading from '../../../../components/Loading';
import axios from 'axios';
import PermissionsTable from './PermissionsTable';

const Roles = () => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [roles, setRoles] = useState([
    { id: 1, name: 'Admin', role: 1, permissions: [] },
    { id: 2, name: 'Inside Sales', role: 2, permissions: [] },
    { id: 3, name: 'Outside Sales', role: 3, permissions: [] },
    { id: 10, name: 'Dealer Manager', role: 10, permissions: [] },
    { id: 11, name: 'Dealer Sales Rep', role: 11, permissions: [] },
    { id: 13, name: 'Dealer Accountant', role: 13, permissions: [] },
  ]);

  useEffect(() => {
    const loadRolePermissions = async () => {
      try {
        const { data } = await axios.get('/api/acl/roles');
        let updatedRoleData = [...roles];
        // console.log(data);
        for (const [key, value] of Object.entries(roles)) {
          if (data[value.role]) {
            value.permissions = data[value.role].map((p) => p);
          }
        }
        setRoles(updatedRoleData);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    if (!submitting) loadRolePermissions();
  }, [submitting]);

  async function handleSaveData() {
    let createPermissions = [];
    let deletePermissions = [];
    for (const [key, value] of Object.entries(roles)) {
      let roleCreatePermissions = value.permissions.filter(
        (p) => p.id === null && !p?.isDeleted
      );

      let roleDeletePermissions = value.permissions.filter(
        (p) => p.id !== null && p.isDeleted
      );

      roleCreatePermissions.map((p) => {
        return createPermissions.push({
          role: p.role,
          permission: p.permission,
        });
      });

      roleDeletePermissions.map((p) => deletePermissions.push(p.id));
    }

    try {
      setSubmitting(true);
      const { data } = await axios.post('/api/acl/roles', {
        createPermissions,
        deletePermissions,
      });
      console.log(data);
      setSubmitting(false);
    } catch (err) {
      console.log(err);
      setSubmitting(false);
    }
  }

  return (
    <Grid item container justifyContent="center">
      <Grid item md={12} lg={10} sx={{ mb: '40px' }}>
        {loading && <Loading />}
        {!loading && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSaveData}
            sx={{ mb: 3 }}
          >
            Save Permissions
          </Button>
        )}
        {!loading && !submitting && (
          <PermissionsTable data={roles} setData={setRoles} />
        )}
        {!loading && submitting && (
          <Typography variant="h4">Updating role permissions...</Typography>
        )}
        {!loading && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSaveData}
            sx={{ mt: 3 }}
          >
            Save Permissions
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default Roles;
