import React, { useState } from 'react';
import {
  TextField,
  Select,
  FormControl,
  Button,
  Grid,
  FormControlLabel,
  Switch as Toggle,
  InputLabel,
  Typography,
  MenuItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
  pageId: yup.number().required(),
  name: yup.string('Enter a name').required('Name is required'),
  tahoeName: yup.string(),
  type: yup.mixed().oneOf(['DROPDOWN', 'CHECKBOX', 'TEXT']),
  price: yup.number(),
  branded: yup.boolean(),
  required: yup.boolean(),
  formType: yup.mixed().oneOf(['ALL', 'CUSTOMER', 'DEALER']),
  inputLayout: yup.mixed().oneOf(['NORMAL', 'GRID', 'HERO']),
  overlayImage: yup.mixed().oneOf(['NA', 'BOAT', 'BENCH']),
  isHidden: yup.boolean(),
  isLockedSubmitted: yup.boolean(),
  epicorPriceBoxName: yup.string(),
  epicorConfigPage: yup.string(),
  epicorInputName: yup.string(),
  epicorInputType: yup
    .mixed()
    .oneOf(['CHARACTER', 'CHECKBOX', 'COMBOBOX', 'DECIMAL']),
});

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(4),
  },
}));

const AddInput = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const { page, setPage, closeAdd } = props;

  const formik = useFormik({
    initialValues: {
      pageId: page.id,
      name: '',
      tahoeName: '',
      type: 'DROPDOWN',
      price: 0,
      branded: false,
      required: false,
      isHidden: false,
      isLockedSubmitted: false,
      formType: 'ALL',
      inputLayout: 'NORMAL',
      overlayImage: 'NA',
      epicorPriceBoxName: '',
      epicorConfigPage: '',
      epicorInputName: '',
      epicorInputType: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const { data } = await axios.post('/api/input', values);

        setPage({ ...page, inputs: [...page.inputs, data.input] });
        setSubmitError(null);
        resetForm();
        closeAdd();
      } catch (err) {
        setSubmitError(err.response.data.message);
      }
    },
  });

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      {submitError !== null ? (
        <h3 style={{ color: '#f4436', marginTop: '0px' }}>{submitError}</h3>
      ) : null}
      <Grid container alignContent="space-between" spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              id="name"
              name="name"
              label="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              className={classes.formField}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              size="small"
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              id="tahoeName"
              name="tahoeName"
              label="Tahoe Name"
              value={formik.values.tahoeName}
              onChange={formik.handleChange}
              className={classes.formField}
              error={formik.touched.tahoeName && Boolean(formik.errors.tahoeName)}
              helperText={formik.touched.tahoeName && formik.errors.tahoeName}
              size="small"
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              id="price"
              name="price"
              label="Price"
              value={formik.values.price}
              onChange={formik.handleChange}
              className={classes.formField}
              error={formik.touched.price && Boolean(formik.errors.price)}
              helperText={formik.touched.price && formik.errors.price}
              size="small"
              fullWidth
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container alignContent="space-between" spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Input Type</InputLabel>
            <Select
              id="type"
              name="type"
              value={formik.values.type}
              onChange={formik.handleChange}
              className={classes.formField}
              error={formik.touched.type && Boolean(formik.errors.type)}
              fullWidth
              native
            >
              <option value="DROPDOWN">Dropdown</option>
              <option value="CHECKBOX">Checkbox</option>
              <option value="TEXT">Text</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Input Layout</InputLabel>
            <Select
              id="inputLayout"
              name="inputLayout"
              label="Input Layout"
              value={formik.values.inputLayout}
              onChange={formik.handleChange}
              className={classes.formField}
              error={
                formik.touched.inputLayout && Boolean(formik.errors.inputLayout)
              }
              fullWidth
              native
            >
              <option value="NORMAL">Normal</option>
              <option value="GRID">Grid</option>
              <option value="HERO">Hero</option>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <FormControl fullWidth>
        <InputLabel>Form Type</InputLabel>
        <Select
          id="formType"
          name="formType"
          label="Form Type"
          value={formik.values.formType}
          onChange={formik.handleChange}
          className={classes.formField}
          error={formik.touched.formType && Boolean(formik.errors.formType)}
          fullWidth
          native
        >
          <option value="ALL">All</option>
          <option value="DEALER">All</option>
          <option value="CUSTOMER">Customer</option>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>Overlay Image</InputLabel>
        <Select
          id="overlayImage"
          name="overlayImage"
          label="Overlay Image"
          value={formik.values.overlayImage}
          onChange={formik.handleChange}
          className={classes.formField}
          fullWidth
          native
        >
          <option value="NA">N/A</option>
          <option value="BOAT">Boat</option>
          <option value="BENCH">Bench</option>
        </Select>
      </FormControl>
      <Grid container alignContent="space-between" alignItems="center">
        <Grid item>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Toggle
                  checked={formik.values.branded}
                  onChange={formik.handleChange}
                  id="branded"
                  name="branded"
                />
              }
              label="Branded"
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Toggle
                  checked={formik.values.required}
                  onChange={formik.handleChange}
                  id="required"
                  name="required"
                />
              }
              label="Required"
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Toggle
                  checked={formik.values.isHidden}
                  onChange={formik.handleChange}
                  id="isHidden"
                  name="isHidden"
                />
              }
              label="Hidden"
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Toggle
                  checked={formik.values.isLockedSubmitted}
                  onChange={formik.handleChange}
                  id="isLockedSubmitted"
                  name="isLockedSubmitted"
                />
              }
              label="Locked Submitted"
            />
          </FormControl>
        </Grid>
      </Grid>
      <Typography variant="h6">Epicor Fields</Typography>
      <FormControl fullWidth>
        <TextField
          id="epicorInputName"
          name="epicorInputName"
          label="Input Name"
          value={formik.values.epicorInputName}
          onChange={formik.handleChange}
          className={classes.formField}
          error={
            formik.touched.epicorInputName && Boolean(formik.errors.epicorInputName)
          }
          helperText={
            formik.touched.epicorInputName && formik.errors.epicorInputName
          }
          fullWidth
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>Input Type</InputLabel>
        <Select
          id="epicorInputType"
          name="epicorInputType"
          label="Input Type"
          value={formik.values.epicorInputType}
          onChange={formik.handleChange}
          className={classes.formField}
          fullWidth
        >
          <MenuItem value="">N/A</MenuItem>
          <MenuItem value="CHARACTER">CHARACTER</MenuItem>
          <MenuItem value="CHECKBOX">CHECKBOX</MenuItem>
          <MenuItem value="COMBOBOX">COMBOBOX</MenuItem>
          <MenuItem value="DECIMAL">DECIMAL</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="epicorPriceBoxName"
          name="epicorPriceBoxName"
          label="Price Box Name"
          value={formik.values.epicorPriceBoxName}
          onChange={formik.handleChange}
          className={classes.formField}
          error={
            formik.touched.epicorPriceBoxName &&
            Boolean(formik.errors.epicorPriceBoxName)
          }
          helperText={
            formik.touched.epicorPriceBoxName && formik.errors.epicorPriceBoxName
          }
          fullWidth
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="epicorConfigPage"
          name="epicorConfigPage"
          label="Config Page"
          value={formik.values.epicorConfigPage}
          onChange={formik.handleChange}
          className={classes.formField}
          error={
            formik.touched.epicorConfigPage &&
            Boolean(formik.errors.epicorConfigPage)
          }
          helperText={
            formik.touched.epicorConfigPage && formik.errors.epicorConfigPage
          }
          fullWidth
        />
      </FormControl>
      <TextField
        id="pageId"
        name="pageId"
        label="pageId"
        value={formik.values.pageId}
        style={{ display: 'none' }}
      />
      <Button
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        className={classes.submitButton}
      >
        Submit
      </Button>
    </form>
  );
};

export default AddInput;
