import { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Typography,
} from '@mui/material';
import Loading from '../../../components/Loading';
import axios from 'axios';

const DiffTable = () => {
  const [isLoadingDiffs, setIsLoadingDiffs] = useState(false);
  const [diffs, setDiffs] = useState([]);
  const [prerigs, setPrerigs] = useState([]);

  useEffect(() => {
    const fetchDiffs = async () => {
      try {
        setIsLoadingDiffs(true);
        const { data } = await axios.get('/api/inputOptions/motor/diff');
        setDiffs(data.diffs);
        setPrerigs(data.prerigs);
        setIsLoadingDiffs(false);
        console.log(data);
      } catch (err) {
        setIsLoadingDiffs(false);
        console.log(err.message);
      }
    };
    fetchDiffs();
  }, []);

  return isLoadingDiffs ? (
    <Loading />
  ) : (
    <Grid item>
      {prerigs.length > 0 && (
        <>
          <Typography variant="h4" gutterBottom>
            New Prerigs
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Prerig</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prerigs.map((prerig, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{prerig.avalonName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      <Typography variant="h4" sx={{ mt: 4 }} gutterBottom>
        Motors
      </Typography>
      <TableContainer sx={{ overflowY: 'scroll', maxHeight: 600 }}>
        <Table sx={{ minWidth: 750 }}>
          <TableHead>
            <TableRow>
              <TableCell>Motor</TableCell>
              <TableCell>OEM Part</TableCell>
              <TableCell>Epicor Part</TableCell>
              <TableCell>Epicor Price</TableCell>
              <TableCell>Transom</TableCell>
              <TableCell>SST Prop Part</TableCell>
              <TableCell>Alum Prop Part</TableCell>
              <TableCell>Performance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {diffs
              .filter((d) => d.status === 'updated')
              .map((diff, index) => (
                <TableRow key={`${index}_updated`}>
                  <TableCell
                    sx={{
                      color: diff.motorDiffs.motor !== null ? 'green' : 'black',
                    }}
                  >
                    {diff.motorDiffs.motor !== null
                      ? diff.motorDiffs.motor
                      : diff.motor}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: diff.motorDiffs.oemPart !== null ? 'green' : 'black',
                    }}
                  >
                    {diff.motorDiffs.oemPart !== null
                      ? diff.motorDiffs.oemPart
                      : diff.oemPart}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: diff.motorDiffs.epicorPart !== null ? 'green' : 'black',
                    }}
                  >
                    {diff.motorDiffs.epicorPart !== null
                      ? diff.motorDiffs.epicorPart
                      : diff.epicorPart}
                  </TableCell>
                  <TableCell
                    sx={{
                      color:
                        diff.motorDiffs.epicorPrice !== null ? 'green' : 'black',
                    }}
                  >
                    {diff.motorDiffs.epicorPrice !== null
                      ? diff.motorDiffs.epicorPrice
                      : diff.epicorPrice}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: diff.motorDiffs.transom !== null ? 'green' : 'black',
                    }}
                  >
                    {diff.motorDiffs.transom !== null
                      ? diff.motorDiffs.transom
                      : diff.transom}
                  </TableCell>
                  <TableCell
                    sx={{
                      color:
                        diff.motorDiffs.sstPropPart !== null ? 'green' : 'black',
                    }}
                  >
                    {diff.motorDiffs.sstPropPart !== null
                      ? diff.motorDiffs.sstPropPart
                      : diff.sstPropPart}
                  </TableCell>
                  <TableCell
                    sx={{
                      color:
                        diff.motorDiffs.alumPropPart !== null ? 'green' : 'black',
                    }}
                  >
                    {diff.motorDiffs.alumPropPart !== null
                      ? diff.motorDiffs.alumPropPart
                      : diff.alumPropPart}
                  </TableCell>
                  <TableCell
                    sx={{
                      color:
                        diff.motorDiffs.performance !== null ? 'green' : 'black',
                    }}
                  >
                    {diff.motorDiffs.performance !== null
                      ? diff.motorDiffs.performance
                      : diff.performance}
                  </TableCell>
                </TableRow>
              ))}
            {diffs
              .filter((d) => d.status === 'add')
              .map((diff, index) => (
                <TableRow key={`${index}_add`}>
                  <TableCell sx={{ color: 'green' }}>{diff.motor}</TableCell>
                  <TableCell sx={{ color: 'green' }}>{diff.oemPart}</TableCell>
                  <TableCell sx={{ color: 'green' }}>{diff.epicorPart}</TableCell>
                  <TableCell sx={{ color: 'green' }}>{diff.epicorPrice}</TableCell>
                  <TableCell sx={{ color: 'green' }}>{diff.transom}</TableCell>
                  <TableCell sx={{ color: 'green' }}>{diff.sstPropPart}</TableCell>
                  <TableCell sx={{ color: 'green' }}>{diff.alumPropPart}</TableCell>
                  <TableCell sx={{ color: 'green' }}>{diff.performance}</TableCell>
                </TableRow>
              ))}
            {diffs
              .filter((d) => d.status === 'removed')
              .map((diff, index) => (
                <TableRow key={`${index}removed`}>
                  <TableCell sx={{ color: 'red' }}>{diff.motor}</TableCell>
                  <TableCell sx={{ color: 'red' }}>{diff.oemPart}</TableCell>
                  <TableCell sx={{ color: 'red' }}>{diff.epicorPart}</TableCell>
                  <TableCell sx={{ color: 'red' }}>{diff.epicorPrice}</TableCell>
                  <TableCell sx={{ color: 'red' }}>{diff.transom}</TableCell>
                  <TableCell sx={{ color: 'red' }}>{diff.sstPropPart}</TableCell>
                  <TableCell sx={{ color: 'red' }}>{diff.alumPropPart}</TableCell>
                  <TableCell sx={{ color: 'red' }}>{diff.performance}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default DiffTable;
