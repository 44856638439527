import { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@mui/material';
import Loading from '../../../../../components/Loading';

const CSVDisplay = ({ csvs, selectedCSV, loadingCSV }) => {
  const [loadingCSVDisplay, setLoadingCSVDisplay] = useState(
    selectedCSV === '' ? false : true
  );
  const [csvHead, setCSVHead] = useState([[], []]);
  const [csvRows, setCSVRows] = useState([]);
  const [addedFPs, setAddedFPs] = useState([]);
  const [removedFPs, setRemovedFPs] = useState([]);

  useEffect(() => {
    const formatCSV = () => {
      if (selectedCSV === '') return setLoadingCSVDisplay(false);
      setLoadingCSVDisplay(true);
      const activeCSV = csvs.find((csv) => csv.active);
      const currentCSV = csvs.find((csv) => csv.id === selectedCSV);

      if (activeCSV?.id === selectedCSV || activeCSV === undefined) {
        setLoadingCSVDisplay(false);
      } else {
        setCSVHead([[], []]);
        setCSVRows([]);
        setAddedFPs([]);
        setRemovedFPs([]);
        let removedFPs = [];
        let addedFPs = [];
        let updatedRows = [];
        // currentCSV is new csv
        // console.log(currentCSV, activeCSV);
        let displayHead = [
          currentCSV.head[0].map((column) => {
            return column !== null ? { ...column, show: false } : null;
          }),
          currentCSV.head[1].map((column) => {
            return column !== null
              ? { title: column, show: false }
              : { title: null, show: false };
          }),
        ];

        for (const row of currentCSV.rows) {
          const foundRow = activeCSV.rows.find((r) => r[1] === row[1]);
          if (foundRow !== undefined) {
            // Evaluate each column
            let currentInputIndex = null;
            let newRow = row.map((r, index) => {
              if (index > 0) {
                if (displayHead[0][index] !== null) currentInputIndex = index;

                if (r !== foundRow[index]) {
                  displayHead[0][currentInputIndex].show = true;
                  if (displayHead[1][index] !== null) {
                    displayHead[1][index].show = true;
                    displayHead[1][index].idx = index;
                  }

                  return {
                    value: `${
                      foundRow[index] === null ? 'No Rule' : foundRow[index]
                    } > ${r === null ? 'No Rule' : r}`,
                    color: r === null ? 'red' : 'green',
                  };
                } else {
                  return r;
                }
              } else {
                return r;
              }
            });

            updatedRows.push(newRow);
          } else {
            let newRow = row.map((r) => {
              return r !== null ? { value: r, color: 'green' } : r;
            });
            addedFPs.push(newRow);
          }
        }

        for (const row of activeCSV.rows) {
          const foundRow = currentCSV.rows.find((r) => r[1] === row[1]);
          if (foundRow === undefined) {
            let newRow = row.map((r) => {
              return r !== null ? { value: r, color: 'red' } : r;
            });
            removedFPs.push(newRow);
          }
        }
        // determine new colspans
        let currentColSpan = 0;
        let currentInputIndex = null;
        for (let i = 0; i < displayHead[0].length; i++) {
          if (displayHead[0][i] !== null) {
            if (currentInputIndex !== null) {
              displayHead[0][currentInputIndex].colSpan = currentColSpan;
            }
            currentColSpan = displayHead[1][i] === null ? 1 : 0;
            currentInputIndex = i;
          }
          if (displayHead[1][i] !== null) {
            if (displayHead[1][i].show) {
              currentColSpan++;
              displayHead[0][currentInputIndex].colSpan = currentColSpan;
            }
          }
        }
        displayHead[0][0] = { title: '', show: true, colSpan: 1 };
        displayHead[0][1] = { title: '', show: true, colSpan: 1 };
        displayHead[1][0].show = true;
        displayHead[1][0].idx = 0;
        displayHead[1][1].show = true;
        displayHead[1][1].idx = 1;
        setCSVRows(updatedRows);
        setCSVHead([
          displayHead[0].filter((column) => column?.show),
          displayHead[1].filter((column) => column?.show),
        ]);
        // console.log(addedFPs, removedFPs);
        setAddedFPs(addedFPs);
        setRemovedFPs(removedFPs);
        setLoadingCSVDisplay(false);
      }
    };

    formatCSV();
  }, [selectedCSV]);

  return (
    <Grid item xs={12}>
      {loadingCSV && <Loading />}
      {loadingCSVDisplay && <Loading />}
      {addedFPs.length > 0 && (
        <Box sx={{ my: 2 }}>
          <Typography variant="h6">Added Floorplans</Typography>
          {addedFPs.map((row, index) => (
            <Typography sx={{ color: 'green' }} key={`added_fp_${index}`}>
              {row[1].value}
            </Typography>
          ))}
        </Box>
      )}

      {removedFPs.length > 0 && (
        <Box sx={{ my: 2 }}>
          <Typography variant="h6">Removed Floorplans</Typography>
          {removedFPs.map((row, index) => (
            <Typography sx={{ color: 'red' }} key={`removed_fp_${index}`}>
              {row[1].value}
            </Typography>
          ))}
        </Box>
      )}

      <Typography variant="h6" sx={{ mt: 2 }}>
        Updated Rows
      </Typography>
      {!loadingCSVDisplay && selectedCSV !== '' && (
        <TableContainer sx={{ maxHeight: 560 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                {csvHead[0]
                  .filter((column) => column.show)
                  .map((column, index) => {
                    if (column !== null)
                      return (
                        <TableCell
                          key={`column_head0_${index}`}
                          colSpan={column.colSpan}
                          component="th"
                          sx={{ whiteSpace: 'nowrap' }}
                        >
                          {column.title}
                        </TableCell>
                      );
                  })}
              </TableRow>
              <TableRow>
                {csvHead[1]
                  .filter((column) => column?.show || column === null)
                  .map((column, index) => {
                    return (
                      <TableCell
                        key={`column_head1_${index}`}
                        sx={{ whiteSpace: 'nowrap' }}
                      >
                        {column.title}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {csvRows.map((row, index) => {
                return (
                  <TableRow key={`row_${index}`}>
                    {csvHead[1].map((column, index) => {
                      return (
                        <TableCell
                          key={`column_${index}`}
                          sx={{ whiteSpace: 'nowrap' }}
                        >
                          <Typography
                            sx={{
                              color: row[column.idx]?.color ?? 'rgba(0,0,0,0.87)',
                            }}
                          >
                            {row[column.idx]?.value ?? row[column.idx]}
                          </Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
};

export default CSVDisplay;
