import './App.css';
import React, { useReducer } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { UserContext, initialState, reducer } from './userContext';
import Layout from './components/Layout';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Home from './pages/Home/';
import DERP from './pages/DERP';
import ATDealerUsers from './pages/admin/ATDealerUsers';
import Roles from './pages/admin/ATDealerUsers/Components/Roles';
import Pages from './pages/admin/FormBuilder/Components/Pages';
import Page from './pages/admin/FormBuilder/Components/Page';
import Input from './pages/admin/FormBuilder/Components/Input';
import DealerAllotment from './pages/admin/DealerAllotment';
import Notifications from './pages/admin/Notifications';
import Reporting from './pages/admin/Reporting';
import AtomSettings from './pages/admin/AtomSettings';
import Single from './pages/admin/Rules/Single';
import InputsWithRules from './pages/admin/Rules/Single/Components/InputsWithRules';
import InputWithRules from './pages/admin/Rules/Single/Components/InputWithRules';
import RuleGroup from './pages/admin/Rules/Group/Components/RuleGroup';
import RuleGroups from './pages/admin/Rules/Group/Components/RuleGroups';
import CSV from './pages/admin/Rules/CSV';
import FloorplanList from './pages/admin/Rules/CSV/Components/FloorplanList';
import CSVInputsWithRules from './pages/admin/Rules/CSV/Components/InputsWithRules';
import CSVInputWithRules from './pages/admin/Rules/CSV/Components/InputWithRules';
import Order from './components/Order';
import OrdersByStatus from './pages/OrdersByStatus/OrdersByStatus';
import Rules from './pages/admin/Rules';
import Search from './pages/Dealerships/Search';
import OrderHistory from './pages/Dealerships/OrderHistory';
import Profile from './pages/Profile';
import DealershipProfile from './components/DealershipProfile';
import NotFound from './pages/404';
import OrderForm from './components/OrderForm';
import Models from './components/OrderForm/Components/Models';
import Floorplans from './components/OrderForm/Components/Floorplans';
import OrderFormPages from './components/OrderForm/Components/Pages';
import DuplicateOrder from './pages/DuplicateOrder';
import MotorSettings from './pages/admin/MotorSettings';
import ErrorElement from './components/ErrorElement';
import Rebate from './pages/Rebate';
import OrderNotes from './pages/OrderNotes';
import { MotorOrders } from './pages/MotorOrders';
import BoatShowForm from './pages/BoatShow/Form';
import BoatShowReports from './pages/BoatShow/Reports';
import BoatShowReport from './pages/BoatShow/Report';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="boat-show/reports/:id/edit" element={<BoatShowForm />} />
      <Route path="boat-show/reports/:id" element={<BoatShowReport />} />
      <Route path="boat-show/reports" element={<BoatShowReports />} />
      <Route path="boat-show" element={<BoatShowForm />} />
      <Route path="rebate/:id/:token" element={<Rebate />} />
      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="order/:orderId/duplicate" element={<DuplicateOrder />} />
      <Route path="order-notes" element={<OrderNotes />} />
      <Route path="motor-orders" element={<MotorOrders />} />
      <Route
        path="dealerships/:dealershipId/locations/:locationId/order/:orderId/:formType/:brand"
        element={<OrderForm />}
      >
        <Route path="" element={<Models />} end />
        <Route path="model/:modelId" element={<Floorplans />} />
        <Route
          path="model/:modelId/floorplan/:floorplanId/page/:pageId"
          element={<OrderFormPages />}
        />
      </Route>
      <Route
        path="order/:orderId/:formType/:brand"
        element={<OrderForm />}
        errorElement={<ErrorElement />}
      >
        <Route path="" element={<Models />} end />
        <Route path="model/:modelId" element={<Floorplans />} />
        <Route
          path="model/:modelId/floorplan/:floorplanId/page/:pageId"
          element={<OrderFormPages />}
        />
      </Route>
      <Route path="derp" element={<DERP />} />
      <Route path="orders/:status" element={<OrdersByStatus />} />
      <Route path="orders/:status/order/:orderId" element={<Order />} />
      <Route path="dealerships" element={<Search />} />
      <Route
        path="locations/:locationId/:archive/:orderSource"
        element={<OrderHistory />}
      />
      <Route
        path="locations/:locationId/:archive/:orderSource/:orderId"
        element={<Order />}
      />
      <Route
        path="dealerships/:dealershipId/settings"
        element={<DealershipProfile />}
      />
      <Route
        path="dealerships/:dealershipId/locations/:locationId/:archive/:orderSource"
        element={<OrderHistory />}
      />
      <Route
        path="dealerships/:dealershipId/locations/:locationId/:archive/:orderSource/:orderId"
        element={<Order />}
      />
      <Route path="profile" element={<Profile />} />
      <Route path="settings">
        <Route path="motors" element={<MotorSettings />} />
        <Route path="form">
          <Route path="pages" element={<Pages />} />
          <Route path="pages/:pageId" element={<Page />} />
          <Route path="pages/:pageId/input/:inputId" element={<Input />} />
        </Route>
        <Route path="rules" element={<Rules />}>
          <Route path="single" element={<Single />}>
            <Route path="page/:pageId">
              <Route path="input/:inputId">
                <Route path="value/:yValue" element={<InputsWithRules />} />
                <Route path="value/:yValue/zId/:zId" element={<InputWithRules />} />
              </Route>
            </Route>
          </Route>
          <Route path="group">
            <Route path="" element={<RuleGroups />} end />
            <Route path=":groupId" element={<RuleGroup />} />
            <Route path=":groupId/input/:inputId" element={<RuleGroup />} />
          </Route>
          <Route path="csv" element={<CSV />}>
            <Route path="model/:modelId" element={<FloorplanList />} />
            <Route
              path="model/:modelId/floorplan/:floorplanId"
              element={<CSVInputsWithRules />}
            />
            <Route
              path="model/:modelId/floorplan/:floorplanId/input/:inputId"
              element={<CSVInputWithRules />}
            />
          </Route>
        </Route>
        <Route path="allotment" element={<DealerAllotment />} />
        <Route path="users" element={<ATDealerUsers />} />
        <Route path="roles" element={<Roles />} />
        <Route path="cms/:type" element={<Notifications />} />
        <Route path="reporting" element={<Reporting />} />
        <Route path="atom" element={<AtomSettings />} />
        <Route path="dealership" element={<DealershipProfile />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={{ userState: state, userDispatch: dispatch }}>
      <RouterProvider router={router} />
    </UserContext.Provider>
  );
}

export default App;
