import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    ['@media print']: {
      width: '100%',
      padding: theme.spacing(0, 2),
    },
  },
  model: {
    fontSize: '1.8rem',
    fontWeight: '600',
    lineHeight: '1.8rem',
    padding: '8px 0px',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 'auto',
    padding: '0px 25px 21px 0px',
    height: '155px',
  },
  price: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 'auto',
    padding: theme.spacing(2, 4),
    textAlign: 'center',
    backgroundColor: '#F8F8F8',
    borderRadius: '7px',
    height: '155px',
  },
  hidePrint: {
    ['@media print']: {
      display: 'none',
    },
  },
}));

const Header = (props) => {
  const {
    id,
    dealership,
    location,
    model,
    floorplan,
    finance,
    calculateTotalPrice,
    showMSRP,
    calculateInternetPrice,
    getStatus,
    status,
    defaultMarkup,
  } = props;
  const classes = useStyles();
  return (
    <Grid container item spacing={2} sx={{ mb: 1 }} className={classes.container}>
      <Grid item sm={6}>
        <Box className={classes.title}>
          <Typography variant="h6">{location}</Typography>
          <Typography variant="h4" className={classes.model}>
            {model} {floorplan}
          </Typography>
          <Typography variant="h5">Finance Option - {finance}</Typography>
        </Box>
      </Grid>
      <Grid item sm={6}>
        <Box className={classes.price}>
          <Typography variant="h6">
            Order #{id}
            <span className={classes.hidePrint}> - {getStatus(status)}</span>
          </Typography>
          <Typography variant="h5">
            {calculateTotalPrice()}
            {showMSRP ? ' TOTAL MSRP' : ' DEALER'}
          </Typography>
          {showMSRP && defaultMarkup === 0 && (
            <Typography variant="h6" sx={{ marginBottom: '8px' }}>
              {calculateInternetPrice()} AVERAGE RETAIL PRICE
            </Typography>
          )}
          <Typography variant="h6" sx={{ lineHeight: '1.4rem' }}>
            {dealership}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Header;
