import React, { useState } from 'react';
import { TextField, FormControl, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Loading from '../Loading';
import { format } from 'date-fns';

const validationSchema = yup.object({
  erpNumber: yup.string('Enter an ERP Number').required('ERP Number is required'),
  customerName: yup.string(),
  dealershipId: yup.number(),
  location: yup.number(),
  orderSource: yup.number(),
  orderStatus: yup.number(),
});

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2),
  },
}));

const AddPaperOrder = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { closeAdd, paperOrder, orders, setOrders } = props;

  const formik = useFormik({
    initialValues: {
      erpNumber: '',
      customerName: '',
      dealershipId: paperOrder.dealershipId,
      locationId: paperOrder.locationId,
      orderStatus: paperOrder.orderStatus,
      orderSource: paperOrder.orderSource,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        const { data } = await axios.post('/api/order/paper', values);
        let sortedOrder = {
          id: data.id,
          orderStatus: data.orderStatus,
          erpNumber: data.erpNumber,
          customerName: data.customerName,
          price: data.price,
          model: data.model,
          floorplan: data.floorplan,
          requestedBuildWeek: data.requestedBuildWeek,
          confirmedDate:
            data.confirmedDate !== null
              ? format(new Date(data.confirmedDate), 'MM/dd/yyyy')
              : null,
          orderDate: format(new Date(data.orderDate), 'MM/dd/yyyy'),
          lastModified:
            data.lastModified !== null
              ? format(new Date(data.lastModified), 'MM/dd/yyyy')
              : null,
          notes: [],
        };

        setOrders([...orders, sortedOrder]);
        setLoading(false);
        resetForm();
        closeAdd();
      } catch (err) {
        setLoading(false);
        setSubmitError(err.response.data.message);
      }
    },
  });

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      {submitError !== null ? (
        <h3 style={{ color: '#f4436', marginTop: '0px' }}>{submitError}</h3>
      ) : null}
      {loading ? (
        <Loading text="Saving paper order..." />
      ) : (
        <>
          <FormControl fullWidth>
            <TextField
              id="erpNumber"
              name="erpNumber"
              label="ERP Number"
              value={formik.values.erpNumber}
              onChange={formik.handleChange}
              className={classes.formField}
              error={formik.touched.erpNumber && Boolean(formik.errors.erpNumber)}
              helperText={formik.touched.erpNumber && formik.errors.erpNumber}
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              id="customerName"
              name="customerName"
              label="Customer Name"
              value={formik.values.customerName}
              onChange={formik.handleChange}
              className={classes.formField}
              fullWidth
            />
          </FormControl>
          <Button color="primary" variant="contained" type="submit">
            Submit
          </Button>
        </>
      )}
    </form>
  );
};

export default AddPaperOrder;
