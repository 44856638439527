import React, { useState } from 'react';
import {
  TextField,
  FormControl,
  Button,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useFormik } from 'formik';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as yup from 'yup';

const validationSchema = yup.object({
  name: yup.string(),
  email: yup.string(),
  category: yup.string(),
  dealershipId: yup.number().required(),
});

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2),
  },
  formMarginTop2: {
    marginTop: theme.spacing(2),
  },
}));

const AddCommunicationEntry = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const { dealershipId, communicationList, setCommunicationList } = props;

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      category: '',
      dealershipId: dealershipId,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const { data } = await axios.post('/api/dealers/communication/user', values);
        setCommunicationList([...communicationList, data]);
        resetForm();
      } catch (err) {
        setSubmitError(err.response.data.message);
      }
    },
  });

  return (
    <form
      noValidate
      autoComplete="off"
      className={classes.formMarginTop2}
      onSubmit={formik.handleSubmit}
    >
      {submitError !== null ? (
        <h3 style={{ color: '#f4436', marginTop: '0px' }}>{submitError}</h3>
      ) : null}
      <FormControl fullWidth>
        <TextField
          id="name"
          name="name"
          label="Name"
          variant="outlined"
          value={formik.values.name}
          onChange={formik.handleChange}
          className={classes.formField}
          fullWidth
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="email"
          name="email"
          label="Email"
          variant="outlined"
          value={formik.values.email}
          onChange={formik.handleChange}
          className={classes.formField}
          fullWidth
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="categoryLabel">Category</InputLabel>
        <Select
          id="category"
          name="category"
          labelId="categoryLabel"
          IconComponent={ExpandMoreIcon}
          value={formik.values.category}
          onChange={formik.handleChange}
          className={classes.formField}
          error={formik.touched.category && Boolean(formik.errors.category)}
          fullWidth
        >
          <MenuItem value="">Select Category</MenuItem>
          <MenuItem value="Sales">Sales</MenuItem>
          <MenuItem value="Service">Service</MenuItem>
          <MenuItem value="All">All</MenuItem>
        </Select>
      </FormControl>
      <Button color="primary" variant="contained" type="submit">
        Add Entry
      </Button>
    </form>
  );
};

export default AddCommunicationEntry;
