import { Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  useNavigate,
  useParams,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import Filters from './Filters';
import { formatPrice, parseBrandName } from '../../../lib';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 20,
    maxWidth: 345,
    backgroundColor: 'rgba(0,0,0,0)',
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'lightGrey',
      borderRadius: 20,
      cursor: 'pointer',
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    borderRadius: 20,
    backgroundColor: '#006991',
    opacity: 0.9,
  },
  body2: {
    fontSize: '11px',
  },
  cardContent: {
    paddingBottom: '0px',
  },
  cardActions: {
    marginBottom: theme.spacing(2),
  },
  modelGroup: {
    margin: theme.spacing(2, 0, 0, 0),
    position: 'relative',
    height: '100%',
  },
  groupTitle: {
    margin: theme.spacing(2, 0, 1, 0),
  },
  title: {
    color: '#747679',
  },
  pathName: {
    textDecoration: 'underline',
    textDecorationColor: '#006991',
    textDecorationThickness: '3px',
  },
  '@media (orientation: landscape) and (max-height: 400px)': {
    flexDirection: 'orientation',
  },
}));

const Models = (props) => {
  const [
    models,
    tags,
    queryTags,
    customerBuild,
    priceFilter,
    lengthFilter,
    handleChangeLengthFilter,
    handleChangePriceFilter,
    hasAvailableFloorplans,
    floorplans,
    price,
    setPrice,
    customerOrderForm,
    showHidden,
    markup,
    setMarkup,
    modelMarkups,
    useModelMarkups,
    calculatePriceWithMarkup,
    setModelMarkups,
    setUseModelMarkups,
    priceMarkupFilter,
  ] = useOutletContext();
  const classes = useStyles();
  const navigate = useNavigate();
  const { brand, formType } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();

  const handleSelectModel = (id, model) => {
    window.dataLayer.push({
      event: 'selected-model',
      eventCategory: model,
    });
    if (searchParams) {
      navigate(`model/${id}?` + searchParams);
    } else {
      navigate(`model/${id}`);
    }
  };

  const handleClick = (model) => {
    if (hasAvailableFloorplans(model) !== 0) {
      handleSelectModel(model.id, parseBrandName(brand, model));
    }
  };

  return (
    <div>
      <Typography variant="h4" align="left" className={classes.pathName}>
        Models
      </Typography>
      <Grid container columnSpacing={5} mt={2}>
        <Grid item sm={12} md={4}>
          <Filters
            models={models}
            priceFilter={priceFilter}
            lengthFilter={lengthFilter}
            handleChangeLengthFilter={handleChangeLengthFilter}
            handleChangePriceFilter={handleChangePriceFilter}
            hasAvailableFloorplans={hasAvailableFloorplans}
            formatPrice={formatPrice}
            customerBuild={customerBuild}
            formType={formType}
            priceMarkupFilter={priceMarkupFilter}
          />
        </Grid>
        <Grid item sm={12} md={8}>
          <Typography variant="h5" align="left" className={classes.title}>
            {models.inputOptions.filter((io) => io.floorplans.length > 0).length}{' '}
            available models
          </Typography>
          <Grid container justifyContent="center" mt={2} alignItems="flex-end">
            {models.inputOptions
              .filter((io) => io.floorplans.length > 0)
              .sort((a, b) => a.optionOrder - b.optionOrder)
              .map((model, index) => {
                return (
                  <Grid
                    key={model.id}
                    item
                    xs={12}
                    sm={4}
                    className={classes.modelGroup}
                  >
                    <Card
                      className={classes.root}
                      onClick={() => handleClick(model)}
                    >
                      {model.thumbnailImgPath !== null ? (
                        <CardMedia
                          className={classes.media}
                          component="img"
                          image={`https://cdn-dev-atdealer.s3.us-east-2.amazonaws.com/images/${model.thumbnailImgPath}`}
                          title={parseBrandName(brand, model)}
                        />
                      ) : null}

                      <CardContent className={classes.cardContent}>
                        <Typography
                          variant="h6"
                          textAlign="center"
                          fontWeight="bold"
                        >
                          {parseBrandName(brand, model)}
                        </Typography>

                        <Typography
                          variant="h6"
                          fontSize={16}
                          textAlign="center"
                          color="GrayText"
                        >
                          {hasAvailableFloorplans(model)} floorplan
                          {model.floorplans.length > 1 ? 's' : ''} to build
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Models;
