import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { DataGridPro, GridLinkOperator } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  Toolbar,
  Typography,
  Paper,
  IconButton,
  Tooltip,
  Button,
  Grid,
  TextField,
  FormControl,
  InputAdornment,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import ImageIcon from '@mui/icons-material/Image';
import CommentIcon from '@mui/icons-material/Comment';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash.debounce';
import { UserContext } from '../../userContext';
import { format } from 'date-fns';
import { readableRole } from '../../lib';

// Warn if overriding existing method
if (Array.prototype.equals)
  console.warn(
    "Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code."
  );
// attach the .equals method to Array's prototype to call it on any array
Array.prototype.equals = function (array) {
  // if the other array is a falsy value, return
  if (!array) return false;

  // compare lengths - can save a lot of time
  if (this.length !== array.length) return false;

  for (var i = 0, l = this.length; i < l; i++) {
    // Check if we have nested arrays
    if (this[i] instanceof Array && array[i] instanceof Array) {
      // recurse into the nested arrays
      if (!this[i].equals(array[i])) return false;
    } else if (this[i] !== array[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }
  return true;
};
// Hide method from for-in loops
Object.defineProperty(Array.prototype, 'equals', { enumerable: false });

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 1),
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
    textAlign: 'left',
  },
  filters: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  formField: {
    margin: theme.spacing(0, 1, 2, 1),
  },
  select: {
    margin: theme.spacing(2, 0, 2, 0),
    width: '200px',
  },
  button: {
    margin: theme.spacing(1, 2, 0, 0),
  },
  search: {
    backgroundColor: '#FFF',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    title,
    rows,
    selected,
    totalSelected,
    addData,
    openEditData,
    setEditData,
    disableActionIcon,
    updateBulk,
    initSelected,
    viewOrder,
    overrideInit,
    paperOrder,
    bulkReturnToQuote,
    deleteOrder,
    createLocationOrder,
    handleOpenEditERP,
    downloadOrders,
    setSearchText,
    handleDownloadXML,
  } = props;
  const userContext = useContext(UserContext);

  const findSelected = () => {
    const selectedRow = rows.find((row) => selected[0] === row.id);
    if (setEditData !== undefined) {
      setEditData(selectedRow);
      openEditData();
    }

    if (setEditData === undefined && openEditData !== undefined) {
      console.log(selectedRow);
      openEditData(selectedRow.id);
    }
  };

  const renderActionIcon = () => {
    switch (numSelected) {
      case 0:
        return addData !== null ? (
          <Tooltip title={`Add ${title}`}>
            <IconButton onClick={addData} aria-label="add" size="large">
              <AddIcon />
            </IconButton>
          </Tooltip>
        ) : null;
      case 1:
        return (
          <Tooltip title="Edit">
            <IconButton onClick={findSelected} aria-label="edit" size="large">
              <EditIcon />
            </IconButton>
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="Delete">
            <IconButton onClick={findSelected} aria-label="delete" size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        );
    }
  };

  const disableDeleteCheck = () => {
    let flag = true;
    if (
      rows.find((r) => r.id === selected[0])?.orderStatus === 0 &&
      userContext.userState.me.role <= 10
    )
      flag = false;
    if (
      rows.find((r) => r.id === selected[0])?.orderStatus === 5 &&
      userContext.userState.me.role <= 2
    )
      flag = false;

    return flag;
  };

  const disableBulkReturnToQuoteCheck = () => {
    let flag = false;
    for (let s of selected) {
      if (rows.find((r) => r.id === s)?.orderStatus === 5) flag = true;
    }

    return flag;
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  });

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
        <Grid item>
          {numSelected > 0 ? (
            <Typography
              className={classes.title}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} selected
            </Typography>
          ) : (
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {title}
            </Typography>
          )}
        </Grid>
        <Grid item>
          {handleDownloadXML !== null &&
          handleDownloadXML !== undefined &&
          userContext.userState.me.role === 1 ? (
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => handleDownloadXML(selected)}
              disabled={selected.length !== 1}
              className={classes.button}
            >
              XML
            </Button>
          ) : null}
          {downloadOrders !== null && downloadOrders !== undefined ? (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => downloadOrders(selected)}
              disabled={selected.length === 0 || disableBulkReturnToQuoteCheck()}
              className={classes.button}
            >
              Bulk Print
            </Button>
          ) : null}
          {createLocationOrder !== null && createLocationOrder !== undefined ? (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => createLocationOrder()}
              className={classes.button}
            >
              Create Order
            </Button>
          ) : null}
          {bulkReturnToQuote !== undefined && bulkReturnToQuote !== null ? (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => bulkReturnToQuote(selected)}
              disabled={selected.length === 0 || disableBulkReturnToQuoteCheck()}
              className={classes.button}
            >
              Return to Quote
            </Button>
          ) : null}
          {updateBulk !== undefined ? (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => updateBulk(totalSelected)}
              disabled={totalSelected.equals(initSelected) && !overrideInit}
              className={classes.button}
            >
              Save Changes
            </Button>
          ) : null}
          {viewOrder !== undefined && viewOrder !== null ? (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => viewOrder(selected[0])}
              disabled={
                numSelected !== 1 ||
                rows.find((r) => r.id === selected[0]).orderStatus === 5
              }
              className={classes.button}
            >
              View Order
            </Button>
          ) : null}
          {paperOrder !== undefined && paperOrder !== null ? (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => paperOrder()}
              className={classes.button}
            >
              Create Paper Order
            </Button>
          ) : null}
          {deleteOrder !== undefined && deleteOrder !== null ? (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => deleteOrder(selected[0])}
              disabled={numSelected !== 1 || disableDeleteCheck()}
              className={classes.button}
            >
              Delete Order
            </Button>
          ) : null}
          {handleOpenEditERP !== undefined && handleOpenEditERP !== null ? (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() =>
                handleOpenEditERP(rows.find((r) => r.id === selected[0]))
              }
              disabled={
                numSelected !== 1 ||
                rows.find((r) => r.id === selected[0])?.erpNumber === null
              }
              className={classes.button}
            >
              Edit ERP
            </Button>
          ) : null}
          {disableActionIcon ? null : renderActionIcon()}
        </Grid>
        <Grid item className={classes.filters} xs={12}>
          <FormControl fullWidth>
            <TextField
              id="search"
              name="search"
              label="Search"
              onChange={debouncedSearch}
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              className={classes.search}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 'calc(100vw - 153px)',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 400,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  columnText: {
    fontSize: '12px',
    whiteSpace: 'nowrap',
  },
}));

const EnhancedTable = (props) => {
  const classes = useStyles();
  let {
    data,
    dense,
    setSelectedData,
    title,
    updateBulk,
    viewOrder,
    openNotes,
    filterTypes,
    overrideInit,
    paperOrder,
    bulkReturnToQuote,
    deleteOrder,
    createLocationOrder,
    handleOpenApproveOrder,
    handleOpenEditERP,
    downloadOrders,
    initRowsPerPage,
    handleDownloadXML,
    totalSelectedShow = false,
  } = props;
  const [selected, setSelected] = useState([]);
  const [totalSelected, setTotalSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(
    initRowsPerPage !== undefined ? initRowsPerPage : 10
  );
  const hiddenColumns = props.hiddenColumns ? props.hiddenColumns : [];
  const disableSelectAll =
    props.disableSelectAll !== undefined ? props.disableSelectAll : false;
  const initSelected = props.initSelected !== undefined ? props.initSelected : [];
  const [searchText, setSearchText] = useState('');
  const [layoutInfo, setLayoutInfo] = useState({});
  const userContext = useContext(UserContext);

  useEffect(() => {
    const parseData = (data) => {
      if (searchText.length > 0) {
        let tmpData = [...data];
        let filteredData = [];
        let columns = tmpData.length !== 0 ? Object.keys(tmpData[0]) : [];
        columns = columns.filter((c) => {
          if (hiddenColumns.indexOf(c) !== -1) return false;
          return true;
        });

        for (let d of tmpData) {
          let addFlag = false;
          for (let c of columns) {
            let columnString = d[c];
            if (c === 'orderStatus')
              columnString = orderStatus(d[c], d['orderSource']);
            let string = String(columnString).toLowerCase();
            if (string.includes(searchText.toLowerCase())) addFlag = true;
          }

          if (addFlag) filteredData.push(d);
        }

        setRows(filteredData);
      } else {
        setRows(data);
      }

      setSelected(initSelected);
      setTotalSelected(initSelected);
      if (data.length === 0) return;

      let columns = Object.keys(data[0]);

      columns = columns
        .filter((c) => {
          if (hiddenColumns.indexOf(c) !== -1) return false;
          return true;
        })
        .map((c) => {
          return {
            id: c,
            numeric: false,
            disablePadding: dense ? true : false,
            label: c.charAt(0).toUpperCase() + c.slice(1),
          };
        });
    };

    parseData(data);
  }, [JSON.stringify(data), JSON.stringify(initSelected)]);

  useEffect(() => {
    const timer = setTimeout(() => {
      let tmpData = [...data];
      let filteredData = [];
      let columns = tmpData.length !== 0 ? Object.keys(tmpData[0]) : [];
      columns = columns.filter((c) => {
        if (hiddenColumns.indexOf(c) !== -1) return false;
        return true;
      });

      for (let d of tmpData) {
        let addFlag = false;
        for (let c of columns) {
          let columnString = d[c];
          if (c === 'orderStatus')
            columnString = orderStatus(d[c], d['orderSource']);
          let string = String(columnString).toLowerCase();
          if (string.includes(searchText.toLowerCase())) addFlag = true;
        }

        if (addFlag) filteredData.push(d);
      }

      setRows(filteredData);
      setSelected(
        totalSelected.filter(
          (ts) => filteredData.findIndex((fd) => fd.id === ts) > -1
        )
      );
    }, 100);

    return () => clearTimeout(timer);
  }, [searchText]);

  useEffect(() => {
    setLayoutInfo({});
  }, [useContext]);

  const handleChangePage = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleChangeRowsPerPage = (counts) => {
    setRowsPerPage(counts);
    setPage(0);
  };

  const orderStatus = (status, orderSource) => {
    switch (parseInt(status, 10)) {
      case 0:
        if (orderSource === 2) {
          return 'Lead';
        } else {
          return 'Quote';
        }
      case 1:
        if (orderSource === 2) {
          return 'Order Created';
        } else {
          return 'Submitted';
        }
      case 2:
        return 'Confirmed';
      case 3:
        return 'Complete';
      case 5:
        return 'Paper Order';
    }
  };

  const ruledColumns = [
    {
      field: 'role',
      valueFormatter: ({ value }) => readableRole(value),
    },
    {
      field: 'price',
      type: 'number',
      valueFormatter: ({ value }) => {
        let price = value;
        if (userContext.userState.me.customerBuild && price) price = price / 0.64;
        return price
          ? `${price.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}`
          : 'N/A';
      },
    },
    {
      field: 'dealerships',
      valueFormatter: ({ value }) => value.length,
    },
    {
      field: 'rules',
      valueGetter: (params) => `${params.row.rules.length}`,
    },
    {
      field: 'tags',
      valueGetter: (params) => `${params.row.tags.length}`,
    },
    {
      field: 'brand',
      valueFormatter: ({ value }) => (value === 1 ? 'Avalon' : 'Tahoe'),
    },
    {
      field: 'required',
      valueFormatter: ({ value }) => (value === 1 ? 'Yes' : ''),
    },
    {
      field: 'orderStatus',
      renderCell: ({ value, row }) => orderStatus(value, row['orderSource']),
    },
    {
      field: 'active',
      valueFormatter: ({ value }) => (value ? 'Active' : 'Disabled'),
    },
    {
      field: 'epicorOrder',
      renderCell: ({ value, row }) =>
        value !== null ? (
          <a
            target="_blank"
            href={`https://www.teamavalonpontoons.com/dealerportal/account/orderpage.php?dealerid=${row['customerId']}&order=${value}`}
          >
            {value}
          </a>
        ) : (
          ''
        ),
    },
    {
      field: 'markup',
      valueFormatter: ({ value }) => `${value}%`,
    },
    {
      field: 'floorplanDiscount',
      valueFormatter: ({ value }) => `${value}%`,
    },
    {
      field: 'motorDiscount',
      valueFormatter: ({ value }) => `${value}%`,
    },
    {
      field: 'sent',
      valueFormatter: ({ value }) => (value === true ? 'Yes' : 'No'),
    },
    {
      field: 'createdAt',
      valueFormatter: ({ value }) => format(new Date(value), 'MM/dd/yyyy'),
    },
    {
      field: 'confirmed',
      renderCell: ({ value, row }) => {
        if (row.orderStatus === 2) {
          return value !== null ? (
            value
          ) : (
            <Button
              color="secondary"
              variant="text"
              size="small"
              className={classes.button}
              onClick={() => handleOpenApproveOrder(row.id)}
            >
              Approve Order
            </Button>
          );
        } else {
          return value;
        }
      },
    },
    {
      field: 'notes',
      renderCell: ({ row, value }) => (
        <CommentIcon
          size="small"
          onClick={() => openNotes(row)}
          color={
            value.filter((v) => v.pageId === null).length === 0
              ? 'disabled'
              : 'secondary'
          }
        />
      ),
    },
    {
      field: 'imgPath',
      renderCell: ({ value }) =>
        value !== null ? <ImageIcon fontSize="small" /> : 'N/A',
    },
    {
      field: 'disableConfirm',
      valueFormatter: ({ value }) => (value === false ? 'No' : 'Yes'),
    },
    {
      field: 'thumbnailImgPath',
      valueFormatter: ({ value }) => (value === null ? '' : value),
    },
    {
      field: 'optionOrder',
      valueFormatter: ({ value }) => (value === null ? '' : value),
    },
  ];

  const gridColumns = useCallback(() => {
    if (data && data.length > 0) {
      const columns = Object.keys(data[0]);
      const validColumns = columns
        .filter((column) => hiddenColumns.indexOf(column) === -1)
        .map((column) => {
          const index = ruledColumns.findIndex((c) => c.field === column);
          if (index !== -1) {
            return {
              ...ruledColumns[index],
              headerName: (column.charAt(0).toUpperCase() + column.slice(1))
                .match(/[A-Z][a-z]+|[0-9]+/g)
                .join(' '),
              flex: 1,
            };
          } else {
            return {
              field: column,
              headerName: (column.charAt(0).toUpperCase() + column.slice(1))
                .match(/[A-Z][a-z]+|[0-9]+/g)
                .join(' '),
              flex: 1,
              valueFormatter: ({ value }) => value || '',
            };
          }
        });

      return validColumns;
    }
  }, [data]);

  const initLayout = useMemo(() => {
    let filterInfo = [];
    let sortInfo = [];
    let hiddenColumns = {};
    const columns = gridColumns();

    if (!columns) {
      return;
    }

    layoutInfo?.filter?.map((f) => {
      if (columns.findIndex((c) => c.field === f.columnField) > -1) {
        filterInfo.push(f);
      }
    });
    if (
      layoutInfo?.sort &&
      columns.findIndex((c) => c.field === layoutInfo?.sort?.field) > -1
    ) {
      sortInfo = layoutInfo?.sort;
    }
    layoutInfo?.hiddenColumns?.map((hc) => {
      if (columns.findIndex((c) => c.field === hc) > -1) {
        hiddenColumns[hc] = false;
      }
    });

    return {
      filter: {
        filterModel: {
          items: filterInfo,
          linkOperator: GridLinkOperator.And,
        },
      },
      sorting: {
        sortModel: [sortInfo],
      },
      columns: {
        columnVisibilityModel: hiddenColumns,
      },
    };
  }, [layoutInfo, gridColumns]);

  const handleChangeFilterColumns = (value) => {
    const newFilterInfo = value.items.map(
      ({ columnField, operatorValue, value, ...other }) => {
        return { columnField, operatorValue, value };
      }
    );
    setLayoutInfo({
      ...layoutInfo,
      filter: newFilterInfo,
    });
  };

  const handleChangeSortColumn = (value) => {
    setLayoutInfo({
      ...layoutInfo,
      sort: value[0],
    });
  };

  const handleChangeShowColumns = (value) => {
    setLayoutInfo({
      ...layoutInfo,
      hiddenColumns: Object.keys(value),
    });
  };

  const updateTotalSelect = (selectedRows) => {
    let temp = totalSelected;
    const unselectedRows = rows
      .filter((r) => selectedRows.findIndex((sr) => sr === r.id) === -1)
      .map((usr) => usr.id);

    // Remove unselectedRows
    temp = temp.filter((ce) => unselectedRows.indexOf(ce) === -1);
    selectedRows.map((sr) => {
      if (temp.indexOf(sr) === -1) {
        temp.push(sr);
      }
    });

    setTotalSelected(temp);
  };

  return (
    <div className={classes.root}>
      <Paper
        elevation={props.elevation !== undefined ? props.elevation : 1}
        className={classes.paper}
      >
        <EnhancedTableToolbar
          title={title}
          numSelected={totalSelectedShow ? totalSelected.length : selected.length}
          rows={rows}
          selected={selected}
          totalSelected={totalSelected}
          addData={props.addData}
          openEditData={props.openEditData}
          setEditData={props.setEditData}
          disableActionIcon={
            props.disableActionIcon !== undefined ? props.disableActionIcon : false
          }
          initSelected={initSelected}
          updateBulk={updateBulk}
          viewOrder={viewOrder}
          filterTypes={filterTypes}
          overrideInit={overrideInit}
          paperOrder={paperOrder}
          bulkReturnToQuote={bulkReturnToQuote}
          deleteOrder={deleteOrder}
          createLocationOrder={createLocationOrder}
          handleOpenEditERP={handleOpenEditERP}
          downloadOrders={downloadOrders}
          setSearchText={setSearchText}
          handleDownloadXML={handleDownloadXML}
        />
        {rows.length === 0 ? (
          <h2 style={{ textAlign: 'center', paddingBottom: '1em' }}>
            No {props.title.toLowerCase()} found
          </h2>
        ) : (
          <div style={{ flexGrow: 1 }}>
            <DataGridPro
              initialState={initLayout}
              autoHeight
              pagination
              checkboxSelection={!disableSelectAll}
              disableSelectionOnClick={!disableSelectAll}
              className={classes.table}
              density="comfortable"
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              columns={gridColumns()}
              disableMultipleSelection={disableSelectAll}
              selectionModel={selected}
              onSelectionModelChange={(selectedRows) => {
                updateTotalSelect(selectedRows);
                setSelected(selectedRows);
                if (disableSelectAll && selectedRows.length === 1) {
                  if (selectedRows[0] === selected[0]) setSelected([]);
                }
                if (disableSelectAll && setSelectedData && selectedRows.length > 0) {
                  setSelectedData(
                    data.find((r) => r.id === selectedRows[selectedRows.length - 1])
                  );
                }
              }}
              rowsPerPageOptions={[5, 10, 25, 50]}
              page={page}
              pageSize={rowsPerPage}
              rowCount={rows.length}
              onPageChange={handleChangePage}
              onPageSizeChange={handleChangeRowsPerPage}
              rows={rows}
              rowHeight={38}
              onFilterModelChange={handleChangeFilterColumns}
              onSortModelChange={handleChangeSortColumn}
              onColumnVisibilityModelChange={handleChangeShowColumns}
            />
          </div>
        )}
      </Paper>
    </div>
  );
};

export default EnhancedTable;
