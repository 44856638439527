import React, { useState, useEffect, useContext } from 'react';
import {
  Grid,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  FormControlLabel,
  Checkbox,
  Tab,
  Tabs,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import Loading from '../Loading';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import AddNote from '../forms/AddNote';
import { formatISO, format } from 'date-fns';
import { UserContext } from '../../userContext';
import html2pdf from 'html2pdf.js';
import PDF from './Components/PDF';
import MY21Order from './Components/MY21Order';
import EditOrderDealership from '../forms/EditOrderDealership';
import { parseBrand } from '../../lib';
import collection from 'lodash/collection';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Header from './Components/Header';
import ImageRow from './Components/ImageRow';
import Options from './Components/Options';
import CustomerInfo from './Components/CustomerInfo';
import EpicorInfo from './Components/EpicorInfo';
import Warnings from './Components/Warnings';
import ActionsBar from './Components/ActionsBar';
import RebatesTable from '../RebatesTable';
import RebateForm from '../RebateForm';
import _ from 'lodash';
import { formatPrice } from '../../lib';

const useStyles = makeStyles((theme) => ({
  logo: {
    display: 'none',
    ['@media print']: {
      display: 'block',
      marginBottom: '0px',
    },
  },
  actionBar: {
    padding: theme.spacing(2, 0, 3, 0),
    ['@media print']: {
      display: 'none',
    },
  },
  floorplanImage: {
    width: '100%',
    maxWidth: '250px',
    marginTop: theme.spacing(2),
    ['@media print']: {
      display: 'none',
    },
  },
  floorplanImageAlt: {
    width: '100%',
    maxWidth: '250px',
    marginTop: theme.spacing(1),
    ['@media print']: {
      display: 'block',
      margin: 'auto',
    },
  },
  heroImageParent: {
    height: '180px',
    [theme.breakpoints.down('lg')]: {
      maxHeight: '140px',
    },
    ['@media print']: {
      display: 'block',
    },
  },
  heroImageParentAlt: {
    height: '140px',
    display: 'block',
    [theme.breakpoints.down('lg')]: {
      maxHeight: '140px',
    },
  },
  summary: {
    paddingTop: '0px!important',
    marginTop: '8px',
    [theme.breakpoints.down('md')]: {
      borderRight: 'none',
    },
  },
  summaryTitle: {
    fontWeight: '500',
  },
  summaryText: {
    paddingBottom: '6px!important',
  },
  page: {
    marginBottom: theme.spacing(1),
  },
  note: {
    wordWrap: 'break-word',
    maxWidth: '43vw',
  },
  order: {
    '-webkit-print-color-adjust': 'exact !important',
    'print-color-adjust': 'exact !important',
    ['@media print']: {
      height: 'auto',
      marginBottom: 0,
      marginTop: '16px',
      position: 'absolute',
      top: '0',
      left: '0',
    },
    marginBottom: theme.spacing(4),
  },
  my21: {
    ['@media print']: {
      display: 'block',
      position: 'absolute',
      top: '15px',
      left: '15px',
    },
  },
  pdf: {
    display: 'none',
    padding: theme.spacing(0, 4),
  },
  optionsTable: {
    width: '100%',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    '& thead tr th': {
      padding: theme.spacing(1, 2),
    },
    '& tbody tr td': {
      padding: theme.spacing(1, 2),
    },
    '& tbody tr:nth-child(odd)': {
      background: '#F8F8F8',
      borderRadius: '7px',
    },
    '&.parallel tbody tr td': {
      padding: theme.spacing(0.25, 2),
      height: '30px',
      fontSize: '12px',
    },
  },
  disableStandardFeatures: {
    ['@media print']: {
      display: 'none',
    },
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  pdfHeader: {
    fontWeight: 'bold',
    marginBottom: '6px',
  },
  orderInfo: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  customerInfo: {
    marginBottom: theme.spacing(1),
  },
  showPrint: {
    display: 'none',
    ['@media print']: {
      display: 'block',
    },
  },
  hidePrint: {
    ['@media print']: {
      display: 'none',
    },
  },
  button: {
    margin: theme.spacing(1, 2, 0, 0),
  },
}));

const Order = (props) => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const { orderId, archive, orderSource, locationId, dealershipId } = useParams();
  const [boatImages, setBoatImages] = useState([]);
  const [benchImages, setBenchImages] = useState([]);
  const [optionsByPage, setOptionsByPage] = useState([]);
  const [showMSRP, setShowMSRP] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [action, setAction] = useState({
    name: '',
    type: '',
    message: '',
    showButton: true,
  });
  const [actionLoading, setActionLoading] = useState(false);
  const [openDiscounts, setOpenDiscounts] = useState(false);
  const [showDealerPricing, setShowDealerPricing] = useState(
    userContext.userState.me.customerBuild
  );
  const [disableStandardFeatures, setDisableStandardFeatures] = useState(true);
  const [hasNotes, setHasNotes] = useState(false);
  const [openSubmit, setOpenSubmit] = useState(false);
  const [openVerify, setOpenVerify] = useState(false);
  const [selectedQuarter, setSelectedQuarter] = useState('');
  const [availableQuarters, setAvailableQuarters] = useState([
    { value: 'q1', name: 'Q1' },
    { value: 'q2', name: 'Q2' },
    { value: 'q3', name: 'Q3' },
    { value: 'q4', name: 'Q4' },
  ]);
  const [discounts, setDiscounts] = useState({
    markup: '0',
    floorplan: '0',
    floorplan2: '0',
    motor: '0',
    motor2: '0',
    markupOptions: [],
    discountOptions: [],
    locationDiscountType: null,
    useDiscounts: false,
  });
  const [openHistory, setOpenHistory] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [update, setUpdate] = useState({
    update: [],
    add: [],
    remove: [],
    version: 2,
  });
  const [sortedOptions, setSortedOptions] = useState([]);
  const [warning, setWarning] = useState('');
  const [pdfOptions, setPDFOptions] = useState([]);
  const [splitPDFOptions, setSplitPDFOptions] = useState([]);
  const [openEditDealership, setOpenEditDealership] = useState(false);
  const [dealerships, setDealerships] = useState([]);
  const [modelConfirmation, setModelConfirmation] = useState(false);
  const permissions = userContext.userState.me.permissions;
  const [outsideRepEmails, setOutsideRepEmails] = useState([]);
  const defaultMSRP = 0.64;
  const [openRebates, setOpenRebates] = useState(false);
  const [rebateView, setRebateView] = useState(0);
  const [selectedRebate, setSelectedRebate] = useState(null);

  let location = useLocation();

  const getPageName = (pageId) => {
    switch (pageId) {
      case 2:
        return 'Colors';
      case 3:
        return 'Packages';
      case 4:
        return 'Performance';
      case 5:
        return 'Additional Features';
    }
  };

  useEffect(() => {
    const loadOrder = async () => {
      if (location.search !== '') {
        // we have query tags
        let queryString = location.search.split('=');
        if (queryString[0] === '?msrp') {
          setShowMSRP(true);
        }
      }

      try {
        let { data } = await axios.get('/api/order', {
          params: { id: orderId, orderSource },
        });
        if (data.notes.length > 0) setHasNotes(true);
        if (data.orderStatus === 5 || data.options.length === 0) {
          alert('Unable to view paper orders, redirecting to view orders');
          userContext.userState.me.role < 10
            ? navigate('/dealerships')
            : navigate('/locations');
        } else {
          data.price = 0;
          for (let option of data.options) {
            if (option.lastVersion === data.orderVersion) data.price += option.price;
          }

          let tmpOptionsByPage = {};
          for (let o of data.options) {
            // console.log(o.input.page.id)
            let pageId = o.inputId === 164 ? 1 : o.input.page.id;
            if (o.lastVersion === data.orderVersion) {
              if (tmpOptionsByPage[pageId] === undefined) {
                tmpOptionsByPage[pageId] = {
                  name: o.inputId === 164 ? 'Initial Boat Build' : o.input.page.name,
                  options: [o],
                  note: null,
                };
              } else {
                tmpOptionsByPage[pageId].options.push(o);
              }
            }
          }

          for (let note of data.notes) {
            if (note.pageId !== null) {
              if (tmpOptionsByPage[note.pageId] !== undefined) {
                // tmpOptionsByPage[note.pageId].note = note.text;
              } else {
                tmpOptionsByPage[note.pageId] = {
                  name: getPageName(note.pageId),
                  options: [],
                  note: note.text,
                };
              }
            }
          }

          // console.log("BUILD SUMMARY: ", data.buildSummary)
          let foundBoatImages = [data.options.find((i) => i.inputId === 1)];
          foundBoatImages = foundBoatImages.concat(
            data.options.filter(
              (o) =>
                o.input.overlayImage === 'BOAT' &&
                o.lastVersion === data.orderVersion
            )
          );

          foundBoatImages = foundBoatImages.map((bi) => {
            return { ...bi, imageOrder: bi.input.imageOrder };
          });

          collection.sortBy(foundBoatImages, ['imageOrder']);

          // console.log(foundBoatImages);
          let foundBenchImages = data.options.filter(
            (o) =>
              o.input.overlayImage === 'BENCH' && o.lastVersion === data.orderVersion
          );
          foundBenchImages.sort((a, b) => a.inputId < b.inputId);

          let useDiscounts = false;
          switch (data.location.locationDiscountType) {
            case 'DEALER':
              if (!userContext.userState.me.customerBuild) useDiscounts = true;
              break;
            case 'RETAIL':
              if (userContext.userState.me.customerBuild) useDiscounts = true;
              break;
            case 'BOTH':
              useDiscounts = true;
              break;
          }

          const modelId = data.options.find((o) => o.inputId === 1).inputOptionId;
          let defaultMarkup = !data.location.useModelMarkup
            ? data.location.markup
            : data.location.modelMarkups.find((mm) => mm.modelId === modelId).markup;
          let tmpDiscounts = {
            markup: defaultMarkup,
            defaultMarkup,
            floorplan: data.location.floorplanDiscount,
            floorplan2: data.location.floorplanDiscount2,
            motor: data.location.motorDiscount,
            motor2: data.location.motorDiscount2,
            markupOptions: [],
            discountOptions: [],
            locationDiscountType: data.location.locationDiscountType,
            useDiscounts,
          };

          for (let i = 0; i < 41; i++) {
            tmpDiscounts.markupOptions.push(i);
          }

          for (let i = 0; i < 101; i++) {
            tmpDiscounts.discountOptions.push(i);
          }

          // Determine available quarters
          switch (data.currentQuarter) {
            case 'q1':
              setAvailableQuarters([
                { value: 'q1', name: 'Q1' },
                { value: 'q2', name: 'Q2' },
                { value: 'q3', name: 'Q3' },
                { value: 'q4', name: 'Q4' },
              ]);
              break;
            case 'q2':
              setAvailableQuarters([
                { value: 'q2', name: 'Q2' },
                { value: 'q3', name: 'Q3' },
                { value: 'q4', name: 'Q4' },
              ]);
              break;
            case 'q3':
              setAvailableQuarters([
                { value: 'q3', name: 'Q3' },
                { value: 'q4', name: 'Q4' },
              ]);
              break;
            case 'q4':
              setAvailableQuarters([{ value: 'q4', name: 'Q4' }]);
              break;
            default:
              console.log('Quarter not found');
          }

          let version = 2;
          for (let action of data.actionHistory.reverse()) {
            if (action.actionType === 'UPDATE') {
              // The date is the day the order version system was pushed to live
              if (new Date(action.createdAt) >= new Date('2022-11-17')) {
                action.version = version;
                version++;
              }
            }
          }

          data.actionHistory.reverse();

          setDiscounts(tmpDiscounts);
          setBoatImages(foundBoatImages);
          setBenchImages(foundBenchImages);

          data.model = data.options.find((o) => o.inputId === 1).optionDropdownName;
          data.floorplan = data.options.find(
            (o) => o.inputId === 2
          ).optionDropdownName;
          data.finance = data.options.find(
            (o) => o.inputId === 164
          )?.optionDropdownName;
          data.floorplanImage = data.options.find(
            (o) => o.inputId === 2
          ).optionImgPath;

          setOrder(data);
          setOptionsByPage(tmpOptionsByPage);
          setSortedOptions(
            data.options
              .filter(
                (o) =>
                  (o.input.hidePrint || o.price > 0) &&
                  o.inputId !== 142 &&
                  o.inputId !== 164 &&
                  o.lastVersion === data.orderVersion
              )
              .sort((a, b) => a.input.printOrder - b.input.printOrder)
          );

          const availableOptions = data.options
            .filter(
              (o) =>
                (o.input.hidePrint || o.price > 0) &&
                o.inputId !== 142 &&
                o.inputId !== 164 &&
                o.lastVersion === data.orderVersion
            )
            .sort((a, b) => a.input.printOrder - b.input.printOrder);

          const motorOption = availableOptions.filter((o) => o.inputId === 6);
          // if (motorOption) console.log('MOTOR: ', motorOption);

          if (availableOptions.length > 13 && availableOptions.length <= 36) {
            let split = availableOptions.length / 2;
            setPDFOptions(availableOptions.filter((option, index) => index < split));
            setSplitPDFOptions(
              availableOptions.filter((option, index) => index >= split)
            );
          } else if (availableOptions.length > 36) {
            const optionsLength = Math.ceil(availableOptions.length / 2) + 1;
            setPDFOptions(
              availableOptions.filter((option, index) => index < optionsLength)
            );
            setSplitPDFOptions(
              availableOptions.filter((option, index) => index >= optionsLength)
            );
          } else {
            setPDFOptions(availableOptions);
            setSplitPDFOptions([]);
          }

          if (
            permissions.indexOf('change_dealership') !== -1 &&
            data.orderStatus === 3 &&
            !data.isInvoiced
          ) {
            const loadedDealerships = await axios.get('/api/dealers/locations/all', {
              params: { brand: data.dealership.brand },
            });

            setDealerships(loadedDealerships.data);
          }

          if (userContext.userState.me.role >= 10) {
            let repsWithEmails = [];
            for (const rep of data.dealership.reps) {
              if (rep.user.role === 3) repsWithEmails.push(rep.user.email);
            }
            // console.log('Outside rep notification list: ', repsWithEmails);
            setOutsideRepEmails(repsWithEmails);
          }
          setLoading(false);
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    const loadArchivedOrder = async () => {
      let { data } = await axios.get('/api/order/archived', {
        params: { id: orderId },
      });

      let tmpDiscounts = {
        markup: '0',
        floorplan: '0',
        floorplan2: '0',
        motor: '0',
        motor2: '0',
        markupOptions: [],
        discountOptions: [],
        locationDiscountType: data.location.locationDiscountType,
      };
      for (let i = 0; i < 41; i++) {
        tmpDiscounts.markupOptions.push(i);
      }

      for (let i = 0; i < 101; i++) {
        tmpDiscounts.discountOptions.push(i);
      }

      setDiscounts(tmpDiscounts);

      setOrder(data.order);
      setLoading(false);
    };

    setLoading(true);

    if (archive === 'MY21') {
      loadArchivedOrder();
    } else {
      loadOrder();
    }
  }, [orderId]);

  useEffect(() => {
    setShowMSRP(userContext.userState.me.customerBuild);
    let useDiscounts = false;
    switch (discounts.locationDiscountType) {
      case 'DEALER':
        if (!userContext.userState.me.customerBuild) useDiscounts = true;
        break;
      case 'RETAIL':
        if (userContext.userState.me.customerBuild) useDiscounts = true;
        break;
      case 'BOTH':
        useDiscounts = true;
        break;
    }
    setDiscounts({ ...discounts, useDiscounts });
  }, [userContext.userState.me.customerBuild]);

  const getStatus = (status) => {
    switch (status) {
      case 0:
        if (order.orderSource === 2) {
          return 'Lead';
        } else {
          return 'Quote';
        }
      case 1:
        if (order.orderSource === 2) {
          return 'Order Created';
        } else {
          return `Submitted ${order?.buildQuarter?.toUpperCase()}`;
        }
      case 2:
        return 'Confirmed';
      case 3:
        return 'Entered';
    }
  };

  const handleSelectRebate = (rebateId) => {
    if (rebateId === selectedRebate) {
      setSelectedRebate(null);
    } else {
      setSelectedRebate(rebateId);
    }
  };

  const handleUpdateRebate = async (newStatus) => {
    const rebateUpdate = {
      rebateId: selectedRebate,
      rebateStatus: newStatus,
    };

    try {
      const { data: newRebate } = await axios.put('/api/order/rebate', rebateUpdate);
      console.log(newRebate);
      setOrder({
        ...order,
        rebates: order.rebates.map((r) => {
          if (r.id === selectedRebate) {
            return { ...newRebate };
          } else {
            return r;
          }
        }),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenRebates = async () => {
    setOpenRebates(true);
    if (order.epicorOrderNumber && !order.epicorData) {
      try {
        const { data } = await axios.get('/api/order/rebate/epicor', {
          params: { epicorOrderId: order.epicorOrderNumber },
        });
        if (!data.message) {
          setOrder({ ...order, epicorData: data });
        } else {
          console.log(data.message);
        }
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const handleCloseRebates = () => {
    setOpenRebates(false);
  };

  const handleOpenEditDealership = () => {
    setOpenEditDealership(true);
  };

  const handleCloseEditDealership = () => {
    setOpenEditDealership(false);
  };

  const handleOpenVerify = () => {
    setOpenVerify(true);
  };

  const handleCloseVerify = () => {
    setOpenVerify(false);
  };

  const handleOpenNotes = () => {
    setOpenNotes(true);
  };

  const handleCloseNotes = () => {
    setOpenNotes(false);
  };

  const handleOpenHistory = () => {
    setOpenHistory(true);
  };

  const handleCloseHistory = () => {
    setOpenHistory(false);
  };

  const handleOpenSubmit = () => {
    setOpenSubmit(true);
    setModelConfirmation(false);
  };

  const handleCloseSubmit = () => {
    setSelectedQuarter('');
    setOpenSubmit(false);
  };

  const handleOpenActionModal = (action) => {
    setAction(action);
    setActionLoading(false);
    setOpenActionModal(true);
  };

  const handleCloseActionModal = () => {
    setOpenActionModal(false);
  };

  const handleEditOrder = () => {
    const brand =
      order.dealership !== null ? parseBrand(order.dealership.brand) : 'a';
    const model = order.options.find((i) => i.inputId === 1).inputOptionId;
    const floorplan = order.options.find((i) => i.inputId === 2).inputOptionId;
    const to = `/order/${orderId}/d/${brand}/model/${model}/floorplan/${floorplan}/page/1`;

    if (!brand || !model || !floorplan) {
      return alert(
        'There was an error loading the order for edit, please refresh the page and try again'
      );
    }

    if (userContext.userState.me.role <= 3) {
      navigate(`${to}?debug=true`);
    } else {
      navigate(to);
    }
  };

  const handleVerifyOrder = async () => {
    setActionLoading(true);
    try {
      let values = {
        orderId: order.id,
        verified: true,
      };
      console.log(values);
      setActionLoading(false);
      handleCloseVerify();
    } catch (err) {
      setAction({
        ...action,
        message: err.response.data.message,
        showButton: false,
      });
      setActionLoading(false);
    }
  };

  const handleSetRebates = (updatedRebates) => {
    setOrder({ ...order, rebates: updatedRebates });
  };

  const handleSubmitOrder = async () => {
    setActionLoading(true);
    try {
      let values = {
        orderId: order.id,
        confirmedDate: formatISO(new Date()),
        lastModified: formatISO(new Date()),
        notification: {
          orderId: order.id,
          locationName: order.location.name,
          emails: [],
          url: `${window.location.protocol}//${window.location.hostname}/dealerships/${order.dealership.id}/locations/${order.location.id}/current/orders/${order.id}`,
        },
        buildQuarter: selectedQuarter,
      };

      await axios.put('/api/order/confirm', values);
      setOrder({ ...order, orderStatus: 1, buildQuarter: selectedQuarter });
      setActionLoading(false);
      handleCloseSubmit();
      if (userContext.userState.me.role <= 3) {
        if (dealershipId || locationId === undefined) {
          navigate('/orders/quote');
        } else {
          navigate(
            `/dealerships/${dealershipId}/locations/${locationId}/${archive}/submitted`
          );
        }
      } else {
        navigate(`/locations/${locationId}/${archive}/submitted`);
      }
    } catch (err) {
      setAction({
        ...action,
        message: err.response.data.message,
        showButton: false,
      });
      setActionLoading(false);
    }
  };

  const handleAction = async () => {
    switch (action.type) {
      case 'verify':
        setActionLoading(true);
        try {
          let values = {
            orderId: order.id,
            verified: true,
          };
          const { data } = await axios.put(`/api/order/verify`, values);
          setOrder({ ...order, verified: true });
          setAction({ ...action, message: data.message, showButton: false });
          setActionLoading(false);
          navigate('/orders/submittednv');
        } catch (err) {
          setAction({
            ...action,
            message: err.response.data.message,
            showButton: false,
          });
          setActionLoading(false);
        }
        break;
      case 'confirm':
        setActionLoading(true);
        try {
          let values = {
            orderId: order.id,
            confirmedDate: formatISO(new Date()),
            lastModified: formatISO(new Date()),
            notification: {
              orderId: order.id,
              locationName: order.location.name,
              emails: [],
              url: `${window.location.protocol}//${window.location.hostname}/dealerships/${order.dealership.id}/locations/${order.location.id}/current/orders/${order.id}`,
            },
          };

          const { data } = await axios.put('/api/order/confirm', values);
          setOrder({ ...order, orderStatus: 1 });
          setAction({ ...action, message: data.message, showButton: false });
          setActionLoading(false);
        } catch (err) {
          setAction({
            ...action,
            message: err.response.data.message,
            showButton: false,
          });
          setActionLoading(false);
        }
        break;
      case 'duplicate':
        setActionLoading(true);
        try {
          const { data } = await axios.get('/api/order/duplicate', {
            params: { id: order.id },
          });
          if (order.orderSource === 2) setOrder({ ...order, orderSource: 1 });
          setAction({ ...action, message: data.message, showButton: false });
          setActionLoading(false);
        } catch (err) {
          setAction({
            ...action,
            message: err.response.data.message,
            showButton: false,
          });
          setActionLoading(false);
        }
        break;
      case 'reset':
        setActionLoading(true);
        try {
          const { data } = await axios.get('/api/order/reset', {
            params: { id: order.id, orderStatus: order.orderStatus },
          });
          setOrder({
            ...order,
            orderStatus: 0,
            erpNumber: null,
            confirmedDate: null,
          });
          setAction({ ...action, message: data.message, showButton: false });
          setActionLoading(false);
        } catch (err) {
          setAction({
            ...action,
            message: err.response.data.message,
            showButton: false,
          });
          setActionLoading(false);
        }
        break;
    }
  };

  const download = async () => {
    setDisableStandardFeatures(false);
    console.log('starting download');
    try {
      let t = document.getElementById('pdf').innerHTML;
      var opt = {
        margin: [5, 2],
        filename: `Order_${order.id}_${
          order.options.find((o) => o.inputId === 1).optionDropdownName
        }_${order.options
          .find((o) => o.inputId === 2)
          .optionDropdownName.replace("'", '')
          .replace(' ', '_')}_${format(new Date(), 'MM_dd_yyyy')}`,
        html2canvas: {
          scale: 4,
          useCORS: true,
        },
        pagebreak: {
          before: '.pagebreak',
        },
      };
      html2pdf().set(opt).from(t).save();
      setDisableStandardFeatures(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenDiscounts = () => {
    setOpenDiscounts(true);
  };

  const handleCloseDiscounts = () => {
    setOpenDiscounts(false);
  };

  const handleChangeDiscounts = (value, type) => {
    let tmpDiscounts = { ...discounts };
    switch (type) {
      case 'markup':
        tmpDiscounts.markup = value;
        break;
      case 'floorplan':
        tmpDiscounts.floorplan = value;
        break;
      case 'floorplan2':
        tmpDiscounts.floorplan2 = value;
        break;
      case 'motor':
        tmpDiscounts.motor = value;
        break;
      case 'motor2':
        tmpDiscounts.motor2 = value;
        break;
    }

    setDiscounts(tmpDiscounts);
    calculateTotalPrice();
    calculateInternetPrice();
  };

  const calculatePrice = (option, asNumber = false) => {
    let calculatedPrice = option.price;
    if (showMSRP) {
      // Only use markups for retail build
      calculatedPrice =
        +discounts.markup !== 0
          ? calculatedPrice / ((100 - +discounts.markup) / 100)
          : calculatedPrice / defaultMSRP;
    }

    calculatedPrice = Math.ceil(calculatedPrice);
    if (option.inputId === 6 && discounts.useDiscounts) {
      calculatedPrice = Math.ceil(
        calculatedPrice * ((100 - parseInt(discounts.motor, 10)) / 100)
      );
      calculatedPrice = Math.ceil(
        calculatedPrice * ((100 - parseInt(discounts.motor2, 10)) / 100)
      );
    }

    if (asNumber) return calculatedPrice;
    return calculatedPrice === 0 ? '' : formatPrice(calculatedPrice);
  };

  const calculateTotalPrice = (options = null, asNumber = false) => {
    const modelId = order.options.find((o) => o.inputId === 1).inputOptionId;
    let calculatedPrice = 0;
    let currentOrderOptions = order.options.filter(
      (o) => o.lastVersion === order.orderVersion
    );
    for (let option of currentOrderOptions) {
      let tmpPrice = option.price;
      if (showMSRP) {
        tmpPrice =
          +discounts.markup !== 0
            ? tmpPrice / ((100 - +discounts.markup) / 100)
            : tmpPrice / defaultMSRP;
      }

      tmpPrice = Math.ceil(tmpPrice);
      if (
        option.inputId === 2 &&
        discounts.useDiscounts &&
        ![1023, 1024].includes(modelId)
      ) {
        tmpPrice = Math.ceil(
          (tmpPrice * (100 - parseInt(discounts.floorplan, 10))) / 100
        );
        tmpPrice = Math.ceil(
          tmpPrice * ((100 - parseInt(discounts.floorplan2, 10)) / 100)
        );
      }

      if (
        option.inputId === 6 &&
        discounts.useDiscounts &&
        ![1023, 1024].includes(modelId)
      ) {
        tmpPrice = Math.ceil(
          tmpPrice * ((100 - parseInt(discounts.motor, 10)) / 100)
        );
        tmpPrice = Math.ceil(
          tmpPrice * ((100 - parseInt(discounts.motor2, 10)) / 100)
        );
      }

      calculatedPrice += Math.ceil(tmpPrice);
      // console.log(option.price)
    }
    if (asNumber) return Math.ceil(calculatedPrice);
    return formatPrice(calculatedPrice);
  };

  const calculateInternetPrice = () => {
    const modelId = order.options.find((o) => o.inputId === 1).inputOptionId;
    let calculatedPrice = 0;
    let currentOrderOptions = order.options.filter(
      (o) => o.lastVersion === order.orderVersion
    );
    for (let option of currentOrderOptions) {
      let tmpPrice = option.price;
      if (showMSRP) {
        tmpPrice =
          +discounts.markup !== 0
            ? tmpPrice / ((100 - +discounts.markup) / 100)
            : tmpPrice / defaultMSRP;
      }

      if (
        option.inputId === 2 &&
        discounts.useDiscounts &&
        ![1023, 1024].includes(modelId)
      ) {
        tmpPrice = tmpPrice * ((100 - parseInt(discounts.floorplan, 10)) / 100);
        tmpPrice = tmpPrice * ((100 - parseInt(discounts.floorplan2, 10)) / 100);
      }

      if (option.inputId === 6 && discounts.useDiscounts) {
        tmpPrice = tmpPrice * ((100 - parseInt(discounts.motor, 10)) / 100);
        tmpPrice = tmpPrice * ((100 - parseInt(discounts.motor2, 10)) / 100);
      }

      calculatedPrice += tmpPrice;
      // console.log(option.price)
    }

    return formatPrice(calculatedPrice * 0.89);
  };

  const handleTogglePricing = (value) => {
    let useDiscounts = false;
    if (value) {
      setShowDealerPricing(false);
      setShowMSRP(value);

      switch (discounts.locationDiscountType) {
        case 'DEALER':
          if (!value) useDiscounts = true;
          break;
        case 'RETAIL':
          if (value) useDiscounts = true;
          break;
        case 'BOTH':
          useDiscounts = true;
          break;
      }
    } else {
      setShowDealerPricing(userContext.userState.me.customerBuild);
      setShowMSRP(false);
      switch (discounts.locationDiscountType) {
        case 'DEALER':
          useDiscounts = true;
          break;
        case 'RETAIL':
          useDiscounts = false;
          break;
        case 'BOTH':
          useDiscounts = true;
          break;
      }
    }
    setDiscounts({ ...discounts, useDiscounts });
  };

  const calculateFloorplanDiscount = (price) => {
    let tmpPrice = userContext.userState.me.customerBuild
      ? discounts.markup !== 0
        ? price / ((100 - discounts.markup) / 100)
        : price / defaultMSRP
      : price;
    tmpPrice = Math.ceil(tmpPrice);
    let discount1 = 0;
    let discount2 = 0;
    const currentModelId = order.options.find((o) => o.inputId === 1).inputOptionId;
    if ([1023, 1024].includes(currentModelId)) return;
    if (discounts.floorplan !== 0) {
      discount1 =
        tmpPrice - Math.ceil((tmpPrice * (100 - discounts.floorplan)) / 100);
    }

    if (discounts.floorplan2 !== 0) {
      discount2 =
        tmpPrice -
        discount1 -
        Math.ceil((tmpPrice - discount1) * ((100 - discounts.floorplan2) / 100));
    }
    return formatPrice((discount1 + discount2) * -1);
  };

  const displayAvailability = (allotment, numOrders) => {
    if (Math.sign(allotment - numOrders) === -1) {
      return 0;
    } else {
      return allotment - numOrders;
    }
  };

  const canEditOrder = () => {
    // Can't edit archived or leads
    if (order.archiveId !== null || order.orderSource === 2) return false;
    // Admins/Sales can edit any order in any status
    if (
      order.orderStatus === 3 &&
      !order.isInvoiced &&
      userContext.userState.me.role <= 2
    )
      return true;
    if (order.orderStatus === 3) return false;
    if (userContext.userState.me.role <= 3) return true;
    // Disable editing of archived ids and leads

    if (order.orderStatus === 0) return true;
    // Can edit verified orders
    if (order.orderStatus === 1) return true;

    return false;
  };

  const handleHistoryClick = (action) => {
    let version = action.version;
    console.log(`Version ${version}`);
    let removedOptions = order.options.filter(
      (option) => option.lastVersion === version - 1
    );
    let addedOptions = order.options.filter(
      (option) => option.firstVersion === version
    );
    let updatedOptions = [];
    for (let ao of addedOptions) {
      let updatedOption = removedOptions.find((ro) => ro.inputId === ao.inputId);
      if (updatedOption) {
        updatedOptions.push({ old: updatedOption, new: ao });
      }
    }

    // Filter out updated inputs
    for (let uo of updatedOptions) {
      removedOptions = removedOptions.filter((ro) => ro.inputId !== uo.old.inputId);
      addedOptions = addedOptions.filter((ao) => ao.inputId !== uo.new.inputId);
    }

    setShowUpdate(true);
    setUpdate({
      update: updatedOptions,
      add: addedOptions,
      remove: removedOptions,
      version,
    });
    console.log(removedOptions, addedOptions, updatedOptions);
  };

  const handleHistoryBack = () => {
    setShowUpdate(false);
    setUpdate({
      update: [],
      add: [],
      remove: [],
      version: 2,
    });
  };

  const downloadAsCSV = async () => {
    const availableOptions = order.options
      .filter(
        (o) =>
          (o.input.hidePrint || o.price > 0) &&
          o.inputId !== 142 &&
          o.inputId !== 164 &&
          o.lastVersion === order.orderVersion
      )
      .sort((a, b) => a.input.printOrder - b.input.printOrder);

    // _.orderBy(availableOptions, ['input.printOrder'], ['asc']);

    const formattedOptions = availableOptions.map((option) => {
      return {
        NAME: option.optionName,
        OPTION: option.optionDropdownName,
        PRICE: calculatePrice(option, true),
      };
    });

    try {
      const title = `Order_${order.id}`;
      const metaInfo = {
        MODEL: order.model,
        FLOORPLAN: order.floorplan,
        'ATOM ID': order.id,
        'ORDER ID': order.epicorOrderNumber,
        'TOTAL PRICE': calculateTotalPrice(true),
      };

      const json = JSON.stringify([[metaInfo], formattedOptions]);
      const blob = new Blob([json], {
        type: 'application/json',
      });
      const data = new FormData();
      data.append('document', blob);
      data.append('title', title);
      axios({
        url: '/api/order/download/csv',
        method: 'POST',
        responseType: 'blob',
        data: data,
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${title}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const canRequestRebate = () => {
    let flag = false;
    if (permissions.indexOf('request_rebate') !== -1) flag = true;

    if (order.limitRebatesCompleted && order.orderStatus !== 3) flag = false;

    if (order.archiveId !== null && order?.disableRebate) flag = false;

    return flag;
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          {order !== null && archive !== 'MY21' ? (
            <div id="pdf" className={classes.pdf}>
              <PDF
                order={order}
                optionsByPage={optionsByPage}
                classes={classes}
                benchImages={benchImages}
                boatImages={boatImages}
                calculatePrice={calculatePrice}
                calculateTotalPrice={calculateTotalPrice}
                discounts={discounts}
                disableStandardFeatures={disableStandardFeatures}
                hasNotes={hasNotes}
                brand={order.dealership.brand}
                hasTwin={order.hasTwin}
                showMSRP={showMSRP}
                calculateFloorplanDiscount={calculateFloorplanDiscount}
                calculateInternetPrice={
                  discounts.defaultMarkup === 0 ? calculateInternetPrice : null
                }
              />
            </div>
          ) : null}
          {archive === 'MY21' ? (
            <Grid
              container
              id="order"
              justifyContent="center"
              className={classes.my21}
            >
              <Grid item xs={12} sm={10} md={8}>
                <MY21Order
                  order={order}
                  setOrder={setOrder}
                  calculatePrice={calculatePrice}
                  calculateTotalPrice={calculateTotalPrice}
                  discounts={discounts}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              id="order"
              className={classes.order}
              justifyContent="center"
              spacing={2}
            >
              <ActionsBar
                handleOpenDiscounts={handleOpenDiscounts}
                handleTogglePricing={handleTogglePricing}
                showDealerPricing={showDealerPricing}
                showMSRP={showMSRP}
                numNotes={order.notes.filter((n) => n.pageId === null).length}
                handleOpenNotes={handleOpenNotes}
                handleOpenHistory={handleOpenHistory}
                canDownload={
                  archive !== 'MY21' &&
                  orderSource !== 'leads' &&
                  order.orderSource !== 2
                }
                download={download}
                handleEditOrder={handleEditOrder}
                canEdit={canEditOrder()}
                handleOpenSubmit={handleOpenSubmit}
                canSubmit={
                  order.orderStatus === 0 &&
                  order.archiveId === null &&
                  order.orderSource === 1
                }
                canVerify={
                  order.orderStatus === 1 &&
                  order.archiveId === null &&
                  orderSource !== 'leads' &&
                  !order.verified &&
                  userContext.userState.me.role <= 3
                }
                handleOpenActionModal={handleOpenActionModal}
                canDuplicate={
                  order.archiveId === null &&
                  orderSource !== 'leads' &&
                  order.orderSource !== 2
                }
                canCreateOrder={
                  order.archiveId === null &&
                  userContext.userState.me.role <= 11 &&
                  order.orderSource === 2 &&
                  order.orderStatus === 0
                }
                canReset={
                  userContext.userState.me.role <= 2 &&
                  archive === 'current' &&
                  orderSource !== 'leads'
                }
                canEditDealership={
                  permissions.indexOf('change_dealership') !== -1 &&
                  order.orderStatus === 3 &&
                  !order.isInvoiced
                }
                handleOpenEditDealership={handleOpenEditDealership}
                downloadAsCSV={downloadAsCSV}
                rebateAllowed={canRequestRebate()}
                handleOpenRebates={handleOpenRebates}
              />
              <Grid item xs={12} className={classes.logo}>
                {(order.dealership.brand === 1 ||
                  order.dealership.brand === undefined) && (
                  <img
                    src="/logo.png"
                    alt="logo"
                    style={{ margin: '0px 12px 16px 12px' }}
                  />
                )}
                {order.dealership.brand === 2 && (
                  <img
                    src="/tahoe_logo_small.png"
                    alt="logo"
                    style={{ margin: '0px 12px 16px 12px', height: '40px' }}
                  />
                )}
              </Grid>

              {order.customerName ||
              order.customerEmail ||
              order.customerPhone ||
              order.epicorOrderNumber ? (
                <>
                  <Grid item md={8} className={classes.hidePrint}>
                    <Paper sx={{ p: 4 }}>
                      <Header
                        id={order.id}
                        dealership={order.dealership.name}
                        location={order.location.name}
                        model={order.model}
                        floorplan={order.floorplan}
                        finance={order.finance}
                        calculateTotalPrice={calculateTotalPrice}
                        showMSRP={showMSRP}
                        calculateInternetPrice={calculateInternetPrice}
                        getStatus={getStatus}
                        status={order.orderStatus}
                        defaultMarkup={discounts.defaultMarkup}
                      />
                      <ImageRow
                        boatImages={boatImages}
                        benchImages={benchImages}
                        floorplanImage={order.floorplanImage}
                      />
                      <Options
                        options={sortedOptions}
                        calculatePrice={calculatePrice}
                        calculateTotalPrice={calculateTotalPrice}
                        showMSRP={showMSRP}
                        discounts={discounts}
                        hasTwin={order.hasTwin}
                        calculateFloorplanDiscount={calculateFloorplanDiscount}
                        modelId={
                          order.options.find((o) => o.inputId === 1).inputOptionId
                        }
                      />
                      <div className={classes.showPrint}>
                        <table
                          style={{
                            width: '100%',
                            padding: '0px 24px',
                            display: 'block',
                          }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ verticalAlign: 'top' }}>
                                <table
                                  className={
                                    splitPDFOptions.length > 0
                                      ? `${classes.optionsTable} parallel`
                                      : classes.optionsTable
                                  }
                                >
                                  <thead>
                                    <tr>
                                      <th align="left" style={{ width: '100vw' }}>
                                        Options
                                      </th>
                                      <th align="right">Price</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {pdfOptions.map((o, index) => {
                                      return (
                                        <tr
                                          style={{ fontSize: '16px' }}
                                          key={`po_${o.id}`}
                                        >
                                          <td>
                                            {o.optionName}
                                            {o.optionDropdownName !== null
                                              ? ` - ${o.optionDropdownName}`
                                              : ''}
                                            {discounts !== undefined ? (
                                              <span>
                                                {discounts.floorplan !== 0 &&
                                                !showMSRP &&
                                                o.inputId === 2 ? (
                                                  <div>
                                                    Floorplan Discount -
                                                    {discounts.floorplan}%
                                                  </div>
                                                ) : null}
                                                {discounts.floorplan !== 0 &&
                                                !showMSRP &&
                                                o.inputId === 6 ? (
                                                  <div>
                                                    Motor Discount -{discounts.motor}
                                                    %
                                                  </div>
                                                ) : null}
                                              </span>
                                            ) : null}
                                          </td>
                                          <td
                                            style={{
                                              paddingLeft: '6px',
                                              textAlign: 'right',
                                            }}
                                          >
                                            {calculatePrice(o) || `$0`}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </td>
                              {splitPDFOptions.length > 0 ? (
                                <td style={{ verticalAlign: 'top', width: '50%' }}>
                                  <table
                                    className={`${classes.optionsTable} parallel`}
                                  >
                                    <thead>
                                      <tr>
                                        <th align="left" style={{ width: '100vw' }}>
                                          Options
                                        </th>
                                        <th align="right">Price</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {splitPDFOptions.map((o, index) => {
                                        return (
                                          <tr
                                            style={{ fontSize: '16px' }}
                                            key={`po_${o.id}`}
                                          >
                                            <td>
                                              {o.optionName}
                                              {o.optionDropdownName !== null
                                                ? ` -1 ${o.optionDropdownName}`
                                                : ''}
                                              {discounts !== undefined ? (
                                                <span>
                                                  {discounts.floorplan !== 0 &&
                                                  !showMSRP &&
                                                  o.inputId === 2 ? (
                                                    <div>
                                                      Floorplan Discount -
                                                      {discounts.floorplan}%
                                                    </div>
                                                  ) : null}
                                                  {discounts.floorplan !== 0 &&
                                                  !showMSRP &&
                                                  o.inputId === 6 ? (
                                                    <div>
                                                      Motor Discount -
                                                      {discounts.motor}%
                                                    </div>
                                                  ) : null}
                                                </span>
                                              ) : null}
                                            </td>
                                            <td
                                              style={{
                                                paddingLeft: '6px',
                                                textAlign: 'right',
                                              }}
                                            >
                                              {calculatePrice(o) || `$0`}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </td>
                              ) : null}
                            </tr>
                          </tbody>
                        </table>
                        <div
                          style={{
                            display: 'flex',
                            padding: '10px 24px',
                            margin: '0 24px',
                            backgroundColor: '#171A20',
                            color: '#fff',
                            borderRadius: '8px',
                          }}
                        >
                          <h3
                            style={{
                              marginTop: '0px',
                              marginBottom: '0px',
                            }}
                          >
                            TOTAL
                          </h3>
                          <h3
                            style={{
                              marginLeft: 'auto',
                              marginTop: '0px',
                              marginBottom: '0px',
                              textAlign: 'right',
                            }}
                          >
                            {calculateTotalPrice(order)}
                          </h3>
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item md={12} className={classes.showPrint}>
                    <Header
                      id={order.id}
                      dealership={order.dealership.name}
                      location={order.location.name}
                      model={order.model}
                      floorplan={order.floorplan}
                      finance={order.finance}
                      calculateTotalPrice={calculateTotalPrice}
                      showMSRP={showMSRP}
                      calculateInternetPrice={calculateInternetPrice}
                      getStatus={getStatus}
                      status={order.orderStatus}
                      defaultMarkup={discounts.defaultMarkup}
                    />
                    <ImageRow
                      boatImages={boatImages}
                      benchImages={benchImages}
                      floorplanImage={order.floorplanImage}
                    />
                    <Options
                      options={sortedOptions}
                      calculatePrice={calculatePrice}
                      calculateTotalPrice={calculateTotalPrice}
                      showDealerPricing={showDealerPricing}
                      discounts={discounts}
                      hasTwin={order.hasTwin}
                      calculateFloorplanDiscount={calculateFloorplanDiscount}
                      modelId={
                        order.options.find((o) => o.inputId === 1).inputOptionId
                      }
                    />
                    <div className={classes.showPrint}>
                      <table
                        style={{
                          width: '100%',
                          padding: '0px 24px',
                          display: 'block',
                        }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ verticalAlign: 'top' }}>
                              <table
                                className={
                                  splitPDFOptions.length > 0
                                    ? `${classes.optionsTable} parallel`
                                    : classes.optionsTable
                                }
                              >
                                <thead>
                                  <tr>
                                    <th align="left" style={{ width: '100vw' }}>
                                      Options
                                    </th>
                                    <th align="right">Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {pdfOptions.map((o, index) => {
                                    return (
                                      <tr
                                        style={{ fontSize: '16px' }}
                                        key={`po_${o.id}`}
                                      >
                                        <td>
                                          {o.optionName}
                                          {o.optionDropdownName !== null
                                            ? ` - ${o.optionDropdownName}`
                                            : ''}
                                          {discounts !== undefined ? (
                                            <span>
                                              {discounts.floorplan !== 0 &&
                                              !showMSRP &&
                                              o.inputId === 2 ? (
                                                <div>
                                                  Floorplan Discount -
                                                  {discounts.floorplan}%
                                                </div>
                                              ) : null}
                                              {discounts.floorplan !== 0 &&
                                              !showMSRP &&
                                              o.inputId === 6 ? (
                                                <div>
                                                  Motor Discount -{discounts.motor}%
                                                </div>
                                              ) : null}
                                            </span>
                                          ) : null}
                                        </td>
                                        <td
                                          style={{
                                            paddingLeft: '6px',
                                            textAlign: 'right',
                                          }}
                                        >
                                          {calculatePrice(o) || `$0`}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </td>
                            {splitPDFOptions.length > 0 ? (
                              <td style={{ verticalAlign: 'top', width: '50%' }}>
                                <table
                                  className={`${classes.optionsTable} parallel`}
                                >
                                  <thead>
                                    <tr>
                                      <th align="left" style={{ width: '100vw' }}>
                                        Options
                                      </th>
                                      <th align="right">Price</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {splitPDFOptions.map((o, index) => {
                                      return (
                                        <tr
                                          style={{ fontSize: '16px' }}
                                          key={`po_${o.id}`}
                                        >
                                          <td>
                                            {o.optionName}
                                            {o.optionDropdownName !== null
                                              ? ` -2 ${o.optionDropdownName}`
                                              : ''}
                                            {discounts !== undefined ? (
                                              <span>
                                                {discounts.floorplan !== 0 &&
                                                !showMSRP &&
                                                o.inputId === 2 ? (
                                                  <div>
                                                    Floorplan Discount -
                                                    {discounts.floorplan}%
                                                  </div>
                                                ) : null}
                                                {discounts.floorplan !== 0 &&
                                                !showMSRP &&
                                                o.inputId === 6 ? (
                                                  <div>
                                                    Motor Discount -{discounts.motor}
                                                    %
                                                  </div>
                                                ) : null}
                                              </span>
                                            ) : null}
                                          </td>
                                          <td
                                            style={{
                                              paddingLeft: '6px',
                                              textAlign: 'right',
                                            }}
                                          >
                                            {calculatePrice(o) || `$0`}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </td>
                            ) : null}
                          </tr>
                        </tbody>
                      </table>
                      <div
                        style={{
                          display: 'flex',
                          padding: '10px 24px',
                          margin: '0 24px',
                          backgroundColor: '#171A20',
                          color: '#fff',
                          borderRadius: '8px',
                        }}
                      >
                        <h3
                          style={{
                            marginTop: '0px',
                            marginBottom: '0px',
                          }}
                        >
                          TOTAL
                        </h3>
                        <h3
                          style={{
                            marginLeft: 'auto',
                            marginTop: '0px',
                            marginBottom: '0px',
                            textAlign: 'right',
                          }}
                        >
                          {calculateTotalPrice(order)}
                        </h3>
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={4} className={classes.hidePrint}>
                    <Paper>
                      {(order.customerName ||
                        order.customerEmail ||
                        order.customerPhone) && (
                        <CustomerInfo
                          name={order.customerName}
                          email={order.customerEmail}
                          phone={order.customerPhone}
                        />
                      )}
                      {warning !== '' && <Warnings warning={warning} />}
                      {order.epicorOrderNumber && (
                        <EpicorInfo
                          orderNum={order.epicorOrderNumber}
                          status={order.epicorApprovalStatus}
                          productionWeek={order.shipByDate}
                          revisionId={order.revisionId}
                          warnings={order.epicorOrderWarnings}
                        />
                      )}
                    </Paper>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item md={8} className={classes.hidePrint}>
                    <Paper sx={{ p: 4 }}>
                      <Header
                        id={order.id}
                        dealership={order.dealership.name}
                        location={order.location.name}
                        model={order.model}
                        floorplan={order.floorplan}
                        finance={order.finance}
                        calculateTotalPrice={calculateTotalPrice}
                        showMSRP={showMSRP}
                        calculateInternetPrice={calculateInternetPrice}
                        getStatus={getStatus}
                        status={order.orderStatus}
                        defaultMarkup={discounts.defaultMarkup}
                      />
                      <ImageRow
                        boatImages={boatImages}
                        benchImages={benchImages}
                        floorplanImage={order.floorplanImage}
                      />
                      <Options
                        options={sortedOptions}
                        calculatePrice={calculatePrice}
                        calculateTotalPrice={calculateTotalPrice}
                        showMSRP={showMSRP}
                        discounts={discounts}
                        hasTwin={order.hasTwin}
                        calculateFloorplanDiscount={calculateFloorplanDiscount}
                        modelId={
                          order.options.find((o) => o.inputId === 1).inputOptionId
                        }
                      />
                      <div className={classes.showPrint}>
                        <table
                          style={{
                            width: '100%',
                            display: 'block',
                          }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ verticalAlign: 'top' }}>
                                <table
                                  className={
                                    splitPDFOptions.length > 0
                                      ? `${classes.optionsTable} parallel`
                                      : classes.optionsTable
                                  }
                                >
                                  <thead>
                                    <tr>
                                      <th align="left" style={{ width: '100vw' }}>
                                        Options
                                      </th>
                                      <th align="right">Price</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {pdfOptions.map((o, index) => {
                                      return (
                                        <tr
                                          style={{ fontSize: '16px' }}
                                          key={`po_${o.id}`}
                                        >
                                          <td>
                                            {o.optionName}
                                            {o.optionDropdownName !== null
                                              ? ` - ${o.optionDropdownName}`
                                              : ''}
                                            {discounts !== undefined ? (
                                              <span>
                                                {discounts.floorplan !== 0 &&
                                                !showMSRP &&
                                                o.inputId === 2 ? (
                                                  <div>
                                                    Floorplan Discount -
                                                    {discounts.floorplan}%
                                                  </div>
                                                ) : null}
                                                {discounts.floorplan !== 0 &&
                                                !showMSRP &&
                                                o.inputId === 6 ? (
                                                  <div>
                                                    Motor Discount -{discounts.motor}
                                                    %
                                                  </div>
                                                ) : null}
                                              </span>
                                            ) : null}
                                          </td>
                                          <td
                                            style={{
                                              paddingLeft: '6px',
                                              textAlign: 'right',
                                            }}
                                          >
                                            {calculatePrice(o) || `$0`}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </td>
                              {splitPDFOptions.length > 0 ? (
                                <td style={{ verticalAlign: 'top', width: '50%' }}>
                                  <table
                                    className={`${classes.optionsTable} parallel`}
                                  >
                                    <thead>
                                      <tr>
                                        <th align="left" style={{ width: '100vw' }}>
                                          Options
                                        </th>
                                        <th align="right">Price</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {splitPDFOptions.map((o, index) => {
                                        return (
                                          <tr
                                            style={{ fontSize: '16px' }}
                                            key={`po_${o.id}`}
                                          >
                                            <td>
                                              {o.optionName}
                                              {o.optionDropdownName !== null
                                                ? ` -3 ${o.optionDropdownName}`
                                                : ''}
                                              {discounts !== undefined ? (
                                                <span>
                                                  {discounts.floorplan !== 0 &&
                                                  !showMSRP &&
                                                  o.inputId === 2 ? (
                                                    <div>
                                                      Floorplan Discount -
                                                      {discounts.floorplan}%
                                                    </div>
                                                  ) : null}
                                                  {discounts.motor !== 0 &&
                                                  !showMSRP &&
                                                  o.inputId === 6 ? (
                                                    <div>
                                                      Motor Discount -
                                                      {discounts.motor}%
                                                    </div>
                                                  ) : null}
                                                </span>
                                              ) : null}
                                            </td>
                                            <td
                                              style={{
                                                paddingLeft: '6px',
                                                textAlign: 'right',
                                              }}
                                            >
                                              {calculatePrice(o) || `$0`}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </td>
                              ) : null}
                            </tr>
                          </tbody>
                        </table>
                        <div
                          style={{
                            display: 'flex',
                            padding: '10px 24px',
                            margin: '0 24px',
                            backgroundColor: '#171A20',
                            color: '#fff',
                            borderRadius: '8px',
                          }}
                        >
                          <h3
                            style={{
                              marginTop: '0px',
                              marginBottom: '0px',
                            }}
                          >
                            TOTAL
                          </h3>
                          <h3
                            style={{
                              marginLeft: 'auto',
                              marginTop: '0px',
                              marginBottom: '0px',
                              textAlign: 'right',
                            }}
                          >
                            {calculateTotalPrice(order)}
                          </h3>
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item md={12} className={classes.showPrint}>
                    <Header
                      id={order.id}
                      dealership={order.dealership.name}
                      location={order.location.name}
                      model={order.model}
                      floorplan={order.floorplan}
                      finance={order.finance}
                      calculateTotalPrice={calculateTotalPrice}
                      showMSRP={showMSRP}
                      calculateInternetPrice={calculateInternetPrice}
                      getStatus={getStatus}
                      status={order.orderStatus}
                      defaultMarkup={discounts.defaultMarkup}
                    />
                    <ImageRow
                      boatImages={boatImages}
                      benchImages={benchImages}
                      floorplanImage={order.floorplanImage}
                    />
                    <Options
                      options={sortedOptions}
                      calculatePrice={calculatePrice}
                      calculateTotalPrice={calculateTotalPrice}
                      showMSRP={showMSRP}
                      discounts={discounts}
                      hasTwin={order.hasTwin}
                      calculateFloorplanDiscount={calculateFloorplanDiscount}
                      modelId={
                        order.options.find((o) => o.inputId === 1).inputOptionId
                      }
                    />
                    <div className={classes.showPrint}>
                      <table
                        style={{
                          width: '100%',
                          display: 'block',
                        }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ verticalAlign: 'top' }}>
                              <table
                                className={
                                  splitPDFOptions.length > 0
                                    ? `${classes.optionsTable} parallel`
                                    : classes.optionsTable
                                }
                              >
                                <thead>
                                  <tr>
                                    <th align="left" style={{ width: '100vw' }}>
                                      Options
                                    </th>
                                    <th align="right">Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {pdfOptions.map((o, index) => {
                                    return (
                                      <tr
                                        style={{ fontSize: '16px' }}
                                        key={`po_${o.id}`}
                                      >
                                        <td>
                                          {o.optionName}
                                          {o.optionDropdownName !== null
                                            ? ` -5 ${o.optionDropdownName}`
                                            : ''}

                                          <span>
                                            {discounts.floorplan !== 0 &&
                                            !showMSRP &&
                                            o.inputId === 2 ? (
                                              <div>
                                                Floorplan Discount -
                                                {discounts.floorplan}%
                                              </div>
                                            ) : null}
                                            {discounts.floorplan !== 0 &&
                                            !showMSRP &&
                                            o.inputId === 6 ? (
                                              <div>
                                                Motor Discount -{discounts.motor}%
                                              </div>
                                            ) : null}
                                          </span>
                                        </td>
                                        <td
                                          style={{
                                            paddingLeft: '6px',
                                            textAlign: 'right',
                                          }}
                                        >
                                          {calculatePrice(o) || `$0`}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </td>
                            {splitPDFOptions.length > 0 ? (
                              <td style={{ verticalAlign: 'top', width: '50%' }}>
                                <table
                                  className={`${classes.optionsTable} parallel`}
                                >
                                  <thead>
                                    <tr>
                                      <th align="left" style={{ width: '100vw' }}>
                                        Options
                                      </th>
                                      <th align="right">Price</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {splitPDFOptions.map((o, index) => {
                                      return (
                                        <tr
                                          style={{ fontSize: '16px' }}
                                          key={`po_${o.id}`}
                                        >
                                          <td>
                                            {o.optionName}
                                            {o.optionDropdownName !== null
                                              ? ` - ${o.optionDropdownName}`
                                              : ''}
                                            {discounts !== undefined ? (
                                              <span>
                                                {discounts.floorplan !== 0 &&
                                                !showMSRP &&
                                                o.inputId === 2 ? (
                                                  <div>
                                                    Floorplan Discount -
                                                    {discounts.floorplan}%
                                                  </div>
                                                ) : null}
                                                {discounts.floorplan !== 0 &&
                                                !showMSRP &&
                                                o.inputId === 6 ? (
                                                  <div>
                                                    Motor Discount -{discounts.motor}
                                                    %
                                                  </div>
                                                ) : null}
                                              </span>
                                            ) : null}
                                          </td>
                                          <td
                                            style={{
                                              paddingLeft: '6px',
                                              textAlign: 'right',
                                            }}
                                          >
                                            {calculatePrice(o) || `$0`}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </td>
                            ) : null}
                          </tr>
                        </tbody>
                      </table>
                      <div
                        style={{
                          display: 'flex',
                          padding: '10px 24px',
                          margin: '0 24px',
                          backgroundColor: '#171A20',
                          color: '#fff',
                          borderRadius: '8px',
                        }}
                      >
                        <h3
                          style={{
                            marginTop: '0px',
                            marginBottom: '0px',
                          }}
                        >
                          TOTAL
                        </h3>
                        <h3
                          style={{
                            marginLeft: 'auto',
                            marginTop: '0px',
                            marginBottom: '0px',
                            textAlign: 'right',
                          }}
                        >
                          {calculateTotalPrice(order)}
                        </h3>
                      </div>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          )}

          <Dialog
            open={openDiscounts}
            onClose={handleCloseDiscounts}
            maxWidth="sm"
            fullWidth
            aria-labelledby="discounts"
          >
            <DialogTitle>Discounts</DialogTitle>
            <DialogContent>
              <form noValidate autoComplete="off">
                <FormControl fullWidth className={classes.textField}>
                  <InputLabel>Markup</InputLabel>
                  <Select
                    id="markup"
                    name="markup"
                    label="Markup"
                    value={discounts.markup}
                    onChange={(e) => handleChangeDiscounts(e.target.value, 'markup')}
                    fullWidth
                  >
                    {discounts.markupOptions.map((o) => (
                      <MenuItem key={`markup_${o}`} value={o}>
                        {o}%
                      </MenuItem>
                    ))}
                  </Select>
                  {order.location !== undefined ? (
                    <Typography variant="subtitle2">
                      Default Markup - {discounts.defaultMarkup}%
                    </Typography>
                  ) : null}
                </FormControl>
                <FormControl fullWidth className={classes.textField}>
                  <InputLabel>Floorplan</InputLabel>
                  <Select
                    id="floorplan"
                    name="floorplan"
                    label="Floorplan"
                    value={discounts.floorplan}
                    onChange={(e) =>
                      handleChangeDiscounts(e.target.value, 'floorplan')
                    }
                    fullWidth
                  >
                    {discounts.discountOptions.map((o) => (
                      <MenuItem key={`floorplan_${o}`} value={o}>
                        {o}%
                      </MenuItem>
                    ))}
                  </Select>
                  {order.location !== undefined ? (
                    <Typography variant="subtitle2">
                      Default Floorplan Discount - {order.location.floorplanDiscount}
                      %
                    </Typography>
                  ) : null}
                </FormControl>
                <FormControl fullWidth className={classes.textField}>
                  <InputLabel>Floorplan 2</InputLabel>
                  <Select
                    id="floorplan2"
                    name="floorplan2"
                    label="Floorplan 2"
                    value={discounts.floorplan2}
                    onChange={(e) =>
                      handleChangeDiscounts(e.target.value, 'floorplan2')
                    }
                    fullWidth
                  >
                    {discounts.discountOptions.map((o) => (
                      <MenuItem key={`floorplan_${o}`} value={o}>
                        {o}%
                      </MenuItem>
                    ))}
                  </Select>
                  {order.location !== undefined ? (
                    <Typography variant="subtitle2">
                      Default Floorplan 2 Discount -{' '}
                      {order.location.floorplanDiscount2}%
                    </Typography>
                  ) : null}
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>Motor</InputLabel>
                  <Select
                    id="motor"
                    name="motor"
                    label="Motor"
                    value={discounts.motor}
                    onChange={(e) => handleChangeDiscounts(e.target.value, 'motor')}
                    fullWidth
                  >
                    {discounts.discountOptions.map((o) => (
                      <MenuItem key={`motor_${o}`} value={o}>
                        {o}%
                      </MenuItem>
                    ))}
                  </Select>
                  {order.location !== undefined ? (
                    <Typography variant="subtitle2" paragraph>
                      Default Motor Discount - {order.location.motorDiscount}%
                    </Typography>
                  ) : null}
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>Motor 2</InputLabel>
                  <Select
                    id="motor2"
                    name="motor2"
                    label="Motor 2"
                    value={discounts.motor2}
                    onChange={(e) => handleChangeDiscounts(e.target.value, 'motor2')}
                    fullWidth
                  >
                    {discounts.discountOptions.map((o) => (
                      <MenuItem key={`motor_${o}`} value={o}>
                        {o}%
                      </MenuItem>
                    ))}
                  </Select>
                  {order.location !== undefined ? (
                    <Typography variant="subtitle2" paragraph>
                      Default Motor 2 Discount - {order.location.motorDiscount2}%
                    </Typography>
                  ) : null}
                </FormControl>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDiscounts} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openNotes}
            onClose={handleCloseNotes}
            maxWidth="sm"
            fullWidth
            aria-labelledby="order-notes"
          >
            <DialogTitle>
              Order #{order.orderSource === 2 ? order.leadId : order.id} Notes
            </DialogTitle>
            <DialogContent>
              {order.notes
                .filter((note) => note.pageId === null)
                .map((note) => {
                  return (
                    <Box className={classes.note} key={`note_${note.id}`}>
                      <Typography variant="body1">
                        By {note.user.name} at{' '}
                        {format(new Date(note.createdAt), 'MM/dd/yyyy')}
                      </Typography>
                      <Typography variant="h6">{note.text}</Typography>
                    </Box>
                  );
                })}
              {archive !== 'MY21' && order.orderStatus < 2 ? (
                <AddNote
                  selectedOrder={order}
                  setSelectedOrder={setOrder}
                  closeAdd={handleCloseNotes}
                  repEmails={outsideRepEmails}
                />
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseNotes} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openVerify}
            onClose={handleCloseVerify}
            maxWidth="sm"
            fullWidth
            aria-labelledby="verify"
          >
            <DialogTitle>Verify Order</DialogTitle>
            <DialogContent>
              {actionLoading ? (
                <Loading />
              ) : (
                <>
                  <Typography variant="p" component="p">
                    Are you sure you want to verify this order?
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => handleVerifyOrder()}
                  >
                    Verify Order
                  </Button>
                </>
              )}
            </DialogContent>
          </Dialog>
          <Dialog
            open={openHistory}
            onClose={handleCloseHistory}
            maxWidth="sm"
            fullWidth
            aria-labelledby="Action Log"
          >
            {showUpdate ? (
              <DialogTitle>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => handleHistoryBack()}
                  sx={{ mr: 2 }}
                >
                  <ChevronLeftIcon />
                  Back
                </Button>
                Update {update.version}
              </DialogTitle>
            ) : (
              <DialogTitle>Action Log</DialogTitle>
            )}
            <DialogContent>
              {showUpdate ? (
                <TableContainer>
                  {update.update.length === 0 &&
                    update.add.length === 0 &&
                    update.remove.length === 0 && (
                      <Typography variant="h5" sx={{ my: 2 }}>
                        No Changes Made
                      </Typography>
                    )}
                  {update.update.length > 0 && (
                    <>
                      <Typography variant="h6">Updated Options</Typography>
                      <Table sx={{ mb: 2 }}>
                        <TableBody>
                          {update.update.map((uo, index) => (
                            <TableRow key={`uo-${index}`}>
                              <TableCell>{uo.new.optionName}</TableCell>
                              <TableCell>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Box sx={{ textDecorationLine: 'line-through' }}>
                                    {uo.old.optionDropdownName}
                                  </Box>{' '}
                                  <ChevronRightIcon fontSize="small" />{' '}
                                  {uo.new.optionDropdownName}
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  {uo.old.price}{' '}
                                  <ChevronRightIcon fontSize="small" />{' '}
                                  {uo.new.price}
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </>
                  )}

                  {update.add.length > 0 && (
                    <>
                      <Typography variant="h6">New Options</Typography>
                      <Table sx={{ mb: 2 }}>
                        <TableBody>
                          {update.add.map((ao) => (
                            <TableRow key={ao.inputId}>
                              <TableCell>
                                {ao.optionName}
                                {ao.optionDropdownName !== null &&
                                  ` - ${ao.optionDropdownName}`}
                              </TableCell>
                              <TableCell>{ao.price}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </>
                  )}
                  {update.remove.length > 0 && (
                    <>
                      <Typography variant="h6">Removed Options</Typography>
                      <Table sx={{ mb: 2 }}>
                        <TableBody>
                          {update.remove.map((ro) => (
                            <TableRow key={ro.inputId}>
                              <TableCell>
                                {ro.optionName}
                                {ro.optionDropdownName !== null &&
                                  ` - ${ro.optionDropdownName}`}
                              </TableCell>
                              <TableCell>{ro.price}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </>
                  )}
                </TableContainer>
              ) : (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Action</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell>Time</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {order?.actionHistory.map((action) => {
                        return (
                          <TableRow key={action.id}>
                            {action.actionType === 'UPDATE' &&
                            new Date(action.createdAt) >= new Date('2022-11-17') ? (
                              <TableCell>
                                <Button
                                  variant="contained"
                                  size="small"
                                  onClick={() => handleHistoryClick(action)}
                                >
                                  {action.actionType} {action.version}
                                </Button>
                              </TableCell>
                            ) : (
                              <TableCell>{action.actionType}</TableCell>
                            )}

                            <TableCell>
                              {action.user !== null ? action.user?.name : 'SYSTEM'}
                            </TableCell>
                            <TableCell>
                              {formatISO(new Date(action.createdAt))}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </DialogContent>
          </Dialog>
          <Dialog
            open={openSubmit}
            onClose={handleCloseSubmit}
            maxWidth="sm"
            fullWidth
            aria-labelledby="submit"
          >
            <DialogTitle>Submit Order</DialogTitle>
            <DialogContent>
              {actionLoading ? (
                <Loading />
              ) : (
                <>
                  <p>Current quarter - {order.currentQuarter}</p>
                  <FormControl fullWidth className={classes.textField}>
                    <InputLabel>Available Quarters</InputLabel>
                    <Select
                      id="quarter"
                      name="quarter"
                      label="Quarter"
                      value={selectedQuarter}
                      onChange={(e) => setSelectedQuarter(e.target.value)}
                      fullWidth
                    >
                      {availableQuarters.map((q) => (
                        <MenuItem key={`quarter_${q.value}`} value={q.value}>
                          {q.name} -{' '}
                          {displayAvailability(
                            order.dealership[q.value],
                            order.dealership[`${q.value}Count`]
                          )}{' '}
                          orders remaining
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {order.my_confirmation === 'ON' && (
                    <FormControl fullWidth className={classes.textField}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={modelConfirmation}
                            onChange={(e) => setModelConfirmation(e.target.checked)}
                          />
                        }
                        label={`Are you sure you want to submit a MY${order.ModelNum.slice(
                          0,
                          2
                        )} order?`}
                      />
                    </FormControl>
                  )}

                  {order.dealership[selectedQuarter] -
                    order.dealership[`${selectedQuarter}Count`] ===
                  0 ? (
                    <Box color="error.main">
                      <Typography variant="subtitle2">
                        You are out of availablity for the selected quarter, please
                        select another quarter.
                      </Typography>
                    </Box>
                  ) : null}
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => handleSubmitOrder()}
                    disabled={
                      selectedQuarter === '' ||
                      order.dealership[selectedQuarter] -
                        order.dealership[`${selectedQuarter}Count`] <=
                        0 ||
                      (!modelConfirmation && order.my_confirmation === 'ON')
                    }
                  >
                    Submit Order
                  </Button>
                </>
              )}
            </DialogContent>
          </Dialog>
          <Dialog
            open={openActionModal}
            onClose={handleCloseActionModal}
            maxWidth="sm"
            fullWidth
            aria-labelledby="action-modal"
          >
            <DialogTitle>{action.name}</DialogTitle>
            <DialogContent>
              {actionLoading ? (
                <Loading />
              ) : (
                <>
                  <Typography variant="body1" paragraph>
                    {action.message}
                  </Typography>
                  {action.showButton ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      onClick={() => handleAction()}
                    >
                      {action.name}
                    </Button>
                  ) : null}
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseActionModal} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openEditDealership}
            onClose={handleCloseEditDealership}
            maxWidth="sm"
            fullWidth
            aria-labelledby="action-modal"
          >
            <DialogTitle>Change Order Dealership</DialogTitle>
            <DialogContent>
              <EditOrderDealership
                dealerships={dealerships}
                orderId={order.id}
                currentDealership={order.dealership.name}
                close={handleCloseEditDealership}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEditDealership} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openRebates}
            onClose={handleCloseRebates}
            maxWidth="md"
            fullWidth
            aria-labelledby="action-modal"
          >
            <DialogTitle>
              <Box sx={{ width: '100%' }}>
                <Tabs value={rebateView} centered>
                  <Tab label="Rebate History" onClick={() => setRebateView(0)} />
                  {canRequestRebate() && (
                    <Tab label="Request Rebate" onClick={() => setRebateView(1)} />
                  )}
                </Tabs>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Box sx={{ p: 2 }}>
                {rebateView === 0 && (
                  <RebatesTable
                    rebates={order.rebates}
                    selectedRebate={selectedRebate}
                    handleSelectRebate={handleSelectRebate}
                    handleUpdateRebate={handleUpdateRebate}
                    role={userContext.userState.me.role}
                    setRebates={handleSetRebates}
                  />
                )}
                {rebateView === 1 && (
                  <RebateForm
                    order={order}
                    setOrder={setOrder}
                    handleCloseModal={handleCloseRebates}
                    totalPrice={calculateTotalPrice(order)}
                  />
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseRebates} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default Order;
