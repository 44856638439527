import React from 'react';
// Queries

const UserContext = React.createContext();
const queryString = location.search.split('=');
const initialState = {
  me: {
    isAuthenticated: false,
    customerBuild: queryString[0] === '?msrp',
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'login':
      return {
        me: { ...action.user, customerBuild: queryString[0] === '?msrp' },
      };
    case 'logout':
      return initialState;
    case 'reset':
      return initialState;
    case 'setLocations':
      return {
        me: {
          ...state.me,
          locations: action.payload.locations,
          dealership: action.payload.dealership,
        },
      };
    case 'toggleCustomerBuild':
      return {
        me: {
          ...state.me,
          customerBuild: !state.me.customerBuild,
        },
      };
    case 'setCustomerBuild':
      return {
        me: {
          ...state.me,
          customerBuild: action.payload.customerBuild,
        },
      };
    default:
      return state;
  }
};

export { UserContext, initialState, reducer };
