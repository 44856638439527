import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { UserContext } from '../userContext';
import makeStyles from '@mui/styles/makeStyles';
import { Paper, Grid, TextField, Button } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Loading from '../components/Loading';
import { useNavigate, Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(0, 2, 2, 2),
  },
}));

const validationSchemaReset = yup.object({
  email: yup
    .string('Please enter an email address')
    .email('Valid email is required')
    .required('Please enter your email'),
});

const ForgotPassword = () => {
  const [fetchError, setFetchError] = useState(null);
  const [loading, setLoading] = useState(false);
  const userContext = useContext(UserContext);
  const classes = useStyles();
  const [resetStatus, setResetStatus] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (userContext.userState.me.isAuthenticated) navigate('/');
  }, [userContext.userState.me.isAuthenticated]);

  const formikReset = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchemaReset,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const { data } = await axios.post('/api/reset-password', values);
        if (data.message) {
          setResetStatus(
            'Please check your email for a link to reset your password.'
          );
          setFetchError(null);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        setFetchError(err.response.data.message);
      }
    },
  });

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={6} lg={4}>
        <Paper variant="outlined" className={classes.paper}>
          {loading ? (
            <Loading />
          ) : (
            <div>
              <h1>Reset Password</h1>
              {fetchError && <p style={{ color: 'red' }}>{fetchError}</p>}
              {resetStatus && <p>{resetStatus}</p>}
              <section>
                <form
                  noValidate
                  autoComplete="off"
                  onSubmit={formikReset.handleSubmit}
                >
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={formikReset.values.email}
                    onChange={formikReset.handleChange}
                    error={
                      formikReset.touched.email && Boolean(formikReset.errors.email)
                    }
                    helperText={
                      formikReset.touched.email && formikReset.errors.email
                    }
                    className={classes.textField}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </Button>
                  <Button
                    variant="text"
                    color="secondary"
                    sx={{ mt: 2 }}
                    component={Link}
                    to="/login"
                  >
                    Return to login
                  </Button>
                </form>
              </section>
            </div>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
