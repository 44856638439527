export function formatPrice(price) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(Math.ceil(price));
}

export function getFormattedDate(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + day + year.toString().substr(-2);
}

export function readableRole(role) {
  switch (parseInt(role, 10)) {
    case 10:
      return 'Manager';
    case 11:
      return 'Sales';
    case 13:
      return 'Accounting';
    case 1:
      return 'Admin';
    case 2:
      return 'Sales Manager';
    case 3:
      return 'Sales Rep';
    default:
      return 'User';
  }
}

export const initPermissions = [
  {
    id: null,
    permission: 'view_order',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'create_order',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'edit_order',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'submit_order',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'verify_order',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'return_to_quote',
    isAllowed: null,
    roleDefault: false,

    touched: false,
  },
  {
    id: null,
    permission: 'print_order',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'convert_lead',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'derp_codes',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'manage_dealers',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'manage_dealer_users',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'manage_dealer_locations',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'manage_dealer_markups',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'manage_dealer_admin_info',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'manage_dealer_communication_list',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'find_dealerships',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'dealer_portal',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'view_invoices',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'rep_portal',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'terms',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'admin_form_builder',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'admin_rules',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'admin_rules_csv',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'admin_motors',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'manage_io_sales',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'manage_roles',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'admin_allotment',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'manage_notifications',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'admin_upcoming_orders',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'admin_atom_quarter_settings',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'edit_order_dealership',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'edit_discounts',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'edit_locked_inputs',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'request_rebate',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'notes_page',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'motor_orders',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
  {
    id: null,
    permission: 'view_boat_show_reports',
    isAllowed: null,
    roleDefault: false,
    touched: false,
  },
];

export const orderStatus = (status, orderSource) => {
  switch (parseInt(status, 10)) {
    case 0:
      if (orderSource === 2) {
        return 'Lead';
      } else {
        return 'Quote';
      }
    case 1:
      return 'Submitted';
    case 2:
      return 'Confirmed';
    case 3:
      return 'Complete';
    case 5:
      return 'Paper Order';
  }
};

export const parseBrand = (brand) => {
  switch (+brand) {
    case 1:
      return 'a';
    case 2:
      return 't';
    case 3:
      return 'gri';
    case 4:
      return 'l';
  }
};

export const parseBrandName = (brand, model) => {
  switch (brand) {
    case 'a':
      return model.avalonName;
    case 't':
      return model.tahoeName;
    case 'gri':
      return model.griName;
    case 'l':
      return model.legendName;
    default:
      return model.avalonName;
  }
};
