import React from 'react';
import {
  Typography,
  Button,
  Grid,
  TextField,
  MenuItem,
  InputAdornment,
} from '@mui/material';

const Text = (props) => {
  const {
    input,
    defaultRule,
    handleDefaultRule,
    priceRule,
    handlePriceRule,
    activeRule,
    handleActiveRule,
    disabledRule,
    handleDisabledRule,
    classes,
    renderActiveButtonText,
  } = props;

  return (
    <Grid container spacing={2}>
      <Grid item>
        <TextField
          id="default-value"
          label="Default value"
          value={defaultRule.changeValue}
          onChange={handleDefaultRule}
          size="small"
          className={defaultRule.changeValue}
          sx={{ width: '25ch' }}
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color={activeRule.changeValue !== '' ? 'secondary' : 'primary'}
          onClick={handleActiveRule}
        >
          {renderActiveButtonText()}
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color={disabledRule.changeValue === '1' ? 'secondary' : 'primary'}
          onClick={handleDisabledRule}
        >
          {disabledRule.changeValue === '1' ? 'Disabled' : 'Enabled'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Text;
