import React from 'react';
import ReactDOM from 'react-dom';
import { LicenseInfo } from '@mui/x-data-grid-pro';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

LicenseInfo.setLicenseKey(
  '6e8ffa96320246bf7b87a09d5c483e05T1JERVI6NDA2OTksRVhQSVJZPTE2ODAxNzg1NTMwMDAsS0VZVkVSU0lPTj0x'
);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
