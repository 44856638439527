import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import makeStyles from '@mui/styles/makeStyles';
import { Paper, Grid, TextField, Button } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom';
import Loading from '../components/Loading';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(0, 2, 2, 2),
  },
}));

const validationSchema = yup.object({
  password1: yup
    .string('Enter your password')
    .required('Password is required')
    .min(8, 'Minimum length is 8')
    .max(64, 'Max length is 64 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
    ),
  password2: yup
    .string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password1 === value;
    }),
});

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const ResetPassword = () => {
  const [isValidToken, setIsValidToken] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const classes = useStyles();
  let query = useQuery();

  useEffect(() => {
    const verifyToken = async () => {
      setLoading(true);
      if (!query.get('uuid')) {
        setIsValidToken(false);
        setLoading(false);
      }

      if (query.get('uuid')) {
        try {
          const { data } = await axios.get(
            `/api/reset-password?token=${query.get('uuid')}`
          );

          setIsValidToken(data);
          setLoading(false);
        } catch (err) {
          setIsValidToken(false);
          setLoading(false);
        }
      }
    };

    verifyToken();
  }, []);

  const formik = useFormik({
    initialValues: {
      password1: '',
      password2: '',
      token: query.get('uuid'),
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const { data } = await axios.patch('/api/reset-password', values);

        if (data.message) {
          setSubmitStatus(data.message);
          setLoading(false);
        }
      } catch (err) {
        setFetchError(err.response.data.message);
      }
    },
  });

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={6} lg={4}>
        <Paper variant="outlined" className={classes.paper}>
          {loading ? (
            <Loading />
          ) : (
            <div>
              <h1>Reset Password</h1>
              {query.get('uuid') && isValidToken ? (
                <>
                  {fetchError && <p style={{ color: 'red' }}>{fetchError}</p>}
                  {submitStatus ? (
                    <p>{submitStatus}</p>
                  ) : (
                    <section>
                      <form
                        noValidate
                        autoComplete="off"
                        onSubmit={formik.handleSubmit}
                      >
                        <TextField
                          fullWidth
                          id="password1"
                          name="password1"
                          label="Password"
                          type="password"
                          value={formik.values.password1}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.password1 &&
                            Boolean(formik.errors.password1)
                          }
                          helperText={
                            formik.touched.password1 && formik.errors.password1
                          }
                          className={classes.textField}
                        />
                        <TextField
                          fullWidth
                          id="password2"
                          name="password2"
                          label="Re-Enter Password"
                          type="password"
                          value={formik.values.password2}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.password2 &&
                            Boolean(formik.errors.password2)
                          }
                          helperText={
                            formik.touched.password2 && formik.errors.password2
                          }
                          className={classes.textField}
                        />
                        <Button
                          color="primary"
                          variant="contained"
                          fullWidth
                          type="submit"
                        >
                          Submit
                        </Button>
                      </form>
                    </section>
                  )}
                </>
              ) : (
                <h2 style={{ color: 'red' }}>
                  Reset token is expired or not found.
                </h2>
              )}
            </div>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
