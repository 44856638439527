import { Box, Container } from '@mui/material';
import PublicAppBar from './PublicAppBar';
import { Outlet } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none!important',
  },
}));

const Layout = (props) => {
  const classes = useStyles();

  return (
    <div>
      <PublicAppBar className={clsx({ [classes.hide]: props.customerOrderForm })} />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: 'calc(100vh - 64px)',
          overflow: 'auto',
        }}
      >
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Outlet />
        </Container>
      </Box>
    </div>
  );
};

export default Layout;
