import React, { useState, useEffect } from 'react';
import {
  TextField,
  Select,
  InputLabel,
  FormControl,
  Button,
  MenuItem,
  Typography,
  Box,
  Chip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

const dealershipValidationSchema = yup.object({
  name: yup
    .string('Enter the dealership name')
    .required('Dealership name is required'),
  brand: yup.string('Select a brand'),
  reps: yup.array(),
});

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    chip: {
      margin: 2,
    },
  },
}));

const AddDealership = () => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const navigate = useNavigate();
  const [reps, setReps] = useState([]);

  useEffect(() => {
    const loadReps = async () => {
      const { data } = await axios.get('/api/users/reps');

      let users = data.users.filter((u) => u.role === 3 && u.active);
      setReps(users);
    };

    loadReps();
  }, []);

  const dealershipFormik = useFormik({
    initialValues: {
      name: '',
      brand: '1',
      reps: [],
    },
    validationSchema: dealershipValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const { data } = await axios.post('/api/dealer', values);

        setSubmitError(null);
        resetForm();
        navigate(`/dealerships/${data.dealership.id}/settings`);
      } catch (err) {
        setSubmitError(err.response.data.message);
      }
    },
  });

  return (
    <form noValidate autoComplete="off" onSubmit={dealershipFormik.handleSubmit}>
      <Typography variant="h6">New Dealership</Typography>
      {submitError !== null ? (
        <Box color="error.main">
          <Typography variant="subtitle2">{submitError}</Typography>
        </Box>
      ) : null}
      <FormControl fullWidth>
        <TextField
          fullWidth
          id="name"
          name="name"
          label="Name"
          value={dealershipFormik.values.name}
          onChange={dealershipFormik.handleChange}
          className={classes.textField}
          error={
            dealershipFormik.touched.name && Boolean(dealershipFormik.errors.name)
          }
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>Brand</InputLabel>
        <Select
          fullWidth
          id="brand"
          name="brand"
          label="Brand"
          value={dealershipFormik.values.brand}
          onChange={dealershipFormik.handleChange}
          className={classes.textField}
        >
          <MenuItem value="1">Avalon</MenuItem>
          <MenuItem value="2">Tahoe</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="repsLabel">Reps</InputLabel>
        <Select
          id="reps"
          name="reps"
          labelId="repsLabel"
          multiple
          value={dealershipFormik.values.reps}
          onChange={dealershipFormik.handleChange}
          className={classes.textField}
          error={
            dealershipFormik.touched.reps && Boolean(dealershipFormik.errors.reps)
          }
          fullWidth
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  key={value.id}
                  label={value.username}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
        >
          {reps.map((r) => (
            <MenuItem key={r.id} value={r}>
              {r.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button color="primary" variant="contained" fullWidth type="submit">
        Submit
      </Button>
    </form>
  );
};

export default AddDealership;
