import React, { useState, useEffect } from 'react';
import { Paper, Grid, Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Loading from '../../../../../components/Loading';
import Conditions from './Conditions';
import InputsWithRules from './InputsWithRules';
import InputWithRules from './InputWithRules';

const RuleGroup = () => {
  let navigate = useNavigate();
  const { groupId, inputId } = useParams();
  const [loading, setLoading] = useState(true);
  const [ruleGroup, setRuleGroup] = useState(null);
  const [pages, setPages] = useState([]);
  const [conditions, setConditions] = useState([
    {
      page: '',
      input: '',
      inputOptions: [],
      inputs: [],
      selectedOptions: [],
      rules: [],
    },
  ]);
  const [inputs, setInputs] = useState([]);
  const [inputsWithRules, setInputsWithRules] = useState([]);

  useEffect(() => {
    const initializeRuleGroupPage = async () => {
      const { data: loadedRuleGroup } = await axios.get('/api/rule/group', {
        params: { id: groupId },
      });

      if (!loadedRuleGroup.ruleGroup) navigate('/settings/rules/group');

      const { data: loadedPages } = await axios.get('/api/pages');

      const { data: loadedInputs } = await axios.get('/api/inputs?isDeleted=1');

      const formattedInputs = loadedInputs.inputs.map((input) => {
        if (input.type === 'DROPDOWN') {
          return {
            ...input,
            inputOptions: [
              { id: 0, inputId: input.id, avalonName: 'Not Selected' },
              ...input.inputOptions,
            ],
          };
        } else if (input.type === 'CHECKBOX') {
          return {
            ...input,
            inputOptions: [
              { id: 0, inputId: input.id, avalonName: 'NOT SELECTED' },
              { id: 1, inputId: input.id, avalonName: 'SELECTED' },
            ],
          };
        } else {
          return input;
        }
      });

      setRuleGroup(loadedRuleGroup.ruleGroup);
      setPages(loadedPages);
      setInputs(formattedInputs);
      setLoading(false);
    };

    initializeRuleGroupPage();
  }, [groupId]);

  return (
    <Paper sx={{ p: 2 }}>
      {loading ? (
        <Loading />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4">{ruleGroup?.name}</Typography>
            {ruleGroup && (
              <Conditions
                ruleGroup={ruleGroup}
                setRuleGroup={setRuleGroup}
                pages={pages}
                conditions={conditions}
                setConditions={setConditions}
                inputs={inputs}
              />
            )}

            {inputId !== undefined ? (
              <InputWithRules
                ruleGroup={ruleGroup}
                setRuleGroup={setRuleGroup}
                inputs={inputs}
              />
            ) : (
              <InputsWithRules
                ruleGroup={ruleGroup}
                pages={pages}
                setPages={setPages}
                inputs={inputs}
                inputsWithRules={inputsWithRules}
                setInputsWithRules={setInputsWithRules}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default RuleGroup;
